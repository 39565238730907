import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import NumberFormat from 'react-number-format'
import StepBlock from '../StepBlock/StepBlock'
import FunnelBlock from '../FunnelBlock/FunnelBlock'
import LooseBlock from '../LooseBlock/LooseBlock'
import declOfNum from '../../../../../../utilities/declOfNum'
import usePeriodFromUrl from '../../../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import countDiff from '../../functions/countDiff'
import countFactWins from '../../functions/countFactWins'
import countFactLooses from '../../functions/countFactLooses'
import countLooses from '../../functions/countLooses'
import countFacts from '../../functions/countFacts'
import getDoc from '../../../../../../utilities/db/getDoc'
import { getUrlQueryParams } from '../../../../../../utilities/queries'
import getCollection from '../../../../../../utilities/db/getCollection'
import Skeleton from '../../../../../../components/UI/Skeleton/Skeleton'

function DealFunnelSteps({ accountId, uid, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const { service } = getUrlQueryParams({ history: router.history, params: ['service'] })
  const [funnel, setFunnel] = useState(null)
  const [funnelSteps, setFunnelSteps] = useState(null)

  useEffect(() => {
    if (service) {
      getDoc({ accountId, path: 'config/global/services', docId: service }).then(({ funnelId }) =>
        getDoc({ accountId, path: 'config/deals/funnels', docId: funnelId }).then(({ steps }) => setFunnel(steps)),
      )
    }
  }, [service])

  useEffect(() => {
    if (!isEmpty(funnel) && !isEmpty(dateRange) && !isEmpty(resp)) {
      countData()
    }
  }, [funnel, dateRange, resp])

  const countData = () => {
    const fetchedFunnelSteps = []
    const { offices, squads } = resp

    const responsible =
      !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : resp.responsible

    promiseAllValues({
      responsible,
      deals: getCollection({
        accountId,
        path: 'deals',
        whereQueries: [
          {
            fieldPath: 'isDeleted',
            op: '==',
            value: false,
          },
        ],
      }),
    }).then((results) => {
      const r = results.responsible
      const deals = results.deals.filter((d) => d.need === service)

      funnel.forEach((step) => {
        fetchedFunnelSteps.push({
          ...step,
          facts: countFacts({ step, deals, responsible: r }),
          diff: countDiff({ step, deals, dateRange, responsible: r }),
          looses: countLooses({ step, deals, dateRange, responsible: r }),
        })
      })
      fetchedFunnelSteps.push({
        resultStep: true,
        label: 'Завершено с победой',
        facts: {},
        diff: countFactWins({ deals, dateRange, responsible: r }),
        looses: countFactLooses({ deals, dateRange, responsible: r }),
      })
      setFunnelSteps(fetchedFunnelSteps)
    })
  }

  return (
    <FunnelBlock
      blockContent={
        <div className="FunnelBlock-Steps">
          {funnelSteps ? (
            funnelSteps.map((funnelStep) => (
              <StepBlock
                suffix="руб"
                isResultStep={funnelStep.resultStep}
                key={funnelStep.stepId}
                label={funnelStep.label}
                factMoney={funnelStep.facts.moneyCounter}
                factLegend={
                  <>
                    <NumberFormat value={funnelStep.facts.totalCounter} displayType="text" thousandSeparator={' '} />{' '}
                    <span>{declOfNum(funnelStep.facts.totalCounter, ['сделка', 'сделки', 'сделок'])}</span>
                  </>
                }
                diffTotalCounter={funnelStep.diff.totalCounter}
                diffMoneyCounter={funnelStep.diff.moneyCounter}
              />
            ))
          ) : (
            <FunnelSkeletonSteps />
          )}
        </div>
      }
      subBlockContent={
        <div className="FunnelBlock-Steps">
          {funnelSteps ? (
            funnelSteps.map((funnelStep) => (
              <LooseBlock
                key={`loose-${funnelStep.stepId}`}
                isResultStep={funnelStep.resultStep}
                content={
                  <>
                    <span>
                      <NumberFormat
                        value={funnelStep.looses.totalCounter}
                        displayType="text"
                        thousandSeparator={' '}
                        suffix={` ${declOfNum(funnelStep.looses.totalCounter, ['сделка', 'сделки', 'сделок'])}`}
                      />
                    </span>
                    <NumberFormat
                      value={funnelStep.looses.moneyCounter}
                      displayType="text"
                      thousandSeparator={' '}
                      suffix={` руб`}
                    />
                  </>
                }
              />
            ))
          ) : (
            <FunnelSkeletonLoose />
          )}
        </div>
      }
    />
  )
}

const FunnelSkeletonSteps = () => {
  const steps = []

  for (let index = 0; index < 5; index++) {
    steps.push(
      <StepBlock
        key={`FunnelStep-Skeleton-${index}`}
        label={<Skeleton width={100} />}
        factMoney={<Skeleton width={120} />}
        diffTotalCounter={<Skeleton circle width={32} height={32} />}
        diffMoneyCounter={<Skeleton width={60} />}
      />,
    )
  }
  return steps
}

const FunnelSkeletonLoose = () => {
  const steps = []

  for (let index = 0; index < 5; index++) {
    steps.push(<LooseBlock key={`FunnelStep-Skeleton-Loose-${index}`} content={<Skeleton width={100} />} />)
  }
  return steps
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(DealFunnelSteps)
