import React from 'react'
import './DropTile.sass'
import Icon from '../../../../../components/UI/Icon/Icon'

function DropTile({ isActive, icon, title, description, onClick }) {
  return (
    <div
      className={[
        'Site-ForgotPassword-DropTile',
        ...(isActive ? ['Site-ForgotPassword-DropTile_theme_active'] : []),
      ].join(' ')}
      onClick={onClick}
    >
      <div className="DropTile-Icon">
        <Icon name={icon} weight="regular" />
      </div>
      <div className="DropTile-Data">
        <p className="Data-Title Site-LinkText">{title}</p>
        <p className="Data-Description">{description}</p>
      </div>
      <div className="DropTile-ConfirmIcon">
        <Icon name="check" />
      </div>
    </div>
  )
}

export default DropTile
