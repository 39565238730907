import { chunk } from 'lodash'

const listOnMount = async ({ collection, page, fetchFunction }) => {
  const docsByChunks = chunk(collection, 20)
  const totalPages = docsByChunks.length

  let chunkCursor = 0
  let chunkToFetch

  if (page) {
    chunkCursor = page - 1
    chunkToFetch = docsByChunks[chunkCursor]
  } else {
    chunkToFetch = docsByChunks[chunkCursor]
  }

  let fetchedChunk = []
  if (chunkToFetch) {
    fetchedChunk = await Promise.all(chunkToFetch.map((d) => fetchFunction(d)))
  }

  return { totalPages, fetchedChunk }
}

export default listOnMount
