import React, { useState, useEffect } from 'react'
import './GeneralConversions.sass'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import WidgetTile from '../../widgets/WidgetTile/WidgetTile'
import ProfitTag from '../../widgets/ProfitTag/ProfitTag'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import countConversionToDeal from '../../functions/countConversionToDeal'
import countConversionToFail from '../../functions/countConversionToFail'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import isResponsibleFilterAvailable from '../../functions/isResponsibleFilterAvailable'

function GeneralConversions({ accountId, uid, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const [conversion, setConversion] = useState(null)

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads, responsible } = resp

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      })
        .then((data) =>
          promiseAllValues({
            dealConversion: countConversionToDeal({
              accountId,
              dateRange,
              responsible: isResponsibleFilterAvailable(uid) ? data.responsible : [uid],
            }),
            failureConversion: countConversionToFail({
              accountId,
              dateRange,
              responsible: isResponsibleFilterAvailable(uid) ? data.responsible : [uid],
            }),
          }),
        )
        .then((conv) => setConversion(conv))
    }
  }, [dateRange, resp])

  return (
    <WidgetTile title="Конверсии" counterPeriod={dateRange} isLoading={!conversion}>
      <div className="GeneralConversion">
        <Column label="В победу" value={conversion && conversion.dealConversion} calcType="%" />
        <Column label="В проигрыш" value={conversion && conversion.failureConversion} calcType="%" negativeCounter />
      </div>
    </WidgetTile>
  )
}

export function Column({ label, value, calcType, profit, negativeCounter = false, defaultColor = false }) {
  return (
    <div
      className={['ConversionColumn', ...(negativeCounter ? ['ConversionColumn_theme_negativeCounter'] : [''])].join(
        ' ',
      )}
    >
      <div className="ConversionColumn-Title">{label}</div>
      <div className="ConversionColumn-Value" style={defaultColor ? { color: '#39393D' } : {}}>
        {value}
        {calcType && calcType}
      </div>
      {profit && <ProfitTag value={profit} calcType="%" treatNegativeAsPositive={negativeCounter} />}
    </div>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(connect(mapStateToProps), withRouter)(GeneralConversions)
