import React, { useState, useEffect } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './Motivations.sass'

import filter from 'lodash/filter'
import isBefore from 'date-fns/isBefore'
import Pagination from '../../../../../components/UI/Pagination/Pagination'

import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import SceneTable from '../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import { formatDateAsDayMontYearHHMM } from '../../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../../components/UserNameWithAvatar/UserNameWithAvatar'

import { clickToCall } from '../../../../Dialer/functions/clickToCall'
import TableFilters from '../../../../TableFilters/TableFilters'
// import getContactsFilterBlocks from './functions/getContactsFilterBlocks'
import MotivationManager from './components/MotivationManager/MotivationManager'
import { convertDateFromString } from '../../../../../utilities/date/convertDateFromString'
import ModuleWithSubmenu from '../../../../Module/ModuleWithSubmenu'

function Motivations({ subsections, motivations, rawMotivations, uid, ...router }) {
  // const [contacts, setContacts, filterFields, showFilters, appliedFilters, filterButton] = useFilteredData(
  //   filteredContactsObjects,
  //   rawContactsObjects,
  //   getContactsFilterBlocks(),
  //   'contactsFilters',
  //   'contactId',
  // )
  const [displayedData, setDisplayedData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showPopUp, setShowPopUp] = useState(null)

  // useEffect(() => {
  //   if (contactsObjects.length) {
  //     setContacts(filteredContactsObjects)
  //     setIsLoading(false)
  //   }
  // }, [contactsObjects])

  const showManager = () => {
    router.history.push(`${router.match.path}add/`)
  }

  const editMode = (id) => {
    router.history.push(`${router.match.path}edit/${id}`)
  }

  // const deleteContact = async (id) => {
  //   setShowPopUp({ contactId: id })
  // }

  let content

  if (isLoading) {
    content = <Spinner type="module" styles={{ marginTop: '32px' }} />
  } else {
    content = (
      <>
        <SceneTable
          editOnDoubleClick
          header={[
            ['Дата создания', 100],
            ['Название'],
            ['Начало действия', 100],
            ['Конец действия', 100],
            ['Офис'],
            ['Отдел'],
            ['Группы'],
            ['Сотрудники', 120],
            ['Статус'],
            ['', 32],
          ]}
          body={displayedData.map((m) => [
            { id: m.motivationId },
            formatDateAsDayMontYearHHMM(m.created.at),
            m.title,
            m.startDate,
            m.endDate ? m.endDate : '-',
            m.offices.map((o) => o.title).join(', '),
            m.squads.map((s) => s.title).join(', '),
            m.roles.map((r) => r.title).join(', '),
            m.employees
              .map((e) => (e.hasOwnProperty('title') ? e.title : `${e.lastName} ${e.name} ${e.middleName}`))
              .join(', '),
            isBefore(new Date(), convertDateFromString(m.endDate)) ? (
              <span className="Status Status_theme_active">Активная</span>
            ) : (
              <span className="Status Status_theme_archive">Архивная</span>
            ),
            'options',
          ])}
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'pencil-alt',
              label: 'Редакт.',
              clicked: editMode,
            },
            // {
            //   return: 'docId',
            //   type: 'delete',
            //   icon: 'trash-alt',
            //   label: 'Удалить',
            //   clicked: deleteContact,
            // },
          ]}
        />
      </>
    )
  }

  return (
    <ModuleWithSubmenu subsections={subsections}>
      <Route path={`${router.match.path}add/`} render={() => <MotivationManager closeLink={router.match.path} />} />
      <Route
        path={`${router.match.path}edit/:motivationId`}
        render={() => <MotivationManager closeLink={router.match.path} />}
      />
      <Route
        path={[router.match.path, `${router.match.path}page/:page`]}
        exact
        render={() => (
          <>
            <SceneContent
              title="Управление системами мотивации"
              // filters={appliedFilters}
              buttons={[
                // filterButton,
                {
                  type: 'add',
                  icon: 'plus',
                  clicked: showManager,
                },
              ]}
            >
              {/* <TableFilters
                localStorageProp="contactsFilters"
                show={showFilters}
                filterBlocks={getContactsFilterBlocks()}
                filterFields={filterFields}
                fetchedObjects={contactsObjects}
                rawObjects={rawContactsObjects}
                comapreFieldId="contactId"
                triggerFilter={(data, filterForm) => {
                  router.history.push(router.match.path)
                  return setContacts(data, filterForm)
                }}
                close={() => ''}
              /> */}
              {content}
            </SceneContent>
            <Pagination data={motivations} setDisplayedData={setDisplayedData} parentPath={router.match.path} />
          </>
        )}
      />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  motivations: state.motivations.sortedMotivations,
  rawMotivations: state.motivations.rawMotivations,
  uid: state.firebase.auth.uid,
})

export default withRouter(connect(mapStateToProps)(Motivations))
