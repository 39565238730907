import { castComparisonVal } from './buildTasksDbQuery'
import isObject from 'lodash/isObject'
import isDate from 'lodash/isDate'
import { getTime } from 'date-fns'

const checkColumnRulePass = ({ rule, task }) => {
  const taskField =
    isObject(task[rule.comparisonField]) && task[rule.comparisonField].hasOwnProperty('seconds')
      ? task[rule.comparisonField].seconds * 1000
      : task[rule.comparisonField]

  const val = castComparisonVal(rule.comparisonVal)
  const comparisonVal = isDate(val) ? getTime(val) : val

  switch (rule.op) {
    case '<':
      return taskField < comparisonVal
    case '==':
      return taskField === comparisonVal
    case '>':
      return taskField > comparisonVal
    case '>=':
      return taskField >= comparisonVal
    case '<=':
      return taskField <= comparisonVal
    case '!=':
      return taskField !== comparisonVal
  }
}

export default checkColumnRulePass
