import React, { useEffect, useState } from 'react'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import Skeleton from '../../../../components/UI/Skeleton/Skeleton'
import Image from '../../../../ui/Img/Img'
import './ExtensionTile.sass'
import test from './Beeline.jpg'
import { setUrlQueryParams } from '../../../../utilities/queries/setUrlQueryParams'
import CommentsCounter from './CommentsCounter'
import RatingCounter from './RatingCounter'
import SetupButton from './SetupButton'
import { withRouter } from 'react-router'
import InstalledAppMarker from './InstalledAppMarker'
import { db } from '../../../../config/firebase'
import getDoc from '../../../../../functions/utilities/db/getDoc'
import promiseAllValues from '../../../../utilities/promiseAllValues'

function ExtensionTile({ extensionId, isInstalled, ...router }) {
  const [appData, setAppData] = useState(null)

  useEffect(() => {
    const listener = db
      .collection(`market/apps/extensions`)
      .doc(extensionId)
      .onSnapshot((snap) => {
        const data = snap.data()
        promiseAllValues({
          ...data,
          category: getDoc({ db, path: 'market/apps/categories', docId: data.category }).then(
            (category) => category.label,
          ),
          author: getDoc({ db, path: 'users', docId: data.author }),
        }).then((result) => setAppData(result))
      })

    return () => listener()
  }, [])

  const showExtensionPage = () => {
    router.history.push(setUrlQueryParams({ history: router.history, params: [['app', extensionId]] }))
  }

  return appData ? (
    <div className="ExtensionTile" onClick={showExtensionPage}>
      <Image src={test} className="ExtensionTile-Image" />
      <div className="ExtensionTile-Info">
        <p className="Title">{appData.title}</p>
        <p className="CategoryName">{appData.category}</p>
        {isInstalled && <InstalledAppMarker />}
        <div className="Scores-Container">
          <UserNameWithAvatar
            avatarUrl={appData.author.avatar.publicUrl}
            userName={`${appData.author.lastName} ${appData.author.name} ${appData.author.middleName}`}
            avatarSize={24}
          />
          <div className="Scores">
            <CommentsCounter comments={appData.comments} />
            <RatingCounter rating={appData.rating} />
            <SetupButton
              extensionId={extensionId}
              {...(isInstalled
                ? {
                    border: 'accent',
                    text: 'Открыть',
                    icon: 'external-link',
                    color: 'accent',
                    className: 'SetupButton_theme_active',
                  }
                : { installs: appData.installs })}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ExtensionTileSkeleton />
  )
}

export function ExtensionTileSkeleton() {
  return (
    <div className="ExtensionTile">
      <Skeleton className="ExtensionTile-Image" />
      <div className="ExtensionTile-Info">
        <p className="Title">
          <Skeleton width={140} />
        </p>
        <p className="CategoryName">
          <Skeleton width={100} />
        </p>
        <div className="Scores-Container">
          <>
            <Skeleton width={24} height={24} circle />
            <Skeleton width={100} height={24} />
          </>
          <div className="Scores">
            <Skeleton width={24} height={24} circle />
            <Skeleton width={24} height={24} circle />
            <Skeleton width={100} height={24} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ExtensionTile)
