import React from 'react'
import './ActionButtons.sass'
import DialerButton from '../../DialerButton/DialerButton'

function ActionButtons({ buttons }) {
  return (
    <div className="ActionButtons">
      {buttons.map((button, index) => (
        <DialerButton key={`DialerButton-${index}`} {...button} />
      ))}
    </div>
  )
}

export default ActionButtons
