import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'

const getTaskObject = ({
  tags = [],
  title,
  description = '',
  contacts = [],
  leads = [],
  sales = [],
  estate = [],
  startDate,
  endDate,
  isFinished = false,
  result = '',
  responsible,
  uid,
}) => ({
  tags,
  title,
  description,
  contacts,
  leads,
  sales,
  estate,
  startDate,
  endDate,
  isFinished,
  result,
  responsible,
  created: getCurDateWithUser(uid),
  updated: getCurDateWithUser(uid),
})

export default getTaskObject
