import { merge, omit } from 'lodash'
import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { addDoc } from '../../../../../../utilities/db/addDoc'
import getFormValues from '../../../../../../utilities/newforms/getFormValues'
import isFormValid from '../../../../../../utilities/newforms/validation/isFormValid'
import { logError } from '../../../../../../utilities/db/logError'

const submitContactForm = ({
  accountId,
  contactId,
  form,
  setStatusMessage,
  setShowErrors,
  setIsLoading,
  uid,
  onSuccessFunc,
}) => {
  if (!isFormValid({ form })) {
    setStatusMessage({
      show: true,
      type: 'fail',
      message: 'Невозможно сохранить контакт. Пожалуйста, заполните обязательные поля',
      closeAfter: 5000,
    })
    return setShowErrors(true)
  }

  setShowErrors(false)
  setIsLoading(true)

  const values = merge(getFormValues({ form }), {
    created: getCurDateWithUser(uid),
    updated: getCurDateWithUser(uid),
  })

  let dbFunc
  if (contactId) {
    dbFunc = updateDoc({
      accountId,
      path: 'contacts',
      docId: contactId,
      data: omit(values, ['created', 'updated']),
      uid,
    })
  } else {
    dbFunc = addDoc({
      accountId,
      path: 'contacts',
      data: values,
    })
  }

  dbFunc
    .then((docId) => {
      setStatusMessage({
        show: true,
        type: 'success',
        message: 'Успешно сохранено',
        closeAfter: 5000,
      })
      onSuccessFunc(docId)
    })
    .catch((error) => {
      console.log('🚀 ~ file: submitContactForm.js:65 ~ error:', error)
      logError({ error })
    })
    .finally(() => setIsLoading(false))
}

export default submitContactForm
