import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../config/firebase'
import getDoc from '../../../../../../utilities/db/getDoc'

const detachContactFromEstate = ({ accountId, estateId, ctForm, setCtForm, doc, uid }) => {
  const newForm = ctForm.filter((c) => doc.id !== c.id)

  if (estateId) {
    updateDoc({
      accountId,
      path: doc.type === 'contact' ? 'contacts' : 'organizations',
      docId: doc.id,
      data: { estate: firebase.firestore.FieldValue.arrayRemove(estateId) },
      uid,
    })
    getDoc({ accountId, path: 'estate', docId: estateId }).then((estate) =>
      updateDoc({
        accountId,
        path: 'estate',
        docId: estateId,
        data: { contacts: estate.contacts.filter((c) => c.id !== doc.id) },
      }),
    )
  }

  setCtForm(newForm)
}

export default detachContactFromEstate
