import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { getServiceTypesOptions } from '../../../../../../utilities/getOptions'
import { getUrlQueryParams, setUrlQueryParams } from '../../../../../../utilities/queries'
import './SalesFunnelControlls.sass'
import Skeleton from '../../../../../../components/UI/Skeleton/Skeleton'

function SalesFunnelControlls({ accountId, ...router }) {
  const [services, setServices] = useState(null)

  const { service } = getUrlQueryParams({ history: router.history, params: ['service'] })

  useEffect(() => {
    getServiceTypesOptions(accountId).then((data) => setServices(data))
  }, [])

  useEffect(() => {
    const localStorageService = localStorage.getItem(`${accountId}-dbService`)
    if (service) {
      router.history.push(setUrlQueryParams({ history: router.history, params: [['service', service]] }))
    } else if (localStorageService) {
      router.history.push(setUrlQueryParams({ history: router.history, params: [['service', localStorageService]] }))
    } else if (!isEmpty(services)) {
      router.history.push(setUrlQueryParams({ history: router.history, params: [['service', services[0].value]] }))
      localStorage.setItem(`${accountId}-dbService`, services[0].value)
    }
  }, [services])

  const onServiceClicked = (value) => {
    localStorage.setItem(`${accountId}-dbService`, value)
    router.history.push(setUrlQueryParams({ history: router.history, params: [['service', value]] }))
  }

  return (
    <div className="SalesFunnelControlls">
      <div className="SalesFunnelControlls-Container">
        {services ? (
          services.map((s) => (
            <ControllItem onClick={() => onServiceClicked(s.value)} title={s.label} isActive={service === s.value} />
          ))
        ) : (
          <>
            <Skeleton width={120} className="ControllItem" />
            <Skeleton width={120} className="ControllItem" />
            <Skeleton width={120} className="ControllItem" />
            <Skeleton width={120} className="ControllItem" />
          </>
        )}
      </div>
    </div>
  )
}

function ControllItem({ title, isActive, onClick }) {
  return (
    <p onClick={onClick} className={[['ControllItem'], ...(isActive ? ['ControllItem_theme_active'] : [])].join(' ')}>
      <span>{title}</span>
    </p>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(SalesFunnelControlls)
