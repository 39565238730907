import find from 'lodash/find'

const renderContact = ({ sortedContacts, contactId, openManager, isDetachable, detachContactObject }) => {
  const contact = find(sortedContacts, ['contactId', contactId])
  if (!contact) {
    return null
  }

  const buttons = [
    {
      title: 'Просмотр',
      theme: 'solid',
      fill: 'accent',
      onClick: () => openManager('contact', 'edit'),
    },
  ]

  if (isDetachable) {
    buttons.push({
      title: 'Открепить',
      theme: 'bounded',
      border: 'gray',
      color: 'black',
      onClick: () => detachContactObject(),
    })
  }

  return {
    icon: 'user',
    name: `${contact.lastName} ${contact.name} ${contact.middleName}`,
    phone: contact.phone[0].value,
    buttons,
  }
}

export default renderContact
