import arrayMove from 'array-move'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import { updateDocs } from '../../../../utilities/db/updateDocs'
import rootDbPath from '../../../../utilities/db/rootDbPath'

const onParamCategoryPositionChanged = (categories, from, to, uid, accountId) => {
  const newSortedArray = arrayMove(categories, from, to).map((c, i) => {
    c.position = i
    return c
  })

  const docsData = []
  newSortedArray.forEach((c) =>
    docsData.push({ docId: c.categoryId, data: { position: c.position, updated: getCurDateWithUser(uid) } }),
  )
  return updateDocs({ path: `${rootDbPath(accountId)}/config/estate/paramsCategories`, docsData })
}

export default onParamCategoryPositionChanged
