import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AccountTile, { AccountTileSkeleton } from './components/AccountTile/AccountTile'
import CreateAccount from './components/CreateAccount/CreateAccount'
import install from '../../../../install'
import getCollection from '../../../../utilities/db/getCollection'
import { db } from '../../../../config/firebase'

function Accounts({ user }) {
  const [accounts, setAccounts] = useState(null)

  useEffect(() => {
    if (user.systemRole === 'god') {
      const listener = db.collection('accounts').onSnapshot((snap) => {
        const docs = []
        snap.forEach((d) => docs.push(d.id))
        setAccounts(docs)
      })

      return () => {
        listener()
      }
    }
  }, [])

  useEffect(() => {
    if (user.systemRole !== 'god') {
      setAccounts(user.accounts)
    }
  }, [JSON.stringify(user.accounts)])

  return (
    <>
      <h5 className="Site-SubsectionTitle Site-ProfileTitle">Аккаунты</h5>
      {accounts ? (
        isEmpty(accounts) ? (
          <p className="NoInfoRow">Нет доступных аккаунтов</p>
        ) : (
          accounts.map((a) => <AccountTile accountId={a} />)
        )
      ) : (
        <AccountTileSkeleton />
      )}
      {user.systemRole === 'god' || (accounts && isEmpty(accounts)) ? <CreateAccount /> : null}
    </>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(Accounts)
