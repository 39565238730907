import React from 'react'
import './TableContact.sass'
import { isEmpty } from 'lodash'
import Icon from '../../../components/UI/Icon/Icon'
import { clickToCall } from '../../Dialer/functions'

function TableContact({ fetchedContacts }) {
  const c = fetchedContacts[0]
  return !isEmpty(c) ? (
    <div className="TableContact">
      {c.type === 'contact' ? <TableContactCard contact={c} /> : <TableOrganizationCard organization={c} />}
    </div>
  ) : (
    <span />
  )
}

const TableContactCard = ({ contact: c }) => (
  <>
    <TableContactData icon="user" data={`${c.lastName} ${c.name} ${c.middleName}`} />
    {c.phone[0].value && (
      <TableContactData
        icon="mobile"
        data={`${c.phone[0].value}`}
        onClick={() =>
          clickToCall({
            name: `${c.lastName} ${c.name} ${c.middleName}`,
            phone: c.phone[0].value,
            contactId: c.contactId,
            responsible: c.responsible.responsibleId,
          })
        }
      />
    )}
  </>
)

const TableOrganizationCard = ({ organization: o }) => (
  <>
    <TableContactData icon="building" data={`${o.title}`} />
    {o.phone[0].value && (
      <TableContactData
        icon="mobile"
        data={`${o.phone[0].value}`}
        onClick={() =>
          clickToCall({
            name: `${o.title}`,
            phone: o.phone[0].value,
            contactId: o.organizationId,
            responsible: o.responsible.responsibleId,
          })
        }
      />
    )}
  </>
)

const TableContactData = ({ icon, data, onClick }) => (
  <div className="TableContact-Data">
    <span className="TableContact-Icon">
      <Icon name={icon} />
    </span>
    <span className={onClick && 'ActiveLink'} onClick={onClick}>
      {data}
    </span>
  </div>
)

export default TableContact
