import React, { useEffect, useState } from 'react'
import ZingChart from 'zingchart-react'
import './InBaseCounter.sass'
import { differenceInDays, startOfYesterday } from 'date-fns'
import { isNull } from 'lodash'
import SceneContent from '../../../../components/UI/SceneContent/SceneContent'
import getDoc from '../../../../utilities/db/getDoc'
import Skeleton from '../../../../components/UI/Skeleton/Skeleton'

function InBaseCounter({ accountId, startDate = startOfYesterday(), getAvailableDaysFunc }) {
  return (
    <SceneContent title="Дней в базе" class="InBaseCounter">
      <Counter
        accountId={accountId}
        size={120}
        slice={52}
        showAvalDaysTitle
        startDate={startDate}
        getAvailableDaysFunc={getAvailableDaysFunc}
      />
    </SceneContent>
  )
}

export function Counter({
  getAvailableDaysFunc,
  startDate = startOfYesterday(),
  endDate = new Date(),
  size,
  slice,
  showAvalDaysTitle,
}) {
  const [avalDays, setAvalDays] = useState(null)
  const [inBaseDays, setInBaseDays] = useState(null)

  useEffect(() => {
    getAvailableDaysFunc().then((days) => {
      setAvalDays(days)
      const inBaseCounter = differenceInDays(endDate, startDate)
      setInBaseDays(inBaseCounter > 0 ? inBaseCounter : 1)
    })
  }, [])

  const chartConfig = {
    type: 'ring',
    scale: { 'size-factor': 1 },
    'scale-r': { 'ref-angle': 270 },
    plot: {
      size: `${size / 2}px`,
      slice: `${slice}px`,
      valueBox: { visible: false },
    },
    plotarea: {
      margin: 0,
      x: 0,
      y: 0,
    },
    series: [
      {
        values: [inBaseDays],
        backgroundColor: inBaseDays > avalDays ? '#F34375' : '#029E1E',
      },
      ...(inBaseDays < avalDays
        ? [
            {
              values: [avalDays - inBaseDays],
              backgroundColor: '#F6F8F9',
            },
          ]
        : []),
    ],
  }

  return (
    <div className="InBaseCounter-Container" style={{ width: `${size}px`, height: `${size}px` }}>
      {isNull(avalDays) || isNull(inBaseDays) ? (
        <Skeleton circle width={size} height={size} />
      ) : (
        <>
          <ZingChart className="InBaseCounter-Ring" data={chartConfig} height={`${size}px`} />
          <div className="InBaseCounter-Title">
            {inBaseDays}
            {showAvalDaysTitle && <span>из {avalDays}</span>}
          </div>
        </>
      )}
    </div>
  )
}

export default InBaseCounter
