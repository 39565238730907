import React, { useState, useEffect } from 'react'
import './AttachedContacts.sass'
import { withRouter } from 'react-router-dom'
import { cloneDeep, find, isEmpty, findIndex, isNull } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SceneContent from '../../../components/UI/SceneContent/SceneContent'
import ContactPopUpManager from './components/ContactPopUpManager/ContactPopUpManager'
import usePrevious from '../../../hooks/usePrevious'
import ContactCard, { ContactCardSkeleton } from '../ContactCard/ContactCard'

function AttachedContacts({ superstructure, permissions, accountId, uid, syncState, formData, docId, detachFunction }) {
  const [showPopUpForContact, setShowPopUpForContact] = useState(null)
  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    syncState(contacts)
  }, [contacts])

  const prevFormData = usePrevious(formData)
  useEffect(() => {
    if (!prevFormData && formData) {
      setContacts(formData)
    }
  }, [formData])

  const addContact = (contactData) => {
    const contactsClone = cloneDeep(contacts)
    const oldIndex = findIndex(contactsClone, ['contactId', contactData.contactId])
    if (oldIndex === -1) {
      contactsClone.push(contactData)
    } else {
      contactsClone.splice(oldIndex, 1, contactData)
    }
    setContacts(contactsClone)
  }

  const detachContact = (contactId) => {
    const contactsClone = cloneDeep(contacts)
    const newContacts = contactsClone.filter((c) => c.contactId !== contactId)

    if (docId) {
      detachFunction(contactId)
    }
    setContacts(newContacts)
  }

  const getSuperstructureData = () => {
    if (!isEmpty(showPopUpForContact)) {
      const data = find(contacts, ['contactId', showPopUpForContact.contactId])
      if (data) return data
      return {}
    }
    return {}
  }

  return (
    <SceneContent
      title="Контакты"
      buttons={
        permissions &&
        permissions.edit && [
          {
            type: 'add',
            icon: 'plus',
            clicked: () => setShowPopUpForContact({}),
          },
        ]
      }
    >
      <div className="Estate-ContactsTable">
        {showPopUpForContact && (
          <ContactPopUpManager
            contactId={showPopUpForContact ? showPopUpForContact.contactId : null}
            superstructure={superstructure}
            superstructureData={getSuperstructureData()}
            pushContact={addContact}
            close={() => setShowPopUpForContact(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contacts, ['contactId', c.contactId]))}
          />
        )}
        {isNull(contacts) ? (
          <ContactCardSkeleton />
        ) : !isEmpty(contacts) ? (
          contacts.map((c) => (
            <ContactCard
              key={`ContactCard-${c.contactId}`}
              contactId={c.contactId}
              superstructure={superstructure}
              superstructureData={c}
              expanded={showPopUpForContact && showPopUpForContact.contactId === c.contactId}
              buttons={[
                { type: 'openCard' },
                { type: 'edit', onEdit: () => setShowPopUpForContact({ contactId: c.contactId }) },
                { type: 'detach', onDetach: () => detachContact(c.contactId) },
              ]}
            />
          ))
        ) : (
          <div className="NoInfoRow">Нет контактов</div>
        )}
      </div>
    </SceneContent>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(AttachedContacts)
