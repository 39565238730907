export const addButton = ({ clicked }) => ({
  type: 'add',
  icon: 'plus',
  clicked,
})

export const filterButton = ({ clicked, pushed }) => ({
  type: 'basic',
  icon: 'filter',
  clicked,
  pushed,
})

export const editButton = ({ clicked, pushed }) => ({
  type: 'basic',
  icon: 'pencil-alt',
  clicked,
  pushed,
})

export const deleteButton = ({ clicked, pushed }) => ({
  type: 'basic',
  icon: 'trash-alt',
  clicked,
  pushed,
})
