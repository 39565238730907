import React, { useState, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import './TaskComments.sass'

import { connect } from 'react-redux'
import TextareaAutosize from 'react-autosize-textarea'
import { db } from '../../../../../../../config/firebase'

import { createRequiredField } from '../../../../../../../utilities/forms/createFormElement'
import taskInputChanged from '../../../functions/taskInputChanged'
import getTaskCommentObject from '../../../functions/getTaskCommentObject'
import addTaskComment from '../../../functions/addTaskComment'
import updateTask from '../../../functions/updateTask'

import Comment from './Comment'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import UserAvatar from '../../../../../../../components/UserAvatar/UserAvatar'
import clearFieldValue from '../../../../../../../utilities/forms/clearFieldValue'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'
import Button from '../../../../../../../ui/Button/Button'

function TaskComments({ accountId, taskId, taskStatus, user, canBeEdit }) {
  const [comments, setComments] = useState([])

  useEffect(
    () =>
      db
        .collection(`${rootDbPath(accountId)}/tasks/${taskId}/comments`)
        .orderBy('created.at')
        .onSnapshot((snapshot) => {
          const commentsArray = []
          snapshot.forEach((comment) => {
            commentsArray.push({
              commentId: comment.id,
              ...comment.data(),
            })
          })
          setComments(commentsArray)
        }),
    [],
  )

  return (
    <>
      <div className="Block-Title">
        <Icon name="comments" size="16" />
        <span>Комментарии</span>
      </div>
      <div className="Task-Comments-Container">
        {comments.map((comment) => (
          <Comment taskStatus={taskStatus} comment={comment} user={user} />
        ))}
        {!canBeEdit && isEmpty(comments) && <p className="NoInfoRow">Нет комментариев</p>}
        {canBeEdit && <CommentInput accountId={accountId} user={user} taskId={taskId} />}
      </div>
    </>
  )
}

function CommentInput({ accountId, user, taskId }) {
  const [newComment, setNewComment] = useState(createRequiredField('textarea', 'text', 'Написать комментарий...'))

  const submitComment = () => {
    if (newComment.valid) {
      const comment = cloneDeep(newComment)
      setNewComment(clearFieldValue(newComment))
      addTaskComment({
        accountId,
        taskId,
        commentObject: getTaskCommentObject({
          text: comment.value,
          submitAsTaskResult: false,
          uid: user.uid,
        }),
      }).then(() => updateTask({ accountId, taskId, taskData: {}, uid: user.uid }))
    }
  }

  return (
    <div className="Task-Comment-Input">
      <UserAvatar size="24" url={user.avatar.publicUrl} />
      <div className="Task-Comment-InputField">
        <TextareaAutosize
          placeholder={newComment.config.label}
          value={newComment.value}
          onChange={(e) => taskInputChanged(newComment, setNewComment, e)}
        />
        <div className="TaskButtons-Container">
          <Button title="Добавить комментарий" theme="solid" fill="accent" size={28} onClick={submitComment} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, user: state.auth.user })

export default connect(mapStateToProps)(TaskComments)
