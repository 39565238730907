import React, { useEffect, useState } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { cloneDeep, find } from 'lodash'
import * as actions from '../../store/actions/index'
import './Layout.sass'
import SideNav from '../SideNav/SideNav'
import Backdrop from '../../components/UI/Backdrop/Backdrop'
import Header from '../Header/Header'
import Dialer from '../../modules/Dialer/Dialer'
import { SideEventsContainer } from '../../modules/SideEvents/SideEvents'
import Module from '../../modules/Module/Module'
import hasAccountAccess from '../../utilities/hasAccountAccess'
import appTree from '../../appTree/appTree'
import usePrevious from '../../hooks/usePrevious'
import { db } from '../../config/firebase'
import rootDbPath from '../../utilities/db/rootDbPath'
import Spinner from '../../components/UI/Spinner/Spinner'
import getCollection from '../../../functions/utilities/db/getCollection'
import AppsLogic from '../../modules/Market/apps/AppsLogic'

function Layout({
  account,
  user,
  accountUser,
  isActiveCallBar,
  dialerSetListener,
  authUpdateAccountUserData,
  ...router
}) {
  const [userAppTree, setUserAppTree] = useState(null)
  const [userRoleId, setUserRoleId] = useState(null)
  const [extensions, setExtensions] = useState(null)

  const prevAccountUser = usePrevious(
    (user.systemRole === 'god'
      ? find(account.members, ['roleId', 'root'])
      : find(account.members, ['userId', user.uid])) || null,
  )

  useEffect(() => {
    const accountUser =
      user.systemRole === 'god'
        ? find(account.members, ['roleId', 'root'])
        : find(account.members, ['userId', user.uid])

    if (
      accountUser &&
      (!prevAccountUser ||
        prevAccountUser.officeId !== accountUser.officeId ||
        prevAccountUser.roleId !== accountUser.roleId)
    ) {
      db.collection(`${rootDbPath(account.accountId)}/offices`)
        .doc(accountUser.officeId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const accountUserData = cloneDeep(accountUser)
            accountUserData.office = { officeId: snap.id, ...snap.data() }
            authUpdateAccountUserData(accountUserData)
            setUserRoleId(accountUserData.roleId)
          } else {
            authUpdateAccountUserData(null)
            setUserRoleId(null)
          }
        })
    }
  }, [account])

  useEffect(() => {
    dialerSetListener(user.uid)

    getCollection({
      db,
      accountId: account.accountId,
      path: 'extensions',
      docIdName: 'extensionId',
      whereQueries: [{ fieldPath: 'mountOnSystemInit', op: '==', value: true }],
    }).then((extensionsToMount) => setExtensions(extensionsToMount.map((e) => e.extensionId)))
  }, [])

  useEffect(() => {
    if (userRoleId) {
      db.collection(`${rootDbPath(account.accountId)}/roles`)
        .doc(accountUser.roleId)
        .onSnapshot((snap) => {
          const role = { roleId: snap.id || accountUser.roleId, ...snap.data() }
          setUserAppTree(
            appTree({
              accountId: account.accountId,
              history: router.history,
              solution: account.solution,
              role,
            }),
          )
        })
    }
  }, [userRoleId])

  return hasAccountAccess({ user, account }) ? (
    <div key={accountUser && `role-${accountUser.roleId}`} className="Layout">
      {extensions && extensions.map((e) => <AppsLogic extensionId={e} />)}
      <Backdrop />
      <SideNav sideLinks={userAppTree} />
      <main
        className={['Layout-Content', ...(isActiveCallBar ? ['Layout-Content_theme_callbar_offset'] : [])].join(' ')}
      >
        <Header />
        {userAppTree ? (
          userAppTree.map((module) => (
            <Route
              path={module.path}
              render={() => (
                <Module
                  path={module.path}
                  component={module.component}
                  subsections={module.subsections}
                  permissions={module.permissions}
                  options={module.options}
                />
              )}
            />
          ))
        ) : (
          <div className="Module-Container" style={{ justifyContent: 'center', marginTop: '0px', height: '100vh' }}>
            <Spinner type="module" />
          </div>
        )}
        <SideEventsContainer callBarStatus={isActiveCallBar} />
        <Dialer />
      </main>
    </div>
  ) : (
    <Redirect to="/" />
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  account: state.auth.account.data,
  accountUser: state.auth.accountUser,
  isActiveCallBar: state.dialer.isActiveCallBar,
})

const mapDispatchToProps = (dispatch) => ({
  dialerSetListener: (uid) => dispatch(actions.dialerSetListener(uid)),
  authUpdateAccountUserData: (payload) => dispatch(actions.authUpdateAccountUserData(payload)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Layout)
