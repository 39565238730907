import getValuesFromPattern from '../../utilities/newforms/getValuesFromPattern'
import organizationObject from '../../modules/Contacts/scenes/OrganizationsList/OrganizationsManager/organizationDbForm'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'
import { setDoc } from '../../utilities/db/setDoc'

const addOrganizationsData = (accountId) => {
  const organizations = [
    {
      docId: 'default',
      data: getValuesFromPattern({
        formData: {
          title: 'ООО CardBox',
          phone: [
            {
              type: '',
              value: '+74951331638',
            },
          ],
          contacts: [
            {
              position: '',
              contactId: 'default',
            },
          ],
          deals: ['default'],
          responsible: 'F6ywHLXaOQOQLMqWKanREa9j0Ug1',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: organizationObject,
      }),
    },
  ]

  return organizations.map((d) => setDoc({ accountId, path: 'organizations', ...d }))
}

export default addOrganizationsData
