import { db } from '../../config/firebase'

export const getMotivationsParamsOptions = async (orderBy) => {
  if (!orderBy) orderBy = 'title'
  const options = []

  await db
    .collection('config/motivations/params')
    .orderBy(orderBy)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        options.push({
          value: doc.id,
          label: doc.data().title,
          rawData: doc.data(),
        })
      })
    })
  return options
}
