import React from 'react'
import './ProfitTag.sass'
import Icon from '../../../../components/UI/Icon/Icon'

function ProfitTag({ value, calcType, treatNegativeAsPositive = false }) {
  return (
    <div
      className={[
        'ProfitTag',
        ...(value >= 0 || treatNegativeAsPositive ? ['ProfitTag_theme_positive'] : ['ProfitTag_theme_negative']),
      ].join(' ')}
    >
      <div className="ProfitTag-Indicator">
        <Icon name={`arrow-${value < 0 ? 'down' : 'up'}`} weight="solid" />
      </div>
      <div className="ProfitTag-Value">
        {value > 0 ? `+${value}` : value}
        {calcType}
      </div>
    </div>
  )
}

export default ProfitTag
