import { firebase } from '../../../../config/firebase'
import { setDoc } from '../../../../utilities/db/setDoc'

const signUpUser = ({ userObject }) =>
  firebase
    .auth()
    .createUserWithEmailAndPassword(userObject.email, userObject.password)
    .then((result) => setDoc({ path: 'users', docId: result.user.uid, data: userObject }))

export default signUpUser
