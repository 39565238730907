import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { merge, omit } from 'lodash'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import FormRender from '../../../../../../../utilities/newforms/render/FormRender'
import { createForm } from '../../../../../../../utilities/newforms'
import CategoryForm from './categoryObject'
import { getOfficesOptions } from '../../../../../../../utilities/getOptions'
import StatusMessage, { useStatusMessage } from '../../../../../../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import { addDoc } from '../../../../../../../utilities/db/addDoc'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import getDoc from '../../../../../../../utilities/db/getDoc'

function CategoryManager({ accountId, officeId, categoryId, extensionId, onClose, uid }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    if (!categoryId) {
      setForm(
        createForm({
          formData: { officeId },
          formPattern: new CategoryForm(),
          methods: { getOfficesOptions: () => getOfficesOptions(accountId) },
        }),
      )
    } else {
      getDoc({ accountId, path: `extensions/${extensionId}/categories`, docId: categoryId }).then((data) =>
        setForm(
          createForm({
            formData: data,
            formPattern: new CategoryForm(),
            methods: { getOfficesOptions: () => getOfficesOptions(accountId) },
          }),
        ),
      )
    }
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 3000,
      })
    }
    setIsLoading(true)

    const values = merge(getFormValues({ form }), {
      created: getCurDateWithUser(uid),
      updated: getCurDateWithUser(uid),
    })

    let dbFunc
    if (categoryId) {
      dbFunc = updateDoc({
        accountId,
        path: `extensions/${extensionId}/categories`,
        docId: categoryId,
        data: omit(values, ['created', 'updated']),
        uid,
      })
    } else {
      dbFunc = addDoc({ accountId, path: `extensions/${extensionId}/categories`, data: values })
    }

    dbFunc
      .then(() => onClose())
      .catch((error) => {
        console.log('🚀 ~ file: CategoryManager.jsx:74 ~ onSubmit ~ error:', error)
        // logError({ error })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PopUp
      show
      close={onClose}
      title={categoryId ? 'Редактировать категорию' : 'Создать категорию'}
      submitButtons
      onSubmit={onSubmit}
      onCancel={onClose}
      isLoading={isLoading}
    >
      <div className="CategoryPrice-PopUp-Container">
        {statusMessage.show && <StatusMessage type={statusMessage.type} message={statusMessage.message} />}
        <FormRender
          sections={[{ fields: ['officeId', 'title', 'isActive'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(CategoryManager)
