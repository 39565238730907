import { find } from 'lodash'
import getDoc from '../../../../../../../utilities/db/getDoc'
import fetchMember from './fetchMember'

const fetchMemberByUserId = ({ accountId, userId }) =>
  getDoc({ path: 'accounts', docId: accountId }).then(({ members }) => {
    const memberObject = find(members, ['userId', userId])
    if (memberObject) return fetchMember({ accountId, memberObject })
    return getDoc({ path: 'users', docId: userId, docIdName: 'userId' })
  })

export default fetchMemberByUserId
