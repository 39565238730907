import { addMinutes, addWeeks, addYears } from 'date-fns'
import { addMonths, startOfTomorrow, endOfWeek } from 'date-fns/esm'
import React from 'react'
import './NextTimePicker.sass'

function NextTimePicker({ startDate, pushDate }) {
  const nextTimeOptions = [
    { label: 'Через 15 минут', value: '15min' },
    { label: 'Через 30 минут', value: '30min' },
    { label: 'Через час', value: 'hour' },
    { label: 'Сегодня', value: 'today' },
    { label: 'Завтра', value: 'tomorrow' },
    { label: 'До конца недели', value: 'endOfWeek' },
    { label: 'Через неделю', value: 'nextWeek' },
    { label: 'Через месяц', value: 'nextMonth' },
    { label: 'Через год', value: 'nextYear' },
  ]

  const getDate = ({ value }) => {
    const now = new Date()

    switch (value) {
      case '15min':
        return addMinutes(now, 15)
      case '30min':
        return addMinutes(now, 30)
      case 'hour':
        return addMinutes(now, 60)
      case 'today':
        updateTime(now, startDate)
        return now
      case 'tomorrow':
        const tomorrow = startOfTomorrow()
        updateTime(tomorrow, startDate)
        return tomorrow
      case 'endOfWeek':
        const weekEnd = endOfWeek(now, { weekStartsOn: 1 })
        updateTime(weekEnd, startDate)
        return weekEnd
      case 'nextWeek':
        const nextWeek = addWeeks(now, 1)
        updateTime(nextWeek, startDate)
        return nextWeek
      case 'nextMonth':
        const nextMonth = addMonths(now, 1)
        updateTime(nextMonth, startDate)
        return nextMonth
      case 'nextYear':
        const nextYear = addYears(now, 1)
        updateTime(nextYear, startDate)
        return nextYear
      default:
        return now
    }
  }

  const updateTime = (date, from) => {
    date.setHours(new Date(from.seconds * 1000).getHours())
    date.setMinutes(new Date(from.seconds * 1000).getMinutes())
  }

  return (
    <div className="NextTimePicker">
      <p className="NextTimePicker-Header">Быстрые опции</p>
      <ul className="NextTimePicker-Container">
        {nextTimeOptions.map((o) => (
          <li key={o.value} className="NextTimePicker-Element" onClick={() => pushDate(getDate(o))}>
            {o.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NextTimePicker
