import React, { useEffect, useState } from 'react'
import { find, isArray } from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import rootPath from '../../../../../appTree/rootPath'
import SceneTable from '../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import getCollection from '../../../../../utilities/db/getCollection'
import fetchMemberByUserId from '../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import DeleteNewsPopUp from './DeleteNewsPopUp'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import { db } from '../../../../../config/firebase'

function Newslist({ accountId, accountUser, ...router }) {
  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState(null)
  const [showPopUp, setShowPopUp] = useState(null)

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/news`).onSnapshot((snap) => {
      const result = []
      snap.docs.forEach((d) => result.push({ newsId: d.id, ...d.data() }))

      const actualNews = result.filter((n) => n.isDeleted === false)
      Promise.all(actualNews.map((r) => fetchMemberByUserId({ accountId, userId: r.created.by }))).then(
        (fetchedMembers) => {
          setNews(
            actualNews.map((n) => {
              n.author = find(fetchedMembers, ['userId', n.created.by])
              return n
            }),
          )
        },
      )
    })
  }, [])

  return (
    <SceneContent
      title="Новости"
      buttons={[
        {
          type: 'default',
          icon: 'cloud-upload-alt',
          clicked: () => null,
        },
        {
          type: 'add',
          icon: 'plus',
          clicked: () => router.history.push(`${rootPath(accountId)}/settings/site/news/add`),
        },
      ]}
    >
      <>
        {showPopUp && <DeleteNewsPopUp newsId={showPopUp && showPopUp.newsId} closePopUp={() => setShowPopUp(null)} />}
        <SceneTable
          editOnDoubleClick
          header={[['Дата создания', 110], ['Статус', 110], ['Новость'], ['Автор', 180], ['', 32]]}
          // skeleton={getLoadingSkeleton(15)}
          useCustomOptions
          body={
            isArray(news)
              ? news.map((n, i) => [
                  { id: n.newsId },
                  formatDateAsDayMontYearHHMM(n.created.at),
                  <div>{n.status === 'published' ? 'Опубликовано' : 'Черновик'}</div>,
                  <div>{n.title}</div>,
                  <UserNameWithAvatar
                    key={`displayed-data-${n.newsId}-${i}`}
                    avatarUrl={n.author.avatar.publicUrl}
                    avatarSize="26"
                    userName={`${n.author.lastName} ${n.author.name} ${n.author.middleName}`}
                  />,
                  // <div>
                  //   <p>{`${contact.lastName} ${contact.name} ${contact.middleName}`}</p>
                  //   <TableTagCloud tags={contact.tags} style={{ marginTop: '4px' }} />
                  // </div>,
                  // <span>{contact.organization}</span>,
                  // <span>test</span>,

                  {
                    options: [
                      {
                        return: 'docId',
                        type: 'edit',
                        icon: 'external-link',
                        label: 'Открыть',
                        clicked: (id) => router.history.push(`${rootPath(accountId)}/settings/site/news/${id}`),
                      },
                      {
                        return: 'docId',
                        type: 'delete',
                        icon: 'trash-alt',
                        label: 'Удалить',
                        clicked: (id) => setShowPopUp({ newsId: id }),
                      },
                    ],
                  },
                ])
              : []
          }
          isLoading={isLoading}
        />
      </>
    </SceneContent>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(Newslist))
