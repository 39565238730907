import React, { useState, useEffect, useRef } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { usePopUp } from '../../../../../../../hooks/usePopUp'
import { TaskTags } from '../TaskNav/options/TaskTags'

function TagChooser({ accountId, taskTags, uid, updateTaskConfig }) {
  const butRef = useRef()
  const ddBRef = useRef()
  const defaultLabel = ['Выберите метку']

  const [showSelectBox] = usePopUp(butRef, ddBRef, true)
  const [options, setOptions] = useState([])
  const [label, setLabel] = useState(defaultLabel)

  useEffect(() => {
    const fetchedLabels = []

    taskTags.forEach((r) => {
      const option = find(options, ['value', r])
      if (option) fetchedLabels.push(option.label)
    })

    if (isEmpty(fetchedLabels)) setLabel(defaultLabel)
    else setLabel(fetchedLabels)
  }, [options, taskTags])

  return (
    <>
      <div className="SelectableLabel" ref={butRef}>
        <span className="NoStyles">с меткой</span>
        {label.map((l) => (
          <span key={l}>{l}</span>
        ))}
      </div>
      <div
        ref={ddBRef}
        className="SelectableLabel-PopUpContainer"
        style={{ position: 'relative', display: showSelectBox ? 'block' : 'none' }}
      >
        <TaskTags
          accountId={accountId}
          data={{ tags: taskTags.map((t) => ({ tagId: t })) }}
          uid={uid}
          returnOptionsCb={setOptions}
          returnValueCb={(tags) => updateTaskConfig({ tags })}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

export default connect(mapStateToProps)(TagChooser)
