import React, { useEffect, useState } from 'react'
import { find, isArray } from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import parse from 'html-react-parser'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import rootPath from '../../../../../appTree/rootPath'
import SceneTable from '../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import getCollection from '../../../../../utilities/db/getCollection'
import fetchMemberByUserId from '../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import DeleteNewsPopUp from './DeleteFeedPopUp'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import { db } from '../../../../../config/firebase'
import DeleteFeedPopUp from './DeleteFeedPopUp'

function Feedslist({ accountId, accountUser, ...router }) {
  const [isLoading, setIsLoading] = useState(false)
  const [feeds, setFeeds] = useState(null)
  const [showPopUp, setShowPopUp] = useState(null)

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/feeds`).onSnapshot((snap) => {
      const result = []
      snap.docs.forEach((d) => result.push({ feedId: d.id, ...d.data() }))

      const actualFeeds = result.filter((n) => n.isDeleted === false)
      Promise.all(actualFeeds.map((r) => fetchMemberByUserId({ accountId, userId: r.rieltor }))).then(
        (fetchedMembers) => {
          setFeeds(
            actualFeeds.map((f) => {
              f.rieltor = find(fetchedMembers, ['userId', f.rieltor])
              return f
            }),
          )
        },
      )
    })
  }, [])

  return (
    <SceneContent
      title="Отзывы"
      buttons={[
        {
          type: 'default',
          icon: 'cloud-upload-alt',
          clicked: () => null,
        },
        {
          type: 'add',
          icon: 'plus',
          clicked: () => router.history.push(`${rootPath(accountId)}/settings/site/feeds/add`),
        },
      ]}
    >
      <>
        {showPopUp && <DeleteFeedPopUp feedId={showPopUp && showPopUp.feedId} closePopUp={() => setShowPopUp(null)} />}
        <SceneTable
          editOnDoubleClick
          header={[
            ['Дата создания', 110],
            ['Статус', 110],
            ['Автор', 110],
            ['Текст отзыва'],
            ['Оценка', 100],
            ['Агент', 180],
            ['', 32],
          ]}
          // skeleton={getLoadingSkeleton(15)}
          useCustomOptions
          body={
            isArray(feeds)
              ? feeds.map((n, i) => [
                  { id: n.feedId },
                  formatDateAsDayMontYearHHMM(n.created.at),
                  <div>{n.status === 'published' ? 'Опубликовано' : 'Черновик'}</div>,
                  <div>{n.author}</div>,
                  <div>{parse(n.text)}</div>,
                  <div>{n.rating}</div>,
                  <UserNameWithAvatar
                    key={`displayed-data-${n.feedId}-${i}`}
                    avatarUrl={n.rieltor.avatar.publicUrl}
                    avatarSize="26"
                    userName={`${n.rieltor.lastName} ${n.rieltor.name} ${n.rieltor.middleName}`}
                  />,

                  {
                    options: [
                      {
                        return: 'docId',
                        type: 'edit',
                        icon: 'external-link',
                        label: 'Открыть',
                        clicked: (id) => router.history.push(`${rootPath(accountId)}/settings/site/feeds/${id}`),
                      },
                      {
                        return: 'docId',
                        type: 'delete',
                        icon: 'trash-alt',
                        label: 'Удалить',
                        clicked: (id) => setShowPopUp({ feedId: id }),
                      },
                    ],
                  },
                ])
              : []
          }
          isLoading={isLoading}
        />
      </>
    </SceneContent>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(Feedslist))
