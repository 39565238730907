import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import Button from '../../../ui/Button/Button'
import './TryFreeButton.sass'

function TryFreeButton({
  title = 'Попробовать бесплатно',
  fill = 'black',
  icon = 'long-arrow-right',
  className = 'TryFreeButton_theme_black',
  size = null,
  user,
  ...router
}) {
  const onSubmit = () => {
    if (!user) {
      return router.history.push(`/auth/sign-up`)
    }
    return router.history.push(`/profile/accounts`)
  }

  return (
    <Button title={title} theme="solid" fill={fill} icon={icon} className={className} size={size} onClick={onSubmit} />
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default compose(withRouter, connect(mapStateToProps))(TryFreeButton)
