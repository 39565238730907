import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import rootDbPath from '../../../../../functions/utilities/db/rootDbPath'
import updateDoc from '../../../../../functions/utilities/db/updateDoc'
import { db, functions } from '../../../../config/firebase'
import renderEvent from '../../../../utilities/renderEvent'
import SubscriptionEvent from '../../../Dialer/SubscriptionEvent/SubscriptionEvent'

function Logic({ accountId, uid }) {
  const [extensionData, setExtensionData] = useState()
  const [subscriptions, setSubscriptions] = useState(null)
  const func = functions.httpsCallable('extensionsOnCall')

  useEffect(() => {
    const subscriptionsListener = db
      .collection(`${rootDbPath(accountId)}/extensions/zb6aPxoNMXsPKsVZfT88/subscriptions`)
      .where('responsible', '==', uid)
      .where('isActive', '==', true)
      .onSnapshot((snap) => {
        const data = []
        snap.forEach((d) => data.push({ subscriptionId: d.id, ...d.data() }))
        setSubscriptions(data)
      })

    const eListener = db
      .collection(`${rootDbPath(accountId)}/extensions/`)
      .doc('zb6aPxoNMXsPKsVZfT88')
      .onSnapshot((snap) => {
        setExtensionData(snap.data())
      })

    return () => {
      subscriptionsListener()
      eListener()
    }
  }, [])

  const putSubscription = (phone) =>
    func({
      extensionId: 'zb6aPxoNMXsPKsVZfT88',
      funcPath: 'api/functions/putSubscription',
      payload: {
        token: extensionData.apiKey,
        phone,
        accountId,
      },
    }).then((result) => {
      if (result.data.errorCode) {
        return null
      }
      return updateDoc({
        db,
        accountId,
        path: `extensions/zb6aPxoNMXsPKsVZfT88/subscriptions`,
        docId: phone,
        data: result.data,
      })
    })

  useEffect(() => {
    if (extensionData) {
      if (!extensionData.apiKey) {
        const subscriptionEvent = (
          <SubscriptionEvent
            extensionTitle="Облачная АТС Билайн"
            status="unactive"
            statusTitle="Укажите токен для подключения к Beeline в настройках приложения."
          />
        )
        renderEvent('sideEvents', 'subscription', subscriptionEvent, { autoClose: 5000 })
      }

      if (subscriptions) {
        Promise.all(
          subscriptions.map((s) =>
            func({
              extensionId: 'zb6aPxoNMXsPKsVZfT88',
              funcPath: 'api/functions/getSubscription',
              payload: {
                token: extensionData.apiKey,
                subscriptionId: s.subscriptionId,
              },
            }).then((r) => {
              if (r.data.errorCode) return false
              return true
            }),
          ),
        ).then((statuses) => {
          statuses.forEach((s, i) => {
            const subscriptionEvent = (
              <SubscriptionEvent
                extensionTitle="Облачная АТС Билайн"
                status={s ? 'active' : 'unactive'}
                phone={subscriptions[i].phone}
                statusTitle={s ? 'Подключение активно' : 'Подключение не активно'}
                showSubscribeButton={!s}
                onSubscribe={() => putSubscription(subscriptions[i].phone)}
              />
            )
            renderEvent('sideEvents', 'subscription', subscriptionEvent, s ? { autoClose: 5000 } : {})
          })
        })
      }
    }
  }, [extensionData, subscriptions])

  return null
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, uid: state.auth.user.uid })

export default connect(mapStateToProps)(Logic)
