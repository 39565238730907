import React from 'react'
import EmailBody from '../components/EmailBody/EmailBody'
import EmailTitle from '../components/EmailTitle'
import EmailText from '../components/EmaiText'

function SignUpEmail({
  homePagePath = 'https://cdbx.io',
  name = '-',
  email = '-',
  phone = '-',
  companyName = '-',
  membersQuantity = '-',
  comment = '-',
}) {
  return (
    <EmailBody
      homePagePath={homePagePath}
      emailIcon="https://storage.googleapis.com/estate-cdbx-io-public/emails/support.png"
      homePagePath="https://cdbx.io"
    >
      <EmailTitle title="Обращение в поддержку" />
      <EmailText
        text={
          <>
            <p>
              <span style={{ fontWeight: 'bold' }}>Имя:</span> {name}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>E-mail:</span> {email}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Телефон:</span> {phone}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Компания:</span> {companyName}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Кол-во сотрудников:</span> {membersQuantity}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Текст вопроса:</span> {comment}
            </p>
          </>
        }
      />
    </EmailBody>
  )
}

export default SignUpEmail
