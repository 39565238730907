import {
  createdObject,
  updatedObject,
  deletedObject,
  createdForm,
  setField,
  updateField,
  deleteField,
  setFormField,
  updateFormField,
  deleteFormField,
  removedField,
  changedField
} from '../../../../../History/events/renderDefaultEvent'

export default function renderByTypeSet(objId, subtype, fieldLabel, newValue, oldValue, form) {
  switch (subtype) {
    case 'createdHistory':
      return createdObject('Создание лида:', `LD-${objId.slice(0, 6).toUpperCase()}`)
    case 'attachedContact':
      return createdObject('Прикрепление контакта:', objId)
    case 'updatedContact':
      return updatedObject('Прикрепление контакта:', newValue, 'Предыдущий контакт', oldValue)
    case 'deletedContact':
      return deletedObject('Удаление контакта', 'Предыдущий контакт', oldValue)
    case 'attachedObject':
      return createdObject('Прикрепление объекта:', 'Название объекта')
    case 'createdNewForm':
      return createdForm('Создание формы:', fieldLabel)
    case 'setField':
      return setField(fieldLabel, newValue)
    case 'updateField':
      return updateField(fieldLabel, oldValue, newValue)
    case 'deleteField':
      return deleteField(fieldLabel, oldValue)
    case 'setFormField':
      return setFormField(form, fieldLabel, newValue)
    case 'updateFormField':
      return updateFormField(form, fieldLabel, oldValue, newValue)
    case 'deleteFormField':
      return deleteFormField(form, fieldLabel, oldValue)
    case 'removedField':
      return removedField('Лейбл поля', 'Старое значение')
    case 'changedField':
      return changedField('Лейбл поля', 'Новое значение', 'Старое значение')
    default:
      break
  }
}
