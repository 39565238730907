import React from 'react'
import './UserNameWithAvatar.sass'
import UserAvatar from '../UserAvatar/UserAvatar'

function UserNameWithAvatar({ avatarUrl, avatarSize, userName }) {
  return (
    <div className="UserNameWithAvatar">
      <UserAvatar size={avatarSize} url={avatarUrl} />
      <span>{userName}</span>
    </div>
  )
}

export default UserNameWithAvatar
