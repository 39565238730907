import React, { useState } from 'react'
import './Office.sass'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SceneContent from '../../../../../../../components/UI/SceneContent/SceneContent'
import SceneTable from '../../../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import {
  addButton,
  editButton,
  deleteButton,
} from '../../../../../../../components/UI/SceneContent/SceneContentHeader/buttons'
import {
  editButton as editOption,
  deleteButton as deleteOption,
} from '../../../../../../../components/UI/SceneContent/Buttons/buttons'
import OfficeInfoBlock from '../OfficeInfoBlock/OfficeInfoBlock'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import UserNameWithAvatar from '../../../../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import Marker from '../../../../../../../components/UI/Marker/Marker'
import ManageSquadPopUp from './ManageSquadPopUp'
import ManageInfoBlockPopUp from './ManageInfoBlockPopUp'
import DeleteOfficePopUp from './DeleteOfficePopUp'
import rootPath from '../../../../../../../appTree/rootPath'
import DeleteSquadPopUp from './DeleteSquadPopUp'

function Office({ accountId, officeId, title, infoBlocks, squads, ...router }) {
  const [popUp, setPopUp] = useState({ type: null, show: false, editMode: false, instanceId: null })

  const togglePopUp = ({ type, show, editMode = false, instanceId = null }) =>
    setPopUp({ type, show, editMode, instanceId })

  return (
    <>
      {popUp.type === 'officeDelete' && popUp.show && (
        <DeleteOfficePopUp show officeId={officeId} close={() => togglePopUp({ type: null, show: false })} />
      )}
      {popUp.type === 'infoBlock' && popUp.show && (
        <ManageInfoBlockPopUp
          show
          officeId={officeId}
          editMode={popUp.editMode}
          infoBlockId={popUp.instanceId}
          close={() => togglePopUp({ type: null, show: false })}
        />
      )}
      {popUp.type === 'squadDelete' && popUp.show && (
        <DeleteSquadPopUp
          show
          officeId={officeId}
          squadId={popUp.instanceId}
          close={() => togglePopUp({ type: null, show: false })}
        />
      )}
      {popUp.type === 'squad' && popUp.show && (
        <ManageSquadPopUp
          show
          editMode={popUp.editMode}
          officeId={officeId}
          squadId={popUp.instanceId}
          close={() => togglePopUp({ type: null, show: false })}
        />
      )}
      <SceneContent
        title={title}
        buttons={[
          deleteButton({ clicked: () => togglePopUp({ type: 'officeDelete', show: true }) }),
          editButton({
            clicked: () => router.history.push(`${rootPath(accountId)}/settings/offices/${officeId}/edit`),
          }),
          addButton({ clicked: () => togglePopUp({ type: 'squad', show: true }) }),
        ]}
      >
        <div className="Office-InfoBlocks">
          {infoBlocks.map((ib) => (
            <OfficeInfoBlock
              key={ib.infoBlockId}
              title={ib.title}
              icon={ib.icon}
              onEdit={() => togglePopUp({ type: 'infoBlock', show: true, editMode: true, instanceId: ib.subId })}
            >
              {ib.content.map((ibContent) => (
                <p className="OfficeInfoBlock-Content-InfoLine">
                  {ibContent.lineTitle && <span className="InfoLine-Title">{ibContent.lineTitle}</span>}
                  {ibContent.lineValue && <span className="InfoLine-Content">{ibContent.lineValue}</span>}
                </p>
              ))}
            </OfficeInfoBlock>
          ))}
          <div onClick={() => togglePopUp({ type: 'infoBlock', show: true })} className="OfficeInfo-AddInfo">
            <Icon name="plus" /> Добавить инфоблок
          </div>
        </div>
        <div className="Office-Squads">
          <SceneTable
            header={[['', 32], ['Отдел'], ['Кол-во сотрудников'], ['Руководитель отдела'], ['', 32]]}
            body={squads.map((s) => [
              { id: s.squadId },
              <Marker color={s.markerColor} size="8" />,
              <span>{s.title}</span>,
              <span>{s.members.length}</span>,
              <UserNameWithAvatar
                avatarUrl={s.squadHead.avatar.publicUrl}
                avatarSize="26"
                userName={`${s.squadHead.lastName} ${s.squadHead.name} ${s.squadHead.middleName}`}
              />,
              'options',
            ])}
            options={[
              editOption((squadId) => togglePopUp({ type: 'squad', show: true, editMode: true, instanceId: squadId })),
              deleteOption((squadId) => togglePopUp({ type: 'squadDelete', show: true, instanceId: squadId })),
            ]}
          />
        </div>
      </SceneContent>
    </>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(Office)
