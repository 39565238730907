import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../../components/UI/Icon/Icon'

const SiteNavLink = React.forwardRef(({ title, type = '', path = null, isActive }, ref) =>
  path ? (
    <NavLink to={path} className={['Site-NavLink', ...(isActive ? ['Site-NavLink_theme_active'] : [])].join(' ')}>
      {title}
    </NavLink>
  ) : (
    <span
      ref={ref}
      className={[
        'Site-NavLink',
        ...(type === 'dropdown' ? ['Site-NavLink_theme_dropdown'] : []),
        ...(isActive ? ['Site-NavLink_theme_active'] : []),
      ].join(' ')}
    >
      {title}
      {type === 'dropdown' && <Icon name="angle-down" helperClass="Site-NavLink-Arrow" />}
    </span>
  ),
)

export default SiteNavLink
