import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = {
  sortedNotifications: [],
}

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.NOTIFICATIONS_SEND_DATA_TO_REDUCER:
      return setNotifications(action)
    case ACTION_TYPES.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS:
      return clearAllNotifications(action)
    case ACTION_TYPES.NOTIFICATIONS_CLEAR_NOTIFICATION:
      return clearNotification(action)
    default:
      return state
  }
}

const setNotifications = action => {
  const notifications = action.payload
  notifications.sort(function(x, y) {
    return y.updated.at.seconds * 1000 - x.updated.at.seconds * 1000
  })

  return {
    sortedNotifications: notifications,
  }
}

const clearAllNotifications = action => {
  return {
    sortedNotifications: [],
  }
}

const clearNotification = action => {
  return {
    sortedNotifications: action.payload,
  }
}
