import React from 'react'
import './DialerButton.sass'
import Icon from '../../../components/UI/Icon/Icon'

import NumberPads from './assets/NumberPads.svg'
const svgs = {
  NumberPads,
}

function DialerButton({ content, clicked, theme = null, preventClick = false }) {
  let child
  switch (content.type) {
    case 'icon':
      child = <Icon name={content.name} weight={content.weight} />
      break
    case 'svg':
      child = <img src={svgs[content.name]} alt={content.name} />
      break
    case 'custom':
      child = content.content
      break
    default:
      break
  }
  return (
    <button
      className={['DialerButton', ...(theme ? [`DialerButton_theme_${theme}`] : [])].join(' ')}
      onClick={() => (preventClick ? '' : clicked())}
    >
      {child}
      {content.text && <span className="DialerButton-Text">{content.text}</span>}
    </button>
  )
}

export default DialerButton
