import React from 'react'
import { Image } from 'react-html-email'
import EmailBody from '../components/EmailBody/EmailBody'
import EmailTitle from '../components/EmailTitle'
import EmailText from '../components/EmaiText'
import EmailCode from '../components/EmailCode'

function SignUpEmail({ homePagePath = 'https://cdbx.io', code, email = 'test@test.com', password = 'testPassword' }) {
  return (
    <EmailBody
      homePagePath={homePagePath}
      emailIcon="https://storage.googleapis.com/estate-cdbx-io-public/emails/signIn.png"
      homePagePath="https://cdbx.io"
    >
      <EmailTitle title="Добро пожаловать в CardBox!" />
      <EmailText text="Регистрация успешно завершена. Для активации аккаунта необходимо подтвердить ваш E-mail. Пожалуйста, введите этот код в соответствующей форме." />
      <EmailCode code={code} />
      <div>
        <p style={{ fontWeight: '600', marginBottom: '24px', marginTop: '0px ' }}>
          Для входа в аккаунт используйте следующие данные:
        </p>
        <div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#F6F8F9',
              padding: '16px',
              borderRadius: '8px',
              boxSizing: 'border-box',
            }}
          >
            <span
              style={{
                borderRadius: '100%',
                backgroundColor: '#1D1F29',
                width: '24px',
                height: '24px',
                marginRight: '12px',
                display: 'block',
                float: 'left',
              }}
            >
              <Image
                src="https://storage.googleapis.com/estate-cdbx-io-public/emails/envelope.png"
                width={24}
                height={24}
              />
            </span>
            <div style={{ float: 'left' }}>
              <p style={{ fontWeight: '600', marginTop: '0px', marginBottom: '4px' }}>E-mail</p>
              <a style={{ color: '#A7ACB0', margin: '0px', textDecoration: 'none' }}>{email}</a>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
        <div style={{ marginTop: '24px' }}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#F6F8F9',
              padding: '16px',
              borderRadius: '8px',
              boxSizing: 'border-box',
            }}
          >
            <span
              style={{
                borderRadius: '100%',
                backgroundColor: '#1D1F29',
                width: '24px',
                height: '24px',
                marginRight: '12px',
                display: 'block',
                float: 'left',
              }}
            >
              <Image
                src="https://storage.googleapis.com/estate-cdbx-io-public/emails/finger.png"
                width={24}
                height={24}
              />
            </span>
            <div style={{ float: 'left' }}>
              <p style={{ fontWeight: '600', marginTop: '0px', marginBottom: '4px' }}>Пароль</p>
              <p style={{ color: '#A7ACB0', margin: '0px' }}>{password}</p>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
    </EmailBody>
  )
}

export default SignUpEmail
