import React, { useRef } from 'react'
import Editor from '@toast-ui/react-image-editor'
import './ImageEditor.sass'
import 'tui-image-editor/dist/tui-image-editor.min.css'
import Backdrop from '../UI/Backdrop/Backdrop'
import Icon from '../UI/Icon/Icon'
import Button from '../../ui/Button/Button'

const ImageEditor = React.forwardRef(({ image, close, onEdit }, ref) => {
  const editorConfig = {
    loadImage: {
      path: image,
      name: 'SampleImage',
    },
    menu: ['rotate', 'flip', 'crop'],
    uiSize: {
      width: '800px',
      height: '500px',
    },
    menuBarPosition: 'bottom',
    // locale: editorLocale,
  }

  const editorRef = useRef()

  const onImageEdit = () => {
    const instance = editorRef.current.getInstance()
    const imageDataURL = instance.toDataURL()
    onEdit(imageDataURL)
    close()
  }

  return (
    <Backdrop show close={close}>
      <div className="ImageEditor">
        <div className="ImageEditor-CloseButton" onClick={close}>
          <Icon name="times" />
        </div>
        <Editor
          ref={editorRef}
          includeUI={editorConfig}
          cssMaxHeight={500}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
        <Button title="Сохранить" theme="solid" className="ImageEditor-SaveButton" onClick={onImageEdit} />
      </div>
    </Backdrop>
  )
})

export default ImageEditor
