import React from 'react'
import './SocialSignIn.sass'
import googleIcon from './google.svg'
import { firebase } from '../../../../config/firebase'
import getValuesFromPattern from '../../../../utilities/newforms/getValuesFromPattern'
import userDbForm from '../functions/UserDbForm'
import handleSignUpErrors from '../functions/handleSignUpErrors'
import { logError } from '../../../../utilities/db/logError'
import { setDoc } from '../../../../utilities/db/setDoc'

function SocialSignIn({ buttonTitle, deviderText, setStatusMessage }) {
  const googleProvider = new firebase.auth.GoogleAuthProvider()

  const onSubmit = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((result) => {
        if (result.additionalUserInfo.isNewUser === true) {
          const name = result.additionalUserInfo.profile.given_name
          const lastName = result.additionalUserInfo.profile.family_name
          const { uid, email, emailVerified } = result.user

          const values = getValuesFromPattern({
            formData: { name, lastName, email, emailVerified, signUpType: 'social' },
            formPattern: userDbForm,
          })

          setDoc({ path: 'users', docId: uid, data: values })
        }
      })
      .catch((e) => {
        const errorMessage = handleSignUpErrors({ code: e.code })
        if (errorMessage) {
          setStatusMessage({
            show: true,
            type: 'fail',
            message: errorMessage,
            closeAfter: 5000,
          })
        } else {
          logError({ error: e })
        }
        console.log('🚀 ~ file: SocialSignIn.jsx ~ line 29 ~ onSubmit ~ e', e)
      })
  }

  return (
    <div className="Site-SocialSignIn">
      <button type="button" onClick={onSubmit} className="SocialSignInButton">
        <img src={googleIcon} alt="Google" />
        {buttonTitle}
      </button>
      <div className="Site-SocialSignInDevider Site-DescriptionText">
        <span />
        {deviderText}
        <span />
      </div>
    </div>
  )
}

export default SocialSignIn
