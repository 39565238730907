import React, { useState, useEffect, useRef } from 'react'
import './RangePicker.sass'
import { withRouter } from 'react-router-dom'
import formatDate from './functions/formatDate'

import getRangePickerObject from '../RangePicker/functions/getRangePickerObject'
import { getUrlQueryParams, removeUrlQueryParams } from '../../../../utilities/queries/'
import { usePopUp } from '../../../../hooks/usePopUp'

import Icon from '../../../../components/UI/Icon/Icon'
import CustomDatePicker, { getDatePickerValuesFromUrlParams } from './CustomDatePicker'
import merge from 'lodash/merge'

function RangePicker({
  type,
  icon,
  dateRange,
  setDateRange: pushDateRange,
  pickDateOnInit = false,
  pickType,
  listenUrl = true,
  history,
}) {
  const rangePickerRef = useRef()
  const datePickerRef = useRef()

  const [active, setActive] = useState(false)
  const [showPopUp] = usePopUp(rangePickerRef, datePickerRef, true)
  const rangePicker = merge({ type }, getRangePickerObject({ type }))

  const [label, setLabel] = useState(rangePicker.label)

  useEffect(() => {
    const { period } = getUrlQueryParams({ history, params: ['period'] })

    if (listenUrl) {
      if (period === type) {
        setActive(true)
        if (type === 'custom') {
          const datePicker = getDatePickerValuesFromUrlParams(history)
          if (datePicker.startDate && datePicker.endDate) {
            setLabel(`${formatDate(datePicker.startDate)} - ${formatDate(datePicker.endDate)}`)
          }
          return pushDateRange({ ...rangePicker, startingDate: datePicker.startDate, endingDate: datePicker.endDate })
        }
        return pushDateRange(rangePicker)
      }

      if (!period && pickDateOnInit) {
        setActive(true)
        return pushDateRange(rangePicker)
      }
    }
  }, [])

  useEffect(() => {
    const { period } = getUrlQueryParams({ history, params: ['period'] })
    if (listenUrl) {
      setActive(period === type)
    }

    if (period !== 'custom') {
      history.push(removeUrlQueryParams({ history, params: ['startDate', 'endDate'] }))
      setLabel(rangePicker.label)
    }
  }, [history.location.search])

  const handleRangePickerClick = () => {
    pushDateRange(rangePicker)
  }

  return (
    <div className="RangePicker-Container">
      <span
        ref={rangePickerRef}
        className={['RangePicker', ...(active ? ['RangePicker_status_active'] : [])].join(' ')}
        onClick={handleRangePickerClick}
      >
        {icon && <Icon name={icon} />}
        {label}
      </span>
      {type === 'custom' && showPopUp && (
        <div ref={datePickerRef}>
          <CustomDatePicker
            listenUrl={listenUrl}
            dateRange={dateRange}
            rangePicker={rangePicker}
            pickType={pickType}
            setLabel={setLabel}
            pushDateRange={pushDateRange}
          />
        </div>
      )}
    </div>
  )
}

export default withRouter(RangePicker)
