import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import rootPath from '../../appTree/rootPath'
import News from './components/News/News'
import Feeds from './components/Feeds/Feeds'

function SiteRoot({ accountId }) {
  return (
    <Switch>
      <Route path={`${rootPath(accountId)}/settings/site/news`} render={() => <News accountId={accountId} />} />
      <Route path={`${rootPath(accountId)}/settings/site/feeds`} render={() => <Feeds accountId={accountId} />} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(SiteRoot)
