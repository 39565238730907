const isResponsibleFilterAvailable = (uid) => {
  if (
    uid !== 'PztXVNmC0DhPLvTBDik47PmMgN72' &&
    uid !== 'fZJiAtrrzkcFDzYIod01ECsmfvB3' &&
    uid !== 'WyM4fWWg3RPnh5KnmPHt7MSJM0o2' &&
    uid !== '3ixNhtG8oSOkJZK03ArVicVvAGE2' &&
    uid !== 'zqsPQ9EJ2XVeZoDwpYQlV6Tkxgb2'
  )
    return false

  return true
}

export default isResponsibleFilterAvailable
