import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { functions } from '../../../config/firebase'

const sendEmailApiRequest = ({ method, request, server = false }) => {
  const requestObject = {
    api_key: '69ma9tm6drsq8airdsh5za46hbym8dfj7ck8bibe',
    ...request,
  }
  const data = serialize(requestObject, { indices: true })

  if (server) {
    const func = functions.httpsCallable('serviceRequestOnCall')
    return func({
      url: `https://api.unisender.com/ru/api/${method}`,
      data: requestObject,
      useFormData: true,
    }).then((response) => response.data)
  }

  return axios({
    method: 'post',
    url: `https://api.unisender.com/ru/api/${method}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => response.data)
    .catch((e) => console.log(e))
}

export default sendEmailApiRequest
