import { useState, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import updateTask from '../../../../functions/updateTask'
import { firebase } from '../../../../../../../../config/firebase'

const useDropdownOptions = (
  accountId,
  taskId,
  taskDataField,
  initialOptions,
  loadComponentOptions,
  notEmptyOptions = false,
  uid,
  returnValueCb,
  returnOptionsCb,
  onUpdateDoneCb,
) => {
  const [isBusy, setIsBusy] = useState(false)
  const [options, setOptions] = useState([])
  const [activeOptions, setActiveOptions] = useState(prepareActiveOptions(initialOptions))

  useEffect(() => {
    loadComponentOptions().then((opt) => {
      if (returnOptionsCb) returnOptionsCb(opt)
      setOptions(markActiveOptions(opt, activeOptions))
    })
  }, [])

  useEffect(() => {
    if (returnValueCb) {
      const preparedOptions = prepareActiveOptions(initialOptions)
      if (!isEqual(preparedOptions, activeOptions)) {
        setActiveOptions(preparedOptions)
        markActiveOptions(options, preparedOptions)
      }
    }
  }, [initialOptions])

  const optionClicked = (option) => {
    if (isBusy) return

    const { value, isStandalone } = option

    if (activeOptions.includes(value)) {
      if (notEmptyOptions && activeOptions.length === 1) return

      const newActiveOptions = activeOptions.filter((t) => t !== value)
      setOptions(markActiveOptions(options, newActiveOptions))
      setActiveOptions(newActiveOptions)

      if (returnValueCb) return returnValueCb(newActiveOptions, option)

      updateTask({
        accountId,
        taskId,
        taskData: { [taskDataField]: firebase.firestore.FieldValue.arrayRemove(value) },
        uid,
      }).then((taskId) => {
        if (onUpdateDoneCb) onUpdateDoneCb(taskId, value)
      })
    } else {
      let activeOptionsClone = cloneDeep(activeOptions)

      if (isStandalone) {
        activeOptionsClone = [value]

        if (returnValueCb) return returnValueCb(activeOptionsClone, option)

        updateTask({ accountId, taskId, taskData: { [taskDataField]: activeOptionsClone }, uid }).then((taskId) => {
          if (onUpdateDoneCb) onUpdateDoneCb(taskId, value)
        })
      } else if (activeOptionsClone.includes('all')) {
        activeOptionsClone = [value]

        if (returnValueCb) return returnValueCb(activeOptionsClone, option)

        updateTask({ accountId, taskId, taskData: { [taskDataField]: activeOptionsClone }, uid }).then((taskId) => {
          if (onUpdateDoneCb) onUpdateDoneCb(taskId, value)
        })
      } else {
        activeOptionsClone.push(value)

        if (returnValueCb) return returnValueCb(activeOptionsClone, option)

        updateTask({
          accountId,
          taskId,
          taskData: { [taskDataField]: firebase.firestore.FieldValue.arrayUnion(value) },
          uid,
        }).then((taskId) => {
          if (onUpdateDoneCb) onUpdateDoneCb(taskId, value)
        })
      }
      setOptions(markActiveOptions(options, activeOptionsClone))
      setActiveOptions(activeOptionsClone)
    }
  }

  return { options, optionClicked, setIsBusy }
}

const prepareActiveOptions = (initialOptions) => initialOptions.options.map((o) => o[initialOptions.valueField])

const markActiveOptions = (options, activeOptions) => {
  const optionsWithActiveStatus = options.map((opt) => {
    if (activeOptions.includes(opt.value)) {
      opt.active = true
    } else {
      opt.active = false
    }
    return opt
  })
  return optionsWithActiveStatus
}

export default useDropdownOptions
