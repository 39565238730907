const countComission = (data, castAsResult = false) => {
  let moneyCounter = 0
  let totalCounter = 0

  data.forEach((d) => {
    totalCounter += 1
    moneyCounter += d.comission
    if (castAsResult) {
      moneyCounter -= d.factComission
    }
  })
  return { moneyCounter, totalCounter }
}

export const countFactComission = ({ deals }) => {
  let moneyCounter = 0
  let totalCounter = 0

  deals.forEach((d) => {
    totalCounter += 1
    moneyCounter += d.factComission
  })
  return { moneyCounter, totalCounter }
}

export default countComission
