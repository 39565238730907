import React from 'react'
import './SiteSectionTitle.sass'

function SiteSectionTitle({ title, subTitle }) {
  return (
    <div className="Site-SectionTitle-Container">
      <h2 className="Site-SectionTitle">{title}</h2>
      {subTitle && <p className="Site-SectionSubTitle">{subTitle}</p>}
    </div>
  )
}

export default SiteSectionTitle
