import {
  createdObject,
  updatedObject,
  deletedObject,
  createdForm,
  setField,
  updateField,
  deleteField,
  setFormField,
  updateFormField,
  deleteFormField,
  removedField,
  changedField,
} from '../../../../../History/events/renderDefaultEvent'
import { getContactObject } from '../functions/'

export default function renderByTypeSet(objId, subtype, fieldLabel, newValue, oldValue, form) {
  switch (subtype) {
    case 'createdHistory':
      return createdObject('Создание контакта:', getContactObject(objId))
    case 'setField':
      return setField(fieldLabel, newValue)
    case 'updateField':
      return updateField(fieldLabel, oldValue, newValue)
    case 'deleteField':
      return deleteField(fieldLabel, oldValue)
    default:
      break
  }
}
