import React, { useRef, useState } from 'react'
import { createForm } from '../../../../../../utilities/newforms/createForm'
import './AccountForm.sass'
import accountDbForm from '../../AccountDbForm'
import { connect } from 'react-redux'
import syncForm from '../../../../../../utilities/newforms/changed/syncForm'
import FieldRender from '../../../../../../utilities/newforms/render/FieldRender'
import AccountIdentifierField from './AccountIdentifierField'
import DropTile from '../../../../Auth/ForgotPassword/DropTile/DropTile'
import Button from '../../../../../../ui/Button/Button'
import getFormValues from '../../../../../../utilities/newforms/getFormValues'
import StatusMessage, { useStatusMessage } from '../../../../../../ui/StatusMessage/StatusMessage'
import install from '../../../../../../install'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../config/firebase'

function AccountForm({ user, close }) {
  const [form, setForm] = useState(createForm({ formPattern: accountDbForm }))
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const afRef = useRef()

  const onSubmit = (e) => {
    e.preventDefault()
    const values = getFormValues({ form })
    const { identifier, solution, title, withData } = values

    if (!identifier) {
      afRef.current.scrollIntoView({ behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, укажите идентификатор аккаунта',
        closeAfter: 5000,
      })
    }
    if (!solution) {
      afRef.current.scrollIntoView({ behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, выберите решение',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    install({ title, identifier, user, solution, withData }).then((accountId) => {
      if (user.systemRole !== 'god') {
        return updateDoc({
          path: 'users',
          docId: user.uid,
          data: { accounts: firebase.firestore.FieldValue.arrayUnion(accountId) },
        }).then(() => {
          setIsLoading(false)
          close()
        })
      }
      setIsLoading(false)
      close()
    })
  }

  return (
    <div ref={afRef} className="Site-CreateAccount-Form Site-Fields">
      <h5 className="Site-SubsectionTitle Site-ProfileTitle">Создание аккаунта</h5>
      {statusMessage.show && (
        <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
      )}

      <FieldRender field={{ ...form.title, fieldId: 'title' }} form={form} setForm={setForm} />
      <AccountIdentifierField
        pushState={(identifier) => syncForm({ propIdentifier: 'identifier', form, setForm, newForm: identifier })}
      />
      <FieldRender field={{ ...form.withData, fieldId: 'withData' }} form={form} setForm={setForm} />
      <p className="Site-LinkText">Выберите версию системы</p>
      <DropTile
        isActive={form.solution.values === 'sales'}
        icon="dollar-sign"
        title="CRM для отделов продаж"
        description="Класический вариант CRM системы для любой компаний, где есть отдел продаж. Входит во все отраслевые решения."
        onClick={() => syncForm({ propIdentifier: 'solution', form, setForm, newForm: 'sales' })}
      />
      <DropTile
        isActive={form.solution.values === 'estate'}
        icon="building"
        title="CRM для агентств недвижимости"
        description="Полноценное узкоспециализированное решение для агентств недвижимости с предустановленными модулями по работе с объектами."
        onClick={() => syncForm({ propIdentifier: 'solution', form, setForm, newForm: 'estate' })}
      />

      <div className="ConfirmationForm-Buttons">
        <Button
          type="submit"
          className="AuthForm-Button"
          icon="check"
          title="Создать аккаунт"
          fill="black"
          theme="solid"
          isLoading={isLoading}
          onClick={onSubmit}
          size={48}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(AccountForm)
