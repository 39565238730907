import cloneDeep from 'lodash/cloneDeep'

const clearFieldValue = (field) => {
  const fieldClone = cloneDeep(field)

  fieldClone.value = ''
  fieldClone.valid = false
  fieldClone.error = ''
  fieldClone.touched = false

  return fieldClone
}
export default clearFieldValue
