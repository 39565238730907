import React, { useState } from 'react'
import './ForgotPassword.sass'
import TechPage from '../../SitePage/TechPage'
import { SignPageNav } from '../SignPage/SignPage'
import Icon from '../../../../components/UI/Icon/Icon'
import EmailRecover from './EmailRecover'
import PhoneRecover from './PhoneRecover'
import Button from '../../../../ui/Button/Button'
import NewPasswordForm from './NewPasswordForm'

function ForgotPassword() {
  const [recoverType, setRecoverType] = useState(null)
  const [showNewPasswordFormFor, setShowNewPasswordFormFor] = useState(false)

  return (
    <TechPage nav={<SignPageNav nav="signin" />}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="ConfirmationForm-Container">
              <div className="ConfirmationForm">
                {showNewPasswordFormFor ? (
                  <NewPasswordForm uid={showNewPasswordFormFor} />
                ) : (
                  <>
                    <div className="AuthForm-Header">
                      <h5 className="AuthForm-Title">Забыли пароль?</h5>
                      <p className="AuthForm-Description">Выберите способ восстановления</p>
                    </div>
                    {(!recoverType || recoverType === 'email') && (
                      <EmailRecover
                        isActive={recoverType === 'email'}
                        onClick={setRecoverType}
                        setShowNewPasswordFormFor={setShowNewPasswordFormFor}
                      />
                    )}
                    {(!recoverType || recoverType === 'phone') && (
                      <PhoneRecover
                        isActive={recoverType === 'phone'}
                        onClick={setRecoverType}
                        setShowNewPasswordFormFor={setShowNewPasswordFormFor}
                      />
                    )}
                    {recoverType && (
                      <>
                        <Button
                          title="Назад"
                          theme="transparent-hug"
                          icon="angle-left"
                          iconWeight="regular"
                          onClick={() => setRecoverType(null)}
                          className="ConfirmationForm-BackButton"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TechPage>
  )
}

export default ForgotPassword
