import React from 'react'
import './Module.sass'
import { Route, withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'

function Module({ path, component: Component, subsections, permissions, options, ...router }) {
  return (
    <>
      {Component ? (
        isEmpty(subsections) ? (
          <Route path={path} render={() => <Component permissions={permissions} options={options} />} />
        ) : (
          <Component subsections={subsections} />
        )
      ) : (
        subsections.map((scene) => (
          <Route
            key={scene.path}
            path={scene.path}
            render={() => (
              <scene.component subsections={subsections} permissions={scene.permissions} options={scene.options} />
            )}
          />
        ))
      )}
    </>
  )
}

export default withRouter(Module)
