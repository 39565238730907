import React from 'react'
import SiteSectionTitle from '../SiteSectionTitle/SiteSectionTitle'
import './AboutEstate.sass'
import estatePattern from './assets/estatePattern.svg'
import ContactsRender from './assets/contacts.svg'
import EstateRender from './assets/estate.svg'
import TasksRender from './assets/tasks.svg'
import MembersRender from './assets/members.svg'
import AnalyticsRender from './assets/analytics.svg'
import PlaningRender from './assets/planing.svg'
import DialerRender from './assets/dialer.svg'
import parse from 'html-react-parser'
import underCutterWhite from '../../pages/SitePage/assets/underCutterWhite.svg'

function AboutEstate() {
  const info = [
    {
      renderPos: 'left',
      renderSrc: ContactsRender,
      title: 'Удобная единая база контактов',
      text: `Простая и удобная база клиентов с быстрым поиском.<br/>
      Добавляйте новые контакты, ставьте пометки и комментарии.<br/>
      Система будет хранить всю хронологию событий по контакту, записи телефонных разговоров, задачи и сделки.`,
    },
    {
      renderPos: 'right',
      renderSrc: EstateRender,
      title: 'Простая работа с объектами',
      text: `Все объекты у вас под рукой.<br/>
      Быстрый поиск объектов по заданным параметрам из собственной базы, также вам подтянутся объекты по заданным параметрам со всех досок объявлений, все это в едином окне.<br/>
      Легко заносить объекты в базу еще легче выгружать собственные объекты из базы на нужные площадки, сайты, социальные сети и доски объявлений в 1 клик.`,
    },
    {
      renderPos: 'left',
      renderSrc: TasksRender,
      title: 'Контроль всех задач',
      text: `Звонки, задачи, выезды, показы – полный контроль над всеми процессами.<br/>
      Планируйте и ставьте задачи, отслеживайте результаты, анализируйте работу сотрудников.<br/>
      С нами вы не потеряете ни одного клиента, CardBox автоматически фиксирует все заявки, на каждую заявку назначает задачу и напоминает вам о ней в нужный момент.<br/>
      С CardBox вам не придется пользоваться сторонними тудулистами.`,
    },
    {
      renderPos: 'right',
      renderSrc: MembersRender,
      title: 'Сотрудники',
      text: `Настраивайте любые уровни доступа. Создавайте отделы. Назначайте руководителей. Отслеживайте эффективность каждого сотрудника.`,
    },
    {
      renderPos: 'left',
      renderSrc: AnalyticsRender,
      title: 'Глубокая аналитика всех процессов',
      text: `Дает полное понимание о работе агентства.<br/>
      Отслеживайте все показатели отделов и сотрудников, сверяйтесь с поставленными планами, анализируйте работу агентов, планируйте и сравнивайте периоды.<br/>
      CardBox покажет вам все слабые места в вашем агентстве.`,
    },
    {
      renderPos: 'right',
      renderSrc: PlaningRender,
      title: 'Финансовое планирование',
      text: `Отчеты о движениях денежных средств.<br/>
      Планируйте и отслеживайте все поступления и расходы. Контролируйте выполнения планов. CardBox предупредит о возможных кассовых разрывах и предупредит вас об этом.`,
    },
    {
      renderPos: 'left',
      renderSrc: DialerRender,
      title: 'Телефония',
      text: `Настроены интеграции со всеми операторами мобильной связи.<br/>
      Фиксация всех телефонных звонков с записями разговоров.<br/>
      Удобный телефонный бар автоматически создает карточку нового клиента или откроет карточку существующего клиента со всей информацией по нему.`,
    },
  ]

  return (
    <div
      id="features"
      className="Site-AboutEstate"
      style={{ backgroundImage: `url(${estatePattern}), url(${underCutterWhite})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SiteSectionTitle title="Наши возможности" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Site-AboutEstate-Container">
              {info.map((b) => (
                <InfoBlock key={b.title} {...b} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const InfoBlock = ({ renderPos = 'left', renderSrc, title, text }) => (
  <div className={`InfoBlock InfoBlock_renderPostion_${renderPos}`}>
    <img className="InfonBlock-Render" src={renderSrc} alt={title} />
    <div className="InfoBlock-Content">
      <h5 className="InfoBlock-Title Site-SubsectionTitle">{title}</h5>
      <p className="InfoBlock-Description">{parse(text)}</p>
    </div>
  </div>
)

export default AboutEstate
