import { cloneDeep, find, isEmpty, isNull } from 'lodash'
import './TagCloud.sass'
import React, { useEffect, useRef, useState } from 'react'
import ReactTags from 'react-tag-autocomplete'
import { WatchAttributes } from 'react-mutation-observer'
import uniqid from 'uniqid'
import Icon from '../UI/Icon/Icon'
import { useCallbacks } from '../../utilities/shared'
import { db } from '../../config/firebase'
import tagObject from './tagObject'
import setDoc from '../../../functions/utilities/db/setDoc'

function TagCloud({ initialTags = [], suggestionsPath, syncState = () => null, allowNewTags = false, disableEdit }) {
  const tagCloudRootRef = useRef()
  const tagCloudRef = useRef()

  const [tags, setTags] = useState([])
  const [suggestions, setSuggestions] = useState(null)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [onEdit, setOnEdit] = useState(false)

  const delimiters = ['Enter', 'Tab', 'Comma']

  useEffect(() => {
    const listener = db.collection(suggestionsPath).onSnapshot((snap) => {
      const data = []
      snap.forEach((s) => data.push({ tagId: s.id, ...s.data() }))
      setSuggestions(data)
    })
    return () => listener()
  }, [])

  useEffect(() => {
    if (!isNull(suggestions)) {
      let updatedTags = []
      if (isEmpty(tags)) {
        updatedTags = initialTags
          .filter((t) => find(suggestions, ['tagId', t]))
          .map((t) => find(suggestions, ['tagId', t]))
      } else {
        updatedTags = tags
          .filter((t) => find(suggestions, ['tagId', t.tagId]))
          .map((t) => find(suggestions, ['tagId', t.tagId]))
      }
      setTags(updatedTags)
    }
  }, [suggestions])

  useEffect(() => {
    if (!isNull(suggestions)) {
      syncState(tags)
      setFilteredSuggestions(suggestions.filter((s) => !find(tags, ['tagId', s.tagId])))
    }
  }, [tags])

  const onDelete = (i) => {
    if (onEdit) setTags(tags.filter((tag, index) => index !== i))
  }

  const onAdd = (tag) => {
    const tagsClone = cloneDeep(tags)
    if (!find(tagsClone, ['tagId', tag.tagId])) {
      if (!tag.tagId) {
        const tagId = uniqid('autoTag-')
        tag = tagObject({ name: tag.name })
        setDoc({ db, path: suggestionsPath, docId: tagId, data: tag })
        tag.tagId = tagId
      }
      tagsClone.push(tag)
      setTags(tagsClone)
      setTimeout(() => setOnEdit(true), 100)
    }
  }

  const classNames = {
    root: [
      'TagCloud',
      ...(isEmpty(tags) ? ['TagCloud-EmptyTags'] : []),
      ...(disableEdit ? ['TagCloud-DisableEdit'] : []),
    ].join(' '),
    selected: 'TagCloud-Container',
    // rootFocused: 'TagCloud-OnFocuse',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'TagCloud-InputContainer',
    searchWrapper: 'TagCloud-InputWrapper',
    searchInput: 'TagCloud-Input',
    suggestions: 'TagCloud-Suggestions',
    suggestionActive: 'TagCloud-SuggestionActive',
    suggestionDisabled: 'is-disabled',
  }

  useEffect(() => {
    document.addEventListener('mousedown', handlers.handleClickOutside)
    return () => document.removeEventListener('mousedown', handlers.handleClickOutside)
  }, [])

  const handlers = useCallbacks((callbacks) => {
    callbacks.handleClickOutside = (event) => {
      if (
        !disableEdit &&
        tagCloudRootRef &&
        tagCloudRootRef.current &&
        tagCloudRootRef.current.contains(event.target)
      ) {
        return setOnEdit(true)
      }

      return setOnEdit(false)
    }
  })

  useEffect(() => {
    if (tagCloudRootRef && tagCloudRootRef.current) {
      if (onEdit) {
        tagCloudRootRef.current.classList.add('TagCloud-OnFocuse')
      } else {
        tagCloudRootRef.current.classList.remove('TagCloud-OnFocuse')
      }
    }
  }, [onEdit])

  return (!isNull(suggestions) && !disableEdit) || (disableEdit && !isEmpty(tags)) ? (
    <div ref={tagCloudRootRef} className="TagCloud-Root">
      <ReactTags
        ref={tagCloudRef}
        tags={tags}
        suggestions={filteredSuggestions}
        onDelete={onDelete}
        onAddition={onAdd}
        classNames={classNames}
        delimiters={delimiters}
        tagComponent={(props) => <TagComponent {...props} onEdit={onEdit} />}
        noSuggestionsText="Нет подходящих тегов"
        placeholderText="Добавить тег..."
        minQueryLength={0}
        suggestionComponent={SuggestionComponent}
        allowNew={allowNewTags}
      />
    </div>
  ) : null
}

function SuggestionComponent({ item }) {
  return <span id={item.id}>{item.name}</span>
}

export const TagComponent = React.forwardRef(({ tag, onDelete = () => null, onEdit = false }, ref) => (
  <div
    className="TagCloud-TagComponent"
    style={{
      ...(tag.backgroundColor ? { backgroundColor: tag.backgroundColor, border: '0px' } : {}),
      ...(tag.textColor ? { color: tag.textColor } : {}),
    }}
    onClick={onDelete}
  >
    {tag.icon && (
      <Icon
        name={tag.icon}
        weight="solid"
        className="TagComponent-Icon"
        style={{ ...(tag.iconColor ? { color: tag.iconColor } : {}) }}
      />
    )}{' '}
    <span>{tag.name}</span>
    {onEdit && (
      <div className="TagComponent-RemoveIcon" onClick={onDelete}>
        <Icon name="times" />
      </div>
    )}
  </div>
))

export default TagCloud
