import getFieldRenderObject from '../../../../../../../utilities/newforms/render/getFieldRenderObject'

const manageOptions = [
  { value: 'denied', color: '#F34375', label: 'Запрещено' },
  { value: 'responsible', color: '#FFD80A', label: 'Если ответственный' },
  { value: 'squad', color: '#0775F8', label: 'Отдел' },
  { value: 'office', color: '#0AF0FF', label: 'Офис' },
  { value: 'all', color: '#27C043', label: 'Все' },
]

const createOptions = [
  { value: false, color: '#F34375', label: 'Запрещено' },
  { value: true, color: '#27C043', label: 'Разрешено' },
]

const settingsOptions = [
  { value: 'denied', color: '#F34375', label: 'Запрещено' },
  { value: 'allow', color: '#27C043', label: 'Разрешено' },
]

const roleObject = (solution) => ({
  title: {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      label: 'Название роли',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  occupation: {
    field: {
      fieldId: 'occupation',
      fieldType: 'input',
      inputType: 'text',
      label: 'Описание должности',
      required: false,
    },
    render: getFieldRenderObject(),
  },
  modules: {
    field: {
      fieldId: 'modules',
      value: [
        {
          moduleId: 'dashboard',
          label: 'Дашборд',
          subsections: [
            {
              subsectionId: 'dashboard',
              label: 'Дашборд',
              permissions: {
                access: {
                  label: 'Доступ к данным',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
              },
            },
          ],
        },
        {
          moduleId: 'contacts',
          label: 'Контакты',
          subsections: [
            {
              subsectionId: 'contactlist',
              label: 'Контакты',
              permissions: {
                access: {
                  label: 'Доступ к данным',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                add: {
                  label: 'Создание',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
                edit: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                delete: {
                  label: 'Удаление',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
              },
              options: {
                createTag: {
                  label: 'Создание и редактирование тегов',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
              },
            },
            {
              subsectionId: 'organizationslist',
              label: 'Организации',
              permissions: {
                access: {
                  label: 'Доступ к данным',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                add: {
                  label: 'Создание',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
                edit: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                delete: {
                  label: 'Удаление',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
              },
              options: {
                createTag: {
                  label: 'Создание и редактирование тегов',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
              },
            },
          ],
        },
        {
          moduleId: 'deals',
          label: 'Сделки',
          subsections: [
            {
              subsectionId: 'dealslist',
              label: 'Контакты',
              permissions: {
                access: {
                  label: 'Доступ к данным',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                add: {
                  label: 'Создание',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
                edit: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                delete: {
                  label: 'Удаление',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
              },
              options: {
                createTag: {
                  label: 'Создание и редактирование тегов',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
              },
            },
          ],
        },
        {
          moduleId: 'tasks',
          label: 'Задачи',
          subsections: [
            {
              subsectionId: 'tasklist',
              label: 'Задачи',
              permissions: {
                access: {
                  label: 'Доступ к данным',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                add: {
                  label: 'Создание',
                  showParam: true,
                  value: false,
                  options: createOptions,
                },
                edit: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
                delete: {
                  label: 'Удаление',
                  showParam: true,
                  value: 'denied',
                  options: manageOptions,
                },
              },
            },
          ],
        },
        ...(solution === 'estate'
          ? [
              {
                moduleId: 'estate',
                label: 'Объекты',
                subsections: [
                  {
                    subsectionId: 'estatelist',
                    label: 'Объекты',
                    permissions: {
                      access: {
                        label: 'Доступ к данным',
                        showParam: true,
                        value: 'denied',
                        options: manageOptions,
                      },
                      add: {
                        label: 'Создание',
                        showParam: true,
                        value: false,
                        options: createOptions,
                      },
                      edit: {
                        label: 'Редактирование',
                        showParam: true,
                        value: 'denied',
                        options: manageOptions,
                      },
                      delete: {
                        label: 'Удаление',
                        showParam: true,
                        value: 'denied',
                        options: manageOptions,
                      },
                      contactBlock: {
                        label: 'Отображение контактов объекта',
                        showParam: true,
                        value: 'denied',
                        options: manageOptions,
                      },
                    },
                    options: {
                      createTag: {
                        label: 'Создание и редактирование тегов',
                        showParam: true,
                        value: false,
                        options: createOptions,
                      },
                    },
                  },
                ],
              },
            ]
          : []),
        {
          moduleId: 'settings',
          label: 'Настройки',
          subsections: [
            {
              subsectionId: 'offices',
              label: 'Офисы',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'members',
              label: 'Сотрудники',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'salesPlans',
              label: 'Планы продаж',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'roles',
              label: 'Системные роли',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'siteNews',
              label: 'Новости',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'siteFeeds',
              label: 'Отзывы',
              permissions: {
                access: {
                  label: 'Редактирование',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
            {
              subsectionId: 'appsMarket',
              label: 'Магазин приложений',
              permissions: {
                access: {
                  label: 'Доступ к приложениям',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
                install: {
                  label: 'Установка / удаление',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
                config: {
                  label: 'Изменение настроек',
                  showParam: true,
                  value: 'denied',
                  options: settingsOptions,
                },
              },
            },
          ],
        },
      ],
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  created: {
    field: {
      fieldId: 'created',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  members: {
    field: {
      fieldId: 'members',
      required: false,
      value: [],
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
})

export default roleObject
