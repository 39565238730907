import React, { useState, useEffect } from 'react'
import { db } from '../../../config/firebase'
import './ParamsManagerConfigurator.sass'
import CustomSceneTable from '../../../components/UI/SceneContent/SceneTable/CustomSceneTable'
import SceneContent from '../../../components/UI/SceneContent/SceneContent'
import getCollection from '../../../utilities/db/getCollection'
import { merge, orderBy, find } from 'lodash'
import RadioGroup from '../../../ui/RadioGroup/RadioGroup'
import ParamPopUp from './ParamPopUp/ParamPopUp'
import CategoryPopUp from './CategoryPopUp/CategoryPopUp'
import OptionsButton from '../../../ui/OptionsButton/OptionsButton'
import DeleteCategoryPopUp from './CategoryPopUp/DeleteCategoryPopUp'
import DeleteParamPopUp from './ParamPopUp/DeleteParamPopUp'
import fetchParamType from './functions/fetchParamType'
import { connect } from 'react-redux'
import onParamStatusChanged from './functions/onParamStatusChanged'
import onCategoryStatusChanged from './functions/onCategoryStatusChanged'
import Icon from '../../../components/UI/Icon/Icon.js'
import onParamPositionChanged from './functions/onParamPositionChanged'
import onParamCategoryPositionChanged from './functions/onParamCategoryPositionChanged'
import rootDbPath from '../../../utilities/db/rootDbPath'

function ParamsManagerConfigurator({ uid, accountId }) {
  const [estateCategories, setEstateCategories] = useState(null)
  const [params, setParams] = useState(null)
  const [paramsCategories, setParamsCategories] = useState(null)
  const [activeRow, setActiveRow] = useState(null)
  const [categoryManager, setCategoryManager] = useState(null)
  const [paramManager, setParamManager] = useState(null)

  useEffect(() => {
    getCollection({ path: `${rootDbPath(accountId)}/config/estate/categories`, docIdName: 'estateCategoryId' })
      .then((categories) =>
        Promise.all(
          categories.map((c) =>
            getCollection({
              path: `${rootDbPath(accountId)}/config/estate/types`,
              docIdName: 'typeId',
            }).then((types) => merge(c, { types })),
          ),
        ),
      )
      .then((fetchedCategories) => {
        const orderedCategories = orderBy(
          fetchedCategories.map((category) => {
            category.types = orderBy(category.types, ['label'], ['asc'])
            return category
          }),
          ['label'],
          ['asc'],
        )
        setEstateCategories(orderedCategories)
      })

    const paramsCategoriesListener = db
      .collection(`${rootDbPath(accountId)}/config/estate/paramsCategories`)
      .orderBy('position')
      .onSnapshot((docs) => {
        const arr = []
        docs.forEach((d) => {
          arr.push({ categoryId: d.id, ...d.data() })
        })
        setParamsCategories(arr)
      })

    const paramsListener = db.collection(`${rootDbPath(accountId)}/config/estate/params`).onSnapshot((docs) => {
      const arr = []
      docs.forEach((d) => {
        arr.push({ paramId: d.id, ...d.data() })
      })
      setParams(arr)
    })

    return () => {
      paramsCategoriesListener()
      paramsListener()
    }
  }, [])

  return estateCategories && params && paramsCategories ? (
    <div className="Module-Container">
      {categoryManager && (categoryManager.operation === 'add' || categoryManager.operation === 'edit') && (
        <CategoryPopUp category={categoryManager} close={() => setCategoryManager(null)} />
      )}
      {categoryManager && categoryManager.operation === 'delete' && (
        <DeleteCategoryPopUp accountId={accountId} category={categoryManager} close={() => setCategoryManager(null)} />
      )}
      {paramManager && (paramManager.operation === 'add' || paramManager.operation === 'edit') && (
        <ParamPopUp categoryId={paramManager.categoryId} param={paramManager} close={() => setParamManager(null)} />
      )}
      {paramManager && paramManager.operation === 'delete' && (
        <DeleteParamPopUp accountId={accountId} param={paramManager} close={() => setParamManager(null)} />
      )}
      <SceneContent
        title="Менеджер параметров"
        buttons={[
          {
            type: 'add',
            icon: 'plus',
            clicked: () => setCategoryManager({ operation: 'add' }),
          },
        ]}
      >
        <div className="ParamsManagerConfigurator-Container">
          <CustomSceneTable
            header={
              <>
                <tr>
                  <th rowSpan={2} className="PMC-Header-Top PMC-Header-Top-Devider StickyColumnLeft" />
                  {estateCategories.map((estateCategory, pos) => (
                    <th
                      key={estateCategory.categoryId}
                      className={`PMC-Header-Top ${pos !== estateCategories.length - 1 && 'PMC-Header-Top-Devider'}`}
                      colSpan={estateCategory.types.length}
                    >
                      {estateCategory.label}
                    </th>
                  ))}
                  <th rowSpan={2} className="PMC-Header-Top StickyColumnRight PMC-Text-Center">
                    Опции
                  </th>
                </tr>
                <tr className="PMC-Header-Last">
                  {estateCategories.map((estateCategory, pos) =>
                    estateCategory.types.map((type, i) => (
                      <th
                        className={
                          i === estateCategory.types.length - 1 &&
                          pos !== estateCategories.length - 1 &&
                          'PMC-Header-Top-Devider'
                        }
                      >
                        {type.label}
                      </th>
                    )),
                  )}
                </tr>
              </>
            }
            body={paramsCategories.map((paramCategory) => {
              const categoryParams = orderBy(
                params.filter((param) => param.categoryId === paramCategory.categoryId),
                ['position'],
              )
              return (
                <>
                  <tr
                    className={`RemoveBoxShadow ${activeRow === paramCategory.categoryId ? 'PMC-ActiveRow' : ''}`}
                    onClick={() => setActiveRow(paramCategory.categoryId)}
                    key={paramCategory.categoryId}
                  >
                    <td className="PMC-Header-Top PMC-Header-Top-Devider StickyColumnLeft">
                      <div className="PMC-Category">
                        <OptionsButton
                          options={[
                            {
                              icon: 'plus',
                              label: 'Доб. параметр',
                              clicked: () =>
                                setParamManager({ categoryId: paramCategory.categoryId, operation: 'add' }),
                            },
                            {
                              icon: 'pencil-alt',
                              label: 'Ред. категорию',
                              clicked: () => setCategoryManager({ ...paramCategory, operation: 'edit' }),
                            },
                            {
                              return: 'docId',
                              icon: 'trash-alt',
                              label: 'Удал. категорию',
                              clicked: () => setCategoryManager({ ...paramCategory, operation: 'delete' }),
                            },
                          ]}
                        />
                        {paramCategory.label}
                        <div className="PMC-ChangePosControlls">
                          <Icon
                            onClick={() =>
                              paramCategory.position === 0
                                ? ''
                                : onParamCategoryPositionChanged(
                                    paramsCategories,
                                    paramCategory.position,
                                    paramCategory.position - 1,
                                    uid,
                                    accountId,
                                  )
                            }
                            helperClass={paramCategory.position === 0 && 'PMC-ChangePosition_disabled'}
                            name="long-arrow-up"
                            weight="regular"
                          />
                          <Icon
                            onClick={() =>
                              paramCategory.position === paramsCategories.length - 1
                                ? ''
                                : onParamCategoryPositionChanged(
                                    paramsCategories,
                                    paramCategory.position,
                                    paramCategory.position + 1,
                                    uid,
                                    accountId,
                                  )
                            }
                            helperClass={
                              paramCategory.position === paramsCategories.length - 1 && 'PMC-ChangePosition_disabled'
                            }
                            name="long-arrow-down"
                            weight="regular"
                          />
                        </div>
                      </div>
                    </td>
                    {estateCategories.map((estateCategory) =>
                      estateCategory.types.map((type) => {
                        const estateTypeInParamCategoryConfig = find(paramCategory.estateTypes, {
                          estateTypeId: type.typeId,
                          estateCategoryId: estateCategory.estateCategoryId,
                        })

                        return (
                          <td className="PMC-Header-Top">
                            <RadioGroup
                              options={[
                                { value: 'hide', color: '#A7ACB0' },
                                { value: 'show', color: '#029E1E' },
                              ]}
                              onChange={(paramStatus) =>
                                onCategoryStatusChanged({
                                  paramCategoryId: paramCategory.categoryId,
                                  estateCategoryId: estateCategory.estateCategoryId,
                                  estateTypeId: type.typeId,
                                  status: paramStatus,
                                  uid,
                                  accountId,
                                })
                              }
                              value={estateTypeInParamCategoryConfig ? estateTypeInParamCategoryConfig.status : 'hide'}
                            />
                          </td>
                        )
                      }),
                    )}
                    <td className="PMC-Header-Top StickyColumnRight">
                      <div className="PMC-LabelsColumn">
                        <span>Не отображать</span>
                        <span>Отображать</span>
                      </div>
                    </td>
                  </tr>
                  {categoryParams.map((param, i) => (
                    <tr
                      onClick={() => setActiveRow(param.paramId)}
                      key={param.paramId}
                      className={activeRow === param.paramId && 'PMC-ActiveRow'}
                    >
                      <td className="PMC-WhiteBox PMC-Header-Top-Devider StickyColumnLeft">
                        <div className="PMC-Param">
                          <OptionsButton
                            options={[
                              {
                                return: 'docId',
                                icon: 'pencil-alt',
                                label: 'Редакт.',
                                clicked: () => setParamManager({ ...param, operation: 'edit' }),
                              },
                              {
                                return: 'docId',
                                icon: 'trash-alt',
                                label: 'Удалить',
                                clicked: () => setParamManager({ ...param, operation: 'delete' }),
                              },
                            ]}
                          />
                          <div className="PMC-ParamDescription">
                            <p className="ParamTitle">{param.label}</p>
                            <p className="ParamType">{fetchParamType(param.paramType)}</p>
                          </div>
                          <div className="PMC-ChangePosControlls">
                            <Icon
                              onClick={() =>
                                param.position === 0
                                  ? ''
                                  : onParamPositionChanged(categoryParams, param.position, param.position - 1, uid)
                              }
                              helperClass={param.position === 0 && 'PMC-ChangePosition_disabled'}
                              name="long-arrow-up"
                              weight="regular"
                            />
                            <Icon
                              onClick={() =>
                                param.position === categoryParams.length - 1
                                  ? ''
                                  : onParamPositionChanged(categoryParams, param.position, param.position + 1, uid)
                              }
                              helperClass={
                                param.position === categoryParams.length - 1 && 'PMC-ChangePosition_disabled'
                              }
                              name="long-arrow-down"
                              weight="regular"
                            />
                          </div>
                        </div>
                      </td>
                      {estateCategories.map((estateCategory) =>
                        estateCategory.types.map((type) => {
                          const estateTypeInParamConfig = find(param.estateTypes, {
                            estateTypeId: type.typeId,
                            estateCategoryId: estateCategory.estateCategoryId,
                          })
                          return (
                            <td>
                              <RadioGroup
                                options={[
                                  { value: 'hide', color: '#A7ACB0' },
                                  { value: 'show', color: '#029E1E' },
                                  { value: 'recomended', color: '#30CDFF' },
                                  { value: 'required', color: '#F34375' },
                                ]}
                                onChange={(paramStatus) =>
                                  onParamStatusChanged({
                                    paramId: param.paramId,
                                    status: paramStatus,
                                    estateCategoryId: estateCategory.estateCategoryId,
                                    estateTypeId: type.typeId,
                                    uid,
                                    accountId,
                                  })
                                }
                                value={estateTypeInParamConfig ? estateTypeInParamConfig.status : 'hide'}
                              />
                            </td>
                          )
                        }),
                      )}
                      <td className="PMC-WhiteBox StickyColumnRight">
                        <div className="PMC-LabelsColumn">
                          <span>Не отображать</span>
                          <span>Отображать</span>
                          <span>Рекомендуемый</span>
                          <span>Обязательный</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )
            })}
          />
        </div>
      </SceneContent>
    </div>
  ) : (
    ''
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(ParamsManagerConfigurator)
