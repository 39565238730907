import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../config/firebase'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'

const syncDealWithContactsAndCustomers = ({ accountId, docId, values, uid }) => {
  const { contact, customer } = values

  return promiseAllValues({
    contact: Promise.all(
      contact.map((c) => {
        switch (c.type) {
          case 'contact':
            return updateDoc({
              accountId,
              path: 'contacts',
              docId: c.id,
              data: { deals: firebase.firestore.FieldValue.arrayUnion(docId) },
              uid,
            })
          case 'organization':
            return updateDoc({
              accountId,
              path: 'organizations',
              docId: c.id,
              data: { deals: firebase.firestore.FieldValue.arrayUnion(docId) },
              uid,
            })
          default:
            return true
        }
      }),
    ),
    customer: Promise.all(
      customer.map((c) => {
        switch (c.type) {
          case 'contact':
            return updateDoc({
              accountId,
              path: 'contacts',
              docId: c.id,
              data: { deals: firebase.firestore.FieldValue.arrayUnion(docId) },
              uid,
            })
          case 'organization':
            return updateDoc({
              accountId,
              path: 'organizations',
              docId: c.id,
              data: { deals: firebase.firestore.FieldValue.arrayUnion(docId) },
              uid,
            })
          default:
            return true
        }
      }),
    ),
  })
}

export default syncDealWithContactsAndCustomers
