import React from 'react'
import './WhyWe.sass'
import SiteSectionTitle from '../SiteSectionTitle/SiteSectionTitle'
import TryFreeButton from '../TryFreeButton/TryFreeButton'

function WhyWe() {
  return (
    <div className="WhyWe container">
      <div className="row">
        <div className="col-12">
          <div className="WhyWe-Container">
            <SiteSectionTitle title="Почему мы?" />
            <p className="WhyWe-Text">
              При разработке CRM мы учли весь многолетний опыт и пожелания более 10 крупных агентств недвижимости. Мы
              знаем все болевые места и специально создали специализированное решение, которое систематизирует, упростит
              всю работу. С нашей системой у Вас появится больше времени и сделок!
            </p>
            <TryFreeButton size={48} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyWe
