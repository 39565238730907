import * as ACTION_TYPES from './actionTypes'
import { db } from '../../../../../config/firebase'
import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'

export const subscribeToNotifications = () => {
  return {
    type: ACTION_TYPES.NOTIFICATIONS_SUBSCRIBE,
  }
}

export const sendNotifications = data => {
  return {
    type: ACTION_TYPES.NOTIFICATIONS_SEND_DATA_TO_REDUCER,
    payload: data,
  }
}

export const clearAllNotifications = (notifications, uid) => {
  return dispatch => {
    removeSubscribers(notifications, uid)
    dispatch({ type: ACTION_TYPES.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS, payload: notifications })
  }
}

export const clearNotification = (notification, uid) => {
  return (dispatch, getState) => {
    const {
      notifications: { sortedNotifications: notifications },
    } = getState()
    const newNotifications = notifications.filter(n => n.notificationId !== notification.notificationId && true)

    removeSubscribers([notification], uid)
    dispatch({ type: ACTION_TYPES.NOTIFICATIONS_CLEAR_NOTIFICATION, payload: newNotifications })
  }
}

const removeSubscribers = async (notifications, subscriber) => {
  const promises = notifications.map(n => {
    const subscribers = n.subscribers.filter(s => s !== subscriber && true)
    return db
      .collection('notifications')
      .doc(n.notificationId)
      .update({ subscribers, updated: getCurDateWithUser(subscriber) })
  })

  return Promise.all(promises)
}
