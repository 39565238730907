import React, { useState, useEffect } from 'react'
import { formatDateAsMonthNameYear, formatDateAsMonthName } from '../../../../utilities/date/dateFormat'

function CounterPeriod({ period }) {
  const [displayText, setDisplayText] = useState('')

  useEffect(() => {
    if (period.years.length > 1) {
      console.log('yes, period in couterPeriod > 1')
    } else if (period.years[0].months.length === 1) {
      setDisplayText(`За ${formatDateAsMonthNameYear({ seconds: period.startingDate / 1000 })}`)
    } else {
      setDisplayText(
        `За ${formatDateAsMonthName({ seconds: period.startingDate / 1000 })} - ${formatDateAsMonthNameYear({
          seconds: period.endingDate / 1000,
        })}`,
      )
    }
  }, [period])

  return (
    <div>
      <span className="WidgetTile-CounterPeriod">{displayText}</span>
    </div>
  )
}

export default CounterPeriod
