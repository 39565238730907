import React from 'react'
import './OfficeInfoBlock.sass'
import Icon from '../../../../../../../components/UI/Icon/Icon'

function OfficeInfoBlock({ type, title, icon, children, onEdit }) {
  return (
    <div className="OfficeInfoBlock">
      <div className="OfficeInfoBlock-Icon">
        <Icon name={icon} />
      </div>
      <div className="OfficeInfoBlock-Content">
        <div className="OfficeInfoBlock-Title">
          {title}{' '}
          <span onClick={onEdit}>
            <Icon name="pencil-alt" />
          </span>
        </div>
        {children}
      </div>
    </div>
  )
}

export default OfficeInfoBlock
