import React, { useState, useEffect } from 'react'
import './TopDeals.sass'
import { orderBy, find, isEmpty, merge } from 'lodash'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import WidgetTile from '../../widgets/WidgetTile/WidgetTile'
import SceneTable from '../../../../components/UI/SceneContent/SceneTable/SceneTable'
import getDoneDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import { getServiceTypesOptions } from '../../../../utilities/getOptions/getServiceTypesOptions'
import { formatDateAsDayMonthNameYear } from '../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import fetchMemberByUserId from '../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'

function TopDeals({ accountId, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const [topDeals, setTopDeals] = useState(null)

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads } = resp
      promiseAllValues({
        responsible: !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : null,
      }).then((data) =>
        getDoneDeals({ accountId, dateRange, responsible: data.responsible })
          .then((doneDeals) => {
            const top = orderBy(doneDeals, (d) => d.comission, ['desc']).filter((d, i) => i < 10)
            return Promise.all(
              top.map((s) =>
                promiseAllValues({
                  title: s.title,
                  responsible: fetchMemberByUserId({ accountId, userId: s.responsible }).then((d) =>
                    merge(d, { employeeId: data.userId }),
                  ),
                  need: getServiceTypesOptions(accountId).then((o) => find(o, ['value', s.need]).label),
                  check: s.comission,
                  closeDate: formatDateAsDayMonthNameYear(s.finishedWith.date),
                }),
              ),
            )
          })
          .then((results) => setTopDeals(results)),
      )
    }
  }, [dateRange, resp])

  return (
    <WidgetTile title="Топ 10 сделок" isLoading={!topDeals} counterPeriod={dateRange} className="TopDeals">
      <SceneTable
        header={[
          ['', 32],
          ['Дата закрытия'],
          ['Сделка'],
          ['Тип потребности', 110],
          ['Сумма комиссии', 100],
          ['Ответственный', 180],
        ]}
        body={
          topDeals
            ? topDeals.map((d, index) => [
                index + 1,
                <span>{d.closeDate}</span>,
                <span>{d.title}</span>,
                <span>{d.need}</span>,
                <span>
                  <NumberFormat value={d.check} displayType="text" thousandSeparator={' '} suffix=" руб." />
                </span>,
                <UserNameWithAvatar
                  avatarUrl={d.responsible.avatar.url}
                  avatarSize="26"
                  userName={`${d.responsible.lastName} ${d.responsible.name} ${d.responsible.middleName}`}
                />,
              ])
            : []
        }
      />
    </WidgetTile>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(TopDeals)
