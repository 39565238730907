import React, { useState, useEffect, useRef } from 'react'
import './EstateManager.sass'
import { connect } from 'react-redux'
import { isEmpty, merge, omit } from 'lodash'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import ModuleWithSubmenu from '../../../../Module/ModuleWithSubmenu'
import getEstateManagerSubmenu from './functions/getEstateManagerSubmenu'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import { createForm } from '../../../../../utilities/newforms'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import SectionRender from '../../../../../utilities/newforms/render/SectionRender'
import AddressPicker from '../../../../../components/AddressPicker/AddressPicker'
import InBaseCounter from '../../../../Analytics/components/InBaseCounter/InBaseCounter'
import FileManager from '../../../../../components/FileManager/FileManager'
import EstatePhotoManager from './components/EstatePhotoManager/EstatePhotoManager'
import EstateParamsManager from './components/EstateParamsManager/EstateParamsManager'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import { addDoc } from '../../../../../utilities/db/addDoc'
import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'
import uploadEstatePhotos from './functions/uploadEstatePhotos'
import uploadEstateAttachments from './functions/uploadEstateAttachments'
import { logError } from '../../../../../utilities/db/logError'
import getDoc from '../../../../../utilities/db/getDoc'
import updateEstateContact from './functions/updateEstateContacts'
import rootPath from '../../../../../appTree/rootPath'
import getEstateAvailableDays from './functions/getEstateAvailableDays'
import AttachedContactsAndOrganizations from '../../../../Contacts/AttachedContactsAndOrganizations/AttachedContactsAndOrganizations'
import useSystemFieldsMethods from '../../../../../utilities/newforms/useSystemFieldsMethods'
import detachContactFromEstate from './functions/detachContactFromEstate'
import useModulePermissions, { getPermissionByResponsible } from '../../../../Module/hooks/useModulePermissions'
import Button from '../../../../../ui/Button/Button'
import TagCloud from '../../../../../components/TagCloud/TagCloud'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import updateDoc from '../../../../../../functions/utilities/db/updateDoc'
import estateDbForm from './estateDbForm'
import { db } from '../../../../../config/firebase'

function EstateManager({ accountId, accountUser, permissions: rights, options = {}, closeLink, uid, ...router }) {
  const estateId = router.match.params.id
  const [form, setForm] = useState(null)
  console.log('🚀 ~ file: EstateManager.jsx:46 ~ EstateManager ~ form:', form)
  const methods = useSystemFieldsMethods({ accountId, form })
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [requiredParams, setRequiredParams] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fileManagerRef = useRef()
  const photoManagerRef = useRef()

  const permissions = useModulePermissions({ form, rights, accountUser })

  const update = () => {
    updateDoc({ db, accountId, path: 'config/estate/objects', docId: 'estateObject', data: estateDbForm })
  }

  useEffect(() => {
    promiseAllValues({
      formData: estateId
        ? getDoc({ accountId, path: 'estate', docId: estateId })
        : { status: 'inProgress', rentTerm: 'month', responsible: uid },
      estateObject: getDoc({ accountId, path: 'config/estate/objects', docId: 'estateObject' }),
      sections: getDoc({ accountId, path: 'config/estate/forms', docId: 'estateManager' }).then(
        (data) => data.sections,
      ),
    }).then((result) => {
      setForm(
        createForm({
          formData: result.formData,
          formPattern: result.estateObject,
          methods,
        }),
      )
      setSections(result.sections)
    })
  }, [])

  const submitForm = () => {
    if (!isFormValid({ form }) || !isEmpty(requiredParams)) {
      if (isEmpty(form.contacts.values)) {
        setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Невозможно сохранить объект. Пожалуйста, добавьте контакт',
          closeAfter: 5000,
        })
      } else {
        setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Невозможно сохранить объект. Пожалуйста, заполните обязательные поля',
          closeAfter: 5000,
        })
      }
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = merge(getFormValues({ form }), {
      created: getCurDateWithUser(uid),
      updated: getCurDateWithUser(uid),
    })

    const data = {
      ...values,
      photos: values.photos.filter((p) => !p.needUpload).map((p) => omit(p, ['file', 'imageUrl'])),
      attachments: values.attachments.filter((a) => !a.needUpload).map((a) => omit(a, ['file'])),
    }

    let dbFunc
    if (estateId) {
      dbFunc = updateDoc({
        db,
        accountId,
        path: 'estate',
        docId: estateId,
        data: merge(omit(data, ['created', 'updated']), { updated: getCurDateWithUser(uid) }),
      })
    } else {
      dbFunc = addDoc({
        accountId,
        path: 'estate',
        data,
      })
    }

    dbFunc.then((docId) =>
      Promise.all([
        uploadEstatePhotos({ accountId, photos: values.photos, docId }),
        uploadEstateAttachments({ accountId, docId, ...fileManagerRef.current.getFiles() }),
        updateEstateContact({
          accountId,
          contacts: values.contacts.map((c) => c.id),
          estateId: docId,
          uid,
          operationType: 'add',
        }),
      ])
        .then(() => {
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Успешно сохранено',
            closeAfter: 5000,
          })
          router.history.push(`${rootPath(accountId)}/estate/list/${docId}/edit`)
        })
        .catch((error) => {
          logError({ error })
        })
        .finally(() => setIsLoading(false)),
    )
  }

  return (
    <ModuleWithSubmenu
      subsections={getEstateManagerSubmenu({ estateId, modulePath: closeLink })}
      className="Manager-Container"
    >
      {/* <button onClick={update}>Update</button> */}
      <div className="SceneWithHistory-Container EstateManager">
        <div className="LeftSide">
          <div className="SceneContent EstateManager-Form">
            <FormRender
              sections={sections}
              tagCloud={
                <TagCloud
                  initialTags={form && form.tags.values}
                  suggestionsPath={`${rootDbPath(accountId)}/config/estate/tags`}
                  syncState={(moduleState) =>
                    syncForm({ propIdentifier: 'tags', form, setForm, newForm: moduleState.map((t) => t.tagId) })
                  }
                  disableEdit={permissions ? !permissions.edit : true}
                  allowNewTags={options.createTag}
                />
              }
              form={form}
              setForm={setForm}
              errors={showErrors}
              permissions={permissions}
              sectionsSkeleton={[6, 2]}
            />
          </div>
          <div className="SceneContent EstateManager-AddressForm">
            <SectionRender
              title="Адрес объекта"
              component={
                <AddressPicker
                  accountId={accountId}
                  formData={form ? form.address.values : null}
                  syncState={(moduleState) =>
                    syncForm({ propIdentifier: 'address', form, setForm, newForm: moduleState })
                  }
                  showErrors={showErrors}
                  withMap
                  disabled={permissions && !permissions.edit}
                  required
                />
              }
            />
          </div>
          {form &&
            getPermissionByResponsible({
              option: rights.contactBlock,
              responsible: form.responsible.value,
              accountUser,
            }) && (
              <AttachedContactsAndOrganizations
                disableEdit={permissions && !permissions.edit}
                contacts={form.contacts.values}
                syncState={(state) => state && syncForm({ propIdentifier: 'contacts', form, setForm, newForm: state })}
                superstructure={{
                  object: {
                    path: 'config/estate/objects',
                    docId: 'estateContactObject',
                  },
                  form: {
                    path: 'config/estate/forms',
                    docId: 'estateContactManager',
                  },
                }}
                onDetach={(doc, ctForm, setCtForm) =>
                  detachContactFromEstate({ accountId, estateId, ctForm, setCtForm, doc, uid })
                }
              />
            )}
        </div>
        <div className="RightSide">
          <div className="EstateManager-TopBlock">
            <InBaseCounter accountId={accountId} getAvailableDaysFunc={() => getEstateAvailableDays(accountId)} />
            <FileManager
              ref={fileManagerRef}
              formData={form ? form.attachments.values : null}
              syncState={(moduleState) =>
                syncForm({ propIdentifier: 'attachments', form, setForm, newForm: moduleState })
              }
              onDelete={(fileName) =>
                estateId
                  ? updateDoc({
                      db,
                      accountId,
                      path: 'estate',
                      docId: estateId,
                      data: {
                        attachments: form.attachments.values.filter((a) => a.fileName !== fileName),
                        updated: getCurDateWithUser(uid),
                      },
                    })
                  : Promise.resolve('ok')
              }
            />
          </div>
          <EstatePhotoManager
            ref={photoManagerRef}
            estateId={estateId}
            formData={form ? form.photos.values : null}
            syncState={(moduleState) => syncForm({ propIdentifier: 'photos', form, setForm, newForm: moduleState })}
            disableEdit={permissions ? !permissions.edit : true}
          />
          <EstateParamsManager
            syncState={(moduleState) => syncForm({ propIdentifier: 'params', form, setForm, newForm: moduleState })}
            formData={form ? form.params.values : null}
            estateCategoryId={form && form.estateCategory.value}
            estateTypeId={form && form.estateType.value}
            pushRequiredParams={setRequiredParams}
            showErrors={showErrors}
            withContainer
            permissions={permissions}
          />
        </div>
      </div>
      <SubmitFormButtons
        className="Module-SubmitFormButtons"
        isLoading={isLoading}
        onSubmit={submitForm}
        onCancel={() => router.history.push(`${rootPath(accountId)}/estate/list`)}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        listenLatestUpdates={{ path: 'estate', docId: estateId }}
        disableEdit={permissions ? !permissions.edit : true}
      />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
})

export default compose(withRouter, connect(mapStateToProps))(EstateManager)
