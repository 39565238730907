import React from 'react'
import SiteDropdownLink from './SiteDropdownMenu/SiteDropdownLink'
import SiteDropdownMenu from './SiteDropdownMenu/SiteDropdownMenu'

export const links = [
  {
    path: '/#benefits',
    hashLink: true,
    icon: 'star',
    title: 'Преимущества',
    description: `Узнайте больше о платформе, ее функционале и возможностях.`,
  },
  {
    path: '/#features',
    hashLink: true,
    icon: 'list-ul',
    title: 'Возможности',
    description: `Ознакомьтесь с отличительными особенностями решения для агентств недвижимости`,
  },
  {
    path: '',
    icon: 'shield-check',
    title: 'Безопасность и надежность',
    description: `Шифрование канала передачи данных. Полное резервирование. Защита авторизации.`,
  },
  {
    path: '',
    icon: 'cogs',
    title: 'Индивидуальная разработка',
    description: `Разработаем уникальные модули, которые полностью соответствуют специфике вашего бизнеса.`,
  },
  {
    path: '',
    icon: 'plug',
    title: 'Комплексное внедрение',
    description: `Узнайте подробнее о комплексе услуг по внедрению CardBox.`,
  },
  {
    path: '',
    icon: 'chart-line',
    title: 'Бесплатный аудит системы продаж',
    description: `Проведем комплексный аудит вашей системы продаж.`,
  },
]

function ProductMenu() {
  return (
    <SiteDropdownMenu title="Продукт">
      {links.map((l) => (
        <SiteDropdownLink
          path={l.path}
          navLink={l.navLink}
          hashLink={l.hashLink}
          icon={l.icon}
          title={l.title}
          description={l.description}
        />
      ))}
    </SiteDropdownMenu>
  )
}

export default ProductMenu
