import React from 'react'
export const confirmButton = { content: { type: 'icon', name: 'phone', weight: 'solid' }, theme: 'call' }
export const confirmDisabledButton = {
  content: { type: 'icon', name: 'phone-slash', weight: 'solid' },
  theme: 'defaultActive',
  clicked: () => '',
}
export const cancelButton = { content: { type: 'icon', name: 'phone-slash', weight: 'solid' }, theme: 'cancel' }
export const closeButton = { content: { type: 'icon', name: 'times', weight: 'solid' } }
export const muteCallButton = { content: { type: 'icon', name: 'bell', weight: 'solid' } }
export const muteCallActiveButton = {
  content: { type: 'icon', name: 'bell-slash', weight: 'solid' },
  theme: 'defaultActive',
}
export const microButton = {
  content: { type: 'icon', name: 'microphone-alt', weight: 'solid' },
}
export const microActiveButton = {
  content: { type: 'icon', name: 'microphone-alt', weight: 'solid' },
  theme: 'defaultActive',
}
export const microDisabledButton = {
  content: { type: 'icon', name: 'microphone-alt-slash', weight: 'solid' },
  theme: 'defaultActive',
  clicked: () => '',
}

export const numberPadButton = {
  content: {
    type: 'custom',
    content: (
      <div className="RingsContainer">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    ),
  },
}
