import { isAfter, isBefore } from 'date-fns'
import { cloneDeep, head, last, orderBy } from 'lodash'

const listDocWasAdded = async ({ displayedData, collection, doc, page, fetchFunction }) => {
  const totalPages = Math.ceil(collection.length / 20)
  const docDate = doc.created.at.seconds * 1000
  const firstElDate = head(displayedData).created.at.seconds * 1000
  const lastElDate = last(displayedData).created.at.seconds * 1000

  if ((totalPages > page && isBefore(docDate, lastElDate)) || (page > 1 && isAfter(docDate, firstElDate))) {
    return { displayedData, totalPages }
  }

  const fetchedDoc = await fetchFunction(doc)
  let displayedDataClone = cloneDeep(displayedData)
  if (displayedData.length < 20) {
    displayedDataClone.push(fetchedDoc)
  } else {
    displayedDataClone.splice(-1, 1, fetchedDoc)
  }

  displayedDataClone = orderBy(displayedDataClone, (d) => d.created.at.seconds * 1000, ['desc'])

  return { displayedData: displayedDataClone, totalPages }
}

export default listDocWasAdded
