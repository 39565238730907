import React, { useEffect, useState } from 'react'
import './Map.sass'
import GoogleMap from 'google-map-react'
import { isEmpty } from 'lodash'
import { db } from '../../config/firebase'
import Spinner from '../UI/Spinner/Spinner'
import { mapStyles } from './mapStyles'
import MapMarker from './components/MapMarker/MapMarker'
import { onChildMouseDown, onChildMouseMove, onChildMouseUp } from './functions'
import getDoc from '../../utilities/db/getDoc'

function Map({
  accountId = null,
  markers,
  address,
  setAddress,
  useExternalConfig = false,
  externalConfig,
  setExternalConfig,
  disabled,
}) {
  const [config, setConfig] = useState(null)

  useEffect(() => {
    getDoc({ accountId, path: 'config/global/maps', docId: 'config' }).then((mapConfig) => {
      if (useExternalConfig)
        return setExternalConfig({
          ...mapConfig,
          ...(!isEmpty(markers) ? { center: { lng: markers[0].lng, lat: markers[0].lat } } : {}),
        })
      return setConfig(mapConfig)
    })
  }, [])

  const mapOptions = {
    styles: mapStyles,
    gestureHandling: 'greedy',
  }

  return (
    <div className="Map">
      {(useExternalConfig && !externalConfig) || (!useExternalConfig && !config) ? (
        <Spinner type="popup" />
      ) : (
        <GoogleMap
          {...(useExternalConfig ? externalConfig : config)}
          options={mapOptions}
          onChildMouseDown={() =>
            onChildMouseDown({
              config: useExternalConfig ? externalConfig : config,
              setConfig: useExternalConfig ? setExternalConfig : setConfig,
            })
          }
          onChildMouseUp={() =>
            onChildMouseUp({
              config: useExternalConfig ? externalConfig : config,
              setConfig: useExternalConfig ? setExternalConfig : setConfig,
              address,
              setAddress,
            })
          }
          onChildMouseMove={(key, marker, newCoords) =>
            disabled ? null : onChildMouseMove(key, marker, newCoords, { address, setAddress })
          }
        >
          {markers.map((m) => (
            <MapMarker {...m} />
          ))}
        </GoogleMap>
      )}
    </div>
  )
}

export default Map
