import React from 'react'
import './SmartButton.sass'
import { connect } from 'react-redux'
import * as actions from '../../../modules/Dialer/store/actions/dialerActions'

const SmartButton = ({ toggleCallBar, inboundCalls }) => {
  return (
    <div className="SmartButton-Container">
      <button type="button" className="Dialer-Caller" onClick={() => toggleCallBar()}>
        <div className="RingsContainer">
          <span></span>
          <span className={inboundCalls ? 'DialerRing-ActiveCall' : ''}></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inboundCalls: state.dialer.callNotifications.length,
})

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCallBar: () => dispatch(actions.dialerToggleCallBar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartButton)
