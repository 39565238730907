import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import getDoc from '../../../../../utilities/db/getDoc'
import DeletePopUp from '../../../../../components/DeletePopUp/DeletePopUp'

const DeleteFeedPopUp = ({ accountId, feedId, closePopUp, uid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    updateDoc({ accountId, path: 'feeds', docId: feedId, data: { isDeleted: true }, uid }).then(() => closePopUp())
  }

  useEffect(() => {
    getDoc({ accountId, path: 'feeds', docId: feedId }).then((news) => setTitle(`${news.author}`))
  }, [])

  return (
    <DeletePopUp
      title="Удаление отзыва"
      warningMessage={
        <>
          Вы уверены, что хотите удалить отзыв <span className="Bolder">"{title}"</span>?
        </>
      }
      fieldLabel="Чтобы удалить этот отзыв, введите его идентификатор:"
      identifier={feedId}
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(DeleteFeedPopUp)
