export const editButton = (clicked) => ({
  return: 'docId',
  type: 'edit',
  icon: 'pencil-alt',
  label: 'Редакт.',
  clicked,
})

export const deleteButton = (clicked) => ({
  return: 'docId',
  type: 'delete',
  icon: 'trash-alt',
  label: 'Удалить',
  clicked,
  // clicked: deleteContact,
})
