import React from 'react'
import DateBar from '../DateBar/DateBar'
import UsersBar from '../UsersBar/UsersBar'
import './DashboardNav.sass'
import { withRouter } from 'react-router-dom'

function DashboardNav({ permissions, ...router }) {
  return (
    <div className="Dashboard-Nav">
      <DateBar history={router.history} />
      <UsersBar permissions={permissions} history={router.history} />
    </div>
  )
}

export default withRouter(DashboardNav)
