import React, { useState } from 'react'
import Button from '../../../../ui/Button/Button'
import StatusMessage, { useStatusMessage } from '../../../../ui/StatusMessage/StatusMessage'
import getDoc from '../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import { createForm } from '../../../../utilities/newforms'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import FormRender from '../../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import { firebase } from '../../../../config/firebase'

function NewPasswordForm({ uid }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            placeholder: 'Введите пароль',
            label: 'Новый пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
        confirmPassword: {
          field: {
            fieldId: 'confirmPassword',
            fieldType: 'input',
            inputType: 'password',
            placeholder: 'Введите пароль',
            label: 'Подтвердите пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    }),
  )

  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    const { password, confirmPassword } = getFormValues({ form })

    if (password !== confirmPassword) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Новый пароль не совпадает с проверочным',
        closeAfter: 5000,
      })
    }

    if (password.length < 6) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Новый пароль должен быть длинной не менее 6 символов',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    getDoc({ path: 'users', docId: uid, docIdName: 'userId' }).then((user) =>
      updateDoc({ path: 'users', docId: user.userId, data: { password } }).then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then(() => firebase.auth().currentUser.updatePassword(password))
          .catch((e) => {
            console.log('🚀 ~ file: NewPasswordForm.jsx ~ line 88 ~ updateDoc ~ e', e)
          })
      }),
    )
  }

  return (
    <>
      <div className="AuthForm-Header">
        <h5 className="AuthForm-Title">Введите новый пароль</h5>
      </div>
      {statusMessage.show && (
        <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
      )}
      <form
        className="Site-Fields"
        style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '24px' }}
        onSubmit={onSubmit}
      >
        <FormRender
          sections={[{ fields: ['password', 'confirmPassword'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="ConfirmationForm-Buttons">
          <Button
            type="submit"
            className="AuthForm-Button"
            icon="check"
            title="Сохранить"
            fill="black"
            theme="solid"
            isLoading={isLoading}
            onClick={onSubmit}
            size={48}
          />
        </div>
      </form>
    </>
  )
}

export default NewPasswordForm
