import { firebase } from '../../../../config/firebase'

const signOutUser = ({ redirectTo } = {}) => {
  if (redirectTo) {
    localStorage.setItem('redirectTo', redirectTo)
  }

  return firebase.auth().signOut()
}

export default signOutUser
