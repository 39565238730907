import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import './EstateBlock.sass'
import BarInfoBlock from '../BarInfoBlock/BarInfoBlock'
import CallInfoPopUp from '../CallInfoPopUp/CallInfoPopUp'
import { formatDateAsDayMontYearHHMM } from '../../../../utilities/date/dateFormat'
import { usePopUp } from '../../../../hooks/usePopUp'

function EstateBlock({ estates, estateObjects }) {
  const [content, setContent] = useState([])
  const [activeEstateCounter, setActiveEstateCounter] = useState(0)
  const buttonRef = useRef()
  const popUpRef = useRef()
  const [showPopUp, setShowPop] = usePopUp(buttonRef, popUpRef, content.length)

  useEffect(() => {
    if (estates.length && !isEmpty(estateObjects)) {
      fetchEstateData()
    }
  }, [estates, estateObjects])

  const fetchEstateData = () => {
    const estatesWithData = estates.map((estate) => estateObjects[estate])
    const fetchedEstates = estatesWithData.map((estate) => (
      <Link to={`/estate/list/edit/${estate.estateId}`}>
        <div className="CallInfoPopUp-Item">
          <p>
            <span className="CallInfoPopUp-WhiteContent">Объект</span>
            {/* #{getSaleName(sale.saleId)} */}
          </p>
          <p>Создан: {formatDateAsDayMontYearHHMM(estate.created.at)}</p>
          <p>
            {estate.category} / {estate.type}
          </p>
          <p>
            <span className="CallInfoPopUp-WhiteContent">{estate.operation}</span>
          </p>
          <p>{estate.address}</p>
        </div>
      </Link>
    ))

    setContent(fetchedEstates)
  }

  return (
    <BarInfoBlock className={['Dialer-LeadsBlock', ...(showPopUp ? ['BarInfoBlock_theme_active'] : [])].join(' ')}>
      {showPopUp && (
        <CallInfoPopUp ref={popUpRef} title="Объекты" content={content} onClose={() => setShowPop(false)} />
      )}
      <p ref={buttonRef} className="LeadsBlock">
        <span className={estates.length ? 'BarInfoBlock-ActiveCounter' : ''}>{activeEstateCounter}</span> /{' '}
        {estates.length} Объектов
      </p>
    </BarInfoBlock>
  )
}

const mapStateToProps = (state) => ({ estateObjects: state.estate.estateObjects })

export default connect(mapStateToProps)(EstateBlock)
