import React, { useState, useEffect, useRef } from 'react'
import './UsersPicker.sass'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { find } from 'lodash'
import Icon from '../../../../components/UI/Icon/Icon'
import { usePopUp } from '../../../../hooks/usePopUp'
import { getUrlQueryParams, setUrlQueryParams } from '../../../../utilities/queries'
import { getMembersOptions, getOfficesOptions, getOfficesSquadsOptions } from '../../../../utilities/getOptions'
import DropdownBox from '../../../../components/UI/DropdownBox/DropdownBox'
import Spinner from '../../../../components/UI/Spinner/Spinner'

function UsersPicker({
  accountId,
  type,
  label,
  icon,
  pickResponsibleOnInit = false,
  defaultResponsible,
  hideBar = false,
  history,
}) {
  const [pickerLabel, setPickerLabel] = useState(label)
  const [responsibleOptions, setResponsibleOptions] = useState(null)
  const [active, setActive] = useState(false)

  const usersPickerRef = useRef()
  const ddPickerRef = useRef()

  const [showPopUp, setShowPopUp] = usePopUp(usersPickerRef, ddPickerRef, true)

  const { responsible: resp, responsibleType } = getUrlQueryParams({
    history,
    params: ['responsible', 'responsibleType'],
  })

  useEffect(() => {
    switch (type) {
      case 'office':
        getOfficesOptions(accountId).then((options) => setResponsibleOptions(options))
        break
      case 'squad':
        getOfficesSquadsOptions({ accountId }).then((options) => setResponsibleOptions(options))
        break
      case 'employee':
        getMembersOptions({ accountId }).then((options) => setResponsibleOptions(options))
        break
      default:
    }
  }, [])

  useEffect(() => {
    if (pickResponsibleOnInit && (!resp || !responsibleType)) {
      history.push(
        setUrlQueryParams({
          history,
          params: [
            ['responsibleType', type],
            ['responsible', defaultResponsible],
          ],
        }),
      )
    }

    if (responsibleType === type) {
      setActive(true)
      const activeOption = find(responsibleOptions, ['value', resp])
      if (activeOption) {
        setPickerLabel(`${label}: ${activeOption.label}`)
      }
    } else {
      setActive(false)
      setPickerLabel(label)
    }
  }, [history.location.search, responsibleOptions])

  const updateUrlParams = (responsible) => {
    history.push(
      setUrlQueryParams({
        history,
        params: [
          ['responsibleType', type],
          ['responsible', responsible],
        ],
      }),
    )
    setShowPopUp(false)
  }

  const handleUsersPickerClick = (type) => {
    if (type === 'all') {
      history.push(
        setUrlQueryParams({
          history,
          params: [
            ['responsible', 'all'],
            ['responsibleType', 'all'],
          ],
        }),
      )
    }
  }

  return hideBar ? null : (
    <div className="UsersPicker-Container">
      <span
        ref={usersPickerRef}
        className={['UsersPicker', ...(active ? ['UsersPicker_status_active'] : [])].join(' ')}
        onClick={() => handleUsersPickerClick(type)}
      >
        {icon && <Icon name={icon} helperClass="UsersPicker-Icon" />}
        {pickerLabel}
        {!isEmpty(responsibleOptions) && <Icon name="angle-down" helperClass="UsersPicker-Arrow" />}
      </span>
      {type !== 'all' && showPopUp && (
        <DropdownBox ref={ddPickerRef} styles={{ minWidth: '180px' }}>
          {!responsibleOptions ? (
            <Spinner type="popup" />
          ) : (
            responsibleOptions.map((rO) => (
              <li
                className={[
                  'DropdownBox-Element',
                  ...(rO.value === resp ? ['DropdownBox-Element_theme_active'] : []),
                ].join(' ')}
                onClick={() => updateUrlParams(rO.value)}
              >
                {rO.label}
              </li>
            ))
          )}
          {isEmpty(responsibleOptions) && <p className="EmptyMessage">Нет данных</p>}
        </DropdownBox>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(UsersPicker)
