import { cloneDeep, omit } from 'lodash'
import fetchTags from '../../../../../components/TagCloud/fetchTags'
import getDoc from '../../../../../utilities/db/getDoc'
import fetchDefaultFields from '../../../../../utilities/newforms/fetch/fetchDefaultFields'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import fetchPhoneFieldset from '../../Contactlist/functions/fetchPhoneFieldset'

const fetchOrganizationObject = ({ accountId, doc }) => {
  const contactClone = cloneDeep(doc)
  return promiseAllValues({
    phone: fetchPhoneFieldset({ accountId, phones: doc.phone }),
    tags: fetchTags({ accountId, tagsDataPath: 'config/contacts/tags', tags: doc.tags }),
    responsible: fetchMemberByUserId({ accountId, userId: doc.responsible }),
    formPattern: getDoc({ accountId, path: 'config/contacts/objects', docId: 'organizationObject' }),
  }).then((fetchedContact) => ({
    ...contactClone,
    ...omit(fetchedContact, ['formPattern']),
    ...fetchDefaultFields({ doc, formPattern: fetchedContact.formPattern }),
  }))
}

export default fetchOrganizationObject
