import React from 'react'
import { A, Image } from 'react-html-email'

function EmailCopyrights() {
  const cbOwners = [
    {
      avatar: 'https://storage.googleapis.com/estate-cdbx-io-public/emails/dAvatar.jpg',
      name: 'Дмитрий Тарнавский',
      instagram: 'https://www.instagram.com/dtwhoisdt/',
    },
    {
      avatar: 'https://storage.googleapis.com/estate-cdbx-io-public/emails/sAvatar.jpg',
      name: 'Сергей Старков',
      instagram: 'https://www.instagram.com/dva_nagana/',
      facebook: 'https://www.facebook.com/starkovonline',
    },
  ]

  const cbSocLinks = [
    { link: '', icon: 'https://storage.googleapis.com/estate-cdbx-io-public/emails/wYoutube.png' },
    { link: '', icon: 'https://storage.googleapis.com/estate-cdbx-io-public/emails/wFacebook.png' },
    { link: '', icon: 'https://storage.googleapis.com/estate-cdbx-io-public/emails/wMedium.png' },
  ]

  return (
    <div
      style={{
        paddingTop: '0px',
        paddingRight: '48px',
        paddingBottom: '48px',
        paddingLeft: '48px',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        fontSize: '16px',
      }}
    >
      <p
        style={{
          display: 'block',
          width: '100%',
          height: '1px',
          backgroundColor: '#D0D5DA',
          marginTop: '0px',
          marginBottom: '24px',
        }}
      />
      <p style={{ fontWeight: '700', lineHeight: '26px', marginBottom: '24px', marginTop: '0px', textAlign: 'center' }}>
        Остались вопросы?
        <br />
        <span style={{ fontWeight: '400' }}>
          Обращайтесь на{' '}
          <A href="mailto:support@cdbx.io" style={{ color: '#27C043' }}>
            support@cdbx.io
          </A>{' '}
          и мы с радостью вам поможем
        </span>
      </p>
      <div style={{ fontWeight: '700', lineHeight: '26px', marginBottom: '24px' }}>
        Мы в соц. сетях
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            {cbSocLinks.map((s, i) => (
              <A
                href={s.link}
                target="blank"
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#1D1F29',
                  width: '32px',
                  height: '32px',
                  flexShrink: '0',
                  float: 'left',
                  ...(i !== cbSocLinks.length - 1 ? { marginRight: '16px' } : { clear: 'right' }),
                }}
              >
                <Image src={s.icon} width={32} height={32} />
              </A>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          padding: '24px',
          backgroundColor: '#F6F8F9',
          fontWeight: '700',
          borderRadius: '8px',
          marginBottom: '24px',
          boxSizing: 'border-box',
        }}
      >
        <p style={{ margin: '0px' }}>Больше новостей от создателей CardBox</p>
        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          {cbOwners.map((o, i) => (
            <div style={{ ...(i === 0 ? { float: 'left' } : { float: 'right' }) }}>
              <Image
                src={o.avatar}
                width={48}
                height={48}
                style={{ float: 'left', marginRight: '12px', borderRadius: '100%' }}
              />
              <div style={{ float: 'left' }}>
                <p style={{ margin: '0px' }}>{o.name}</p>
                <p style={{ float: 'left', marginTop: '4px' }}>
                  {o.instagram && (
                    <A href={o.instagram} style={{ marginRight: '16px', float: 'left' }}>
                      <Image
                        src="https://storage.googleapis.com/estate-cdbx-io-public/emails/gInstagram.png"
                        height={16}
                      />
                    </A>
                  )}
                  {o.facebook && (
                    <A href={o.facebook} style={{ marginRight: '16px', float: 'left' }}>
                      <Image
                        src="https://storage.googleapis.com/estate-cdbx-io-public/emails/gFacebook.png"
                        height={16}
                      />
                    </A>
                  )}
                </p>
              </div>
            </div>
          ))}
          <div style={{ clear: 'both' }} />
        </div>
      </div>
      <p style={{ fontWeight: '700', lineHeight: '26px', padding: '0px' }}>
        © CardBox {new Date().getFullYear()}
        <br />
        <span style={{ fontWeight: '400' }}>CRM система для автоматизации бизнес процессов</span>
      </p>
    </div>
  )
}
export default EmailCopyrights
