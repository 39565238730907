import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, omit } from 'lodash'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'
import getDoc from '../../../../../utilities/db/getDoc'
import { createForm } from '../../../../../utilities/newforms'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import PhotoManager from '../../../../../components/PhotoManager/PhotoManager'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import './FeedsManager.sass'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import rootPath from '../../../../../appTree/rootPath'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import { addDoc } from '../../../../../utilities/db/addDoc'
import uploadPhotos from './functions/uploadPhotos'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import { getMembersOptions } from '../../../../../utilities/getOptions/getMembersOptions'

function FeedsManager({ accountId, uid, ...router }) {
  const feedId = router.match.params.id

  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ accountId, path: 'feeds', docId: feedId }).then((result) => {
      let formData = {}
      if (result) formData = result

      setForm(
        createForm({
          formData,
          formPattern: new FeedForm(),
          methods: { getMembersOptions: () => getMembersOptions({ accountId }) },
        }),
      )
    })
  }, [])

  const submitForm = () => {
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Невозможно сохранить отзыв. Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }

    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const data = {
      ...values,
      updated: getCurDateWithUser(uid),
      ...(!feedId ? { created: getCurDateWithUser(uid) } : {}),
    }

    const opRef = feedId
      ? updateDoc({ accountId, path: 'feeds', docId: feedId, data })
      : addDoc({ accountId, path: 'feeds', data })

    opRef
      .then((docId) => {
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        setIsLoading(false)
        router.history.push(`${rootPath(accountId)}/settings/site/feeds/${docId}`)

        // window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log('🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error', error)
      })
  }

  return (
    <SceneContent title={feedId ? 'Редактировать отзыв' : 'Создать отзыв'}>
      <div className="NewsManager">
        <FormRender
          sections={[{ fields: ['status', 'author', 'text', 'rieltor', 'rating'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
      <SubmitFormButtons
        className="Module-SubmitFormButtons"
        isLoading={isLoading}
        onSubmit={submitForm}
        onCancel={() => router.history.push(`${rootPath(accountId)}/settings/site/feeds`)}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        // listenLatestUpdates={{ path: 'estate', docId: newsId }}
      />
    </SceneContent>
  )
}
class FeedForm {
  constructor() {
    this.status = {
      field: {
        fieldId: 'status',
        fieldType: 'select',
        label: 'Статус',
        value: 'draft',
        required: true,
        getOptions: [
          { label: 'Опубликовано', value: 'published' },
          { label: 'На модерации', value: 'draft' },
        ],
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
    this.author = {
      field: {
        fieldId: 'author',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Автор',
      },
      render: getFieldRenderObject(),
    }
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        label: 'Текст отзыва',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.rieltor = {
      field: {
        fieldId: 'rieltor',
        fieldType: 'select',
        label: 'Агент',
        value: '',
        required: true,
        getOptions: 'getMembersOptions',
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
    this.rating = {
      field: {
        fieldId: 'rating',
        fieldType: 'select',
        label: 'Оценка',
        required: true,
        getOptions: [
          { label: '5', value: '5' },
          { label: '4', value: '4' },
          { label: '3', value: '3' },
          { label: '2', value: '2' },
          { label: '1', value: '1' },
        ],
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: {
          at: '',
          by: '',
        },
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: {
          at: '',
          by: '',
        },
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(FeedsManager))
