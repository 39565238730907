import React from 'react'
import TryFreeButton from '../TryFreeButton/TryFreeButton'
import './Hero.sass'
import pattern from './grayPattern.svg'
import triangle from './grayTriangle.svg'
import Button from '../../../ui/Button/Button'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

function Hero({ user, ...router }) {
  const onSubmit = () => {
    if (!user) {
      return router.history.push(`/auth/sign-up`)
    }
    return router.history.push(`/profile/accounts`)
  }

  return (
    <div className="Site-Hero" style={{ backgroundImage: `url(${pattern}), url(${triangle})` }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Hero-Offer-Container">
              <div className="Hero-Offer">
                <h1 className="Offer-Title">
                  Увеличиваем эффективность работы компаний с минимальными затратами ресурсов и времени
                </h1>
                <p className="Offer-Description Site-DescriptionText">
                  CardBox поможет сфокусироваться на важных сделках. Систематизирует и поставит на контроль все процессы
                  и задачи. С нами Вы будете продавать больше и быстрее!
                </p>
                <Button
                  onClick={onSubmit}
                  title="Попробовать бесплатно"
                  theme="solid"
                  fill="accent"
                  icon="long-arrow-right"
                  size={48}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default compose(withRouter, connect(mapStateToProps))(Hero)
