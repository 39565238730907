import React from 'react'
import './Profile.sass'
import { Redirect, Route, Switch } from 'react-router'
import ProfileNav from './ProfileNav'
import Accounts from './Accounts/Accounts'
import EditProfile from './EditProfile/EditProfile'
// import updateUserObjectToNewVersion from '../Auth/functions/updeteUserObjectToNewVersion'

function Profile() {
  return (
    <div className="Site-Profile">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <button onClick={updateUserObjectToNewVersion}>test</button> */}
            <div className="Site-ProfileContainer">
              <ProfileNav />
              <div className="ProfileContainer-Data">
                <Switch>
                  <Route path="/profile/edit" component={EditProfile} />
                  <Route path="/profile/accounts" component={Accounts} />
                  <Route path="/profile">
                    <Redirect to="/profile/accounts" />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
