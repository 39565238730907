import { isNumber, toNumber } from 'lodash'
import React from 'react'
import NumberFormat from 'react-number-format'
import './StepBlock.sass'

function StepBlock({ label, factMoney, factLegend, diffTotalCounter, diffMoneyCounter, isResultStep, suffix }) {
  return (
    <div className={['StepBlock', ...(isResultStep ? ['StepBlock_theme_result'] : [])].join(' ')}>
      <p className="StepBlock-Label">{label}</p>
      {isResultStep ? (
        <>
          <div className="StepBlock-ResultTotal">
            <NumberFormat value={diffTotalCounter} displayType="text" thousandSeparator={' '} />
          </div>
          <div className="StepBlock-ResultMoney">
            <NumberFormat value={diffMoneyCounter} displayType="text" thousandSeparator={' '} suffix={` ${suffix}`} />
          </div>
        </>
      ) : (
        <>
          <div className="StepBlock-Data">
            <p className="StepBlock-MoneyCounter">
              {isNumber(factMoney) ? (
                <NumberFormat value={factMoney} displayType="text" thousandSeparator={' '} suffix=" руб" />
              ) : (
                factMoney
              )}
            </p>
            <p className="StepBlock-DataCounter">{factLegend}</p>
          </div>
          <div className="StepBlock-DiffTotalCounter">
            {isNumber(diffTotalCounter) ? (
              <NumberFormat
                value={diffTotalCounter}
                displayType="text"
                thousandSeparator={' '}
                prefix={diffTotalCounter > 0 ? '+' : ''}
              />
            ) : (
              diffTotalCounter
            )}
          </div>
          <div
            className={`StepBlock-DiffMoneyCounter ${
              diffMoneyCounter >= 0 ? '' : 'StepBlock-DiffMoneyCounter_theme_negative'
            }`}
          >
            {isNumber(diffMoneyCounter) ? (
              <NumberFormat
                value={diffMoneyCounter}
                displayType="text"
                thousandSeparator={' '}
                prefix={diffMoneyCounter > 0 ? '+' : ''}
                suffix={` ${suffix}`}
              />
            ) : (
              diffMoneyCounter
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default StepBlock
