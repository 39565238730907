import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-autosize-textarea'

import merge from 'lodash/merge'
import { createRequiredField } from '../../../../../utilities/forms/createFormElement'

import IconicButton from '../../../../../components/UI/IconicButton/IconicButton'
import Button from '../../../../../components/UI/Button/Button'
import { usePopUp } from '../../../../../hooks/usePopUp'
import taskInputChanged from '../functions/taskInputChanged'
import dbCallTasks from '../functions/dbCallTasks'
import getTaskObject from '../functions/getTaskObject'
import clearFieldValue from '../../../../../utilities/forms/clearFieldValue'
import { castComparisonVal } from '../functions/buildTasksDbQuery'
import { addDoc } from '../../../../../utilities/db/addDoc'

function AddTaskCard({ accountId, newTaskConfig, uid }) {
  const addTaskButRef = useRef()
  const addTaskFieldBlockRef = useRef()

  const [showAddTaskField, setShowAddTaskField] = usePopUp(addTaskButRef, addTaskFieldBlockRef)
  const [newTaskField, setNewTaskField] = useState(
    createRequiredField('textarea', 'text', 'Введите заголовок для этой задачи'),
  )
  const [isLoading, setIsLoading] = useState(false)

  const onAddTaskCard = () => {
    if (newTaskField.valid) {
      setIsLoading(true)
      const taskConfig = {}

      Object.entries(newTaskConfig).forEach(([k, v]) => {
        taskConfig[k] = castComparisonVal(v)
      })

      const taskObject = merge(
        getTaskObject({
          title: newTaskField.value,
          responsible: [uid],
          uid,
        }),
        {
          comments: 0,
          attachments: [],
        },
        taskConfig,
      )

      addDoc({ accountId, path: 'tasks', data: taskObject }).then(() => {
        setNewTaskField(clearFieldValue(newTaskField))
        setIsLoading(false)
        setShowAddTaskField(false)
      })
    }
  }

  const onTextareaSubmit = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault()
      onAddTaskCard()
    }
  }

  return showAddTaskField ? (
    <div className="AddTaskFieldBlock" ref={addTaskFieldBlockRef}>
      <TextareaAutosize
        autoFocus
        className="AddTaskField"
        value={newTaskField.value}
        placeholder={newTaskField.config.label}
        onChange={(event) => taskInputChanged(newTaskField, setNewTaskField, event)}
        onKeyDown={onTextareaSubmit}
      />
      <div className="TaskButtons-Container">
        <Button class="Save" clicked={onAddTaskCard} loading={isLoading}>
          Сохранить
        </Button>
        <div className="Cancel" onClick={() => setShowAddTaskField(false)}>
          <IconicButton type="basic" icon="times" iconSize="12" />
        </div>
      </div>
    </div>
  ) : (
    <button role="button" ref={addTaskButRef} className="AddTask" onClick={() => setShowAddTaskField(true)}>
      + Добавить задачу
    </button>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, uid: state.auth.user.uid })

export default connect(mapStateToProps)(AddTaskCard)
