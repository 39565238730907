import React, { useState, useEffect } from 'react'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { db } from '../../../config/firebase'
import TaskCard from '../../Tasks/scenes/Tasks/TaskCard/TaskCard'
import Task from '../../Tasks/scenes/Tasks/Task/Task'
import SceneContentPopUp from '../../../components/UI/SceneContentPopUp/SceneContentPopUp'
import EventWithIconTemplate from './EventWithIconTemplate'
import TaskResultsInput from '../../Tasks/scenes/Tasks/Task/components/TaskResults/TaskResultsInput'
import NewTaskConstructor from '../../Tasks/scenes/Tasks/Task/components/NewTaskConstructor/NewTaskConstructor'
import fetchTask from '../../Tasks/store/sagas/functions/fetchTask'
import defineTaskColumn from '../../Tasks/scenes/Tasks/functions/defineTaskColumn'
import rootDbPath from '../../../utilities/db/rootDbPath'

function RenderTask({ accountId, eventId, taskId, attachableData, uid }) {
  const [taskColumns, setTaskColumns] = useState([])
  const [taskData, setTaskData] = useState(null)
  const [showManager, setShowManager] = useState(false)
  const [taskConstructor, updateTaskConstructor] = useState({})

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/config/tasks/columns`)
      .get()
      .then((docs) => {
        const cols = []
        docs.forEach((d) => cols.push({ columnId: d.id, ...d.data() }))
        setTaskColumns(cols)
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(taskColumns)) {
      return db
        .collection(`${rootDbPath(accountId)}/tasks`)
        .doc(taskId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const rawTask = merge({ taskId: snap.id }, snap.data())
            const taskColumn = defineTaskColumn({ columns: taskColumns, task: rawTask })
            fetchTask({ accountId, task: rawTask }).then((fetchedTask) => setTaskData(merge(fetchedTask, taskColumn)))
          } else {
            return db.collection(`${attachableData.section}/${attachableData.objectId}/history`).doc(eventId).delete()
          }
        })
    }
  }, [taskId, taskColumns])

  return taskData ? (
    <>
      <div className="EventContent EventContent_theme_white">
        {EventWithIconTemplate({
          icon: taskData.isFinished ? 'check' : 'tasks',
          iconTheme: `task_status_${taskData.isFinished ? 'done' : 'pending'}`,
          content: (
            <>
              <span className="Bolder">Поставил задачу</span>
              <TaskCard
                columnStyles={taskData.styles}
                {...taskData}
                crossOutStyle={taskData.isFinished}
                onClickFn={() => setShowManager(true)}
              />
              {taskData.result ? (
                <div className="Task-ResultBlock">
                  {/* <span className="Task-ResultTag">Результат</span> */}
                  <p className="Task-ResultText">{taskData.result}</p>
                </div>
              ) : (
                <div className="TaskResults">
                  <TaskResultsInput
                    attachableData={attachableData}
                    taskId={taskId}
                    uid={uid}
                    createNewTask={taskConstructor.createNewTask}
                    taskConfig={taskConstructor.taskConfig}
                  />
                  <NewTaskConstructor
                    pushComponentControlls={updateTaskConstructor}
                    attachableData={attachableData}
                    uid={uid}
                    showCreateNewTask
                    newTaskConfig={{
                      title: taskData.title,
                      responsible: taskData.responsible.map((r) => r.responsibleId),
                      tags: taskData.tags.map((t) => t.tagId),
                    }}
                  />
                </div>
              )}
            </>
          ),
        })}

        {showManager && (
          <SceneContentPopUp transparent>
            <Task {...taskData} rawColumns={taskColumns} onClose={() => setShowManager(false)} />
          </SceneContentPopUp>
        )}
      </div>
    </>
  ) : (
    ''
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(RenderTask)
