import React, { useState, useRef } from 'react'
import './OptionsButton.sass'
import OptionsMenu from '../../components/UI/OptionsMenu/OptionsMenu'
import { usePopUp } from '../../hooks/usePopUp'
import Button from '../Button/Button'

function OptionsButton({ options, icon = 'list-ul' }) {
  const butRef = useRef()
  const ddbRef = useRef()
  const [showOptions, setShowOptions] = usePopUp(butRef, ddbRef, true)

  return (
    <div className="OptionsButton">
      {options.length > 1 ? (
        <Button ref={butRef} theme="circle" icon={icon} fill="white" color="accent" />
      ) : (
        <Button ref={butRef} theme="circle" onClick={() => options[0].onClick()} icon={options[0].icon} />
      )}
      {showOptions && (
        <OptionsMenu
          ref={ddbRef}
          close={() => setShowOptions(false)}
          options={options.map((option) => ({
            ...option,
            // clicked: () => option.clicked(option.return === 'docId' ? elementId : pos),
          }))}
        />
      )}
    </div>
  )
}

export default OptionsButton
