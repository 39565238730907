import cloneDeep from 'lodash/cloneDeep'

const setFieldValue = ({ form, fieldId, value }) => {
  const formClone = cloneDeep(form)
  const field = formClone[fieldId]

  if (field.hasOwnProperty('value')) {
    field.value = value
  } else {
    field.values = value
  }

  field.valid = true
  field.error = ''
  field.touched = true

  return formClone
}
export default setFieldValue
