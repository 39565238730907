import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SceneWithHistory from '../../../../../components/UI/SceneWithHistory/SceneWithHistory'
import ModuleWithSubmenu from '../../../../Module/ModuleWithSubmenu'
import './ContactsManager.sass'
import getContactsManagerSubmenu from './functions/getContactsManagerSubmenu'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import contactsEvents from './events/contactsEvents'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import getDoc from '../../../../../utilities/db/getDoc'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import rootPath from '../../../../../appTree/rootPath'
import createContactForm from './functions/createContactForm'
import submitContactForm from './functions/submitContactForm'
import OrganizationPopUpManager from '../../../AttachedOrganizations/components/OrganizationPopUpManager/OrganizationPopUpManager'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import OrganizationCard from '../../../OrganizationCard/OrganizationCard'
import detachContact from '../../OrganizationsList/OrganizationsManager/functions/detachContact'
import useModulePermissions from '../../../../Module/hooks/useModulePermissions'
import TagCloud from '../../../../../components/TagCloud/TagCloud'

function ContactsManager({ accountId, accountUser, permissions: rights, options = {}, closeLink, uid, ...router }) {
  const contactId = router.match.params.id

  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [localHistory, setLocalHistory] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showPopUpForOrganization, setShowPopUpForOrganization] = useState(null)

  const permissions = useModulePermissions({ form, rights, accountUser })

  useEffect(() => {
    promiseAllValues({
      formData: contactId ? getDoc({ accountId, path: 'contacts', docId: contactId }) : {},
      formPattern: getDoc({ accountId, path: 'config/contacts/objects', docId: 'contactObject' }),
      sections: getDoc({ accountId, path: 'config/contacts/forms', docId: 'contactManager' }).then(
        (data) => data.sections,
      ),
    }).then((result) => {
      let formData

      if (contactId) {
        formData = result.formData
      } else {
        formData = { responsible: uid }
      }

      setForm(createContactForm({ accountId, formData, formPattern: result.formPattern }))
      setSections(result.sections)
    })
  }, [])

  // const SaveObject = () => {
  //   // updateContactObjectToNewVersion(accountId)
  //   setDoc({ accountId, path: 'config/contacts/objects', docId: 'contactObject', data: contactDbForm })
  // }

  return (
    <ModuleWithSubmenu
      subsections={getContactsManagerSubmenu({
        accountId,
        contactId,
        modulePath: closeLink,
        counters: {
          deals: form ? form.deals.values.length : 0,
          estate: form ? form.estate.values.length : 0,
        },
        history: router.history,
      })}
      className="Manager-Container"
    >
      <SceneWithHistory
        className="ContactManager"
        historyProps={{
          useLocalState: !contactId && true,
          pushState: setLocalHistory,
          eventsTypeSet: contactsEvents,
          attachableData: {
            section: `${rootDbPath(accountId)}/contacts`,
            objectId: contactId,
          },
        }}
      >
        {/* <button onClick={SaveObject}>Save!</button> */}
        <div className="SceneContent ContactManager-Form">
          <FormRender
            sections={sections}
            form={form}
            setForm={setForm}
            errors={showErrors}
            sectionsSkeleton={[7, 4, 2]}
            permissions={permissions}
            tagCloud={
              <TagCloud
                initialTags={form && form.tags.values}
                suggestionsPath={`${rootDbPath(accountId)}/config/contacts/tags`}
                syncState={(moduleState) =>
                  syncForm({ propIdentifier: 'tags', form, setForm, newForm: moduleState.map((t) => t.tagId) })
                }
                disableEdit={permissions ? !permissions.edit : true}
                allowNewTags={options.createTag}
              />
            }
          />
        </div>
        {form && (
          <SceneContent
            title="Организация"
            buttons={
              !form.organization.values &&
              permissions &&
              permissions.edit && [
                {
                  type: 'add',
                  icon: 'plus',
                  clicked: () => setShowPopUpForOrganization({}),
                },
              ]
            }
          >
            {showPopUpForOrganization && (
              <OrganizationPopUpManager
                organizationId={showPopUpForOrganization.organizationId}
                pushOrganization={({ organizationId }) =>
                  syncForm({ propIdentifier: 'organization', form, setForm, newForm: organizationId })
                }
                close={() => setShowPopUpForOrganization(null)}
              />
            )}
            {form.organization.values ? (
              <OrganizationCard
                organizationId={form.organization.values}
                expanded={showPopUpForOrganization}
                buttons={[
                  { type: 'openCard' },
                  {
                    type: 'edit',
                    onEdit: () => setShowPopUpForOrganization({ organizationId: form.organization.values }),
                  },
                  {
                    type: 'detach',
                    onDetach: () =>
                      detachContact({ accountId, organizationId: form.organization.values, contactId, uid }).then(
                        (result) =>
                          result === 'ok' && syncForm({ propIdentifier: 'organization', form, setForm, newForm: '' }),
                      ),
                  },
                ]}
              />
            ) : (
              <div className="NoInfoRow">Контакт не закреплен за организацией</div>
            )}
          </SceneContent>
        )}
      </SceneWithHistory>
      <SubmitFormButtons
        className="Module-SubmitFormButtons"
        isLoading={isLoading}
        onSubmit={() =>
          submitContactForm({
            accountId,
            contactId,
            form,
            setStatusMessage,
            setShowErrors,
            setIsLoading,
            uid,
            onSuccessFunc: (docId) => router.history.push(`${rootPath(accountId)}/contacts/persons/list/${docId}/edit`),
          })
        }
        onCancel={() => router.history.push(`${rootPath(accountId)}/contacts/persons/list`)}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        listenLatestUpdates={{ path: 'contacts', docId: contactId }}
        disableEdit={permissions ? !permissions.edit : true}
      />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
  accountUser: state.auth.accountUser,
})

export default compose(withRouter, connect(mapStateToProps))(ContactsManager)
