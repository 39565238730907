import React, { useEffect, useRef } from 'react'
import './TaskNavElement.sass'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import { usePopUp } from '../../../../../../../hooks/usePopUp'
import Spinner from '../../../../../../../components/UI/Spinner/Spinner'

function TaskNavElement({ extraClass, icon, label, children, showByDefault = false, cbOnClick, isLoading, canBeEdit }) {
  const navElementRef = useRef()
  const containerRef = useRef()

  const [showPopUp, setShowPopUp] = usePopUp(navElementRef, containerRef, true)

  useEffect(() => {
    if (showPopUp && !isLoading && cbOnClick) return cbOnClick()
  }, [showPopUp])

  return (
    <li className={['NavElements-Item', ...(extraClass ? [extraClass] : [])].join(' ')}>
      <div className="NavElements-ItemContainer" ref={navElementRef}>
        {isLoading ? (
          <div className="Item-LoadingScene">
            <Spinner type="button" />
          </div>
        ) : (
          <>
            <Icon name={icon} size="12" helperClass="Item-Icon" />
            <span className="Item-Label">{label}</span>
          </>
        )}
      </div>
      {(showPopUp || showByDefault) && canBeEdit && <div ref={containerRef}>{children}</div>}
    </li>
  )
}

export default TaskNavElement
