import React from 'react'
import './WidgetTile.sass'
import CounterPeriod from './CounterPeriod'
import Skeleton from '../../../../components/UI/Skeleton/Skeleton'

function WidgetTile({ title, children, counterPeriod, isLoading, className }) {
  return (
    <div className={['WidgetTile', ...(className ? [className] : [])].join(' ')}>
      <div className="WidgetTile-Title">{isLoading ? <Skeleton width={100} /> : title}</div>
      <div className="WidgetTile-Content">
        {isLoading ? (
          <>
            <Skeleton width="100%" height={80} />
            <Skeleton width={60} />
          </>
        ) : (
          <>{children}</>
        )}
      </div>
      {!isLoading && counterPeriod && <CounterPeriod period={counterPeriod} />}
    </div>
  )
}

export default WidgetTile
