import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import getDoc from '../../../../utilities/db/getDoc'

const DeleteContactPopUp = ({ accountId, contactId, closePopUp, uid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [contactName, setContactName] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    updateDoc({ accountId, path: 'contacts', docId: contactId, data: { isDeleted: true }, uid }).then(() =>
      closePopUp(),
    )
  }

  useEffect(() => {
    getDoc({ accountId, path: 'contacts', docId: contactId }).then((contact) =>
      setContactName(`${contact.lastName} ${contact.name} ${contact.middleName}`),
    )
  }, [])

  return (
    <DeletePopUp
      title="Удаление контакта"
      warningMessage={
        <>
          Вы уверены, что хотите удалить <span className="Bolder">"{contactName}"</span>
          ?
          <br />
          Все данные, как-либо связанные с "{contactName}
          ", будут сохранены.
        </>
      }
      fieldLabel="Чтобы удалить этот контакт, введите его идентификатор:"
      identifier={contactId}
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(DeleteContactPopUp)
