import orderBy from 'lodash/orderBy'

const reorderTasksInColumn = (taskColumn) => {
  const orderByField = []
  const orderByType = []

  if (taskColumn.orderBy.field) {
    orderByField.push(taskColumn.orderBy.field)
    if (taskColumn.orderBy.type) {
      orderByType.push(taskColumn.orderBy.type)
    } else {
      orderByType.push('asc')
    }
  }

  return orderBy(taskColumn.tasks, orderByField, orderByType)
}

export default reorderTasksInColumn
