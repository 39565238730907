import { isEmpty, find } from 'lodash'
import { useEffect, useState } from 'react'

const useModulePermissions = ({ form, rights, accountUser }) => {
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    if (form) {
      setPermissions({
        ...rights,
        edit: getPermissionByResponsible({ option: rights.edit, responsible: form.responsible.value, accountUser }),
      })
    }
  }, [form, rights, accountUser])

  return permissions
}

export const getPermissionByResponsible = ({ option, responsible, accountUser }) => {
  switch (option) {
    case 'denied':
      return false
    case 'responsible':
      return responsible === accountUser.userId
    case 'squad':
      const squadData = find(accountUser.office.squads, ['squadId', accountUser.squadId])
      if (squadData) return squadData.members.includes(responsible)
      return false
    case 'office':
      return !isEmpty(find(accountUser.office.squads, (squad) => squad.members.includes(responsible)))
    default:
      return true
  }
}

export default useModulePermissions
