import { head, isArray, last, tail } from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../components/UI/Icon/Icon'
import SceneContentHeader from '../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import './NavPanel.sass'
import Skeleton from '../../components/UI/Skeleton/Skeleton'

function NavPanel({ blocks }) {
  return (
    <div className="NavPanel">
      {blocks ? (
        blocks.map((b) => (
          <div className="NavPanel-Block">
            {b.title && <SceneContentHeader title={b.title} />}
            {b.items.map((i) => {
              const item = i.icon ? (
                <>
                  <div className="NavItem-Icon">
                    <Icon name={i.icon} />
                  </div>
                  {i.title}
                </>
              ) : (
                i.title
              )

              return i.type === 'navlink' ? (
                <NavLink
                  to={isArray(i.path) ? last(i.path) : i.path}
                  className="NavPanel-Item"
                  activeClassName="NavPanel-Item_theme_active"
                >
                  {item}
                </NavLink>
              ) : (
                <div
                  onClick={i.onClick}
                  className={['NavPanel-Item', ...(i.isActive ? ['NavPanel-Item_theme_active'] : [])].join(' ')}
                >
                  {item}
                </div>
              )
            })}
          </div>
        ))
      ) : (
        <div className="NavPanel-Block">
          <SceneContentHeader title={<Skeleton width={80} />} />
          <div className="NavPanel-Item" style={{ alignItems: 'center' }}>
            <Skeleton circle width={24} height={24} />
            <Skeleton width={150} height={16} style={{ marginLeft: '12px' }} />
          </div>
          <div className="NavPanel-Item" style={{ alignItems: 'center' }}>
            <Skeleton circle width={24} height={24} />
            <Skeleton width={150} height={16} style={{ marginLeft: '12px' }} />
          </div>
          <div className="NavPanel-Item" style={{ alignItems: 'center' }}>
            <Skeleton circle width={24} height={24} />
            <Skeleton width={150} height={16} style={{ marginLeft: '12px' }} />
          </div>
        </div>
      )}
    </div>
  )
}

export default NavPanel
