import startOfToday from 'date-fns/startOfToday'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfQuarter from 'date-fns/endOfQuarter'
import getCalendarYearsWithMonths from './getCalendarYearsWithMonths'

const getQuarterRange = () => {
  const startingDate = startOfQuarter(startOfToday())
  const endingDate = endOfQuarter(startOfToday())
  const label = 'Квартал'

  return {
    label,
    startingDate,
    endingDate,
    years: getCalendarYearsWithMonths(startingDate, endingDate),
  }
}

export default getQuarterRange
