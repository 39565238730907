import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'
import getDoc from '../../../../../../../../utilities/db/getDoc'

const handleSquadChange = ({ accountId, userId, prevOffice, nextOffice, prevSquad, nextSquad, uid }) => {
  if (prevOffice !== nextOffice) {
    return Promise.all([
      getDoc({ accountId, path: 'offices', docId: prevOffice }).then((officeData) => {
        const newSquads = officeData.squads.map((squad) => {
          if (squad.squadId === prevSquad) squad.members = squad.members.filter((m) => m !== userId)
          return squad
        })
        return updateDoc({
          accountId,
          path: 'offices',
          docId: prevOffice,
          data: { squads: newSquads },
          uid,
        })
      }),
      ...(nextSquad
        ? [
            getDoc({ accountId, path: 'offices', docId: nextOffice }).then((officeData) => {
              const newSquads = officeData.squads.map((squad) => {
                if (squad.squadId === nextSquad) squad.members.push(userId)
                return squad
              })
              updateDoc({
                accountId,
                path: 'offices',
                docId: nextOffice,
                data: { squads: newSquads },
                uid,
              })
            }),
          ]
        : []),
    ])
  }
  if (nextSquad && prevSquad !== nextSquad) {
    return getDoc({ accountId, path: 'offices', docId: nextOffice }).then((officeData) => {
      const newSquads = officeData.squads.map((squad) => {
        if (squad.squadId === nextSquad) squad.members.push(userId)
        if (squad.squadId === prevSquad) squad.members = squad.members.filter((m) => m !== userId)
        return squad
      })
      updateDoc({
        accountId,
        path: 'offices',
        docId: nextOffice,
        data: { squads: newSquads },
        uid,
      })
    })
  }
  if (prevSquad && !nextSquad) {
    return getDoc({ accountId, path: 'offices', docId: nextOffice }).then((officeData) => {
      const newSquads = officeData.squads.map((squad) => {
        if (squad.squadId === prevSquad) squad.members = squad.members.filter((m) => m !== userId)
        return squad
      })
      updateDoc({
        accountId,
        path: 'offices',
        docId: nextOffice,
        data: { squads: newSquads },
        uid,
      })
    })
  }
}

export default handleSquadChange
