import { addDays, startOfToday, endOfToday, startOfTomorrow, endOfTomorrow } from 'date-fns'

const buildTasksDbQuery = ({ dbRef, rules, orderBy = {}, uid }) => {
  rules.forEach((r) => {
    dbRef = dbRef.where(r.comparisonField, r.op, castComparisonVal(r.comparisonVal))
  })

  if (orderBy.field) {
    dbRef = dbRef.orderBy(orderBy.field, orderBy.type ? orderBy.type : 'asc')
  }

  return dbRef
}

export const castComparisonVal = (val) => {
  switch (val) {
    case 'NOW':
      return new Date()
    case 'START_OF_TODAY':
      return startOfToday()
    case 'END_OF_TODAY':
      return endOfToday()
    case 'START_OF_TOMORROW':
      return startOfTomorrow()
    case 'START_OF_DAY_AFTER_TOMORROW':
      return addDays(startOfTomorrow(), 1)
    case 'END_OF_DAY_AFTER_TOMORROW':
      return addDays(endOfTomorrow(), 1)
    case 'END_OF_TOMORROW':
      return endOfTomorrow()
    default:
      return val
  }
}

export default buildTasksDbQuery
