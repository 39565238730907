import { cloneDeep, isEmpty } from 'lodash'
import { storageRef } from '../../../../../../../../config/firebase'
import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'
import { getCurDateWithUser } from '../../../../../../../../utilities/date/getCurDateWithUser'

export const deletePhoto = ({ accountId, photoIndex, photos, setPhotos, estateId, uid }) => {
  const photosClone = cloneDeep(photos)
  const photo = photosClone[photoIndex]

  photosClone.splice(photoIndex, 1)
  if (photo.isAvatar && !isEmpty(photosClone)) {
    photosClone[0].isAvatar = true
  }

  if (photo.storagePath) {
    storageRef
      .child(`${photo.storagePath}/${photo.fileName}.${photo.fileExt}`)
      .delete()
      .then(() =>
        updateDoc({
          accountId,
          path: 'estate',
          docId: estateId,
          data: { photos: photosClone.filter((p) => !p.needUpload), updated: getCurDateWithUser(uid) },
        }),
      )
      .then(() => setPhotos(photosClone))
      .catch((e) => console.log('🚀 ~ file: deletePhoto.js ~ line 29 ~ deletePhoto ~ e', e))
  } else {
    setPhotos(photosClone)
  }
}
