import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { orderBy, isArray } from 'lodash'
import { compose } from 'redux'
import { db } from '../../../../../../config/firebase'
import './Memberslist.sass'

import SceneContent from '../../../../../../components/UI/SceneContent/SceneContent'
import UserNameWithAvatar from '../../../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import SceneTable from '../../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../../../utilities/date/dateFormat'
import Skeleton from '../../../../../../components/UI/Skeleton/Skeleton'
import DeleteMemberPopUp from './DeleteMemberPopUp'
import rootPath from '../../../../../../appTree/rootPath'
import fetchMember from './functions/fetchMember'
import MembersFilters from './MembersFilters'
import filterRecursivly from '../../../../../TableFilters/functions/filterRecursivly'

function Memberslist({ accountId, uid, ...router }) {
  const [deletePopUp, setDeletePopUp] = useState(null)
  const [displayedData, setDisplayedData] = useState(null)

  const [showFilters, setShowFilters] = useState(false)
  const [filterButtons, setFilterButtons] = useState(null)
  const [members, setMembers] = useState(null)
  const [filterFields, setFilterFields] = useState(null)

  useEffect(() => {
    const listener = db
      .collection('accounts')
      .doc(accountId)
      .onSnapshot((snap) => setMembers(snap.data().members))

    return () => {
      listener()
    }
  }, [])

  useEffect(() => {
    if (members) {
      const filteredMembers = members.filter((object) => filterRecursivly({ filterFields, object }))
      Promise.all(filteredMembers.map((m) => fetchMember({ accountId, memberObject: m }))).then((fetchedMembers) => {
        setDisplayedData(orderBy(fetchedMembers, (m) => m.created.at.seconds * 1000, ['desc']))
      })
    }
  }, [members, filterFields])

  return (
    <>
      <MembersFilters
        show={showFilters}
        close={() => setShowFilters(false)}
        pushFilterFields={setFilterFields}
        pushFilterButtons={setFilterButtons}
      />
      {deletePopUp && <DeleteMemberPopUp userId={deletePopUp} close={() => setDeletePopUp(null)} />}
      <SceneContent
        title="Управление сотрудниками"
        filters={filterButtons}
        buttons={[
          {
            type: 'basic',
            icon: 'filter',
            clicked: () => setShowFilters(true),
          },
          {
            type: 'add',
            icon: 'plus',
            clicked: () => router.history.push(`${rootPath(accountId)}/settings/members/list/add`),
          },
        ]}
      >
        <SceneTable
          className="Memberslist"
          editOnDoubleClick
          header={[
            ['Дата добавления', 100],
            ['Фио'],
            ['Роль', 100],
            ['Офис', 100],
            ['Отдел', 100],
            ['Телефон', 100],
            ['Статус', 100],
            ['', 32],
          ]}
          body={
            isArray(displayedData)
              ? displayedData.map((m, i) => [
                  { id: m.userId },
                  formatDateAsDayMontYearHHMM(m.created.at),
                  <UserNameWithAvatar
                    key={`displayed-data-${m.userId}-${i}`}
                    avatarUrl={m.avatar.publicUrl}
                    avatarSize="26"
                    userName={`${m.lastName} ${m.name} ${m.middleName}`}
                  />,
                  <span>{m.role}</span>,
                  <span>{m.office}</span>,
                  <span>{m.squad}</span>,
                  <span>{m.phone.value}</span>,
                  <span>{m.status}</span>,
                  'options',
                ])
              : displayedData
          }
          skeleton={getLoadingSkeleton(15)}
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'pencil-alt',
              label: 'Редакт.',
              clicked: (id) => router.history.push(`${rootPath(accountId)}/settings/members/list/${id}/edit`),
            },
            {
              return: 'docId',
              type: 'delete',
              icon: 'trash-alt',
              label: 'Удалить',
              clicked: (id) => setDeletePopUp(id),
            },
          ]}
        />
      </SceneContent>
    </>
  )
}

function getLoadingSkeleton(count) {
  const content = []

  for (let index = 0; index < count; index++) {
    content.push([
      <Skeleton />,
      <div className="Skeleton-Responsible">
        <Skeleton circle width={24} height={24} />
        <Skeleton />
      </div>,
      <Skeleton />,
      <Skeleton />,
      <Skeleton />,
      <Skeleton />,
      <Skeleton circle width={24} height={24} />,
    ])
  }

  return content
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(Memberslist)
