import { cloneDeep } from 'lodash'

const onUserDetached = ({ form, setForm }) => {
  const formClone = cloneDeep(form)
  formClone.userId.values = ''
  formClone.avatar.value = {
    fileName: null,
    fileExt: null,
    publicUrl: null,
    storagePath: null,
    needUpload: false,
    created: new Date(),
  }
  formClone.userSearch.value = ''
  setForm(formClone)
}

export default onUserDetached
