import { omit } from 'lodash'
import { setDoc } from '../../utilities/db/setDoc'
import getCollection from '../../utilities/db/getCollection'
import estateObject, { estateManagerForm } from '../../modules/Estate/scenes/Estatelist/EstateManager/estateDbForm'
import estateContactObject, {
  estateContactManagerForm,
} from '../../modules/Estate/scenes/Estatelist/EstateManager/estateContactDbForm'

const installEstate = ({ accountId }) => {
  const categories = [
    {
      docId: 'commercial',
      data: { label: 'Коммерческая' },
    },
    {
      docId: 'living',
      data: { label: 'Жилая недвижимость' },
    },
    {
      docId: 'newBuilding',
      data: { label: 'Новостройки' },
    },
  ]

  const forms = [
    { docId: 'estateContactManager', data: estateContactManagerForm },
    { docId: 'estateManager', data: estateManagerForm },
  ]
  const objects = [
    { docId: 'estateContactObject', data: estateContactObject },
    { docId: 'estateObject', data: estateObject },
  ]

  Promise.all([
    setDoc({ accountId, path: 'config', docId: 'estate', data: { moduleConfig: { inBaseCounter: 30 } } }),
    categories.map((d) => setDoc({ accountId, path: 'config/estate/categories', ...d })),
    forms.map((d) => setDoc({ accountId, path: 'config/estate/forms', ...d })),
    objects.map((d) => setDoc({ accountId, path: 'config/estate/objects', ...d })),
    getCollection({ accountId: 'template', path: 'config/estate/params', docIdName: 'paramId' }).then((params) =>
      Promise.all(
        params.map((p) =>
          setDoc({ accountId, path: 'config/estate/params', docId: p.paramId, data: omit(p, ['paramId']) }),
        ),
      ),
    ),
    getCollection({
      accountId: 'template',
      path: 'config/estate/paramsCategories',
      docIdName: 'categoryId',
    }).then((cats) =>
      Promise.all(
        cats.map((c) =>
          setDoc({
            accountId,
            path: 'config/estate/paramsCategories',
            docId: c.categoryId,
            data: omit(c, ['categoryId']),
          }),
        ),
      ),
    ),
    getCollection({
      accountId: 'template',
      path: 'config/estate/statuses',
      docIdName: 'statusId',
    }).then((statuses) =>
      Promise.all(
        statuses.map((s) =>
          setDoc({
            accountId,
            path: 'config/estate/statuses',
            docId: s.statusId,
            data: omit(s, ['statusId']),
          }),
        ),
      ),
    ),
    getCollection({
      accountId: 'template',
      path: 'config/estate/types',
      docIdName: 'typeId',
    }).then((types) =>
      Promise.all(
        types.map((t) =>
          setDoc({
            accountId,
            path: 'config/estate/types',
            docId: t.typeId,
            data: omit(t, ['typeId']),
          }),
        ),
      ),
    ),
  ])
}

export default installEstate
