import React, { useEffect, useState } from 'react'
import './SubmitFormButtons.sass'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import SaveButton from '../Button/templates/SaveButton'
import CancelButton from '../Button/templates/CancelButton'
import StatusMessage, { initStatusMessage } from '../StatusMessage/StatusMessage'
import rootDbPath from '../../utilities/db/rootDbPath'
import { db } from '../../config/firebase'
import fetchMemberByUserId from '../../modules/Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import UserAvatar from '../../components/UserAvatar/UserAvatar'
import { formatDateAsDayMontYearHHMM } from '../../utilities/date/dateFormat'

function SubmitFormButtons({
  accountId,
  isLoading,
  className,
  justify = 'start',
  onSubmit,
  onCancel,
  statusMessage = {},
  setStatusMessage,
  disableEdit = false,
  listenLatestUpdates = false,
}) {
  const [docData, setDocData] = useState(null)

  useEffect(() => {
    if (listenLatestUpdates && listenLatestUpdates.docId) {
      const { path, docId } = listenLatestUpdates
      const listener = db
        .collection(`${rootDbPath(accountId)}/${path}`)
        .doc(docId)
        .onSnapshot((snap) => {
          const responsible = snap.data().updated.by
          fetchMemberByUserId({ accountId, userId: responsible }).then((fetchedMember) =>
            setDocData({
              ...snap.data(),
              responsible: fetchedMember,
            }),
          )
        })
      return () => {
        listener()
      }
    }
  }, [])

  useEffect(() => {
    if (statusMessage.show && statusMessage.closeAfter) {
      setTimeout(() => setStatusMessage(initStatusMessage()), statusMessage.closeAfter)
    }
  }, [statusMessage])

  return (
    <div
      className={[`SubmitFormButtons SubmitFormButtons_justify_${justify}`, ...(className ? [className] : [])].join(
        ' ',
      )}
    >
      {!disableEdit && <SaveButton isLoading={isLoading} onClick={onSubmit} />}
      <CancelButton onClick={onCancel} />
      {!isEmpty(statusMessage) && (
        <div
          className={[
            'SubmitFormButtons-StatusMessage',
            ...(statusMessage.show ? ['SubmitFormButtons-StatusMessage_show'] : ['']),
          ].join(' ')}
        >
          <StatusMessage type={statusMessage.type} message={statusMessage.message} />
        </div>
      )}
      {docData && (
        <div className="SubmitFormButtons-LatestUpdate">
          <div className="UserNameWithAvatar">
            <p className="UserData">
              <span className="UpdateDate">Обновлено: {formatDateAsDayMontYearHHMM(docData.updated.at)}</span>
              <span className="UserName">{`${docData.responsible.lastName} ${docData.responsible.name} ${docData.responsible.middleName}`}</span>
            </p>
            <UserAvatar size={26} url={docData.responsible.avatar.publicUrl} />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(SubmitFormButtons)
