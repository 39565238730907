import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'

const defaultFilterFields = () => ({
  createdDate: {
    field: {
      fieldId: 'createdDate',
      fieldType: 'dateRange',
      label: 'Дата создания',
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  createdBy: {
    field: {
      fieldId: 'createdBy',
      fieldType: 'select',
      label: 'Кем создан',
      required: false,
      getOptions: 'getMembersOptions',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  updatedDate: {
    field: {
      fieldId: 'updatedDate',
      fieldType: 'dateRange',
      label: 'Дата обновления',
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  updatedBy: {
    field: {
      fieldId: 'updatedBy',
      fieldType: 'select',
      label: 'Кем обновлен',
      required: false,
      getOptions: 'getMembersOptions',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
})

export default defaultFilterFields
