import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PlanGauge from '../../widgets/PlanGauge/PlanGauge'
import getSalesTotalPlanFor from '../../../Settings/Company/components/SalesPlans/functions/getSalesTotalPlanFor'
import getSalesDonePlanFor from '../../../Settings/Company/components/SalesPlans/functions/getSalesDonePlanFor'
import countDonePercent from '../../functions/countDonePercent'
import countRatePercent from '../../functions/countRatePercent'
import getWidgetConfig from '../../functions/getWidgetConfig'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'

function PersonalPlan({ accountId, uid, ...router }) {
  const [personalPlan, setPersonalPlan] = useState({})
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(dateRange)) {
      const widgetSettings = {
        minValue: 0,
        maxValue: 0,
        currentValue: 0,
        donePercent: 0,
        doneRate: 0,
        rules: [],
      }

      getSalesTotalPlanFor({ accountId, years: dateRange.years, responsible: [uid] })
        .then((aPlan) => {
          widgetSettings.maxValue = aPlan
          return getSalesDonePlanFor({
            accountId,
            startingDate: dateRange.startingDate,
            endingDate: dateRange.endingDate,
            responsible: [uid],
          })
        })
        .then((uDonePlan) => {
          widgetSettings.currentValue = uDonePlan
          widgetSettings.donePercent = countDonePercent(uDonePlan, widgetSettings.maxValue)
          widgetSettings.doneRate = countRatePercent(
            dateRange.startingDate,
            dateRange.endingDate,
            uDonePlan,
            widgetSettings.maxValue,
          )
          return getWidgetConfig({ accountId, widgetId: 'planGauge' })
        })
        .then((wConfig) => {
          // widgetSettings.rules = prepareWidgetRules({ wConfig, data: widgetSettings })
          setPersonalPlan(widgetSettings)
        })
    }
  }, [dateRange])

  return <PlanGauge title="Личный план" isLoading={isEmpty(personalPlan)} {...personalPlan} counterPeriod={dateRange} />
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(PersonalPlan)
