import sendSms from '../../../../../modules/Sms/functions/sendSms'
import { updateDoc } from '../../../../../utilities/db/updateDoc'

const sendPhoneRecoveryCode = ({ uid, phone, code }) =>
  Promise.all([
    updateDoc({ path: 'users', docId: uid, data: { confirmationCode: code } }),
    sendSms({
      phone,
      message: `Ваш проверочный код: ${code}. Если процедуру сброса пароля начали не вы, пожалуйста, немедленно обратитесь в поддержку для предотвращения несанкцианированного доступа к вашему профилю. https://cdbx.io/contact-us`,
    }),
  ])
export default sendPhoneRecoveryCode
