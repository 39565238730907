import React from 'react'
import './UserAvatar.sass'
import ReactTooltip from 'react-tooltip'
import Icon from '../UI/Icon/Icon'
import Img from '../../ui/Img/Img'

const UserAvatar = ({ size, url, deleteButton, clicked, children, tooltip }) => {
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    padding: size >= 42 ? 3 : `${2}px`,
  }

  const classes = ['UserAvatar']

  let iconSize

  if (size >= 42) iconSize = size / 1.6
  else iconSize = size - 12

  if (deleteButton) classes.push('UserAvatar_theme_delete')

  return (
    <div data-tip={tooltip} className={classes.join(' ')} style={styles}>
      {tooltip && <ReactTooltip />}
      {url ? (
        <Img src={url} className="UserAvatar_img" />
      ) : (
        <div className="UserAvatar_theme_noavatar">
          <Icon name="user" size={iconSize} weight="regular" />
        </div>
      )}
      {deleteButton ? (
        <div className="DeleteButton" onClick={clicked}>
          <Icon name="trash-alt" size="18" />
        </div>
      ) : (
        ''
      )}
      {children}
    </div>
  )
}

export default UserAvatar
