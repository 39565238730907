import React from 'react'
import Icon from '../../../../../../../components/UI/Icon/Icon'

function TaskResultBlock({ result }) {
  return (
    <div className="Body-Block TaskResultsBlock">
      <div className="Block-Title">
        <Icon name="check" size="16" />
        <span>Результат выполнения задачи</span>
      </div>
      <p>{result}</p>
    </div>
  )
}

export default TaskResultBlock
