import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = (props) => {
  let style = 'fal'
  switch (props.weight) {
    case 'regular':
      style = 'far'
      break
    case 'solid':
      style = 'fas'
      break
    case 'brand':
      style = 'fab'
      break
    default:
      style = 'fal'
  }

  const classes = ['Icon']
  props.helperClass && classes.push(props.helperClass)
  props.className && classes.push(props.className)

  return (
    <FontAwesomeIcon
      onClick={props.onClick}
      icon={[style, props.name]}
      className={classes.join(' ')}
      style={props.size ? { fontSize: `${props.size}px` } : props.style}
    />
  )
}

export default Icon
