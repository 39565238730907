import cloneDeep from 'lodash/cloneDeep'
import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = {
  user: null,
  accountUser: null,
  account: {
    isLoading: true,
    data: null,
  },
  isLoading: true,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AUTH_GET_AUTH_STATE:
      return authGetState(state, action.payload)
    case ACTION_TYPES.AUTH_UPDATE_ACCOUNT_DATA:
      return authUpdateAccountData(state, action.payload)
    case ACTION_TYPES.AUTH_UPDATE_ACCOUNT_USER_DATA:
      return authUpdateAccountUserData(state, action.payload)
    default:
      return state
  }
}

const authGetState = (state, payload) => {
  const stateClone = cloneDeep(state)
  stateClone.user = payload
  stateClone.isLoading = false
  return stateClone
}

const authUpdateAccountData = (state, payload) => {
  const stateClone = cloneDeep(state)
  stateClone.account = payload
  return stateClone
}

const authUpdateAccountUserData = (state, payload) => {
  const stateClone = cloneDeep(state)
  stateClone.accountUser = payload
  return stateClone
}

export default authReducer
