import { isEmpty, merge } from 'lodash'
import uniq from 'lodash/uniq'
import getCollection from '../../../../../../utilities/db/getCollection'
import getDoc from '../../../../../../utilities/db/getDoc'

const getEmployeesFrom = async ({ accountId, offices, squads, returnEmployeesAs = 'ids' }) => {
  const fetchedEmployees = await getDoc({
    path: 'accounts',
    docId: accountId,
    docIdName: 'employeeId',
  }).then(({ members }) => members.map((m) => merge(m, { employeeId: m.userId })))

  if ((isEmpty(offices) && isEmpty(squads)) || offices.includes('all')) {
    return getCollection({ accountId, path: 'offices', docIdName: 'officeId' }).then((ofs) => {
      const employees = []

      ofs.forEach((o) => o.squads.forEach((s) => s.members.forEach((e) => employees.push(e))))
      return returnEmployeesAs === 'ids'
        ? uniq(employees)
        : fetchedEmployees.filter((e) => employees.includes(e.employeeId))
    })
  }

  return getCollection({ accountId, path: 'offices', docId: accountId, docIdName: 'officeId' }).then((ofs) => {
    if (!isEmpty(offices)) {
      ofs
        .filter((o) => offices.includes(o.officeId))
        .filter((o) => {
          if (!isEmpty(squads)) {
            let squadInOffice = false
            o.squads.forEach((s) => {
              if (squads.includes(s.squadId) && !squadInOffice) squadInOffice = true
            })
            return squadInOffice
          }
          return true
        })
    } else {
      ofs.filter((o) => {
        let squadInOffice = false
        o.squads.forEach((s) => {
          if (squads.includes(s.squadId) && !squadInOffice) squadInOffice = true
        })
        return squadInOffice
      })
    }

    const employees = []

    ofs.forEach((o) => {
      if (!isEmpty(squads)) {
        o.squads.forEach((s) => {
          if (squads.includes(s.squadId)) {
            s.members.forEach((e) => employees.push(e))
          }
        })
      } else {
        o.squads.forEach((s) => s.members.forEach((e) => employees.push(e)))
      }
    })

    return returnEmployeesAs === 'ids'
      ? uniq(employees)
      : fetchedEmployees.filter((e) => employees.includes(e.employeeId))
  })
}

export default getEmployeesFrom
