import isNaN from 'lodash/isNaN'
import isFinite from 'lodash/isFinite'
import getFailDeals from '../../Deals/scenes/Dealslist/functions/analytics/getFailDeals'
import getDeals from '../../Deals/scenes/Dealslist/functions/analytics/getDeals'
import promiseAllValues from '../../../utilities/promiseAllValues'

const countConversionToFail = ({ accountId, dateRange, responsible }) => {
  const promises = {
    failDeals: getFailDeals({ accountId, dateRange, responsible }).then((fd) => fd.length),
    totalDeals: getDeals({ accountId, dateRange, responsible }).then((td) => td.length),
  }

  return promiseAllValues(promises).then((data) => {
    const conversionToFailPercent = Math.round((data.failDeals * 100) / data.totalDeals)
    return isNaN(conversionToFailPercent) ? 0 : isFinite(conversionToFailPercent) ? conversionToFailPercent : 0
  })
}
export default countConversionToFail
