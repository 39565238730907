import { addDays, endOfToday } from 'date-fns/esm'
import { endOfTomorrow } from 'date-fns'
import { setDoc } from '../../utilities/db/setDoc'
import getTaskObject from '../../modules/Tasks/scenes/Tasks/functions/getTaskObject'

const addTasksData = ({ accountId, uid }) => {
  const tasks = [
    {
      data: getTaskObject({
        title: 'Разобраться с принципом работы системы',
        startDate: endOfToday(),
        endDate: endOfToday(),
        responsible: [uid],
        uid,
      }),
    },
    {
      data: getTaskObject({
        title: 'Cформировать список вопросов',
        startDate: endOfTomorrow(),
        endDate: endOfTomorrow(),
        responsible: [uid],
        uid,
      }),
    },
    {
      data: getTaskObject({
        title: 'Разработать тех. задание на внедрение',
        startDate: addDays(endOfTomorrow(), 2),
        endDate: addDays(endOfTomorrow(), 2),
        responsible: [uid],
        uid,
      }),
    },
    {
      data: getTaskObject({
        title: 'Заключить договор и оплатить стоимость лицензии',
        startDate: addDays(endOfToday(), 7),
        endDate: addDays(endOfToday(), 7),
        responsible: [uid],
        uid,
      }),
    },
  ]

  return tasks.map((d) => setDoc({ accountId, path: 'tasks', ...d }))
}

export default addTasksData
