import React from 'react'
import { HashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'
import './PolicyAgreement.sass'

function PolicyAgreement() {
  return (
    <p className="Site-PolicyAgreement">
      Отправляя эту форму, вы подтверждаете, что ознакомлены с{' '}
      <HashLink to="/privacy-policy" smooth scroll={(el) => scrollWithOffset(el, -128)}>
        Политикой обработки персональных данных
      </HashLink>{' '}
      и даете свое{' '}
      <HashLink to="/privacy-policy#agreement" smooth scroll={(el) => scrollWithOffset(el, -64)}>
        согласие на обработку и хранение предоставленных персональных данных.
      </HashLink>
    </p>
  )
}

export default PolicyAgreement
