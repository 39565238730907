import { takeEvery } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import { getColumnsWithTasksSaga, fetchChunk } from './tasksSagas'
import addTaskSaga from './addTaskSaga'
import modifyTaskSaga from './modifyTaskSaga'
import removeTaskSaga from './removeTaskSaga'

export function* watchTasks() {
  yield takeEvery(actionTypes.TASKS_INITIATE_BOARD_MOUNT, getColumnsWithTasksSaga)
  yield takeEvery(actionTypes.TASKS_FETCH_CHUNK, fetchChunk)
  yield takeEvery(actionTypes.TASKS_TASK_WAS_ADDED, addTaskSaga)
  yield takeEvery(actionTypes.TASKS_TASK_WAS_MODIFIED, modifyTaskSaga)
  yield takeEvery(actionTypes.TASKS_TASK_WAS_REMOVED, removeTaskSaga)
}
