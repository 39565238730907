import React, { useState, useEffect } from 'react'
import './MotivationManager.sass'
import { connect } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import SceneContent from '../../../../../../../components/UI/SceneContent/SceneContent'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import { getSystemRolesOptions, getMotivationsParamsOptions } from '../../../../../../../utilities/getOptions'

import Spinner from '../../../../../../../components/UI/Spinner/Spinner'
import manageFormDependencies from './functions/manageFormDependencies'
import IconicButton from '../../../../../../../components/UI/IconicButton/IconicButton'
import Button from '../../../../../../../components/UI/Button/Button'
import { db } from '../../../../../../../config/firebase'
import { isFormValid } from '../../../../../../../utilities/forms/validation'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import createMotivationFormFields from './functions/createMotivationFormFields'
import { createFieldsFromParamSettings } from './functions/manageRulesFields'

function MotivationManager({ stateOffices, closeLink, uid, ...router }) {
  const { motivationId } = router.match.params

  const [motivationParams, setMotivationParams] = useState(null)
  const [motivationForm, setMotivationForm] = useState(motivationId ? [] : createMotivationFormFields())
  const [roles, setRoles] = useState(null)
  const [rules, setRules] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const defaultParamId = 'sa01dNJ1VNRrOLm039PE'

  useEffect(() => {
    getSystemRolesOptions().then((roles) => setRoles(roles))
    getMotivationsParamsOptions().then((params) => {
      setMotivationParams(params)

      if (motivationId) {
        db.collection('config/motivations/motivations')
          .doc(motivationId)
          .get()
          .then((docRef) => {
            const motivationData = docRef.data()
            setMotivationForm(createMotivationFormFields(motivationData))
            setRules(getRulesWithData(motivationData.rules, params))
          })
      } else {
        setRules([createGroupRulesSet('all', params)])
      }
    })
  }, [])

  const getRulesWithData = (motivationDataRules, params) =>
    motivationDataRules.map((rg) => ({
      ...createGroupRulesSet(rg.groupId, params),
      rules: rg.rules.map((r) =>
        createFieldsFromParamSettings({
          paramId: r.motivationParam,
          params,
          getMotivationsParamsOptions,
        }).map((f) => {
          if (f.fieldId !== 'html' && r.hasOwnProperty(f.fieldId)) {
            f.value = r[f.fieldId]
            f.valid = true
            f.touched = true
            f.error = ''
          }
          return f
        }),
      ),
    }))

  const onRuleFieldChanged = ({ newRules, groupId, ruleLine }) => {
    const rulesClone = cloneDeep(rules)
    const groupToUpdate = find(rulesClone, ['groupId', groupId])

    const oldParamId = find(groupToUpdate.rules[ruleLine], ['fieldId', 'motivationParam']).value
    const newParamId = find(newRules, ['fieldId', 'motivationParam']).value

    if (oldParamId !== newParamId) {
      const fields = createFieldsFromParamSettings({
        paramId: find(newRules, ['fieldId', 'motivationParam']).value,
        params: motivationParams,
        getMotivationsParamsOptions,
      })
      newRules = fields
    }

    groupToUpdate.rules.splice(ruleLine, 1, newRules)
    setRules(rulesClone)
  }

  const createGroupRulesSet = (groupId, params) => ({
    groupId,
    rules: [
      createFieldsFromParamSettings({
        paramId: defaultParamId,
        params: params || motivationParams,
        getMotivationsParamsOptions,
      }),
    ],
  })

  const addRuleLine = (groupId) => {
    const rulesClone = cloneDeep(rules)
    const groupToUpdate = find(rulesClone, ['groupId', groupId])
    groupToUpdate.rules.push(
      createFieldsFromParamSettings({ paramId: defaultParamId, params: motivationParams, getMotivationsParamsOptions }),
    )
    setRules(rulesClone)
  }

  const removeRuleLine = (groupId, ruleLineId) => {
    const rulesClone = cloneDeep(rules)
    const groupToUpdate = find(rulesClone, ['groupId', groupId])
    groupToUpdate.rules.splice(ruleLineId, 1)
    setRules(rulesClone)
  }

  const submitMotivation = () => {
    const validRules = flatten(rules.map((rg) => rg.rules.map((rl) => isFormValid(rl))))
    if (!isFormValid(motivationForm) || validRules.includes(false)) return setShowErrors(true)
    setIsLoading(true)
    setShowErrors(false)

    const motivationObject = {
      rules: [],
      created: getCurDateWithUser(uid),
      updated: getCurDateWithUser(uid),
    }
    motivationForm.forEach((f) => (motivationObject[f.fieldId] = f.value))

    rules.forEach((rg) => {
      const groupRules = { groupId: rg.groupId, rules: [] }
      rg.rules.forEach((rl) => {
        const ruleLine = {}
        rl.filter((r) => r.fieldType !== 'html' && (!r.hasOwnProperty('shouldRender') || r.shouldRender)).forEach(
          (r) => {
            ruleLine[r.fieldId] = r.value
          },
        )

        groupRules.rules.push(ruleLine)
      })
      motivationObject.rules.push(groupRules)
    })

    if (motivationId) {
      delete motivationObject.created
      db.collection('config/motivations/motivations')
        .doc(motivationId)
        .update(motivationObject)
        .then(() => closeMotivationManager())
    } else {
      db.collection('config/motivations/motivations')
        .add(motivationObject)
        .then(() => closeMotivationManager())
    }
  }

  const closeMotivationManager = () => router.history.push(closeLink)

  return stateOffices.isLoading || !motivationParams || !roles ? (
    <Spinner type="module" />
  ) : (
    <div className="MotivationManager-Container">
      <SceneContent title="Система мотивации">
        <div className="MotivationManager-FormContainer">
          {renderFields(
            motivationForm,
            (newForm) =>
              manageFormDependencies({
                oldForm: motivationForm,
                newForm,
                setNewForm: setMotivationForm,
                stateOffices,
                createGroupRulesSet,
                rules,
                setRules,
              }),
            showErrors,
          )}
        </div>
      </SceneContent>
      {rules.map((rg) => (
        <SceneContent
          title={`Набор правил: ${rg.groupId === 'all' ? 'Все группы' : find(roles, ['value', rg.groupId]).label}`}
        >
          <div className="MotivationManager-RulesContainer">
            {rg.rules.map((r, ind) => (
              <div className="MotivationManager-RuleConstructorLine">
                {ind > 0 ? (
                  <IconicButton
                    type="remove"
                    icon="minus"
                    customClass="ParamForm-FieldSet-Button"
                    clicked={() => removeRuleLine(rg.groupId, ind)}
                  />
                ) : (
                  <IconicButton
                    type="add"
                    icon="plus"
                    customClass="ParamForm-FieldSet-Button"
                    clicked={() => addRuleLine(rg.groupId)}
                  />
                )}
                {renderFields(
                  r,
                  (newRules) => onRuleFieldChanged({ newRules, groupId: rg.groupId, ruleLine: ind }),
                  showErrors,
                )}
              </div>
            ))}
          </div>
        </SceneContent>
      ))}
      <div style={{ gridColumn: '1/-1', display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button type="active" clicked={submitMotivation} loading={isLoading}>
          Сохранить
        </Button>
        <Button clicked={closeMotivationManager} style={{ marginLeft: '16px' }}>
          Закрыть
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  stateOffices: {
    data: state.offices.sortedOffices,
    isLoading: state.offices.isLoading,
  },
  uid: state.firebase.auth.uid,
})

export default compose(withRouter, connect(mapStateToProps))(MotivationManager)
