import { createForm } from '../../../utilities/newforms/createForm'

const createFilterForm = ({ formPattern, formData, methods }) => {
  const filterForm = {}
  Object.entries(formPattern).map(([k, v]) => {
    if (v.field.fieldType === 'fieldset') {
      v.field.fields.forEach((f) => {
        f.field.useDropValueButton = true
        v.field.multiOptions = true
      })
    } else {
      v.field.useDropValueButton = true
      v.field.multiOptions = true
    }

    filterForm[k] = v
  })

  return createForm({ formPattern: filterForm, formData, methods })
}

export default createFilterForm
