import { find } from 'lodash'
import React from 'react'
import './FunnelProgressBar.sass'

function FunnelProgressBar({ funnel, finishedWith }) {
  const currentStep = find(funnel, ['stepStatus', 'active'])

  return (
    <div className="Funnel-ProgressBar">
      <div>{finishedWith.status || currentStep.label}</div>
      <div className="ProgressBar-Container" style={{ gridTemplateColumns: `repeat(${funnel.length}, 1fr)` }}>
        {finishedWith.status ? (
          <FunnelStep finish={finishedWith.reason ? 'fail' : 'success'} />
        ) : (
          funnel.map((step) =>
            step.stepStatus === 'active' || step.stepStatus === 'passed' ? <FunnelStep active /> : <FunnelStep />,
          )
        )}
      </div>
    </div>
  )
}

function FunnelStep({ active, finish }) {
  return (
    <span
      className={[
        'FunnelStep',
        ...(active ? ['FunnelStep_theme_active'] : ['']),
        ...(finish ? [`FunnelStep_finish_${finish}`] : ['']),
      ].join(' ')}
    />
  )
}

export default FunnelProgressBar
