import React from 'react'
import './SceneWithHistory.sass'
import History from '../../../modules/History/History'

function SceneWithHistory({ className, children, aboveHistoryBlock, historyProps }) {
  return (
    <div className="SceneWithHistory-Container">
      <div className={['Container-ContentHolder', ...(className && [className])].join(' ')}>{children}</div>
      <div className="Container-HistoryHolder">
        {aboveHistoryBlock}
        <History {...historyProps} />
      </div>
    </div>
  )
}

export default SceneWithHistory
