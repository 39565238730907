import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import countComission from './countComission'

const countFactLooses = ({ deals, dateRange, responsible }) => {
  const filteredData = getFactLooses({ deals, dateRange, responsible })
  return countComission(filteredData, true)
}

export const getFactLooses = ({ deals, dateRange, responsible }) =>
  deals.filter((d) => {
    if (!responsible.includes(d.responsible)) return false
    if (d.finishedWith.status !== 'fail') return false
    if (
      !d.finishedWith.date ||
      !isAfter(d.finishedWith.date.seconds * 1000, dateRange.startingDate) ||
      !isBefore(d.finishedWith.date.seconds * 1000, dateRange.endingDate)
    ) {
      return false
    }
    return true
  })

export default countFactLooses
