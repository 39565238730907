import React, { useState, useRef } from 'react'
import './PhotoTile.sass'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import { usePopUp } from '../../../../../../../hooks/usePopUp'
import ImageEditor from '../../../../../../../components/ImageEditor/ImageEditor'
import Img from '../../../../../../../ui/Img/Img'

function PhotoTile({ isAvatar, isLayout, imageUrl, publicUrl, onDelete, setAvatar, setLayout, onEdit, disableEdit }) {
  const butRef = useRef()
  const optDd = useRef()
  const [showPopUp] = usePopUp(butRef, optDd)
  const [showImageEditor, setShowImageEditor] = useState(false)

  return (
    <div className="PhotoTile">
      {showImageEditor && !disableEdit && (
        <ImageEditor image={imageUrl || publicUrl} close={() => setShowImageEditor(false)} onEdit={onEdit} />
      )}
      <div className="PhotoTile-Markers">
        {isAvatar && (
          <div className="PhotoTile-Marker Avatar">
            <Icon name="star" />
          </div>
        )}
        {isLayout && (
          <div className="PhotoTile-Marker LayoutPlan">
            <Icon name="ruler-combined" />
          </div>
        )}
      </div>

      <Img src={imageUrl || publicUrl} alt="" className="PhotoTile-Skeleton" />
      {!disableEdit && (
        <div ref={butRef} className="PhotoTile-Marker EditButton">
          <Icon name="ellipsis-v" />
        </div>
      )}
      <a href={imageUrl || publicUrl} data-fancybox="images">
        <div className="PhotoTile-Marker ZoomButton">
          <Icon name="search-plus" />
        </div>
      </a>
      {!disableEdit && (
        <div ref={optDd} className="PhotoTile-Options" style={{ display: showPopUp ? 'flex' : 'none' }}>
          <div className="PhotoTile-OptionsContainer">
            <div className="Option" onClick={setAvatar}>
              <Icon name="star" helperClass={`Avatar ${isAvatar && 'Avatar_theme_active'}`} />
            </div>
            <div className="Option" onClick={setLayout}>
              <Icon name="ruler-combined" helperClass={`Layout ${isLayout && 'Layout_theme_active'}`} />
            </div>
            <div className="Option" onClick={() => setShowImageEditor(true)}>
              <Icon name="pencil-alt" helperClass="Edit" />
            </div>
            <div className="Option" onClick={onDelete}>
              <Icon name="trash-alt" helperClass="Delete" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PhotoTile
