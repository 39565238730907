import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  position: {
    field: {
      fieldId: 'position',
      fieldType: 'input',
      inputType: 'text',
      required: false,
      label: 'Должность',
      systemField: false,
    },
    render: getFieldRenderObject(),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export const organizationContactManagerForm = {
  sections: [
    {
      title: 'Организация',
      fields: ['position'],
    },
  ],
}

export default object
