import React, { useState } from 'react'
import { connect } from 'react-redux'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { createForm } from '../../../../../../../utilities/newforms'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import FormRender from '../../../../../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../../../../../utilities/newforms/render/getFieldRenderObject'
import useSystemFieldsMethods from '../../../../../../../utilities/newforms/useSystemFieldsMethods'
import isFormValid from '../../../../../../../utilities/newforms/validation/isFormValid'

function FinishDealWithFailPopUp({ accountId, uid, dealId, pushStatus, close }) {
  const methods = useSystemFieldsMethods({ accountId })
  const [form, setForm] = useState(createForm({ formPattern: new DealFailForm(), methods }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })
    const newStatus = { status: 'fail', ...values }
    updateDoc({ accountId, path: 'deals', docId: dealId, data: { finishedWith: newStatus }, uid }).then(() => {
      pushStatus(newStatus)
      close()
    })
  }
  return (
    <PopUp
      title="Завершить с проигрышем"
      close={close}
      show
      submitButtons
      isLoading={isLoading}
      onSubmit={onSubmit}
      onCancel={close}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '16px' }}>
        <FormRender
          sections={[{ fields: ['reason', 'reasonText'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

function DealFailForm() {
  this.reason = {
    field: {
      fieldId: 'reason',
      fieldType: 'select',
      label: 'Причина проигрыша',
      required: true,
      getOptions: 'getDealFailReasons',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  }

  this.reasonText = {
    field: {
      fieldId: 'reasonText',
      fieldType: 'textarea-autosize',
      label: 'Причина',
      required: true,
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject({ byDefault: false, ifFieldId: 'reason', value: 'other' }),
  }
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(FinishDealWithFailPopUp)
