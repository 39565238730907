import { put } from 'redux-saga/effects'
import * as actions from '../actions/dialerActions'
import apiCalls from '../../functions/apiCalls'
import dbCalls from '../../functions/dbCalls'
import { cleanPhoneNumber } from '../../../../utilities/cleanPhoneNumber'

export default function* subscribeToCallEventsSaga({ payload: { phone } }) {
  yield put(actions.dialerSendSubscriptionInfo({ isLoading: true }))

  const cleanPhone = cleanPhoneNumber(phone)

  const preparedPhone = phone.slice(1)

  const payload = {
    func: 'putSubscription',
    extensionId: '08gWJH83oEYZsvfCZSaz',
    phone: preparedPhone,
  }

  const result = yield apiCalls(payload)
  console.log('defaultfunction*subscribeToCallEventsSaga -> result', result)

  if (result !== 'error') {
    yield dbCalls({
      func: 'updateSubscriptionFile',
      extensionId: '08gWJH83oEYZsvfCZSaz',
      phone: cleanPhone,
      status: 'active',
      ...result,
    })
  } else {
  }
  yield put(actions.dialerSendSubscriptionInfo({ isLoading: false }))
}
