import React from 'react'
import './SceneContent.sass'

import SceneContentHeader from './SceneContentHeader/SceneContentHeader'

const sceneContent = ({ title, buttons, filters, children, class: className, emptyStyle } = {}) => {
  const classes = ['SceneContent']

  if (emptyStyle) classes.push('SceneContent_theme_empty')
  if (className) classes.push(className)

  return (
    <div className={classes.join(' ')}>
      {(title || filters || buttons) && <SceneContentHeader title={title} filters={filters} buttons={buttons} />}
      {children}
    </div>
  )
}

export default sceneContent
