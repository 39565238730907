import React, { useEffect, useState } from 'react'
import './EditProfile.sass'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'lodash'
import PinnedMember from '../../../../modules/Settings/Company/components/Members/Memberslist/MembersManager/PinnedMember/PinnedMember'
import getDoc from '../../../../utilities/db/getDoc'
import { createForm } from '../../../../utilities/newforms'
import userDbForm from '../../Auth/functions/UserDbForm'
import FormRender from '../../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'
import Button from '../../../../ui/Button/Button'
import StatusMessage, { useStatusMessage } from '../../../../ui/StatusMessage/StatusMessage'
import onSubmitProfile from './onSubmitProfile'
import Backdrop from '../../../../components/UI/Backdrop/Backdrop'
import sendPhoneConfirmationCode from '../../Auth/Confirmation/functions/sendPhoneConfirmationCode'
import CodeForm from '../../Auth/Confirmation/CodeForm'
import smsIcon from '../../Auth/Confirmation/assets/smsIcon.svg'
import { updateDoc } from '../../../../utilities/db/updateDoc'

function EditProfile({ user }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const sections = [
    { title: 'Редактировать профиль', fields: ['lastName', 'name', 'middleName', 'bdate', 'gender', 'invitable'] },
    { title: 'Изменить номер телефона', fields: ['phone'] },
    { title: 'Изменить пароль', fields: ['password', 'newPassword', 'confirmPassword'] },
  ]
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [newPhoneNumber, setNewPhoneNumber] = useState(null)

  useEffect(() => {
    getDoc({ path: 'users', docId: user.uid }).then((data) => {
      const passwordObject = new ChangePasswordFields()
      setForm(
        createForm({
          formData: omit(data, ['password']),
          formPattern: { ...userDbForm, ...passwordObject },
        }),
      )
    })
  }, [])

  return (
    <>
      {!isEmpty(newPhoneNumber) && (
        <Backdrop show className="Site-Backdrop">
          <CodeForm
            icon={smsIcon}
            type="phone"
            title="Изменение номера телефона"
            description="Для сохранения профиля необходимо подтвердить новый номера телефона. Для этого мы выслали SMS с проверочным кодом. Пожалуйста, введите полученый код в форму ниже."
            sendCodeFunc={() => sendPhoneConfirmationCode({ uid: user.uid, phone: newPhoneNumber.value })}
            onSuccessFunc={() => {
              updateDoc({ path: 'users', docId: user.uid, data: { phone: newPhoneNumber, updated: new Date() } })
              setNewPhoneNumber(null)
              setStatusMessage({
                show: true,
                type: 'success',
                message: 'Номер телефона успешно изменен',
                closeAfter: 5000,
              })
            }}
            user={user}
            sendCodeOnInit
          />
        </Backdrop>
      )}
      <h5 className="Site-SubsectionTitle Site-ProfileTitle">Мой профиль</h5>
      <div className="ProfileContainer-Body Site-EditProfile">
        <PinnedMember userId={user.uid} />
        <div className="Site-EditProfile-Container Site-Fields">
          {statusMessage.show && (
            <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
          )}
          <FormRender
            sections={sections}
            sectionsSkeleton={[6, 2, 4]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          {form && (
            <div className="EditProfile-SubmitButton">
              <Button
                isLoading={isLoading}
                className="AuthForm-Button"
                icon="save"
                title="Сохранить профиль"
                fill="black"
                theme="solid"
                size={48}
                onClick={() =>
                  onSubmitProfile({ form, user, setStatusMessage, setShowErrors, setIsLoading, setNewPhoneNumber })
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

class ChangePasswordFields {
  constructor() {
    this.password = {
      field: {
        fieldId: 'password',
        fieldType: 'input',
        inputType: 'password',
        placeholder: 'Введите пароль',
        label: 'Текущий пароль',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.newPassword = {
      field: {
        fieldId: 'newPassword',
        fieldType: 'input',
        inputType: 'password',
        placeholder: 'Введите пароль',
        label: 'Новый пароль',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.confirmPassword = {
      field: {
        fieldId: 'confirmPassword',
        fieldType: 'input',
        inputType: 'password',
        placeholder: 'Введите пароль',
        label: 'Подтвердите пароль',
        required: false,
      },
      render: getFieldRenderObject(),
    }
  }
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(EditProfile)
