import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './SalesPlans.sass'

import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import toNumber from 'lodash/toNumber'
import isNaN from 'lodash/isNaN'
import { isNull, orderBy } from 'lodash'
import Icon from '../../../../../components/UI/Icon/Icon'

import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import SceneTable from '../../../../../components/UI/SceneContent/SceneTable/SceneTable'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import addNewSalesPlan from './functions/addNewSalesPlan'
import { db } from '../../../../../config/firebase'
import { countAvailablePlan, countEmployeeAvailablePlan } from './functions/countPlans'
import { renderSquadsTable, renderEmployeesTable } from './functions/renderSalesPlans'
import { getMembersSalesPlanObject } from './functions/getSalesPlanObject'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import fetchSalePlan from './functions/fetchSalePlan'
import getDoc from '../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import { addDoc } from '../../../../../utilities/db/addDoc'

function SalesPlans({ accountId, uid }) {
  const [showForSquad, setShowForSquad] = useState(null)
  const [salesPlans, setSalesPlans] = useState(null)

  const [planOnEdit, setPlanOnEdit] = useState({
    planId: null,
    officeId: null,
    squadId: null,
    employeeId: null,
  })

  useEffect(() => {
    const listener = db.collection(`${rootDbPath(accountId)}/config/deals/plans`).onSnapshot((snap) => {
      let collection = []
      snap.forEach((d) => collection.push({ planId: d.id, ...d.data() }))
      collection = orderBy(collection, ['year'], ['desc'])
      Promise.all(
        collection.map((salePlanObject) => fetchSalePlan({ accountId, salePlanObject })),
      ).then((fetchedSalesPlans) => setSalesPlans(fetchedSalesPlans))
    })

    return () => {
      listener()
    }
  }, [])

  const editPlan = (id) => {
    const { planId, officeId, squadId, employeeId } = id

    const objectToEdit = {}

    if (planId && officeId) {
      const plan = find(salesPlans, ['planId', planId])
      if (plan) {
        const officeOnEdit = cloneDeep(find(plan.offices, ['officeId', officeId]))
        if (officeOnEdit) {
          const squadOnEdit = cloneDeep(find(officeOnEdit.squads, ['squadId', squadId]))

          objectToEdit.planId = planId
          objectToEdit.officeId = officeId
          objectToEdit.squadId = squadId || null
          objectToEdit.employeeId = employeeId || null

          for (let index = 1; index <= 12; index++) {
            objectToEdit[index] = {
              totalPlan: squadId
                ? employeeId
                  ? squadOnEdit[index].members[employeeId].totalPlan
                  : squadOnEdit[index].totalPlan
                : officeOnEdit[index],
              availablePlan: squadId
                ? employeeId
                  ? countEmployeeAvailablePlan(squadOnEdit, index)
                  : countAvailablePlan(officeOnEdit, index)
                : 0,
            }
          }

          setPlanOnEdit(objectToEdit)
        }
      }
    }
  }

  const addPlan = () => {
    addNewSalesPlan({ accountId, year: 2021 }).then((salesPlan) =>
      addDoc({ accountId, path: 'config/deals/plans', data: salesPlan }),
    )
  }

  const fieldChangedHandler = (e, fieldIdentifier) => {
    const planOnEditClone = cloneDeep(planOnEdit)
    const value = toNumber(e.target.value)
    if (!isNaN(value)) {
      planOnEditClone[fieldIdentifier].totalPlan = value

      const plan = find(salesPlans, ['planId', planOnEditClone.planId])
      const officeOnEdit = find(plan.offices, ['officeId', planOnEditClone.officeId])

      if (planOnEditClone.squadId && !planOnEditClone.employeeId) {
        const availablePlan = countAvailablePlan(officeOnEdit, fieldIdentifier, false, planOnEditClone)
        const newAvailablePlan = value > availablePlan ? 0 : availablePlan - value

        if (newAvailablePlan === 0) {
          planOnEditClone[fieldIdentifier].totalPlan = availablePlan
        }

        planOnEditClone[fieldIdentifier].availablePlan = newAvailablePlan
      }

      if (planOnEditClone.employeeId) {
        const squadData = find(officeOnEdit.squads, ['squadId', planOnEditClone.squadId])
        const availablePlan = countEmployeeAvailablePlan(squadData, fieldIdentifier, false, planOnEditClone)
        const newAvailablePlan = value > availablePlan ? 0 : availablePlan - value

        if (newAvailablePlan === 0) {
          planOnEditClone[fieldIdentifier].totalPlan = availablePlan
        }

        planOnEditClone[fieldIdentifier].availablePlan = newAvailablePlan
      }

      setPlanOnEdit(planOnEditClone)
    }
  }

  const savePlan = () => {
    getDoc({ accountId, path: 'config/deals/plans', docId: planOnEdit.planId })
      .then((dbPlan) => {
        const officeToModify = find(dbPlan.offices, ['officeId', planOnEdit.officeId])

        for (let index = 1; index <= 12; index++) {
          if (!planOnEdit.squadId) {
            officeToModify[index] = planOnEdit[index].totalPlan
          } else {
            let squadToModify = find(officeToModify.squads, ['squadId', planOnEdit.squadId])

            if (!squadToModify) {
              const plan = find(salesPlans, ['planId', planOnEdit.planId])
              const office = find(plan.offices, ['officeId', planOnEdit.officeId])
              squadToModify = cloneDeep(find(office.squads, ['squadId', planOnEdit.squadId]))
              officeToModify.squads.push(squadToModify)
            }

            if (!planOnEdit.employeeId) {
              squadToModify[index].totalPlan = planOnEdit[index].totalPlan
            } else {
              const employeeObject = squadToModify[index].members[planOnEdit.employeeId]
              if (employeeObject) {
                squadToModify[index].members[planOnEdit.employeeId].totalPlan = planOnEdit[index].totalPlan
              } else {
                squadToModify[index].members[planOnEdit.employeeId] = getMembersSalesPlanObject({
                  totalPlan: planOnEdit[index].totalPlan,
                })
              }
            }
          }
        }

        return updateDoc({ accountId, path: 'config/deals/plans', docId: planOnEdit.planId, data: dbPlan })
      })
      .then(() => setPlanOnEdit({}))
  }

  return (
    <>
      <SceneContent
        class="SalesPlans"
        title={
          <div className="SalesPlans-SectionTitle">
            <span
              className={showForSquad ? 'DecorateLink' : ''}
              onClick={showForSquad ? () => setShowForSquad(null) : ''}
            >
              Управление планами продаж
            </span>
            {showForSquad && (
              <>
                <Icon name="angle-right" weight="solid" />
                <span>{`Отдел: ${showForSquad.squadTitle}`}</span>
              </>
            )}
          </div>
        }
        buttons={[
          {
            type: 'add',
            icon: 'plus',
            clicked: addPlan,
          },
        ]}
      >
        {isNull(salesPlans) ? (
          <Spinner type="module" styles={{ marginTop: '32px' }} />
        ) : (
          salesPlans.map((p) => {
            const body = []

            if (showForSquad) {
              const office = find(p.offices, (o) => find(o.squads, ['squadId', showForSquad.squadId]))
              const squadData = find(office.squads, ['squadId', showForSquad.squadId])

              renderEmployeesTable(
                p.planId,
                office.officeId,
                squadData,
                planOnEdit,
                setPlanOnEdit,
                savePlan,
                editPlan,
                body,
                fieldChangedHandler,
                office.members,
              )
            } else {
              renderSquadsTable(
                p,
                planOnEdit,
                setPlanOnEdit,
                savePlan,
                editPlan,
                body,
                fieldChangedHandler,
                setShowForSquad,
              )
            }

            return (
              <>
                <div className="SalesPlans-AccentCell SalesPlans-YearTitle">{p.year} г</div>
                <SceneTable
                  editOnDoubleClick
                  header={[
                    ...(showForSquad
                      ? [['Сотрудник', 200]]
                      : [
                          ['Офис', 120],
                          ['Отдел', 120],
                        ]),
                    ['Январь'],
                    ['Февраль'],
                    ['Март'],
                    ['Кв. I', , 'SalesPlans-AccentCell'],
                    ['Апрель'],
                    ['Май'],
                    ['Июнь'],
                    ['Кв. II', , 'SalesPlans-AccentCell'],
                    ['Июль'],
                    ['Август'],
                    ['Сентябрь'],
                    ['Кв. III', , 'SalesPlans-AccentCell'],
                    ['Октябрь'],
                    ['Ноябрь'],
                    ['Декабрь'],
                    ['Кв. IV', , 'SalesPlans-AccentCell'],
                    ['Год', , 'SalesPlans-AccentCell'],
                    ['', 32],
                  ]}
                  body={body}
                  useCustomOptions
                />
              </>
            )
          })
        )}
      </SceneContent>
    </>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(SalesPlans))
