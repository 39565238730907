import React from 'react'
import './InfoLine.sass'

function InfoLine({ title, content }) {
  return (
    <p className="InfoLine">
      <span className="Bolder">{title}:</span>
      {content}
    </p>
  )
}

export default InfoLine
