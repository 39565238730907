import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebase, db } from './config/firebase'
import CardBox from './CardBox'
import Website from './website'
import * as actions from './store/actions'

function App({ authIsLoading, authGetState, ...router }) {
  useEffect(() => {
    let userDocListener
    const userStateListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        userDocListener = db
          .collection('users')
          .doc(user.uid)
          .onSnapshot((userData) => {
            if (userData.exists) authGetState({ uid: user.uid, ...userData.data() })
          })
      } else {
        authGetState(null)
        if (typeof userDocListener === 'function') userDocListener()
      }
    })

    return () => {
      userStateListener()
      if (typeof userDocListener === 'function') userDocListener()
    }
  }, [])

  return authIsLoading ? (
    ''
  ) : (
    <>
      <Switch>
        <Route path="/app/:accountId/" component={CardBox} />
        <Route path="/" component={Website} />
      </Switch>
    </>
  )
}

const mapStateToProps = (state) => ({ authIsLoading: state.auth.isLoading })
const mapDispatchToProps = (dispatch) => ({ authGetState: (payload) => dispatch(actions.authGetState(payload)) })

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App)
