import React, { useState } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../DeletePopUp/DeletePopUp'

const DeleteFilePopUp = ({ file, deleteFunc, close }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteFunc(file).then(() => close())
  }

  return (
    <DeletePopUp
      title="Удаление файла"
      warningMessage={
        <>
          Вы уверены, что хотите удалить <span className="Bolder">"{file.title}"</span>
          ?
          <br />
          Восстановить удалённый файл будет невозможно.
        </>
      }
      fieldLabel="Чтобы удалить этот файл, введите его идентификатор:"
      identifier={file.fileName}
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid })

export default connect(mapStateToProps)(DeleteFilePopUp)
