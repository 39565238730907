import React from 'react'
import { PinnedMemberSkeleton } from './PinnedMember/PinnedMember'
import Skeleton from '../../../../../../../components/UI/Skeleton/Skeleton'

function MembersManagerSkeleton() {
  return (
    <div className="MembersManager-Container">
      <PinnedMemberSkeleton />
      <div className="MembersManager-UserInfo">
        <p className="SceneContent-Header">
          <Skeleton width={200} height={12} />
        </p>
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <Skeleton height={32} />
        <p className="Field-Textarea">
          <Skeleton height={70} />
        </p>
      </div>
    </div>
  )
}

export default MembersManagerSkeleton
