import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = {
  contactsObjects: {},
  sortedContactsObjects: [],
  rawMergedContactsObjects: [],
  isLoading: true,
}

export const contactsReducer = (state = initialState, action) => {
  const stateClone = cloneDeep(state)

  switch (action.type) {
    case ACTION_TYPES.CONTACTS_SEND_DATA_TO_REDUCER:
      return initContactsTableWithData(action)
    case ACTION_TYPES.CONTACTS_DELETE_CONTACT:
      return deleteContact(stateClone, action)
    default:
      return state
  }
}

const initContactsTableWithData = (action) => {
  const { contactsObjects, sortedContactsObjects, rawMergedContactsObjects } = action.payload
  return {
    contactsObjects,
    sortedContactsObjects,
    rawMergedContactsObjects,
  }
}

const deleteContact = (stateClone, { payload: { contactId } }) => {
  const index = findIndex(stateClone.sortedContactsObjects, ['contactId', contactId])
  stateClone.sortedContactsObjects.splice(index, 1)
  return stateClone
}
