import { firebase } from '../../../../../../config/firebase'
import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'

const updateEstateContact = ({ accountId, contacts, estateId, uid, operationType }) =>
  Promise.all(
    contacts.map((c) =>
      updateDoc({
        accountId,
        path: 'contacts',
        docId: c,
        data: {
          estate:
            operationType === 'add'
              ? firebase.firestore.FieldValue.arrayUnion(estateId)
              : firebase.firestore.FieldValue.arrayRemove(estateId),
          updated: getCurDateWithUser(uid),
        },
      }),
    ),
  )

export default updateEstateContact
