import React, { useState } from 'react'
import Icon from '../../../components/UI/Icon/Icon'
import ConversionBlock from '../../components/ConversionBlock/ConversionBlock'
import Faq from '../../components/Faq/Faq'
import SiteSectionTitle from '../../components/SiteSectionTitle/SiteSectionTitle'
import TryFreeButton from '../../components/TryFreeButton/TryFreeButton'
import './Pricing.sass'

function Pricing() {
  const prices = [
    { title: '3 Месяца', price: 999, oldPrice: null, discount: null },
    { title: '6 Месяцев', price: 899, oldPrice: 999, discount: 10 },
    { title: '12 Месяцев', price: 799, oldPrice: 999, discount: 20 },
    { title: '24 Месяца', price: 699, oldPrice: 999, discount: 30 },
  ]

  const [activeTarif, setActiveTarif] = useState(2)

  return (
    <>
      <div className="Site-Pricing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SiteSectionTitle
                title="Простые и понятные тарифные планы"
                subTitle="Мы не ограничиваем функционал CRM в зависимости от тарифа. Весь функционал CRM доступен всем пользователям."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Site-Pricing-Container">
                <div className="Site-Pricing-InfoBlock">
                  <p className="Site-TitleText">Что входит в тариф?</p>
                  <div className="Pricing-TarifDescription-Container">
                    <MarkedText text="Неограниченое кол-во контактов, сделок, объектов, задач" />
                    <MarkedText text={`Полный функционал раздела "Задачи"`} />
                    <MarkedText text={`Полный функционал раздела "Аналитика"`} />
                    <MarkedText text="Интеграция с почтой и телефонией (звонки из карточки)" />
                    <MarkedText text="Воронки продаж" />
                    <MarkedText text="Полное сопровождение и поддержка" />
                    <MarkedText text="Миграция из других CRM" />
                  </div>
                  <p className="Pricing-Note">Минимальная стоимость пользования CardBox 3750 руб/мес.</p>
                </div>
                <div className="Site-Pricing-TarifTiles">
                  <p className="TarifTiles-Title Site-TitleText">Цена за 1 пользователя в месяц</p>
                  {prices.map((p, i) => (
                    <TarifTile key={p.title} {...p} isActive={activeTarif === i} onClick={() => setActiveTarif(i)} />
                  ))}
                  <div className="Pricing-SubmitButton">
                    <TryFreeButton size={48} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Faq />
      <ConversionBlock />
    </>
  )
}

const MarkedText = ({ text }) => (
  <p className="MarkedText">
    <span className="MarkedText-Title">{text}</span>
    <div className="MarkedText-Icon">
      <Icon name="check" />
    </div>
  </p>
)

const TarifTile = ({ title, oldPrice, price, discount, isActive, onClick }) => (
  <div onClick={onClick} className={['Site-TarifTile', ...(isActive ? ['Site-TarifTile_active'] : [])].join(' ')}>
    <div className="TarifTile-CheckIcon">
      <Icon name="check" />
    </div>
    <div className="TarifTile-Info">
      <p className="TarifTile-Info-Title">{title}</p>
      {discount && <p className="TarifTile-Info-Discount">Скидка {discount}%</p>}
    </div>
    <div className="TarifTile-Price">
      {oldPrice && (
        <p className="Price-OldPrice">
          <span>{oldPrice} </span>
          <span className="Price-Currency">руб</span>
        </p>
      )}
      <p>
        <span>{price} </span>
        <span className="Price-Currency">руб</span>
      </p>
    </div>
  </div>
)

export default Pricing
