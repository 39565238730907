import React, { useState, useEffect } from 'react'
import './ContactBlock.sass'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import BarInfoBlock from '../BarInfoBlock/BarInfoBlock'
import rootPath from '../../../../appTree/rootPath'

function ContactBlock({ accountId, contactId, contactData, setContactData }) {
  const [name, setName] = useState('')

  useEffect(() => {
    if (contactData) {
      const { lastName, name, middleName } = contactData
      setName(`${lastName} ${name} ${middleName}`)
      setContactData(contactData)
    } else {
      setName('')
      setContactData({})
    }
  }, [contactData])

  const block = (
    <BarInfoBlock className="Dialer-ContactBlock">
      <p className="ContactBlock-Title">Контакт</p>
      <p className="ContactBlock-ContactName">{name}</p>
    </BarInfoBlock>
  )

  return contactId ? <Link to={`${rootPath(accountId)}/contacts/list/${contactId}/edit`}>{block}</Link> : block
}

const mapStateToProps = (state, ownProps) => ({
  accountId: state.auth.account.data.accountId,
  contactData: ownProps.contactId ? state.contacts.contactsObjects[ownProps.contactId] : null,
})

export default connect(mapStateToProps)(ContactBlock)
