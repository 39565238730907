const { merge } = require('lodash')
const getCurDateWithUser = require('../getCurDateWithUser')
const rootDbPath = require('./rootDbPath')

const updateDoc = ({ db, accountId, path, docId, data, uid = null }) => {
  let dbPath
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }
  let dataToUpdate = data
  if (uid) {
    dataToUpdate = merge(data, { updated: getCurDateWithUser(uid) })
  }
  return db
    .collection(dbPath)
    .doc(docId)
    .update(dataToUpdate)
    .then(() => docId)
    .catch((e) => {
      console.log('🚀 ~ file: updateDoc.js ~ line 10 ~ updateDoc ~ e', e)
      return 'fail'
    })
}

module.exports = updateDoc
