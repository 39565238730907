import { db } from '../../../../../config/firebase'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import { castComparisonVal } from './buildTasksDbQuery'

const getTasks = ({ accountId, responsible = null, columns } = {}) => {
  const promises = {}

  columns.forEach((c) => {
    let dbRef = db.collection(`${rootDbPath(accountId)}/tasks`)

    c.filterRules.forEach((r) => {
      dbRef = dbRef.where(r.comparisonField, r.op, castComparisonVal(r.comparisonVal))
    })

    promises[c.columnId] = dbRef.get().then((docs) => {
      const tasks = []
      docs.forEach((d) => tasks.push({ taskId: d.id, ...d.data() }))
      return tasks.filter((t) => t.responsible.every((v) => responsible.concat('all').includes(v)))
    })
  })

  return promiseAllValues(promises)
}
export default getTasks
