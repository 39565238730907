import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'

export function CategoryPopUpForm({ uid } = {}) {
  this.label = {
    field: {
      fieldId: 'label',
      fieldType: 'input',
      inputType: 'text',
      required: true,
      label: 'Название категории',
    },
    render: getFieldRenderObject(),
  }
  this.systemCategory = {
    field: {
      fieldId: 'systemCategory',
      value: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.estateTypes = {
    field: {
      fieldId: 'estateTypes',
      value: [],
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.created = {
    field: {
      fieldId: 'created',
      value: {
        at: new Date(),
        by: uid,
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.updated = {
    field: {
      fieldId: 'updated',
      value: {
        at: new Date(),
        by: uid,
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.position = {
    field: {
      fieldId: 'position',
      value: '',
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
}
