import { isEmpty, isObject } from 'lodash'

const isFormWithValues = ({ form, checkSystemFields = false }) => {
  if (!form) return false
  const hasValues = [false]

  Object.entries(form).forEach(([k, v]) => {
    if (v.render.byDefault) {
      switch (v.fieldType) {
        case 'address':
          if (v.value.formattedAddress) {
            hasValues.push(true)
          }
          break
        case 'phone':
          if (v.value.value) {
            hasValues.push(true)
          }
          break
        case 'dateRange':
          if (v.value.from || v.value.to) {
            hasValues.push(true)
          }
          break
        default: {
          if (v.render.isSystem && checkSystemFields) {
            if (isObject(v.values) && !isEmpty(v.values)) return hasValues.push(true)
            if (!isObject(v.values) && v.values) return hasValues.push(true)
          } else if (v.isFildset) {
            v.values.forEach((fSet) => {
              fSet.forEach((f) => {
                if (isObject(f.value) && !isEmpty(f.value)) return hasValues.push(true)
                if (!isObject(f.value) && f.value) return hasValues.push(true)
              })
            })
          } else {
            if (isObject(v.value) && !isEmpty(v.value)) return hasValues.push(true)
            if (!isObject(v.value) && v.value) return hasValues.push(true)
          }
        }
      }
    }
  })

  return hasValues.some((el) => el && true)
}

export default isFormWithValues
