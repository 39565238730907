import React from 'react'
import Button from '../../../../ui/Button/Button'

function SetupButton({
  installs,
  text,
  icon = 'arrow-alt-to-bottom',
  border = 'gray',
  color = 'black',
  onClick = () => null,
  isLoading,
  className = 'SetupButton',
}) {
  return (
    <Button
      onClick={onClick}
      icon={icon}
      theme="bounded"
      border={border}
      size={28}
      title={installs || text}
      color={color}
      isLoading={isLoading}
      spinnerColor="gray"
      className={className}
    />
  )
}

export default SetupButton
