export const getCallBarObject = ({
  direction = '',
  currentState = '',
  personality = '',
  contactId,
  phone = '',
  responsible,
  systemCallControll = false,
} = {}) => ({
  direction,
  currentState,
  personality,
  contactId,
  phone,
  responsible,
  systemCallControll,
})
