import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Memberslist from './Memberslist/Memberslist'
import MembersManager from './Memberslist/MembersManager/MembersManager'
import rootPath from '../../../../../appTree/rootPath'

function Members({ accountId }) {
  return (
    <>
      <Route
        path={[
          `${rootPath(accountId)}/settings/members/list`,
          `${rootPath(accountId)}/settings/members/list/page/:page`,
          `${rootPath(accountId)}/settings/members/list/add`,
          `${rootPath(accountId)}/settings/members/list/:id/edit`,
        ]}
        exact
        render={() => <Memberslist />}
      />
      <Route
        path={[
          `${rootPath(accountId)}/settings/members/list/add`,
          `${rootPath(accountId)}/settings/members/list/:id/edit`,
        ]}
        exact
        render={() => <MembersManager closeLink={`${rootPath(accountId)}/settings/members/list`} />}
      />
    </>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Members)
