import find from 'lodash/find'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import countComission from './countComission'

const countNewStarts = ({ step, deals, dateRange, responsible }) => {
  const filteredData = deals.filter((d) => {
    const funnelStep = find(d.funnel, ['stepId', step.stepId])

    if (d.finishedWith.status) return false

    if (
      funnelStep &&
      responsible.includes(d.responsible) &&
      funnelStep.started &&
      isAfter(funnelStep.started.at.seconds * 1000, dateRange.startingDate) &&
      isBefore(funnelStep.started.at.seconds * 1000, dateRange.endingDate)
    ) {
      return true
    }
    return false
  })
  return countComission(filteredData)
}

export default countNewStarts
