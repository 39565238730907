import React from 'react'
import './SideEvents.sass'
import 'react-toastify/dist/ReactToastify.min.css'

import { ToastContainer, toast } from 'react-toastify'

export function SideEventsContainer({ callBarStatus }) {
  return (
    <ToastContainer
      enableMultiContainer
      containerId={'sideEvents'}
      position={toast.POSITION.BOTTOM_RIGHT}
      autoClose={false}
      closeButton={false}
      closeOnClick={false}
      className={['SideEvents-Container', ...(callBarStatus ? ['Layout-Content_theme_callbar_offset'] : [])].join(' ')}
      toastClassName="SideEvents_Event"
      progressClassName="SideEvents-ProgressBar"
    />
  )
}
