import differenceInDays from 'date-fns/differenceInDays'

const countDealsAverageDuration = ({ deals }) => {
  const totalDeals = deals.length
  let totalDays = 0

  deals.forEach((d) => {
    totalDays += differenceInDays(d.finishedWith.date.seconds * 1000, d.created.at.seconds * 1000)
  })

  const dealsAverageDuration = Math.round(totalDays / totalDeals)
  return isNaN(dealsAverageDuration) ? 0 : isFinite(dealsAverageDuration) ? dealsAverageDuration : 0
}

export default countDealsAverageDuration
