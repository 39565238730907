import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import countComission, { countFactComission } from './countComission'
import { getFactLooses } from './countFactLooses'

const countFactWins = ({ deals, dateRange, responsible }) => {
  const filteredData = deals.filter((d) => {
    if (!responsible.includes(d.responsible)) return false
    if (d.finishedWith.status !== 'success') return false
    if (
      !d.finishedWith.date ||
      !isAfter(d.finishedWith.date.seconds * 1000, dateRange.startingDate) ||
      !isBefore(d.finishedWith.date.seconds * 1000, dateRange.endingDate)
    ) {
      return false
    }
    return true
  })

  const factWins = countComission(filteredData)
  factWins.moneyCounter += countFactComission({ deals: getFactLooses({ deals, dateRange, responsible }) }).moneyCounter

  return factWins
}

export default countFactWins
