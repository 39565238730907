import React, { useState } from 'react'
import './SubscriptionEvent.sass'
import DialerSideEvent from '../DialerSideEvent/DialerSideEvent'
import SubscriptionIcon from '../assets/SubscriptionIcon.svg'
import Spinner from '../../../components/UI/Spinner/Spinner'

function SubscriptionEvent({
  extensionTitle,
  status,
  statusTitle,
  phone,
  closeToast: closeEvent,
  showSubscribeButton,
  onSubscribe,
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DialerSideEvent
      className="SubscriptionEvent"
      img={{ src: SubscriptionIcon, alt: status }}
      title={extensionTitle}
      closeEvent={closeEvent}
    >
      {phone && <p>Подписка по номеру: {phone}</p>}
      <p className={`SubscriptionEvent_status_theme_${status}`}>{statusTitle}</p>
      {showSubscribeButton && !isLoading && (
        <div
          onClick={() => {
            setIsLoading(true)
            onSubscribe().finally(() => setIsLoading(false))
          }}
          className="SubscriptionEvent-ResubscribeButton"
        >
          Подключиться повторно
        </div>
      )}
      {isLoading && (
        <p className="SpinnerContainer">
          <Spinner type="popup" />
        </p>
      )}
    </DialerSideEvent>
  )
}

export default SubscriptionEvent
