import UndefinedCall from '../assets/UndefinedCall.svg'
import InboundCall from '../assets/InboundCall.svg'
import OutboundCall from '../assets/OutboundCall.svg'

export const getCallIcon = direction => {
  switch (direction) {
    case 'inbound':
      return InboundCall
    case 'outbound':
      return OutboundCall
    default:
      return UndefinedCall
  }
}
