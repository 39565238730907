import React from 'react'
import './CallStatus.sass'

import { parseCallStatus, parseCallDirection, getCallIcon } from '../../functions/'

function CallStatus({ direction, status, personality }) {
  const parsedStatus = parseCallStatus(status, personality)
  const directionName = parseCallDirection(direction)

  return (
    <div className="CallStatus">
      <img className="CallDirectionIcon" src={getCallIcon(direction)} alt={directionName} />
      <div className="CallStatus-Container">
        <p>{directionName}</p>
        <p>
          Статус:{' '}
          <span
            className={[
              'CallStatus-Status',
              ...(parsedStatus.theme ? [`CallStatus-Status_theme_${parsedStatus.theme}`] : []),
            ].join(' ')}
          >
            {parsedStatus.title}
          </span>
        </p>
      </div>
    </div>
  )
}

export default CallStatus
