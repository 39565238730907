import React, { useState } from 'react'
import { connect } from 'react-redux'
import isNull from 'lodash/isNull'

import './CallBar.sass'
import CallStatus from './CallStatus/CallStatus'
import ContactBlock from './ContactBlock/ContactBlock'
import SalesBlock from './SalesBlock/SalesBlock'
import EstateBlock from './EstateBlock/EstateBlock'
import CallTracker from './CallTracker/CallTracker'
import ActionButtons from './ActionButtons/ActionButtons'
import { getCallBarObject } from './functions'
import { microButton, microDisabledButton } from '../DialerButton/ButtonTypes'
import NumberPad from '../NumberPad/NumberPad'

function CallBar({ activeCall: call }) {
  const [contactData, setContactData] = useState({})
  return (
    <div className="CallBar">
      <div className="CallBar-InfoBlocks">
        <CallStatus direction={call.direction} status={call.currentState} personality={call.personality} />
        <ContactBlock contactId={call.contactId} setContactData={setContactData} />
        {/* <LeadsBlock leads={contactData.hasOwnProperty('leads') ? contactData.leads : []} /> */}
        <SalesBlock sales={contactData.hasOwnProperty('sales') ? contactData.sales : []} />
        <EstateBlock estates={contactData.hasOwnProperty('estate') ? contactData.estate : []} />
      </div>
      <div className="CallBar-Tools">
        <CallTracker call={call} />
        <ActionButtons buttons={[...(call.systemCallControll ? [microButton] : [microDisabledButton])]} />
        <NumberPad />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeCall: isNull(state.dialer.activeCall) ? getCallBarObject() : state.dialer.activeCall,
})

export default connect(mapStateToProps)(CallBar)
