import { all, put, call } from 'redux-saga/effects'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { db } from '../../../../config/firebase'
import * as actions from '../actions/tasksActions'
import { store } from '../../../../index'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import buildTasksDbQuery from '../../scenes/Tasks/functions/buildTasksDbQuery'
import fetchTask from './functions/fetchTask'
import getCollection from '../../../../utilities/db/getCollection'
import rootDbPath from '../../../../utilities/db/rootDbPath'

export function* getColumnsWithTasksSaga({ payload: { accountId, uid } }) {
  const columns = yield getCollection({
    accountId,
    path: 'config/tasks/columns',
    orderBy: { fieldPath: 'position' },
    docIdName: 'columnId',
  })

  const rawTasksByColumns = yield getNextTasks({ accountId, columns, uid })

  const fetchedTasks = yield all(
    rawTasksByColumns.map((c) =>
      call(function* () {
        return yield all(c.tasks.map((task) => fetchTask({ accountId, task })))
      }),
    ),
  )

  columns.forEach((c, index) => {
    c.tasks = fetchedTasks[index]
    const col = find(rawTasksByColumns, ['columnId', c.columnId])
    c.totalTasks = col.totalTasks
    if (fetchedTasks[index].length !== col.totalTasks) {
      c.lastDocumentSnapshot = col.lastDocumentSnapshot
      c.hasMore = true
    } else {
      c.lastDocumentSnapshot = null
      c.hasMore = false
    }
  })

  yield put(actions.tasksSendDataToReducer({ columns: columns.filter((c) => c.onMountFetch), rawColumns: columns }))
}

export function* fetchChunk({ payload: { accountId, uid } }) {
  const state = store.getState()
  const columns = cloneDeep(state.tasks.columns)

  const rawTasksByColumns = yield getNextTasks({ accountId, columns, uid, useLastDoc: true })

  const fetchedTasks = yield all(
    rawTasksByColumns.map((c) =>
      call(function* () {
        return yield all(c.tasks.map((task) => fetchTask({ accountId, task })))
      }),
    ),
  )
  columns.forEach((c, index) => {
    c.tasks = c.tasks.concat(fetchedTasks[index])

    if (rawTasksByColumns[index].tasks.length !== rawTasksByColumns[index].totalTasks) {
      c.lastDocumentSnapshot = find(rawTasksByColumns, ['columnId', c.columnId]).lastDocumentSnapshot
      c.hasMore = true
    } else {
      c.lastDocumentSnapshot = null
      c.hasMore = false
    }
  })

  yield put(actions.tasksUpdateColumns(columns))
}

const getNextTasks = ({ accountId, columns, uid, useLastDoc = false }) => {
  const columnsTasks = []

  columns.forEach((c) => {
    let query = buildTasksDbQuery({
      dbRef: db.collection(`${rootDbPath(accountId)}/tasks`),
      rules: c.filterRules,
      orderBy: c.orderBy,
      uid,
    })
    if (useLastDoc && c.lastDocumentSnapshot) query = query.startAfter(c.lastDocumentSnapshot)

    let columnPromises

    if ((useLastDoc && c.lastDocumentSnapshot) || !useLastDoc) {
      columnPromises = {
        columnId: c.columnId,
        tasks: query
          .limit(15)
          .get()
          .then((docs) => {
            columnPromises.lastDocumentSnapshot = docs.docs[docs.docs.length - 1]
            const rawTasks = []
            docs.forEach((d) => rawTasks.push({ taskId: d.id, ...d.data() }))

            return rawTasks
          }),
        totalTasks: query.get().then((docs) => docs.docs.length),
        lastDocumentSnapshot: query
          .limit(15)
          .get()
          .then((docs) => docs.docs[docs.docs.length - 1]),
      }
    } else {
      columnPromises = {
        columnId: c.columnId,
        tasks: [],
        totalTasks: 0,
        lastDocumentSnapshot: null,
      }
    }

    columnsTasks.push(promiseAllValues(columnPromises))
  })

  return Promise.all(columnsTasks)
}
