import React from 'react'
import SiteSectionTitle from '../../components/SiteSectionTitle/SiteSectionTitle'
import './PrivacyPolicy.sass'

function PrivacyPolicy() {
  return (
    <div className="Site-PrivacyPolicy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SiteSectionTitle title="Политика обработки персональных данных" subTitle="Редакция от: 28 марта 2021" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="PrivacyPolicy-Container">
              <h5>1. Термины и определения</h5>
              <p>
                1.1. Персональные данные (далее – ПД) – любая информация, относящаяся к прямо или косвенно определенному
                или определяемому физическому лицу (субъекту персональных данных).
              </p>
              <p>
                1.2. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо,
                самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных
                данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными данными.
              </p>
              <p>
                1.3. Обработка ПД – любое действие (операция) или совокупность действий (операций), совершаемых с
                использованием средств автоматизации или без использования таких средств с персональными данными,
                включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
              </p>
              <p>
                1.4. Автоматизированная обработка ПД – обработка персональных данных с помощью средств вычислительной
                техники.
              </p>
              <p>
                1.5. Предоставление ПД – действия, направленные на раскрытие персональных данных определенному лицу или
                определенному кругу лиц.
              </p>
              <p>
                1.6. Уничтожение ПД – действия, в результате которых становится невозможным восстановить содержание
                персональных данных в информационной системе персональных данных и (или) в результате которых
                уничтожаются материальные носители персональных данных.
              </p>
              <p>
                1.7. Обезличивание ПД – действия, в результате которых становится невозможным без использования
                дополнительной информации определить принадлежность персональных данных конкретному субъекту
                персональных данных.
              </p>
              <p>
                1.8. Информационная система ПД (ИСПД) – совокупность содержащихся в базах данных персональных данных и
                обеспечивающих их обработку информационных технологий и технических средств.
              </p>
              <p>
                1.9. Трансграничная передача ПД – передача персональных данных на территорию иностранного государства
                органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому
                лицу.
              </p>
              <p>
                1.10. Субъект ПД - физическое лицо, которое прямо или косвенно определено, или определяемо с помощью
                персональных данных.
              </p>
              <p>1.11. ФЗ «О персональных данных» - Федеральный закон от 27.07.2006 г. № 152 «О персональных данных»</p>
              <p>
                1.12. Продукты Оператора – лицензии на использование программного обеспечения, сопутствующие услуги,
                сертификаты технической поддержки, мероприятия, организатором которых выступает CardBox.
              </p>
              <p>1.13. Сайты Оператора – www.cdbx.io, www.cdbx.ru</p>
              <h5>2. Общие положения</h5>
              <p>
                2.1. Политика обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным
                законом от 27.07.2006. №152-ФЗ «О персональных данных» (далее – ФЗ-152).
              </p>
              <p>
                2.2. Настоящая Политика определяет порядок обработки персональных данных, систему, принципы, цели,
                условия и меры по обеспечению безопасности персональных данных в ИП Старкова Мария Вадимовна (далее –
                Оператор) с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в
                том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
              </p>
              <p>
                2.3. Действие настоящей Политики распространяется на все операции и действия Оператора, связанные с
                персональными данными.
              </p>
              <h5>3. Принципы и цели обработки персональных данных</h5>
              <p>3.1. Цели сбора и обработки ПД:</p>
              <p>
                3.1.1. Для исполнения договоров, стороной (представителем, уполномоченным лицом, авторизированным лицом,
                выгодоприобретателем, поручителем) является Субъект ПД;
              </p>
              <p>
                3.1.2. Для заключения договора по инициативе Субъекта ПД, в том числе в роли уполномоченного лица,
                выгодоприобретателя, поручителя;
              </p>
              <p>
                3.1.3. Для обработки входящих запросов физических/юридических лиц с целью реализации продвижения и
                популяризации продуктов Оператора;
              </p>
              <p>
                3.1.4. Для достижения целей, предусмотренных действующим законодательство РФ, для осуществления и
                выполнения возложенных на Оператора функций, полномочий и обязанностей;
              </p>
              <p>
                3.1.5. Для продвижения продуктов Оператора путем осуществления прямых контактов с Субъектом ПД с помощью
                средств связи, включая сеть Интернет.
              </p>
              <p>3.2. Не допускается обработка ПД, не совместимая с целями сбора ПД.</p>
              <p>3.3. Принципы обработки ПД:</p>
              <p>3.3.1. Обработка ПД должна осуществляться на законной и справедливой основе.</p>
              <p>
                3.3.2. Обработка ПД должна ограничиваться достижением конкретных, заранее определенных и законных целей.
              </p>
              <p>
                3.3.3. Не допускается объединение баз данных, содержащих ПД, обработка которых осуществляется в целях,
                несовместимых между собой.
              </p>
              <p>3.3.4. Обработке подлежат только ПД, которые отвечают целям их обработки.</p>
              <p>
                3.3.5. Содержание и объем обрабатываемых ПД должны соответствовать заявленным целям обработки.
                Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их
                обработки.
              </p>
              <p>
                3.3.6. При обработке ПД должны быть обеспечены точность ПД, их достаточность, а в необходимых случаях и
                актуальность по отношению к целям обработки ПД. Оператор должен принимать необходимые меры либо
                обеспечивать их принятие по удалению или уточнению неполных или неточных данных.
              </p>
              <p>
                3.3.7. Хранение ПД должно осуществляться в форме, позволяющей определить субъекта ПД, не дольше, чем
                этого требуют цели обработки ПД, если срок хранения ПД не установлен федеральным законом, договором,
                стороной которого, выгодоприобретателем или поручителем, по которому является субъект ПД. Обрабатываемые
                ПД подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты
                необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
              </p>
              <p>
                3.3.8. Оператор собирает ПД непосредственно у Субъектов ПД. В случае предоставления ПД от третьих лиц,
                такие третьи лица гарантируют получение согласия от субъекта персональных данных на сбор и обработку их
                ПД Оператор в порядке, предусмотренном настоящей Политикой.
              </p>
              <p>
                3.4. Оператор собирает ПД непосредственно у Субъектов ПД. В случае предоставления ПД от третьих лиц,
                такие третьи лица гарантируют получение согласия от субъекта персональных данных на сбор и обработку их
                ПД Оператор в порядке, предусмотренном настоящей Политикой.
              </p>
              <p>
                3.5. Оператор обрабатывает ПД автоматизированными и неавтоматизированными способами, с использованием
                средств вычислительной техники и без использования таких средств.
              </p>
              <h5>4. Условия обработки персональных данных.</h5>
              <p>4.1. Нормативно-правовые акты, которыми руководствуется Оператор при обработке ПД:</p>
              <p>4.1.1. Конституция РФ;</p>
              <p>4.1.2. Трудовой кодекс РФ;</p>
              <p>4.1.3. Гражданский кодекс РФ;</p>
              <p>
                4.1.4 Федеральный закон от 27 июля 2006 года № 149-ФЗ «Об информации, информационных технологиях и
                защите информации»;
              </p>
              <p>
                4.1.5. Указ Президента РФ от 06 марта 1997 г. № 188 «Об утверждении перечня сведений конфиденциального
                характера»;
              </p>
              <p>
                4.1.6. Постановление Правительства РФ от 15 сентября 2008 г. № 687 «Об утверждении Положения об
                особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
              </p>
              <p>
                4.1.7. Постановление Правительства РФ от 01 ноября 2012 г. № 1119 «Об утверждении требований к защите
                персональных данных в информационных системах персональных данных»;
              </p>
              <p>
                4.1.8. Приказ Роскомнадзора от 05 сентября 2013 г. № 996 «Об утверждении требований и методов по
                обезличиванию персональных данных»;
              </p>
              <p>
                4.1.9. Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении состава и содержания
                организационных и технических мер по обеспечению безопасности персональных данных при их обработке в
                информационных системах персональных данных»;
              </p>
              <p>4.1.10. Уставные документы Оператора;</p>
              <p>4.1.11. Договоры, заключаемые между Оператором и субъектами персональных данных;</p>
              <p>4.1.12. Согласия субъектов персональных данных на обработку персональных данных;</p>
              <p>4.1.13. Иные основания, когда согласие на обработку персональных данных не требуется в силу закона.</p>
              <p>4.2. Условия обработки ПД</p>
              <p>
                4.2.1. Обработка персональных данных должна осуществляться с соблюдением принципов и правил,
                предусмотренных ФЗ «О персональных данных». Обработка персональных данных допускается в следующих
                случаях:
              </p>
              <p>
                4.2.1.1. обработка персональных данных осуществляется с согласия субъекта персональных данных на
                обработку его персональных данных;
              </p>
              <p>
                4.2.1.2. обработка персональных данных необходима для достижения целей, предусмотренных международным
                договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством
                Российской Федерации на оператора функций, полномочий и обязанностей;
              </p>
              <p>
                4.2.1.3. обработка персональных данных осуществляется в связи с участием лица в конституционном,
                гражданском, административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах;
              </p>
              <p>
                4.2.1.4. обработка персональных данных необходима для исполнения судебного акта, акта другого органа или
                должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об
                исполнительном производстве (далее - исполнение судебного акта);
              </p>
              <p>
                4.2.1.5. обработка персональных данных необходима для исполнения договора, стороной которого либо
                выгодоприобретателем или поручителем, по которому является субъект персональных данных, а также для
                заключения договора по инициативе субъекта персональных данных или договора, по которому субъект
                персональных данных будет являться выгодоприобретателем или поручителем;
              </p>
              <p>
                4.2.1.6. обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных
                интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;
              </p>
              <p>
                4.2.1.7. обработка персональных данных осуществляется в статистических или иных исследовательских целях,
                за исключением целей, указанных в статье 15 ФЗ «О персональных данных», при условии обязательного
                обезличивания персональных данных;
              </p>
              <p>
                4.2.1.8. осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
                предоставлен субъектом персональных данных либо по его просьбе (далее - персональные данные, сделанные
                общедоступными субъектом персональных данных);
              </p>
              <p>
                4.2.1.9. осуществляется обработка персональных данных, подлежащих опубликованию или обязательному
                раскрытию в соответствии с федеральным законом.
              </p>
              <p>
                4.2.2. Оператор вправе поручить обработку ПД третьим лицам с согласия субъекта ПД, если иное не
                предусмотрено действующим законодательством, на основании заключаемого с этим третьим лицом договора
                (далее – поручение оператора). Такое третье лицо обязано соблюдать принципы и правила обработки ПД,
                предусмотренные настоящей политикой и действующим законодательством.
              </p>
              <p>
                4.2.3. В случае, если Оператор поручает обработку ПД третьему лицу, ответственность перед субъектом ПД
                за действия третьего лица несет Оператор. Лицо, осуществляющее обработку ПД по поручению Оператора,
                несет ответственность перед Оператором.
              </p>
              <p>
                4.2.4. Оператор не осуществляет обработку ПД, касающихся расовой, национальной принадлежности,
                политических взглядов, религиозных, философских и иных убеждений, интимной жизни.
              </p>
              <p>
                4.2.5. Оператор может осуществлять обработку ПД о состоянии здоровья субъекта ПД (работников Оператора)
                в следующих случаях:
              </p>
              <p>
                4.2.5.1. в соответствии с законодательством о государственной социальной помощи, трудовым
                законодательством, законодательством о пенсиях по государственному пенсионному обеспечению, трудовых
                пенсиях;
              </p>
              <p>
                4.2.5.2. для защиты жизни, здоровья или иных жизненно важных интересов работника либо для защиты жизни,
                здоровья или иных жизненно важных интересов других лиц и получение согласия субъекта ПД невозможно;
              </p>
              <p>
                4.2.5.3. для установления или осуществления прав работника или третьих лиц, а равно и в связи с
                осуществлением правосудия;
              </p>
              <p>
                4.2.5.4. в соответствии с законодательством об обязательных видах страхования, со страховым
                законодательством.
              </p>
              <p>
                4.2.6. Биометрические ПД (сведения, которые характеризируют физиологические и биологические особенности
                человека, на основании которых можно установить его личность и которые используются оператором для
                установления личности субъекта ПД) Оператор не обрабатывает.
              </p>
              <p>
                4.2.7. ПД, срок обработки (хранения) которых истек, должны быть уничтожены, если иное не предусмотрено
                законодательством. Хранение ПД после прекращения их обработки допускается только после их обезличивания.
              </p>
              <p>
                4.3. Объем и категории обрабатываемых ПД.
                <br />
                Категории субъектов ПД:
                <br />
                - работники и бывшие работники Оператора;
                <br />
                - соискатели на замещение вакантных должностей;
                <br />
                - родственники работников Оператора;
                <br />
                - представители/работники клиентов, поставщиков, партнеров Оператора;
                <br />- представители потенциальных клиентов Оператора, включая посетителей сайта Оператора;
              </p>
              <p>
                4.3.1. Обработка ПД работников Оператора - Оператор обрабатывает ПД работников Оператор в рамках
                правоотношений, урегулированных ТК РФ, в том числе главой 14 ТК РФ;
                <br />
                - Оператор осуществляет обработку ПД работников с их письменного согласия с целью (1) выполнения
                трудовых договоров (включая, но не ограничиваясь для начисления и выплаты заработной платы,
                предоставления отпусков, больничных, оформления пропусков в помещения Оператор, оформления
                командировок), (2) соблюдения норм законодательства РФ, ведения кадрового, бухгалтерского учета,
                осуществления функций, полномочий и обязанностей, возложенных законодательством РФ на Оператор, в том
                числе по предоставлению ПД в органы государственной власти (ПФ РФ, Фонд социального страхования РФ,
                Федеральный фонд обязательного медицинского страхования), (3) соблюдения норм и требований по охране
                труда и обеспечения личной безопасности работников, сохранности имущества, (4) предоставления льгот и
                компенсаций, предусмотренных законодательством РФ, (5) публикаций во внутренних справочниках Оператор,
                (6) открытия банковских счетов работников для перечисления заработной платы, (7) заключения договоров
                медицинского страхования сотрудников, (8) организации обучения, повышения квалификации, участия в
                семинарах, конференциях выставках;
                <br />
                - Оператор не принимает решения, затрагивающие интересы работников, основываясь на их ПД, полученных
                электронным образом или исключительно в результате автоматизированной обработки;
                <br />
                - Оператор обеспечивает защиту ПД работников за счет собственных средств, в порядке, предусмотренном
                действующим законодательством;
                <br />
                - Оператор предоставляет доступ в ПД работников только допущенным лицам, которые имеют право получать
                только те данные, которые необходимы для выполнения их функций;
                <br />
                - Оператор получает все персональные данные работников у них самих. Если данные работника возможно
                получить только у третьей стороны, Оператор заранее уведомляет об этом работника и получает его
                письменное согласие. Оператор сообщает работнику о целях, источниках, способах получения, а также о
                характере подлежащих получению данных и последствиях отказа работника дать письменное согласие на их
                получение;
                <br />
                - Оператор обрабатывает ПД работников в течение срока действия трудового договора. Оператор обрабатывает
                ПД уволенных работников в течение срока, установленного п. 5 ч.3 ст. 24 части первой Налогового Кодекса
                РФ, ч. 1 ст. 29 Федерального закона «О бухгалтерском учете» от 06 декабря 2011 года и иными нормативными
                правовыми актами;
                <br />
                - Оператор может обрабатывать специальные категории ПД работников (Сведений о состоянии здоровья,
                относящихся к вопросу о возможности выполнения ими трудовых функций) на основании п.2.3. ч. 2 ст. 10 ФЗ
                «О персональных данных»;
                <br />
                - Оператор не обрабатывает биометрические ПД работников;
                <br />
                - Оператор не получает данные о членстве работников в общественных объединениях или их профсоюзной
                деятельности, за исключением случаев, предусмотренных ТК РФ или иными федеральными законами;
                <br />
                - Оператор обрабатывает следующие ПД сотрудников: фамилия, имя, отчество (в т.ч. предыдущие), паспортные
                данные или данные документа, удостоверяющего личность, дата рождения, место рождения, гражданство,
                отношение к воинской обязанности и иные сведения военного билета и приписного удостоверения, данные
                документов о профессиональном образовании, профессиональной переподготовки, повышении квалификации,
                стажировке, данные документов о подтверждении специальных знаний, данные документов о присвоении ученой
                степени, ученого звания, списки научных трудов и изобретений и сведения о наградах и званиях, знание
                иностранных языков, семейное положение и данные о составе и членах семьи, сведения о социальных льготах,
                пенсионном обеспечении и страховании, данные документов об инвалидности (при наличии), стаж работы и
                другие данные трудовой книжки и вкладыша к трудовой книжке, должность, квалификационный уровень,
                сведения о заработной плате (доходах), банковских счетах, картах, адрес места жительства (по регистрации
                и фактический), дата регистрации по указанному месту жительства, номер телефона (стационарный домашний,
                мобильный), данные свидетельства о постановке на учет в налоговом органе физического лица по месту
                жительства на территории РФ (ИНН), данные страхового свидетельства государственного пенсионного
                страхования, данные страхового медицинского полиса обязательного страхования граждан;
                <br />
                - Оператор не сообщает третьей стороне ПД работника без его письменного согласия, кроме случаев, когда
                это необходимо для предупреждения угрозы жизни или здоровью работника, а также в других случаях,
                предусмотренных ТК РФ, ФЗ «О персональных данных» или иными федеральными законами;
                <br />
                - Оператор не сообщает ПД работника в коммерческих целях без его письменного согласия;
                <br />
                - Оператор передает ПД работников их представителям в порядке, установленном ТК РФ, ФЗ «О персональных
                данных» и иными федеральными законами, и ограничивает эту информацию только теми данными, которые
                необходимы для выполнения представителями их функций;
                <br />
                - Оператор предупреждает лиц, получающих ПД работника, что эти данные могут быть использованы только в
                целях, для которых они сообщены, требует от этих лиц подтверждения, что это правило соблюдено;
                <br />- В порядке, установленном законодательством, и в соответствии со ст. 7 ФЗ «О персональных данных»
                для достижения целей обработки ПД и с согласия работников Оператор предоставляет ПД работников или
                поручает обработку следующим лицам:
                <ul>
                  <li>Государственные органы (ПФР, ФНС, ФСС и др.);</li>
                  <li>Банк (в рамках зарплатного проекта);</li>
                  <li>Компании пассажирских перевозок и гостиницы (в рамках организации командировок);</li>
                  <li>Клиенты, Партнеры;</li>
                  <li>Субподрядчики;</li>
                  <li>Участники (Учредители) Оператора.</li>
                </ul>
                - Работник может получить свободный бесплатный доступ к информации о его ПД и их обработке этих данных.
                Работник может получить копию любой записи, содержащей его ПД, за исключением случаев, предусмотренных
                федеральным законом;
                <br />
                - Работник может получить доступ к медицинской документации, отражающей состояние его здоровья, с
                помощью медицинского работника по его выбору;
                <br />
                - Работник может определить представителя для защиты его ПД;
                <br />
                - Работник может требовать исключить или исправить свои неверные или неполные ПД, а также данные,
                обработанные с нарушением требований ТК РФ, ФЗ «О персональных данных» или иного федерального закона.
                При отказе Оператор исключить или исправить ПД работника он может заявить в письменной форме о своем
                несогласии и обосновать такое несогласие. Работник может дополнить ПД оценочного характера заявлением,
                выражающим его собственную точку зрения;
                <br />
                - Работник может требовать известить всех лиц, которым ранее были сообщены его неверные или неполные ПД,
                обо всех произведенных в них исключениях, исправлениях или дополнениях;
                <br />- Работник может обжаловать в суд любые неправомерные действия или бездействие Оператор при
                обработке и защите его ПД.
              </p>
              <p>
                4.3.2. Обработка ПД соискателей на замещение вакантных должностей:
                <br />
                - Оператор обрабатывает ПД соискателей в объеме, предоставленном таким соискателем при направлении
                отклика на вакантную должность Оператор, включая, но не ограничиваясь: фамилия, имя, отчество; дата
                рождения; сведения об образовании, профессии, квалификации; сведения о предыдущих местах работы;
                <br />
                - Оператор обрабатывает ПД соискателей исключительно в целях подбора персонала и замещения вакантных
                должностей;
                <br />- Оператор обрабатывает ПД соискателей не дольше, чем это необходимо для достижения целей,
                указанных в настоящем пункте. ПД соискателя сохраняются в кадровом резерве Оператор в течении 5 лет с
                момента получения Оператор ПД, после истечения указанного срока – уничтожаются;
              </p>
              <p>
                4.3.3. Обработка ПД родственников работников Оператора.
                <br />
                Оператор обрабатывает ПД родственников работников Оператор в составе и сроки, необходимые для и
                осуществления и выполнения возложенных законодательством РФ на Оператор функций, полномочий и
                обязанностей, осуществления прав и законных интересов Оператор, а также для заключения и исполнения
                договора, стороной которого является субъект ПД, в том числе в целях предоставления страхования с
                согласия субъекта ПД.
              </p>
              <p>
                4.3.4. Обработка ПД представителей контрагентов Оператора.
                <br />
                - Оператор осуществляет обработку ПД представителей и работников своих клиентов, поставщиков, партнеров
                (далее совместно – Контрагенты) в рамках реализации заключенных с Оператор договоров;
                <br />- Оператор обрабатывает ПД представителей Контрагентов с целью:
                <ul>
                  <li>Заключать и выполнять обязательства по договорам;</li>
                  <li>Информировать о новых продуктах Оператора, специальных акциях и предложениях;</li>
                  <li>Предоставлять информацию об услугах;</li>
                  <li>Дальнейшего сотрудничества;</li>
                  <li>Вести переговоры, предоставлять предложения о сотрудничестве;</li>
                  <li>
                    Анализа качества предоставляемых продуктов Оператора и улучшение качества предоставляемых продуктов
                    Оператора;
                  </li>
                  <li>
                    Связи с пользователем, в том числе по вопросам технической поддержки и обслуживания клиентов, а
                    также маркетинговой коммуникации по всем доступным каналам связи;
                  </li>
                </ul>
                - Оператор обрабатывает ПД представителей Контрагентов с их согласия, предоставляемого путем заключения
                Договоров. В случаях, предусмотренных ФЗ «О персональных данных», согласие предоставляется в письменном
                виде. В иных случаях согласие считается полученным при заключении договора или при совершении
                конклюдентных действий;
                <br />
                - Оператор обрабатывает ПД Контрагентов и их представителей в течение сроков действия заключенных с
                контрагентами договоров. Оператор может обрабатывать персональные данные контрагентов и представителей
                контрагента после окончания сроков действия заключенных с ними договоров в течение срока,
                установленного. 5 ч. 3 ст. 24 части первой НК РФ, ч. 1 ст. 29 ФЗ «О бухгалтерском учете» и иными
                нормативными правовыми актами;
                <br />- Оператор может обрабатывать следующие ПД Контрагентов и представителей контрагента:
                <ul>
                  <li>Фамилия, имя отчество;</li>
                  <li>Тип, серия и номер документа, удостоверяющего личность;</li>
                  <li>Дата выдачи документа, удостоверяющего личность, и информация о выдавшем его органе;</li>
                  <li>Дата рождения;</li>
                  <li>Должность;</li>
                  <li>Номер контактного телефона;</li>
                  <li>Адрес электронной почты;</li>
                </ul>
                Для достижения целей обработки ПД и с согласия контрагентов и представителей контрагента Оператор
                предоставляет ПД или поручает их обработку следующим лицам:
                <ul>
                  <li>Субподрядчики;</li>
                  <li>Учредители;</li>
                  <li>Партнеры;</li>
                  <li>Аффилированные лица;</li>
                </ul>
              </p>
              <p>
                4.3.5. Обработка ПД потенциальных клиентов Оператора
                <br />
                - Оператор осуществляет обработку ПД представителей потенциальных клиентов, партнеров Оператора, а также
                посетителей сайта Оператора.
                <br />
                - Существуют два основных способа, с помощью которых Оператор может получить ПД с помощью сети Интернет:
                предоставление ПД (включая фамилию, имя, должность, место работы, контактный телефон, адрес электронной
                почты) субъектами ПД путем заполнения соответствующих форм на сайте Оператора и посредством направления
                электронных писем на корпоративные адреса Оператора (в доменах cdbx.ru, cdbx.io) и/или через
                автоматически собираемую информацию;
                <br />- ПД посетителей сайта обрабатываются Оператором в целях соблюдения норм законодательства РФ, а
                также с целью:
                <ul>
                  <li>Вести переговоры и предоставлять предложения о сотрудничестве;</li>
                  <li>Предоставлять информацию об услугах;</li>
                  <li>Информировать о новых продуктах Оператора, специальных акциях и предложениях;</li>
                  <li>Дальнейшего сотрудничества;</li>
                  <li>Заключать и выполнять обязательства по договорам;</li>
                  <li>
                    Анализа качества предоставляемых услуг и улучшение качества предоставляемых продуктов Оператора;
                  </li>
                  <li>
                    Связи с пользователем, в том числе по вопросам технической поддержки и обслуживания, а также
                    маркетинговой коммуникации по всем доступным каналам связи;
                  </li>
                </ul>
                - Оператор обрабатывает ПД посетителей сайтов с их согласия, предоставляемого в электронном виде во
                время заполнения форм на сайте путем предоставления галочки согласия с передачей и обработкой ПД на
                сайте Оператор. В случаях, предусмотренных ФЗ «О персональных данных, согласие предоставляется в
                письменном виде. В иных случаях согласие считается полученным при проставлении галочки согласия на сайте
                Оператора или при совершении иных конклюдентных действий;
                <br />
                - Оператор обрабатывает ПД посетителей в течении 5 лет с даты получения. В случае необходимости Оператор
                может обрабатывать ПД посетителей сайта в течение срока, установленного п. 5 ч. 3 ст.24 части первой НК
                РФ, ч. 1 ст. 29 ФЗ «О бухгалтерском учете» и иными нормативными правовыми актами;
                <br />
                - Оператор обрабатывает следующие ПД посетителей сайта Оператора (потенциальных клиентов):
                <br />
                <ul>
                  <li>Фамилия, имя, отчество;</li>
                  <li>Номер контактного телефона;</li>
                  <li>Адрес электронной почты;</li>
                  <li>Должность и название компании;</li>
                </ul>
                - Для достижения целей обработки ПД и с согласия посетителей сайта Оператор предоставляет ПД или
                поручает их обработку следующим лицам:
                <ul>
                  <li>Субподрядчики;</li>
                  <li>Учредители;</li>
                  <li>Партнеры;</li>
                  <li>Аффилированные лица;</li>
                </ul>
                - Сайт Оператора содержит ссылки на иные веб-ресурсы, где может находиться полезная и интересная для
                пользователей сайта информация. При этом действие настоящей Политики не распространяется на такие иные
                сайты. Посетителям, переходящим по ссылкам на другие сайты, рекомендуется ознакомиться с политиками по
                обработке ПД, размещенными на таких сайтах;
                <br />- Оператор собирает и обрабатывает данные, не являющиеся персональными с помощью cookies,
                веб-протоколов, веб-отметок в порядке и на условиях, определенных в Приложении № 2 к настоящей Политике.
              </p>
              <h5>5. Права субъекта персональных данных.</h5>
              <p>
                5.1. Субъект ПД имеет право на получение информации, касающейся обработки его ПД, в том числе
                содержащей:
                <br />
                - Подтверждение факта обработки ПД Оператором;
                <br />
                - Правовые основания и цели обработки ПД;
                <br />
                - Цели и применяемые оператором способы обработки ПД;
                <br />
                - Наименование и местонахождения Оператора, сведения о лицах (за исключением работников Оператора),
                которые имеют доступ к ПД или которым могут быть раскрыты ПД на основании договора с Оператором или на
                основании федерального закона;
                <br />
                - Обрабатываемые ПД, относящиеся к соответствующему субъекту ПД, источник их получения, если иной
                порядок предоставления таких данных не предусмотрен федеральным законом;
                <br />
                - Сроки обработки ПД, в том числе сроки их хранения;
                <br />
                - Порядок осуществления субъектом ПД, предусмотренных ФЗ «О персональных данных» прав;
                <br />
                - Информацию об осуществленной или о предполагаемой трансграничной передаче данных;
                <br />
                - Наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПД по поручению
                оператора, если обработка поручена или будет поручена такому лицу;
                <br />- Иные сведения, предусмотренные ФЗ «О персональных данных».
              </p>
              <p>
                5.2. Субъект ПД имеет право требовать от Оператора уточнения его ПД, их блокирования или уничтожения в
                случае, если ПД являются неполными, устаревшими, неточными, незаконно полученными или не являются
                необходимыми для заявленной цели обработки, отозвать данное субъектом ПД согласие на обработку ПД, а
                также принимать предусмотренные действующим законодательством меры по защите своих прав.
              </p>
              <p>
                5.3. С целью получения сведений, указанных в п. 5.1., субъект ПД предоставляет соответствующий запрос,
                самостоятельно или через представителя. Запрос должен содержать номер основного документа,
                удостоверяющего личность субъекта ПД или его представителя, сведения о дате выдачи указанного документа
                и выдавшем его органе, сведения, подтверждающие участие субъекта ПД в отношениях с Оператор (номер
                договора, дата заключения договора, условное словесное обозначение и/или иные сведения), либо сведения,
                иным образом подтверждающие факт обработки ПД Оператором, подпись субъекта ПД или его представителя.
                Запрос может быть направлен в форме электронного документа, и подписан электронной подписью в
                соответствии с законодательством РФ. Повторный запрос может быть направлен не ранее чем через 30
                (тридцать) календарных дней после первоначального запроса
              </p>
              <p>
                5.4. Оператор вправе отказать субъекту ПД в выполнении повторного запроса, если такой запрос не
                соответствует п. 5.3. настоящей Политики.
              </p>
              <p>
                5.5. Субъект ПД имеет право требовать прекращения Оператором обработки его ПД, если таковая
                осуществляется в целях продвижения продуктов Оператора путем осуществления прямых контактов с
                потенциальным потребителем с помощью средств связи без получения предварительного согласия субъекта ПД.
              </p>
              <p>
                5.6. Субъект ПД имеет право обжаловать действия или бездействия Оператора в случае, если считает, что
                Оператор осуществляет обработку его ПД с нарушением ФЗ «О защите персональных данных» в порядке,
                предусмотренном ст. 17 указанного федерального закона.
              </p>
              <h5>6. Обязанности Оператора.</h5>
              <p>
                6.1. Действия по обработке ПД включают сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление и уничтожение.
              </p>
              <p>
                6.2. Оператор обязан предоставлять субъекту ПД по его запросу, направленному согласно п. 5.3.,
                информацию, предусмотренную п. 5.1. настоящей Политики.
              </p>
              <p>
                6.3. По требованию субъекта ПД уточнять обрабатываемые ПД, блокировать или удалять, если ПД являются
                неточными, неполными, устаревшими, незаконно полученными или не являются необходимыми для заявленной
                цели обработки. Либо обеспечить блокирование, удаление в случае, если обработка ПД осуществляется другим
                лицом, действующим по поручению Оператора.
              </p>
              <p>
                6.4. Уведомлять субъекта ПД об обработке ПД в том случае, если ПД были получены не от субъекта ПД (за
                исключением случаев, когда субъект ПД уже уведомлен об осуществлении обработки его ПД соответствующим
                оператором).
              </p>
              <p>
                6.5. Оператор обязан вести Журнал учета обращений Субъектов ПД, в котором фиксируются запросы субъектов
                ПД на получение ПД, а также факты предоставления ПД по этим запросам.
              </p>
              <p>
                6.6. В случае достижения цели обработки ПД незамедлительно прекратить обработку ПД и уничтожить либо
                обезличить соответствующие ПД в срок, не превышающий 30 дней с даты достижения цели обработки ПД, если
                иное не предусмотрено действующим законодательством либо обеспечить уничтожение, обезличивание в случае,
                если обработка ПД осуществляется другим лицом по поручению Оператора.
              </p>
              <p>
                6.7. В случае отзыва субъектом ПД согласия на обработку ПД, прекратить обработку ПД и уничтожить ПД в
                срок, не превышающий 30 дней с даты поступления указанного отзыва, если иное не предусмотрено
                соглашением между Оператором и субъектом ПД. Либо обеспечить прекращение обработки ПД и их уничтожение,
                в случае если обработка ПД осуществляется третьим лицом, действующим по поручению Оператора. Об
                уничтожении ПД в таком случае Оператор обязан уведомит субъекта ПД.
              </p>
              <p>
                6.8. В случае поступления требования субъекта ПД о прекращении обработки ПД в целях продвижения
                продуктов Оператор - немедленно прекратить обработку ПД, либо обеспечить прекращение обработки ПД в
                случае, если обработка ПД осуществляется третьим лицом, действующим по поручению Оператора.
              </p>
              <p>
                6.9. При сборе ПД, в том числе посредством сети Интернет, обеспечить запись, систематизацию, накопление,
                хранение, уточнение (обновление, изменение), извлечение ПД граждан РФ с использованием баз данных,
                находящихся на территории РФ, за исключением случаев, отдельно указанных в ФЗ «О персональных данных».
              </p>
              <p>
                6.10. Оператор не раскрывает третьим лицам и не распространяет ПД без согласия субъекта ПД, если иное не
                предусмотрено федеральным законом.
              </p>
              <p>
                6.11. При достижении целей обработки ПД, а также в случае отзыва субъектом ПД согласия, ПД подлежат
                уничтожению, если:
                <br />
                - Оператор не вправе осуществлять обработку без согласия субъекта ПД;
                <br />
                - Иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем, по которому
                является субъект ПД;
                <br />- Иное не предусмотрено иным соглашением между Оператором и субъектом ПД.
              </p>
              <p>
                6.12. В случае подтверждения факта неточности ПД или неправомерности их обработки ПД подлежат их
                актуализации Оператором, или их обработка должна быть прекращена соответственно.
              </p>
              <p>
                6.13. Факт неточности ПД или неправомерности их обработки может быть установлен либо субъектом ПД, либо
                компетентными государственными органами РФ.
              </p>
              <h5>7. Обеспечение безопасности персональных данных.</h5>
              <p>
                7.1. Оператор назначает ответственного за организацию ПД для выполнения обязанностей, предусмотренных ФЗ
                «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами.
              </p>
              <p>
                7.2. Оператор применяет комплекс правовых организационных и технических мер по обеспечению безопасности
                ПД для обеспечения конфиденциальности ПД и их защиты от неправомерных действий:
                <br />
                - Обеспечивает неограниченные доступ к Политике, копия которой размещена по адресу нахождения Оператора,
                а также размещена на сайте Оператора;
                <br />
                - Во исполнение Политики утверждает и приводит в действие локальные акты;
                <br />
                - Производит ознакомление работников с положениями законодательства о ПД, а также с Политикой;
                <br />
                - Осуществляет допуск работников к ПД, обрабатываемым в информационной системе Оператор, а также к их
                материальным носителям только для выполнения трудовых обязанностей;
                <br />
                - Устанавливает правила доступа к ПД, обрабатываемым в информационной системе Оператор, а также
                обеспечивает регистрацию и учет всех действий с ними;
                <br />
                - Производит оценку вреда, который может быть причинен субъектам ПД в случае нарушения ФЗ «О
                персональных данных»; - Производит определение угроз безопасности ПД пи их обработке в информационной
                системе Оператора;
                <br />
                - Применяет организационные и технические меры и использует средства защиты информации, необходимые для
                достижения установленного уровня защищенности ПД;
                <br />
                - Осуществляет обнаружение фактов несанкционированного доступа к ПД и принимает меры по реагированию,
                включая восстановление ПД, модифицированных или уничтоженных вследствие несанкционированного доступа к
                ним;
                <br />
                - Производит оценку эффективности принимаемых мер по обеспечению безопасности ПД до ввода в эксплуатацию
                информационной системы Оператора;
                <br />
                - Осуществляет внутренний контроль соответствия обработки ПД ФЗ «О персональных данных», принятым в
                соответствии с ним нормативным правовым актам, требованиям к защите ПД, настоящей Политике и иным
                локальным актам, включающих контроль за принимаемыми мерами по обеспечению безопасности ПД и их уровня
                защищенности при обработке в информационной системе Оператора;
                <br />
                - Определяет угрозы безопасности ПД при их обработке в информационной системе ПД;
                <br />- Осуществляет учет машинных носителей информации.
              </p>
              <p>7.3. Оператор осуществляет обработку и хранение ПД на территории РФ.</p>
              <p>
                7.4. Оператор может осуществлять трансграничную передачу ПД своим авторизированным партнерам (включая,
                но не ограничиваясь на территорию Беларуси, Казахстана, Грузии), а также аффилированным компаниям (в
                частности на территорию Республики Кипр).
              </p>
              <h5>8. Заключительные положения.</h5>
              <p>
                8.1. Настоящая Политика, может быть, пересмотрена в любом из следующих случаев:
                <br />
                - При изменении законодательства РФ в области обработки и защиты ПД;
                <br />
                - В случаях получения предписаний от компетентных государственных органов на устранение несоответствий,
                затрагивающих область действия Политики;
                <br />
                - По решению руководства Оператора;
                <br />
                - При изменении целей и сроков обработки ПД;
                <br />
                - При изменении организационной структуры структуры информационных и/или телекоммуникационных систем
                (или введения новых);
                <br />
                - При применении новых технологий обработки и защиты ПД (в т.ч. передачи, хранения);
                <br />- При появлении необходимости в изменении процесса обработки ПД, связанной с деятельностью
                Оператора.
              </p>
              <p>
                8.2. В случае неисполнения положений настоящей Политики Оператор и его работники несут ответственность в
                соответствии с действующим законодательством РФ.
              </p>
              <p>
                8.4. Контроль исполнения требований настоящей Политики осуществляется лицами, ответственными за
                организацию обработки ПД Оператора, а также за безопасность персональных данных.
              </p>
              <p>
                8.5. Контакты Оператора:
                <br />
                183025, г. Мурманск, улица Карла Маркса, дом 25 а, оф. 204;
                <br />
                Телефон +7 (921) 605-90-80 Email ответственного за обработку ПД: office@cdbx.io
              </p>
              <div id="agreement" className="PrivacyPolicy-Attachment">
                <h5>Приложение №1. Согласие на обработку ПД посетителя сайта Оператора.</h5>
                <p>
                  При заполнении формы регистрации или авторизации, заявки, формы обратной связи и т.п. на сайте
                  Оператора, пользователь сайта предоставляет свое согласие на обработку предоставленных ПД Оператором в
                  соответствии с положениями Политики обработки ПД и настоящим Согласием.
                </p>
                <p>
                  1. Пользователь сайта предоставляет согласие на обработку предоставленных им персональных данных, а
                  именно: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                  извлечение, использование, передача (распространение, предоставление, доступ), обезличивание,
                  блокирование, удаление, уничтожение ПД.
                </p>
                <p>2. Предоставленные персональные данные не являются специальными или биометрическими.</p>
                <p>
                  3. Персональные данные, которые может предоставлять пользователь сайта могут включать: фамилия, имя,
                  отчество, дата рождения, адрес электронной почты, номер телефона, место работы, занимаемая должность.
                </p>
                <p>4. Оператор также может обрабатывать данные, получаемые в соответствии с политикой Cookies.</p>
                <p>
                  5. Обработка персональных данных может производиться как с использованием средств автоматизации, так и
                  без их использования.
                </p>
                <p>
                  6. Персональные данные предоставляются с целью предоставления пользователю сайта продуктов Оператора,
                  коммуникации в отношении новых продуктов, обновлений, полезных материалов, мероприятий, проводимых
                  Оператором, а также иных услуг, связанных с использованием продуктов Оператора.
                </p>
                <p>
                  7. Пользователь сайта предоставляет согласие Оператору передать предоставленные ПД третьим лицам
                  (партнерам Оператора), в случае, если это необходимо для достижения целей обработки ПД.
                </p>
                <p>8. Согласие действует с дня предоставления данных и до дня его отзыва.</p>
                <p>
                  9. Согласие может быть отозвано путем направления соответствующего уведомления на адрес office@cdbx.io
                </p>
                <p>
                  10. Обработка ПД прекращается в течении 30 (тридцати) календарных дней с дня получения отзыва, за
                  исключением случаев, когда Оператор обязан продолжить обработку ПД на основаниях, предусмотренных
                  действующим законодательством.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="cookies" className="row">
          <div className="col-12">
            <SiteSectionTitle
              title="Информация об использовании файлов cookies на сайте Оператора"
              subTitle="Редакция от: 28 марта 2021"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="PrivacyPolicy-Container">
              <div className="PrivacyPolicy-Attachment">
                <h5>Приложение №2. Политика Cookies</h5>
                <p>
                  1. Оператор может собирать и обрабатывать сведения, не являющиеся ПД:
                  <br />
                  - Информацию об интересах посетителей сайта на основе действий посетителей сайта с целью
                  предоставления актуальной информации клиентам Оператор при использовании сайта, а также обобщения и
                  анализа информации, о том, какие разделы сайта Оператор пользуются наибольшим спросом об использовании
                  сайта;
                  <br />- Обработка и хранение действий посетителей сайта с целью обобщения и создания клиентской
                  статистики об использовании сайта.
                </p>
                <p>
                  2. Оператор автоматически получает некоторые виды информации, получаемой в процессе взаимодействия
                  посетителей с сайтом, переписки по электронной почте и т.п. Речь идет о технологиях и сервисах, таких
                  как веб-протоколы, cookies, веб-отметки и т.п.
                </p>
                <p>
                  3. Cookies - это часть данных, автоматически располагающаяся на жестком диске компьютера при каждом
                  посещении веб-сайта. Таким образом Сookies – это уникальный идентификатор браузера для веб-сайта.
                  Сookies дают возможность хранить информацию на сервере и помогают легче ориентироваться в
                  веб-пространстве, а также позволяют осуществляют анализ сайта и оценку результатов его использования.
                  Большинство веб-браузеров разрешают использования Сookies, однако можно изменить настройки для отказа
                  от работы с Сookies или отслеживания пути их рассылки. При этом некоторые ресурсы могут работать
                  некорректно если работа Сookies в браузере будет запрещена. На определенных веб-страницах или
                  электронных письмах Оператор может использовать распространенную в Интернете технологию «веб-отметки»
                  (также известную как «Тэги» или «точная GIF-технология»). Веб-отметки помогают анализировать
                  эффективность веб-сайтов, например, с помощью измерения числа посетителей сайта или количества
                  «кликов», сделанных на ключевых позициях страницы сайта.
                  <br />
                  Полученные данные в обобщенном и обезличенном виде могут использоваться для лучшего понимания
                  потребностей покупателей продуктов Оператора и улучшения качества обслуживания.
                </p>
                <p>4. Оператор использует файлы Сookies на сайте Оператора.</p>
                <p>
                  5. Файлы Сookies используются Оператором для следующих целей:
                  <br />
                  - Авторизации на сайте Оператора в качестве зарегистрированного пользователя, предоставления продуктов
                  Оператору;
                  <br />
                  - Выявления и устранения ошибок на сайте Оператора;
                  <br />
                  - Обеспечения функционирования, повышения производительности и улучшения качества сайта Оператора;
                  <br />
                  - Смягчения рисков предотвращения возможного мошенничества, обеспечения безопасности при использовании
                  сайта Оператора;
                  <br />
                  - Хранения персональных предпочтений и настроек пользователей;
                  <br />
                  - Предоставления целевой информации по продуктам Оператора и его партнеров;
                  <br />
                  - Усовершенствование продуктов Оператора для разработки новых продуктов и(или) сервисов;
                  <br />- Ведение аналитики.
                </p>
                <p>
                  6. При посещении сайта Оператора в сети Интернет происходит автоматический сбор иных данных, в том
                  числе: технических характеристик устройства, IP адреса, информации об используемого браузере и языке,
                  даты и времени доступа к сайту, адресов запрашиваемых страниц сайта и иной подобной информации.
                </p>
                <p>
                  7. Оператор может использовать Сookies и иные автоматизированные и неавтоматизированные способы
                  обработки пользовательских данных (сведения о действиях, которые совершаются пользователем на сайте,
                  сведения об используемых для этого устройствах, дата и время сессии), а также Оператор может
                  передавать их третьим лицам для проведения исследований, выполнения работ или оказания услуг.
                </p>
                <p>
                  8. Пользователь сайта Оператора вправе самостоятельно управлять Сookies. Используемый браузер и(или)
                  устройство могут позволять блокировать, удалять или иным образом ограничивать использование Сookies
                  чтобы узнать, как управлять Сookies с помощью используемых браузера или устройства, необходимо
                  воспользоваться инструкцией, предоставляемой разработчиком браузера или производителем используемого
                  устройства.
                </p>
                <p>
                  9. При удалении или ограничении использования файлов Сookies некоторые функции сайта Оператора могут
                  оказаться недоступны.
                </p>
                <p>
                  10. Обрабатываемые Сookies уничтожаются, либо обезличиваются по достижении указанных выше целей
                  обработки или в случае утраты необходимости в достижении этих целей.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
