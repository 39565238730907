import React, { useRef } from 'react'
import './SceneTable.sass'
import { find, isObject, isEmpty, isNull } from 'lodash'

import IconicButton from '../../IconicButton/IconicButton'
import OptionsMenu from '../../OptionsMenu/OptionsMenu'
import { usePopUp } from '../../../../hooks/usePopUp'
import Spinner from '../../Spinner/Spinner'

function SceneTable({
  header,
  isLoading,
  body,
  className,
  options,
  editOnDoubleClick,
  useCustomOptions = false,
  skeleton,
}) {
  return (
    <div className="SceneContent-TableContainer">
      <table className={['SceneContent-Table', ...(className ? [className] : [])].join(' ')}>
        {!isEmpty(header) && (
          <thead>
            <tr>
              {header.map(([col, width, className], index) => (
                <th key={`${col}-${index}`} style={width && { width: `${width}px` }} className={className}>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <Body
            body={body}
            editOnDoubleClick={editOnDoubleClick}
            useCustomOptions={useCustomOptions}
            options={options}
            skeleton={skeleton}
          />
        </tbody>
      </table>
      {isLoading && (
        <div className="SceneContent-TableLoader">
          <Spinner type="module" />
        </div>
      )}
    </div>
  )
}

function Body({ body, options, editOnDoubleClick, useCustomOptions, skeleton }) {
  if (isNull(body)) {
    return skeleton.map((el, ind) => (
      <tr key={`skeleton-${ind}`}>
        {el.map((rowEl, ind) => (
          <td key={`row-skeleton-${ind}`}>{rowEl}</td>
        ))}
      </tr>
    ))
  }

  return !isEmpty(body) ? (
    body.map((row, ind) => {
      const [{ id: elementId }] = row.filter((element) => isObject(element) && true)
      return (
        <RenderRow
          key={`${elementId}-${ind}`}
          row={row}
          pos={ind}
          options={options}
          editOnDoubleClick={editOnDoubleClick}
          useCustomOptions={useCustomOptions}
        />
      )
    })
  ) : (
    <tr key="row-emptyInfo" className="NoInfoRow">
      <td colSpan="100">Нет информации</td>
    </tr>
  )
}

function RenderRow({ row, pos, options, editOnDoubleClick, useCustomOptions }) {
  const [{ id: elementId }] = row.filter((element) => isObject(element) && true)
  let editOption = find(options, ['type', 'edit'])

  if (useCustomOptions) {
    find(row, (e) => {
      if (isObject(e) && e.options) {
        editOption = find(e.options, ['type', 'edit'])
      }
    })
  }

  const butRef = useRef()
  const ddbRef = useRef()

  const [showOptions, setShowOptions] = usePopUp(butRef, ddbRef, true)

  return (
    <tr key={`row-${elementId}`} onDoubleClick={editOnDoubleClick && (() => editOption.clicked(elementId))}>
      {row
        .filter((element) => element && !element.hasOwnProperty('id') && true)
        .map((element, i) => {
          if (useCustomOptions) {
            if (isObject(element)) {
              if (element.hasOwnProperty('content')) {
                return (
                  <td
                    key={`row-${elementId}-cell-${i}`}
                    className={element.className && element.className}
                    {...element.props}
                  >
                    {element.content}
                  </td>
                )
              }

              if (element.hasOwnProperty('options')) {
                return (
                  <td key={`row-${elementId}-cell-${i}`}>
                    <div className="OptionsButton-Container">
                      {element.options && element.options.length > 1 ? (
                        <IconicButton
                          ref={butRef}
                          type={showOptions ? 'add' : element.options[0].type}
                          icon="list-ul"
                        />
                      ) : (
                        <IconicButton
                          type="options"
                          clicked={() =>
                            element.options[0].clicked(element.options[0].return === 'docId' ? elementId : pos)
                          }
                          icon={element.options[0].icon}
                        />
                      )}
                      {showOptions && (
                        <OptionsMenu
                          ref={ddbRef}
                          close={() => setShowOptions(false)}
                          options={element.options.map((option) => ({
                            ...option,
                            clicked: () => option.clicked(option.return === 'docId' ? elementId : pos),
                          }))}
                        />
                      )}
                    </div>
                  </td>
                )
              }
            }
            return <td key={`row-${elementId}-cell-${i}`}>{element}</td>
          }
          if (element !== 'options') {
            if (isObject(element) && element.hasOwnProperty('content')) {
              return (
                <td
                  key={`row-${elementId}-cell-${i}`}
                  className={element.className && element.className}
                  {...element.props}
                >
                  {element.content}
                </td>
              )
            }
            return <td key={`row-${elementId}-cell-${i}`}>{element}</td>
          }
          return (
            <td key={`row-${elementId}-cell-${i}`}>
              <div className="OptionsButton-Container">
                {options && options.length > 1 ? (
                  <IconicButton ref={butRef} type={showOptions ? 'add' : 'options'} icon="list-ul" />
                ) : (
                  <IconicButton
                    type="options"
                    clicked={() => options[0].clicked(options[0].return === 'docId' ? elementId : pos)}
                    icon={options[0].icon}
                  />
                )}
                {showOptions && (
                  <OptionsMenu
                    ref={ddbRef}
                    close={() => setShowOptions(false)}
                    options={options.map((option) => ({
                      ...option,
                      clicked: () => option.clicked(option.return === 'docId' ? elementId : pos),
                    }))}
                  />
                )}
              </div>
            </td>
          )
        })}
    </tr>
  )
}

export default SceneTable
