import React, { useEffect, useState } from 'react'
import PopUp from '../../../../components/UI/PopUp/PopUp'
import Skeleton from '../../../../components/UI/Skeleton/Skeleton'
import './ExtensionPage.sass'
import Image from '../../../../ui/Img/Img'
import test from '../ExtensionTile/Beeline.jpg'
import getDoc from '../../../../utilities/db/getDoc'
import CommentsCounter from '../ExtensionTile/CommentsCounter'
import RatingCounter from '../ExtensionTile/RatingCounter'
import SetupButton from '../ExtensionTile/SetupButton'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import { connect } from 'react-redux'
import getCollection from '../../../../utilities/db/getCollection'
import { cloneDeep, find } from 'lodash'
import { formatDateAsDayMonthYear } from '../../../../utilities/date/dateFormat'
import ExtensionUi from '../../apps/AppsUi'
import Submenu from '../../../../components/Submenu/Submenu'
import { getUrlQueryParams, setUrlQueryParams } from '../../../../utilities/queries'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { db, functions, firebase } from '../../../../config/firebase'
import rootDbPath from '../../../../../functions/utilities/db/rootDbPath'
import updateDoc from '../../../../../functions/utilities/db/updateDoc'
import InstalledAppMarker from '../ExtensionTile/InstalledAppMarker'

function ExtensionPage({ accountId, permissions, close, ...router }) {
  const { view: currentView, app: extensionId } = getUrlQueryParams({
    searchString: router.location.search,
    params: ['view', 'app'],
  })

  const [eData, setEData] = useState(null)
  const [isInstalling, setIsInstalling] = useState(false)

  useEffect(() => {
    let listener
    promiseAllValues({
      eData: getDoc({ path: 'market/apps/extensions', docId: extensionId, docIdName: 'extensionId' }),
      appsCategories: getCollection({ path: 'market/apps/categories', docIdName: 'categoryId' }),
    }).then((result) =>
      promiseAllValues({
        ...result.eData,
        category: find(result.appsCategories, ['categoryId', result.eData.category]).label,
        author: getDoc({ path: 'users', docId: result.eData.author }),
      }).then((appData) => {
        listener = db
          .collection(`${rootDbPath(accountId)}/extensions`)
          .doc(extensionId)
          .onSnapshot((snap) => {
            const appDataClone = cloneDeep(appData)
            if (snap.exists) {
              appDataClone.config = snap.data()
            } else {
              appDataClone.config = null
            }
            return setEData(appDataClone)
          })
      }),
    )

    return () => listener()
  }, [])

  useEffect(() => {
    if (eData) {
      router.history.push(
        setUrlQueryParams({
          history: router.history,
          params: [['view', eData.config && permissions.config === 'allow' ? 'config' : 'desc']],
        }),
      )
    }
  }, [eData])

  const appSections = {
    ...(permissions.config === 'allow'
      ? { config: setUrlQueryParams({ history: router.history, params: [['view', 'config']] }) }
      : {}),
    desc: setUrlQueryParams({ history: router.history, params: [['view', 'desc']] }),
  }

  const renderAppView = () => {
    switch (currentView) {
      case 'desc':
        return eData.desc
      case 'config':
        return eData.interfaceSrc === 'native' ? <ExtensionUi extensionId={eData.extensionId} /> : null
      default:
        return null
    }
  }

  const installExtension = () => {
    setIsInstalling(true)
    const func = functions.httpsCallable('extensionsOnCall')
    return func({
      extensionId,
      funcPath: 'install',
      payload: { accountId, extensionId: eData.extensionId },
    })
      .then(() =>
        updateDoc({
          db,
          path: 'market/apps/extensions',
          docId: eData.extensionId,
          data: { installs: firebase.firestore.FieldValue.increment(1) },
        }),
      )
      .finally(() => setIsInstalling(false))
  }

  const uninstallExtension = () => {
    setIsInstalling(true)
    const func = functions.httpsCallable('extensionsOnCall')
    return func({
      extensionId,
      funcPath: 'delete',
      payload: { accountId, extensionId: eData.extensionId },
    }).finally(() => setIsInstalling(false))
  }

  return (
    <PopUp show className={`ExtensionPage ${eData && eData.fullView && 'ExtensionPage_theme_full_view'}`} close={close}>
      <div className="ExtensionPage-Container">
        <div className="ExtensionPage-Content">
          {eData ? (
            <>
              <Image src={test} className="ExtensionPage-Image" />
              <Submenu
                links={[
                  ...(eData.config && permissions.config === 'allow'
                    ? [
                        {
                          title: 'Конфигурация',
                          access: true,
                          path: appSections.config,
                          isActive: () => currentView === 'config',
                        },
                      ]
                    : []),
                  {
                    title: 'Описание приложения',
                    access: true,
                    path: appSections.desc,
                    isActive: () => currentView === 'desc',
                  },
                ]}
              />
              {renderAppView()}
            </>
          ) : (
            <Skeleton className="ExtensionPage-Image" />
          )}
        </div>
        <div className="ExtensionPage-Info">
          {eData ? <Image src={test} className="InfoImage" /> : <Skeleton className="InfoImage" />}
          <div className="InfoContainer">
            <p className="ExtensionPage-SectionTitle ExtensionTitle">
              {eData ? eData.title : <Skeleton width={160} />}
              <br />
              <span className="ExtensionPage-Description">{eData ? eData.category : <Skeleton width={100} />}</span>
              <br />
              {eData && eData.config && <InstalledAppMarker />}
            </p>
            <div className="ExtensionPage-Counters">
              {eData ? <CommentsCounter comments={`${eData.comments} Отзывов`} /> : <Skeleton width={60} />}
              {eData ? <RatingCounter rating={`${eData.rating} Сред. оценка`} /> : <Skeleton width={60} />}
            </div>
            {permissions.install === 'allow' ? (
              eData ? (
                <SetupButton
                  onClick={eData.config ? uninstallExtension : installExtension}
                  text="Установить"
                  {...(eData.config
                    ? {
                        border: 'noty',
                        text: 'Удалить',
                        icon: 'trash-alt',
                        color: 'noty',
                      }
                    : {})}
                  isLoading={isInstalling}
                />
              ) : (
                <Skeleton height={28} />
              )
            ) : null}
            <div className="ExtensionPage-InfoBlock">
              {eData ? (
                <>
                  <p className="ExtensionPage-SectionTitle">Разработчик</p>
                  <UserNameWithAvatar
                    avatarUrl={eData.author.avatar.publicUrl}
                    userName={`${eData.author.lastName} ${eData.author.name} ${eData.author.middleName}`}
                    avatarSize={24}
                  />
                </>
              ) : (
                <>
                  <Skeleton width={120} />
                  <Skeleton width={80} />
                </>
              )}
            </div>
            <div className="ExtensionPage-InfoBlock">
              {eData ? (
                <>
                  <p className="ExtensionPage-SectionTitle">Версия</p>
                  <p>{eData.version}</p>
                </>
              ) : (
                <>
                  <Skeleton width={120} />
                  <Skeleton width={80} />
                </>
              )}
            </div>
            <div className="ExtensionPage-InfoBlock">
              {eData ? (
                <>
                  <p className="ExtensionPage-SectionTitle">Последнее обновление</p>
                  <p>{formatDateAsDayMonthYear(eData.updated)}</p>
                </>
              ) : (
                <>
                  <Skeleton width={120} />
                  <Skeleton width={80} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(ExtensionPage)
