import React, { useState, useEffect } from 'react'
import './UsersBar.sass'
import { cloneDeep, find, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import UsersPicker from '../UsersPicker/UsersPicker'
import { getUrlQueryParams, removeUrlQueryParams } from '../../../../utilities/queries'

function UsersBar({ permissions, accountUser, ...router }) {
  const [responsibleTypes, setResponsibleTypes] = useState(null)

  useEffect(() => {
    const types = [
      { type: 'all', label: 'Все', pickResponsibleOnInit: true, defaultResponsible: 'all' },
      { type: 'office', label: 'Офис' },
      { type: 'squad', label: 'Отдел' },
      { type: 'employee', label: 'Сотрудник' },
    ]

    let filteredTypes

    switch (permissions.access) {
      case 'all':
        filteredTypes = types
        break
      case 'office':
        filteredTypes = types
          .filter((t) => !['all'].includes(t.type) && true)
          .map((t, i) => {
            if (i === 0) {
              const type = cloneDeep(t)
              type.pickResponsibleOnInit = true
              type.defaultResponsible = accountUser.officeId
              return type
            }
            return t
          })
        break
      case 'squad':
        filteredTypes = types
          .filter((t) => !['all', 'office'].includes(t.type) && true)
          .map((t, i) => {
            if (i === 0) {
              const type = cloneDeep(t)
              type.pickResponsibleOnInit = true
              type.defaultResponsible = accountUser.squadId
              return type
            }
            return t
          })
        break
      default:
        filteredTypes = types
          .filter((t) => !['all', 'office', 'squad'].includes(t.type) && true)
          .map((t, i) => {
            if (i === 0) {
              const type = cloneDeep(t)
              type.pickResponsibleOnInit = true
              type.defaultResponsible = accountUser.userId
              type.hideBar = true
              return type
            }
            return t
          })
        break
    }

    setResponsibleTypes(filteredTypes)

    const { responsible, responsibleType } = getUrlQueryParams({
      history: router.history,
      params: ['responsible', 'responsibleType'],
    })

    if (responsible || responsibleType) {
      const activeType = find(filteredTypes, ['type', responsibleType])
      if (activeType) {
        if (activeType.type === 'all') return
        if (activeType.type === 'office' && responsible === accountUser.officeId) return
        if (activeType.type === 'squad' && find(accountUser.office.squads, ['squadId', responsible])) return
        if (
          activeType.type === 'employee' &&
          !isEmpty(find(accountUser.office.squads, (squad) => squad.members.includes(responsible)))
        )
          return
      }

      return router.history.push(
        removeUrlQueryParams({ history: router.history, params: ['responsibleType', 'responsible'] }),
      )
    }
  }, [permissions, accountUser])

  return !isEmpty(responsibleTypes) ? (
    responsibleTypes.length > 1 ? (
      <div className="UsersBar">
        {responsibleTypes.map((picker) => (
          <UsersPicker key={picker.type} {...picker} />
        ))}
      </div>
    ) : (
      responsibleTypes.map((picker) => <UsersPicker key={picker.type} {...picker} />)
    )
  ) : null
}

const mapStateToProps = (state) => ({ accountUser: state.auth.accountUser })

export default compose(withRouter, connect(mapStateToProps))(UsersBar)
