import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import SceneContent from '../../../../../../../components/UI/SceneContent/SceneContent'
import './EstatePhotoManager.sass'
import { concat, isEmpty, cloneDeep } from 'lodash'
import PhotoTile from './PhotoTile'
import promiseAllValues from '../../../../../../../utilities/promiseAllValues'
import SortableList from '../../../../../../../components/UI/SortableList/SortableList'
import arrayMove from 'array-move'
import { photoObject, deletePhoto, setAvatar, setLayout } from './functions'
import { useCallbacks } from '../../../../../../../utilities/shared'
import { base64ToFile } from '../../../../../../../utilities/files/base64ToFile'
import useModuleInit from '../../../../../../../utilities/newforms/changed/useModuleInit'

function EstatePhotoManager({ accountId, estateId, formData, syncState, uid, disableEdit }) {
  const [photos, setPhotos] = useModuleInit({ formData, initialState: [] })

  useEffect(() => {
    syncState(photos)
  }, [photos])

  const handlers = useCallbacks((callbacks) => {
    callbacks.onDrop = (acceptedFiles) => {
      Promise.all(
        acceptedFiles.map((f, i) =>
          promiseAllValues(new photoObject({ file: f, isAvatar: !!(i === 0 && isEmpty(photos)) })),
        ),
      ).then((photoObjects) => setPhotos(concat(photos, photoObjects)))
    }
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handlers.onDrop })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newPhotos = arrayMove(photos, oldIndex, newIndex)
    setPhotos(newPhotos)
  }

  const onImageEdited = (newDataUrl, photoPos) => {
    const photosClone = cloneDeep(photos)
    const photoOnEdit = photosClone[photoPos]
    photoOnEdit.file = base64ToFile(newDataUrl, photoOnEdit.fileName)
    photoOnEdit.needUpload = true
    photoOnEdit.imageUrl = newDataUrl
    setPhotos(photosClone)
  }

  return (
    <SceneContent title="Фото объекта">
      <div className="EstatePhotoManager" style={!isEmpty(photos) ? { gridTemplateRows: 'max-content 1fr' } : {}}>
        {!isEmpty(photos) && (
          <SortableList
            className="EstatePhotoManager-PhotoGrid"
            axis="xy"
            onSortEnd={onSortEnd}
            pressDelay={200}
            items={photos.map((p, i) => (
              <PhotoTile
                disableEdit={disableEdit}
                key={`photoTile-${i}`}
                {...p}
                setAvatar={() => setAvatar({ photoIndex: i, photos, setPhotos })}
                setLayout={() => setLayout({ photoIndex: i, photos, setPhotos })}
                onDelete={() => deletePhoto({ accountId, photoIndex: i, photos, setPhotos, estateId, uid })}
                onEdit={(newDataUrl) => onImageEdited(newDataUrl, i)}
              />
            ))}
          />
        )}
        {!disableEdit && (
          <div className={['DragAndDrop-Zone'].join(' ')} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Перетятине фото в это поле...</p>
            ) : (
              <p>
                Перетяните несколько фото с компьютера, либо <span>нажмите здесь</span> для выбора фото вручную
              </p>
            )}
          </div>
        )}
      </div>
    </SceneContent>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(EstatePhotoManager)
