import find from 'lodash/find'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import countComission from './countComission'

const countLooses = ({ step, deals, dateRange, responsible }) => {
  const filteredData = deals.filter((d) => {
    const funnelStep = find(d.funnel, ['stepId', step.stepId])
    if (!funnelStep) return false
    if (!responsible.includes(d.responsible)) return false
    if (d.finishedWith.status !== 'fail') return false

    if (
      funnelStep.stepStatus === 'active' &&
      d.finishedWith.date &&
      isAfter(d.finishedWith.date.seconds * 1000, dateRange.startingDate) &&
      isBefore(d.finishedWith.date.seconds * 1000, dateRange.endingDate)
    ) {
      return true
    }
    return false

    // const finishStep = find(d.funnel, { resultStep: true, status: 'active' })
    // if (!finishStep) return false

    // const isFinishedWithFail = find(finishStep.options, { result: 'fail', status: 'active' })
    // if (!isFinishedWithFail) return false

    // const nextStepInFunnelOrder = +step.order + 1
    // const nextStep = find(d.funnel, ['order', nextStepInFunnelOrder.toString()])
    // if (!nextStep || (nextStep.status !== 'skipped' && !nextStep.resultStep)) return false
  })

  return countComission(filteredData, true)
}

export default countLooses
