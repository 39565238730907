export default function moduleConfig({
  id,
  title,
  icon,
  path,
  navComponent = null,
  component = null,
  subsections = [],
  activeClassPath,
}) {
  return { id, title, icon, path, navComponent, component, subsections, activeClassPath }
}
