import React from 'react'
import zb6aPxoNMXsPKsVZfT88 from './zb6aPxoNMXsPKsVZfT88/ui/ExtensionUi'
import IL2hGczCtAL9B1Tua2Th from './IL2hGczCtAL9B1Tua2Th/ui/ExtensionUi'

function AppsUi({ extensionId }) {
  const extensionsUi = { zb6aPxoNMXsPKsVZfT88, IL2hGczCtAL9B1Tua2Th }

  const Component = extensionsUi[extensionId]
  return <Component extensionId={extensionId} />
}

export default AppsUi
