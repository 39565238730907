import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'

const getAttachmentObject = ({ title, file, ext, url, uid }) => ({
  title,
  file,
  ext,
  url,
  created: getCurDateWithUser(uid),
  updated: getCurDateWithUser(uid),
})

export default getAttachmentObject
