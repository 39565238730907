import { chunk, last, orderBy } from 'lodash'

const listDocWasRemoved = async ({ displayedData, collection, page, doc, propId, fetchFunction }) => {
  const totalPages = Math.ceil(collection.length / 20)

  let docToShow = null
  if (totalPages > page) {
    const chunkedCollection = chunk(collection, 20)
    const itemsForCurrentPage = chunkedCollection[page - 1]
    const newDoc = last(itemsForCurrentPage)
    docToShow = await fetchFunction(newDoc)
  }

  let displayedDataClone = displayedData.filter((d) => d[propId] !== doc[propId])
  if (docToShow) displayedDataClone.push(docToShow)

  displayedDataClone = orderBy(displayedDataClone, (d) => d.created.at.seconds * 1000, ['desc'])

  return { displayedData: displayedDataClone, totalPages }
}

export default listDocWasRemoved
