import isEmpty from 'lodash/isEmpty'
import getDoc from '../../../../../utilities/db/getDoc'

const fetchTaskContacts = ({ accountId, contacts }) => {
  if (!isEmpty(contacts)) {
    return Promise.all(
      contacts.map((c) => getDoc({ accountId, path: 'contacts', docId: c, docIdName: 'contactId' })),
    ).then((fetchedContacts) => {
      const contactsData = []
      fetchedContacts.forEach((contact) => {
        if (contact) {
          contactsData.push({
            contactId: contact.contactId,
            lastName: contact.lastName,
            name: contact.name,
            middleName: contact.middleName,
            phone: contact.phone[0].value,
          })
        }
      })
      return contactsData
    })
  }
  return []
}

export default fetchTaskContacts
