import React from 'react'
import Spinner from '../../../../../../../components/UI/Spinner/Spinner'

function LoadingScene({ title }) {
  return (
    <div className="Attachment">
      <div className="Attachment-Prepare">
        <div className="Prepare-Container">
          <div>
            <Spinner type="popup" />
          </div>
          <div className="Loading-Text">{title}</div>
        </div>
      </div>
    </div>
  )
}

export default LoadingScene
