import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'

const getTaskCommentObject = ({ text, submitAsTaskResult = false, uid }) => ({
  text,
  isTaskResult: submitAsTaskResult,
  created: getCurDateWithUser(uid),
  updated: getCurDateWithUser(uid),
})

export default getTaskCommentObject
