import React from 'react'
import ReactDOM from 'react-dom'
import './styles/main.sass'
// eslint-disable-next-line
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { BrowserRouter as Router } from 'react-router-dom'
import FontAwesomeLibrary from './config/fontawesome'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { watchDialer } from './modules/Dialer/store/sagas'
import { watchTasks } from './modules/Tasks/store/sagas/index'
import { watchNotifications } from './containers/Header/NotificationsCenter/store/sagas/index'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'

import rootReducer from './store/reducers/rootReducer'

const composeEnhancers =
  (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)))

sagaMiddleware.run(watchDialer)
sagaMiddleware.run(watchTasks)
sagaMiddleware.run(watchNotifications)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </Provider>,
  document.getElementById('cardbox'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
