import React from 'react'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import './EstateImage.sass'
import { isEmpty, find } from 'lodash'
import Img from '../../../../../../../ui/Img/Img'

export function EstateAvatar({ photos }) {
  return isEmpty(photos) ? <EstateThumbnail /> : <EstateImage src={find(photos, ['isAvatar', true]).publicUrl} />
}

function EstateImage({ src }) {
  return <Img key={src} className="EstateImage" src={src} />
}

function EstateThumbnail() {
  return (
    <div className="EstateImage EstateImage-Thumbnail">
      <Icon name="building" />
    </div>
  )
}
export default EstateImage
