import isNaN from 'lodash/isNaN'
import isFinite from 'lodash/isFinite'
import getDoneDeals from '../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import getDeals from '../../Deals/scenes/Dealslist/functions/analytics/getDeals'
import promiseAllValues from '../../../utilities/promiseAllValues'

const countConversionToDeal = ({ accountId, dateRange, responsible }) => {
  const promises = {
    doneDeals: getDoneDeals({ accountId, dateRange, responsible }).then((dd) => dd.length),
    totalDeals: getDeals({ accountId, dateRange, responsible }).then((td) => td.length),
  }

  return promiseAllValues(promises).then((data) => {
    const conversionToDealPercent = Math.round((data.doneDeals * 100) / data.totalDeals)
    return isNaN(conversionToDealPercent) ? 0 : isFinite(conversionToDealPercent) ? conversionToDealPercent : 0
  })
}
export default countConversionToDeal
