import { addDoc } from '../../utilities/db/addDoc'
import { setDoc } from '../../utilities/db/setDoc'

const installOffices = ({ accountId, uid }) => {
  const infoBlocks = [
    { data: { icon: 'building', title: 'Адрес', type: 'infoblock' } },
    { data: { icon: 'clock', title: 'Режим работы', type: 'infoblock' } },
    { data: { icon: 'mobile', title: 'Телефон', type: 'infoblock' } },
  ]

  return Promise.all([
    setDoc({ accountId, path: 'config', docId: 'offices', data: {} }),
    infoBlocks.map((d) => addDoc({ accountId, path: 'config/offices/infoBlocks', ...d })),
  ])
}

export default installOffices
