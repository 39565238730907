import find from 'lodash/find'
import countComission from './countComission'

const countFacts = ({ step, deals, responsible }) => {
  const filteredData = deals.filter((d) => {
    if (d.finishedWith.status) return false
    return find(
      d.funnel,
      (s) => s.stepId === step.stepId && s.stepStatus === 'active' && responsible.includes(d.responsible),
    )
  })

  return countComission(filteredData)
}

export default countFacts
