import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import rootDbPath from '../../../functions/utilities/db/rootDbPath'
import rootPath from '../../appTree/rootPath'
import SceneContent from '../../components/UI/SceneContent/SceneContent'
import { db } from '../../config/firebase'
import getCollection from '../../utilities/db/getCollection'
import promiseAllValues from '../../utilities/promiseAllValues'
import { getUrlQueryParams } from '../../utilities/queries'
import ExtensionPage from './Extension/ExtensionPage/ExtensionPage'
import ExtensionTile, { ExtensionTileSkeleton } from './Extension/ExtensionTile/ExtensionTile'
import ExtensionsCategories from './ExtensionsCategories/ExtensionsCategories'
import './Market.sass'

function Market({ accountId, permissions, ...router }) {
  const { categoryId } = router.match.params
  const [marketData, setMarketData] = useState(null)
  const [showExtensionPage, setShowExtensionPage] = useState(false)
  const [installedApps, setInstalledApps] = useState([])
  const { app: extensionId } = getUrlQueryParams({ history: router.history, params: ['app'] })

  useEffect(() => {
    promiseAllValues({
      apps: getCollection({ path: 'market/apps/extensions', docIdName: 'extensionId' }),
      appsCategories: getCollection({ path: 'market/apps/categories', docIdName: 'categoryId' }),
    }).then((data) => setMarketData(data))
  }, [])

  useEffect(() => {
    if (categoryId && marketData && marketData.apps) {
      if (extensionId && find(marketData.apps, ['extensionId', extensionId])) {
        setShowExtensionPage(true)
      } else {
        setShowExtensionPage(false)
      }
    }
  }, [marketData, router.location.search])

  useEffect(() => {
    const listener = db.collection(`${rootDbPath(accountId)}/extensions`).onSnapshot((snap) => {
      const appsData = []
      snap.forEach((d) => appsData.push(d.id))
      setInstalledApps(appsData)
    })

    return () => listener()
  }, [])

  return (
    <SceneContent>
      <div className="Market">
        <div style={{ textAlign: 'center' }}>
          <h3 className="Market-Title">Расширьте функционал вашей системы в один клик!</h3>
          <p className="Market-Description">
            Устанавливайте официальные и сторонние приложения для расширения функций CardBox
          </p>
        </div>
        <ExtensionsCategories />
        {showExtensionPage && (
          <ExtensionPage
            permissions={permissions}
            close={() => router.history.push(`${rootPath(accountId)}/settings/market/apps/${categoryId}`)}
          />
        )}
        <div className="Market-Apps">
          {marketData
            ? isEmpty(marketData.apps)
              ? ''
              : marketData.apps.map((a) => (
                  <ExtensionTile
                    key={`app-${a.extensionId}`}
                    isInstalled={installedApps.includes(a.extensionId)}
                    extensionId={a.extensionId}
                  />
                ))
            : [
                <ExtensionTileSkeleton />,
                <ExtensionTileSkeleton />,
                <ExtensionTileSkeleton />,
                <ExtensionTileSkeleton />,
              ]}
        </div>
      </div>
    </SceneContent>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(Market)
