import getValuesFromPattern from '../../utilities/newforms/getValuesFromPattern'
import { setDoc } from '../../utilities/db/setDoc'
import officeObject from '../../modules/Settings/Company/components/Offices/components/ManageOfficePopUp/officeDbForm'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'
import getOfficeSquadObject from '../../modules/Settings/Company/components/Offices/functions/getOfficeSquadObject'

const addOfficeData = ({ accountId, uid }) =>
  setDoc({
    accountId,
    path: 'offices',
    docId: 'root',
    data: getValuesFromPattern({
      formData: {
        title: 'Главный офис',
        created: getCurDateWithUser(uid),
        updated: getCurDateWithUser(uid),
        squads: [getOfficeSquadObject({ title: 'Отдел продаж', markerColor: 'green', squadHead: uid, members: [uid] })],
      },
      formPattern: officeObject,
    }),
  })

export default addOfficeData
