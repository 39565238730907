import { merge, omit } from 'lodash'
import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { addDoc } from '../../../../../../utilities/db/addDoc'
import getFormValues from '../../../../../../utilities/newforms/getFormValues'
import isFormValid from '../../../../../../utilities/newforms/validation/isFormValid'
import { logError } from '../../../../../../utilities/db/logError'

const submitDealForm = ({
  accountId,
  dealId,
  form,
  serviceForm,
  setStatusMessage,
  setShowErrors,
  setIsLoading,
  uid,
  onSuccessFunc,
}) => {
  if (!isFormValid({ form }) || (serviceForm && !isFormValid({ form: serviceForm }))) {
    setStatusMessage({
      show: true,
      type: 'fail',
      message: 'Невозможно сохранить сделку. Пожалуйста, заполните обязательные поля',
      closeAfter: 5000,
    })
    return setShowErrors(true)
  }

  setShowErrors(false)
  setIsLoading(true)

  const values = {
    ...getFormValues({ form }),
    ...(serviceForm ? { needForm: getFormValues({ form: serviceForm }) } : {}),
    created: getCurDateWithUser(uid),
    updated: getCurDateWithUser(uid),
  }

  let dbFunc
  if (dealId) {
    dbFunc = updateDoc({
      accountId,
      path: 'deals',
      docId: dealId,
      data: omit(values, ['created', 'updated']),
      uid,
    })
  } else {
    dbFunc = addDoc({
      accountId,
      path: 'deals',
      data: values,
    })
  }

  dbFunc
    .then((docId) => {
      setStatusMessage({
        show: true,
        type: 'success',
        message: 'Успешно сохранено',
        closeAfter: 5000,
      })
      onSuccessFunc(docId, values)
    })
    .catch((error) => {
      logError({ error })
    })
    .finally(() => setIsLoading(false))
}

export default submitDealForm
