import React, { useState } from 'react'
import { connect } from 'react-redux'
import { db, storageRef } from '../../../../../../../config/firebase'
import { getDateWithDaysAndTime } from '../../../../../../../utilities/dates'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'
import updateTask from '../../../functions/updateTask'
import LoadingScene from './LoadingScene'

function Attachment({ accountId, attachment, taskId, uid, canBeEdit }) {
  const [deleteInProcess, setDeleteInProcess] = useState(false)

  const deleteAttachment = () => {
    setDeleteInProcess(true)
    storageRef
      .child(`${rootDbPath(accountId)}/tasks/${taskId}/${attachment.file}`)
      .delete()
      .then(() =>
        db
          .collection(`${rootDbPath(accountId)}/tasks/${taskId}/attachments`)
          .doc(attachment.attachmentId)
          .delete(),
      )
      .then(() => updateTask({ accountId, taskId, taskData: {}, uid }))
      .catch((e) => console.log('🚀 ~ file: Attachment.js ~ line 25 ~ deleteAttachment ~ e', e))
  }

  return deleteInProcess ? (
    <LoadingScene title="Удаление..." />
  ) : (
    <div className="Attachment">
      <a href={attachment.url} target="blank" className="Attachment-FileBlock">
        {attachment.ext}
      </a>
      <div className="Attachment-Details">
        <div className="Details-Name">{attachment.title}</div>
        <div className="Details-Date">{getDateWithDaysAndTime(attachment.created.at)}</div>
        <div className="Details-Actions">{canBeEdit && <button onClick={deleteAttachment}>Удалить</button>}</div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Attachment)
