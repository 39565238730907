import React from 'react'
import { connect } from 'react-redux'
import { storageRef } from '../../../../../../../../config/firebase'
import { addDoc } from '../../../../../../../../utilities/db/addDoc'
import rootDbPath from '../../../../../../../../utilities/db/rootDbPath'
import * as actions from '../../../../../../store/actions/tasksActions'
import getAttachmentObject from '../../../../functions/getAttachmentObject'
import updateTask from '../../../../functions/updateTask'

import TaskNavElement from '../TaskNavElement'

function TaskAttachments({ accountId, icon, label, taskId, uid, setTaskAttachmentUploadFor, canBeEdit }) {
  const fieldChangedHandler = (event) => {
    const { value } = { ...event.target }
    if (value) {
      setTaskAttachmentUploadFor(taskId)
      const file = event.target.files[0]

      const fileExtensionPattern = /(?:\.([^.]+))?$/
      const fileName = file.name

      const fileExtension = fileExtensionPattern.exec(fileName)[1]

      const newFileName = Date.now()
      const fileLocation = storageRef.child(`${rootDbPath(accountId)}/tasks/${taskId}/${newFileName}.${fileExtension}`)

      fileLocation
        .put(file)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) =>
          addDoc({
            accountId,
            path: `tasks/${taskId}/attachments`,
            data: getAttachmentObject({
              title: fileName,
              file: `${newFileName}.${fileExtension}`,
              ext: `${fileExtension}`,
              url,
              uid,
            }),
          }),
        )
        .then(() => updateTask({ accountId, taskId, taskData: {}, uid }))
        .then(() => setTaskAttachmentUploadFor(null))
    }
  }

  return (
    <TaskNavElement icon={icon} label={label} showByDefault canBeEdit={canBeEdit}>
      <input type="file" className="Task-Attachments-Field-Hidden" onChange={fieldChangedHandler} />
    </TaskNavElement>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

const mapDispatchToProps = (dispatch) => ({
  setTaskAttachmentUploadFor: (taskId) => dispatch(actions.tasksAttachmentUpload(taskId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskAttachments)
