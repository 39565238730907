import { isEmpty, cloneDeep, find, omit } from 'lodash'
import uploadFiles from '../../../../../../utilities/storage/uploadFiles'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'

const uploadEstateAttachments = ({ accountId, files, docId, setFiles }) => {
  const docsToUpload = files.filter((p) => p.needUpload)
  if (!isEmpty(docsToUpload)) {
    const storagePath = `accounts/${accountId}/estate/${docId}/attachments`

    return uploadFiles({ files: docsToUpload, storagePath }).then((uploadedFiles) => {
      if (!isEmpty(uploadedFiles)) {
        const attachmentsClone = cloneDeep(files)
        uploadedFiles.forEach((f) => {
          const file = find(attachmentsClone, ['fileName', f.fileName])
          if (file) {
            file.publicUrl = f.publicUrl
            file.needUpload = false
            file.storagePath = storagePath
          }
        })

        setFiles(attachmentsClone)

        return updateDoc({
          accountId,
          path: 'estate',
          docId,
          data: { attachments: attachmentsClone.map((p) => omit(p, ['file', 'uploadProgress', 'needUpload'])) },
        })
      }
    })
  }
  return Promise.resolve('Nothing to upload')
}

export default uploadEstateAttachments
