import contactObject, {
  contactManagerForm,
} from '../../modules/Contacts/scenes/Contactlist/ContactsManager/contactDbForm'
import organizationObject, {
  organizationManagerForm,
} from '../../modules/Contacts/scenes/OrganizationsList/OrganizationsManager/organizationDbForm'
import organizationContactObject, {
  organizationContactManagerForm,
} from '../../modules/Contacts/AttachedContacts/organizationContactDbForm'
import { addDoc } from '../../utilities/db/addDoc'
import { setDoc } from '../../utilities/db/setDoc'

const installContacts = ({ accountId, uid }) => {
  const emailTypes = [{ data: { label: 'Личный' } }, { data: { label: 'Рабочий' } }]
  const phoneTypes = [{ data: { label: 'Рабочий' } }, { data: { label: 'Личный' } }]
  const addressTypes = [{ data: { label: 'Почтовый' } }, { data: { label: 'Основной' } }]

  const forms = [
    { docId: 'contactManager', data: contactManagerForm },
    { docId: 'organizationContactManager', data: organizationContactManagerForm },
    { docId: 'organizationManager', data: organizationManagerForm },
  ]
  const objects = [
    { docId: 'contactObject', data: contactObject },
    { docId: 'organizationContactObject', data: organizationContactObject },
    { docId: 'organizationObject', data: organizationObject },
  ]

  return Promise.all([
    setDoc({ accountId, path: 'config', docId: 'contacts', data: {} }),
    emailTypes.map((d) => addDoc({ accountId, path: 'config/contacts/emailTypes', ...d })),
    phoneTypes.map((d) => addDoc({ accountId, path: 'config/contacts/phoneTypes', ...d })),
    addressTypes.map((d) => addDoc({ accountId, path: 'config/contacts/addressTypes', ...d })),
    forms.map((d) => setDoc({ accountId, path: 'config/contacts/forms', ...d })),
    objects.map((d) => setDoc({ accountId, path: 'config/contacts/objects', ...d })),
  ])
}

export default installContacts
