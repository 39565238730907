import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'

import { fieldChanged } from './fieldChanged'

export const fieldChangedHandler = (fieldIdentifier, form, setForm) => (event) => {
  const formClone = cloneDeep(form)
  const field = find(formClone, ['fieldId', fieldIdentifier])
  fieldChanged(field, event)
  setForm(formClone)
}
