import React from 'react'
import DropdownBox from '../../../../components/UI/DropdownBox/DropdownBox'
import ProfileNav from '../../../pages/Profile/ProfileNav'
import './UserPopUp.sass'

const UserPopUp = React.forwardRef((props, ref) => (
  <DropdownBox className="UserPopUp" ref={ref} styles={{ minWidth: '240px' }}>
    <ProfileNav />
  </DropdownBox>
))

export default UserPopUp
