import React, { useEffect, useRef, useState } from 'react'
import './OrganizationCard.sass'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { find, isEmpty } from 'lodash'
import Icon from '../../../components/UI/Icon/Icon'
import { db } from '../../../config/firebase'
import fetchOrganizationObject from '../scenes/OrganizationsList/OrganizationsManager/functions/fetchOrganizationObject'
import rootDbPath from '../../../utilities/db/rootDbPath'
import getDoc from '../../../utilities/db/getDoc'
import promiseAllValues from '../../../utilities/promiseAllValues'
import { clickToCall } from '../../Dialer/functions'
import { cleanPhoneNumber } from '../../../utilities/cleanPhoneNumber'
import UserNameWithAvatar from '../../../components/UserNameWithAvatar/UserNameWithAvatar'
import { usePopUp } from '../../../hooks/usePopUp'
import Skeleton from '../../../components/UI/Skeleton/Skeleton'
import Button from '../../../ui/Button/Button'
import rootPath from '../../../appTree/rootPath'

function OrganizationCard({
  accountId,
  organizationId,
  superstructure,
  superstructureData,
  buttons = null,
  expanded = false,
  ...router
}) {
  const [organizationData, setOrganizationData] = useState(null)
  const [organizationObject, setOrganizationObject] = useState(null)
  const [organizationForm, setOrganizationForm] = useState(null)
  const [superstructureObject, setSuperstructureObject] = useState(null)
  const [superstructureForm, setSuperstructureForm] = useState(null)
  const ctHeaderRef = useRef()
  const ctBodyRef = useRef()
  const [showFullData] = usePopUp(ctHeaderRef, ctBodyRef)

  useEffect(() => {
    promiseAllValues({
      ...(superstructure
        ? {
            superstructureObject: getDoc({
              accountId,
              path: superstructure.object.path,
              docId: superstructure.object.docId,
            }),
            superstructureForm: getDoc({
              accountId,
              path: superstructure.form.path,
              docId: superstructure.form.docId,
            }).then(({ sections }) => sections),
          }
        : {}),
      organizationObject: getDoc({ accountId, path: 'config/contacts/objects', docId: 'organizationObject' }),
      organizationForm: getDoc({ accountId, path: 'config/contacts/forms', docId: 'organizationManager' }).then(
        ({ sections }) => sections,
      ),
    }).then((results) => {
      if (superstructure) {
        setSuperstructureObject(results.superstructureObject)
        setSuperstructureForm(results.superstructureForm)
      }
      setOrganizationObject(results.organizationObject)
      setOrganizationForm(results.organizationForm)
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(organizationObject) && !isEmpty(organizationForm)) {
      const listener = db
        .collection(`${rootDbPath(accountId)}/organizations`)
        .doc(organizationId)
        .onSnapshot((snap) => {
          fetchOrganizationObject({
            accountId,
            doc: { organizationId: snap.id, ...snap.data() },
          }).then((fetchedOrganization) => setOrganizationData(fetchedOrganization))
        })

      return () => listener()
    }
  }, [organizationObject, organizationForm])

  const renderSuperstructureField = (field) => {
    if (!superstructureObject[field].render.isSystem && superstructureData[field]) {
      return (
        <>
          <span className="DataTitle">{superstructureObject[field].field.label}:</span>
          <span>{superstructureData[field]}</span>
        </>
      )
    }
    return null
  }

  const renderOrganizationField = (field) => {
    switch (field) {
      case 'phone':
      case 'email':
      case 'address':
        if (hasFieldsWithValues(organizationData[field])) {
          return (
            <>
              <div className="OrganizationCard-Body-SectionTitle">{organizationObject[field].field.label}</div>
              {organizationData[field]
                .filter((m) => (m.type && true) || (m.value && true))
                .map((m) => (
                  <>
                    <span className="DataTitle">
                      {m.type || field === 'phone' ? 'Телефон' : field === 'address' ? 'Адрес' : 'E-mail'}:
                    </span>

                    <span
                      onClick={
                        field === 'phone'
                          ? () =>
                              clickToCall({
                                name: `${organizationData.title}`,
                                phone: cleanPhoneNumber(m.value),
                                contactId: organizationData.contactId,
                                responsible: organizationData.responsible.userId,
                              })
                          : null
                      }
                      className={field === 'phone' && 'ActiveLink'}
                    >
                      {m.value}
                    </span>
                  </>
                ))}
            </>
          )
        }
        return null
      case 'responsible':
        return (
          <>
            <span className="DataTitle">{organizationObject[field].field.label}:</span>
            <span>
              <UserNameWithAvatar
                avatarUrl={organizationData[field].avatar.publicUrl}
                avatarSize="26"
                userName={`${organizationData[field].lastName} ${organizationData[field].name} ${organizationData[field].middleName}`}
              />
            </span>
          </>
        )
      default:
        if (!organizationObject[field].render.isSystem && organizationData[field]) {
          return (
            <>
              <span className="DataTitle">{organizationObject[field].field.label}:</span>
              <span>{organizationData[field]}</span>
            </>
          )
        }
        return null
    }
  }

  return organizationData && ((superstructure && superstructureForm && superstructureObject) || !superstructure) ? (
    <div
      className={['OrganizationCard', ...(showFullData || expanded ? ['OrganizationCard_status_opened'] : [])].join(
        ' ',
      )}
    >
      <div ref={ctHeaderRef} className="OrganizationCard-Header">
        <div className="Header-Name">
          <div className="OrganizationCard-Icon">
            <Icon name="building" />
          </div>
          <span className="ContactName">{`${organizationData.title}`}</span>
        </div>
        <Icon helperClass="OrganizationCard-Header-Arrow" name="angle-down" />
      </div>
      <div ref={ctBodyRef} className="OrganizationCard-Body">
        {superstructure &&
          superstructureForm.map((section) => section.fields.map((field) => renderSuperstructureField(field)))}
        {organizationForm.map((section) =>
          section.fields.filter((field) => !['title'].includes(field)).map((field) => renderOrganizationField(field)),
        )}
        {buttons && (
          <div className="Body-Buttons">
            <div className="OrganizationCard-Options">
              {find(buttons, ['type', 'openCard']) && (
                <Button
                  onClick={() =>
                    router.history.push(`${rootPath(accountId)}/contacts/organizations/list/${organizationId}/edit`)
                  }
                  title="Открыть карточку"
                  theme="solid"
                  fill="accent"
                  size={28}
                />
              )}
              {find(buttons, ['type', 'edit']) && (
                <Button
                  onClick={find(buttons, ['type', 'edit']).onEdit}
                  title="Редакт."
                  theme="bounded"
                  border="accent"
                  color="accent"
                  size={28}
                />
              )}
            </div>
            {find(buttons, ['type', 'detach']) && (
              <Button
                onClick={find(buttons, ['type', 'detach']).onDetach}
                title="Открепить."
                theme="bounded"
                border="noty"
                color="noty"
                size={28}
              />
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <OrganizationCardSkeleton />
  )
}

export const OrganizationCardSkeleton = () => (
  <div className="OrganizationCard">
    <div className="OrganizationCard-Header">
      <div className="Header-Name">
        <Skeleton className="OrganizationCard-Icon" circle width={24} height={24} />
        <Skeleton width={80} />
      </div>
      <Skeleton circle width={16} height={16} />
    </div>
  </div>
)

const hasFieldsWithValues = (fields) =>
  fields.map((m) => Object.values(m).some((v) => v && true)).some((v) => v && true)

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(OrganizationCard)
