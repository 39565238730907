import getCollection from '../db/getCollection'

export const getOfficesOptions = (accountId) =>
  getCollection({ accountId, path: 'offices', docIdName: 'value' }).then((offices) =>
    offices.map((o) => ({
      value: o.value,
      label: o.title,
      rawData: o,
    })),
  )
