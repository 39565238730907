import { AsYouType } from 'libphonenumber-js/max'

const getPhoneNumberMetadata = (phone, country = null) => {
  if (phone) {
    const asYouType = new AsYouType(country || '')
    asYouType.input(phone)
    const phoneNumber = asYouType.getNumber()

    return {
      metadata: {
        country: phoneNumber ? phoneNumber.country || '' : '',
        formatInternational: phoneNumber ? phoneNumber.formatInternational() || '' : '',
        formatNational: phoneNumber ? phoneNumber.formatNational() || '' : '',
        number: phoneNumber ? phoneNumber.number || '' : '',
      },
      value: phone,
    }
  }
  return {
    metadata: {
      country: '',
      formatInternational: '',
      formatNational: '',
      number: '',
    },
    value: '',
  }
}

export default getPhoneNumberMetadata
