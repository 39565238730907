import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  title: {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      required: false,
      label: 'Название сделки',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  comission: {
    field: {
      fieldId: 'comission',
      fieldType: 'input',
      inputType: 'number',
      required: false,
      label: 'Комиссия',
      mask: 'number',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  factComission: {
    field: {
      fieldId: 'factComission',
      fieldType: 'input',
      inputType: 'number',
      required: false,
      label: 'Комиссия проигрыша',
      mask: 'number',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  need: {
    field: {
      fieldId: 'need',
      fieldType: 'select',
      label: 'Тип потребности',
      required: true,
      getOptions: 'getServiceTypesOptions',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject({ isChangableAfterSave: false }),
  },
  responsible: {
    field: {
      fieldId: 'responsible',
      fieldType: 'select',
      label: 'Ответственный',
      required: true,
      getOptions: 'getMembersOptions',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  funnel: {
    field: {
      fieldId: 'funnel',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  contact: {
    field: {
      fieldId: 'contact',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  customer: {
    field: {
      fieldId: 'customer',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  needForm: {
    field: {
      fieldId: 'needForm',
      value: null,
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  created: {
    field: {
      fieldId: 'created',
      value: {
        at: '',
        by: '',
      },
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: {
        at: '',
        by: '',
      },
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  isDeleted: {
    field: {
      fieldId: 'isDeleted',
      label: 'Удаленные',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  finishedWith: {
    field: {
      fieldId: 'finishedWith',
      value: {
        status: null,
        date: null,
        reason: null,
        reasonText: null,
      },
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  tags: {
    field: {
      fieldId: 'tags',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export const dealManagerForm = {
  sections: [
    {
      title: 'Сделка',
      fields: ['title', 'need', 'comission', 'factComission', 'responsible'],
    },
  ],
}

export default object
