import React, { useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Logo from '../../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import SignInLink from './SignInLink/SignInLink'
import LangPicker from './LangPicker/LangPicker'
import TryFreeButton from '../TryFreeButton/TryFreeButton'
import ProductMenu from './ProductMenu'
import SolutionsMenu from './SolutionsMenu'
import ResoutcesMenu from './ResourcesMenu'
import { usePopUp } from '../../../hooks/usePopUp'
import UserProfileBar from '../UserProfileBar/UserProfileBar'
import useScrollPosition from '../../../hooks/useScrollPosition'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(pos > 0 || router.location.pathname !== '/' ? [] : ['Site-Nav_theme_transparent']),
      ].join(' ')}
    >
      <Media
        query="(max-width: 1200px)"
        render={() => (
          <span ref={hamRef}>
            <Hamburger
              isOpen={menuIsOpen}
              width={24}
              height={16}
              strokeWidth={2}
              className="Site-Hamburger-Container"
              color={pos > 0 || router.location.pathname !== '/' ? '#1D1F29' : '#ffffff'}
              borderRadius={2}
              animationDuration={0.2}
            />
          </span>
        )}
      />
      <Logo
        theme={pos > 0 || router.location.pathname !== '/' ? 'primary' : 'primaryWhite'}
        direction="horizontal"
        navLink
        path="/"
      />
      <div ref={navRef} className="Site-Nav-Menu" style={menuIsOpen ? { display: 'grid' } : {}}>
        <ProductMenu />
        <SolutionsMenu />
        <SiteNavLink title="Тарифы" path="/pricing" />
        <ResoutcesMenu />
      </div>
      <div className="Site-Nav-Controlls">
        <SiteNavLink title="Контакты" path="/contact-us" />
        {user ? <UserProfileBar /> : <SignInLink />}
        <LangPicker />
        {!user && <TryFreeButton title="Пробная версия" fill="accent" className="TryFreeButton" />}
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default compose(withRouter, connect(mapStateToProps))(Nav)
