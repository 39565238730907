import React from 'react'
import './PieChart.sass'
import 'zingchart/es6'
import ZingChart from 'zingchart-react'
import WidgetTile from '../WidgetTile/WidgetTile'

function PieChart({ title, series = [], isLoading, counterPeriod }) {
  const chartConfig = {
    type: 'ring',
    plot: {
      x: 62,
      size: '100%',
      slice: '48px',
      valueBox: { visible: false },
      borderWidth: '0px',
      'legend-item': {
        paddingBottom: '4px',
        margin: 0,
      },
    },
    legend: {
      x: '130px',
      marker: {
        size: '4px',
        type: 'circle',
      },
      borderWidth: 1,
      borderColor: '#F2F4F6',
      borderRadius: '8px',
      y: 0,
      layout: `${series ? series.length : 0}x1`,
      toggleAction: 'remove',
      width: '150px',
      height: '112px',
      'max-items': 7,
      overflow: 'scroll',
      scroll: {
        bar: { width: '4px' },
        handle: {
          width: '4px',
          borderRadius: '4px',
        },
      },
    },
    plotarea: { margin: 0 },
    series: series
      ? series.map((s) => {
          s['legend-item'] = {
            fontSize: '11px',
            fontColor: '#39393D',
            cursor: 'hand',
            width: '100%',
            maxWidth: '150px',
          }
          return s
        })
      : [],
  }
  return (
    <WidgetTile className="PieChart" title={title} isLoading={isLoading} counterPeriod={counterPeriod}>
      <div>
        <ZingChart data={chartConfig} height={120} />
      </div>
    </WidgetTile>
  )
}

export default PieChart
