import {
  createField,
  createRequiredField,
  createSelectbox,
} from '../../../../../../../../utilities/forms/createFormElement'
import { getOfficesOptions, getSystemRolesOptions, getUsersOptions } from '../../../../../../../../utilities/getOptions'
import { getSquadsOptions } from './manageFormDependencies'

const createMotivationFormFields = (formData) => {
  const fields = [
    {
      fieldId: 'offices',
      ...createSelectbox(
        'Выберите офис',
        true,
        () =>
          getOfficesOptions().then((offices) => [
            { label: 'Все офисы', value: 'all', standAloneOption: true },
            ...offices,
          ]),
        false,
        true,
      ),
      value: ['all'],
      valid: true,
      touched: true,
      error: '',
    },
    {
      fieldId: 'squads',
      ...createSelectbox('Выберите отдел', true, getSquadsOptions, false, true),
      value: ['all'],
      valid: true,
      touched: true,
      error: '',
    },
    {
      fieldId: 'roles',
      ...createSelectbox(
        'Выберите группу',
        true,
        () =>
          getSystemRolesOptions().then((roles) => [
            { label: 'Все группы', value: 'all', standAloneOption: true },
            ...roles,
          ]),
        false,
        true,
      ),
      value: ['all'],
      valid: true,
      touched: true,
      error: '',
    },
    {
      fieldId: 'employees',
      ...createSelectbox(
        'Выберите сотрудников',
        true,
        () =>
          getUsersOptions().then((users) => [
            { label: 'Все сотрудники', value: 'all', standAloneOption: true },
            ...users,
          ]),
        false,
        true,
      ),
      value: ['all'],
      valid: true,
      touched: true,
      error: '',
    },
    { fieldId: 'title', ...createRequiredField('input', 'text', 'Название мотивации') },
    { fieldId: 'startDate', ...createRequiredField('input', 'text', 'Начало действия', 'date') },
    { fieldId: 'endDate', ...createField('input', 'text', 'Конец действия', 'date') },
  ]

  if (formData) {
    fields.forEach((f) => {
      if (formData.hasOwnProperty(f.fieldId)) {
        f.value = formData[f.fieldId]
        f.valid = true
        f.touched = true
        f.error = ''
      }
    })
  }

  return fields
}

export default createMotivationFormFields
