import React, { useRef, useState } from 'react'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utilities/newforms'
import FormRender from '../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'
import SiteSectionTitle from '../../components/SiteSectionTitle/SiteSectionTitle'
import ConversionBlock from '../../components/ConversionBlock/ConversionBlock'
import './ContactUs.sass'
import isFormValid from '../../../utilities/newforms/validation/isFormValid'
import StatusMessage, { useStatusMessage } from '../../../ui/StatusMessage/StatusMessage'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'
import PolicyAgreement from '../PrivacyPolicy/PolicyAgreement'
import sendEmail from '../../../modules/Emails/functions/sendEmail'
import { renderEmail } from 'react-html-email'
import SupportEmail from '../../../modules/Emails/templates/SupportEmail'
import getFormValues from '../../../utilities/newforms/getFormValues'
import { omit } from 'lodash'
import subscribeToEmails from '../../../modules/Emails/functions/subscribeToEmails'

function ContactUs() {
  const [form, setForm] = useState(createForm({ formPattern: new ContactUsForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const formRef = useRef()

  const onSubmit = (e) => {
    e.preventDefault()
    scrollWithOffset(formRef.current, -128)

    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }

    setIsLoading(true)
    const values = getFormValues({ form })

    sendEmail({
      email: 'support@cdbx.io',
      subject: 'Обращение в поддержу',
      body: renderEmail(<SupportEmail {...omit(values, ['phone'])} phone={values.phone.value} />),
      listId: 70,
    }).then(() => {
      setForm(createForm({ formPattern: new ContactUsForm() }))
      setIsLoading(false)
      setStatusMessage({
        show: true,
        type: 'success',
        message: 'Мы получили ваш запрос. Наши специалисты направят ответ на указанный вами E-mail.',
        closeAfter: 5000,
      })
    })
  }

  return (
    <>
      <div className="Site-ContactUs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SiteSectionTitle
                title="Контакты"
                subTitle={
                  <>
                    <span>
                      Мы с радостью проконсультируем вас по любому интересующему вопросу, связанному с работой нашей
                      системы.
                    </span>
                    <br />
                    <span>Подскажем чем CardBox, может быть, полезен вашему бизнесу.</span>
                  </>
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Site-ContactUs-Container">
                <form ref={formRef} onSubmit={onSubmit} className="Site-Fields ContactUs-Form">
                  {statusMessage.show && (
                    <StatusMessage
                      className="Site-StatusMessage"
                      type={statusMessage.type}
                      message={statusMessage.message}
                    />
                  )}
                  <FormRender
                    sections={[{ fields: ['name', 'email', 'phone', 'companyName', 'membersQuantity', 'comment'] }]}
                    form={form}
                    setForm={setForm}
                    errors={showErrors}
                  />
                  <PolicyAgreement />
                  <div className="ContactUs-SubmitButton">
                    <Button
                      isLoading={isLoading}
                      className="AuthForm-Button"
                      icon="envelope"
                      title="Отправить"
                      fill="black"
                      theme="solid"
                      onClick={onSubmit}
                      size={48}
                    />
                  </div>
                </form>
                <div className="ContactUs-Contacts">
                  <div className="Site-Footer-Block">
                    <h5 className="Site-TitleText">Офис</h5>
                    <div className="Footer-Block-Links">
                      <a className="Contact-Data Site-PhoneNumber" href="tel: +7 (495) 133-16-38">
                        +7 (495) 133-16-38
                      </a>
                      <p className="Contact-Data">office@cdbx.io</p>
                    </div>
                  </div>
                  <div className="Site-Footer-Block">
                    <h5 className="Site-TitleText">Тех. поддержка</h5>
                    <div className="Footer-Block-Links">
                      <a className="Contact-Data Site-PhoneNumber" href="tel: +7 (921) 030-33-32">
                        +7 (921) 030-33-32
                      </a>
                      <p className="Contact-Data">support@cdbx.io</p>
                    </div>
                  </div>
                  <div className="Site-Footer-Block">
                    <h5 className="Site-TitleText">График работы</h5>
                    <div className="Footer-Block-Links">
                      <p>Пн-Пт: с 09:00 до 18:00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConversionBlock cutter="white" />
    </>
  )
}

class ContactUsForm {
  constructor() {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Введите ваше имя',
        label: 'Имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        placeholder: 'Введите ваш E-mail',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        placeholder: 'Введите ваш контактный телефон',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.companyName = {
      field: {
        fieldId: 'companyName',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Введите название компании',
        label: 'Название компании',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.membersQuantity = {
      field: {
        fieldId: 'membersQuantity',
        fieldType: 'input',
        inputType: 'number',
        step: 1,
        min: 1,
        placeholder: 'Укажите кол-во сотрудников',
        label: 'Количество сотрудников',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        placeholder: 'Ваш комментарий',
        label: 'Чем мы можем вам помочь?',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ContactUs
