import { find } from 'lodash'

const listDocWasModified = async ({ displayedData, doc, propId, fetchFunction }) => {
  const docInDisplayedData = find(displayedData, [propId, doc[propId]])

  if (docInDisplayedData) {
    const fetchedDoc = await fetchFunction(doc)
    return displayedData.map((d) => (d[propId] === doc[propId] ? fetchedDoc : d))
  }
  return null
}

export default listDocWasModified
