import { isNull, orderBy } from 'lodash'
import { useState, useEffect } from 'react'
import { db } from '../../../config/firebase'
import { getPermissionByResponsible } from '../../../modules/Module/hooks/useModulePermissions'
import { useCallbacks } from '../../shared'
import getCollection from '../getCollection'
import listDocWasAdded from './listDocWasAdded'
import listDocWasModified from './listDocWasModified'
import listDocWasRemoved from './listDocWasRemoved'
import listFetchPage from './listFetchPage'
import listOnMount from './listOnMount'

const useDocsList = ({ accountId, accountUser, path, page, fetchFunction, filterFunction, docIdName, permissions }) => {
  const [totalPages, setTotalPages] = useState(0)
  const [displayedData, setDisplayedData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filterFields, setFilterFields] = useState(null)

  useEffect(() => {
    if (!isNull(filterFields)) {
      let initialCall = true

      const listener = db.collection(path).onSnapshot((snap) => {
        let collection = []
        snap.forEach((d) => collection.push({ [docIdName]: d.id, ...d.data() }))
        collection = orderBy(collection, (d) => d.created.at.seconds * 1000, ['desc'])
        // filter collection by access permission
        collection = collection.filter((d) =>
          getPermissionByResponsible({ option: permissions.access, responsible: d.responsible, accountUser }),
        )

        collection = filterFunction({
          collection,
          filterFields,
        })

        if (!initialCall) {
          snap.docChanges().forEach((change) => {
            const doc = { [docIdName]: change.doc.id, ...change.doc.data() }
            switch (change.type) {
              case 'added':
                listDocWasAdded({
                  displayedData: handlers.getDisplayedData(),
                  collection,
                  doc,
                  page: page || 1,
                  fetchFunction: (d) => fetchFunction({ accountId, doc: d }),
                }).then((data) => {
                  setDisplayedData(data.displayedData)
                  setTotalPages(data.totalPages)
                })
                break
              case 'modified':
                listDocWasModified({
                  displayedData: handlers.getDisplayedData(),
                  doc,
                  propId: docIdName,
                  fetchFunction: (d) => fetchFunction({ accountId, doc: d }),
                }).then((data) => data && setDisplayedData(data))
                break
              case 'removed':
                listDocWasRemoved({
                  displayedData: handlers.getDisplayedData(),
                  collection,
                  page: page || 1,
                  doc,
                  propId: docIdName,
                  fetchFunction: (d) => fetchFunction({ accountId, doc: d }),
                }).then((data) => {
                  setDisplayedData(data.displayedData)
                  setTotalPages(data.totalPages)
                })
                break
              default:
                break
            }
          })
        } else {
          listOnMount({
            accountId,
            collection,
            page,
            fetchFunction: (doc) => fetchFunction({ accountId, doc }),
          }).then((data) => {
            setTotalPages(data.totalPages)
            setDisplayedData(data.fetchedChunk)
          })
        }
        initialCall = false
      })
      return () => listener()
    }
  }, [filterFields, permissions])

  const handlers = useCallbacks((callbacks) => {
    callbacks.getDisplayedData = () => displayedData
  })

  const fetchPage = (listPage) => {
    setIsLoading(true)
    getCollection({ path, docIdName, whereQueries: [{ fieldPath: 'isDeleted', op: '==', value: false }] }).then(
      (docs) => {
        const collection = orderBy(docs, (d) => d.created.at.seconds * 1000, ['desc'])
        listFetchPage({ collection, fetchFunction: (doc) => fetchFunction({ accountId, doc }), page: listPage }).then(
          (data) => {
            setDisplayedData(data.displayedData)
            setTotalPages(data.totalPages)
            setIsLoading(false)
          },
        )
      },
    )
  }

  return {
    totalPages,
    setTotalPages,
    displayedData,
    setDisplayedData,
    fetchPage,
    isLoading,
    setFilterFields,
  }
}

export default useDocsList
