import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'

export function ParamPopUpForm({ uid } = {}) {
  this.categoryId = {
    field: {
      fieldId: 'categoryId',
      fieldType: 'select',
      label: 'Категория параметра',
      required: true,
      getOptions: 'getEstateParamsCategoriesOptions',
      value: '',
      isSystemField: true,
    },
    render: getFieldRenderObject(),
  }
  this.label = {
    field: {
      fieldId: 'label',
      fieldType: 'input',
      inputType: 'text',
      required: true,
      label: 'Название параметра',
    },
    render: getFieldRenderObject(),
  }
  this.paramType = {
    field: {
      fieldId: 'paramType',
      fieldType: 'select',
      label: 'Тип параметра',
      required: true,
      getOptions: [
        { label: 'Выбор опции', value: 'option' },
        { label: 'Текстовое поле', value: 'inputText' },
        { label: 'Числовое поле', value: 'inputNumber' },
        { label: 'Чекбокс', value: 'checkbox' },
        { label: 'Чекбокс-группа', value: 'checkboxGroup' },
      ],
      value: '',
      isSystemField: true,
    },
    render: getFieldRenderObject(),
  }
  this.multiOptions = {
    field: {
      fieldId: 'multiOptions',
      fieldType: 'checkbox',
      label: 'Разрешить мультивыбор',
      required: false,
      value: false,
      isSystemField: true,
    },
    render: getFieldRenderObject({ byDefault: false, ifFieldId: 'paramType', value: 'option' }),
  }
  this.options = {
    field: {
      fieldId: 'options',
      fieldType: 'fieldset',
      fields: [
        {
          field: {
            fieldId: 'label',
            fieldType: 'input',
            inputType: 'text',
            required: true,
            label: 'Опция',
          },
          render: getFieldRenderObject(),
        },
      ],
      required: true,
    },
    render: getFieldRenderObject({ byDefault: false, ifFieldId: 'paramType', value: ['option', 'checkboxGroup'] }),
  }
  this.estateTypes = {
    field: {
      fieldId: 'estateTypes',
      value: [],
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.systemField = {
    field: {
      fieldId: 'systemField',
      value: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.created = {
    field: {
      fieldId: 'created',
      value: getCurDateWithUser(uid),
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.updated = {
    field: {
      fieldId: 'updated',
      value: getCurDateWithUser(uid),
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
  this.position = {
    field: {
      fieldId: 'position',
      value: '',
    },
    render: getFieldRenderObject({ isSystem: true }),
  }
}
