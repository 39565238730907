import { merge } from 'lodash'
import { db } from '../../config/firebase'
import { getCurDateWithUser } from '../date/getCurDateWithUser'
import rootDbPath from './rootDbPath'

export const updateDoc = ({ accountId, path, docId, data, uid = null }) => {
  let dbPath
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  let dataToUpdate = data
  if (uid) {
    dataToUpdate = merge(data, { updated: getCurDateWithUser(uid) })
  }

  return db
    .collection(dbPath)
    .doc(docId)
    .update(dataToUpdate)
    .then(() => docId)
    .catch((e) => {
      console.log('🚀 ~ file: updateDoc.js ~ line 10 ~ updateDoc ~ e', e)
      return 'fail'
    })
}
