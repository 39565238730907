import React, { useState } from 'react'
import './CategoryPopUp.sass'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import PopUp from '../../../../components/UI/PopUp/PopUp'
import getCollection from '../../../../utilities/db/getCollection'
import { createForm } from '../../../../utilities/newforms'
import FormRender from '../../../../utilities/newforms/render/FormRender'
import { CategoryPopUpForm } from './CategoryPopUpForm'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import { db } from '../../../../config/firebase'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function CategoryPopUp({ category, uid, close, accountId }) {
  const [form, setForm] = useState(
    createForm({
      formData: category,
      formPattern: new CategoryPopUpForm({ uid }),
      methods: {
        getEstateParamsCategoriesOptions: () =>
          getCollection({
            path: `${rootDbPath(accountId)}/config/estate/paramsCategories`,
            docIdName: 'value',
            orderBy: { field: 'label', dir: 'asc' },
          }),
      },
    }),
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) return setShowErrors(true)
    setIsLoading(true)
    const formValues = getFormValues({ form })

    const dbRef = db.collection(`${rootDbPath(accountId)}/config/estate/paramsCategories`)

    if (category.operation === 'edit') {
      dbRef
        .doc(category.categoryId)
        .update({ ...omit(formValues, ['categoryId', 'created']), updated: getCurDateWithUser(uid) })
        .then(() => close())
        .catch((e) => {
          console.log(e)
          setIsLoading(false)
        })
    } else {
      db.collection(`${rootDbPath(accountId)}/config/estate/paramsCategories`)
        .get()
        .then((docs) => {
          const { size } = docs
          dbRef
            .add({ ...formValues, position: size })
            .then(() => close())
            .catch((e) => {
              console.log(e)
              setIsLoading(false)
            })
        })
    }
  }

  return (
    <PopUp
      title={`${category.categoryId ? 'Редактировать' : 'Добавить новую'} категорию параметов`}
      show
      submitButtons
      close={close}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <div className="ParamPopUp-Container">
        <FormRender sections={[{ fields: ['label'] }]} form={form} setForm={setForm} errors={showErrors} />
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(CategoryPopUp)
