import React from 'react'
import { Image, Box, A } from 'react-html-email'
import EmailFooter from '../EmailFooter/EmailFooter'

function EmailBody({ emailIcon, homePagePath, children }) {
  return (
    <div
      style={{
        width: '100%',
        background: '#F6F8F9',
        height: '100%',
        paddingTop: '64px',
        paddingBottom: '64px',
        paddingLeft: '32px',
        paddingRight: '32px',
        textAlign: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Box
        align="center"
        style={{
          maxWidth: '600px',
          width: '100%',
          display: 'inline-block',
        }}
      >
        <div style={{ textAlign: 'center', paddingBottom: '24px', width: '100%' }}>
          <A href={homePagePath} target="_blank" textDecoration="none">
            <Image
              src="https://storage.googleapis.com/estate-cdbx-io-public/emails/primaryHor.png"
              width={138}
              height={32}
              alt="CardBox"
              style={{ display: 'inline-block' }}
            />
          </A>
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
            color: '#1D1F29',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
          }}
        >
          <div
            width={600}
            height={96}
            alt="top-pattern"
            style={{
              width: '600px',
              height: '96px',
              backgroundImage: 'url(https://storage.googleapis.com/estate-cdbx-io-public/emails/emailTopPattern.jpg)',
              backgroundSize: '600px 48px',
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              display: 'block',
              paddingTop: '24px',
              textAlign: 'center',
              boxSizing: 'border-box',
            }}
          >
            <span
              style={{
                width: '42px',
                height: '42px',
                borderRadius: '100%',
                backgroundColor: '#27C043',
                textAlign: 'center',
                display: 'inline-block',
              }}
            >
              <Image src={emailIcon} width={42} height={42} alt="icon" />
            </span>
          </div>
          <div
            style={{
              paddingTop: '0px',
              paddingLeft: '48px',
              paddingBottom: '48px',
              paddingRight: '48px',
              width: '100%',
              textAlign: 'left',
              boxSizing: 'border-box',
            }}
          >
            {children}
          </div>
          <EmailFooter />
        </div>
      </Box>
    </div>
  )
}

export default EmailBody
