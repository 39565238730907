import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, omit } from 'lodash'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'
import getDoc from '../../../../../utilities/db/getDoc'
import { createForm } from '../../../../../utilities/newforms'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import PhotoManager from '../../../../../components/PhotoManager/PhotoManager'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import './NewsManager.sass'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import rootPath from '../../../../../appTree/rootPath'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import { addDoc } from '../../../../../utilities/db/addDoc'
import uploadPhotos from './functions/uploadPhotos'
import rootDbPath from '../../../../../utilities/db/rootDbPath'

function NewsManager({ accountId, uid, ...router }) {
  const newsId = router.match.params.id

  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ accountId, path: 'news', docId: newsId }).then((result) => {
      console.log('🚀 ~ file: NewsManager.jsx:34 ~ getDoc ~ result:', result)
      let formData = {}
      if (result) formData = result

      setForm(
        createForm({
          formData,
          formPattern: new NewsForm(uid),
        }),
      )
    })
  }, [])

  const submitForm = () => {
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Невозможно сохранить новость. Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }

    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const data = {
      ...values,
      updated: getCurDateWithUser(uid),
      ...(!newsId ? { created: getCurDateWithUser(uid) } : {}),
      photos: values.photos.filter((p) => !p.needUpload).map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = newsId
      ? updateDoc({ accountId, path: 'news', docId: newsId, data })
      : addDoc({ accountId, path: 'news', data })

    opRef.then((docId) =>
      uploadPhotos({ accountId, photos: values.photos, docId, docPath: `news`, storagePath: 'news' })
        .then(() => {
          router.history.push(`${rootPath(accountId)}/settings/site/news/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log('🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error', error)
        }),
    )
  }

  return (
    <SceneContent title={newsId ? 'Редактировать новость' : 'Создать новость'}>
      <div className="NewsManager">
        <FormRender
          sections={[{ fields: ['status', 'title', 'shortNews', 'longNews'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <PhotoManager
          accountId={accountId}
          projectId={newsId}
          projectPath="news"
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
      </div>
      <SubmitFormButtons
        className="Module-SubmitFormButtons"
        isLoading={isLoading}
        onSubmit={submitForm}
        onCancel={() => router.history.push(`${rootPath(accountId)}/settings/site/news`)}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        // listenLatestUpdates={{ path: 'estate', docId: newsId }}
      />
    </SceneContent>
  )
}
class NewsForm {
  constructor(uid) {
    this.status = {
      field: {
        fieldId: 'status',
        fieldType: 'select',
        label: 'Статус',
        value: 'draft',
        required: true,
        getOptions: [
          { label: 'Опубликовано', value: 'published' },
          { label: 'Черновик', value: 'draft' },
        ],
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
    this.shortNews = {
      field: {
        fieldId: 'shortNews',
        fieldType: 'texteditor',
        label: 'Краткий текст новости',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.longNews = {
      field: {
        fieldId: 'longNews',
        fieldType: 'texteditor',
        label: 'Полный текст новости',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Заголовок',
        // value: '1',
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: {
        fieldId: 'photos',
        value: [],
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: getCurDateWithUser(uid),
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: {
          at: '',
          by: '',
        },
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(NewsManager))
