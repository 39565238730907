import getFieldRenderObject from '../../../../../../../utilities/newforms/render/getFieldRenderObject'

const officeDbForm = {
  title: {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      label: 'Название офиса',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  infoBlocks: {
    field: {
      fieldId: 'infoBlocks',
      value: [],
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  squads: {
    field: {
      fieldId: 'squads',
      value: [],
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  created: {
    field: {
      fieldId: 'created',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export default officeDbForm
