const fetchEstateOperation = (operationId) => {
  switch (operationId) {
    case 'sell':
      return 'Продажа'
    case 'rent':
      return 'Аренда'
    default:
      break
  }
}

export default fetchEstateOperation
