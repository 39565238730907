import React, { useState } from 'react'

import { db } from '../../../../config/firebase'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'

import './SetTask.sass'

import Button from '../../../../ui/Button/Button'
import NewTaskConstructor from '../../../Tasks/scenes/Tasks/Task/components/NewTaskConstructor/NewTaskConstructor'
import { addDoc } from '../../../../utilities/db/addDoc'
import { connect } from 'react-redux'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function SetTask({ accountId, onTaskAdded, attachableData, uid }) {
  const [isLoading, setIsLoading] = useState(false)

  const [taskConstructor, updateTaskConstructor] = useState({})

  const submitTask = () => {
    setIsLoading(true)

    addDoc({ accountId, path: 'tasks', data: taskConstructor.taskConfig })
      .then((taskId) =>
        db.collection(`${rootDbPath(accountId)}/history`).add({
          docId: attachableData.objectId,
          type: 'task',
          objId: taskId,
          created: getCurDateWithUser(uid),
        }),
      )
      .then(() => {
        onTaskAdded('comment')
        setIsLoading(false)
      })
  }

  return (
    <>
      <NewTaskConstructor pushComponentControlls={updateTaskConstructor} attachableData={attachableData} uid={uid} />
      <div className="Buttons-Container">
        <Button size={28} onClick={submitTask} isLoading={isLoading} theme="solid" fill="accent">
          Отправить
        </Button>
        <Button size={28} onClick={() => taskConstructor.dropTaskConfig()} theme="bounded" color="black" border="gray">
          Отменить
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(SetTask)
