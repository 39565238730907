import dealObject, { dealManagerForm } from '../../modules/Deals/scenes/Dealslist/DealManager/dealDbForm'
import funnelStepObject from '../../modules/Funnel/functions/FunnelStepObject'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'
import { addDoc } from '../../utilities/db/addDoc'
import { setDoc } from '../../utilities/db/setDoc'

const installTasks = ({ accountId, uid }) => {
  const columns = [
    {
      docId: 'archive',
      data: {
        addTaskButton: false,
        filterRules: [{ comparisonField: 'isFinished', comparisonVal: true, op: '==' }],
        label: 'Завершенные задачи',
        onMountFetch: false,
        orderBy: { field: '', type: '' },
        position: 4,
        styles: {
          dateTagBg: '#E9E9E9',
          dateTagText: '#A7ACB0',
          markerColor: '#A7ACB0',
        },
      },
    },
    {
      docId: 'expired',
      data: {
        addTaskButton: false,
        filterRules: [
          { comparisonField: 'endDate', comparisonVal: 'NOW', op: '<' },
          { comparisonField: 'isFinished', comparisonVal: false, op: '==' },
          { comparisonField: 'endDate', comparisonVal: 'START_OF_TODAY', op: '!=' },
        ],
        label: 'Просроченные задачи',
        onMountFetch: true,
        orderBy: { field: 'endDate', type: '' },
        position: 0,
        styles: {
          dateTagBg: '#FFE5ED',
          dateTagText: '#F34375',
          markerColor: '#F34375',
        },
      },
    },
    {
      docId: 'future',
      data: {
        addTaskButton: true,
        filterRules: [
          { comparisonField: 'startDate', comparisonVal: 'END_OF_TOMORROW', op: '>' },
          { comparisonField: 'isFinished', comparisonVal: false, op: '==' },
        ],
        label: 'Будущие задачи',
        newTaskConfig: {
          endDate: 'END_OF_DAY_AFTER_TOMORROW',
          startDate: 'END_OF_DAY_AFTER_TOMORROW',
        },
        onMountFetch: true,
        orderBy: { field: 'startDate', type: '' },
        position: 3,
        styles: {
          dateTagBg: '#E9E9E9',
          dateTagText: '#A7ACB0',
          markerColor: '#8B54FF',
        },
      },
    },
    {
      docId: 'today',
      data: {
        addTaskButton: true,
        filterRules: [
          { comparisonField: 'endDate', comparisonVal: 'START_OF_TODAY', op: '>=' },
          { comparisonField: 'endDate', comparisonVal: 'NOW', op: '>' },
          { comparisonField: 'isFinished', comparisonVal: false, op: '==' },
          { comparisonField: 'endDate', comparisonVal: 'END_OF_TODAY', op: '<=' },
        ],
        label: 'Задачи на сегодня',
        newTaskConfig: {
          endDate: 'END_OF_TODAY',
          startDate: 'END_OF_TODAY',
        },
        onMountFetch: true,
        orderBy: { field: 'endDate', type: '' },
        position: 1,
        styles: {
          dateTagBg: '#BEF0C7',
          dateTagText: '#029E1E',
          markerColor: '#029E1E',
        },
      },
    },
    {
      docId: 'tomorrow',
      data: {
        addTaskButton: true,
        filterRules: [
          { comparisonField: 'endDate', comparisonVal: 'START_OF_TOMORROW', op: '>=' },
          { comparisonField: 'endDate', comparisonVal: 'END_OF_TOMORROW', op: '<=' },
          { comparisonField: 'isFinished', comparisonVal: false, op: '==' },
        ],
        label: 'Задачи на завтра',
        newTaskConfig: {
          endDate: 'END_OF_TOMORROW',
          startDate: 'END_OF_TOMORROW',
        },
        onMountFetch: true,
        orderBy: { field: 'endDate', type: '' },
        position: 2,
        styles: {
          dateTagBg: '#FFF5D0',
          dateTagText: '#D0A716',
          markerColor: '#F3D743',
        },
      },
    },
  ]
  const tags = [
    {
      data: {
        label: 'Встреча',
        styles: {
          tagBg: '#DBF4FF',
          tagBorder: '#4DC9FF',
          tagText: '#4DC9FF',
        },
      },
    },
    {
      data: {
        label: 'Связаться',
        styles: {
          tagBg: '#B5E8C0',
          tagBorder: '#029E1E',
          tagText: '#029E1E',
        },
      },
    },
  ]

  return Promise.all([
    setDoc({ accountId, path: 'config', docId: 'tasks', data: {} }),
    columns.map((d) => setDoc({ accountId, path: 'config/tasks/columns', ...d })),
    tags.map((d) => addDoc({ accountId, path: 'config/tasks/tags', ...d })),
  ])
}

export default installTasks
