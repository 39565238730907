import React, { forwardRef } from 'react'
import './CallInfoPopUp.sass'
import SceneContentHeader from '../../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import ScrollbarContainer from '../../../../components/UI/SrollbarContainer/ScrollbarContainer'

function CallInfoPopUp({ title, content, onClose, forwardedRef }) {
  return (
    <div ref={forwardedRef} className="CallInfoPopUp">
      <SceneContentHeader
        title={title}
        buttons={[
          {
            type: 'basic',
            icon: 'times',
            clicked: onClose,
          },
        ]}
      />
      <ScrollbarContainer style={{ maxHeight: '400px' }}>{content}</ScrollbarContainer>
    </div>
  )
}

export default forwardRef((props, ref) => <CallInfoPopUp {...props} forwardedRef={ref} />)
