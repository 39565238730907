import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SceneWithHistory from '../../../../../components/UI/SceneWithHistory/SceneWithHistory'
import ModuleWithSubmenu from '../../../../Module/ModuleWithSubmenu'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import contactsEvents from '../../Contactlist/ContactsManager/events/contactsEvents'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import getDoc from '../../../../../utilities/db/getDoc'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import rootPath from '../../../../../appTree/rootPath'
import getOrganizationsManagerSubmenu from './functions/getOrganizationsManagerSubmenu'
import AttachedContacts from '../../../AttachedContacts/AttachedContacts'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import detachContact from './functions/detachContact'
import createOrganizationForm from './functions/createOrganizationForm'
import submitOrganizationForm from './functions/submitOrganizationForm'
import useModulePermissions from '../../../../Module/hooks/useModulePermissions'
import TagCloud from '../../../../../components/TagCloud/TagCloud'

function OrganizationsManager({
  accountId,
  accountUser,
  permissions: rights,
  options = {},
  closeLink,
  uid,
  ...router
}) {
  const organizationId = router.match.params.id

  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [localHistory, setLocalHistory] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const permissions = useModulePermissions({ form, rights, accountUser })

  useEffect(() => {
    promiseAllValues({
      formData: organizationId ? getDoc({ accountId, path: 'organizations', docId: organizationId }) : {},
      formPattern: getDoc({ accountId, path: 'config/contacts/objects', docId: 'organizationObject' }),
      sections: getDoc({ accountId, path: 'config/contacts/forms', docId: 'organizationManager' }).then(
        (data) => data.sections,
      ),
    }).then((result) => {
      let formData

      if (organizationId) {
        formData = result.formData
      } else {
        formData = { responsible: uid }
      }

      setForm(createOrganizationForm({ accountId, formData, formPattern: result.formPattern }))
      setSections(result.sections)
    })
  }, [])

  // const SaveObject = () => {
  //   setDoc({ accountId, path: 'config/contacts/objects', docId: 'organizationObject', data: organizationDbForm })
  // }

  return (
    <ModuleWithSubmenu
      subsections={getOrganizationsManagerSubmenu({
        accountId,
        organizationId,
        modulePath: closeLink,
        counters: {
          deals: form ? form.deals.values.length : 0,
          estate: form ? form.estate.values.length : 0,
        },
        history: router.history,
      })}
      className="Manager-Container"
    >
      <SceneWithHistory
        className="ContactManager"
        historyProps={{
          useLocalState: !organizationId && true,
          pushState: setLocalHistory,
          fetchPath: `${rootDbPath(accountId)}/organizations/${organizationId}/history`,
          eventsTypeSet: contactsEvents,
          attachableData: {
            section: `${rootDbPath(accountId)}/organizations`,
            objectId: organizationId,
          },
        }}
      >
        {/* <button onClick={SaveObject}>Save!</button> */}
        <div className="SceneContent ContactManager-Form">
          <FormRender
            sections={sections}
            form={form}
            setForm={setForm}
            errors={showErrors}
            sectionsSkeleton={[4, 4, 2]}
            permissions={permissions}
            tagCloud={
              <TagCloud
                initialTags={form && form.tags.values}
                suggestionsPath={`${rootDbPath(accountId)}/config/contacts/tags`}
                syncState={(moduleState) =>
                  syncForm({ propIdentifier: 'tags', form, setForm, newForm: moduleState.map((t) => t.tagId) })
                }
                disableEdit={permissions ? !permissions.edit : true}
                allowNewTags={options.createTag}
              />
            }
          />
        </div>
        {form && (
          <AttachedContacts
            formData={form.contacts.values}
            docId={organizationId}
            detachFunction={(contactId) => detachContact({ accountId, organizationId, contactId, uid })}
            syncState={(state) => state && syncForm({ propIdentifier: 'contacts', form, setForm, newForm: state })}
            superstructure={{
              object: {
                path: 'config/contacts/objects',
                docId: 'organizationContactObject',
              },
              form: {
                path: 'config/contacts/forms',
                docId: 'organizationContactManager',
              },
            }}
            permissions={permissions}
          />
        )}
      </SceneWithHistory>
      <SubmitFormButtons
        className="Module-SubmitFormButtons"
        isLoading={isLoading}
        onSubmit={() =>
          submitOrganizationForm({
            accountId,
            organizationId,
            form,
            setStatusMessage,
            setShowErrors,
            setIsLoading,
            uid,
            onSuccessFunc: (docId) =>
              router.history.push(`${rootPath(accountId)}/contacts/organizations/list/${docId}/edit`),
          })
        }
        onCancel={() => router.history.push(`${rootPath(accountId)}/contacts/organizations/list`)}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        listenLatestUpdates={{ path: 'organizations', docId: organizationId }}
        disableEdit={permissions ? !permissions.edit : true}
      />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
  accountUser: state.auth.accountUser,
})

export default compose(withRouter, connect(mapStateToProps))(OrganizationsManager)
