import { addDoc } from '../../utilities/db/addDoc'
import { setDoc } from '../../utilities/db/setDoc'

const installGlobal = ({ accountId, uid }) => {
  const adSource = [
    { data: { label: 'Avito' } },
    { data: { label: 'Холодный звонок' } },
    { data: { label: 'Наружная реклама' } },
    { data: { label: 'Расклейка' } },
    { data: { label: 'Радио' } },
    { data: { label: 'Повторное обращение' } },
    { data: { label: 'Рекомендация' } },
    { data: { label: 'Сайт' } },
    { data: { label: 'ТВ' } },
    { data: { label: 'VK' } },
  ]
  const docCreationTypes = [
    { data: { label: 'Входящий звонок' } },
    { data: { label: 'Лендинг' } },
    { data: { label: 'Вручную' } },
    { data: { label: 'Исходящий звонок' } },
    { data: { label: 'Сайт агентства' } },
  ]
  const maps = [
    {
      docId: 'config',
      data: {
        center: {
          lat: '59.9342802',
          lng: '30.3350986',
        },
        defaultCenter: {
          lat: '59.9342802',
          lng: '30.3350986',
        },
        defaultZoom: 13,
        draggable: true,
      },
    },
  ]
  const services = [
    {
      docId: 'default',
      data: {
        funnelId: 'default',
        label: 'Покупка товара',
        needFormId: null,
      },
    },
  ]

  return Promise.all([
    setDoc({ accountId, path: 'config', docId: 'global', data: {} }),
    adSource.map((d) => addDoc({ accountId, path: 'config/global/adSource', ...d })),
    docCreationTypes.map((d) => addDoc({ accountId, path: 'config/global/docCreationTypes', ...d })),
    maps.map((d) => setDoc({ accountId, path: 'config/global/maps', ...d })),
    services.map((d) => setDoc({ accountId, path: 'config/global/services', ...d })),
  ])
}

export default installGlobal
