import React from 'react'
import './ControllButtons.sass'

const controllButtons = props => {
  return (
    <div className='ControllButtons'>
      <div className='ControllButtons-Container'>{props.children}</div>
    </div>
  )
}

export default controllButtons
