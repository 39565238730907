import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import getCollection from '../../../../../../utilities/db/getCollection'

const getDeals = ({ accountId, dateRange = null, responsible = null } = {}) =>
  getCollection({
    accountId,
    path: 'deals',
    docIdName: 'dealId',
    whereQueries: [{ fieldPath: 'isDeleted', op: '==', value: false }],
  }).then((deals) =>
    deals
      .filter((d) => {
        if (dateRange) {
          return (
            isAfter(d.created.at.seconds * 1000, dateRange.startingDate) &&
            isBefore(d.created.at.seconds * 1000, dateRange.endingDate)
          )
        }
        return true
      })
      .filter((d) => {
        if (responsible) {
          return responsible.includes(d.responsible)
        }
        return true
      }),
  )

export default getDeals
