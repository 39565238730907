import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './Dealslist.sass'

import { isArray } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import NumberFormat from 'react-number-format'
import Pagination from '../../../../components/UI/Pagination/PaginationNew'
import SceneContent from '../../../../components/UI/SceneContent/SceneContent'
import SceneTable from '../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import ModuleWithSubmenu from '../../../Module/ModuleWithSubmenu'
import rootPath from '../../../../appTree/rootPath'
import rootDbPath from '../../../../utilities/db/rootDbPath'
import fetchDealObject from './functions/fetch/fetchDealObject'
import filterDeals from './functions/filterDeals'
import useDocsList from '../../../../utilities/db/list/useDocsList'
import DeleteDealPopUp from './DeleteDealPopUp'
import FunnelProgressBar from '../../../Funnel/FunnelProgressBar/FunnelProgressBar'
import TableContact from '../../../Contacts/TableContact/TableContact'
import { Counter } from '../../../Analytics/components/InBaseCounter/InBaseCounter'
import getDealAvailableDays from './functions/getDealAvailableDays'
import DealsFilters from './DealsFilters'
import { getPermissionByResponsible } from '../../../Module/hooks/useModulePermissions'
import TableTagCloud from '../../../../components/TagCloud/TableTagCloud'

function Dealslist({ accountId, accountUser, permissions, options, subsections, uid, ...router }) {
  const { page } = router.match.params
  const { displayedData, totalPages, fetchPage, isLoading, setFilterFields } = useDocsList({
    accountId,
    accountUser,
    path: `${rootDbPath(accountId)}/deals`,
    page,
    fetchFunction: fetchDealObject,
    filterFunction: filterDeals,
    docIdName: 'dealId',
    permissions,
  })
  const [showPopUp, setShowPopUp] = useState(null)

  const [showFilters, setShowFilters] = useState(false)
  const [filterButtons, setFilterButtons] = useState(null)

  return (
    <ModuleWithSubmenu subsections={subsections}>
      <DealsFilters
        show={showFilters}
        close={() => setShowFilters(false)}
        pushFilterFields={setFilterFields}
        pushFilterButtons={setFilterButtons}
      />
      <SceneContent
        title="Список сделок"
        filters={filterButtons}
        buttons={[
          {
            type: 'basic',
            icon: 'filter',
            clicked: () => setShowFilters(true),
          },
          ...(permissions.add
            ? [
                {
                  type: 'add',
                  icon: 'plus',
                  clicked: () => router.history.push(`${rootPath(accountId)}/deals/list/add`),
                },
              ]
            : []),
        ]}
      >
        <>
          {showPopUp && <DeleteDealPopUp dealId={showPopUp} closePopUp={() => setShowPopUp(null)} />}
          <SceneTable
            editOnDoubleClick
            className="Dealslist"
            header={[
              ['Дата создания', 110],
              ['Сделка'],
              ['Тип потребности', 100],
              ['Комиссия', 100],
              ['Контакт', 180],
              ['Дней в работе', 60],
              ['Этап', 180],
              ['Ответственный', 180],
              ['', 32],
            ]}
            skeleton={getLoadingSkeleton(15)}
            useCustomOptions
            body={
              isArray(displayedData)
                ? displayedData.map((deal, i) => [
                    { id: deal.dealId },
                    formatDateAsDayMontYearHHMM(deal.created.at),
                    <div>
                      <span>{deal.title}</span>
                      <TableTagCloud tags={deal.tags} style={{ marginTop: '4px' }} />
                    </div>,
                    <span>{deal.need}</span>,
                    <span>
                      {deal.comission ? (
                        <NumberFormat
                          value={deal.comission}
                          displayType="text"
                          thousandSeparator={' '}
                          suffix=" руб."
                        />
                      ) : (
                        ''
                      )}
                    </span>,
                    <TableContact fetchedContacts={deal.contact} />,
                    <Counter
                      getAvailableDaysFunc={() => getDealAvailableDays(accountId)}
                      key={`displayed-data-${deal.dealId}-${i}`}
                      size={32}
                      slice={12}
                      startDate={deal.created.at.seconds * 1000}
                      endDate={deal.finishedWith.status && deal.updated.at.seconds * 1000}
                    />,
                    <FunnelProgressBar finishedWith={deal.finishedWith} funnel={deal.funnel} />,
                    <UserNameWithAvatar
                      key={`displayed-data-${deal.responsible.responsibleId}-${i}`}
                      avatarUrl={deal.responsible.avatar.publicUrl}
                      avatarSize="26"
                      userName={`${deal.responsible.lastName} ${deal.responsible.name} ${deal.responsible.middleName}`}
                    />,
                    {
                      options: [
                        {
                          return: 'docId',
                          type: 'edit',
                          icon: 'external-link',
                          label: 'Открыть',
                          clicked: (id) => router.history.push(`${rootPath(accountId)}/deals/list/${id}/edit`),
                        },
                        ...(getPermissionByResponsible({
                          option: permissions.delete,
                          responsible: deal.responsible.userId,
                          accountUser,
                        })
                          ? [
                              {
                                return: 'docId',
                                type: 'delete',
                                icon: 'trash-alt',
                                label: 'Удалить',
                                clicked: setShowPopUp,
                              },
                            ]
                          : []),
                      ],
                    },
                  ])
                : displayedData
            }
            isLoading={isLoading}
          />
        </>
      </SceneContent>
      <Pagination totalPages={totalPages} parentPath={`${rootPath(accountId)}/deals/list`} onPageClicked={fetchPage} />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(Dealslist))

function getLoadingSkeleton(count) {
  const content = []

  for (let index = 0; index < count; index++) {
    content.push([
      <Skeleton />,
      <Skeleton />,
      <Skeleton />,
      <Skeleton />,
      <div className="RowDataColumn" style={{ gridTemplateColumns: '24px 1fr' }}>
        <Skeleton width={16} height={16} circle />
        <Skeleton />
        <Skeleton width={16} height={16} circle />
        <Skeleton />
      </div>,
      <Skeleton width={32} height={32} circle />,
      <div className="RowDataColumn">
        <Skeleton width={60} />
        <Skeleton />
      </div>,
      <div className="Skeleton-Responsible">
        <Skeleton circle width={24} height={24} />
        <Skeleton />
      </div>,
      <Skeleton circle width={24} height={24} />,
    ])
  }

  return content
}
