import { useState, useEffect } from 'react'
import { getUrlQueryParams } from '../utilities/queries/'
import getRangePickerObject from '../modules/Dashboard/components/RangePicker/functions/getRangePickerObject'
import formatDate from '../modules/Dashboard/components/RangePicker/functions/formatDate'
import { getDatePickerValuesFromUrlParams } from '../modules/Dashboard/components/RangePicker/CustomDatePicker'
import merge from 'lodash/merge'

const usePeriodFromUrl = ({ listenUrl, history, defaultPeriod }) => {
  const [rangePicker, setRangePicker] = useState(null)

  useEffect(() => {
    const { period } = getUrlQueryParams({ history, params: ['period'] })
    let rPicker = merge({ type: period, years: [] }, getRangePickerObject({ type: period }))

    if (period === 'custom') {
      const datePicker = getDatePickerValuesFromUrlParams(history)
      if (datePicker.startDate && datePicker.endDate) {
        rPicker = merge(
          { type: period, years: [] },
          getRangePickerObject({ type: period, startingDate: datePicker.startDate, endingDate: datePicker.endDate }),
        )
        rPicker.label = `${formatDate(datePicker.startDate)} - ${formatDate(datePicker.endDate)}`
        rPicker.startingDate = datePicker.startDate
        rPicker.endingDate = datePicker.endDate
      }
    }
    setRangePicker(rPicker)
  }, [history.location.search])

  return [rangePicker, setRangePicker]
}

export default usePeriodFromUrl
