import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'

export const getContactObject = ({
  creationType = '',
  adSource = '',
  lastName = '',
  name = '',
  middleName = '',
  gender = '',
  phone = [],
  email = [],
  messangers = [],
  social = [],
  address = [],
  dates = [],
  leads = [],
  sales = [],
  estate = [],
  responsible,
  uid,
}) => {
  return {
    creationType,
    adSource,
    lastName,
    name,
    middleName,
    gender,
    phone,
    email,
    messangers,
    social,
    address,
    dates,
    leads,
    sales,
    estate,
    responsible,
    created: getCurDateWithUser(uid),
    updated: getCurDateWithUser(uid),
  }
}
