import React from 'react'
import Icon from '../../../../components/UI/Icon/Icon'

function InstalledAppMarker() {
  return (
    <span className="InstalledAppMarker">
      <Icon name="check" /> Это приложение установлено
    </span>
  )
}

export default InstalledAppMarker
