import React from 'react'
import './SiteDropdownLink.sass'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Icon from '../../../../components/UI/Icon/Icon'
import scrollWithOffset from '../../../../ui/ScrollToTop/scrollWithOffset'

function SiteDropdownLink({
  icon,
  path,
  navLink = false,
  hashLink = false,
  targetBlank = false,
  title,
  description = null,
}) {
  const link = (
    <>
      <div className="Site-DropdownLink-Icon">
        <Icon name={icon} />
      </div>
      <div className="Site-DropdownLink-Container">
        <p className="Link-Title">{title}</p>
        {description && <p className="Link-Description">{description}</p>}
      </div>
    </>
  )

  return navLink ? (
    <NavLink to={path} className="Site-DropdownLink">
      {link}
    </NavLink>
  ) : hashLink ? (
    <HashLink to={path} className="Site-DropdownLink" smooth scroll={(el) => scrollWithOffset(el, -64)}>
      {link}
    </HashLink>
  ) : (
    <a href={path} className="Site-DropdownLink" target={targetBlank ? 'blank' : ''}>
      {link}
    </a>
  )
}

export default SiteDropdownLink
