import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import DeletePopUp from '../../../../../../../components/DeletePopUp/DeletePopUp'
import getDoc from '../../../../../../../utilities/db/getDoc'
import deleteSquad from './functions/deleteSquad'

const DeleteOfficePopUp = ({ accountId, officeId, squadId, uid, close }) => {
  const [squadData, setSquadData] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteSquad({ accountId, officeId, squadId, uid }).then(() => {
      close()
    })
  }

  useEffect(() => {
    getDoc({ accountId, path: 'offices', docId: officeId }).then((office) => {
      const squad = find(office.squads, ['squadId', squadId])
      if (squad) setSquadData(squad)
    })
  }, [])

  return (
    <DeletePopUp
      title="Удаление отдела"
      warningMessage={
        <>
          Вы уверены, что хотите удалить отдел <span className="Bolder">"{squadData.title}"</span>
          ?
          <br />
          Все данные, как-либо связанные с этим отделом, будут удалены.
          <br />
          Восстановить удалённые данные будет невозможно.
        </>
      }
      fieldLabel="Чтобы удалить этот отдел, введите его идентификатор:"
      identifier={squadId}
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DeleteOfficePopUp)
