import React from 'react'
import './Home.sass'
import Benefits from '../../components/Benefits/Benefits'
import Hero from '../../components/Hero/Hero'
import WhyWe from '../../components/WhyWe/WhyWe'
import ConversionBlock from '../../components/ConversionBlock/ConversionBlock'
import AboutEstate from '../../components/AboutEstate/AboutEstate'
import CrmRender from '../../components/CrmRender/CrmRender'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <CrmRender />
      <AboutEstate />
      <ConversionBlock />
    </div>
  )
}

export default Home
