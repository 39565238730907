import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import rootPath from '../../../appTree/rootPath'
import getCollection from '../../../utilities/db/getCollection'
import Skeleton from '../../../components/UI/Skeleton/Skeleton'
import './ExtensionsCategories.sass'

function ExtensionsCategories({ accountId, ...router }) {
  const [categories, setCategories] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'market/apps/categories',
      docIdName: 'categoryId',
      orderBy: { fieldPath: 'order', orderDir: 'asc' },
    }).then((data) => setCategories(data))
  }, [])

  useEffect(() => {
    if (!router.match.params.categoryId) {
      return router.history.push(`${rootPath(accountId)}/settings/market/apps/all`)
    }
  }, [router.location.pathname])

  return (
    <div className="ExtensionsCategories">
      {categories ? (
        <>
          <NavLink
            to={`${rootPath(accountId)}/settings/market/apps/all`}
            className="Category"
            activeClassName="Category_theme_active"
          >
            Все
          </NavLink>
          {categories.map((c) => (
            <NavLink
              to={`${rootPath(accountId)}/settings/market/apps/${c.categoryId}`}
              className="Category"
              activeClassName="Category_theme_active"
            >
              {c.label}
            </NavLink>
          ))}
        </>
      ) : (
        <CategoriesSkeleton />
      )}
    </div>
  )
}

function CategoriesSkeleton() {
  const skeleton = <Skeleton width={80} height={28} className="CategorySkeleton" />
  return [skeleton, skeleton, skeleton, skeleton, skeleton]
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(ExtensionsCategories)
