import rootPath from '../../../../../../appTree/rootPath'
import subsectionConfig from '../../../../../../appTree/subsectionConfig'

function getOrganizationsManagerSubmenu({ accountId, organizationId, modulePath, counters, history }) {
  const submenuLinks = [
    subsectionConfig({
      id: '',
      title: 'Инфо об организации',
      path: organizationId ? `${modulePath}/${organizationId}/edit` : `${modulePath}/add`,
      access: true,
    }),
    subsectionConfig({
      title: 'Сделки',
      path: `${modulePath}/${organizationId}/deals`,
      icon: {
        name: 'plus',
        onClick: () => history.push(`${rootPath(accountId)}/deals/list/add?contactId=${organizationId}`),
      },
      counter: counters.deals,
      // access: organizationId,
      access: false,
    }),
    subsectionConfig({
      title: 'Объекты',
      path: `${modulePath}/${organizationId}/estate`,
      icon: {
        name: 'plus',
        onClick: () => history.push(`${rootPath(accountId)}/estate/list/add?contactId=${organizationId}`),
      },
      counter: counters.estate,
      // access: organizationId,
      access: false,
    }),
  ]

  return submenuLinks
}

export default getOrganizationsManagerSubmenu
