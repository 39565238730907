import React from 'react'
import './TaskResponsible.sass'

import { connect } from 'react-redux'
import DropdownBox from '../../../../../../../../components/UI/DropdownBox/DropdownBox'
import Spinner from '../../../../../../../../components/UI/Spinner/Spinner'
import Icon from '../../../../../../../../components/UI/Icon/Icon'
import TaskNavElement from '../TaskNavElement'
import useDropdownOptions from './useDropdownOptions'
import useFilterObjValues from '../../../../../../../../hooks/useFilterObjValues'
import { getMembersOptions } from '../../../../../../../../utilities/getOptions/getMembersOptions'

function TaskNavResponsible({ accountId, icon, label, data, uid, canBeEdit }) {
  return (
    <TaskNavElement icon={icon} label={label} canBeEdit={canBeEdit}>
      <TaskResponsible accountId={accountId} data={data} uid={uid} />
    </TaskNavElement>
  )
}

export function TaskResponsible({
  accountId,
  data,
  uid,
  chooseAllOption = true,
  standAloneOptions = false,
  returnValueCb = null,
  returnOptionsCb = null,
}) {
  const loadComponentOptions = async () => {
    const options = await getMembersOptions({ accountId })
    if (chooseAllOption) {
      options.unshift({ label: 'Все сотрудники', value: 'all', responsibleId: 'all', isStandalone: true })
    }
    if (standAloneOptions) {
      options.forEach((o) => {
        o.isStandalone = true
      })
    }

    return options
  }

  const { options, optionClicked } = useDropdownOptions(
    accountId,
    data.taskId,
    'responsible',
    { options: data.responsible, valueField: 'responsibleId' },
    loadComponentOptions,
    true,
    uid,
    returnValueCb,
    returnOptionsCb,
  )
  const [filteredOptions, setSearchFieldVal] = useFilterObjValues(options)

  return (
    <div className="TaskNav-TaskResponsible">
      <DropdownBox isSearchable pushSearchVal={setSearchFieldVal} styles={{ minWidth: '180px', right: 0 }}>
        {options.length ? (
          filteredOptions.map((option) => (
            <li key={option.value} className="DropdownBox-Element" onClick={() => optionClicked(option, returnValueCb)}>
              <span>{option.label}</span>
              {option.active && <Icon name="check" size="10" />}
            </li>
          ))
        ) : (
          <Spinner type="popup" />
        )}
      </DropdownBox>
    </div>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

export default connect(mapStateToProps)(TaskNavResponsible)
