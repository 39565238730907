import sendEmail from '../../../../../modules/Emails/functions/sendEmail'
import subscribeToEmails from '../../../../../modules/Emails/functions/subscribeToEmails'
import { updateDoc } from '../../../../../utilities/db/updateDoc'

const sendEmailConfirmationCode = ({ uid, email, htmlEmail, code }) =>
  Promise.all([
    updateDoc({ path: 'users', docId: uid, data: { confirmationCode: code } }),
    subscribeToEmails({ email, listId: 1 }).then(() =>
      sendEmail({ email, subject: 'Код подтверждения E-mail', body: htmlEmail, listId: 1 }),
    ),
  ])

export default sendEmailConfirmationCode
