import React, { useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { connect } from 'react-redux'
import Button from '../../../../ui/Button/Button'

import { db } from '../../../../config/firebase'
import { createRequiredField } from '../../../../utilities/forms/createFormElement'
import clearFieldValue from '../../../../utilities/forms/clearFieldValue'
import taskInputChanged from '../../../Tasks/scenes/Tasks/functions/taskInputChanged'
import getCommentObject from '../functions/getCommentObject'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function SetComment({ accountId, useLocalState, attachableData, saveEventLocaly, uid }) {
  const [input, setInput] = useState(createRequiredField('textarea', 'text', 'Введите текст комментария'))

  const submitInput = () => {
    if (!input.value.trim()) return

    const comment = getCommentObject(input.value, uid)
    comment.docId = attachableData.objectId

    if (useLocalState) {
      comment.created = {
        at: { seconds: new Date() / 1000 },
        by: uid,
      }
      comment.updated = {
        at: { seconds: new Date() / 1000 },
        by: uid,
      }
      saveEventLocaly(comment)
      setInput(clearFieldValue(input))
    } else {
      db.collection(`${rootDbPath(accountId)}/history`)
        .add(comment)
        .then(() => setInput(clearFieldValue(input)))
    }
  }

  return (
    <>
      <TextareaAutosize
        className="TaskConstructor-InputField"
        placeholder={input.config.label}
        value={input.value}
        onChange={(e) => taskInputChanged(input, setInput, e)}
      />
      <div className="Buttons-Container">
        <Button theme="solid" fill="accent" size={28} onClick={submitInput}>
          Отправить
        </Button>
        <Button theme="bounded" border="gray" size={28} color="black" onClick={() => setInput(clearFieldValue(input))}>
          Отменить
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(SetComment)
