import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import WidgetTile from '../../widgets/WidgetTile/WidgetTile'
import { Column } from '../GeneralConversions/GeneralConversions'
import getCalls from '../../../Dialer/functions/getCalls'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'

function CallsCounter({ accountId, uid, ...router }) {
  const [callsCounter, setCallsCounter] = useState(null)
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads, responsible } = resp

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      }).then((data) =>
        getCalls({
          callTypes: ['inbound', 'outbound'],
          takeMissedCalls: false,
          dateRange,
          responsible: data.responsible,
        }).then((calls) => {
          setCallsCounter({
            inbound: calls.filter((c) => c.direction === 'inbound').length,
            outbound: calls.filter((c) => c.direction === 'outbound').length,
          })
        }),
      )
    }
  }, [dateRange, resp])

  return (
    <WidgetTile title="Звонки" isLoading={!callsCounter} counterPeriod={dateRange}>
      <div className="GeneralConversion">
        <Column label="Вход." value={callsCounter && callsCounter.inbound} defaultColor />
        <Column label="Исход." value={callsCounter && callsCounter.outbound} defaultColor />
      </div>
    </WidgetTile>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(CallsCounter)
