import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { cloneDeep, find } from 'lodash'
import usePrevious from './hooks/usePrevious'
import * as actions from './store/actions'
import { db } from './config/firebase'
import Layout from './containers/Layout/Layout'
import VerifiedUserRoute from './routs/VerifiedUserRoute'
import rootDbPath from './utilities/db/rootDbPath'

function CardBox({
  user,
  account,
  authUpdateAccountData,
  authUpdateAccountUserData,
  authUpdateOfficesData,
  ...router
}) {
  const { accountId } = router.match.params
  const prevAccountId = usePrevious(accountId)

  useEffect(() => {
    if (prevAccountId !== accountId) {
      if (!accountId) {
        authUpdateAccountData({ isLoading: false, data: null })
      } else {
        db.collection('accounts')
          .doc(accountId)
          .onSnapshot((snap) => {
            if (snap.exists) {
              const accountData = { accountId: snap.id, ...snap.data() }
              authUpdateAccountData({ isLoading: false, data: accountData })
            } else {
              authUpdateAccountData({ isLoading: false, data: null })
            }
          })
      }
    }
  }, [router.match.params])

  return (
    <>
      {account.isLoading ? (
        ''
      ) : account.data ? (
        <VerifiedUserRoute path="/app" component={Layout} redirectTo="signIn" />
      ) : (
        router.history.push('/')
      )}
    </>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user, account: state.auth.account })

const mapDispatchToProps = (dispatch) => ({
  authUpdateAccountData: (payload) => dispatch(actions.authUpdateAccountData(payload)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CardBox)
