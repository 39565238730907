import React from 'react'
import './TaskAttachments.sass'

import { connect } from 'react-redux'
import Attachment from './Attachment'
import LoadingScene from './LoadingScene'

import Icon from '../../../../../../../components/UI/Icon/Icon'

function TaskAttachments({ taskId, attachments, uploadAttachment, uid, canBeEdit }) {
  return (
    <>
      {(attachments.length > 0 || uploadAttachment) && (
        <>
          <div className="Block-Title">
            <Icon name="paperclip" size="16" />
            <span>Вложения</span>
          </div>
          <div className="Task-Attachments">
            {attachments.map((attachment) => (
              <Attachment
                key={attachment.attachmentId}
                attachment={attachment}
                taskId={taskId}
                uid={uid}
                canBeEdit={canBeEdit}
              />
            ))}
            {uploadAttachment && <LoadingScene title="Обработка..." />}
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  uploadAttachment: state.tasks.uploadAttachment === ownProps.taskId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(TaskAttachments)
