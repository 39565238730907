import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import { addDoc } from '../../../../../../../utilities/db/addDoc'
import { logError } from '../../../../../../../utilities/db/logError'
import { createForm } from '../../../../../../../utilities/newforms'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import FormRender from '../../../../../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../../../../../utilities/newforms/validation/isFormValid'
import officeObject from './officeDbForm'
import getDoc from '../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'

function ManageOfficePopUp({ accountId, close, uid, ...router }) {
  const officeId = router.match.params.id

  const [form, setForm] = useState(
    createForm({
      formData: { created: getCurDateWithUser(uid), updated: getCurDateWithUser(uid) },
      formPattern: officeObject,
    }),
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (officeId) {
      getDoc({ accountId, path: 'offices', docId: officeId }).then((formData) =>
        setForm(createForm({ formData, formPattern: officeObject })),
      )
    }
  }, [officeId])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    let dbOperation

    if (officeId) {
      dbOperation = updateDoc({ accountId, path: 'offices', docId: officeId, data: values, uid })
    } else {
      dbOperation = addDoc({ accountId, path: 'offices', data: values })
    }

    dbOperation
      .then(() => close())
      .catch((error) => {
        setIsLoading(false)
        logError({ error })
      })
  }

  return (
    <PopUp
      show
      title={`${officeId ? 'Редактировать' : 'Добавить'} офис`}
      submitButtons
      onSubmit={onSubmit}
      close={close}
    >
      <div className="AddOfficePopUp">
        <FormRender sections={[{ fields: ['title'] }]} form={form} setForm={setForm} errors={showErrors} />
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, uid: state.auth.user.uid })

export default compose(withRouter, connect(mapStateToProps))(ManageOfficePopUp)
