import { find } from 'lodash'
import getFieldRenderObject from '../../../../../../../../utilities/newforms/render/getFieldRenderObject'

const convertParamConfigToFormField = (paramConfig, status, useFieldsRange = false, formData = []) => {
  const fieldObject = {
    fieldType: '',
    inputType: '',
    mask: '',
    required: status === 'required',
    options: paramConfig.options.map((o) => ({ label: o.label, value: o.label })),
  }

  switch (paramConfig.paramType) {
    case 'option':
      fieldObject.fieldType = 'select'
      break
    case 'inputText':
      fieldObject.fieldType = 'input'
      fieldObject.inputType = 'text'
      break
    case 'inputNumber':
      fieldObject.fieldType = 'input'
      fieldObject.inputType = 'number'
      break
    case 'checkbox':
      fieldObject.fieldType = 'checkbox'
      break

    default:
      fieldObject.fieldType = 'input'
      fieldObject.inputType = 'text'
      break
  }

  return !useFieldsRange || !['inputNumber', 'inputText'].includes(paramConfig.paramType)
    ? {
        [paramConfig.paramId]: {
          field: {
            fieldId: paramConfig.paramId,
            fieldType: fieldObject.fieldType,
            inputType: fieldObject.inputType,
            label: paramConfig.label,
            required: fieldObject.required,
            getOptions: fieldObject.options,
            multiOptions: useFieldsRange ? true : paramConfig.multiOptions,
            mask: fieldObject.mask,
            systemField: true,
            useDropValueButton: true,
            ...(paramConfig.multiOptions ? { value: [] } : {}),
            value: find(formData, ['paramId', paramConfig.paramId])
              ? find(formData, ['paramId', paramConfig.paramId]).value
              : '',
          },
          render: getFieldRenderObject(),
        },
      }
    : {
        [`${paramConfig.paramId}From`]: {
          field: {
            fieldId: `${paramConfig.paramId}From`,
            fieldType: fieldObject.fieldType,
            inputType: fieldObject.inputType,
            label: `${paramConfig.label}, от`,
            required: fieldObject.required,
            getOptions: fieldObject.options,
            multiOptions: useFieldsRange ? true : paramConfig.multiOptions,
            mask: fieldObject.mask,
            systemField: true,
            useDropValueButton: true,
            ...(paramConfig.multiOptions ? { value: [] } : {}),
            value: find(formData, ['paramId', paramConfig.paramId])
              ? find(formData, ['paramId', paramConfig.paramId]).value.valueFrom
              : '',
          },
          render: getFieldRenderObject({
            helperData: { useFieldsRange: true, fieldId: paramConfig.paramId, tag: 'From' },
          }),
        },
        [`${paramConfig.paramId}To`]: {
          field: {
            fieldId: `${paramConfig.paramId}To`,
            fieldType: fieldObject.fieldType,
            inputType: fieldObject.inputType,
            label: `${paramConfig.label}, до`,
            required: fieldObject.required,
            getOptions: fieldObject.options,
            multiOptions: useFieldsRange ? true : paramConfig.multiOptions,
            mask: fieldObject.mask,
            systemField: true,
            useDropValueButton: true,
            ...(paramConfig.multiOptions ? { value: [] } : {}),
            value: find(formData, ['paramId', paramConfig.paramId])
              ? find(formData, ['paramId', paramConfig.paramId]).value.valueTo
              : '',
          },
          render: getFieldRenderObject({
            helperData: { useFieldsRange: true, fieldId: paramConfig.paramId, tag: 'To' },
          }),
        },
      }
}

export default convertParamConfigToFormField
