import uniqid from 'uniqid'
import { fileToBase64 } from '../../../../../../../../utilities/files/fileToBase64'
import getFileExt from '../../../../../../../../utilities/files/getFileExt'

export function photoObject({
  file = null,
  needUpload = true,
  isAvatar = false,
  isLayout = false,
  storagePath = null,
  publicUrl = null,
}) {
  this.file = file
  this.needUpload = needUpload
  this.fileName = uniqid('photo-')
  this.fileType = file.type
  this.fileExt = getFileExt(file.name)
  this.isAvatar = isAvatar
  this.isLayout = isLayout
  this.imageUrl = fileToBase64(file).then((base) => base)
  this.storagePath = storagePath
  this.publicUrl = publicUrl
  this.uploadProgress = 0
}
