import * as FIELD_ERRORS from '../../constants/forms/fieldErrors'
import cloneDeep from 'lodash/cloneDeep'

const fieldPattern = {
  fieldType: 'input',
  config: {
    type: 'text',
    label: 'label',
    icon: '',
    mask: '',
  },
  validation: {
    required: false,
    pattern: '',
  },
  value: '',
  valid: true,
  error: '',
  touched: false,
}

const checkboxPattern = {
  fieldType: 'checkbox',
  config: {
    label: 'Оставаться в системе',
    link: {
      label: 'Забыли пароль?',
      path: '',
    },
  },
  validation: { ...fieldPattern.validation },
  value: false,
  valid: true,
  error: '',
  touched: false,
}

const selectboxPattern = {
  fieldType: 'select',
  config: {
    label: 'Оставаться в системе',
    getOptions: null,
    // options: [{ value: '', label: '' }]
  },
  validation: { ...fieldPattern.validation },
  value: '',
  valid: true,
  error: '',
  touched: false,
}

const fileHolderPattern = {
  fieldType: 'inputFile',
  config: {
    label: '',
    icon: 'ellipsis-v',
    filetypesAccept: 'image/*',
    storageRef: 'avatars',
    url: null,
    needUpload: true,
  },
  validation: { ...fieldPattern.validation },
  url: '',
  file: '',
  value: '',
  valid: true,
  error: '',
  touched: false,
}

const photoHolderPattern = {
  fieldType: 'inputPhoto',
  config: {
    icon: '',
    filetypesAccept: 'image/*',
    storageRef: '',
    url: null,
    needUpload: true,
  },
  validation: { ...fieldPattern.validation },
  url: '',
  value: '',
  valid: true,
  error: '',
  touched: false,
}

export const createField = (fieldType, inputType, label, mask, value) => {
  const field = JSON.parse(JSON.stringify(fieldPattern))

  field.fieldType = fieldType
  field.config.type = inputType
  field.config.label = label

  if (fieldType === 'phone') {
    field.country = ''
    field.initCountry = (country) => {
      field.country = country
    }
  }

  if (mask) field.config.mask = mask
  field.value = value || ''

  return field
}

export const createRequiredField = (fieldType, inputType, label, mask, validationPattern, defaultError) => {
  const field = createField(fieldType, inputType, label, mask)

  field.validation.required = true
  if (validationPattern) field.validation.pattern = validationPattern
  field.valid = false
  if (defaultError) field.error = defaultError
  else field.error = FIELD_ERRORS.EMPTY_FIELD

  return field
}

export const createFieldWithIcon = (fieldType, inputType, label, icon, mask) => {
  const field = createField(fieldType, inputType, label, mask)

  field.config.icon = icon

  return field
}

export const createRequiredFieldWithIcon = (
  fieldType,
  inputType,
  label,
  icon,
  mask,
  validationPattern,
  defaultError,
) => {
  const field = createRequiredField(fieldType, inputType, label, mask, validationPattern, defaultError)

  field.config.icon = icon

  return field
}

export const createFieldWithFunctionalIcon = (fieldType, inputType, label, icon, iconClass, iconFunc, mask) => {
  const field = createField(fieldType, inputType, label, mask)

  field.config.icon = icon
  field.config.iconClass = iconClass
  field.config.iconFunc = iconFunc

  return field
}

export const createRequiredFieldWithFunctionalIcon = (
  fieldType,
  inputType,
  label,
  icon,
  iconClass,
  iconFunc,
  mask,
  validationPattern,
  defaultError,
) => {
  const field = createRequiredField(fieldType, inputType, label, mask, validationPattern, defaultError)

  field.config.icon = icon
  field.config.iconClass = iconClass
  field.config.iconFunc = iconFunc

  return field
}

export const createCheckbox = (label, required, linkLabel, linkPath, defaultError) => {
  const field = JSON.parse(JSON.stringify(checkboxPattern))

  field.config.label = label
  field.config.link.label = linkLabel
  field.config.link.path = linkPath
  field.validation.required = required
  field.valid = !required
  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''

  return field
}

export const createSelectbox = (label, required, getOptionsFunction, isSearchable, multiOptions, defaultError) => {
  const field = cloneDeep(selectboxPattern)

  field.config.label = label
  field.config.getOptions = getOptionsFunction
  field.config.isSearchable = isSearchable
  field.config.multiOptions = multiOptions
  if (multiOptions) field.value = []
  field.validation.required = required
  field.valid = !required
  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''
  return field
}

export const createFileHolderInput = (label, required, accept, storeAt, defaultError) => {
  const field = JSON.parse(JSON.stringify(fileHolderPattern))

  field.config.label = label
  field.config.filetypesAccept = accept
  field.config.storageRef = storeAt
  field.validation.required = required
  field.valid = !required
  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''

  return field
}

export const createPhotoHolder = (icon, required, accept, storeAt, defaultError) => {
  const field = JSON.parse(JSON.stringify(photoHolderPattern))

  field.validation.required = required
  field.config.icon = icon
  field.config.filetypesAccept = accept
  field.config.storageRef = storeAt
  field.valid = !required
  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''

  return field
}
