import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import renderContact from '../../../../../../Contacts/AttachContact/renderTypes/renderContact'
import renderLead from '../../../../../../Contacts/AttachContact/renderTypes/renderLead'
import renderSale from '../../../../../../Contacts/AttachContact/renderTypes/renderSale'
import ActiveContactBlock from '../../../../../../Contacts/ActiveContactBlock/ActiveContactBlock'
import rootPath from '../../../../../../../appTree/rootPath'

function TaskInstanceCard({ accountId, contacts, leads, sales, estate, sortedContacts, sortedSales, history }) {
  const [attachableInstance, setAttachableInstance] = useState({})

  useEffect(() => {
    if (!isEmpty(contacts)) setAttachableInstance({ type: 'contact', ...contacts[0] })
    if (!isEmpty(leads)) setAttachableInstance({ type: 'lead', ...leads[0] })
    if (!isEmpty(sales)) setAttachableInstance({ type: 'sale', ...sales[0] })
    if (!isEmpty(estate)) setAttachableInstance({ type: 'estate', ...estate[0] })
  }, [contacts, leads, sales, estate])

  const openManager = () => {
    switch (attachableInstance.type) {
      case 'contact':
        return history.push(`${rootPath(accountId)}/contacts/list/${attachableInstance.contactId}/edit`)
      case 'lead':
        return history.push(`${rootPath(accountId)}/leads/list/${attachableInstance.leadId}/edit`)
      case 'sale':
        return history.push(`${rootPath(accountId)}/sales/list/${attachableInstance.saleId}/edit`)
      default:
        break
    }
  }

  let blockTitle
  let blockContent
  switch (attachableInstance.type) {
    case 'contact':
      blockTitle = 'Прикрепленный контакт'
      blockContent = (
        <ActiveContactBlock
          {...renderContact({
            sortedContacts,
            contactId: attachableInstance.contactId,
            isDetachable: false,
            openManager,
          })}
        />
      )
      break
    case 'sale':
      blockTitle = 'Прикрепленная продажа'
      blockContent = (
        <ActiveContactBlock
          {...renderSale({ sortedSales, cardId: attachableInstance.saleId, isDetachable: false, openManager })}
        />
      )
      break

    default:
      break
  }

  return !isEmpty(contacts) || !isEmpty(leads) || !isEmpty(sales) || !isEmpty(estate) ? (
    <div className="Header-Col">
      <div className="Title">{blockTitle}</div>
      <div className="Col-Body">{blockContent}</div>
    </div>
  ) : (
    ''
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  sortedContacts: state.contacts.sortedContactsObjects,
  sortedSales: state.sales.sortedSalesObjects,
})

export default compose(withRouter, connect(mapStateToProps))(TaskInstanceCard)
