import { db } from '../../config/firebase'

export const getUsersOptions = async ({ orderBy, rolesIds, employeesIds } = {}) => {
  if (!orderBy) orderBy = 'lastName'
  const options = []

  await db
    .collection('users')
    .orderBy(orderBy)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        const user = doc.data()
        if (
          !employeesIds ||
          (employeesIds.includes(doc.id) && ((rolesIds && rolesIds.includes(user.profile)) || !rolesIds))
        ) {
          options.push({
            value: doc.id,
            label: `${user.lastName} ${user.name} ${user.middleName}`,
          })
        }
      })
    })
  return options
}
