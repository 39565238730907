const { isEmpty } = require('lodash')
const rootDbPath = require('./rootDbPath')

const getCollection = ({
  db,
  accountId,
  path,
  docIdName,
  orderBy,
  whereQueries,
}) => {
  let dbPath = ''
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  let collectionRef = db.collection(dbPath)

  if (!isEmpty(whereQueries)) {
    whereQueries.forEach((q) => {
      collectionRef = collectionRef.where(q.fieldPath, q.op, q.value)
      if (q.op !== '==') {
        collectionRef = collectionRef.orderBy(q.fieldPath, q.orderDir)
      }
    })
  }

  if (orderBy) {
    collectionRef = collectionRef.orderBy(orderBy.fieldPath, orderBy.orderDir)
  }

  return collectionRef.get().then((docs) => {
    const arr = []

    docs.forEach((d) => {
      arr.push({ ...(docIdName ? { [docIdName]: d.id } : {}), ...d.data() })
    })
    return arr
  })
}

module.exports = getCollection
