import React from 'react'
import './TaskResponsible.sass'

import { connect } from 'react-redux'
import DropdownBox from '../../../../../../../../components/UI/DropdownBox/DropdownBox'
import Spinner from '../../../../../../../../components/UI/Spinner/Spinner'
import Icon from '../../../../../../../../components/UI/Icon/Icon'
import TaskNavElement from '../TaskNavElement'
import useDropdownOptions from './useDropdownOptions'
import { getContactsOptions } from '../../../../../../../../utilities/getOptions/getContactsOptions'
import useFilterObjValues from '../../../../../../../../hooks/useFilterObjValues'
import getTaskHistoryEventObject from '../../../../../../../History/functions/getTaskHistoryEventObject'
import pushEventToHistory from '../../../../../../../History/functions/pushEventToHistory'

function TaskNavContact({ accountId, icon, label, data, uid, canBeEdit }) {
  return (
    <TaskNavElement icon={icon} label={label} canBeEdit={canBeEdit}>
      <TaskContact accountId={accountId} data={data} uid={uid} />
    </TaskNavElement>
  )
}

export function TaskContact({ accountId, data, uid, returnValueCb = null, returnOptionsCb = null }) {
  const loadComponentOptions = async () => {
    const options = await getContactsOptions({ accountId })
    return options.map((o) => ({ ...o, isStandalone: true }))
  }

  const onUpdateDoneCb = (taskId, value) => {
    pushEventToHistory({
      section: 'contacts',
      objectId: value,
      eventObject: getTaskHistoryEventObject({ taskId, uid }),
    })
  }

  const { options, optionClicked, setIsBusy } = useDropdownOptions(
    accountId,
    data.taskId,
    'contacts',
    { options: data.contacts, valueField: 'contactId' },
    loadComponentOptions,
    true,
    uid,
    returnValueCb,
    returnOptionsCb,
    onUpdateDoneCb,
  )
  const [filteredOptions, setSearchFieldVal] = useFilterObjValues(options)

  return (
    <div className="TaskNav-TaskResponsible">
      <DropdownBox isSearchable pushSearchVal={setSearchFieldVal} styles={{ minWidth: '180px', right: 0 }}>
        {options.length ? (
          filteredOptions.map((option) => (
            <li
              key={option.value}
              className="DropdownBox-Element"
              onClick={() => {
                setIsBusy(true)
                optionClicked(option, returnValueCb)
              }}
            >
              <span>{option.label}</span>
              {option.active && <Icon name="check" size="10" />}
            </li>
          ))
        ) : (
          <Spinner type="popup" />
        )}
      </DropdownBox>
    </div>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

export default connect(mapStateToProps)(TaskNavContact)
