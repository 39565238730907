import { getMonth } from 'date-fns'

const isMonthEqual = (dateLeft, dateRight) => {
  const date1 = dateLeft ? new Date(dateLeft.seconds * 1000) : new Date(1970, 0, 1)
  const date2 = dateRight ? new Date(dateRight.seconds * 1000) : new Date(1970, 0, 1)

  return getMonth(date1) === getMonth(date2) && true
}

export default isMonthEqual
