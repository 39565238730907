import React, { useRef } from 'react'
export const cloneObjectWithNewProperties = (object, newProperties) => {
  return {
    ...JSON.parse(JSON.stringify(object)),
    ...newProperties,
  }
}

export const cloneObject = (object) => {
  return {
    ...JSON.parse(JSON.stringify(object)),
  }
}

export const useCallbacks = (reinit) => {
  const data = useRef({ callbacks: {}, handlers: {} })
  const callbacks = data.current.callbacks
  const handlers = data.current.handlers

  // Generate new callbacks
  reinit(callbacks)

  // Generate new handlers if necessary
  for (let callback in callbacks) {
    if (!handlers[callback]) {
      handlers[callback] = (...args) => callbacks[callback](...args)
    }
  }

  // Return existing handlers
  return handlers
}

export const isArrayEqual = (a, b) => {
  // if the other array is a falsy value, return
  if (!a || !b) return false

  // if any of them is not an Array, return
  if (!Array.isArray(a) || !Array.isArray(b)) return false

  // compare lengths - can save a lot of time
  if (a.length != b.length) return false

  for (var i = 0, l = a.length; i < l; i++) {
    // Check if we have nested arrays
    if (a[i] instanceof Array && b[i] instanceof Array) {
      // recurse into the nested arrays
      if (!isArrayEqual(a[i], b[i])) return false
    } else if (a[i] != b[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

export const objectsDifference = (object, base) => {
  function changes(object, base) {
    const transform = require('lodash/transform')
    const isEqual = require('lodash/isEqual')
    const isObject = require('lodash/isObject')
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}
