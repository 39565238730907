import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { toast } from 'react-toastify'
import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = {
  activeCall: null,
  callNotifications: [],
  isActiveCallBar: false,
  playSoundStatus: true,
  subscription: {
    expires: '',
    status: '',
    subscriptionId: '',
    isLoading: false,
  },
}

export const dialerReducer = (state = initialState, action) => {
  const stateClone = cloneDeep(state)

  switch (action.type) {
    case ACTION_TYPES.DIALER_SEND_ACTIVE_CALL_TO_REDUCER:
      return initiateActiveCall(stateClone, action)
    case ACTION_TYPES.DIALER_INITIATE_INBOUND_CALLS:
      return initiateInboundCalls(stateClone, action)
    case ACTION_TYPES.DIALER_SHOW_CALLBAR:
      return showCallBar(stateClone)
    case ACTION_TYPES.DIALER_HIDE_CALLBAR:
      return hideCallBar(stateClone)
    case ACTION_TYPES.DIALER_TOGGLE_CALLBAR:
      return toggleCallBar(stateClone)
    case ACTION_TYPES.DIALER_TOGGLE_MUTE_INBOUND_CALL_SOUND:
      return toggleMuteInboundCallSound(stateClone)
    case ACTION_TYPES.DIALER_SUBSCRIPTION_UPDATE_SUBSCRIPTION_INFO:
      return updateSubscriptionInfo(stateClone, action)
    default:
      return stateClone
  }
}

const initiateActiveCall = (stateClone, action) => {
  const { payload: activeCall } = action
  stateClone.activeCall = activeCall
  return stateClone
}

const initiateInboundCalls = (stateClone, action) => {
  const { payload: callNotifications } = action
  stateClone.callNotifications = callNotifications

  if (!stateClone.playSoundStatus && stateClone.callNotifications.length && callNotifications.length) {
    stateClone.playSoundStatus = false
  } else {
    stateClone.playSoundStatus = true
  }
  return stateClone
}

const showCallBar = (state) => {
  const stateClone = cloneDeep(state)
  return {
    ...stateClone,
    isActiveCallBar: true,
  }
}

const hideCallBar = (stateClone) => {
  return {
    ...stateClone,
    ...(stateClone.activeCall && stateClone.activeCall.isServing ? {} : { activeCall: null }),
    isActiveCallBar: false,
  }
}

const toggleCallBar = (stateClone) => {
  return {
    ...stateClone,
    ...(stateClone.activeCall && stateClone.activeCall.isServing ? {} : { activeCall: null }),
    isActiveCallBar: !stateClone.isActiveCallBar,
  }
}

const toggleMuteInboundCallSound = (stateClone) => {
  return {
    ...stateClone,
    playSoundStatus: !stateClone.playSoundStatus,
  }
}

const updateSubscriptionInfo = (stateClone, action) => {
  const { payload } = action

  const subscriptionState = stateClone.subscription

  for (const key in subscriptionState) {
    if (payload.hasOwnProperty(key)) {
      subscriptionState[key] = payload[key]
    }
  }

  return {
    ...stateClone,
    subscription: subscriptionState,
  }
}
