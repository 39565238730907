import getAddressObject from '../../../../../components/AddressPicker/functions/getAddressObject'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  creationType: {
    field: {
      fieldId: 'creationType',
      fieldType: 'select',
      label: 'Тип создания',
      required: false,
      getOptions: 'getDocCreationTypesOptions',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  adSource: {
    field: {
      fieldId: 'adSource',
      fieldType: 'select',
      label: 'Источник рекламы',
      required: false,
      getOptions: 'getAddOptions',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  lastName: {
    field: {
      fieldId: 'lastName',
      fieldType: 'input',
      inputType: 'text',
      required: false,
      label: 'Фамилия',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  name: {
    field: {
      fieldId: 'name',
      fieldType: 'input',
      inputType: 'text',
      required: true,
      label: 'Имя',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  middleName: {
    field: {
      fieldId: 'middleName',
      fieldType: 'input',
      inputType: 'text',
      required: false,
      label: 'Отчество',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  gender: {
    field: {
      fieldId: 'gender',
      fieldType: 'select',
      label: 'Пол',
      required: false,
      getOptions: [
        { label: 'Выбрать', value: '' },
        { label: 'Мужской', value: 'male' },
        { label: 'Женский', value: 'female' },
      ],
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  phone: {
    field: {
      fieldId: 'phone',
      fieldType: 'fieldset',
      label: 'Телефон',
      fields: [
        {
          field: {
            fieldId: 'type',
            fieldType: 'select',
            label: 'Тип номера',
            required: false,
            getOptions: 'getPhoneTypesOptions',
            value: '',
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        {
          field: {
            fieldId: 'value',
            fieldType: 'phone',
            inputType: 'text',
            required: false,
            label: 'Номер',
          },
          render: getFieldRenderObject(),
        },
      ],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  email: {
    field: {
      fieldId: 'email',
      fieldType: 'fieldset',
      label: 'E-mail',
      fields: [
        {
          field: {
            fieldId: 'type',
            fieldType: 'select',
            label: 'Тип E-mail',
            required: false,
            getOptions: 'getEmailTypesOptions',
            value: '',
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        {
          field: {
            fieldId: 'value',
            fieldType: 'input',
            inputType: 'email',
            required: false,
            label: 'E-mail',
          },
          render: getFieldRenderObject(),
        },
      ],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  responsible: {
    field: {
      fieldId: 'responsible',
      fieldType: 'select',
      label: 'Ответственный',
      value: '',
      required: true,
      getOptions: 'getMembersOptions',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  address: {
    field: {
      fieldId: 'address',
      fieldType: 'fieldset',
      label: 'Адрес',
      fields: [
        {
          field: {
            fieldId: 'type',
            fieldType: 'select',
            label: 'Тип адреса',
            required: false,
            getOptions: 'getAddressTypesOptions',
            value: '',
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        {
          field: {
            fieldId: 'value',
            fieldType: 'address',
            required: false,
            label: 'Адрес',
            value: getAddressObject(),
          },
          render: getFieldRenderObject(),
        },
      ],
      required: false,
      systemField: false,
    },
    render: getFieldRenderObject(),
  },

  estate: {
    field: {
      fieldId: 'estate',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  deals: {
    field: {
      fieldId: 'deals',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  organization: {
    field: {
      fieldId: 'organization',
      value: '',
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  created: {
    field: {
      fieldId: 'created',
      value: {
        at: '',
        by: '',
      },
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: {
        at: '',
        by: '',
      },
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  isDeleted: {
    field: {
      fieldId: 'isDeleted',
      label: 'Удаленные',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  tags: {
    field: {
      fieldId: 'tags',
      value: [],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export const contactManagerForm = {
  sections: [
    {
      title: 'Контакт',
      fields: ['creationType', 'adSource', 'lastName', 'name', 'middleName', 'gender', 'responsible'],
    },
    {
      title: 'Средства связи',
      fields: ['phone', 'email'],
    },
    {
      title: 'Адреса',
      fields: ['address'],
    },
  ],
}

export default object
