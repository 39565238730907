import { find, merge } from 'lodash'
import getDoc from '../../../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../../../utilities/promiseAllValues'

const fetchMember = ({ accountId, memberObject }) =>
  promiseAllValues({
    ...memberObject,
    ...{
      avatar: memberObject.avatar.publicUrl
        ? memberObject.avatar
        : getDoc({ path: 'users', docId: memberObject.userId }).then(({ avatar }) => avatar),
      status: fetchMemberStatus(memberObject.statusId),
      office: memberObject.officeId
        ? getDoc({ accountId, path: 'offices', docId: memberObject.officeId }).then(({ title }) => title)
        : '',
      squad: memberObject.officeId
        ? getDoc({ accountId, path: 'offices', docId: memberObject.officeId }).then((data) => {
            const squad = find(data.squads, ['squadId', memberObject.squadId])
            if (squad) return squad.title
            return ''
          })
        : '',
      role: getDoc({ accountId, path: 'roles', docId: memberObject.roleId }).then((result) =>
        memberObject.roleId === 'root' ? 'Главный администратор' : result.title,
      ),
    },
  })

const fetchMemberStatus = (statusId) => {
  switch (statusId) {
    case 'invited':
      return 'Приглашен'
    case 'active':
      return 'Активен'
    case 'blocked':
      return 'Заблокирован'
    case 'declined':
      return 'Приглашение отклонено'
    default:
      return statusId
  }
}

export default fetchMember
