import React from 'react'
import EventWithIconTemplate from './EventWithIconTemplate'

export default function renderComment(text) {
  const eventContent = EventWithIconTemplate({
    icon: 'comments-alt',
    iconTheme: 'basic',
    content: text,
  })
  return <div className="EventContent EventContent_theme_white">{eventContent}</div>
}
