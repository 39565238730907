import React, { useState, useEffect } from 'react'
import './Dialer.sass'

import { connect } from 'react-redux'
import { Slide, toast, ToastContainer } from 'react-toastify'
import Sound from 'react-sound'
import CallResultsPopUp from './CallResultsPopUp/CallResultsPopUp'
import CallBar from './CallBar/CallBar'
import SoundEffect from './assets/calling.mp3'
import usePrevious from '../../hooks/usePrevious'
import { handleInboundCallsNotifications } from './functions/handleInboundCallsNotifications'
import SubscriptionEvent from './SubscriptionEvent/SubscriptionEvent'
import renderEvent, { dismissEvent } from '../../utilities/renderEvent'

function Dialer({ subscriptionStatus, callBarStatus, activeCall, callNotifications, playSoundStatus }) {
  const componentId = 'callbar'
  const newCallBar = <CallBar {...activeCall} />

  const [showCallResultsPopUp, setShowCallResultsPopUp] = useState(false)

  useEffect(() => {
    toggleCallBar()
  }, [callBarStatus])

  useEffect(() => {
    if (subscriptionStatus) {
      const subscriptionEvent = <SubscriptionEvent status={subscriptionStatus} />
      const options = subscriptionStatus === 'active' ? { autoClose: 5000 } : {}
      renderEvent('sideEvents', 'subscription', subscriptionEvent, { ...options })
    }
  }, [subscriptionStatus])

  const prevActiveCall = usePrevious(activeCall)
  useEffect(() => {
    if (prevActiveCall && activeCall && prevActiveCall.currentState !== activeCall.currentState) {
      if (
        activeCall &&
        activeCall.currentState === 'released' &&
        activeCall.hasOwnProperty('isMissed') &&
        activeCall.isMissed === false
      ) {
        // setShowCallResultsPopUp(true)
      }
    }
  }, [activeCall])

  const prevCallNotifications = usePrevious(callNotifications)
  useEffect(() => {
    handleInboundCallsNotifications(prevCallNotifications, callNotifications)
  }, [callNotifications])

  const toggleCallBar = () => {
    if (callBarStatus) {
      renderEvent('dialerContainer', componentId, newCallBar)
    } else {
      dismissEvent(componentId)
    }
  }

  return (
    <>
      {showCallResultsPopUp && (
        <CallResultsPopUp
          contactId={activeCall.contactId}
          historyEventId={activeCall.historyEventId}
          onClose={() => setShowCallResultsPopUp(false)}
        />
      )}
      {playSoundStatus && callNotifications.length ? (
        <Sound url={SoundEffect} playStatus={Sound.status.PLAYING} loop />
      ) : (
        ''
      )}
      <ToastContainer
        enableMultiContainer
        containerId="dialerContainer"
        position={toast.POSITION.BOTTOM_CENTER}
        autoClose={false}
        closeButton={false}
        closeOnClick={false}
        className="Dialer-Container"
        toastClassName="Dialer-Bar"
        draggable={false}
        transition={Slide}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeCall: state.dialer.activeCall,
  callNotifications: state.dialer.callNotifications,
  callBarStatus: state.dialer.isActiveCallBar,
  playSoundStatus: state.dialer.playSoundStatus,
  subscriptionStatus: state.dialer.subscription.status,
})

export default connect(mapStateToProps)(Dialer)
