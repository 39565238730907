import { db } from '../../config/firebase'
import rootDbPath from './rootDbPath'

const searchDoc = ({ accountId, path, field, op, value, docIdName = null }) => {
  let dbPath = ''
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  return db
    .collection(dbPath)
    .where(field, op, value)
    .get()
    .then((docs) => {
      const results = []

      docs.forEach((d) =>
        results.push({
          ...(docIdName ? { [docIdName]: d.id } : {}),
          ...d.data(),
        }),
      )
      return results
    })
}

export default searchDoc
