import { cloneDeep } from 'lodash'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import fetchEstateContacts from './fetchEstateContacts'
import fetchEstateCategory from './fetchEstateCategory'
import fetchEstateType from './fetchEstateType'
import fetchEstateOperation from './fetchEstateOperation'
import fetchEstateStatus from './fetchEstateStatus'
import fetchMemberByUserId from '../../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import fetchTags from '../../../../../../components/TagCloud/fetchTags'

const fetchEstateObject = ({ accountId, doc }) => {
  const estateClone = cloneDeep(doc)
  return promiseAllValues({
    responsible: fetchMemberByUserId({ accountId, userId: estateClone.responsible }),
    contacts: fetchEstateContacts({ accountId, contacts: estateClone.contacts }),
    estateCategory: fetchEstateCategory({ accountId, estateCategoryId: estateClone.estateCategory }),
    estateType: fetchEstateType({
      accountId,
      estateTypeId: estateClone.estateType,
    }),
    operation: fetchEstateOperation(estateClone.operation),
    status: fetchEstateStatus({ accountId, statusId: estateClone.status }),
    tags: fetchTags({ accountId, tagsDataPath: 'config/estate/tags', tags: estateClone.tags }),
  }).then((fetchedEstate) => ({ ...estateClone, ...fetchedEstate }))
}

export default fetchEstateObject
