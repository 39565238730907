import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = {
  salesObjects: {},
  sortedSalesObjects: [],
  rawSalesObjects: {},
  rawMergedSalesObjects: {},
  funnels: {},
  isLoading: true,
}

export const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SALES_SEND_DATA_TO_REDUCER:
      return initSalesTableWithData(action)
    default:
      return state
  }
}

const initSalesTableWithData = (action) => {
  const { salesObjects, sortedSalesObjects, rawSalesObjects, rawMergedSalesObjects, funnels } = action.payload
  return {
    salesObjects,
    sortedSalesObjects,
    rawSalesObjects,
    rawMergedSalesObjects,
    funnels,
  }
}
