import React from 'react'
import './TaskTags.sass'

function TaskTags({ tags }) {
  return (
    <div className="Task-Tags">
      {tags.map((tag) => (
        <TaskTag tag={tag} />
      ))}
    </div>
  )
}

function TaskTag({ tag }) {
  return (
    <span
      className="Tag"
      style={{
        backgroundColor: `${tag.styles.tagBg}`,
        color: `${tag.styles.tagText}`,
        borderLeft: `2px ${tag.styles.tagBorder} solid`,
      }}
    >
      {tag.label}
    </span>
  )
}

export default TaskTags
