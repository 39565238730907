import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { orderBy, isArray } from 'lodash'
import { compose } from 'redux'
import NumberFormat from 'react-number-format'
import { db } from '../../../../config/firebase'
import Pagination from '../../../../components/UI/Pagination/PaginationNew'
import './Estatelist.sass'
import SceneContent from '../../../../components/UI/SceneContent/SceneContent'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import ModuleWithSubmenu from '../../../Module/ModuleWithSubmenu'
import SceneTable from '../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../utilities/date/dateFormat'
import Skeleton from '../../../../components/UI/Skeleton/Skeleton'
import * as actions from '../../store/actions/estateActions'
import { EstateAvatar } from './EstateManager/components/EstateImage/EstateImage'
import definePriceForSpaceQuantity from './functions/definePriceForSpaceQuantity'
import { Counter } from '../../../Analytics/components/InBaseCounter/InBaseCounter'
import ActiveContactBlock from '../../../Contacts/ActiveContactBlock/ActiveContactBlock'
import DeleteEstatePopUp from './DeleteEstatePopUp'
import rootPath from '../../../../appTree/rootPath'
import rootDbPath from '../../../../utilities/db/rootDbPath'
import getEstateAvailableDays from './EstateManager/functions/getEstateAvailableDays'
import useDocsList from '../../../../utilities/db/list/useDocsList'
import filterEstate from './functions/filterEstate'
import fetchEstateObject from './functions/fetch/fetchEstateObject'
import TableContact from '../../../Contacts/TableContact/TableContact'
import EstateFilters from './EstateFilters'
import { getPermissionByResponsible } from '../../../Module/hooks/useModulePermissions'
import TableTagCloud from '../../../../components/TagCloud/TableTagCloud'

function Estatelist({
  accountId,
  accountUser,
  onMount,
  subsections,
  permissions,
  estateWasAdded,
  estateWasModified,
  estateWasRemoved,
  onUnmount,
  uid,
  ...router
}) {
  const { page } = router.match.params
  const { displayedData, totalPages, fetchPage, isLoading, setFilterFields } = useDocsList({
    accountId,
    accountUser,
    path: `${rootDbPath(accountId)}/estate`,
    page,
    fetchFunction: fetchEstateObject,
    filterFunction: filterEstate,
    docIdName: 'estateId',
    permissions,
  })
  const [showPopUp, setShowPopUp] = useState(null)

  const [showFilters, setShowFilters] = useState(false)
  const [filterButtons, setFilterButtons] = useState(null)

  return (
    <ModuleWithSubmenu subsections={subsections} className="Module_with_list">
      {showPopUp && <DeleteEstatePopUp estateId={showPopUp} close={() => setShowPopUp(null)} />}
      <EstateFilters
        show={showFilters}
        close={() => setShowFilters(false)}
        pushFilterFields={setFilterFields}
        pushFilterButtons={setFilterButtons}
      />
      <SceneContent
        title="Список объектов"
        filters={filterButtons}
        buttons={[
          {
            type: 'basic',
            icon: 'filter',
            clicked: () => setShowFilters(true),
          },
          ...(permissions.add
            ? [
                {
                  type: 'add',
                  icon: 'plus',
                  clicked: () => router.history.push(`${rootPath(accountId)}/estate/list/add`),
                },
              ]
            : []),
        ]}
      >
        <SceneTable
          isLoading={isLoading}
          className="Estatelist"
          editOnDoubleClick
          header={[
            ['Дата создания', 100],
            ['Операция', 80],
            ['Объект', 300],
            ['Адрес'],
            ['Стоимость', 100],
            ['Дней в базе', 60],
            ['Контакт', 150],
            ['Ответственный'],
            // ['Выгрузка', 80],
            ['', 32],
          ]}
          useCustomOptions
          body={
            isArray(displayedData)
              ? displayedData.map((e, i) => [
                  { id: e.estateId },
                  <span>{formatDateAsDayMontYearHHMM(e.created.at)}</span>,
                  <span>{e.operation}</span>,
                  <div key={`displayed-data-${e.estateId}-${i}`} className="EstateObjectTile">
                    <EstateAvatar photos={e.photos} />
                    <div className="EstateObjectTile-Body">
                      <p className="EstateObjectTile-EstateCategory">{e.estateCategory} /</p>
                      <p className="EstateObjectTile-EstateType">{e.estateType}</p>
                      <TableTagCloud tags={e.tags} />
                    </div>
                  </div>,
                  <span>{e.address.formattedAddress || e.address.inputAddress}</span>,
                  <div key={`displayed-data-${e.estateId}-${i}`} className="RowDataColumn">
                    <p>
                      <NumberFormat value={e.price} displayType="text" thousandSeparator={' '} /> руб.
                    </p>
                    {definePriceForSpaceQuantity(e) ? (
                      <p>
                        <NumberFormat
                          value={definePriceForSpaceQuantity(e)}
                          displayType="text"
                          thousandSeparator={' '}
                        />{' '}
                        м2
                      </p>
                    ) : (
                      ''
                    )}
                  </div>,
                  <Counter
                    getAvailableDaysFunc={() => getEstateAvailableDays(accountId)}
                    accountId={accountId}
                    key={`displayed-data-${e.estateId}-${i}`}
                    size={32}
                    slice={12}
                    startDate={e.created.at.seconds * 1000}
                  />,
                  getPermissionByResponsible({
                    option: permissions.contactBlock,
                    responsible: e.responsible.userId,
                    accountUser,
                  }) ? (
                    <TableContact fetchedContacts={e.contacts} />
                  ) : (
                    <span />
                  ),
                  <UserNameWithAvatar
                    key={`displayed-data-${e.estateId}-${i}`}
                    avatarUrl={e.responsible.avatar.publicUrl}
                    avatarSize="26"
                    userName={`${e.responsible.lastName} ${e.responsible.name} ${e.responsible.middleName}`}
                  />,
                  {
                    options: [
                      {
                        return: 'docId',
                        type: 'edit',
                        icon: 'external-link',
                        label: 'Открыть',
                        clicked: (id) => router.history.push(`${rootPath(accountId)}/estate/list/${id}/edit`),
                      },
                      ...(getPermissionByResponsible({
                        option: permissions.delete,
                        responsible: e.responsible.userId,
                        accountUser,
                      })
                        ? [
                            {
                              return: 'docId',
                              type: 'delete',
                              icon: 'trash-alt',
                              label: 'Удалить',
                              clicked: (id) => setShowPopUp(id),
                            },
                          ]
                        : []),
                    ],
                  },
                ])
              : displayedData
          }
          skeleton={getLoadingSkeleton(15)}
        />
      </SceneContent>
      <Pagination totalPages={totalPages} parentPath={`${rootPath(accountId)}/estate/list`} onPageClicked={fetchPage} />
    </ModuleWithSubmenu>
  )
}

function getLoadingSkeleton(count) {
  const content = []

  for (let index = 0; index < count; index++) {
    content.push([
      <Skeleton />,
      <Skeleton />,
      <div className="EstateObjectTile">
        <Skeleton width="64px" height="64px" />
        <div className="EstateObjectTile-Body">
          <Skeleton width="100px" />
          <Skeleton width="100px" />
        </div>
      </div>,
      <Skeleton />,
      <Skeleton />,
      <Skeleton width={32} height={32} circle />,
      <div className="RowDataColumn">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>,
      <div className="Skeleton-Responsible">
        <Skeleton circle width={24} height={24} />
        <Skeleton />
      </div>,
      // <div className="RowDataColumn">
      //   <Skeleton />
      //   <Skeleton />
      // </div>,
      <Skeleton circle width={24} height={24} />,
    ])
  }

  return content
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  displayedData: state.estate.displayedData,
  totalPages: state.estate.totalPages,
  isLoading: state.estate.isLoading,
  uid: state.auth.user.uid,
})

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(actions.estateOnMount(payload)),
  fetchPage: (payload) => dispatch(actions.estateFetchPage(payload)),
  onUnmount: (payload) => dispatch(actions.estateUpdateComponentData(payload)),
  estateWasAdded: (payload) => dispatch(actions.estateWasAdded(payload)),
  estateWasModified: (payload) => dispatch(actions.estateWasModified(payload)),
  estateWasRemoved: (payload) => dispatch(actions.estateWasRemoved(payload)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Estatelist)
