import { cloneDeep, find } from 'lodash'
import { getDealFailReasons } from '../../../../../../utilities/getOptions'

const fetchDealFinishStatus = ({ accountId, finishedWith }) =>
  getDealFailReasons(accountId).then((reasons) => {
    const fetchedStatus = cloneDeep(finishedWith)
    if (finishedWith.status) {
      if (finishedWith.status === 'success') {
        fetchedStatus.status = 'Завершено с победой'
      } else {
        fetchedStatus.status = 'Завершено с проигрышем'
        const reason = find(reasons, ['value', finishedWith.reason])
        fetchedStatus.reason = reason ? reason.label : ''
      }
    }

    return fetchedStatus
  })
export default fetchDealFinishStatus
