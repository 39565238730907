import React from 'react'
import './SideScene.sass'

import Button from '../../../ui/Button/Button'
import PageTitle from '../../PageTitle/PageTitle'
import SubmitFormButtons from '../../../ui/SubmitFormButtons/SubmitFormButtons'

function SideScene({ title, children, isLoading, onSubmit, statusMessage, setStatusMessage, close }) {
  return (
    <div className="SideScene">
      <div className="SideScene-Header">
        <div className="SideScene-CloseContainer">
          <Button icon="times" theme="circle" fill="white" color="gray" onClick={close} />
        </div>
        <PageTitle>{title}</PageTitle>
      </div>
      <div className="SideScene-Body">{children}</div>
      <SubmitFormButtons
        isLoading={isLoading}
        onSubmit={onSubmit}
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        onCancel={close}
      />
    </div>
  )
}

export default SideScene
