import { cloneDeep, findIndex } from 'lodash'
import { getCurDateWithUser } from '../../../utilities/date/getCurDateWithUser'

const onStepClicked = ({ stepId, funnel, uid }) => {
  let funnelClone = cloneDeep(funnel)
  const clickedStepIndex = findIndex(funnelClone, ['stepId', stepId])
  funnelClone = funnelClone.map((step, stepIndex) => {
    if (stepIndex < clickedStepIndex) {
      switch (step.stepStatus) {
        case 'active':
          step.stepStatus = 'passed'
          step.ended = getCurDateWithUser(uid)
          break
        case 'passed':
          break
        default:
          step.stepStatus = 'skipped'
          step.started = null
          step.ended = null
          break
      }
    } else if (stepIndex === clickedStepIndex) {
      step.stepStatus = 'active'
      step.started = getCurDateWithUser(uid)
      step.ended = null
    } else {
      step.stepStatus = null
      step.started = null
      step.ended = null
    }

    return step
  })

  return funnelClone
}

export default onStepClicked
