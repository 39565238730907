function fetchSolutions(solution) {
  switch (solution) {
    case 'estate':
      return 'Недвижимость'
    case 'sales':
      return 'Продажи'

    default:
      break
  }
}

export default fetchSolutions
