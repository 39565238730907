import React from 'react'
import './DropdownMarker.sass'

import Icon from '../Icon/Icon'

const dropdownMarker = props => {
    return (
      <div className='DropdownMarker' style={props.styles}>
        <Icon name='angle-down' />
      </div>
    )
}

export default dropdownMarker
