import React from 'react'
import './TaskTags.sass'
import isEmpty from 'lodash/isEmpty'
import propTypes from 'prop-types'

import { connect } from 'react-redux'
import { getTaskTagsOptions } from '../../../../../../../../utilities/getOptions/getTaskTagsOptions'

import DropdownBox from '../../../../../../../../components/UI/DropdownBox/DropdownBox'
import Spinner from '../../../../../../../../components/UI/Spinner/Spinner'
import Icon from '../../../../../../../../components/UI/Icon/Icon'
import TaskNavElement from '../TaskNavElement'
import useDropdownOptions from './useDropdownOptions'

function TaskNavTags({ accountId, icon, label, data, canBeEdit, uid }) {
  return (
    <TaskNavElement icon={icon} label={label} canBeEdit={canBeEdit}>
      <TaskTags accountId={accountId} data={data} uid={uid} />
    </TaskNavElement>
  )
}

export function TaskTags({ accountId, data, uid, returnValueCb = null, returnOptionsCb = null }) {
  const { options, optionClicked } = useDropdownOptions(
    accountId,
    data.taskId,
    'tags',
    { options: data.tags, valueField: 'tagId' },
    () => getTaskTagsOptions(accountId),
    false,
    uid,
    returnValueCb,
    returnOptionsCb,
  )

  return (
    <div className="TaskNav-TaskTags">
      <DropdownBox styles={{ minWidth: '180px', right: 0 }}>
        {!isEmpty(options) ? (
          options.map((option) => (
            <li
              key={option.value}
              className="DropdownBox-Element"
              style={{
                backgroundColor: `${option.styles.tagBg}`,
                color: `${option.styles.tagText}`,
                borderLeft: `2px ${option.styles.tagBorder} solid`,
              }}
              onClick={() => optionClicked(option)}
            >
              <span>{option.label}</span>
              {option.active && <Icon name="check" size="10" />}
            </li>
          ))
        ) : (
          <Spinner type="popup" />
        )}
      </DropdownBox>
    </div>
  )
}

TaskNavTags.propTypes = {
  icon: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  data: propTypes.shape({
    taskId: propTypes.string.isRequired,
    tags: propTypes.array.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(TaskNavTags)
