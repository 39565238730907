import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import './SalesBlock.sass'
import BarInfoBlock from '../BarInfoBlock/BarInfoBlock'
import CallInfoPopUp from '../CallInfoPopUp/CallInfoPopUp'
import { formatDateAsDayMontYearHHMM } from '../../../../utilities/date/dateFormat'
import FunnelProgressBar from '../../../Funnel/FunnelProgressBar/FunnelProgressBar'
import { usePopUp } from '../../../../hooks/usePopUp'
import rootPath from '../../../../appTree/rootPath'

function SalesBlock({ accountId, sales, salesObjects }) {
  const [content, setContent] = useState([])
  const [activeSalesCounter, setActiveSalesCounter] = useState(0)
  const buttonRef = useRef()
  const popUpRef = useRef()
  const [showPopUp, setShowPop] = usePopUp(buttonRef, popUpRef, content.length)

  useEffect(() => {
    if (sales.length && !isEmpty(salesObjects)) {
      fetchLeadData()
    }
  }, [sales, salesObjects])

  const fetchLeadData = () => {
    const salesWithData = sales.map((sale) => salesObjects[sale])
    const fetchedSales = salesWithData.map((sale) => (
      <Link to={`${rootPath(accountId)}/sales/list/${sale.saleId}/edit`}>
        <div className="CallInfoPopUp-Item">
          <p>
            <span className="CallInfoPopUp-WhiteContent">Сделка</span> название
          </p>
          <p>Создана: {formatDateAsDayMontYearHHMM(sale.created.at)}</p>
          <p>
            <span className="CallInfoPopUp-WhiteContent">{sale.need}</span>
          </p>
          <FunnelProgressBar
            status={find(sale.funnel, ['status', 'active']).label}
            curStep={findIndex(sale.funnel, ['status', 'active']) + 1}
            totalSteps={sale.funnel.length}
            extraClass={
              'options' in find(sale.funnel, ['status', 'active'])
                ? find(find(sale.funnel, ['status', 'active']).options, [
                    'label',
                    find(sale.funnel, ['status', 'active']).label,
                  ]).class
                : ''
            }
          />
        </div>
      </Link>
    ))

    setContent(fetchedSales)
  }

  return (
    <BarInfoBlock className={['Dialer-LeadsBlock', ...(showPopUp ? ['BarInfoBlock_theme_active'] : [])].join(' ')}>
      {showPopUp && <CallInfoPopUp ref={popUpRef} title="Сделки" content={content} onClose={() => setShowPop(false)} />}
      <p ref={buttonRef} className="LeadsBlock">
        <span className={sales.length ? 'BarInfoBlock-ActiveCounter' : ''}>{activeSalesCounter}</span> / {sales.length}{' '}
        Сделок
      </p>
    </BarInfoBlock>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  salesObjects: state.sales.salesObjects,
})

export default connect(mapStateToProps)(SalesBlock)
