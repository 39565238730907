import React, { useEffect, useState } from 'react'
import { db } from '../../../../../../config/firebase'
import Icon from '../../../../../../components/UI/Icon/Icon'
import Button from '../../../../../../ui/Button/Button'
import InfoLine from '../../../../../../ui/InfoLine/InfoLine'
import './AccountTile.sass'
import fetchAccountObject from '../../functions/fetchAccountObject'
import Skeleton from '../../../../../../components/UI/Skeleton/Skeleton'
import { connect } from 'react-redux'
import { formatDateAsDayMontYearHHMM } from '../../../../../../utilities/date/dateFormat'
import ReactTooltip from 'react-tooltip'

function AccountTile({ accountId, user }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    const listener = db
      .collection('accounts')
      .doc(accountId)
      .onSnapshot((snap) => {
        const snapData = { accountId: snap.id, ...snap.data() }
        fetchAccountObject(snapData).then((fetchedAccount) => setData(fetchedAccount))
      })
    return () => {
      listener()
    }
  }, [])

  return data ? (
    <div className="Site-AccountTile">
      <ReactTooltip />
      <div className="AccountTile-Avatar">
        <Icon name="browser" weight="regular" className="Avatar-Icon" />
      </div>
      <div className="AccountTile-Data">
        <div className="Data-Header">
          <div className="AccountDetails">
            <p className="Site-LinkText">{data.title}</p>
            <InfoLine title="Отраслевое решение" content={data.solution} />
            <InfoLine
              title="Владелец"
              content={
                data.owner.userId === user.uid
                  ? 'Вы'
                  : `${data.owner.lastName} ${data.owner.name} ${data.owner.middleName}`
              }
            />
          </div>
          <Button
            tooltip="Редакт. инфо"
            theme="transparent-hug"
            icon="pencil-alt"
            iconWeight="solid"
            className="AccountTile-Button"
          />
        </div>
        <div className="Data-Details">
          <div className="Details-Counters">
            <AccountTileCounter
              title="Статус"
              data={data.isActive ? 'Активный' : 'Не активный'}
              className={data.isActive ? 'Accent' : 'Noty'}
            />
            <AccountTileCounter title="Пользователи" data={`${data.members.length}/${data.totalMembers}`} />
            <AccountTileCounter title="Лицензия до" data={formatDateAsDayMontYearHHMM(data.expired)} />
          </div>
          <Button type="navlink" path={`/app/${data.accountId}`} theme="solid" title="Войти в систему" fill="accent" />
        </div>
      </div>
    </div>
  ) : (
    <AccountTileSkeleton />
  )
}

function AccountTileCounter({ title, data, className }) {
  return (
    <div className="AccuntTile-Counter">
      <p className="Site-LinkText">{title}</p>
      <p className={['Data', ...(className ? [className] : [])].join(' ')}>{data}</p>
    </div>
  )
}

export function AccountTileSkeleton() {
  return (
    <div className="Site-AccountTile">
      <Skeleton circle width={56} height={56} />
      <div className="AccountTile-Data">
        <div className="Data-Header">
          <div className="AccountDetails">
            <Skeleton width={120} height={14} />
            <br />
            <Skeleton width={80} height={14} />
          </div>
          <Skeleton width={24} height={24} />
        </div>
        <div className="Data-Details">
          <div className="Details-Counters">
            <Skeleton width={60} height={40} style={{ marginRight: '16px' }} />
            <Skeleton width={60} height={40} style={{ marginRight: '16px' }} />
            <Skeleton width={60} height={40} />
          </div>
          <Skeleton width={120} height={32} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(AccountTile)
