import React from 'react'
import './SideNav.sass'
import withHoc from '../../utilities/withHoc'

import Item from './Item/Item'
import SmartButton from './SmartButton/SmartButton'
import getDefaultModulePath from '../../appTree/getDefaultModulePath'
import Skeleton from '../../components/UI/Skeleton/Skeleton'

function SideNav({ sideLinks }) {
  return (
    <div className="SideNav">
      <div className="Logo">
        <span />
      </div>
      <nav>
        {sideLinks ? (
          sideLinks.map((link) =>
            link.navComponent ? (
              withHoc(link.navComponent)(link)
            ) : (
              <Item
                key={link.icon}
                icon={link.icon}
                title={link.title}
                component={link.component}
                counter={link.counter}
                path={getDefaultModulePath(link)}
                activeClassPath={link.activeClassPath}
              />
            ),
          )
        ) : (
          <SideNavSkeleton />
        )}
      </nav>
      <SmartButton />
    </div>
  )
}

function SideNavSkeleton() {
  const content = [
    <div className="SideNavSkeleton">
      <Skeleton circle width={36} height={36} />
      <Skeleton className="SideNavSkeleton-Title" />
    </div>,
  ]
  return [content, content, content, content, content]
}

export default SideNav
