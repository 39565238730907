import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'
import { getOfficesSquadsOptions, getUsersOptions } from '../../../../../../../../utilities/getOptions'

const manageFormDependencies = ({
  oldForm,
  newForm,
  setNewForm,
  stateOffices,
  createGroupRulesSet,
  rules,
  setRules,
}) => {
  const motivationFormClone = cloneDeep(oldForm)
  manageOfficesAndSquadsDependencies(motivationFormClone, newForm)
  manageOfficesSquadsRolesAndUsersDependencies(motivationFormClone, newForm, stateOffices)
  manageRolesChanges(motivationFormClone, newForm, createGroupRulesSet, rules, setRules)
  setNewForm(newForm)
}

const manageRolesChanges = (oldForm, newForm, createGroupRulesSet, rules, setRules) => {
  const oldRoles = find(oldForm, ['fieldId', 'roles']).value
  const newRoles = find(newForm, ['fieldId', 'roles']).value
  if (!isEqual(oldRoles, newRoles)) {
    const elementToRemove = difference(oldRoles, newRoles)
    const newRules = rules.filter((rg) => !elementToRemove.includes(rg.groupId))
    newRoles.forEach((role) => {
      const isRuleExists = find(rules, ['groupId', role])
      if (!isRuleExists) newRules.push(createGroupRulesSet(role))
    })
    // console.log('manageRolesChanges -> newRules', newRules)
    setRules(newRules)
  }
}

const manageOfficesAndSquadsDependencies = (oldForm, newForm) => {
  const oldOffices = find(oldForm, ['fieldId', 'offices'])
  const newOffices = find(newForm, ['fieldId', 'offices'])

  if (!isEqual(oldOffices.value, newOffices.value)) {
    const squads = find(newForm, ['fieldId', 'squads'])
    if (newOffices.value.includes('all') || isEmpty(newOffices.value)) {
      squads.config.getOptions = () => getSquadsOptions()
    } else {
      squads.config.getOptions = () => getSquadsOptions(newOffices.value)
    }
    squads.value = ['all']
  }
}

const manageOfficesSquadsRolesAndUsersDependencies = (oldForm, newForm, stateOffices) => {
  const oldOffices = find(oldForm, ['fieldId', 'offices'])
  const newOffices = find(newForm, ['fieldId', 'offices'])

  const oldSquads = find(oldForm, ['fieldId', 'squads'])
  const newSquads = find(newForm, ['fieldId', 'squads'])

  const oldRoles = find(oldForm, ['fieldId', 'roles'])
  const newRoles = find(newForm, ['fieldId', 'roles'])

  const employees = find(newForm, ['fieldId', 'employees'])

  if (
    isEqual(oldOffices.value, newOffices.value) &&
    isEqual(oldSquads.value, newSquads.value) &&
    isEqual(oldRoles.value, newRoles.value)
  ) {
  } else {
    employees.value = ['all']
    let officesIds = null

    if (!isEmpty(newOffices.value) && !newOffices.value.includes('all')) {
      officesIds = newOffices.value
    }

    let rolesIds = null
    let squads = ['all']
    if (!officesIds) {
      squads = stateOffices.data.map((o) => o.squads)
    } else {
      squads = stateOffices.data.filter((o) => officesIds.includes(o.officeId)).map((o) => o.squads)
    }

    squads = flatten(squads)

    const employeesIds = uniq(flatten(squads.map((s) => s.employees.map((e) => e.id))))

    if (!isEmpty(newRoles.value) && !newRoles.value.includes('all')) {
      rolesIds = newRoles.value
    }

    employees.config.getOptions = () =>
      getUsersOptions({ rolesIds, employeesIds }).then((users) => [
        { label: 'Все сотрудники', value: 'all', standAloneOption: true },
        ...users,
      ])
  }
}

export const getSquadsOptions = (officeId) => {
  let func = () => getOfficesSquadsOptions()
  if (officeId) func = () => getOfficesSquadsOptions({ officeId })

  return func().then((squads) => [{ label: 'Все отделы', value: 'all', standAloneOption: true }, ...squads])
}

export default manageFormDependencies
