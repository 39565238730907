import React, { useState } from 'react'
import DeletePopUp from '../../../components/DeletePopUp/DeletePopUp'

const FilterPopUpDelete = ({ filter, onFilterDelete, close }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = async () => {
    setIsLoading(true)
    onFilterDelete().then(() => close())
  }

  return (
    <DeletePopUp
      title="Удаление фильтра"
      warningMessage={
        <>
          Вы уверены, что хотите удалить фильтр
          <span className="Bolder">"{filter.label}"</span>?
        </>
      }
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

export default FilterPopUpDelete
