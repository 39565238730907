import { merge } from 'lodash'
import getDoc from '../../../../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../../../Members/Memberslist/functions/fetchMemberByUserId'

const fetchOffice = ({ accountId, officeObject }) =>
  promiseAllValues(
    merge(officeObject, {
      infoBlocks: Promise.all(
        officeObject.infoBlocks.map((ib) =>
          getDoc({ accountId, path: 'config/offices/infoBlocks', docId: ib.infoBlockId }).then((fetchedInfoBlock) =>
            merge(ib, fetchedInfoBlock),
          ),
        ),
      ),
      squads: Promise.all(
        officeObject.squads.map((s) =>
          promiseAllValues({
            members: Promise.all(s.members.map((m) => fetchMemberByUserId({ accountId, userId: m }))),
            squadHead: fetchMemberByUserId({ accountId, userId: s.squadHead }),
          }).then((results) => merge(s, { members: results.members, squadHead: results.squadHead })),
        ),
      ),
    }),
  )

export default fetchOffice
