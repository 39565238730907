import { combineReducers } from 'redux'
import authReducer from './authReducer'
import tasksReducer from '../../modules/Tasks/store/reducers/tasksReducer'
import { dialerReducer } from '../../modules/Dialer/store/reducers/dialerReducer'
import { notificationsReducer } from '../../containers/Header/NotificationsCenter/store/reducers/notificationsReducer'
import { estateReducer } from '../../modules/Estate/store/reducers/estateReducer'
import { contactsReducer } from '../../modules/Contacts/store/reducers/contactsReducer'
import { salesReducer } from '../../modules/Deals/store/reducers/salesReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  dialer: dialerReducer,
  tasks: tasksReducer,
  notifications: notificationsReducer,
  estate: estateReducer,
  contacts: contactsReducer,
  sales: salesReducer,
})

export default rootReducer
