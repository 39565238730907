import getDeals from './getDeals'

const countFactDeals = ({ accountId, deals = null, responsible }) => {
  if (deals) return countDeals(deals)
  return getDeals({ accountId, responsible }).then((data) => countDeals(data))
}

const countDeals = (deals) => deals.filter((d) => !d.finishedWith.status).length

export default countFactDeals
