import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import DeletePopUp from '../../../../../../../components/DeletePopUp/DeletePopUp'
import getDoc from '../../../../../../../utilities/db/getDoc'
import { deleteDoc } from '../../../../../../../utilities/db/deleteDoc'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'

const DeleteCategoryManager = ({ accountId, categoryId, extensionId, uid, close }) => {
  const [catData, setCatData] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteCat({ accountId, extensionId, categoryId, uid }).then(() => {
      close()
    })
  }

  useEffect(() => {
    getDoc({ accountId, path: `extensions/${extensionId}/categories`, docId: categoryId }).then((cat) =>
      setCatData(cat),
    )
  }, [])

  return (
    <DeletePopUp
      title="Удаление категории"
      warningMessage={
        <>
          Вы уверены, что хотите удалить категорию <span className="Bolder">"{catData.title}"</span>?
        </>
      }
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const deleteCat = ({ accountId, extensionId, categoryId }) =>
  deleteDoc(`${rootDbPath(accountId)}/extensions/${extensionId}/categories/${categoryId}`)

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DeleteCategoryManager)
