import { cloneDeep, find, findIndex } from 'lodash'
import { storageRef } from '../../../../../../../../config/firebase'
import { getCurDateWithUser } from '../../../../../../../../utilities/date/getCurDateWithUser'
import getDoc from '../../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'

const onAvatarRemove = ({ accountId, form, setForm, uid }) => {
  const formClone = cloneDeep(form)
  const avatar = formClone.avatar.value

  if (avatar.storagePath) {
    storageRef
      .child(`${avatar.storagePath}/${avatar.fileName}.${avatar.fileExt}`)
      .delete()
      .then(() =>
        getDoc({ path: 'accounts', docId: accountId }).then((data) => {
          const { members } = data
          const memberIndex = findIndex(data.members, ['userId', formClone.userId.values])
          const member = find(data.members, ['userId', formClone.userId.values])
          if (member) {
            member.avatar = {
              fileName: null,
              fileExt: null,
              publicUrl: null,
              storagePath: null,
            }
            members.splice(memberIndex, 1, member)
            return updateDoc({
              path: 'accounts',
              docId: accountId,
              data: { members, updated: getCurDateWithUser(uid) },
            })
          }
        }),
      )
      .then(() => {
        formClone.avatar.value = {
          fileName: null,
          fileExt: null,
          publicUrl: null,
          storagePath: null,
        }
        setForm(formClone)
      })
      .catch((e) => console.log('🚀 ~ file: deletePhoto.js ~ line 29 ~ deletePhoto ~ e', e))
  } else {
    formClone.avatar.value = {
      fileName: null,
      fileExt: null,
      publicUrl: null,
      storagePath: null,
    }
    setForm(formClone)
  }
}

export default onAvatarRemove
