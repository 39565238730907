import React from 'react'
import { Image } from 'react-html-email'
import EmailBody from '../components/EmailBody/EmailBody'
import EmailTitle from '../components/EmailTitle'
import EmailText from '../components/EmaiText'
import EmailCode from '../components/EmailCode'

function PasswordRecoverEmail({ homePagePath = 'https://cdbx.io', code }) {
  return (
    <EmailBody
      homePagePath={homePagePath}
      emailIcon="https://storage.googleapis.com/estate-cdbx-io-public/emails/passwordRecover.png"
      homePagePath="https://cdbx.io"
    >
      <EmailTitle title="Сброс пароля" />
      <EmailText text="Для сброса пароля доступа к профилю  введите код, указаный ниже. Если процедуру сброса пароля начали не вы, пожалуйста, немедленно обратитесь в службу поддержки для защиты вашего аккаунта." />
      <EmailCode code={code} />
    </EmailBody>
  )
}

export default PasswordRecoverEmail
