import React, { useState, useEffect } from 'react'
import './CallResultsPopUp.sass'

import find from 'lodash/find'
import { db } from '../../../config/firebase'
import PopUp from '../../../components/UI/PopUp/PopUp'
import { createRequiredField } from '../../../utilities/forms/createFormElement'
import { isFormValid } from '../../../utilities/forms/validation'
import { renderFields } from '../../../utilities/forms/renderFields'
import usePrevious from '../../../hooks/usePrevious'

function CallResultsPopUp({ contactId, historyEventId, onClose }) {
  // console.log('CallResultsPopUp -> historyEventId', historyEventId)
  const [callComment, setCallComment] = useState([
    {
      fieldId: 'callComment',
      ...createRequiredField('textarea-autosize', 'text', 'Комментарий к звонку'),
    },
  ])
  const [formIsValid, setFormIsValid] = useState(false)
  const [showFieldErrors, setShowFieldErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [eventId, setEventId] = useState(historyEventId)

  useEffect(() => {
    setFormIsValid(isFormValid(callComment))
  }, [callComment])

  const prevEventId = usePrevious(eventId)
  useEffect(() => {
    if (prevEventId && historyEventId && prevEventId !== historyEventId) {
      setEventId(historyEventId)
    }
  }, [historyEventId])

  const confirmComment = async () => {
    if (!formIsValid) {
      return setShowFieldErrors(true)
    }

    setIsLoading(true)

    const comment = find(callComment, ['fieldId', 'callComment']).value

    await db
      .collection(`contacts/${contactId}/history`)
      .doc(eventId)
      .update({ 'content.comment': comment })
      .then(() => {
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })

    onClose()
  }

  return (
    <PopUp
      show
      title="Оставить комментарий к звонку?"
      className="CallResultsPopUp"
      buttons={[
        {
          type: 'active',
          label: 'Сохранить',
          clicked: () => confirmComment(),
          loading: isLoading,
        },
        {
          type: 'basic',
          label: 'Отменить',
          clicked: () => onClose(),
        },
      ]}
      close={onClose}
    >
      {renderFields(callComment, setCallComment, showFieldErrors)}
    </PopUp>
  )
}

export default CallResultsPopUp
