import { cloneDeep } from 'lodash'
import fetchTags from '../../../../../../components/TagCloud/fetchTags'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import fetchDealContact from './fetchDealContact'
import fetchDealFinishStatus from './fetchDealFinishStatus'
import fetchFunnel from './fetchFunnel'
import fetchNeed from './fetchNeed'

const fetchDealObject = ({ accountId, doc }) => {
  const dealClone = cloneDeep(doc)
  return promiseAllValues({
    contact: fetchDealContact({ accountId, contact: doc.contact }),
    funnel: fetchFunnel({ accountId, needId: doc.need, funnel: doc.funnel, doc }),
    need: fetchNeed({ accountId, docId: doc.need }),
    finishedWith: fetchDealFinishStatus({ accountId, finishedWith: doc.finishedWith }),
    tags: fetchTags({ accountId, tagsDataPath: 'config/deals/tags', tags: doc.tags }),
    responsible: fetchMemberByUserId({ accountId, userId: doc.responsible }),
  }).then((fetchedContact) => ({ ...dealClone, ...fetchedContact }))
}

export default fetchDealObject
