import { db } from '../../../config/firebase'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

const getCalls = ({ callTypes = null, takeMissedCalls = true, dateRange = null, responsible = null } = {}) => {
  return db
    .collection('calls')
    .get()
    .then((docs) => {
      const calls = []
      docs.forEach((d) => calls.push({ callId: d.id, ...d.data() }))

      return calls
        .filter((c) => {
          if (takeMissedCalls) return true
          return !c.isMissed
        })
        .filter((c) => {
          if (callTypes) return callTypes.includes(c.direction)
          return true
        })
        .filter((c) => {
          if (dateRange) {
            if (!c.created) return false
            return (
              isAfter(c.created.seconds * 1000, dateRange.startingDate) &&
              isBefore(c.created.seconds * 1000, dateRange.endingDate)
            )
          }
          return true
        })
        .filter((c) => (responsible ? responsible.includes(c.responsible) : true))
    })
}
export default getCalls
