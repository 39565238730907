import React from 'react'
import './DropdownProfile.sass'

import DropdownBox from '../../../components/UI/DropdownBox/DropdownBox'
import UserAvatar from '../../../components/UserAvatar/UserAvatar'
import Icon from '../../../components/UI/Icon/Icon'

const dropdownProfile = (props) => {
   
    const dropdownBoxStyles = {
        top: '64px',
        right: '0px',
        width: '260px',
        display: props.hidden ? 'none' : 'block'
    }

    return (
      <DropdownBox ref={props.forwardedRef} styles={dropdownBoxStyles}>
        <div className='DropdownProfile'>
          <div className='DropdownProfile-BriefLine'>
            <div className='d-flex'>
              <UserAvatar size='42' url={props.avatarUrl} />
              <div className='BriefLine-Name'>
                <p>{props.name}</p>
                <p>{props.role}</p>
              </div>
            </div>
            <div className='BriefLine-SettingsButton'>
              <Icon name='cog' weight='regular' />
            </div>
          </div>
          <ul className='DropdownProfile-Content'>
            <li className='Content-Header'>
              <Icon name='bell' weight='regular' />
              <span>Уведомления</span>
            </li>
            <li className='Content-Element'>Не беспокоить</li>
            <li className='Content-Element'>Настройка уведомлений</li>
            <li onClick={props.logout} className='Content-Header'>
              <Icon name='sign-out' weight='regular' />
              <span>Выход</span>
            </li>
          </ul>
        </div>
      </DropdownBox>
    )
}

export default dropdownProfile
