import React from 'react'
import './DeletePopUp.sass'
import PopUp from '../UI/PopUp/PopUp'
import Icon from '../UI/Icon/Icon'
import Button from '../../ui/Button/Button'
import CancelButton from '../../ui/Button/templates/CancelButton'

export default function DeletePopUp({ title, warningMessage, closePopUp, submitDelete, isLoading }) {
  return (
    <PopUp
      title={title}
      customButtons={[
        <Button
          isLoading={isLoading}
          onClick={submitDelete}
          title="Удалить"
          theme="solid"
          icon="trash-alt"
          fill="noty"
        />,
        <CancelButton onClick={closePopUp} />,
      ]}
      show
      close={closePopUp}
    >
      <div className="DeletePopUp-WarningMessage">
        <Icon name="exclamation-triangle" helperClass="DeletePopUp-WarningIcon" />
        <p>{warningMessage}</p>
      </div>
    </PopUp>
  )
}
