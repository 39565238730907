import { db } from '../../config/firebase'
import rootDbPath from './rootDbPath'

export const addDoc = ({ accountId, path, data }) => {
  let dbPath
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  return db
    .collection(dbPath)
    .add(data)
    .then((doc) => doc.id)
}
