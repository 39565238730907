import { pick } from 'lodash'
import getCollection from '../db/getCollection'

export const getEstateTypesOptions = ({ accountId, estateCategoryId }) =>
  getCollection({
    accountId,
    path: 'config/estate/types',
    orderBy: { fieldPath: 'label', orderDir: 'asc' },
    docIdName: 'value',
  }).then((types) =>
    types.filter((t) => t.categoryIds.includes(estateCategoryId)).map((t) => pick(t, ['label', 'value'])),
  )

export default getEstateTypesOptions
