import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'
import getDoc from '../../../../../../utilities/db/getDoc'
import { logError } from '../../../../../../utilities/db/logError'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'

const detachContact = ({ accountId, organizationId, contactId, uid }) =>
  Promise.all([
    updateDoc({
      accountId,
      path: 'contacts',
      docId: contactId,
      data: { organization: '', updated: getCurDateWithUser(uid) },
    }),
    getDoc({ accountId, path: 'organizations', docId: organizationId }).then((organizationData) =>
      updateDoc({
        accountId,
        path: 'organizations',
        docId: organizationId,
        data: {
          contacts: organizationData.contacts.filter((c) => c.contactId !== contactId),
          updated: getCurDateWithUser(uid),
        },
      }),
    ),
  ])
    .then(() => 'ok')
    .catch((error) => logError({ error }))

export default detachContact
