import { put } from 'redux-saga/effects'
import find from 'lodash/find'
import merge from 'lodash/merge'
import * as actions from '../actions/tasksActions'
import { store } from '../../../../index'
import fetchTask from './functions/fetchTask'
import isTaskBefore from './functions/isTaskBefore'
import getTaskSnapshot from './functions/getTaskSnapshot'
import updateColumnWithNewTaskSnapshot from './functions/updateColumnWithNewTaskSnapshot'

export default function* modifyTaskSaga({ payload: { accountId, rawTask } }) {
  const state = store.getState()
  const taskColumn = find(state.tasks.columns, ['columnId', rawTask.columnId])

  if (
    taskColumn.tasks.length < 15 ||
    isTaskBefore(rawTask, taskColumn.tasks[taskColumn.tasks.length - 1], taskColumn.orderBy)
  ) {
    const fetchedTask = yield fetchTask({ accountId, task: rawTask })
    yield put(actions.tasksUpdateTask(merge(rawTask, fetchedTask)))
  } else {
    yield put(actions.tasksRemoveTask(rawTask))
    if (!taskColumn.lastDocumentSnapshot) {
      const taskSnapshot = yield getTaskSnapshot({
        accountId,
        taskId: taskColumn.tasks[taskColumn.tasks.length - 1].taskId,
      })
      const columnConf = updateColumnWithNewTaskSnapshot(taskColumn, taskSnapshot)
      yield put(actions.tasksUpdateColumnInfo(merge({ columnId: rawTask.columnId }, columnConf)))
    }
  }
}
