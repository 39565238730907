import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../../../config/firebase'
import promiseAllValues from '../../../../../../../../utilities/promiseAllValues'

const handleRoleChange = ({ accountId, userId, prevRole, nextRole, uid }) =>
  promiseAllValues({
    updateNextRole:
      (!prevRole || prevRole !== nextRole) && nextRole !== 'root'
        ? updateDoc({
            accountId,
            path: 'roles',
            docId: nextRole,
            data: { members: firebase.firestore.FieldValue.arrayUnion(userId) },
            uid,
          })
        : null,
    updatePrevRole:
      prevRole && prevRole !== 'root' && prevRole !== nextRole
        ? updateDoc({
            accountId,
            path: 'roles',
            docId: prevRole,
            data: { members: firebase.firestore.FieldValue.arrayRemove(userId) },
            uid,
          })
        : null,
  })

export default handleRoleChange
