import React, { useState } from 'react'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import { db } from '../../../../config/firebase'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function DeleteParamPopUp({ accountId, param, close }) {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    db.collection(`${rootDbPath(accountId)}/config/estate/params`)
      .doc(param.paramId)
      .delete()
      .then(() => close())
      .catch((e) => {
        console.log('🚀 ~ file: DeleteParamPopUp.jsx ~ line 16 ~ onDelete ~ e', e)
        setIsLoading(false)
      })
  }

  return (
    <DeletePopUp
      title="Удаление параметра"
      warningMessage={
        <>
          Вы уверены, что хотите удалить параметр <span className="Bolder">"{param.label}"</span>
          ?
          <br />
          Восстановить удалённые данные будет невозможно.
        </>
      }
      fieldLabel="Чтобы удалить этот парамет, введите его идентификатор:"
      identifier={param.paramId}
      isLoading={isLoading}
      closePopUp={close}
      submitDelete={onDelete}
    />
  )
}

export default DeleteParamPopUp
