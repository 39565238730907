import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import { useCallbacks } from '../../utilities/shared'

import './UserProfile.sass'

import UserAvatar from '../../components/UserAvatar/UserAvatar'
import DropdownMarker from '../../components/UI/DropdownMarker/DropdownMarker'
import DropdownProfile from './DropdownProfile/DropdownProfile'
import signOutUser from '../../website/pages/Auth/functions/signOutUser'
import { usePopUp } from '../../hooks/usePopUp'

function UserProfile({ user }) {
  const profileAvatarRef = useRef()
  const dropdownBoxRef = useRef()
  const [showDropdown, setShowDropdown] = usePopUp(profileAvatarRef, dropdownBoxRef)

  const userAvatar = user.avatar ? user.avatar.publicUrl : null
  const dropdownMarkerStyles = {
    bottom: 0,
    right: 0,
    position: 'absolute',
  }

  return (
    <div className="UserProfile">
      <div className="UserProfile-ProfileLabel">
        Привет, <span className="ProfileLabel-UserName">{user.name}</span>
      </div>
      <div ref={profileAvatarRef}>
        <UserAvatar size="42" url={userAvatar}>
          <DropdownMarker styles={dropdownMarkerStyles} />
        </UserAvatar>
      </div>
      <DropdownProfile
        forwardedRef={dropdownBoxRef}
        avatarUrl={userAvatar}
        name={`${user.name} ${user.lastName}`}
        // role={role.profile}
        hidden={!showDropdown}
        logout={signOutUser}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(UserProfile)
