import { put } from 'redux-saga/effects'
import find from 'lodash/find'
import merge from 'lodash/merge'
import * as actions from '../actions/tasksActions'
import { store } from '../../../../index'

export default function* removeTaskSaga({ payload: { rawTask } }) {
  const state = store.getState()
  const taskColumn = find(state.tasks.columns, ['columnId', rawTask.columnId])

  if (taskColumn.lastDocumentSnapshot && taskColumn.totalTasks - 1 === taskColumn.tasks.length) {
    yield put(
      actions.tasksUpdateColumnInfo(
        merge({ columnId: rawTask.columnId }, { hasMore: false, lastDocumentSnapshot: null }),
      ),
    )
  }

  if (taskColumn && find(taskColumn.tasks, ['taskId', rawTask.taskId])) {
    yield put(actions.tasksRemoveTask(rawTask))
  } else {
    yield put(
      actions.tasksUpdateColumnInfo(merge({ columnId: rawTask.columnId }, { totalTasks: taskColumn.totalTasks - 1 })),
    )
  }
}
