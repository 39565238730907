import React, { useState, useEffect } from 'react'
import './TableFilters.sass'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import Icon from '../../components/UI/Icon/Icon'
import FiltersNav from './FiltersNav/FiltersNav'
import toggleSpoiler from '../../utilities/toggleSpoiler'
import FormRender from '../../utilities/newforms/render/FormRender'
import PopUp from '../../components/UI/PopUp/PopUp'
import Skeleton from '../../components/UI/Skeleton/Skeleton'
import Button from '../../ui/Button/Button'
import createFilterForm from './functions/createFilterForm'
import useSystemFieldsMethods from '../../utilities/newforms/useSystemFieldsMethods'
import getFormValues from '../../utilities/newforms/getFormValues'
import rootDbPath from '../../utilities/db/rootDbPath'
import { db } from '../../config/firebase'
import useFormLabels from '../../utilities/newforms/useFormLabels'

function TableFilters({
  accountId,
  show,
  close,
  lsFilterProp,
  filtersDbPath,
  systemFilters = [],
  formPattern,
  formData,
  setFormData,
  blocks,
  pushFilterButtons,
  pushFilterFields,
}) {
  const [savedFilters, setSavedFilters] = useState(null)
  const [filters, setFilters] = useState(null)
  const [form, setForm] = useState(null)
  const methods = useSystemFieldsMethods({ accountId, form })
  const filterButtons = useFormLabels({
    accountId,
    formPattern,
    formData,
  })

  useEffect(() => {
    const listener = db.collection(`${rootDbPath(accountId)}/${filtersDbPath}`).onSnapshot((snap) => {
      const docs = []
      snap.forEach((d) => docs.push({ filterId: d.id, ...d.data() }))
      setSavedFilters(systemFilters.concat(docs))
    })
    return () => {
      listener()
    }
  }, [])

  useEffect(() => {
    if (show) {
      setForm(createFilterForm({ formPattern, formData, methods }))
    } else {
      setForm(null)
    }
  }, [show])

  useEffect(() => {
    if (savedFilters) {
      const activeFilter = localStorage.getItem(lsFilterProp)
      if (activeFilter)
        setFilters(
          savedFilters.map((f) => {
            f.isActive = f.filterId === activeFilter
            return f
          }),
        )
      else setFilters(savedFilters)
    }
  }, [savedFilters, show])

  useEffect(() => {
    if (filterButtons) {
      const filterButtonsClone = cloneDeep(filterButtons)
      filterButtonsClone.forEach((b) => {
        b.onClick = () => removeFilterField(b.fieldId)
      })
      pushFilterButtons(filterButtonsClone)
    } else {
      pushFilterButtons(filterButtons)
    }
  }, [filterButtons])

  const onFilterSubmit = () => {
    const values = getFormValues({ form, omitSystemFields: true, withoutEmptyFields: true })
    applyFilter({ fields: values })
    close()
  }

  const removeFilterField = (fieldId) => {
    localStorage.removeItem(lsFilterProp)
    const newFormData = omit(formData, [fieldId])
    setFormData(newFormData)
    applyFilter({ fields: newFormData })
  }

  const applyFilter = (filter) => {
    if (filter.filterId) {
      localStorage.setItem(lsFilterProp, filter.filterId)
    }
    setFormData(filter.fields)
    pushFilterFields(filter.fields)
  }

  const dropFilter = () => {
    localStorage.removeItem(lsFilterProp)
    pushFilterFields({})
    setFormData({})
    close()
  }

  return (
    <PopUp title="Фильтрация данных" show={show} className="TableFilters-PopUp" close={close}>
      <div className="TableFilters">
        <FiltersNav
          savedFilters={filters}
          form={form}
          filtersDbPath={filtersDbPath}
          applyFilter={(data) => {
            applyFilter(data)
            close()
          }}
        />
        <div className="Filters-Content">
          {form && blocks ? (
            <>
              {blocks.map((b, i) => {
                const spoilerClasses = ['Content-Spoiler']
                if (i === 0) spoilerClasses.push('Spoiler_status_expanded')
                return (
                  <div className={spoilerClasses.join(' ')}>
                    <div role="menu" tabIndex={i} className="Content-Header" onClick={toggleSpoiler}>
                      <h4>{b.label}</h4>
                      <Icon name="angle-down" size="12" />
                    </div>
                    <div className="Content-Body">
                      <FormRender sections={b.fields} form={form} setForm={setForm} />
                    </div>
                  </div>
                )
              })}
              <div className="Buttons-Container">
                <Button title="Применить" theme="solid" fill="accent" size={32} onClick={onFilterSubmit} />
                <Button title="Сбросить" theme="bounded" color="black" border="gray" size={32} onClick={dropFilter} />
              </div>
            </>
          ) : (
            <FiltersFieldsSkeleton />
          )}
        </div>
      </div>
    </PopUp>
  )
}

const FiltersFieldsSkeleton = () => {
  const content = []

  for (let index = 0; index < 8; index++) {
    content.push(<Skeleton height={32} />)
  }

  return (
    <>
      <div className="Content-Spoiler Spoiler_status_expanded">
        <div className="Content-Header">
          <Skeleton width={120} height={20} />
          <Skeleton width={20} height={20} circle />
        </div>
        <div className="Content-Body">{content}</div>
      </div>
      <div className="Content-Spoiler">
        <div className="Content-Header">
          <Skeleton width={120} height={20} />
          <Skeleton width={20} height={20} circle />
        </div>
      </div>
      <div className="Buttons-Container">
        <Skeleton width={120} height={32} style={{ marginRight: '16px' }} />
        <Skeleton width={120} height={32} />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(TableFilters)
