import React, { useState } from 'react'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import { db } from '../../../../config/firebase'
import { deleteDocs } from '../../../../utilities/db/deleteDocs'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function DeleteCategoryPopUp({ category, close, accountId }) {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    db.collection(`${rootDbPath(accountId)}/config/estate/params`)
      .where('categoryId', '==', category.categoryId)
      .get()
      .then((docs) => {
        const arr = []
        docs.forEach((d) => arr.push(d.id))
        return deleteDocs({ path: `${rootDbPath(accountId)}/config/estate/params`, docIds: arr }).then((result) => {
          if (result === 'success') {
            db.collection(`${rootDbPath(accountId)}/config/estate/paramsCategories`)
              .doc(category.categoryId)
              .delete()
              .then(() => close())
          } else {
            setIsLoading(false)
          }
        })
      })
  }

  return (
    <DeletePopUp
      title="Удаление категории параметров"
      warningMessage={
        <>
          Вы уверены, что хотите удалить категорию <span className="Bolder">"{category.label}"</span>
          ?
          <br />
          Все данные, как-либо связанные с "{category.label}
          ", будут удалены.
          <br />
          Восстановить удалённые данные будет невозможно.
        </>
      }
      fieldLabel="Чтобы удалить эту категорию параметров, введите ее идентификатор:"
      identifier={category.categoryId}
      isLoading={isLoading}
      closePopUp={close}
      submitDelete={onDelete}
    />
  )
}

export default DeleteCategoryPopUp
