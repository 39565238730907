import React, { useEffect, useState } from 'react'
import './DealManager.sass'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { find, isEmpty, omit } from 'lodash'
import SceneWithHistory from '../../../../../components/UI/SceneWithHistory/SceneWithHistory'
import ModuleWithSubmenu from '../../../../Module/ModuleWithSubmenu'
import getDealsManagerSubmenu from './functions/getDealsManagerSubmenu'
import leadsEvents from './events/leadsEvents'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import Funnel from '../../../../Funnel/Funnel'
import getCollection from '../../../../../utilities/db/getCollection'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import SubmitFormButtons from '../../../../../ui/SubmitFormButtons/SubmitFormButtons'
import getDoc from '../../../../../utilities/db/getDoc'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import { createForm } from '../../../../../utilities/newforms'
import useSystemFieldsMethods from '../../../../../utilities/newforms/useSystemFieldsMethods'
import mergeFunnelWithPattern from '../../../../Funnel/functions/mergeFunnelWithPattern'
import DealContacts from './components/DealContacts/DealContacts'
import submitDealForm from './functions/submitDealForm'
import rootPath from '../../../../../appTree/rootPath'
import { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'
import usePrevious from '../../../../../hooks/usePrevious'
import syncDealWithContactsAndCustomers from './functions/syncDealWithContactsAndCustomers'
import detachContactFromDeal from './functions/detachContactFromDeal'
import FinishDealButtons from './components/FinishDealButtons/FinishDealButtons'
import useModulePermissions from '../../../../Module/hooks/useModulePermissions'
import TagCloud from '../../../../../components/TagCloud/TagCloud'

function DealManager({ accountId, accountUser, permissions: rights, options = {}, closeLink, uid, ...router }) {
  console.log('🚀 ~ file: DealManager.jsx ~ line 34 ~ DealManager ~ options', options)
  const dealId = router.match.params.id

  const [moduleConfig, setModuleConfig] = useState(null)
  const [funnel, setFunnel] = useState(null)
  const [form, setForm] = useState(null)
  const methods = useSystemFieldsMethods({ accountId, form })
  const [sections, setSections] = useState([])
  const [serviceForm, setServiceForm] = useState(null)
  const serviceFormMethods = useSystemFieldsMethods({ accountId, form: serviceForm })
  const [serviceSections, setServiceSections] = useState([])
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [localHistory, setLocalHistory] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const permissions = useModulePermissions({ form, rights, accountUser })

  useEffect(() => {
    promiseAllValues({
      services: getCollection({ accountId, path: 'config/global/services', docIdName: 'serviceId' }),
      moduleConfig: getDoc({ accountId, path: 'config', docId: 'deals' }).then(({ moduleConfig }) => moduleConfig),
      formData: dealId ? getDoc({ accountId, path: 'deals', docId: dealId }) : {},
      objects: getCollection({ accountId, path: 'config/deals/objects', docIdName: 'objectId' }),
      sections: getCollection({ accountId, path: 'config/deals/forms', docIdName: 'formId' }),
      funnels: getCollection({ accountId, path: 'config/deals/funnels', docIdName: 'funnelId' }),
    }).then((results) => {
      setModuleConfig({ ...omit(results, ['formData, moduleConfig']), ...results.moduleConfig })
      const formPattern = omit(find(results.objects, ['objectId', 'dealObject']), ['objectId'])
      setForm(
        createForm({
          formData: dealId
            ? results.formData
            : { responsible: uid, need: !isEmpty(results.services) ? results.services[0].serviceId : '' },
          formPattern,
          methods,
        }),
      )
      setSections(find(results.sections, ['formId', 'dealManager']).sections)
    })
  }, [])

  const prevForm = usePrevious(form)
  useEffect(() => {
    if (
      form &&
      form.need.value &&
      moduleConfig &&
      // moduleConfig.useNeedForms &&
      (!prevForm || (prevForm && prevForm.need.value !== form.need.value))
    ) {
      const needConfig = find(moduleConfig.services, ['serviceId', form.need.value])
      if (needConfig.needFormId) {
        const needFormObject = find(moduleConfig.objects, ['objectId', needConfig.needFormId])
        const needForm = find(moduleConfig.sections, ['formId', needConfig.needFormId])
        const formPattern = omit(needFormObject, ['objectId'])
        setServiceForm(
          createForm({
            formPattern,
            formData: form.needForm.values ? form.needForm.values : {},
            methods: serviceFormMethods,
          }),
        )
        setServiceSections(needForm.sections)
      }

      if (needConfig.funnelId) {
        const funnelPattern = find(moduleConfig.funnels, ['funnelId', needConfig.funnelId])
        setFunnel(mergeFunnelWithPattern({ funnel: form.funnel.values, pattern: funnelPattern.steps, uid }))
      }
    }
  }, [form, moduleConfig])

  useEffect(() => {
    syncForm({
      propIdentifier: 'funnel',
      form,
      setForm,
      pickProps: ['stepId', 'started', 'ended', 'stepStatus'],
      newForm: funnel,
    })
  }, [funnel])

  return (
    <ModuleWithSubmenu subsections={getDealsManagerSubmenu({ dealId, modulePath: closeLink })}>
      <Funnel
        accountId={accountId}
        dealId={dealId}
        funnel={funnel}
        setFunnel={setFunnel}
        uid={uid}
        isChangable={form && !form.finishedWith.values.status}
        buttons={dealId && <FinishDealButtons dealId={dealId} form={form} setForm={setForm} />}
        permissions={permissions}
      />
      <SceneWithHistory
        className="DealManager"
        historyProps={{
          useLocalState: !dealId && true,
          pushState: setLocalHistory,
          fetchPath: `${rootDbPath(accountId)}/deals/${dealId}/history`,
          eventsTypeSet: leadsEvents,
          attachableData: {
            section: `${rootDbPath(accountId)}/deals`,
            objectId: dealId,
          },
        }}
      >
        {form && moduleConfig && (
          <DealContacts
            isDetachableContact={moduleConfig.isDetachableContact}
            useCustomer={moduleConfig.useCustomer}
            contact={form.contact.values}
            customer={form.customer.values}
            syncState={(contacts, customers) =>
              syncForm({ propIdentifier: ['contact', 'customer'], form, setForm, newForm: [contacts, customers] })
            }
            onDetach={(doc, ctForm, setCtForm) =>
              detachContactFromDeal({ accountId, dealId, ctForm, setCtForm, doc, uid })
            }
            disableEdit={permissions && permissions.edit ? form.finishedWith.values.status : true}
          />
        )}
        <div className="SceneContent DealManager-Form">
          <FormRender
            closeForm={form && form.finishedWith.values.status}
            tagCloud={
              <TagCloud
                initialTags={form && form.tags.values}
                suggestionsPath={`${rootDbPath(accountId)}/config/deals/tags`}
                syncState={(moduleState) =>
                  syncForm({ propIdentifier: 'tags', form, setForm, newForm: moduleState.map((t) => t.tagId) })
                }
                disableEdit={form && form.finishedWith.values.status ? true : permissions ? !permissions.edit : true}
                allowNewTags={options.createTag}
              />
            }
            isFormSaved={dealId}
            sections={sections}
            form={form}
            setForm={setForm}
            errors={showErrors}
            sectionsSkeleton={[6]}
            permissions={permissions}
          />
        </div>
        {serviceForm && !isEmpty(serviceSections) && (
          <div className="SceneContent DealManager-NeedForm">
            <FormRender
              closeForm={form && form.finishedWith.values.status}
              isFormSaved={dealId}
              sections={serviceSections}
              form={serviceForm}
              setForm={setServiceForm}
              errors={showErrors}
              sectionsSkeleton={[4, 4]}
              permissions={permissions}
            />
          </div>
        )}
      </SceneWithHistory>
      {form && (
        <SubmitFormButtons
          className="Module-SubmitFormButtons"
          isLoading={isLoading}
          onSubmit={() =>
            submitDealForm({
              accountId,
              dealId,
              form,
              serviceForm,
              setStatusMessage,
              setShowErrors,
              setIsLoading,
              uid,
              onSuccessFunc: (docId, values) =>
                syncDealWithContactsAndCustomers({ accountId, docId, values, uid }).then(() =>
                  router.history.push(`${rootPath(accountId)}/deals/list/${docId}/edit`),
                ),
            })
          }
          onCancel={() => router.history.push(`${rootPath(accountId)}/deals/list`)}
          disableEdit={form.finishedWith.values.status || (permissions && !permissions.edit)}
          statusMessage={statusMessage}
          setStatusMessage={setStatusMessage}
          listenLatestUpdates={{ path: 'deals', docId: dealId }}
        />
      )}
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(DealManager)
