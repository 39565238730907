import React, { useState } from 'react'
import './ParamPopUp.sass'
import { connect } from 'react-redux'
import { omit, pick } from 'lodash'
import PopUp from '../../../../components/UI/PopUp/PopUp'
import getCollection from '../../../../utilities/db/getCollection'
import { createForm } from '../../../../utilities/newforms'
import FormRender from '../../../../utilities/newforms/render/FormRender'
import { ParamPopUpForm } from './ParamPopUpForm'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import { db } from '../../../../config/firebase'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import rootDbPath from '../../../../utilities/db/rootDbPath'

function ParamPopUp({ categoryId = '', param, close, uid, accountId }) {
  const [form, setForm] = useState(
    createForm({
      formData: { ...param, categoryId },
      formPattern: new ParamPopUpForm({ uid }),
      methods: {
        getEstateParamsCategoriesOptions: () =>
          getCollection({
            path: `${rootDbPath(accountId)}/config/estate/paramsCategories`,
            docIdName: 'value',
            // orderBy: { field: 'label', dir: 'asc' },
          }).then((paramsCategories) => paramsCategories.map((c) => pick(c, ['value', 'label']))),
      },
    }),
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) return setShowErrors(true)
    setIsLoading(true)
    const formValues = getFormValues({ form })

    const dbRef = db.collection(`${rootDbPath(accountId)}/config/estate/params`)

    if (param.operation === 'edit') {
      dbRef
        .doc(param.paramId)
        .update({ ...omit(formValues, ['paramId', 'created']), updated: getCurDateWithUser(uid) })
        .then(() => close())
        .catch((e) => {
          console.log(e)
          setIsLoading(false)
        })
    } else {
      db.collection(`${rootDbPath(accountId)}/config/estate/params`)
        .where('categoryId', '==', categoryId)
        .get()
        .then((docs) => {
          const { size } = docs
          dbRef
            .add({ ...formValues, position: size })
            .then(() => close())
            .catch((e) => {
              console.log(e)
              setIsLoading(false)
            })
        })
    }
  }

  return (
    <PopUp
      title={`${param.paramId ? 'Редактировать' : 'Добавить новый'} параметр`}
      show
      submitButtons
      isLoading={isLoading}
      onSubmit={onSubmit}
      close={close}
    >
      <div className="ParamPopUp-Container">
        <FormRender
          sections={[{ fields: ['categoryId', 'label', 'paramType', 'multiOptions', 'options'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(ParamPopUp)
