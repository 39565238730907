import find from 'lodash/find'

const renderSale = ({ sortedSales, cardId, openManager, isDetachable, detachContactObject }) => {
  const sale = find(sortedSales, ['saleId', cardId])

  if (!sale) {
    return null
  }

  const buttons = [
    {
      title: 'Открыть',
      type: 'link',
      theme: 'solid',
      onClick: () => openManager('contact', sale.contact.contactId, 'edit'),
    },
  ]

  if (isDetachable) {
    buttons.push({
      title: 'Открепить',
      type: 'link',
      onClick: () => detachContactObject(),
    })
  }

  return {
    icon: 'dollar-sign',
    lead: {
      id: `SE-${sale.saleId.slice(0, 6).toUpperCase()}`,
      clicked: () => openManager('sale', sale.saleId, 'edit'),
    },
    name: `${sale.contact.lastName} ${sale.contact.name} ${sale.contact.middleName}`,
    phone: sale.contact.phone,
    buttons,
  }
}

export default renderSale
