import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import isNull from 'lodash/isNull'
import Icon from '../UI/Icon/Icon'

import './Submenu.sass'
import { isUndefined } from 'lodash'

const Submenu = ({ links }) => (
  <ul className="Submenu">
    {links.map((link) => (
      <li key={link.title} className="Submenu-Item">
        <p className="Submenu-Item-Container">
          {!isNull(link.counter) && !isUndefined(link.counter) && (
            <span className="Submenu-Item-Counter">{link.counter}</span>
          )}
          {link.access ? (
            <>
              <NavLink
                to={link.path}
                className="Submenu-Item-Link"
                activeClassName="Submenu-Item-Link_active"
                exact={!!link.exact}
                isActive={link.isActive}
              >
                {link.title}
              </NavLink>
              {link.icon && (
                <span onClick={link.icon.onClick && link.icon.onClick} className="Submenu-Item-Icon">
                  <Icon name={link.icon.name} />
                </span>
              )}
            </>
          ) : (
            <>
              <span className="Submenu-Item-Link Submenu-Item_theme_disabled">{link.title}</span>
              <span className="Submenu-Item-Icon Submenu-Item_theme_disabled">
                <Icon name="lock-alt" weight="solid" />
              </span>
            </>
          )}
        </p>
      </li>
    ))}
  </ul>
)

export default withRouter(Submenu)
