import { cloneDeep, isEmpty, omit, pick } from 'lodash'
import fetchTags from '../../../../../components/TagCloud/fetchTags'
import getDoc from '../../../../../utilities/db/getDoc'
import fetchDefaultFields from '../../../../../utilities/newforms/fetch/fetchDefaultFields'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import fetchAddressFieldset from './fetchAddressFieldset'
import fetchAdSource from './fetchAdSource'
import fetchCreationType from './fetchCreationType'
import fetchOrganization from './fetchOrganization'
import fetchPhoneFieldset from './fetchPhoneFieldset'

const fetchContactObject = ({ accountId, doc, fetchProps = [] }) => {
  const contactClone = cloneDeep(doc)

  const objectToFetch = {
    adSource: fetchAdSource({ accountId, docId: doc.adSource }),
    organization: fetchOrganization({ accountId, docId: doc.organization }),
    creationType: fetchCreationType({ accountId, docId: doc.creationType }),
    address: fetchAddressFieldset({ accountId, addresses: doc.address }),
    tags: fetchTags({ accountId, tagsDataPath: 'config/contacts/tags', tags: doc.tags }),
    phone: fetchPhoneFieldset({ accountId, phones: doc.phone }),
    responsible: fetchMemberByUserId({ accountId, userId: doc.responsible }),
    formPattern: getDoc({ accountId, path: 'config/contacts/objects', docId: 'contactObject' }),
  }

  return promiseAllValues(!isEmpty(fetchProps) ? pick(objectToFetch, fetchProps) : objectToFetch).then(
    (fetchedContact) => ({
      ...contactClone,
      ...omit(fetchedContact, ['formPattern']),
      ...fetchDefaultFields({ doc, formPattern: fetchedContact.formPattern }),
    }),
  )
}

export default fetchContactObject
