import getAddressObject from '../../../../../components/AddressPicker/functions/getAddressObject'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  status: {
    field: {
      fieldId: 'status',
      fieldType: 'select',
      label: 'Статус объекта',
      required: true,
      getOptions: [
        { label: 'В работе', value: 'inProgress' },
        { label: 'Архив', value: 'archive' },
      ],
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  operation: {
    field: {
      fieldId: 'operation',
      fieldType: 'select',
      label: 'Операция',
      required: true,
      getOptions: [
        { label: 'Продажа', value: 'sell' },
        { label: 'Аренда', value: 'rent' },
      ],
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  estateCategory: {
    field: {
      fieldId: 'estateCategory',
      fieldType: 'select',
      label: 'Категория недвижимости',
      required: true,
      getOptions: 'getEstateCategoriesOptions',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  estateType: {
    field: {
      fieldId: 'estateType',
      fieldType: 'select',
      label: 'Тип недвижимости',
      required: true,
      getOptions: 'getEstateCategoriesTypesOptions',
      emptyOptionsText: 'Выберите категорию недвижимости',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject({ dropOnFieldIdValueChanged: 'estateCategory' }),
  },
  price: {
    field: {
      fieldId: 'price',
      fieldType: 'input',
      inputType: 'text',
      required: true,
      label: 'Цена',
      mask: 'number',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  rentTerm: {
    field: {
      fieldId: 'rentTerm',
      fieldType: 'select',
      label: 'Цена за',
      required: true,
      getOptions: [
        { label: 'Час', value: 'hour' },
        { label: 'Сутки', value: 'day' },
        { label: 'Месяц', value: 'month' },
      ],
      systemField: true,
    },
    render: getFieldRenderObject({ byDefault: false, ifFieldId: 'operation', value: 'rent' }),
  },
  responsible: {
    field: {
      fieldId: 'responsible',
      fieldType: 'select',
      label: 'Ответственный',
      required: true,
      getOptions: 'getMembersOptions',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  cadastralNumber: {
    field: {
      fieldId: 'cadasctralNumber',
      fieldType: 'fieldset',
      fields: [
        {
          field: {
            fieldId: 'label',
            fieldType: 'input',
            inputType: 'text',
            required: false,
            label: 'Примечание',
          },
          render: getFieldRenderObject(),
        },
        {
          field: {
            fieldId: 'number',
            fieldType: 'input',
            inputType: 'text',
            required: false,
            label: 'Кадастровый номер',
          },
          render: getFieldRenderObject(),
        },
      ],
      required: false,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  generateDescription: {
    field: {
      fieldId: 'generateDescription',
      fieldType: 'checkbox',
      label: 'Использовать шаблон',
      systemField: true,
      value: false,
    },
    render: getFieldRenderObject({ className: 'FullWidth' }),
  },
  title: {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      required: false,
      label: 'Название объекта',
      systemField: true,
    },
    render: getFieldRenderObject({ className: 'FullWidth' }),
  },
  description: {
    field: {
      fieldId: 'description',
      fieldType: 'textarea-autosize',
      required: false,
      label: 'Описание объекта',
      systemField: true,
    },
    render: getFieldRenderObject({ className: 'FullWidth' }),
  },
  address: {
    field: {
      fieldId: 'address',
      value: getAddressObject(),
      required: true,
      requiredProp: 'formattedAddress',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  contacts: {
    field: {
      fieldId: 'contacts',
      value: [],
      required: true,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  photos: {
    field: {
      fieldId: 'photos',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  attachments: {
    field: {
      fieldId: 'attachments',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  params: {
    field: {
      fieldId: 'params',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  tags: {
    field: {
      fieldId: 'tags',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  isDeleted: {
    field: {
      fieldId: 'isDeleted',
      label: 'Удаленные',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export const estateManagerForm = {
  sections: [
    {
      title: 'Объект',
      fields: ['status', 'operation', 'estateCategory', 'estateType', 'price', 'rentTerm', 'responsible'],
    },
    {
      title: 'Кадастровый номер',
      fields: ['cadastralNumber'],
    },
  ],
}

export default object
