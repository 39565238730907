import React, { useState } from 'react'
import './RadioGroup.sass'
import Icon from '../../components/UI/Icon/Icon'
import Skeleton from '../../components/UI/Skeleton/Skeleton'

function RadioGroup({ options, name, value, onChange }) {
  const [activeOption, setActiveOption] = useState(value)

  const onRadioChanged = (e) => {
    const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value
    setActiveOption(value)
    onChange(value)
  }

  return (
    <div className="RadioGroup">
      {options.map((o) => (
        <label className="Radio">
          <div className="Radio-Tumbler">
            <input
              className="Field Field-Radio"
              type="radio"
              name={name}
              value={o.value}
              checked={activeOption === o.value}
              onChange={onRadioChanged}
            />
            <span className="Radio-Checkmark" style={{ color: o.color }}>
              <Icon name="circle" weight="solid" />
            </span>
          </div>
          {o.label && o.label}
        </label>
      ))}
    </div>
  )
}

export const RadioGroupSkeleton = () => {
  const item = (
    <label className="Radio Radio-Skeleton">
      <div className="Radio-Tumbler">
        <Skeleton circle width={16} height={16} />
      </div>
      <Skeleton width={80} />
    </label>
  )

  return <div className="RadioGroup">{[item, item, item, item, item]}</div>
}

export default RadioGroup
