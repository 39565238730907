import React from 'react'
import { NavLink } from 'react-router-dom'
import find from 'lodash/find'
import { connect } from 'react-redux'

import './Item.sass'
import Icon from '../../../components/UI/Icon/Icon'
import NotificationsMarker from '../../Header/NotificationsCenter/NotificationsMarker/NotificationsMarker'

function Item({ icon, title, path, counter, activeClassPath }) {
  return (
    <NavLink
      to={path}
      className="SideNav-Item"
      activeClassName="SideNav-Item_theme_active"
      isActive={(match, location) => location.pathname.includes(activeClassPath)}
    >
      <div className="SideNav-Item_Icon">
        <NotificationsMarker counter={counter} />
        <Icon name={icon} />
      </div>
      <div className="SideNav-Item_Title">{title}</div>
    </NavLink>
  )
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.component === 'tasks') {
    const expiredColumn = find(state.tasks.sortedColumnsWithTasks, ['columnId', 'expired'])
    if (expiredColumn) return { counter: expiredColumn.tasks.length }
    return { counter: null }
  }
}
export default connect(mapStateToProps)(Item)
