import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './Contactlist.sass'

import { isArray, isEmpty } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Pagination from '../../../../components/UI/Pagination/PaginationNew'
import SceneContent from '../../../../components/UI/SceneContent/SceneContent'
import SceneTable from '../../../../components/UI/SceneContent/SceneTable/SceneTable'
import { formatDateAsDayMontYearHHMM } from '../../../../utilities/date/dateFormat'
import UserNameWithAvatar from '../../../../components/UserNameWithAvatar/UserNameWithAvatar'
import DeleteContactPopUp from './DeleteContactPopUp'
import { clickToCall } from '../../../Dialer/functions/clickToCall'
import ModuleWithSubmenu from '../../../Module/ModuleWithSubmenu'
import rootPath from '../../../../appTree/rootPath'
import rootDbPath from '../../../../utilities/db/rootDbPath'
import fetchContactObject from './functions/fetchContactObject'
import filterContacts from './functions/filterContacts'
import useDocsList from '../../../../utilities/db/list/useDocsList'
import ContactsFilters from './ContactsFilters'
import { getPermissionByResponsible } from '../../../Module/hooks/useModulePermissions'
import TableTagCloud from '../../../../components/TagCloud/TableTagCloud'

function Contactlist({ accountId, accountUser, subsections, permissions, options, uid, ...router }) {
  const { page } = router.match.params
  const { displayedData, totalPages, fetchPage, isLoading, setFilterFields } = useDocsList({
    accountId,
    accountUser,
    path: `${rootDbPath(accountId)}/contacts`,
    page,
    fetchFunction: ({ doc }) =>
      fetchContactObject({ accountId, doc, fetchProps: ['responsible', 'formPattern', 'phone', 'tags'] }),
    filterFunction: filterContacts,
    docIdName: 'contactId',
    permissions,
  })
  const [showPopUp, setShowPopUp] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [filterButtons, setFilterButtons] = useState(null)

  return (
    <ModuleWithSubmenu subsections={subsections}>
      <ContactsFilters
        show={showFilters}
        close={() => setShowFilters(false)}
        pushFilterFields={setFilterFields}
        pushFilterButtons={setFilterButtons}
      />
      <SceneContent
        title="Список контактов"
        filters={filterButtons}
        buttons={[
          {
            type: 'basic',
            icon: 'filter',
            clicked: () => setShowFilters(true),
          },
          ...(permissions.add
            ? [
                {
                  type: 'add',
                  icon: 'plus',
                  clicked: () => router.history.push(`${rootPath(accountId)}/contacts/persons/list/add`),
                },
              ]
            : []),
        ]}
      >
        <>
          {showPopUp && (
            <DeleteContactPopUp contactId={showPopUp && showPopUp.contactId} closePopUp={() => setShowPopUp(null)} />
          )}
          <SceneTable
            editOnDoubleClick
            header={[
              ['Дата создания', 110],
              ['Контакт', 300],
              ['Организация'],
              ['Телефон', 180],
              ['Сделки', 50],
              ['Объекты', 50],
              ['Ответственный', 180],
              ['', 32],
            ]}
            skeleton={getLoadingSkeleton(15)}
            useCustomOptions
            body={
              isArray(displayedData)
                ? displayedData.map((contact, i) => [
                    { id: contact.contactId },
                    formatDateAsDayMontYearHHMM(contact.created.at),
                    <div>
                      <p>{`${contact.lastName} ${contact.name} ${contact.middleName}`}</p>
                      <TableTagCloud tags={contact.tags} style={{ marginTop: '4px' }} />
                    </div>,
                    <span>{contact.organization}</span>,
                    !isEmpty(contact.phone) && contact.phone[0].value ? (
                      <span
                        className="ActiveLink"
                        onClick={() =>
                          clickToCall({
                            phone: contact.phone[0].value,
                            name: `${contact.lastName} ${contact.name} ${contact.middleName}`,
                            responsible: uid,
                            contactId: contact.contactId,
                          })
                        }
                      >
                        {contact.phone[0].value}
                      </span>
                    ) : (
                      <span />
                    ),
                    // <span>{!isEmpty(contact.email) ? contact.email[0].value : ''}</span>,
                    <TableRowCounter counter={contact.deals.length} />,
                    <TableRowCounter counter={contact.estate.length} />,
                    <UserNameWithAvatar
                      key={`displayed-data-${contact.contactId}-${i}`}
                      avatarUrl={contact.responsible.avatar.publicUrl}
                      avatarSize="26"
                      userName={`${contact.responsible.lastName} ${contact.responsible.name} ${contact.responsible.middleName}`}
                    />,
                    {
                      options: [
                        {
                          return: 'docId',
                          type: 'edit',
                          icon: 'external-link',
                          label: 'Открыть',
                          clicked: (id) =>
                            router.history.push(`${rootPath(accountId)}/contacts/persons/list/${id}/edit`),
                        },
                        ...(getPermissionByResponsible({
                          option: permissions.delete,
                          responsible: contact.responsible.userId,
                          accountUser,
                        })
                          ? [
                              {
                                return: 'docId',
                                type: 'delete',
                                icon: 'trash-alt',
                                label: 'Удалить',
                                clicked: (id) => setShowPopUp({ contactId: id }),
                              },
                            ]
                          : []),
                      ],
                    },
                  ])
                : displayedData
            }
            isLoading={isLoading}
          />
        </>
      </SceneContent>
      <Pagination
        totalPages={totalPages}
        parentPath={`${rootPath(accountId)}/contacts/persons/list`}
        onPageClicked={(page) => fetchPage(page)}
      />
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  accountUser: state.auth.accountUser,
  uid: state.auth.user.uid,
})

export default withRouter(connect(mapStateToProps)(Contactlist))

const TableRowCounter = ({ counter }) => (
  <span className={`Contactlist-TableRow-Counter ${counter > 0 && 'Contactlist-TableRow-Counter_theme_bold'}`}>
    {counter}
  </span>
)

function getLoadingSkeleton(count) {
  const content = []

  for (let index = 0; index < count; index++) {
    content.push([
      <Skeleton />,
      <Skeleton />,
      <Skeleton />,
      <div className="RowDataColumn">
        <Skeleton />
        <Skeleton />
      </div>,
      <div className="RowDataColumn">
        <Skeleton />
        <Skeleton />
      </div>,
      <Skeleton width={16} height={16} circle />,
      <Skeleton width={16} height={16} circle />,
      <div className="Skeleton-Responsible">
        <Skeleton circle width={24} height={24} />
        <Skeleton />
      </div>,
      <Skeleton circle width={24} height={24} />,
    ])
  }

  return content
}
