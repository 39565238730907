export const getUrlQueryParams = ({ history, searchString, params }) => {
  const searchParams = new URLSearchParams(history ? history.location.search : searchString)
  const paramsObject = {}
  params.forEach((paramName) => {
    paramsObject[paramName] = convertType(searchParams.get(paramName))
  })
  return paramsObject
}

const convertType = (value) => {
  try {
    return new Function(`return ${value};`)()
  } catch (e) {
    return value
  }
}
