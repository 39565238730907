import sendEmailApiRequest from './sendEmailApiRequest'

const sendEmail = ({ email, subject, body, listId, lang = 'ru' }) => {
  const request = {
    email,
    sender_name: 'CardBox',
    sender_email: 'info@cdbx.ru',
    subject,
    body,
    list_id: listId,
    lang,
  }

  return sendEmailApiRequest({ method: 'sendEmail', request, server: true })
}

export default sendEmail
