import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Icon from '../Icon/Icon'
import './Pagination.sass'

const Pagination = ({ onPageClicked = () => null, totalPages, ...router }) => {
  const [currentPage, setCurrentPage] = useState(
    Object.prototype.hasOwnProperty.call(router.match.params, 'page') ? router.match.params.page : 1,
  )

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(router.match.params, 'page')) {
      const curPage = router.match.params.page
      setCurrentPage(curPage)
    } else {
      setCurrentPage(1)
    }
  }, [router.match.params])

  const onPageChanged = ({ selected }) => {
    router.history.push(`${router.parentPath}/page/${selected + 1}`)
    onPageClicked(selected + 1)
  }

  return totalPages ? (
    <ReactPaginate
      previousLabel={<Icon name="angle-left" size="14" />}
      nextLabel={<Icon name="angle-right" size="14" />}
      breakLabel="..."
      breakClassName="break-me"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChanged}
      containerClassName="Pagination-Container"
      subContainerClassName="pages pagination"
      activeClassName="active"
      forcePage={currentPage - 1}
    />
  ) : (
    ''
  )
}

export default withRouter(Pagination)
