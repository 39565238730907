import { pushCallEvent } from '../../Dialer/CallEvent/functions/pushCallEvent'

export const clickToCall = ({ phone, responsible, originator, name = 'Неизвестный', contactId = null }) => {
  return pushCallEvent({
    eventId: 'clickToCall',
    eventType: 'clickToCall',
    direction: 'outbound',
    responsible,
    originator,
    name,
    phone,
    contactId,
  })
}
