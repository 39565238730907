import { firebase } from '../../../../config/firebase'

const signInUser = ({ email, password }) => firebase.auth().signInWithEmailAndPassword(email, password)
// if (!isEmpty(account)) {
//   return db
//     .collection('users')
//     .where('email', '==', email)
//     .where('password', '==', password)
//     .get()
//     .then((docs) => {
//       const user = []
//       docs.forEach((d) => user.push({ userId: d.id, ...d.data() }))
//       if (!isEmpty(user)) {
//         const isAccountMember = find(account.members, ['userId', user[0].userId])
//         if (!isAccountMember) {
//           throw new CustomAuthException({ message: 'Not an account member', code: 'auth/not-a-member' })
//         } else if (isAccountMember.isBlocked) {
//           throw new CustomAuthException({ message: 'Not an active member', code: 'auth/blocked-member' })
//         }
//       }

//       return signIn(email, password)
//     })
// }
// return signIn(email, password)

// const signIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password)

export default signInUser
