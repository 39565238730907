import React from 'react'
import Icon from '../../../components/UI/Icon/Icon'

export default function renderSale() {
  const buttons = [
    {
      class: 'Accent',
      label: 'Открыть',
      clicked: '',
    },
  ]

  const openSale = () => {}

  return (
    <div className="EventContent EventContent_theme_white">
      <div className="EventIcon EventIcon_theme_sale">
        <Icon name="dollar-sign" size="12" weight="regular" />
      </div>
      <div className="Container">
        <div className="Text">Создана продажа</div>
        {/* <div className="Buttons">
          {buttons.map(button => {
            return <button className={button.class}>{button.label}</button>
          })}
        </div> */}
      </div>
    </div>
  )
}
