import { cloneDeep, find, reverse } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { db } from '../../../../../../../config/firebase'
import Button from '../../../../../../../ui/Button/Button'
import { formatDateAsDayMontYearHHMM } from '../../../../../../../utilities/date/dateFormat'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import fetchDealObject from '../../../functions/fetch/fetchDealObject'
import FinishDealWithFailPopUp from './FinishDealWithFailPopUp'

function FinishDealButtons({ dealId, form, setForm, accountId, uid }) {
  const [showReasonPopUp, setShowReasonPopUp] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dealData, setDealData] = useState(null)

  useEffect(() => {
    const listener = db
      .collection(`${rootDbPath(accountId)}/deals`)
      .doc(dealId)
      .onSnapshot((snap) => {
        fetchDealObject({ accountId, doc: snap.data() }).then((fetchedDeal) => setDealData(fetchedDeal))
      })
    return () => {
      listener()
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [dealData])

  const updateDealStatus = ({ status = null, reason = null, reasonText = null }) => {
    setIsLoading(true)

    const finishedWith = {
      status,
      date: status ? new Date() : null,
      reason,
      reasonText,
    }

    const formClone = cloneDeep(form)
    const activeStep = find(formClone.funnel.values, ['stepStatus', 'active'])
    if (activeStep) {
      if (status) {
        activeStep.ended = getCurDateWithUser(uid)
      } else {
        activeStep.ended = null
      }
    }

    setForm(formClone)

    updateDoc({
      accountId,
      path: 'deals',
      docId: dealId,
      data: { finishedWith, funnel: formClone.funnel.values },
      uid,
    }).then(() => {
      setIsLoading(false)
      const formClone = cloneDeep(form)
      formClone.finishedWith.values = finishedWith
      setForm(formClone)
    })
  }

  return (
    <>
      {showReasonPopUp && (
        <FinishDealWithFailPopUp
          dealId={dealId}
          close={() => setShowReasonPopUp(false)}
          pushStatus={updateDealStatus}
        />
      )}
      {form && form.finishedWith.values.status ? (
        <>
          {form.finishedWith.values.status === 'success' ? (
            <span
              data-tip={
                dealData &&
                dealData.finishedWith.date &&
                `
                <p>Завершено: <span>${formatDateAsDayMontYearHHMM(dealData.finishedWith.date)}</span></p>
                `
              }
              data-html
            >
              <Button title="Завершено с победой" icon="trophy-alt" fill="accent" theme="solid" size={28} />
            </span>
          ) : (
            <span
              data-tip={
                dealData &&
                dealData.finishedWith.date &&
                `
                <p>Завершено: <span>${formatDateAsDayMontYearHHMM(dealData.finishedWith.date)}</span></p>
                <p>Причина: <span>${dealData.finishedWith.reasonText || dealData.finishedWith.reason}</span></p>
                `
              }
              data-html
            >
              <Button title="Завершено с проигрышем" icon="bone-break" fill="noty" theme="solid" size={28} />
            </span>
          )}
          <Button
            title="Открыть повторно"
            onClick={() => updateDealStatus({})}
            icon="lock-open-alt"
            theme="bounded"
            border="gray"
            color="black"
            size={28}
          />
        </>
      ) : (
        <>
          <Button
            title="Завершить с победой"
            onClick={() => updateDealStatus({ status: 'success' })}
            icon="trophy-alt"
            fill="accent"
            theme="solid"
            size={28}
            isLoading={isLoading}
          />
          <Button
            title="Завершить с проигрышем"
            icon="bone-break"
            theme="bounded"
            border="noty"
            color="noty"
            onClick={() => setShowReasonPopUp(true)}
            size={28}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(FinishDealButtons)
