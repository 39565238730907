import { useState, useEffect } from 'react'
import { useCallbacks } from '../utilities/shared'

export const usePopUp = (buttonRef, popUpRef, contentLength = true, initialState = false, additionalRef) => {
  const [showPopUp, setShowPopUp] = useState(initialState)

  useEffect(() => {
    document.addEventListener('mousedown', handlers.handleClickOutside)
    return () => document.removeEventListener('mousedown', handlers.handleClickOutside)
  }, [])

  const handlers = useCallbacks((callbacks) => {
    callbacks.handleClickOutside = (event) => {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        return togglePopUp()
      }
      if (popUpRef.current && !popUpRef.current.contains(event.target) && !additionalRef) {
        setShowPopUp(false)
      }

      if (
        popUpRef.current &&
        !popUpRef.current.contains(event.target) &&
        additionalRef &&
        additionalRef.current &&
        !additionalRef.current.contains(event.target)
      ) {
        setShowPopUp(false)
      }
    }
  })

  const togglePopUp = () => {
    if (contentLength) {
      setShowPopUp(!showPopUp)
    }
  }

  return [showPopUp, setShowPopUp]
}
