import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

const formatDate = (date) => {
  return format(date, 'dd.LL.yyyy', { locale: ru })
}

export const convertToDateTime = ({ date, setTime, setStartTime, setEndTime }) => {
  const dateArray = date.split('.')

  if (setTime) {
    const time = setTime.split(':')
    return new Date(dateArray[2], dateArray[1], dateArray[0], time[0], time[1], time[2])
  }

  if (setStartTime) return new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '00', '00', '00')
  if (setEndTime) return new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '23', '59', '59')
}

export default formatDate
