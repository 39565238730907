import React, { useState } from 'react'
import PopUp from '../../../components/UI/PopUp/PopUp'
import { createForm } from '../../../utilities/newforms/createForm'
import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'
import FormRender from '../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../utilities/newforms/getFormValues'

export default function FilterPopUpAddEdit({ filter, close, onFilterSave }) {
  const [form, setForm] = useState(createForm({ formData: filter || {}, formPattern: new FilterForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })
    onFilterSave(values).then(() => close())
  }

  return (
    <PopUp
      show
      title={filter ? 'Редактировать название фильтра' : 'Сохранить фильтр'}
      close={close}
      submitButtons
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <FormRender sections={[{ fields: ['label'] }]} form={form} setForm={setForm} errors={showErrors} />
    </PopUp>
  )
}

class FilterForm {
  constructor() {
    this.label = {
      field: {
        fieldId: 'label',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название фильтра',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.isSystemFilter = {
      field: {
        fieldId: 'isSystemFilter',
        value: false,
        systemField: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        value: 0,
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}
