import React, { useEffect, useState } from 'react'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'
import EventWithIconTemplate from './EventWithIconTemplate'
import AudioPlayer from '../../AudioPlayer/AudioPlayer'
import InboundCall from '../assets/InboundCall.svg'
import OutboundCall from '../assets/OutboundCall.svg'
import MissedCall from '../assets/MissedCall.svg'
import Button from '../../../ui/Button/Button'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { db, functions } from '../../../config/firebase'
import rootDbPath from '../../../utilities/db/rootDbPath'

function RenderCall({ subtype, content, accountId }) {
  const [isLoading, setIsLoading] = useState(false)
  const { direction, phone } = content

  const [call, setCall] = useState(null)

  useEffect(() => {
    const listener = db
      .collection(`${rootDbPath(accountId)}/calls`)
      .doc(content.callId)
      .onSnapshot((snap) => {
        setCall(snap.data())
      })
    return () => listener()
  }, [])

  const getCallRecord = () => {
    setIsLoading(true)
    const func = functions.httpsCallable('extensionsOnCall')
    func({
      funcPath: 'api/functions/getRecords',
      extensionId: call.callData.extensionId,
      payload: {
        accountId,
        contactId: call.contactId,
        extensionId: call.callData.extensionId,
        extTrackingId: call.callData.extTrackingId,
        targetId: call.callData.targetId,
        callId: content.callId,
      },
    })
      .then((result) => {
        console.log('🚀 ~ file: renderCall.js ~ line 92 ~ getCallRecord ~ result', result)
      })
      .catch((e) => {
        console.log('🚀 ~ file: renderCall.js ~ line 49 ~ getCallRecord ~ e', e)
      })
      .finally(() => setIsLoading(false))
  }

  const convertCallToText = () => {
    const func = functions.httpsCallable('dbCallsOnCall')
    func({
      funcPath: 'functions/convertCallToText',
      payload: {
        accountId,
        callId: content.callId,
      },
    }).then((resp) => {
      console.log('🚀 ~ file: renderCall.js ~ line 109 ~ convertCallToText ~ resp', resp)
    })
  }

  let callType
  let customIcon
  let iconTheme

  switch (subtype) {
    case 'inboundCall':
      callType = (
        <>
          Принял <span className="Bolder">входящий</span> вызов от номера
        </>
      )
      customIcon = InboundCall
      iconTheme = 'inbound_call'
      break
    case 'outboundCall':
      callType = (
        <>
          Осуществил <span className="Bolder">исходящий</span> вызов на номер
        </>
      )
      customIcon = OutboundCall
      iconTheme = 'outbound_call'
      break
    default:
      callType =
        direction === 'inbound' ? (
          <>
            Пропустил <span className="Bolder">входящий</span> вызов от номера
          </>
        ) : (
          <>
            Осуществил исходящий <span className="Bolder">не принятый</span> вызов на номер
          </>
        )
      customIcon = MissedCall
      iconTheme = 'missed_call'
      break
  }

  const child = (
    <>
      {callType} <span className="Bolder">{phone}</span>
      {call && !isNull(call.recordLink) && call.recordLink !== 'error' && call.recordLink !== 'pending' ? (
        <AudioPlayer
          audio={{
            type: 'internalLink',
            link: call.recordLink,
          }}
          customButtons={
            <Button
              className="AudioPlayer-ActionButton RenderCall-AudioToTextButton"
              theme="transparent-hug"
              icon="align-left"
              iconWeight="solid"
              onClick={convertCallToText}
            />
          }
        />
      ) : (
        call &&
        !isNull(call.recordLink) && (
          <div className="PendingAudioFile">
            {isLoading ? (
              <Spinner type="popup" />
            ) : (
              <>
                Аудиозапись ожидается.{' '}
                <Button title="Загрузить вручную" theme="transparent-hug" onClick={getCallRecord} />
              </>
            )}
          </div>
        )
      )}
      {/* {comment && (
        <p className="CallComment">
          <span>Комментарий к звонку:</span>
          <br />
          {comment}
        </p>
      )} */}
    </>
  )

  return (
    <div className="EventContent EventContent_theme_white">
      {EventWithIconTemplate({
        customIcon,
        iconTheme,
        content: child,
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(RenderCall)
