import { put } from 'redux-saga/effects'
import axios from 'axios'
import { apiPath } from '../../../../config/apiPath'
import * as actions from '../actions/dialerActions'
import { handleContactCreationSaga } from './handleContactCreationSaga'
import { getCallBarObject } from '../../CallBar/functions/getCallBarObject'
import { getOriginator } from './utilities/getOriginator'
import { db } from '../../../../config/firebase'
import { store } from '../../../../index'

export function* dialerSendCallRequestSaga({ payload: { direction, phone, contactId, createContact, responsible } }) {
  const state = store.getState()
  const { accountId } = state.auth.account.data

  if (!contactId && createContact) {
    contactId = yield handleContactCreationSaga(phone, responsible)
  }

  const activeCall = getCallBarObject({
    direction,
    personality: 'click-to-dial',
    currentState: 'outboundInit',
    phone,
    contactId,
    responsible,
  })

  const extensionId = '08gWJH83oEYZsvfCZSaz'
  const originator = yield getOriginator({ accountId, responsible })

  yield put(actions.dialerShowCallBar())
  yield put(actions.dialerSendActiveCallToReducer(activeCall))

  try {
    const res = yield axios({
      method: 'post',
      url: `${apiPath}/apiCalls?extensionId=${extensionId}`,
      data: {
        func: 'postAbonentsCall',
        phone,
        // contactId,
        // createContact,
        // responsible,
        originator,
      },
    })
    if (res.status === 200) {
      yield saveCallDataInRegistry({ extTrackingId: res.data, contactId, phone })
      console.log('call request ok!')
    }
  } catch (err) {
    yield put(actions.dialerSendActiveCallToReducer({ ...activeCall, currentState: 'callerror' }))
  }
}

function* saveCallDataInRegistry({ extTrackingId, contactId, phone }) {
  return yield db
    .collection('api/calls/clickToDialRegistry')
    .add({ extTrackingId, contactId, phone })
    .then((docRef) => docRef.id)
}
