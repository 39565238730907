import React from 'react'
import { Route, Switch } from 'react-router-dom'
import rootPath from '../../../../appTree/rootPath'
import Feedslist from './Feedslist/Feedslist'
import FeedsManager from './FeedsManager/FeedsManager'

function Feeds({ accountId }) {
  return (
    <Switch>
      <Route path={`${rootPath(accountId)}/settings/site/feeds`} exact render={() => <Feedslist />} />
      <Route
        path={[`${rootPath(accountId)}/settings/site/feeds/add`, `${rootPath(accountId)}/settings/site/feeds/:id`]}
        exact
        render={() => <FeedsManager closeLink={`${rootPath(accountId)}/settings/site/feeds`} />}
      />
    </Switch>
  )
}

export default Feeds
