import * as SIGN_UP_ERRORS from './authErrors'

const handleSignUpErrors = ({ code }) => {
  switch (code) {
    case 'auth/email-already-in-use':
    case 'auth/email-already-exists':
    case 'auth/account-exists-with-different-credential':
      return SIGN_UP_ERRORS.ALREADY_IN_USE
    case 'auth/weak-password':
    case 'auth/invalid-password':
      return SIGN_UP_ERRORS.WEAK_PASSWORD
    case 'auth/operation-not-allowed':
      return SIGN_UP_ERRORS.OPERATION_NOT_ALLOWED
    default:
  }
}

export function CustomAuthException({ message, code }) {
  const error = new Error(message)
  error.code = code
  return error
}

export default handleSignUpErrors
