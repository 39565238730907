import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'

const userDbForm = {
  lastName: {
    field: {
      fieldId: 'lastName',
      fieldType: 'input',
      inputType: 'text',
      label: 'Фамилия',
      placeholder: 'Введите вашу фамилию',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  name: {
    field: {
      fieldId: 'name',
      fieldType: 'input',
      inputType: 'text',
      label: 'Имя',
      placeholder: 'Введите ваше имя',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  middleName: {
    field: {
      fieldId: 'middleName',
      fieldType: 'input',
      inputType: 'text',
      label: 'Отчество',
      placeholder: 'Введите ваше отчество',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  avatar: {
    field: {
      fieldId: 'avatar',
      fieldType: 'inputFile',
      inputType: 'file',
      label: 'Файл',
      icon: 'ellipsis-v',
    },
    render: getFieldRenderObject(),
  },
  bdate: {
    field: {
      fieldId: 'bdate',
      fieldType: 'input',
      inputType: 'text',
      mask: 'date',
      label: 'Дата рождения',
      placeholder: 'Введите вашу дату рождения',
    },
    render: getFieldRenderObject(),
  },
  gender: {
    field: {
      fieldId: 'gender',
      fieldType: 'select',
      label: 'Пол',
      required: false,
      placeholder: 'Укажите ваш пол',
      getOptions: [
        { label: 'Выбрать', value: '' },
        { label: 'Мужской', value: 'male' },
        { label: 'Женский', value: 'female' },
      ],
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  email: {
    field: {
      fieldId: 'email',
      icon: 'envelope',
      fieldType: 'input',
      inputType: 'email',
      validationPattern: 'email',
      placeholder: 'Введите ваш E-mail',
      label: 'E-mail',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  phone: {
    field: {
      fieldId: 'phone',
      fieldType: 'phone',
      inputType: 'text',
      icon: 'phone',
      placeholder: 'Введите ваш контактный телефон',
      label: 'Контактный телефон',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  password: {
    field: {
      fieldId: 'password',
      fieldType: 'input',
      icon: 'fingerprint',
      inputType: 'password',
      placeholder: 'Введите пароль',
      label: 'Придумайте пароль',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  emailVerified: {
    field: {
      fieldId: 'emailVerified',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  phoneVerified: {
    field: {
      fieldId: 'phoneVerified',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  accounts: {
    field: {
      fieldId: 'accounts',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  canCreateAccount: {
    field: {
      fieldId: 'canCreateAccount',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  systemRole: {
    field: {
      fieldId: 'systemRole',
      value: 'user',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  invitable: {
    field: {
      fieldId: 'invitable',
      fieldType: 'checkbox',
      label: 'Принимать приглашения в другие системы?',
      required: true,
      value: true,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  created: {
    field: {
      fieldId: 'created',
      value: new Date(),
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: new Date(),
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  signUpType: {
    field: {
      fieldId: 'signUpType',
      value: 'manual',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export default userDbForm
