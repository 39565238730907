import { isAfter, isBefore } from 'date-fns'
import { isArray, isObjectLike, isString, find } from 'lodash'

const filterRecursivly = ({ filterFields, object }) => {
  if (!filterFields.hasOwnProperty('isDeleted')) {
    filterFields.isDeleted = false
  }

  const isSuitable = [true]
  Object.entries(object).forEach(([k, v]) => {
    if (filterFields.hasOwnProperty(k)) {
      const filterValue = filterFields[k]

      if (isArray(filterValue)) {
        if (!isObjectLike(filterValue[0])) isSuitable.push(filterValue.includes(object[k]))
        else {
          filterValue.forEach((fv) => {
            Object.entries(fv).forEach(([fvK, fvV]) => {
              isSuitable.push(find(object[k], [fvK, fvV]) && true)
            })
          })
        }
      }

      if (!isObjectLike(filterValue)) {
        isSuitable.push(
          isString(filterValue)
            ? object[k].toLowerCase().includes(filterValue.toLocaleLowerCase())
            : object[k] === filterValue,
        )
      }
    }

    if (filterFields.hasOwnProperty('createdDate')) {
      const { from, to } = filterFields.createdDate
      isSuitable.push(filterByDateRange({ objectDate: object.created.at.seconds * 1000, from, to }))
    }

    if (filterFields.hasOwnProperty('updatedDate')) {
      const { from, to } = filterFields.updatedDate
      isSuitable.push(filterByDateRange({ objectDate: object.updated.at.seconds * 1000, from, to }))
    }

    if (filterFields.hasOwnProperty('createdBy')) {
      isSuitable.push(filterFields.createdBy.includes(object.created.by))
    }
    if (filterFields.hasOwnProperty('updatedBy')) {
      isSuitable.push(filterFields.updatedBy.includes(object.updated.by))
    }
  })

  return isSuitable.every((el) => el === true)
}

const filterByDateRange = ({ objectDate, from, to }) => {
  if (from && to) {
    return isBefore(objectDate, to) && isAfter(objectDate, from)
  }
  if (from) {
    return isAfter(objectDate, from)
  }
  if (to) {
    return isBefore(objectDate, to)
  }
}

export default filterRecursivly
