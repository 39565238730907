import React, { useState, useEffect } from 'react'
import './ContactPopUpManager.sass'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isEmpty } from 'lodash'
import PopUp from '../../../../../components/UI/PopUp/PopUp'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import getDoc from '../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import { createForm } from '../../../../../utilities/newforms'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import createContactForm from '../../../scenes/Contactlist/ContactsManager/functions/createContactForm'
import Tabs from '../../../../../ui/Tabs/Tabs'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import submitContactForm from '../../../scenes/Contactlist/ContactsManager/functions/submitContactForm'
import ContactsOptions from '../ContactsOptions/ContacsOptions'
import SceneContentHeader from '../../../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import ContactCard from '../../../ContactCard/ContactCard'
import Button from '../../../../../ui/Button/Button'

function ContactPopUpManager({
  superstructure,
  superstructureData,
  accountId,
  contactId,
  uid,
  pushContact,
  close,
  omitOptions = (data) => data,
}) {
  const [superstructureForm, setSuperstructureForm] = useState(null)
  const [superstructureSections, setSuperstructureSections] = useState([])
  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [contactFromDb, setContactFromDb] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useEffect(() => {
    promiseAllValues({
      ...(superstructure
        ? {
            superstructureObject: getDoc({
              accountId,
              path: superstructure.object.path,
              docId: superstructure.object.docId,
            }),
            superstructureForm: getDoc({
              accountId,
              path: superstructure.form.path,
              docId: superstructure.form.docId,
            }).then((data) => data.sections),
          }
        : {}),
      contactObject: getDoc({ accountId, path: 'config/contacts/objects', docId: 'contactObject' }),
      contactForm: getDoc({ accountId, path: 'config/contacts/forms', docId: 'contactManager' }).then(
        (data) => data.sections,
      ),
      contactData: contactId ? getDoc({ accountId, path: 'contacts', docId: contactId }) : { responsible: uid },
    }).then((results) => {
      if (superstructure) {
        setSuperstructureForm(createForm({ formData: superstructureData, formPattern: results.superstructureObject }))
        setSuperstructureSections(results.superstructureForm)
      }
      setForm(createContactForm({ accountId, formData: results.contactData, formPattern: results.contactObject }))
      setSections(results.contactForm)
    })
  }, [])

  const onSumbit = () => {
    if (activeTabIndex === 0) {
      if (!isFormValid({ form }) || (superstructure && !isFormValid({ form: superstructureForm }))) {
        return setShowErrors(true)
      }
      submitContactForm({
        accountId,
        contactId,
        form,
        setStatusMessage: () => null,
        setShowErrors,
        setIsLoading,
        uid,
        onSuccessFunc: (docId) => {
          pushContact({ contactId: docId, ...(superstructure ? getFormValues({ form: superstructureForm }) : {}) })
          close()
        },
      })
    } else {
      if ((superstructure && !isFormValid({ form: superstructureForm })) || !contactFromDb) {
        return setShowErrors(true)
      }
      pushContact({ contactId: contactFromDb, ...(superstructure ? getFormValues({ form: superstructureForm }) : {}) })
      close()
    }
  }

  return (
    <PopUp
      show
      title={`${contactId ? 'Редактировать' : 'Добавить'} контакт`}
      submitButtons
      isLoading={isLoading}
      onSubmit={onSumbit}
      close={close}
    >
      <Tabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabs={[
          {
            ...(contactId
              ? {
                  title: 'Контакт',
                  icon: 'pencil-alt',
                }
              : {
                  title: 'Новый контакт',
                  icon: 'plus',
                }),
            content: (
              <div className="ContactPopUpManager">
                {(!superstructure || (superstructure && superstructureForm && !isEmpty(superstructureSections))) &&
                form &&
                !isEmpty(sections) ? (
                  <>
                    {superstructure && (
                      <FormRender
                        sections={superstructureSections}
                        form={superstructureForm}
                        setForm={setSuperstructureForm}
                        errors={showErrors}
                      />
                    )}
                    <FormRender sections={sections} form={form} setForm={setForm} errors={showErrors} />
                  </>
                ) : (
                  <Spinner type="popup" />
                )}
              </div>
            ),
          },
          ...(!contactId
            ? [
                {
                  title: 'Существующий контакт',
                  icon: 'list-ul',
                  content: (
                    <div className="ContactPopUpManager">
                      {superstructure && (
                        <FormRender
                          sections={superstructureSections}
                          form={superstructureForm}
                          setForm={setSuperstructureForm}
                          errors={showErrors}
                        />
                      )}
                      <SceneContentHeader title="Список контактов" />
                      {contactFromDb ? (
                        <ContactCard
                          contactId={contactFromDb}
                          expanded
                          buttons={[{ type: 'detach', onDetach: () => setContactFromDb(null) }]}
                        />
                      ) : (
                        <ContactsOptions
                          activeDocId={contactFromDb}
                          onElementClicked={(docId) => setContactFromDb(docId)}
                          omitOptions={omitOptions}
                        />
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </PopUp>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(ContactPopUpManager)
