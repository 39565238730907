const { default: getFieldRenderObject } = require('../../../../../../../utilities/newforms/render/getFieldRenderObject')

class CategoryForm {
  constructor() {
    this.officeId = {
      field: {
        fieldId: 'officeId',
        fieldType: 'select',
        label: 'Офис',
        required: true,
        getOptions: 'getOfficesOptions',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название категории',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.isActive = {
      field: {
        fieldId: 'isActive',
        fieldType: 'checkbox',
        label: 'Активная',
        value: true,
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: {
          at: '',
          by: '',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: {
          at: '',
          by: '',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default CategoryForm
