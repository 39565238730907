import find from 'lodash/find'
import { db } from '../../../../config/firebase'
import * as ACTION_TYPES from './actionTypes'

export const dialerSendDataToReducer = (data) => ({
  type: ACTION_TYPES.DIALER_SEND_DATA_TO_REDUCER,
  payload: data,
})

export const dialerSetListener = (responsible = '') => (dispatch) => {
  db.collection('api/calls/events')
    // .where('isServing', '==', true)
    .where('responsible', '==', responsible)
    .orderBy('updated.at', 'desc')
    .onSnapshot((snap) => {
      const docs = []
      snap.forEach((doc) => {
        docs.push({ fbCallId: doc.id, ...doc.data() })
      })

      const activeCall = find(docs, ['isServing', true])
      let payload = null
      if (activeCall) {
        payload = activeCall
        dispatch({ type: ACTION_TYPES.DIALER_INITIATE_ACTIVE_CALL, payload })
      }

      const inboundCalls = docs.filter(
        (event) => event.currentState.toLowerCase() === 'alerting' && event.direction === 'inbound' && true,
      )
      dispatch({ type: ACTION_TYPES.DIALER_INITIATE_INBOUND_CALLS, payload: inboundCalls })
    })

  dispatch({ type: ACTION_TYPES.DIALER_SUBSCRIPTION_GET_SUBSCRIPTION, payload: { responsible } })
}

export const dialerSetSubscriptionListener = (phone) => (dispatch) => {
  db.collection(`extensions/08gWJH83oEYZsvfCZSaz/subscriptions/`)
    .doc(phone)
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({ type: ACTION_TYPES.DIALER_SUBSCRIPTION_UPDATE_SUBSCRIPTION_INFO, payload: doc.data() })
      }
    })
}

export const dialerSendActiveCallToReducer = (activeCall) => ({
  type: ACTION_TYPES.DIALER_SEND_ACTIVE_CALL_TO_REDUCER,
  payload: activeCall,
})

export const dialerShowCallBar = () => ({ type: ACTION_TYPES.DIALER_SHOW_CALLBAR })

export const dialerHideCallBar = () => ({ type: ACTION_TYPES.DIALER_HIDE_CALLBAR })

export const dialerToggleCallBar = () => ({ type: ACTION_TYPES.DIALER_TOGGLE_CALLBAR })

export const dialerSendCallRequest = (payload) => ({
  type: ACTION_TYPES.DIALER_SEND_CALL_REQUEST,
  payload,
})

export const dialerMuteInboundCallSound = () => ({ type: ACTION_TYPES.DIALER_MUTE_INBOUND_CALL_SOUND })

export const dialerUnmuteInboundCallSound = () => ({ type: ACTION_TYPES.DIALER_UNMUTE_INBOUND_CALL_SOUND })

export const dialerToggleMuteInboundCallSound = () => ({ type: ACTION_TYPES.DIALER_TOGGLE_MUTE_INBOUND_CALL_SOUND })

export const dialerSendSubscriptionInfo = (payload) => ({
  type: ACTION_TYPES.DIALER_SUBSCRIPTION_UPDATE_SUBSCRIPTION_INFO,
  payload,
})

export const dialerSubscribeManualy = (payload) => ({
  type: ACTION_TYPES.DIALER_SUBSCRIPTION_SUBSCRIBE_TO_CALL_EVENTS,
  payload,
})
