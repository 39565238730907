import React from 'react'
import CallEvent from '../CallEvent'
import { toast } from 'react-toastify'

export const pushCallEvent = ({ eventId, eventType, direction, responsible, name, phone, contactId }) => {
  const newToast = <CallEvent {...{ eventType, direction, responsible, name, phone, contactId }} />

  if (toast.isActive(eventId)) {
    toast.update(eventId, { render: newToast, containerId: 'sideEvents' })
    return eventId
  } else {
    return toast(newToast, { toastId: eventId, containerId: 'sideEvents' })
  }
}
