import React, { useEffect, useRef, useState } from 'react'
import './ContactCard.sass'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { find, isEmpty } from 'lodash'
import Icon from '../../../components/UI/Icon/Icon'
import { db } from '../../../config/firebase'
import fetchContactObject from '../scenes/Contactlist/functions/fetchContactObject'
import rootDbPath from '../../../utilities/db/rootDbPath'
import getDoc from '../../../utilities/db/getDoc'
import promiseAllValues from '../../../utilities/promiseAllValues'
import { clickToCall } from '../../Dialer/functions'
import { cleanPhoneNumber } from '../../../utilities/cleanPhoneNumber'
import UserNameWithAvatar from '../../../components/UserNameWithAvatar/UserNameWithAvatar'
import { usePopUp } from '../../../hooks/usePopUp'
import Skeleton from '../../../components/UI/Skeleton/Skeleton'
import Button from '../../../ui/Button/Button'
import rootPath from '../../../appTree/rootPath'

function ContactCard({
  accountId,
  contactId,
  superstructure,
  superstructureData,
  buttons = null,
  expanded = false,
  disableEdit,
  ...router
}) {
  const [contactData, setContactData] = useState(null)
  const [contactObject, setContactObject] = useState(null)
  const [contactForm, setContactForm] = useState(null)
  const [superstructureObject, setSuperstructureObject] = useState(null)
  const [superstructureForm, setSuperstructureForm] = useState(null)
  const ctHeaderRef = useRef()
  const ctBodyRef = useRef()
  const [showFullData] = usePopUp(ctHeaderRef, ctBodyRef)

  useEffect(() => {
    promiseAllValues({
      ...(superstructure
        ? {
            superstructureObject: getDoc({
              accountId,
              path: superstructure.object.path,
              docId: superstructure.object.docId,
            }),
            superstructureForm: getDoc({
              accountId,
              path: superstructure.form.path,
              docId: superstructure.form.docId,
            }).then(({ sections }) => sections),
          }
        : {}),
      contactObject: getDoc({ accountId, path: 'config/contacts/objects', docId: 'contactObject' }),
      contactForm: getDoc({ accountId, path: 'config/contacts/forms', docId: 'contactManager' }).then(
        ({ sections }) => sections,
      ),
    }).then((results) => {
      if (superstructure) {
        setSuperstructureObject(results.superstructureObject)
        setSuperstructureForm(results.superstructureForm)
      }
      setContactObject(results.contactObject)
      setContactForm(results.contactForm)
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(contactObject) && !isEmpty(contactForm)) {
      const listener = db
        .collection(`${rootDbPath(accountId)}/contacts`)
        .doc(contactId)
        .onSnapshot((snap) => {
          fetchContactObject({ accountId, doc: { contactId: snap.id, ...snap.data() } }).then((fetchedContact) =>
            setContactData(fetchedContact),
          )
        })

      return () => listener()
    }
  }, [contactObject, contactForm])

  const renderSuperstructureField = (field) => {
    if (!superstructureObject[field].render.isSystem && superstructureData[field]) {
      return (
        <>
          <span className="DataTitle">{superstructureObject[field].field.label}:</span>
          <span>{superstructureData[field]}</span>
        </>
      )
    }
    return null
  }

  const renderContactField = (field) => {
    switch (field) {
      case 'phone':
      case 'email':
      case 'address':
        if (hasFieldsWithValues(contactData[field])) {
          return (
            <>
              <div className="ContactCard-Body-SectionTitle">{contactObject[field].field.label}</div>
              {contactData[field]
                .filter((m) => (m.type && true) || (m.value && true))
                .map((m) => (
                  <>
                    <span className="DataTitle">
                      {m.type || field === 'phone' ? 'Телефон' : field === 'address' ? 'Адрес' : 'E-mail'}:
                    </span>
                    <span
                      onClick={
                        field === 'phone'
                          ? () =>
                              clickToCall({
                                name: `${contactData.lastName} ${contactData.name} ${contactData.middleName}`,
                                phone: cleanPhoneNumber(m.value),
                                contactId: contactData.contactId,
                                responsible: contactData.responsible.userId,
                              })
                          : null
                      }
                      className={field === 'phone' && 'ActiveLink'}
                    >
                      {m.value}
                    </span>
                  </>
                ))}
            </>
          )
        }
        return null
      case 'responsible':
        return (
          <>
            <span className="DataTitle">{contactObject[field].field.label}:</span>
            <span>
              <UserNameWithAvatar
                avatarUrl={contactData[field].avatar.publicUrl}
                avatarSize="26"
                userName={`${contactData[field].lastName} ${contactData[field].name} ${contactData[field].middleName}`}
              />
            </span>
          </>
        )
      default:
        if (!contactObject[field].render.isSystem && contactData[field]) {
          return (
            <>
              <span className="DataTitle">{contactObject[field].field.label}:</span>
              <span>{contactData[field]}</span>
            </>
          )
        }
        return null
    }
  }

  return contactData && ((superstructure && superstructureForm && superstructureObject) || !superstructure) ? (
    <div className={['ContactCard', ...(showFullData || expanded ? ['ContactCard_status_opened'] : [])].join(' ')}>
      <div ref={ctHeaderRef} className="ContactCard-Header">
        <div className="Header-Name">
          <div className="ContactCard-Icon">
            <Icon name="user" />
          </div>
          <span className="ContactName">{`${contactData.lastName} ${contactData.name} ${contactData.middleName}`}</span>
        </div>
        <Icon helperClass="ContactCard-Header-Arrow" name="angle-down" />
      </div>
      <div ref={ctBodyRef} className="ContactCard-Body">
        {superstructure &&
          superstructureForm.map((section) => section.fields.map((field) => renderSuperstructureField(field)))}
        {contactForm.map((section) =>
          section.fields
            .filter((field) => !['name', 'lastName', 'middleName'].includes(field))
            .map((field) => renderContactField(field)),
        )}
        {buttons && (
          <div className="Body-Buttons">
            <div className="ContactCard-Options">
              {find(buttons, ['type', 'openCard']) && (
                <Button
                  onClick={() => router.history.push(`${rootPath(accountId)}/contacts/persons/list/${contactId}/edit`)}
                  title="Открыть карточку"
                  theme="solid"
                  fill="accent"
                  size={28}
                />
              )}
              {!disableEdit && find(buttons, ['type', 'edit']) && (
                <Button
                  onClick={find(buttons, ['type', 'edit']).onEdit}
                  title="Редакт."
                  theme="bounded"
                  border="accent"
                  color="accent"
                  size={28}
                />
              )}
            </div>
            {!disableEdit && find(buttons, ['type', 'detach']) && (
              <Button
                onClick={find(buttons, ['type', 'detach']).onDetach}
                title="Открепить."
                theme="bounded"
                border="noty"
                color="noty"
                size={28}
              />
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <ContactCardSkeleton />
  )
}

export const ContactCardSkeleton = () => (
  <div className="ContactCard">
    <div className="ContactCard-Header">
      <div className="Header-Name">
        <Skeleton className="ContactCard-Icon" circle width={24} height={24} />
        <Skeleton width={80} />
      </div>
      <Skeleton circle width={16} height={16} />
    </div>
  </div>
)

const hasFieldsWithValues = (fields) =>
  fields.map((m) => Object.values(m).some((v) => v && true)).some((v) => v && true)

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(ContactCard)
