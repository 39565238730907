import getFieldRenderObject from '../../../../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  userId: {
    field: {
      fieldId: 'userId',
      required: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  userSearch: {
    field: {
      fieldId: 'userSearch',
      icon: 'search',
      iconPosition: 'left',
      fieldType: 'input',
      inputType: 'text',
      label: 'Введите E-mail пользователя CardBox',
    },
    render: getFieldRenderObject(),
  },
  avatar: {
    field: {
      fieldId: 'avatar',
      fieldType: 'inputFile',
      inputType: 'file',
      label: 'Файл',
      icon: 'ellipsis-v',
    },
    render: getFieldRenderObject(),
  },
  lastName: {
    field: {
      fieldId: 'lastName',
      fieldType: 'input',
      inputType: 'text',
      label: 'Фамилия',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  name: {
    field: {
      fieldId: 'name',
      fieldType: 'input',
      inputType: 'text',
      label: 'Имя',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  middleName: {
    field: {
      fieldId: 'middleName',
      fieldType: 'input',
      inputType: 'text',
      label: 'Отчество',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  phone: {
    field: {
      fieldId: 'phone',
      fieldType: 'phone',
      inputType: 'text',
      label: 'Номер телефона',
      required: true,
    },
    render: getFieldRenderObject(),
  },
  bdate: {
    field: {
      fieldId: 'bdate',
      fieldType: 'input',
      inputType: 'text',
      mask: 'date',
      label: 'Дата рождения',
    },
    render: getFieldRenderObject(),
  },
  roleId: {
    field: {
      fieldId: 'roleId',
      fieldType: 'select',
      label: 'Роль',
      required: true,
      getOptions: 'getSystemRoles',
    },
    render: getFieldRenderObject(),
  },
  officeId: {
    field: {
      fieldId: 'officeId',
      fieldType: 'select',
      label: 'Офис',
      required: true,
      getOptions: 'getOffices',
    },
    render: getFieldRenderObject(),
  },
  squadId: {
    field: {
      fieldId: 'squadId',
      fieldType: 'select',
      label: 'Отдел',
      getOptions: 'getOfficeSquads',
    },
    render: getFieldRenderObject(),
  },
  occupation: {
    field: {
      fieldId: 'occupation',
      fieldType: 'input',
      inputType: 'text',
      label: 'Должность',
    },
    render: getFieldRenderObject(),
  },
  description: {
    field: {
      fieldId: 'description',
      fieldType: 'textarea-autosize',
      label: 'Доп. описание',
    },
    render: getFieldRenderObject(),
  },
  isBlocked: {
    field: {
      fieldId: 'isBlocked',
      value: false,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  statusId: {
    field: {
      fieldId: 'statusId',
      value: 'invited',
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  created: {
    field: {
      fieldId: 'created',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: {
        at: '',
        by: '',
      },
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export const membersForm = [
  'lastName',
  'name',
  'middleName',
  'phone',
  'bdate',
  'roleId',
  'officeId',
  'squadId',
  'occupation',
  'description',
]

export default object
