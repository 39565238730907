import { isEmpty } from 'lodash'

const countEarnings = ({ doneDeals, failDeals, responsible = [] }) => {
  let earnings = 0
  let totalSalesWithComission = 0
  let averageCheck = 0
  let maxCheck = 0

  doneDeals
    .filter((dd) => {
      if (!isEmpty(responsible)) {
        return responsible.includes(dd.responsible)
      }

      return true
    })
    .forEach((d) => {
      totalSalesWithComission += 1
      earnings += d.comission
      averageCheck = Math.round(earnings / totalSalesWithComission)
      if (d.comission > maxCheck) maxCheck = d.comission
    })

  failDeals
    .filter((fd) => {
      if (!isEmpty(responsible)) {
        return responsible.includes(fd.responsible)
      }

      return true
    })
    .forEach((d) => {
      const comission = d.factComission
      earnings += comission
      averageCheck = Math.round(earnings / totalSalesWithComission)
      if (comission > maxCheck) maxCheck = comission
    })
  return { earnings, totalSalesWithComission, averageCheck, maxCheck }
}

export default countEarnings
