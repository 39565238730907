import React, { useRef, useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Icon from '../../../components/UI/Icon/Icon'
import { usePopUp } from '../../../hooks/usePopUp'
import useWindowSize from '../../../hooks/useWindowSize'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'

function FooterBlock({ title, links, ...router }) {
  const linkRef = useRef()
  const bodyRef = useRef()
  const { width: windowWidth } = useWindowSize()
  const [showMenu, setShowMenu] = usePopUp(linkRef, bodyRef)

  useEffect(() => {
    setShowMenu(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <div
      className={[
        'Site-Footer-Block',
        ...(windowWidth < 1024 && showMenu ? ['Site-Footer-Block_theme_active'] : []),
      ].join(' ')}
    >
      <h5 ref={linkRef} className="Site-TitleText">
        {title}
        <span className="Footer-Block-Arrow">
          <Icon name="angle-down" />
        </span>
      </h5>
      <div ref={bodyRef} className="Footer-Block-Links">
        {links.map((l) =>
          l.navLink ? (
            <NavLink to={l.path} className="Site-DescriptionText Footer-Link">
              {l.title}
            </NavLink>
          ) : l.hashLink ? (
            <HashLink
              to={l.path}
              smooth
              scroll={(e) => scrollWithOffset(e, -64)}
              className="Site-DescriptionText Footer-Link"
            >
              {l.title}
            </HashLink>
          ) : (
            <a href={l.path} className="Site-DescriptionText Footer-Link" target={l.targetBlank ? 'blank' : ''}>
              {l.title}
            </a>
          ),
        )}
      </div>
    </div>
  )
}

export default withRouter(FooterBlock)
