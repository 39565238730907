import React, { useState, useEffect } from 'react'
import './SceneFilters.sass'
import { isEmpty } from 'lodash'
import IconicButton from '../../../components/UI/IconicButton/IconicButton'

function SceneFilters({ filters }) {
  const [filtersToShow, setFiltersToShow] = useState([])

  useEffect(() => {
    if (!isEmpty(filters)) {
      if (filters.length > 3) {
        setFiltersToShow(filters.filter((f, i) => !(i > 2)))
      } else {
        setFiltersToShow(filters)
      }
    } else {
      setFiltersToShow([])
    }
  }, [filters])

  return !isEmpty(filters) ? (
    <div className="SceneFilters">
      {filtersToShow.map((filter) => (
        <div className="SceneContent-Header-ActiveFilter">
          <span className="FilterLabel">{filter.label}</span>
          <span className="FilterClose-ButtonContainer">
            <IconicButton type="basic" icon="times" size="5" clicked={filter.onClick} />
          </span>
        </div>
      ))}
      {!isEmpty(filters) && filters.length > 3 && (
        <div className="SceneContent-Header-ActiveFilters-Counter">+{filters.length - 3}</div>
      )}
    </div>
  ) : null
}

export default SceneFilters
