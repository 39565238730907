export const getEditOptions = (savePlan, setPlanOnEdit) => {
  return [
    {
      return: 'docId',
      type: 'add',
      icon: 'save',
      label: 'Сохранить',
      clicked: savePlan,
    },
    {
      return: 'docId',
      type: 'remove',
      icon: 'times',
      label: 'Отменить',
      clicked: () => setPlanOnEdit({}),
    },
  ]
}

export const getDefaultOptions = (editPlan) => {
  return [
    {
      return: 'docId',
      type: 'edit',
      icon: 'pencil-alt',
      label: 'Редакт.',
      clicked: editPlan,
    },
  ]
}
