import { isEmpty } from 'lodash'

const marketNavBlock = (subsections) => {
  const blockSubsections = subsections.filter((s) => ['appsMarket', 'appsInstalled'].includes(s.id) && true)

  return !isEmpty(blockSubsections)
    ? {
        title: 'Приложения',
        items: blockSubsections.map((i) => {
          i.type = 'navlink'
          return i
        }),
        //   id: 'installedApps',
        //   type: 'navlink',
        //   icon: 'arrow-alt-to-bottom',
        //   title: 'Установленные приложения',
        //   path: `${rootPath(accountId)}/settings/market/installed`,
        //   component: MarketRoot,
        // },
      }
    : null
}

export default marketNavBlock
