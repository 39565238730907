import dealObject, { dealManagerForm } from '../../modules/Deals/scenes/Dealslist/DealManager/dealDbForm'
import funnelStepObject from '../../modules/Funnel/functions/FunnelStepObject'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'
import { addDoc } from '../../utilities/db/addDoc'
import { setDoc } from '../../utilities/db/setDoc'

const installDeals = ({ accountId, uid }) => {
  const failReasons = [
    { data: { label: 'Потребность не удовлетворена' } },
    { data: { label: 'Потребность отсутствует' } },
  ]
  const funnels = [
    {
      docId: 'default',
      data: {
        steps: [
          funnelStepObject({ label: 'Квалификация' }),
          funnelStepObject({ label: 'Выявление потребности' }),
          funnelStepObject({ label: 'Реализация' }),
        ],
        created: getCurDateWithUser(uid),
        updated: getCurDateWithUser(uid),
      },
    },
  ]

  const forms = [{ docId: 'dealManager', data: dealManagerForm }]
  const objects = [{ docId: 'dealObject', data: dealObject }]

  return Promise.all([
    setDoc({
      accountId,
      path: 'config',
      docId: 'deals',
      data: {
        moduleConfig: {
          inBaseCounter: 30,
          isDetachableContact: true,
          useCustomer: false,
          useNeedForms: false,
        },
      },
    }),
    failReasons.map((d) => addDoc({ accountId, path: 'config/deals/failReasons', ...d })),
    funnels.map((d) => setDoc({ accountId, path: 'config/deals/funnels', ...d })),
    forms.map((d) => setDoc({ accountId, path: 'config/deals/forms', ...d })),
    objects.map((d) => setDoc({ accountId, path: 'config/deals/objects', ...d })),
  ])
}

export default installDeals
