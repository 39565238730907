import React, { useState } from 'react'
import './Funnel.sass'
import { isEmpty, find, pick } from 'lodash'
import ReactTooltip from 'react-tooltip'
import { differenceInDays } from 'date-fns'
import Skeleton from '../../components/UI/Skeleton/Skeleton'
import onStepClicked from './functions/onStepClicked'
import declOfNum from '../../utilities/declOfNum'
import spinner from '../../ui/Button/assets/spinner-white.svg'
import { updateDoc } from '../../utilities/db/updateDoc'

export default function Funnel({ accountId, dealId, funnel, setFunnel, buttons, isChangable, permissions, uid }) {
  return isEmpty(funnel) ? (
    <FunnelSkeleton />
  ) : (
    <div className="Funnel-Container">
      <div className="Funnel-Header">
        <ReactTooltip />
        <div className="Funnel-CurrentStep">
          Текущий этап: <span>{find(funnel, ['stepStatus', 'active']).label}</span>
        </div>
        {buttons && permissions && permissions.edit && <div className="Funnel-Buttons">{buttons}</div>}
      </div>
      <div className="Funnel-Steps" style={{ gridTemplateColumns: `repeat(${funnel.length}, 1fr)` }}>
        {funnel.map((step) => (
          <FunnelStep
            accountId={accountId}
            dealId={dealId}
            step={step}
            funnel={funnel}
            setFunnel={setFunnel}
            uid={uid}
            isChangable={permissions && permissions.edit ? isChangable : false}
          />
        ))}
      </div>
    </div>
  )
}

function FunnelStep({ accountId, dealId, step, funnel, setFunnel, uid, isChangable }) {
  const [isLoading, setIsLoading] = useState(false)

  const startDate = step.started
    ? step.started.at.hasOwnProperty('seconds')
      ? step.started.at.seconds * 1000
      : step.started.at
    : null

  const endDate = step.ended
    ? step.ended.at.hasOwnProperty('seconds')
      ? step.ended.at.seconds * 1000
      : step.ended.at
    : new Date()

  const stepDays = step.started ? differenceInDays(endDate, startDate) : 0

  const onClick = (stepId) => {
    const newFunnel = onStepClicked({ stepId, funnel, uid })
    setFunnel(newFunnel)
    if (dealId) {
      setIsLoading(true)
      updateDoc({
        accountId,
        path: 'deals',
        docId: dealId,
        data: { funnel: newFunnel.map((f) => pick(f, ['stepId', 'started', 'ended', 'stepStatus'])) },
      }).then(() => setIsLoading(false))
    }
  }

  return (
    <div
      data-tip={step.label}
      onClick={() => (isChangable ? onClick(step.stepId) : null)}
      className={[
        'Funnel-Step',
        ...(step.stepStatus ? [`Funnel-Step_status_${step.stepStatus}`] : []),
        ...(!isChangable ? [`Funnel-Step_disabled`] : []),
      ].join(' ')}
    >
      {isLoading ? (
        <img src={spinner} alt="Loading" />
      ) : step.stepStatus ? (
        `${stepDays} ${declOfNum(stepDays, ['день', 'дня', 'дней'])}`
      ) : null}
      <div className="Funnel-Step-TriangleColor" />
      <div className="Funnel-Step-TriangleWhite" />
    </div>
  )
}

function FunnelSkeleton() {
  return (
    <div className="Funnel-Container">
      <div className="Funnel-Header">
        <div className="Funnel-CurrentStep">
          <Skeleton width={120} />
        </div>
        <div className="Funnel-Buttons">
          <Skeleton width={120} height={28} style={{ marginRight: '16px' }} />
          <Skeleton width={120} height={28} />
        </div>
      </div>
      <Skeleton height={32} />
    </div>
  )
}
