import { find } from 'lodash'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { deleteStorageFile } from '../../../../../../../utilities/storage/deleteStorageFile'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import { firebase } from '../../../../../../../config/firebase'
import getDoc from '../../../../../../../utilities/db/getDoc'

const deleteMember = ({ accountId, userId, uid }) =>
  getDoc({ path: 'accounts', docId: accountId }).then(({ members }) => {
    const member = find(members, ['userId', userId])

    if (member) {
      return Promise.all([
        ...(member.avatar.storagePath
          ? [deleteStorageFile(`${member.avatar.storagePath}/${member.avatar.fileName}.${member.avatar.fileExt}`)]
          : []),
        updateDoc({
          path: 'accounts',
          docId: accountId,
          data: { members: members.filter((m) => m.userId !== member.userId), updated: getCurDateWithUser(uid) },
        }),
        ...(member.squadId
          ? [
              getDoc({ accountId, path: 'offices', docId: member.officeId }).then((officeData) =>
                updateDoc({
                  accountId,
                  path: 'offices',
                  docId: member.officeId,
                  data: {
                    squads: officeData.squads.map((squad) => {
                      if (squad.squadId === member.squadId) {
                        squad.members = squad.members.filter((m) => m !== userId)
                        squad.squadHead = squad.squadHead === userId ? '' : squad.squadHead
                      }

                      return squad
                    }),
                  },
                  uid,
                }),
              ),
            ]
          : []),
        ...(member.roleId !== 'root'
          ? [
              updateDoc({
                accountId,
                path: 'roles',
                docId: member.roleId,
                data: { members: firebase.firestore.FieldValue.arrayRemove(userId) },
              }),
            ]
          : []),
      ])
        .then(() => 'ok')
        .catch((e) => console.log(e))
    }
    return Promise.resolve('member not found')
  })

export default deleteMember
