import { cloneDeep, find, isEmpty, last } from 'lodash'
import moduleConfig from './moduleConfig'
import subsectionConfig from './subsectionConfig'

// Dashboard module
import Dashboard from '../modules/Dashboard/Dashboard'
// Contacts module
import Contacts from '../modules/Contacts/Contacts'
// Estate module
import Estate from '../modules/Estate/Estate'
// Members module
import Members from '../modules/Settings/Company/components/Members/Members'
import Roles from '../modules/Settings/Company/components/Roles/Roles'
import Offices from '../modules/Settings/Company/components/Offices/Offices'
import Motivations from '../modules/Settings/Company/components/Motivations/Motivations'
import SalesPlans from '../modules/Settings/Company/components/SalesPlans/SalesPlans'
// Leads module
// import Leads from '../modules/Leads/Leads'
// Sales module
import Deals from '../modules/Deals/Deals'
// Tasks module
import TaskNavComponent from '../modules/Tasks/NavComponent'
import Tasks from '../modules/Tasks/scenes/Tasks/Tasks'
import ParamsManagerConfigurator from '../modules/Settings/ParamsManagerConfigurator/ParamsManagerConfigurator'
import rootPath from './rootPath'
import Settings from '../modules/Settings/Settings'
import Organizations from '../modules/Contacts/Organizations'
import roleObject from '../modules/Settings/Company/components/Roles/components/ManageRolePopUp/roleObject'
import MarketRoot from '../modules/Market/MarketRoot'
import SiteRoot from '../modules/Site/SiteRoot'

function appTree({ accountId, history, solution, role }) {
  const tree = [
    moduleConfig({
      id: 'dashboard',
      title: 'Дашборд',
      icon: 'tachometer-alt-fast',
      path: `${rootPath(accountId)}/dashboard`,
      activeClassPath: `${rootPath(accountId)}/dashboard`,
      subsections: [
        subsectionConfig({
          id: 'dashboard',
          title: 'Дашборд',
          path: `${rootPath(accountId)}/dashboard`,
          component: Dashboard,
          access: true,
        }),
      ],
    }),
    moduleConfig({
      id: 'contacts',
      title: 'Контакты',
      icon: 'users',
      path: `${rootPath(accountId)}/contacts`,
      activeClassPath: `${rootPath(accountId)}/contacts`,
      subsections: [
        subsectionConfig({
          id: 'contactlist',
          title: 'Контакты',
          path: `${rootPath(accountId)}/contacts/persons/list`,
          component: Contacts,
          access: true,
        }),
        subsectionConfig({
          id: 'organizationslist',
          title: 'Организации',
          path: `${rootPath(accountId)}/contacts/organizations/list`,
          component: Organizations,
          access: true,
        }),
      ],
    }),
    moduleConfig({
      id: 'deals',
      title: 'Сделки',
      icon: 'dollar-sign',
      path: `${rootPath(accountId)}/deals`,
      subsections: [
        subsectionConfig({
          id: 'dealslist',
          title: 'Сделки',
          path: `${rootPath(accountId)}/deals/list`,
          component: Deals,
          access: true,
        }),
      ],
      activeClassPath: `${rootPath(accountId)}/deals`,
    }),
    moduleConfig({
      id: 'tasks',
      title: 'Задачи',
      icon: 'tasks',
      path: `${rootPath(accountId)}/tasks`,
      activeClassPath: `${rootPath(accountId)}/tasks`,
      subsections: [
        subsectionConfig({
          id: 'tasklist',
          title: 'Задачи',
          path: `${rootPath(accountId)}/tasks/list`,
          component: Tasks,
          access: true,
        }),
      ],
      navComponent: TaskNavComponent,
    }),
    ...(solution === 'estate'
      ? [
          moduleConfig({
            id: 'estate',
            title: 'Объекты',
            icon: 'home',
            path: `${rootPath(accountId)}/estate`,
            subsections: [
              subsectionConfig({
                id: 'estatelist',
                title: 'Список обьектов',
                path: `${rootPath(accountId)}/estate/list`,
                component: Estate,
                access: true,
              }),
            ],
            activeClassPath: `${rootPath(accountId)}/estate`,
          }),
        ]
      : []),
    moduleConfig({
      id: 'settings',
      title: 'Настройки',
      icon: 'cog',
      path: `${rootPath(accountId)}/settings`,
      activeClassPath: `${rootPath(accountId)}/settings`,
      component: Settings,
      subsections: [
        subsectionConfig({
          id: 'offices',
          icon: 'city',
          title: 'Офисы',
          path: `${rootPath(accountId)}/settings/offices`,
          component: Offices,
        }),
        subsectionConfig({
          id: 'members',
          icon: 'users',
          title: 'Сотрудники',
          path: `${rootPath(accountId)}/settings/members/list`,
          component: Members,
        }),
        subsectionConfig({
          id: 'salesPlans',
          icon: 'chart-line',
          title: 'Планы продаж',
          path: `${rootPath(accountId)}/settings/sales-plans`,
          component: SalesPlans,
        }),
        subsectionConfig({
          id: 'roles',
          icon: 'address-card',
          title: 'Системные роли',
          path: [
            `${rootPath(accountId)}/settings/roles/:id/:block/:subsection`,
            `${rootPath(accountId)}/settings/roles/:id`,
            `${rootPath(accountId)}/settings/roles`,
          ],
          component: Roles,
        }),
        subsectionConfig({
          id: 'appsMarket',
          icon: 'puzzle-piece',
          title: 'Магазин приложений',
          path: `${rootPath(accountId)}/settings/market/apps`,
          component: MarketRoot,
        }),
        subsectionConfig({
          id: 'siteNews',
          icon: 'newspaper',
          title: 'Новости',
          path: `${rootPath(accountId)}/settings/site/news`,
          component: SiteRoot,
        }),
        subsectionConfig({
          id: 'siteFeeds',
          icon: 'comments',
          title: 'Отзывы',
          path: `${rootPath(accountId)}/settings/site/feeds`,
          component: SiteRoot,
        }),
      ],
    }),
  ]

  const userTree = []

  tree.forEach((m) => {
    const moduleClone = cloneDeep(m)
    if (role.roleId === 'root') {
      if (moduleClone.hasOwnProperty('subsections')) {
        moduleClone.subsections = m.subsections.map((subsection) => {
          subsection.permissions = {}
          subsection.options = {}
          const roleObjectModule = find(roleObject(solution).modules.field.value, ['moduleId', m.id])
          const roleObjectSubsection = find(roleObjectModule.subsections, ['subsectionId', subsection.id])
          Object.entries(roleObjectSubsection.permissions).forEach(([k, v]) => {
            const lastOption = last(v.options)
            subsection.permissions[k] = lastOption.value
          })
          if (roleObjectSubsection.hasOwnProperty('options')) {
            Object.entries(roleObjectSubsection.options).forEach(([k, v]) => {
              const lastOption = last(v.options)
              subsection.options[k] = lastOption.value
            })
          }
          return subsection
        })
      }
      return userTree.push(moduleClone)
    }

    if (role.modules.hasOwnProperty(m.id)) {
      moduleClone.subsections = m.subsections
        .filter((subsection) =>
          role.modules[m.id][subsection.id] ? role.modules[m.id][subsection.id].permissions.access !== 'denied' : false,
        )
        .map((subsection) => {
          subsection.permissions = role.modules[m.id][subsection.id].permissions
          subsection.options = role.modules[m.id][subsection.id].options
          return subsection
        })
      if (!isEmpty(moduleClone.subsections)) userTree.push(moduleClone)
    }
  })

  return userTree
}

export default appTree
