import React from 'react'
import isEmpty from 'lodash/isEmpty'
import TaskNavContact from './TaskContact'
import TaskNavSales from './TaskSale'

function TaskAttachableInstance({ taskId, contacts, leads, sales, estate, uid, canBeEdit }) {
  return isEmpty(contacts) && isEmpty(leads) && isEmpty(sales) && isEmpty(estate) ? (
    <>
      {/* <TaskNavContact icon="users" label="Контакт" data={{ taskId, contacts }} uid={uid} canBeEdit={canBeEdit} /> */}
      {/* <TaskNavSales icon="dollar-sign" label="Сделку" data={{ taskId, sales }} uid={uid} canBeEdit={canBeEdit} /> */}
    </>
  ) : (
    ''
  )
}

export default TaskAttachableInstance
