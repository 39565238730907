import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import DataCounter from '../../widgets/DataCounter/DataCounter'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import getDoneDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import getFailDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getFailDeals'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import isResponsibleFilterAvailable from '../../functions/isResponsibleFilterAvailable'
import countFactDeals from '../../../Deals/scenes/Dealslist/functions/analytics/countFactDeals'

function DealsCounter({ accountId, uid, ...router }) {
  const [counters, setCounters] = useState(null)
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads } = resp
      let { responsible } = resp

      const counters = [
        { value: 0, tag: 'Активные сделки', theme: 'default' },
        { value: 0, tag: 'Завершено с победой', theme: 'positive' },
        { value: 0, tag: 'Завершено с проигрышем', theme: 'negative' },
      ]

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      })
        .then((data) => {
          responsible = isResponsibleFilterAvailable(uid) ? data.responsible : [uid]
          return promiseAllValues({
            activeDeals: countFactDeals({ accountId, responsible }),
            doneDeals: getDoneDeals({ accountId, dateRange, responsible }).then((ds) => ds.length),
            failedDeals: getFailDeals({ accountId, dateRange, responsible }).then((fs) => fs.length),
          })
        })
        .then((ctrs) => {
          counters[0].value = ctrs.activeDeals
          counters[1].value = ctrs.doneDeals
          counters[2].value = ctrs.failedDeals
          setCounters(counters)
        })
    }
  }, [dateRange, resp])

  return <DataCounter title="Сделки" counters={counters} isLoading={!counters} counterPeriod={dateRange} />
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(connect(mapStateToProps), withRouter)(DealsCounter)
