import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfToday from 'date-fns/startOfToday'
import getTodayRange from './getTodayRange'
import getCalendarYearsWithMonths from './getCalendarYearsWithMonths'

const getCustomRange = ({ startingDate = startOfMonth(startOfToday()), endingDate = endOfMonth(startOfToday()) }) => ({
  ...getTodayRange(),
  label: 'Выбранный период',
  startingDate,
  endingDate,
  years: getCalendarYearsWithMonths(startingDate, endingDate),
})

export default getCustomRange
