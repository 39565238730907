import subsectionConfig from '../../../../../../appTree/subsectionConfig'

function getEstateManagerSubmenu({ estateId, modulePath }) {
  const submenuLinks = [
    subsectionConfig({
      id: '',
      title: 'Инфо об объекте',
      path: estateId ? `${modulePath}/${estateId}/edit` : `${modulePath}/add`,
      access: true,
    }),
  ]

  return submenuLinks
}

export default getEstateManagerSubmenu
