import { cloneDeep, find, isEmpty } from 'lodash'
import { getCurDateWithUser } from '../../../utilities/date/getCurDateWithUser'

const mergeFunnelWithPattern = ({ funnel = [], pattern, uid }) => {
  const funnelPatternClone = cloneDeep(pattern)

  if (isEmpty(funnel)) {
    funnelPatternClone[0].stepStatus = 'active'
    funnelPatternClone[0].started = getCurDateWithUser(uid)
    return funnelPatternClone
  }

  const mergedFunnel = funnelPatternClone.map((step) => {
    const stepInFunnel = find(funnel, ['stepId', step.stepId])

    if (stepInFunnel) {
      return { ...step, ...stepInFunnel }
    }
    return step
  })

  const funnelHasActiveStep = find(mergedFunnel, ['stepStatus', 'active'])
  if (!funnelHasActiveStep) {
    mergedFunnel[0].stepStatus = 'active'
    mergedFunnel[0].started = getCurDateWithUser(uid)
  }

  return mergedFunnel
}

export default mergeFunnelWithPattern
