import * as actionTypes from './actionTypes'

export const authGetState = (payload) => ({
  type: actionTypes.AUTH_GET_AUTH_STATE,
  payload,
})

export const authUpdateAccountData = (payload) => ({
  type: actionTypes.AUTH_UPDATE_ACCOUNT_DATA,
  payload,
})

export const authUpdateAccountUserData = (payload) => ({
  type: actionTypes.AUTH_UPDATE_ACCOUNT_USER_DATA,
  payload,
})

export const authSignOutUser = () => ({ type: actionTypes.AUTH_SIGN_OUT_USER })
