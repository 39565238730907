import React, { useState, useEffect } from 'react'
import './DateBar.sass'
import RangePicker from '../RangePicker/RangePicker'
import { setUrlQueryParams } from '../../../../utilities/queries/'

function DateBar({ history }) {
  const [dateRange, setDateRange] = useState(null)
  useEffect(() => {
    if (dateRange) {
      history.push(setUrlQueryParams({ history, params: [['period', dateRange.type]] }))
    }
  }, [dateRange])

  return (
    <div className="DateBar">
      {/* <RangePicker type="yesterday" setDateRange={setDateRange} history={history} /> */}
      {/* <RangePicker type="today" setDateRange={setDateRange} history={history} /> */}
      {/* <RangePicker type="week" setDateRange={setDateRange} history={history} /> */}
      <RangePicker type="month" setDateRange={setDateRange} history={history} pickDateOnInit />
      <RangePicker type="quarter" setDateRange={setDateRange} history={history} />
      <RangePicker type="year" setDateRange={setDateRange} history={history} />
      <RangePicker
        type="custom"
        pickType="month"
        dateRange={dateRange}
        setDateRange={setDateRange}
        icon="calendar-alt"
        history={history}
      />
    </div>
  )
}

export default DateBar
