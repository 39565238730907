import React from 'react'
import SiteDropdownLink from './SiteDropdownMenu/SiteDropdownLink'
import SiteDropdownMenu from './SiteDropdownMenu/SiteDropdownMenu'

export const links = [
  {
    path: '',
    icon: 'star',
    title: 'О нас',
    description: `Увеличиваем эффективность работы компаний, с минимальными затратами ресурсов и времени.`,
  },
  {
    path: '',
    icon: 'star',
    title: 'Вакансии',
    description: `У нас всегда есть интересные вакансии. Ищем профессионалов своего дела.`,
  },
  {
    path: '',
    icon: 'star',
    title: 'Блог',
    description: `Пишем о наших обновлениях и перспективах развития вашего бизнеса`,
  },
  { path: '', icon: 'star', title: 'Отзывы', description: 'Честные отзывы на независимых площадках' },
  {
    path: '',
    icon: 'star',
    title: 'Стать партнером',
    description: `Присоединяйтесь и зарабатывайте вместе с нами. Стабильный ежемесячный доход.`,
  },
  { path: '/contact-us', icon: 'star', title: 'Контакты', description: null },
]

function ResourcesMenu() {
  return (
    <SiteDropdownMenu title="Ресурсы">
      {links.map((l) => (
        <SiteDropdownLink path={l.path} icon={l.icon} title={l.title} description={l.description} />
      ))}
    </SiteDropdownMenu>
  )
}

export default ResourcesMenu
