import isFinite from 'lodash/isFinite'
import toNumber from 'lodash/toNumber'
import { checkValidity } from './validation'

export const fieldChanged = (field, event) => {
  const { value } = { ...event.target }

  if (field.config.mask === 'number') {
    if (!isFinite(toNumber(value))) return
  }

  if (field.fieldType === 'checkbox') {
    const { checked } = { ...event.target }
    field.value = checked
  } else if (field.fieldType === 'select' && field.config.multiOptions) {
    if (event.standAloneOption) {
      field.value = [value]
    } else {
      if (event.standAloneOptions) {
        let activeSAOption = false
        event.standAloneOptions.forEach((o) => {
          if (field.value.includes(o.value) && !activeSAOption) {
            activeSAOption = true
          }
        })
        if (activeSAOption) field.value = []
      }

      if (field.value.includes(value)) {
        field.value = field.value.filter((v) => v !== value)
      } else {
        field.value.push(value)
      }
    }
  } else {
    field.value = value
  }

  if (field.fieldType === 'phone') {
    field.country = event.country
  }

  const { status, error } = checkValidity(field)
  field.valid = status
  field.error = error

  if (field.fieldType !== 'checkbox' && field.fieldType !== 'select') {
    field.value.trim() !== '' ? (field.touched = true) : (field.touched = false)
  }

  return field
}
