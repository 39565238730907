import React from 'react'
import './SignInLink.sass'
import Button from '../../../../ui/Button/Button'

function SignInLink() {
  return (
    <Button
      title="Войти"
      type="navlink"
      path="/auth/sign-in"
      icon="sign-in"
      iconWeight="solid"
      theme="transparent-hug"
      className="SignInLink"
    />
  )
}

export default SignInLink
