import { cloneDeep, find } from 'lodash'
import roleObject from '../components/ManageRolePopUp/roleObject'

const fillRoleObjectWithData = ({ formData, solution }) => {
  const formPatternClone = cloneDeep(roleObject(solution))
  Object.entries(formData).forEach(([fKey, fVal]) => {
    if (fKey === 'modules') {
      Object.entries(formData.modules).forEach(([mKey, mVal]) => {
        const module = find(formPatternClone.modules.field.value, ['moduleId', mKey])
        if (module) {
          Object.entries(mVal).forEach(([sKey, sVal]) => {
            const subsection = find(module.subsections, ['subsectionId', sKey])
            if (subsection) {
              Object.entries(sVal).forEach(([fKey, fVal]) => {
                Object.entries(fVal).forEach(([vKey, vVal]) => {
                  subsection[fKey][vKey].value = vVal
                })
              })
            }
          })
        }
      })
    } else if (formPatternClone.hasOwnProperty(fKey)) {
      formPatternClone[fKey].field.value = fVal
    }
  })

  return formPatternClone
}

export default fillRoleObjectWithData
