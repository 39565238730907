import { isEmpty, cloneDeep, find, omit } from 'lodash'
import uploadFiles from '../../../../../../utilities/storage/uploadFiles'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'

const uploadPhotos = ({ accountId, photos, docId, docPath = 'tours', storagePath = 'photos' }) => {
  console.log('🚀 ~ file: uploadPhotos.js:6 ~ uploadPhotos ~ photos:', photos)
  const photosToUpload = photos.filter((p) => p.needUpload)
  if (!isEmpty(photosToUpload)) {
    const path = `accounts/${accountId}/news/${docId}`

    return uploadFiles({ files: photosToUpload, storagePath: path }).then((uploadedPhotos) => {
      if (!isEmpty(uploadedPhotos)) {
        const photosClone = cloneDeep(photos)
        uploadedPhotos.forEach((p) => {
          const photo = find(photosClone, ['fileName', p.fileName])
          if (photo) {
            photo.publicUrl = p.publicUrl
            photo.needUpload = false
            photo.storagePath = storagePath
          }
        })

        return updateDoc({
          accountId,
          path: docPath,
          docId,
          data: { photos: photosClone.map((p) => omit(p, ['imageUrl', 'file', 'needUpload', 'uploadProgress'])) },
        })
      }
    })
  }
  return Promise.resolve('Nothing to upload')
}

export default uploadPhotos
