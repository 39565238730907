import React, { useState, useEffect } from 'react'
import './TaskTermStatus.sass'
import { connect } from 'react-redux'
import formatExpirationDate from '../../../functions/formatExpirationDate'
import * as actions from '../../../../../store/actions/tasksActions'

import Icon from '../../../../../../../components/UI/Icon/Icon'

function TaskTermStatus({ startDate, endDate, isFinished, showTaskResultsForm, canBeEdit }) {
  const [finishTask, setFinishTask] = useState(isFinished)

  useEffect(() => {
    setFinishTask(isFinished)
  }, [isFinished])

  const updateTaskStatus = () => {
    if (!canBeEdit) return

    if (!finishTask) return showTaskResultsForm({ status: true, comment: null })
    return showTaskResultsForm({ status: false, comment: null })
  }

  return (
    <>
      <div className="Task-Term" onClick={updateTaskStatus}>
        <Icon name={finishTask ? 'check-square' : 'square'} size="12" />
        <span>{formatExpirationDate({ startDate, endDate })}</span>
      </div>
      {finishTask && <div className="Task-Term Task-Term_theme_done">Выполнено</div>}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showTaskResultsForm: (payload) => dispatch(actions.tasksShowResultsForm(payload)),
})

export default connect(null, mapDispatchToProps)(TaskTermStatus)
