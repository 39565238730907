import { omit } from 'lodash'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import scrollTop from '../../../../utilities/scrollTop'
import { firebase } from '../../../../config/firebase'
import { logError } from '../../../../utilities/db/logError'

const onSubmitProfile = ({ form, user, setStatusMessage, setShowErrors, setIsLoading, setNewPhoneNumber }) => {
  if (!isFormValid({ form })) {
    scrollTop()
    setStatusMessage({
      show: true,
      type: 'fail',
      message: 'Пожалуйста, заполните обязательные поля',
      closeAfter: 5000,
    })

    return setShowErrors(true)
  }

  if (form.newPassword.value) {
    if (form.newPassword.value !== form.confirmPassword.value) {
      scrollTop()
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Новый пароль не совпадает с проверочным',
        closeAfter: 5000,
      })
    }
    if (form.newPassword.value.length < 6) {
      scrollTop()
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Новый пароль должен быть длинной не менее 6 символов',
        closeAfter: 5000,
      })
    }
    if (form.password.value !== user.password) {
      scrollTop()
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Текущий пароль указан неверно',
        closeAfter: 5000,
      })
    }
  }

  setShowErrors(false)
  setIsLoading(true)
  const values = omit(getFormValues({ form }), ['password', 'newPassword', 'confirmPassword', 'phone'])

  updateDoc({
    path: 'users',
    docId: user.uid,
    data: { ...values, ...(form.newPassword.value ? { password: form.newPassword.value } : {}), updated: new Date() },
  })
    .then(() => {
      if (form.newPassword.value) {
        return firebase
          .auth()
          .currentUser.updatePassword(form.newPassword.value)
          .catch((e) => logError({ error: e }))
      }
    })
    .finally(() => {
      if (form.phone.value.metadata.number !== user.phone.metadata.number) {
        setNewPhoneNumber(form.phone.value)
      }
      setIsLoading(false)
      scrollTop()
      return setStatusMessage({
        show: true,
        type: 'success',
        message: 'Профиль успешно сохранен',
        closeAfter: 5000,
      })
    })
}

export default onSubmitProfile
