import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'

export function NewFilePopUpForm({ withoutFile = false }) {
  this.title = {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      required: true,
      label: 'Введите название файла',
    },
    render: getFieldRenderObject(),
  }
  if (!withoutFile) {
    this.file = {
      field: {
        fieldId: 'file',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл',
        icon: 'ellipsis-v',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}
