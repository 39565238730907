import React from 'react'
import './Button.sass'

import Spinner from '../Spinner/Spinner'

const Button = React.forwardRef((props, ref) => {
  const classes = ['Button']

  if (props.type === 'active') classes.push('Button_theme_active')
  if (props.type === 'noty') classes.push('Button_theme_noty')
  if (props.type === 'link') classes.push(`Button_theme_link${props.theme && `_${props.theme}`}`)
  if (props.class) classes.push(props.class)

  return (
    <button
      ref={ref}
      style={props.style}
      className={classes.join(' ')}
      onClick={props.clicked}
      onDoubleClick={(event) => event.preventDefault()}
      disabled={props.loading || props.disabled ? 'disabled' : null}
    >
      {props.loading ? <Spinner type="button" /> : props.children}
    </button>
  )
})

export default Button
