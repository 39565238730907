import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'

import NavPanel from '../../../../../ui/NavPanel/NavPanel'
import ApiKeyForm from './ApiKeyForm'
import SubscriptionsSettings from './SubscriptionsSettings'

function ExtensionUi({ accountId, extensionId }) {
  const [activeTab, setActiveTab] = useState('apiKey')
  const nav = [
    {
      id: 'apiKey',
      title: 'API Ключ',
      component: <ApiKeyForm accountId={accountId} extensionId={extensionId} />,
      onClick: () => setActiveTab('apiKey'),
    },
    {
      id: 'settings',
      title: 'Подключение номеров',
      component: <SubscriptionsSettings accountId={accountId} extensionId={extensionId} />,
      onClick: () => setActiveTab('settings'),
    },
  ]

  return (
    <div className="zb6aPxoNMXsPKsVZfT88">
      <NavPanel
        blocks={[
          {
            items: nav.map((i) => {
              i.isActive = i.id === activeTab
              return i
            }),
          },
        ]}
      />

      <div className="Content">{find(nav, ['id', activeTab]).component}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(ExtensionUi)
