const createNavBlockItem = ({ icon, title, type = 'navlink', onClick, path, isActive }) => ({
  title,
  icon,
  type,
  path,
  isActive,
  onClick,
})

export default createNavBlockItem
