import { getYear } from 'date-fns'

const isYearEqual = (dateLeft, dateRight) => {
  const date1 = dateLeft ? new Date(dateLeft.seconds * 1000) : new Date(1970, 0, 1)
  const date2 = dateRight ? new Date(dateRight.seconds * 1000) : new Date(1970, 0, 1)

  return getYear(date1) === getYear(date2) && true
}

export default isYearEqual
