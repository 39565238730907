import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Contactlist from './scenes/Contactlist/Contactlist'
import ContactsManager from './scenes/Contactlist/ContactsManager/ContactsManager'
import rootPath from '../../appTree/rootPath'

function Contacts({ permissions, options, subsections, accountId }) {
  return (
    <Switch>
      <Route
        path={[
          `${rootPath(accountId)}/contacts/persons/list`,
          `${rootPath(accountId)}/contacts/persons/list/page/:page`,
        ]}
        exact
        render={() => <Contactlist permissions={permissions} options={options} subsections={subsections} />}
      />
      <Route
        path={[
          ...(permissions.add ? [`${rootPath(accountId)}/contacts/persons/list/add`] : []),
          `${rootPath(accountId)}/contacts/persons/list/:id/edit`,
        ]}
        exact
        render={() => (
          <ContactsManager
            permissions={permissions}
            options={options}
            closeLink={`${rootPath(accountId)}/contacts/persons/list`}
          />
        )}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Contacts)
