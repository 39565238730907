import React, { useState, useEffect } from 'react'
import './AudioPlayer.sass'
import Sound from 'react-sound'
import ActionButton from './buttons/ActionButton'
import { unactivePlayButton, playButton, downloadButton, pauseButton } from './buttons/buttonTypes'
import TrackBar from './TrackBar/TrackBar'

import { getFileWithInternalLink } from './functions'

function AudioPlayer({ audio: { type, link }, noDownloadButton, customButtons }) {
  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED)
  const [audio, setAudio] = useState(null)

  useEffect(() => {
    switch (type) {
      case 'internalLink':
        getFileWithInternalLink(link, setAudio)
        break

      default:
        break
    }
  }, [])

  const buttons = {
    playButton: {
      ...playButton,
      clicked: () => playSound(),
    },
    pauseButton: {
      ...pauseButton,
      clicked: () => pauseSound(),
    },
  }

  const [activeControllButton, setActiveControllButton] = useState(buttons.playButton)

  const onSoundLoading = ({ bytesLoaded, bytesTotal, duration }) => {
    setDuration(duration)
  }

  const playSound = () => {
    setPlayStatus(Sound.status.PLAYING)
    setActiveControllButton(buttons.pauseButton)
  }

  const pauseSound = () => {
    setPlayStatus(Sound.status.PAUSED)
    setActiveControllButton(buttons.playButton)
  }

  const onFinishedPlaying = () => {
    setPlayStatus(Sound.status.STOPPED)
    setActiveControllButton(buttons.playButton)
  }

  return (
    <>
      <Sound
        url={audio}
        playStatus={playStatus}
        onLoading={onSoundLoading}
        position={position}
        onPlaying={({ position }) => setPosition(position)}
        autoLoad
        onFinishedPlaying={onFinishedPlaying}
      />
      <div className="AudioPlayer">
        <ActionButton {...activeControllButton} />
        <div className="AudioPlayer-Timer">{millisToMinutesAndSeconds(position)}</div>
        <TrackBar position={position} duration={duration} playFromPos={(newPos) => setPosition(newPos)} />
        <div className="AudioPlayer-Timer">{millisToMinutesAndSeconds(duration)}</div>
        {link !== 'error' && !noDownloadButton && (
          <ActionButton {...downloadButton} clicked={() => window.open(audio, '_blank')} />
        )}
        {/* {customButtons && <div className="AudioPlayer-CustomButtons">{customButtons}</div>} */}
      </div>
    </>
  )
}

function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)
  return seconds == 60 ? `${minutes + 1}:00` : `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export default AudioPlayer
