import { orderBy } from 'lodash'
import getDoc from '../db/getDoc'

export const getMembersOptions = ({ accountId }) =>
  getDoc({ path: 'accounts', docId: accountId }).then(({ members }) =>
    orderBy(members, ['lastName', 'name'], ['asc']).map((m) => ({
      value: m.userId,
      label: `${m.lastName} ${m.name} ${m.middleName}`,
    })),
  )
