import fetchMemberByUserId from '../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'

const fetchTaskResponsible = ({ accountId, responsible }) => {
  if (!responsible) {
    console.log('function*getResponsible -> onResponsible in task')
  }

  if (responsible[0] === 'all') return [{ responsibleId: 'all' }]

  return Promise.all(responsible.map((userId) => fetchMemberByUserId({ accountId, userId }))).then(
    (fetchedResponsibles) =>
      fetchedResponsibles.map((r) => ({
        responsibleId: r.userId,
        lastName: r.lastName,
        name: r.name,
        middleName: r.middleName,
        avatar: r.avatar.publicUrl,
      })),
  )
}

export default fetchTaskResponsible
