import React, { useState, useEffect } from 'react'
import './EstateParamsManager.sass'
import { find, merge, isEmpty, isArray, flattenDeep, isObject, isString, isBoolean, isNull } from 'lodash'
import { connect } from 'react-redux'
import SceneContent from '../../../../../../../components/UI/SceneContent/SceneContent'
import Skeleton from '../../../../../../../components/UI/Skeleton/Skeleton'
import promiseAllValues from '../../../../../../../utilities/promiseAllValues'
import getCollection from '../../../../../../../utilities/db/getCollection'
import convertParamConfigToFormField from './functions/convertParamConfigToFormField'
import { createForm } from '../../../../../../../utilities/newforms'
import FormRender from '../../../../../../../utilities/newforms/render/FormRender'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import usePrevious from '../../../../../../../hooks/usePrevious'

function EstateParamsManager({
  accountId,
  estateCategoryId,
  estateTypeId,
  formData,
  syncState = () => null,
  pushRequiredParams = () => null,
  useFieldsRange = false,
  withContainer = false,
  showErrors,
  permissions,
}) {
  const [form, setForm] = useState(null)
  const [sections, setSections] = useState(null)

  const prevFormData = usePrevious(formData)
  const prevEstateCategoryId = usePrevious(estateCategoryId)
  const prevEstateTypeId = usePrevious(estateTypeId)

  useEffect(() => {
    if (
      estateCategoryId &&
      estateTypeId &&
      (prevEstateCategoryId !== estateCategoryId ||
        prevEstateTypeId !== estateTypeId ||
        (isNull(prevFormData) && !isEmpty(formData)))
    ) {
      promiseAllValues({
        params: getCollection({
          accountId,
          path: 'config/estate/params',
          docIdName: 'paramId',
          orderBy: { fieldPath: 'position', orderDir: 'asc' },
        }),
        paramsCategories: getCollection({
          accountId,
          path: 'config/estate/paramsCategories',
          docIdName: 'paramCategoryId',
          orderBy: { fieldPath: 'position', orderDir: 'asc' },
        }),
      }).then((results) => {
        const filteredCategories = results.paramsCategories.filter((c) =>
          find(
            c.estateTypes,
            (el) =>
              el.estateCategoryId === estateCategoryId && el.estateTypeId === estateTypeId && el.status !== 'hide',
          ),
        )
        const formPattern = {}

        const sects = filteredCategories.map((c) => {
          const paramsInCategory = flattenDeep(
            results.params
              .filter(
                (p) =>
                  find(
                    p.estateTypes,
                    (el) =>
                      el.estateCategoryId === estateCategoryId &&
                      el.estateTypeId === estateTypeId &&
                      el.status !== 'hide',
                  ) && p.categoryId === c.paramCategoryId,
              )
              .map((p) => {
                const paramConfig = convertParamConfigToFormField(
                  p,
                  find(
                    p.estateTypes,
                    (el) => el.estateCategoryId === estateCategoryId && el.estateTypeId === estateTypeId,
                  ).status,
                  useFieldsRange,
                  formData,
                )
                merge(formPattern, paramConfig)

                return Object.values(paramConfig).map((v) => v.field.fieldId)
              }),
          )
          return {
            fields: paramsInCategory,
            title: c.label,
          }
        })

        setSections(sects)
        setForm(createForm({ formPattern }))
      })
    }
  }, [estateCategoryId, estateTypeId, formData])

  useEffect(() => {
    if (!isEmpty(form)) {
      const values = getFormValues({ form })
      const formToPush = []
      Object.entries(values).forEach(([k, v]) => {
        if (
          (isArray(v) && !isEmpty(v)) ||
          (isString(v) && v) ||
          (isObject(v) && !isAllPropsEmpty(v)) ||
          (isBoolean(v) && v)
        )
          formToPush.push({ paramId: k, value: v })
      })
      syncState(formToPush)
      const requiredParams = Object.entries(form).filter(([k, v]) => !v.valid)
      pushRequiredParams(requiredParams.filter((p) => !find(formToPush, ['paramId', p.paramId])))
    } else {
      pushRequiredParams([])
    }
  }, [form])

  function isAllPropsEmpty(obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') return false
    }
    return true
  }

  const content =
    estateCategoryId && estateTypeId ? (
      !sections || !form ? (
        <LoaderSkeleton />
      ) : isEmpty(sections) || isEmpty(form) ? (
        <p className="EPM-InitMessage">Нет параметров объекта</p>
      ) : (
        <>
          <div className="EstateParamsManager">
            <FormRender
              sections={sections}
              form={form}
              setForm={setForm}
              errors={showErrors}
              permissions={permissions}
            />
          </div>
        </>
      )
    ) : (
      <div className="EPM-InitMessage">Выберите категорию и тип недвижимости</div>
    )
  return withContainer ? <SceneContent title="Параметры объекта">{content}</SceneContent> : content
}

function LoaderSkeleton() {
  const fields = []
  for (let index = 0; index < 8; index++) {
    fields.push(<Skeleton className="EPM-LoaderSkeletonField" />)
  }

  return (
    <div className="EPM-LoaderSkeleton">
      <Skeleton className="EPM-LoaderSkeletonTitle" width={200} />
      <div className="EPM-LoaderSkeletonGrid">{fields}</div>
      <Skeleton className="EPM-LoaderSkeletonTitle" width={140} />
      <div className="EPM-LoaderSkeletonGrid">{fields}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(EstateParamsManager)
