import React from 'react'
import './CrmRender.sass'
import render from './render.svg'
import mobileRender from './mobileRender.svg'
import useWindowSize from '../../../hooks/useWindowSize'

function CrmRender() {
  const { width } = useWindowSize()

  return (
    <div className="Site-CrmRender">
      <img src={width < 768 ? mobileRender : render} alt="CardBox" />
    </div>
  )
}

export default CrmRender
