import { find, cloneDeep, findIndex, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import getDoc from '../../../../../../functions/utilities/db/getDoc'
import rootDbPath from '../../../../../../functions/utilities/db/rootDbPath'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import { db, functions } from '../../../../../config/firebase'
import Button from '../../../../../ui/Button/Button'
import StatusMessage, { initStatusMessage } from '../../../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../../../utilities/newforms'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'
import useSystemFieldsMethods from '../../../../../utilities/newforms/useSystemFieldsMethods'
import FieldRender from '../../../../../utilities/newforms/render/FieldRender'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import setDoc from '../../../../../../functions/utilities/db/setDoc'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import syncForm from '../../../../../utilities/newforms/changed/syncForm'

function SubscriptionsSettings({ accountId, extensionId }) {
  const [numbers, setNumbers] = useState([])
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const listener = db
      .collection(`${rootDbPath(accountId)}/extensions/${extensionId}/subscriptions`)
      .onSnapshot((snap) => {
        const numbersClone = cloneDeep(numbers)

        snap.forEach((d) => {
          if (!find(numbersClone, ['phone', d.phone])) {
            numbersClone.push(d.data())
          } else {
            const index = findIndex(numbersClone, ['phone', d.phone])
            numbersClone.splice(index, 1, d.data())
          }
        })
        setNumbers(numbersClone)
      })

    return () => listener()
  }, [])

  const getNumbers = () => {
    setIsLoading(true)
    getDoc({ db, accountId, path: 'extensions', docId: extensionId }).then((eData) => {
      if (!eData.apiKey) {
        setIsLoading(false)
        return setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Невозможно получить список номеров. Пожалуйста, введите API ключ доступа к "Облачной АТС Билайн"',
        })
      }

      const func = functions.httpsCallable('extensionsOnCall')
      return func({
        extensionId,
        funcPath: 'api/functions/getAbonents',
        payload: { token: eData.apiKey },
      })
        .then((result) => {
          const numbersClone = cloneDeep(numbers)
          result.data.forEach((n) => {
            if (!find(numbersClone, ['phone', n.phone])) numbersClone.push(n)
          })
          setNumbers(numbersClone)
        })
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <div className="SubscriptionSettings">
      <p className="Title">Подключение номеров</p>
      {statusMessage.show && <StatusMessage type={statusMessage.type} message={statusMessage.message} />}
      {!numbers ? (
        <Spinner type="popup" />
      ) : isEmpty(numbers) ? (
        <p className="NoInfoRow">Нет номеров для подключения</p>
      ) : (
        numbers.map((n) => (
          <NumberSubscription key={n.phone} extensionId={extensionId} accountId={accountId} data={n} />
        ))
      )}
      <Button
        title="Обновить список"
        fill="accent"
        theme="solid"
        size={28}
        onClick={getNumbers}
        isLoading={isLoading}
      />
    </div>
  )
}

function NumberSubscription({ extensionId, accountId, data }) {
  const methods = useSystemFieldsMethods({ accountId })

  const [form, setForm] = useState(
    createForm({
      formPattern: {
        isActive: {
          field: {
            fieldId: 'isActive',
            fieldType: 'checkbox',
            label: 'Актив.',
            value: true,
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        phone: {
          field: {
            fieldId: 'phone',
            fieldType: 'input',
            label: 'Телефон.',
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        responsible: {
          field: {
            fieldId: 'responsible',
            fieldType: 'select',
            label: 'Ответственный',
            required: true,
            getOptions: 'getMembersOptions',
            systemField: true,
          },
          render: getFieldRenderObject(),
        },
        subscriptionId: {
          field: {
            fieldId: 'subscriptionId',
            value: null,
            required: false,
            systemField: true,
          },
          render: getFieldRenderObject({ isSystem: true }),
        },
        expires: {
          field: {
            fieldId: 'expires',
            value: 0,
            required: false,
            systemField: true,
          },
          render: getFieldRenderObject({ isSystem: true }),
        },
      },
      formData: data,
      methods,
    }),
  )

  useEffect(() => {
    if (data) {
      const formClone = cloneDeep(form)
      formClone.subscriptionId.values = data.subscriptionId
      formClone.expires.values = data.expires
      setForm(formClone)
    }
  }, [data])

  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) return setShowErrors(true)

    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })

    const func = functions.httpsCallable('extensionsOnCall')

    getDoc({ db, accountId, path: 'extensions', docId: extensionId }).then((eData) => {
      if (values.isActive) {
        return func({
          funcPath: 'api/functions/getSubscription',
          extensionId,
          payload: {
            token: eData.apiKey,
            subscriptionId: values.subscriptionId,
          },
        }).then((result) => {
          if (result.data.errorCode) return putSubscription({ accountId, apiKey: eData.apiKey, values })
          return setDoc({
            db,
            accountId,
            path: `extensions/${extensionId}/subscriptions`,
            docId: values.phone,
            data: values,
          }).finally(() => setIsLoading(false))
        })
      }
      return func({
        funcPath: 'api/functions/deleteSubscription',
        extensionId,
        payload: {
          token: eData.apiKey,
          subscriptionId: values.subscriptionId,
        },
      }).then(() =>
        setDoc({
          db,
          accountId,
          path: `extensions/${extensionId}/subscriptions`,
          docId: values.phone,
          data: { ...values, subscriptionId: null, expires: 0 },
        }).finally(() => setIsLoading(false)),
      )
    })
  }

  const putSubscription = ({ accountId, apiKey, values }) => {
    const func = functions.httpsCallable('extensionsOnCall')

    return func({
      funcPath: 'api/functions/putSubscription',
      extensionId,
      payload: {
        token: apiKey,
        phone: values.phone,
        accountId,
      },
    }).then((result) => {
      if (result.data.errorCode) {
        return setDoc({
          db,
          accountId,
          path: `extensions/${extensionId}/subscriptions`,
          docId: values.phone,
          data: { ...values, subscriptionId: null, expires: 0 },
        }).finally(() => setIsLoading(false))
      }
      setDoc({
        db,
        accountId,
        path: `extensions/${extensionId}/subscriptions`,
        docId: values.phone,
        data: { ...values, ...result.data },
      }).finally(() => setIsLoading(false))
    })
  }

  const castSubscriptionStatus = () => {
    if (!data.subscriptionId) return <span className="Subscription_status_not-active">не активна</span>
    return <span className="Subscription_status_active">активна</span>
  }

  return (
    <div className="NumberSubscription">
      <p className="NumberSubscription-Status">Статус подписки: {castSubscriptionStatus()}</p>
      <FieldRender field={form.isActive} form={form} setForm={setForm} />
      <span className="NumberSubscription-Phone">{data.phone}</span>
      <FieldRender field={form.responsible} form={form} setForm={setForm} showErrors={showErrors} />
      <Button isLoading={isLoading} theme="solid" size={28} icon="save" fill="accent" onClick={onSubmit} />
    </div>
  )
}

export default SubscriptionsSettings
