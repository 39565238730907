import React, { useState, useEffect, useRef } from 'react'
import './Select.sass'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Icon from '../../../Icon/Icon'
import DropdownBox from '../../../DropdownBox/DropdownBox'
import Spinner from '../../../Spinner/Spinner'
import { usePopUp } from '../../../../../hooks/usePopUp'
import useFilterObjValues from '../../../../../hooks/useFilterObjValues'

function Select({
  fieldConfig: { label, placeholder, icon, iconFunc, iconClass, useDropValueButton },
  extraClass,
  beforeContent,
  value,
  setPropAsLabel,
  isSearchable,
  multiOptions,
  getOptions,
  isChangable,
  ddbStyles,
  changed,
  errors,
  dropValue,
  validation,
}) {
  const [inputLabel, setInputLabel] = useState('')
  const fieldRef = useRef()
  const ddBRef = useRef()
  const arrowRef = useRef()
  const [isFocused, setIsFocused] = usePopUp(fieldRef, ddBRef, isChangable, false, arrowRef)
  const {
    options,
    inputLabel: fetchedLabel,
    emptyMessage,
  } = useSelectOptions(getOptions, value, setPropAsLabel, isFocused)
  const [filteredOptions, setSearchFieldVal] = useFilterObjValues(options)

  useEffect(() => {
    setInputLabel(fetchedLabel)
  }, [fetchedLabel])

  const optionClicked = (option) => {
    const { value: optionValue } = option
    const standAloneOptions = options.filter((o) => o.standAloneOption)
    const event = { target: { value: optionValue }, standAloneOption: option.standAloneOption, standAloneOptions }
    changed(event)
    setInputLabel(option[setPropAsLabel])
    setIsFocused(false)
  }

  return (
    <div
      className={[
        extraClass,
        ...(isFocused ? ['Field-Select_Focused'] : []),
        ...(!isChangable ? ['Field-ReadOnly'] : []),
      ].join(' ')}
    >
      {beforeContent && beforeContent}
      {multiOptions ? (
        <div className={`Select SelectTags-Container ${isFocused && 'Select_theme_focused'}`} ref={fieldRef}>
          <div className="SelectOptionTag-Container">
            {!isEmpty(inputLabel) && isArray(inputLabel) && (
              <>
                <span className="SelectOption-Tag">{inputLabel[0]}</span>
                {inputLabel.length > 1 && (
                  <span className="SelectOption-Tag SelectOption-CircleTag">+{inputLabel.length - 1}</span>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <input
          ref={fieldRef}
          type="text"
          placeholder={placeholder}
          readOnly
          value={value ? inputLabel : ''}
          className="Select"
          onFocus={() => (isChangable ? setIsFocused(true) : null)}
        />
      )}
      <input type="hidden" value={value} onChange={() => changed()} />
      <span
        ref={arrowRef}
        role="button"
        onClick={useDropValueButton && !isEmpty(value) ? dropValue : iconFunc || (() => setIsFocused(!isFocused))}
        className={['Field-Icon', 'Field-SelectAngle-Icon', ...(iconClass ? [iconClass] : [])].join(' ')}
        tabIndex={0}
      >
        {icon ? (
          <Icon name={icon} />
        ) : isChangable ? (
          useDropValueButton && !isEmpty(value) ? (
            <Icon name="times" />
          ) : (
            <Icon name="angle-down" />
          )
        ) : (
          <Icon name="lock-alt" />
        )}
      </span>
      {isFocused && isChangable && (
        <DropdownBox
          ref={ddBRef}
          isSearchable={isSearchable}
          pushSearchVal={setSearchFieldVal}
          styles={{ top: '43px', ...ddbStyles }}
        >
          {filteredOptions ? (
            filteredOptions.map((option) => {
              const deniedOptionKeys = ['rawData', 'standAloneOption', 'value']
              return (
                <li
                  key={option.value}
                  className={`DropdownBox-Element ${
                    !multiOptions && option.value === value && 'SelectOption-Element_theme_active'
                  }`}
                  onClick={() => optionClicked(option)}
                >
                  {Object.entries(option)
                    .filter(([k, v]) => !deniedOptionKeys.includes(k))
                    .map(([k, v]) => {
                      const content = k === 'label' ? option.label : <div className="SubLabel-Gray">{v}</div>

                      return (
                        <div className="SelectOption-Element">
                          {content}
                          {multiOptions && value.includes(option.value) && <Icon name="check" />}
                        </div>
                      )
                    })}
                </li>
              )
            })
          ) : emptyMessage ? (
            <p className="EmptyMessage">{emptyMessage}</p>
          ) : (
            <Spinner type="popup" />
          )}
        </DropdownBox>
      )}
      <span className="Field-Bar" />
      <label>
        {label}
        {validation.required && (
          <span className="Field-RequiredLabel">
            <Icon name="circle" weight="solid" />
          </span>
        )}
      </label>
      {errors && <div className="Field-Error">{errors}</div>}
    </div>
  )
}

Select.defaultProps = {
  beforeContent: '',
  extraClass: '',
  ddbStyles: {},
  setPropAsLabel: 'label',
  isChangable: true,
  isSearchable: false,
  errors: '',
}

export const useSelectOptions = (getOptions, value, setPropAsLabel = 'label', isFocused = false) => {
  const [options, setOptions] = useState(null)
  const [inputLabel, setInputLabel] = useState('')
  const [emptyMessage, setEmptyMessage] = useState(null)

  useEffect(() => {
    getFieldOptions()
  }, [])

  useEffect(() => {
    getFieldOptions()
  }, [isFocused, getOptions, value])

  const getFieldOptions = () => {
    if (typeof getOptions === 'function') {
      loadOptions()
    } else {
      setDefaultOptions()
    }
  }

  const loadOptions = () => {
    getOptions().then((opt) => {
      let newLabel = 'Недоступное значение'

      if (isArray(opt) && !isEmpty(opt)) {
        if (isArray(value)) {
          newLabel = []
          value.forEach((v) => {
            const option = find(opt, ['value', v])
            if (option) {
              newLabel.push(option[setPropAsLabel])
            }
          })
        } else {
          const option = find(opt, ['value', value])
          if (option) {
            newLabel = option[setPropAsLabel]
          }
        }

        setOptions(opt)
      } else {
        setOptions(null)
        setEmptyMessage(opt)
      }

      setInputLabel(newLabel)
    })
  }

  const setDefaultOptions = () => {
    let newLabel = ''

    if (isArray(value)) {
      newLabel = []
      value.forEach((v) => {
        const obj = find(getOptions, ['value', v])
        if (obj) {
          newLabel.push(obj[setPropAsLabel])
        }
      })
    } else {
      const obj = find(getOptions, ['value', value])
      if (obj) {
        newLabel = obj[setPropAsLabel]
      }
    }

    setInputLabel(newLabel)
    setOptions(getOptions)
  }
  return { options, inputLabel, emptyMessage }
}

export default Select
