import { find, omit } from 'lodash'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { uploadFile } from '../../../../../../../utilities/storage/uploadFiles'
import getDoc from '../../../../../../../utilities/db/getDoc'

const uploadMemberAvatar = ({ accountId, file, userId }) => {
  if (file.needUpload) {
    const storagePath = `/accounts/${accountId}/members/${userId}/avatar`

    return uploadFile({ fileObject: file, storagePath }).then(({ publicUrl }) => {
      file.publicUrl = publicUrl
      file.needUpload = false
      file.storagePath = storagePath
      return getDoc({ path: 'accounts', docId: accountId }).then((accountData) => {
        const { members } = accountData

        const user = find(members, ['userId', userId])
        if (user) {
          user.avatar = omit(file, ['needUpload', 'file'])
          return updateDoc({ path: 'accounts', docId: accountId, data: { members } })
        }
      })
    })
  }
  return Promise.resolve('Nothing to upload')
}

export default uploadMemberAvatar
