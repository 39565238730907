import React from 'react'
import './DataCounter.sass'
import NumberFormat from 'react-number-format'
import WidgetTile from '../WidgetTile/WidgetTile'

function DataCounter({ title, counters = [], isLoading, counterPeriod }) {
  return (
    <WidgetTile title={title} isLoading={isLoading} counterPeriod={counterPeriod}>
      <div className="DataCounters">
        <div className="CountersLine">
          {counters &&
            counters.map((c, index) => (
              <span
                key={`DataCounter-Counter-${index}`}
                className={['DataCounters-Counter', ...(c.theme ? [`DataCounters-Counter_theme_${c.theme}`] : [])].join(
                  ' ',
                )}
                style={c.style}
              >
                <NumberFormat
                  value={c.value}
                  displayType="text"
                  thousandSeparator={' '}
                  suffix={counters.length - 1 === index ? '' : '/'}
                />
              </span>
            ))}
        </div>
        <div className="CountersLegend">
          {counters &&
            counters.map(
              (c, index) =>
                c.tag && (
                  <p
                    key={`DataCounter-${index}-LegendMark`}
                    className={[
                      'DataCounters-LegendLine',
                      ...(c.theme ? [`DataCounters-LegendLine_theme_${c.theme}`] : []),
                    ].join(' ')}
                  >
                    <span className="LegendMark" />
                    {c.tag}
                  </p>
                ),
            )}
        </div>
      </div>
    </WidgetTile>
  )
}

export default DataCounter
