import React from 'react'

import { find } from 'lodash'
import renderSale from './events/renderSale'
import renderComment from './events/renderComment'
import RenderTask from './events/renderTask'
import RenderCall from './events/renderCall'

import UserAvatar from '../../components/UserAvatar/UserAvatar'
import { getDateWithDaysAndTime } from '../../utilities/dates'

export default function HistoryEvent(props) {
  const { eventsTypeSet } = props
  const { authors } = props
  const { type, subtype } = props
  const { fieldLabel, newValue, oldValue } = props
  const { objId } = props
  const { form } = props

  const { message } = props
  const { attachableData } = props

  const { showAuthorNameAndAvatar, uid } = props
  const creationDate = props.created.at

  const getEventAuthor = (authorId) => {
    const authorData = find(authors, ['userId', authorId])

    if (authorData) {
      return {
        avatar: authorData.avatar.publicUrl,
        name: `${authorData.lastName} ${authorData.name} ${authorData.middleName}`,
      }
    }

    return {
      avatar: '',
      name: 'CardBox API',
    }
  }

  let eventContent

  switch (type) {
    case 'comment':
      eventContent = renderComment(message)
      break
    case 'task':
      eventContent = <RenderTask eventId={props.eventId} attachableData={attachableData} taskId={objId} uid={uid} />
      break
    case 'sale':
      eventContent = renderSale()
      break
    case 'call':
      eventContent = <RenderCall {...props} />
      break
    default:
      eventContent = eventsTypeSet(objId, subtype, fieldLabel, newValue, oldValue, form)
      break
  }

  const eventAuthor = getEventAuthor(props.created.by)

  return (
    <>
      <div className={`History-EventContainer ${!showAuthorNameAndAvatar && `History-EventContainer_fixed_margin`}`}>
        {showAuthorNameAndAvatar ? (
          <div className="UserAvatar">
            <UserAvatar size="24" url={eventAuthor.avatar} />
          </div>
        ) : (
          <div className="NoUserAvatar" />
        )}
        <div className="UserCredentials">
          {showAuthorNameAndAvatar && <div className="UserName">{eventAuthor.name}</div>}
          <div className="EventDate">{getDateWithDaysAndTime(creationDate)}</div>
        </div>
        {eventContent}
      </div>
    </>
  )
}
