import React from 'react'
import './InfoCard.sass'
import OptionsButton from '../../../../../../../ui/OptionsButton/OptionsButton'
import Skeleton from '../../../../../../../components/UI/Skeleton/Skeleton'
import declOfNum from '../../../../../../../utilities/declOfNum'

function InfoCard({ isActive, onClick, options, title, description, mCounter }) {
  return (
    <div onClick={onClick} className={['InfoCard', ...(isActive ? ['InfoCard_theme_active'] : [])].join(' ')}>
      <div className="InfoCard-Header">
        <span className="InfoCard-Employees">
          {`${mCounter} ${declOfNum(mCounter, ['сотрудник', 'сотрудника', 'сотрудников'])}`}
        </span>
        <div className="InfoCard-Header-ButtonsContainer">
          <OptionsButton options={options} />
        </div>
      </div>
      <p className="InfoCard-Title">{title}</p>
      {description && (
        <>
          <p className="InfoCard-Occupation">Должность</p>
          <p className="InfoCard-OccupationDescription">{description}</p>
        </>
      )}
    </div>
  )
}

export const InfoCardSkeleton = () => (
  <div className="InfoCard InfoCard-Skeleton">
    <div className="InfoCard-Header">
      <Skeleton width={80} />
      <div className="InfoCard-Header-ButtonsContainer">
        <Skeleton circle width={24} height={24} />
      </div>
    </div>
    <p className="InfoCard-Title">
      <Skeleton width={60} />
    </p>
    <p className="InfoCard-Occupation">
      <Skeleton width={120} />
    </p>
    <p className="InfoCard-OccupationDescription">
      <Skeleton width={80} />
    </p>
  </div>
)

export default InfoCard
