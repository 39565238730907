import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SceneContent from '../../../../components/UI/SceneContent/SceneContent'
import { addButton } from '../../../../components/UI/SceneContent/SceneContentHeader/buttons'
import Newslist from './Newslist/Newslist'
import rootPath from '../../../../appTree/rootPath'
import NewsManager from './NewsManager/NewsManager'

function News({ accountId }) {
  return (
    <Switch>
      <Route
        path={[`${rootPath(accountId)}/settings/site/news`, `${rootPath(accountId)}/settings/site/news/page/:page`]}
        exact
        render={() => <Newslist />}
      />
      <Route
        path={[`${rootPath(accountId)}/settings/site/news/add`, `${rootPath(accountId)}/settings/site/news/:id`]}
        exact
        render={() => <NewsManager closeLink={`${rootPath(accountId)}/settings/site/news`} />}
      />
    </Switch>
  )
}

export default News
