import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { db } from '../../../../../../../config/firebase'
import * as actions from '../../../../../store/actions/tasksActions'
import { getDateWithDaysAndTime } from '../../../../../../../utilities/dates'
import UserAvatar from '../../../../../../../components/UserAvatar/UserAvatar'
import Icon from '../../../../../../../components/UI/Icon/Icon'

function Comment({ accountId, taskStatus, comment, user, showResultsForm, submitCommentAsTaskResult }) {
  const [author, setAuthor] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [castCommentAsResult, setCastCommentAsResult] = useState(taskStatus === 'done')

  useEffect(() => {
    if (submitCommentAsTaskResult === comment.commentId) return setCastCommentAsResult(true)
    setCastCommentAsResult(false)
  }, [submitCommentAsTaskResult])

  useEffect(
    () =>
      db
        .collection('users')
        .doc(comment.created.by)
        .onSnapshot((authorData) => {
          setAuthor({
            uid: authorData.id,
            ...authorData.data(),
          })
          setIsLoading(false)
        }),
    [],
  )

  const commentClasses = ['Comment']

  if (author && user.uid === author.uid) commentClasses.push('Comment_theme_highlighted')

  return isLoading ? (
    ''
  ) : (
    <div className="Task-Comment">
      <UserAvatar size="24" url={author.avatar.publicUrl} />
      <div className={commentClasses.join(' ')}>
        <div className="Comment-Name">{`${author.lastName} ${author.name}`}</div>
        <div className="Comment-Date">
          {getDateWithDaysAndTime(comment.updated.at)}{' '}
          {comment.isTaskResult && <span className="Comment_theme_result">Результат</span>}
        </div>
        <div className="Comment-Text">
          <div className="Text-Container">{comment.text}</div>
          <div className="Text-Controlls">
            {taskStatus !== 'done' && (
              <button
                onClick={() => {
                  showResultsForm({ status: true, comment: comment.commentId })
                  setCastCommentAsResult(true)
                }}
              >
                <Icon name="flag" weight={castCommentAsResult ? 'solid' : 'regular'} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  submitCommentAsTaskResult: state.tasks.submitCommentAsTaskResult,
})

const mapDispatchToProps = (dispatch) => ({
  showResultsForm: (payload) => dispatch(actions.tasksShowResultsForm(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
