import getValuesFromPattern from '../../utilities/newforms/getValuesFromPattern'
import { setDoc } from '../../utilities/db/setDoc'
import dealObject from '../../modules/Deals/scenes/Dealslist/DealManager/dealDbForm'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'

const addDealsData = (accountId) => {
  const deals = [
    {
      docId: 'default',
      data: getValuesFromPattern({
        formData: {
          title: 'Заказ товара',
          comission: '20000',
          need: 'default',
          contact: [
            {
              type: 'contact',
              id: 'default',
            },
          ],
          responsible: 'O7XN6hRIRjZLqREQO5ljwlQiiqm1',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: dealObject,
      }),
    },
    {
      docId: 'default',
      data: getValuesFromPattern({
        formData: {
          title: 'Заказ услуги',
          comission: '35000',
          need: 'default',
          contact: [
            {
              type: 'organization',
              id: 'default',
            },
          ],
          responsible: 'A3kHwtaUlgdyTKEMkJ0vlsSdz0F3',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: dealObject,
      }),
    },
  ]

  return deals.map((d) => setDoc({ accountId, path: 'deals', ...d }))
}

export default addDealsData
