import React from 'react'
import './Header.sass'

import PageTitle from '../../components/PageTitle/PageTitle'
// import NotificationsCenter from './NotificationsCenter/NotificationsCenter'
import UserProfile from '../UserProfile/UserProfile'

function Header() {
  return (
    <header className="Header">
      <div className="Header-ChildGroup">
        {/* <div className="Hamburger" /> */}
        <PageTitle>CardBox public alpha v0.14.1</PageTitle>
      </div>
      <div className="Header-ChildGroup">
        {/* <NotificationsCenter /> */}
        <UserProfile />
      </div>
    </header>
  )
}

export default Header
