import React, { useState } from 'react'
import './CallEvent.sass'
import { connect } from 'react-redux'
import * as actions from '../store/actions/dialerActions'

import DialerSideEvent from '../DialerSideEvent/DialerSideEvent'
import { Link } from 'react-router-dom'
import DialerButton from '../DialerButton/DialerButton'
import { parseCallDirection, getCallIcon } from '../functions/'
import { getCallEventTitle } from './functions/getCallEventTitle'
import {
  confirmButton,
  confirmDisabledButton,
  cancelButton,
  closeButton,
  muteCallButton,
  muteCallActiveButton,
} from '../DialerButton/ButtonTypes'

function CallEvent({
  responsible,
  eventType,
  direction,
  name,
  phone,
  contactId,
  closeToast: closeEvent,
  sendCallRequest,
  contactData,
  playSoundStatus,
  toggleCallSoundStatus,
}) {
  const [createContact, setCreateContact] = useState(checkIfNeedCreateContact(contactId, direction))
  const [error, setError] = useState(null)

  let buttons = []
  switch (eventType) {
    case 'clickToCall':
      buttons = [
        {
          ...confirmButton,
          clicked: () => {
            closeEvent()
            sendCallRequest({ direction, phone, contactId, createContact, responsible })
          },
        },
        { ...closeButton, clicked: closeEvent },
      ]
      break
    default:
      buttons = [
        {
          ...confirmDisabledButton,
          clicked: () => '',
        },
        {
          ...(playSoundStatus ? muteCallButton : muteCallActiveButton),
          clicked: () => toggleCallSoundStatus(),
        },
        { ...closeButton, clicked: closeEvent },
      ]
      break
  }

  return (
    <DialerSideEvent
      img={{ src: getCallIcon(direction), alt: parseCallDirection(direction) }}
      title={getCallEventTitle(eventType, direction)}
      closeEvent={closeEvent}
    >
      <div className="CallEvent-Contact">
        <div className="Contact-Name">{name ? name : contactData && contactData.name}</div>
        <div className="Contact-PhoneNumber">{phone}</div>
      </div>
      <div className="CallEvent-Buttons">
        <div className="CallEvent-ActionButtons">
          {buttons.map((button, index) => (
            <DialerButton key={`CallEvent-Buttons-${index}`} {...button} />
          ))}
        </div>
        {handleContact(contactId, direction, createContact, setCreateContact)}
      </div>
    </DialerSideEvent>
  )
}

const handleContact = (contactId, direction, createContact, setCreateContact) => {
  if (!contactId && direction === 'outbound') {
    return (
      <DialerButton
        content={{ type: 'icon', name: createContact ? 'check' : 'times', weight: 'solid', text: 'Создать контакт' }}
        theme={`textContent ${createContact && 'DialerButton_theme_defaultActive'}`}
        clicked={() => setCreateContact(!createContact)}
      />
    )
  } else {
    return (
      <Link to={`/contacts/list/edit/${contactId}`}>
        <DialerButton content={{ type: 'icon', name: 'users', weight: 'solid' }} preventClick />
      </Link>
    )
  }
}

const checkIfNeedCreateContact = (contactId, direction) => (!contactId && direction === 'outbound' ? true : false)

const mapStateToProps = (state, ownProps) => ({
  contactData: state.contacts.contactsObjects[ownProps.contactId],
  playSoundStatus: state.dialer.playSoundStatus,
})

const mapDispatchToProps = (dispatch) => ({
  sendCallRequest: (payload) => dispatch(actions.dialerSendCallRequest(payload)),
  toggleCallSoundStatus: () => dispatch(actions.dialerToggleMuteInboundCallSound()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallEvent)
