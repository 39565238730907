import React, { useState, useEffect } from 'react'
import './Task.sass'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { db } from '../../../../../config/firebase'
import fetchTask from '../../../store/sagas/functions/fetchTask'

import Icon from '../../../../../components/UI/Icon/Icon'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import IconicButton from '../../../../../components/UI/IconicButton/IconicButton'
import Backdrop from '../../../../../components/UI/Backdrop/Backdrop'
import UserAvatar from '../../../../../components/UserAvatar/UserAvatar'
import TaskInstanceCard from './components/TaskInstanceCard/TaskInstanceCard'
import TaskResultBlock from './components/TaskResults/TaskResultBlock'
import TaskDescription from './components/TaskDescription/TaskDescription'
import TaskNav from './components/TaskNav/TaskNav'
import TaskAttachments from './components/TaskAttachments/TaskAttachments'
import TaskComments from './components/TaskComments/TaskComments'
import TaskTitle from './components/TaskTitle/TaskTitle'

import TaskTags from './components/TaskTags/TaskTags'
import TaskTermStatus from './components/TaskTermStatus/TaskTermStatus'
import TaskResults from './components/TaskResults/TaskResults'
import defineTaskColumn from '../functions/defineTaskColumn'
import rootDbPath from '../../../../../utilities/db/rootDbPath'

function Task({ accountId, rawColumns, taskColumn, taskId, uid, onClose, showResultsForm }) {
  const [task, setTask] = useState(null)
  const [column, setColumn] = useState(taskColumn)

  useEffect(
    () =>
      db
        .collection(`${rootDbPath(accountId)}/tasks`)
        .doc(taskId)
        .onSnapshot((snap) => {
          const taskData = merge({ taskId }, snap.data())
          const taskColumn = defineTaskColumn({ columns: rawColumns, task: taskData })
          setColumn(taskColumn)
          fetchTask({ accountId, task: taskData }).then((fetchedTask) =>
            setTask(merge(fetchedTask, { canBeEdit: !fetchedTask.isFinished })),
          )
        }),
    [],
  )

  return (
    <Backdrop show close={onClose}>
      {showResultsForm && task.canBeEdit && <TaskResults taskId={taskId} taskData={task} uid={uid} />}
      <div className="Task-PopUp">
        {task ? (
          <>
            <div className="Task-Header">
              <span className="Header-Icon">
                <Icon name="tasks" size="16" />
              </span>
              <div className="Task-Title">
                <TaskTitle taskId={taskId} title={task.title} canBeEdit={task.canBeEdit} />
                <div className="Task-ColumnName">
                  В колонке: <span>{column.label}</span>
                </div>
              </div>
              <div className="Task-Icon-Container" onClick={onClose}>
                <IconicButton type="basic" icon="times" iconSize="11" />
              </div>
            </div>
            <div className="Task-Body-Container">
              <div className="Body">
                {task.isFinished && <TaskResultBlock result={task.result} />}
                <div className="Body-Header">
                  <div className="Header-Col">
                    <div className="Title">Ответственные</div>
                    <div className="Col-Body">
                      {task.responsible.map((resp) =>
                        resp.responsibleId === 'all' ? (
                          <span key={resp.responsibleId} className="AllMembersTag">
                            Все сотрудники
                          </span>
                        ) : (
                          <UserAvatar
                            tooltip={`${resp.lastName} ${resp.name} ${resp.middleName}`}
                            key={resp.responsibleId}
                            size="24"
                            url={resp.avatar}
                          />
                        ),
                      )}
                      {/* <div className='Task-Icon-Container'>
                    <IconicButton type='basic' icon='plus' iconSize='12'></IconicButton>
                  </div> */}
                    </div>
                  </div>
                  {!isEmpty(task.tags) && (
                    <div className="Header-Col">
                      <div className="Title">Метки</div>
                      <div className="Col-Body">
                        <TaskTags tags={task.tags} />
                        {/* <div className="Task-Icon-Container">
                      <IconicButton type="basic" icon="plus" iconSize="12" />
                    </div> */}
                      </div>
                    </div>
                  )}

                  <div className="Header-Col">
                    <div className="Title">Срок исполнения</div>
                    <div className="Col-Body">
                      <TaskTermStatus
                        startDate={task.startDate}
                        endDate={task.endDate}
                        isFinished={task.isFinished}
                        canBeEdit={task.canBeEdit}
                      />
                    </div>
                  </div>
                  <TaskInstanceCard
                    contacts={task.contacts}
                    leads={task.leads}
                    sales={task.sales}
                    estate={task.estate}
                    uid={uid}
                  />
                </div>
                <div className="Body-Block">
                  {task.description && (
                    <TaskDescription
                      taskId={taskId}
                      description={task.description}
                      uid={uid}
                      canBeEdit={task.canBeEdit}
                    />
                  )}
                  <TaskAttachments taskId={taskId} attachments={task.attachments} canBeEdit={task.canBeEdit} />
                  <TaskComments taskId={taskId} taskStatus={task.status} canBeEdit={task.canBeEdit} />
                </div>
              </div>
              <TaskNav taskId={taskId} {...task} uid={uid} />
            </div>
          </>
        ) : (
          <div className="Task-SpinnerContainer">
            <Spinner type="popup" />
          </div>
        )}
      </div>
    </Backdrop>
  )
}

const mapStateToProps = (state, ownProps) => {
  const taskId = ownProps.taskId ? ownProps.taskId : ownProps.match.params.id
  return {
    taskId,
    showResultsForm: state.tasks.showResultsForm,
    taskColumn: getColumn({ columns: state.tasks.columns, taskId }),
    rawColumns: ownProps.rawColumns || state.tasks.rawColumns,
    uid: state.auth.user.uid,
    accountId: state.auth.account.data.accountId,
  }
}

const getColumn = ({ columns, taskId }) => {
  const column = columns.filter((col) => {
    if (find(col.tasks, ['taskId', taskId])) return true
    return false
  })
  return column[0]
}

export default withRouter(connect(mapStateToProps)(Task))
