import React, { useState, useEffect } from 'react'
import './MembersManager.sass'
import { withRouter } from 'react-router-dom'
import { find, merge, omit, findIndex } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SideScene from '../../../../../../../components/UI/SideScene/SideScene'
import { createForm } from '../../../../../../../utilities/newforms'
import membersDbForm, { membersForm } from './membersDbForm'
import FieldRender from '../../../../../../../utilities/newforms/render/FieldRender'
import SectionRender from '../../../../../../../utilities/newforms/render/SectionRender'
import SearchUsersResults from './SearchUsersResults/SearchUsersResults'
import PinnedMember from './PinnedMember/PinnedMember'
import isFormValid from '../../../../../../../utilities/newforms/validation/isFormValid'
import { initStatusMessage } from '../../../../../../../ui/StatusMessage/StatusMessage'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../../config/firebase'
import uploadMemberAvatar from '../functions/uploadMemberAvatar'
import rootPath from '../../../../../../../appTree/rootPath'
import { logError } from '../../../../../../../utilities/db/logError'
import getDoc from '../../../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../../../utilities/promiseAllValues'
import MembersManagerSkeleton from './MembersManagerSkeleton'
import onUserAttached from './functions/onUserAttached'
import onUserDetached from './functions/onUserDetached'
import onAvatarRemove from './functions/onAvatarRemove'
import useSystemFieldsMethods from '../../../../../../../utilities/newforms/useSystemFieldsMethods'
import handleRoleChange from './functions/handleRoleChange'
import handleSquadChange from './functions/handleSquadChange'

function MembersManager({ uid, accountId, accountData, closeLink, ...router }) {
  const userId = router.match.params.id
  const [form, setForm] = useState(null)
  const methods = useSystemFieldsMethods({ accountId, form })

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    promiseAllValues({
      formData: userId
        ? getDoc({ path: `accounts`, docId: accountId }).then((data) => find(data.members, ['userId', userId]) || {})
        : {},
    }).then(({ formData }) =>
      setForm(
        createForm({
          formData,
          formPattern: membersDbForm,
          methods,
        }),
      ),
    )
  }, [userId])

  const closeManager = () => {
    router.history.push(closeLink)
  }

  const onSubmit = async () => {
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = omit(getFormValues({ form }), ['userSearch'])

    let userData
    let prevRole
    let prevOffice
    let prevSquad

    if (userId) {
      const members = await getDoc({ path: 'accounts', docId: accountId }).then((data) => data.members)
      const memberIndex = findIndex(members, ['userId', userId])

      prevSquad = members[memberIndex].squadId
      prevRole = members[memberIndex].roleId
      prevOffice = members[memberIndex].officeId
      prevSquad = members[memberIndex].squadId

      members.splice(memberIndex, 1, {
        ...merge(values, { updated: getCurDateWithUser(uid) }),
        avatar: omit(values.avatar, ['needUpload', 'file']),
      })
      userData = { members, updated: getCurDateWithUser(uid) }
    } else {
      const userExists = find(accountData.members, ['userId', values.userId])
      if (userExists) {
        setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Этот сотрудник уже добавлен в систему',
          closeAfter: 5000,
        })
        return setIsLoading(false)
      }

      userData = {
        members: firebase.firestore.FieldValue.arrayUnion({
          ...values,
          avatar: omit(values.avatar, ['needUpload', 'file']),
          created: getCurDateWithUser(uid),
          updated: getCurDateWithUser(uid),
        }),
      }
    }

    updateDoc({
      path: 'accounts',
      docId: accountId,
      data: userData,
    })
      .then(() =>
        Promise.all([
          uploadMemberAvatar({ accountId, file: values.avatar, userId: values.userId }),
          handleRoleChange({ accountId, userId: values.userId, prevRole, nextRole: values.roleId, uid }),
          handleSquadChange({
            accountId,
            userId: values.userId,
            prevOffice,
            nextOffice: values.officeId,
            prevSquad,
            nextSquad: values.squadId,
            uid,
          }),
        ]),
      )
      .then(() => {
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Успешно сохранено.',
          closeAfter: 5000,
        })
        router.history.push(`${rootPath(accountId)}/settings/members/list/${values.userId}/edit`)
      })
      .catch((error) => {
        logError({ error })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <SideScene
      title={`${userId ? 'Редактирование' : 'Добавление'} сотрудника`}
      isLoading={isLoading}
      onSubmit={onSubmit}
      statusMessage={statusMessage}
      setStatusMessage={setStatusMessage}
      close={closeManager}
    >
      {form ? (
        <div className="MembersManager-Container">
          {form.userId.values ? (
            <PinnedMember
              userId={form.userId.values}
              onDetach={() => onUserDetached({ form, setForm })}
              avatar={form.avatar.value}
              avatarField={
                <FieldRender key="avatar" field={form.avatar} form={form} setForm={setForm} showErrors={showErrors} />
              }
              onAvatarRemove={() => onAvatarRemove({ accountId, form, setForm, uid })}
              useDetachButton={!userId}
            />
          ) : (
            <>
              <div className="MembersManager-UserSearchField">
                <FieldRender
                  key="userSearch"
                  field={form.userSearch}
                  form={form}
                  setForm={setForm}
                  showErrors={showErrors}
                />
                {showErrors && !form.userSearch.value && (
                  <div className="Field-Error">Выберите пользователя системы CardBox</div>
                )}
              </div>
              <SearchUsersResults
                email={form.userSearch.value}
                onUserClicked={(attachedUserId) => onUserAttached({ attachedUserId, form, setForm })}
              />
            </>
          )}
          <div className="MembersManager-UserInfo">
            <SectionRender
              title="Информация о пользователе в вашей системе"
              fields={membersForm}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>
        </div>
      ) : (
        <MembersManagerSkeleton />
      )}
    </SideScene>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
  accountData: state.auth.account.data,
})

export default compose(withRouter, connect(mapStateToProps))(MembersManager)
