import { db } from '../../config/firebase'
import rootDbPath from '../db/rootDbPath'

export const getOfficesSquadsOptions = async ({ accountId, officeId } = {}) => {
  const options = []

  await db
    .collection(`${rootDbPath(accountId)}/offices`)
    .orderBy('title')
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        const office = doc.data()
        office.squads.forEach((s) => {
          if ((officeId && (officeId === doc.id || officeId.includes(doc.id))) || !officeId) {
            options.push({
              value: s.squadId,
              label: s.title,
              rawData: s,
            })
          }
        })
      })
    })

  return options
}

export const getSquad = async ({ accountId, squadId } = {}) =>
  db
    .collection(`${rootDbPath(accountId)}/offices`)
    .orderBy('title')
    .get()
    .then((docs) => {
      let squadData = {}

      docs.forEach((doc) => {
        const office = doc.data()
        office.squads.forEach((s) => {
          if (s.squadId === squadId) squadData = { squadId: s.squadId, ...s }
        })
      })
      return squadData
    })
