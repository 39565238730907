import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import stylePropType from 'react-style-proptype'
import { isEmpty } from 'lodash'
import { createFieldWithIcon } from '../../../utilities/forms/createFormElement'
import { renderFields } from '../../../utilities/forms/renderFields'

import './DropdownBox.sass'

const DropdownBox = React.forwardRef((props, ref) => {
  const [searchField, setSearchField] = useState([
    { fieldId: 'searchField', ...createFieldWithIcon('input', 'text', 'Поиск', 'search') },
  ])

  useEffect(() => {
    if (props.isSearchable) {
      props.pushSearchVal(searchField[0].value)
    }
  }, [searchField])

  const classes = ['DropdownBox-Container']

  if (props.class) classes.push(props.class)
  if (props.className) classes.push(props.className)

  return (
    <div ref={ref} className={classes.join(' ')} style={props.styles}>
      <div className="DropdownBox">
        {props.isSearchable && renderFields(searchField, setSearchField)}
        {!isEmpty(props.children) ? props.children : <p className="DropdownBox-EmptyText">{props.emptyText}</p>}
      </div>
    </div>
  )
})

DropdownBox.propTypes = {
  children: PropTypes.node.isRequired,
  isSearchable: PropTypes.bool,
  pushSearchVal: PropTypes.oneOfType([PropTypes.func, () => null]),
  class: PropTypes.string,
  styles: PropTypes.oneOfType([stylePropType, () => null]),
}

DropdownBox.defaultProps = {
  isSearchable: false,
  pushSearchVal: null,
  class: '',
  styles: null,
}

export default DropdownBox
