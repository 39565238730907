import React, { useState } from 'react'
import { connect } from 'react-redux'
import { db } from '../../../../../../../config/firebase'
import Button from '../../../../../../../components/UI/Button/Button'
import taskInputChanged from '../../../functions/taskInputChanged'
import dbCallTasks from '../../../functions/dbCallTasks'
import updateTask from '../../../functions/updateTask'
import { createRequiredField } from '../../../../../../../utilities/forms/createFormElement'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import { addDoc } from '../../../../../../../utilities/db/addDoc'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'

function TaskResultsInput({ accountId, taskId, uid, createNewTask = false, taskConfig = null, attachableData }) {
  const [input, setInput] = useState(createRequiredField('textarea-autosize', 'text', 'Добавить результат'))
  const [isLoading, setIsLoading] = useState(false)

  const submitInput = () => {
    if (!input.valid) return
    setIsLoading(true)
    if (createNewTask) {
      return addDoc({ accountId, path: 'tasks', data: taskConfig })
        .then((taskId) =>
          db.collection(`${rootDbPath(accountId)}/history`).add({
            docId: attachableData.objectId,
            type: 'task',
            objId: taskId,
            created: getCurDateWithUser(uid),
          }),
        )
        .then(() => updateTask({ accountId, taskId, taskData: { isFinished: true, result: input.value }, uid }))
    }
    updateTask({ accountId, taskId, taskData: { isFinished: true, result: input.value }, uid })
  }

  return (
    <div className="TaskResults-SimpleInputBlock">
      <input
        type="text"
        className="SimpleInput"
        onChange={(e) => taskInputChanged(input, setInput, e)}
        placeholder={input.config.label}
      />
      <Button class="Button_size_24 SimpleInput-SubmitButton" clicked={submitInput} loading={isLoading}>
        Выполнить
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(TaskResultsInput)
