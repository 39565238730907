import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import OrganizationsManager from './scenes/OrganizationsList/OrganizationsManager/OrganizationsManager'
import rootPath from '../../appTree/rootPath'
import OrganizationsList from './scenes/OrganizationsList/OrganizationsList'

function Organizations({ permissions, subsections, accountId }) {
  return (
    <Switch>
      <Route
        path={[
          `${rootPath(accountId)}/contacts/organizations/list`,
          `${rootPath(accountId)}/contacts/organizations/list/page/:page`,
        ]}
        exact
        render={() => <OrganizationsList permissions={permissions} subsections={subsections} />}
      />
      <Route
        path={[
          ...(permissions.add ? [`${rootPath(accountId)}/contacts/organizations/list/add`] : []),
          `${rootPath(accountId)}/contacts/organizations/list/:id/edit`,
        ]}
        exact
        render={() => (
          <OrganizationsManager
            permissions={permissions}
            closeLink={`${rootPath(accountId)}/contacts/organizations/list`}
          />
        )}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Organizations)
