import React from 'react'

function EmailCode({ code = '2212' }) {
  return (
    <div style={{ width: '100%', padding: '48px 0px', textAlign: 'center' }}>
      <span
        style={{
          textDecoration: 'none',
          background: '#D0D5DA',
          color: '#1D1F29',
          padding: '16px 48px',
          borderRadius: '16px',
          fontSize: '18px',
          fontWeight: '700',
          border: 0,
        }}
      >
        {code}
      </span>
    </div>
  )
}

export default EmailCode
