import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

function VerifiedUserRoute({ user, component, path, exact, redirectTo }) {
  let redirectLink = null
  switch (redirectTo) {
    case 'signIn':
      redirectLink = '/auth/sign-in'
      break
    default:
      redirectLink = redirectTo
  }

  return user && user.phoneVerified && user.emailVerified ? (
    <Route path={path} exact={exact} component={component} />
  ) : redirectTo ? (
    <Route path={path}>
      <Redirect to={redirectLink} />
    </Route>
  ) : (
    ''
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(VerifiedUserRoute)
