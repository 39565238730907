import React from 'react'

export const createdObject = (text, object) => {
  return (
    <div className='EventContent EventOneLine'>
      {text} <span className='Link'>{object}</span>
    </div>
  )
}

export const updatedObject = (text, newValue, objText, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      {text} <span className='Link'>{newValue}</span>. {objText} <span className='Link'>{oldValue}</span>
    </div>
  )
}

export const deletedObject = (text, objText, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      {text}. {objText} <span className='Link'>{oldValue}</span>
    </div>
  )
}

export const createdForm = (text, form) => {
  return (
    <div className='EventContent EventOneLine'>
      {text} <span className='HighLighted'>{form}</span>
    </div>
  )
}

export const setField = (field, newValue) => {
  return (
    <div className='EventContent EventOneLine'>
      Для поля <span className='HighLighted'>{`${field}`}</span> установлено значение{' '}
      <span className='HighLighted'>{`${newValue}`}</span>
    </div>
  )
}

export const updateField = (field, oldValue, newValue) => {
  return (
    <div className='EventContent EventOneLine'>
      Изменено значение поля <span className='HighLighted'>{`${field}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>. Новое значение{' '}
      <span className='HighLighted'>{`${newValue}`}</span>
    </div>
  )
}

export const deleteField = (field, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      Удалено значение поля <span className='HighLighted'>{`${field}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>
    </div>
  )
}

export const setFormField = (form, field, newValue) => {
  return (
    <div className='EventContent EventOneLine'>
      В форме <span className='HighLighted'>{`${form}`}</span> для поля{' '}
      <span className='HighLighted'>{`${field}`}</span> установлено значение{' '}
      <span className='HighLighted'>{`${newValue}`}</span>
    </div>
  )
}

export const updateFormField = (form, field, oldValue, newValue) => {
  return field === 'formLabel' ? (
    <div className='EventContent EventOneLine'>
      Изменено название формы <span className='HighLighted'>{`${oldValue}`}</span>. Новое название{' '}
      <span className='HighLighted'>{`${newValue}`}</span>
    </div>
  ) : (
    <div className='EventContent EventOneLine'>
      В форме <span className='HighLighted'>{`${form}`}</span> изменено значение поля{' '}
      <span className='HighLighted'>{`${field}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>. Новое значение{' '}
      <span className='HighLighted'>{`${newValue}`}</span>
    </div>
  )
}

export const deleteFormField = (form, field, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      В форме <span className='HighLighted'>{`${form}`}</span> удалено значение поля{' '}
      <span className='HighLighted'>{`${field}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>
    </div>
  )
}

export const removedField = (field, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      Удалено значение поля <span className='HighLighted'>{`${field}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>
    </div>
  )
}

export const changedField = (field, newValue, oldValue) => {
  return (
    <div className='EventContent EventOneLine'>
      Изменено поле <span className='HighLighted'>{`${field}`}</span>. Новое значение{' '}
      <span className='HighLighted'>{`${newValue}`}</span>. Предыдущее значение{' '}
      <span className='HighLighted'>{`${oldValue}`}</span>
    </div>
  )
}
