import React from 'react'
import './TaskNav.sass'

import TaskTags from './options/TaskTags'
// Options
import TaskNavResponsible from './options/TaskResponsible'
import TaskNavExpirationDate from './options/TaskExpirationDate'
import TaskNavAttachments from './options/TaskAttachments'
import TaskNavAttachableInstance from './options/TaskAttachableInstance'

// Actions
import ChangeTaskStatus from './actions/ChangeTaskStatus'

function TaskNav({
  taskId,
  tags,
  contacts,
  leads,
  sales,
  estate,
  responsible,
  startDate,
  endDate,
  isFinished,
  uid,
  canBeEdit,
}) {
  return (
    <div className="TaskNav">
      <div className="Column-Title">Добавить к задаче</div>
      <ul className="NavElements">
        <TaskTags icon="tags" label="Метки" data={{ taskId, tags }} uid={uid} canBeEdit={canBeEdit} />
        <TaskNavExpirationDate
          icon="clock"
          label="Дата выполнения"
          data={{ taskId, startDate, endDate }}
          uid={uid}
          canBeEdit={canBeEdit}
        />
        <TaskNavAttachments icon="paperclip" label="Вложение" taskId={taskId} uid={uid} canBeEdit={canBeEdit} />
        <TaskNavResponsible
          icon="user-secret"
          label="Ответственные"
          data={{ taskId, responsible }}
          uid={uid}
          canBeEdit={canBeEdit}
        />
        <TaskNavAttachableInstance
          taskId={taskId}
          contacts={contacts}
          leads={leads}
          sales={sales}
          estate={estate}
          uid={uid}
          canBeEdit={canBeEdit}
        />
      </ul>
      <div className="Column-Title">Действия</div>
      <ul className="NavElements">
        <ChangeTaskStatus taskId={taskId} isFinished={isFinished} />
      </ul>
    </div>
  )
}

export default TaskNav
