import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Dealslist from './scenes/Dealslist/Dealslist'
import DealManager from './scenes/Dealslist/DealManager/DealManager'
import rootPath from '../../appTree/rootPath'

function Deals({ permissions, options, subsections, accountId }) {
  return (
    <Switch>
      <Route
        path={[`${rootPath(accountId)}/deals/list`, `${rootPath(accountId)}/deals/list/page/:page`]}
        exact
        render={() => <Dealslist permissions={permissions} options={options} subsections={subsections} />}
      />
      <Route
        path={[
          ...(permissions.add ? [`${rootPath(accountId)}/deals/list/add`] : []),
          `${rootPath(accountId)}/deals/list/:id/edit`,
        ]}
        exact
        render={() => (
          <DealManager permissions={permissions} options={options} closeLink={`${rootPath(accountId)}/deals/list`} />
        )}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Deals)
