import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '../../../../../../components/UI/Icon/Icon'
import { db } from '../../../../../../config/firebase'
import { createForm } from '../../../../../../utilities/newforms/createForm'
import FieldRender from '../../../../../../utilities/newforms/render/FieldRender'
import getFieldRenderObject from '../../../../../../utilities/newforms/render/getFieldRenderObject'

function AccountIdentifierField({ pushState }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        identifier: {
          field: {
            fieldId: 'identifier',
            fieldType: 'input',
            inputType: 'text',
            label: 'Идентификатор аккаунта',
            placeholder: 'Введите уникальный идентификатор',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    }),
  )
  const [isAvailable, setIsAvailable] = useState(false)
  const [reason, setReason] = useState(null)

  useEffect(() => {
    if (form.identifier.value) {
      db.collection('accounts')
        .doc(form.identifier.value)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setIsAvailable(false)
            setReason('Аккаунт с таким идентификатором уже существует')
            pushState('')
          } else if (!/^[a-z0-9-]*$/.test(form.identifier.value)) {
            setIsAvailable(false)
            setReason('Идентификатор аккаунта должен состоять из англ. букв нижнего регистра, цифр или символа "-".')
            pushState('')
          } else {
            setIsAvailable(true)
            setReason('')
            pushState(form.identifier.value)
          }
        })
    }
  }, [form])

  return (
    <div className="AccountIdentifier-Field">
      <ReactTooltip />
      <span
        className="AccountIdentifier-HelperIcon"
        data-tip="Идентификатор аккаунта используется для формирования уникальной ссылки, например: https://cdbx.io/app/cardbox. Изменить идентификатор после создания аккаунта невозможно."
      >
        <Icon name="question" weight="solid" />
      </span>
      <FieldRender
        field={{ ...form.identifier, fieldId: 'identifier', error: reason }}
        form={form}
        setForm={setForm}
        showErrors={!isAvailable}
      />
      {reason && <p className="AccountIdentifier-Error">{reason}</p>}
    </div>
  )
}

export default AccountIdentifierField
