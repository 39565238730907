import isArray from 'lodash/isArray'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import { createRequiredField, createSelectbox } from '../../../../../../../../utilities/forms/createFormElement'

export const createFieldsFromParamSettings = ({ paramId, params, getMotivationsParamsOptions }) => {
  const param = find(params, ['value', paramId])
  const fields = [
    {
      fieldId: 'motivationParam',
      ...createSelectbox('Тип мотивации', true, params),
      value: paramId,
      valid: true,
      touched: true,
      error: '',
      order: 0,
    },
  ]
  for (const k in param.rawData) {
    if (k !== 'title') {
      let createField = null
      const fv = param.rawData[k]
      switch (fv.fieldType) {
        case 'input':
          createField = {
            fieldId: k,
            ...createRequiredField(fv.fieldType, fv.inputType, fv.label, fv.mask),
            order: fv.order,
            shouldRenderIf: fv.shouldRenderIf,
          }
          break
        case 'select':
          createField = {
            fieldId: k,
            ...createSelectbox(
              fv.label,
              fv.required,
              isArray(fv.getOptionsFunction) ? fv.getOptionsFunction : eval(fv.getOptionsFunction),
            ),
            order: fv.order,
            shouldRenderIf: fv.shouldRenderIf,
          }
          break
        default:
          createField = fv
          break
      }

      fields.push(createField)
    }
  }
  return sortBy(fields, ['order'])
}
