import React, { useRef } from 'react'
import Icon from '../../../components/UI/Icon/Icon'
import Button from '../../../ui/Button/Button'
import SiteSectionTitle from '../SiteSectionTitle/SiteSectionTitle'
import './Faq.sass'
import { questions } from './questions'
import underCutterWhite from '../../pages/SitePage/assets/underCutterWhite.svg'

function Faq() {
  return (
    <div className="Site-Faq" style={{ backgroundImage: `url(${underCutterWhite})` }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SiteSectionTitle title="Часто задаваемые вопросы" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Site-Faq-Container">
              {questions.map((q) => (
                <Question {...q} />
              ))}
              <p className="Site-Faq-Buttons">
                <Button
                  title="Задать другой вопрос"
                  theme="solid"
                  fill="black"
                  icon="envelope"
                  size="48"
                  className="TryFreeButton_theme_black"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Question = ({ q, a }) => {
  const qRef = useRef()

  const onQuestionClick = () => {
    qRef.current.classList.toggle('Site-Question_status_active')
  }

  return (
    <div ref={qRef} className="Site-Question">
      <div className="Question-Header" onClick={onQuestionClick}>
        <span className="Site-TitleText">{q}</span>
        <div className="Question-Arrow">
          <Icon name="angle-down" />
        </div>
      </div>
      <div className="Question-Body">{a}</div>
    </div>
  )
}

export default Faq
