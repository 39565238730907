import { isEmpty } from 'lodash'
import { isBefore, isAfter } from 'date-fns'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import getEmployeesFrom from '../../../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import getCollection from '../../../../../../utilities/db/getCollection'

const getNewDealsFor = ({ accountId, service, dateRange, offices, squads, responsible }) =>
  promiseAllValues({
    responsible: !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
    deals: getCollection({ accountId, path: 'deals', docIdName: 'dealId' }),
  }).then((data) =>
    data.deals.filter(
      (d) =>
        d.need === service &&
        isAfter(d.created.at.seconds * 1000, dateRange.startingDate) &&
        isBefore(d.created.at.seconds * 1000, dateRange.endingDate) &&
        data.responsible.includes(d.responsible),
    ),
  )

export default getNewDealsFor
