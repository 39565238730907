import { merge, find, flatten } from 'lodash'
import getDoc from '../../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../Members/Memberslist/functions/fetchMemberByUserId'

const fetchSalePlan = ({ accountId, salePlanObject }) =>
  promiseAllValues(
    merge(salePlanObject, {
      // infoBlocks: Promise.all(
      //   officeObject.infoBlocks.map((ib) =>
      //     getDoc({ accountId, path: 'config/offices/infoBlocks', docId: ib.infoBlockId }).then((fetchedInfoBlock) =>
      //       merge(ib, fetchedInfoBlock),
      //     ),
      //   ),
      // ),
      offices: Promise.all(
        salePlanObject.offices.map((o) =>
          promiseAllValues({
            squads: Promise.all(
              o.squads.map((s) =>
                getDoc({ accountId, path: 'offices', docId: o.officeId }).then((office) => {
                  merge(o, { title: office.title })
                  return merge(s, find(office.squads, ['squadId', s.squadId]))
                }),
              ),
            ),
            members: getDoc({ accountId, path: 'offices', docId: o.officeId }).then(({ squads }) =>
              Promise.all(
                squads.map((s) => Promise.all(s.members.map((m) => fetchMemberByUserId({ accountId, userId: m })))),
              ).then((members) => flatten(members)),
            ),
          }).then((results) => merge(o, results)),
        ),
      ),
    }),
  )

export default fetchSalePlan
