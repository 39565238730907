import { merge } from 'lodash'
import getDoc from '../../../../../../utilities/db/getDoc'
import fetchContactObject from '../../../../../Contacts/scenes/Contactlist/functions/fetchContactObject'
import fetchOrganizationObject from '../../../../../Contacts/scenes/OrganizationsList/functions/fetchOrganizationObject'

const fetchDealContact = ({ accountId, contact }) =>
  Promise.all(
    contact.map((c) => {
      if (c.type === 'contact')
        return getDoc({ accountId, path: 'contacts', docId: c.id, docIdName: 'contactId' }).then((data) =>
          fetchContactObject({ accountId, doc: data }).then((fetchedContact) => merge(c, fetchedContact)),
        )
      return getDoc({ accountId, path: 'organizations', docId: c.id, docIdName: 'organizationId' }).then((data) =>
        fetchOrganizationObject({ accountId, doc: data }).then((fetchedContact) => merge(c, fetchedContact)),
      )
    }),
  )

export default fetchDealContact
