import { db } from '../../../../config/firebase'
import * as ACTION_TYPES from './actionTypes'

export const estateOnMount = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_ON_MOUNT,
    payload,
  }
}
export const estateInitComponentWithData = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_INIT_COMPONENT_WITH_DATA,
    payload,
  }
}
export const estateUpdateComponentData = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_UPDATE_COMPONENT_DATA,
    payload,
  }
}

export const estatePushNewData = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_PUSH_NEW_DATA,
    payload,
  }
}

export const estateFetchPage = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_FETCH_PAGE,
    payload,
  }
}

export const estateWasAdded = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_ESTATE_WAS_ADDED,
    payload,
  }
}

export const estateWasModified = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_ESTATE_WAS_MODIFIED,
    payload,
  }
}

export const estateWasRemoved = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_ESTATE_WAS_REMOVED,
    payload,
  }
}

export const estateAdd = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_ADD_ESTATE,
    payload,
  }
}

export const estateModify = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_MODIFY_ESTATE,
    payload,
  }
}

export const estateRemove = (payload) => {
  return {
    type: ACTION_TYPES.ESTATE_REMOVE_ESTATE,
    payload,
  }
}

// export const estateSetListener = () => {
//   return (dispatch) => {
//     db.collection('estate')
//       .orderBy('created.at')
//       .onSnapshot((snap) => {
//         dispatch({ type: ACTION_TYPES.ESTATE_INITIATE_ESTATE_OBJECTS })
//       })
//   }
// }

export const estateSaveForm = (form) => {
  return {
    type: ACTION_TYPES.ESTATE_SAVE_FORM,
    payload: form,
  }
}
