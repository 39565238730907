import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../../../../components/DeletePopUp/DeletePopUp'
import getDoc from '../../../../../../../utilities/db/getDoc'
import deleteOffice from './functions/deleteOffice'

const DeleteOfficePopUp = ({ accountId, officeId, uid, close }) => {
  const [officeData, setOfficeData] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteOffice({ accountId, officeId, uid }).then(() => {
      close()
    })
  }

  useEffect(() => {
    getDoc({ accountId, path: 'offices', docId: officeId }).then(({ title }) => setOfficeData(title))
  }, [])

  return (
    <DeletePopUp
      title="Удаление офиса"
      warningMessage={
        <>
          Вы уверены, что хотите удалить офис <span className="Bolder">"{officeData}"</span>
          ?
          <br />
          Все данные, как-либо связанные с этим этим офисом, будут удалены.
          <br />
          Восстановить удалённые данные будет невозможно.
        </>
      }
      fieldLabel="Чтобы удалить этот офис, введите его идентификатор:"
      identifier={officeId}
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DeleteOfficePopUp)
