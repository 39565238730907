import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { find, isEmpty } from 'lodash'
import Item from '../../containers/SideNav/Item/Item'
import getDefaultModulePath from '../../appTree/getDefaultModulePath'
import { db } from '../../config/firebase'
import buildTasksDbQuery from './scenes/Tasks/functions/buildTasksDbQuery'
import getCollection from '../../utilities/db/getCollection'

function NavComponent({ accountId, title, icon, subsections, path, activeClassPath, component, uid }) {
  const [counter, setCounter] = useState(null)

  useEffect(() => {
    getCollection({ accountId, path: 'config/tasks/columns', docIdName: 'columnId' }).then((columns) => {
      if (!isEmpty(columns)) {
        const expiredColumn = find(columns, ['columnId', 'expired'])
        const dbRef = db.collection('tasks')
        const query = buildTasksDbQuery({ dbRef, rules: expiredColumn.filterRules, uid })
        query.onSnapshot((docs) => setCounter(docs.size))
      }
    })
  }, [])

  return (
    <Item
      icon={icon}
      title={title}
      component={component}
      counter={counter}
      path={getDefaultModulePath({ subsections, path })}
      activeClassPath={activeClassPath}
    />
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, uid: state.auth.user.uid })

export default connect(mapStateToProps)(NavComponent)
