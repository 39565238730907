import React, { useState, useEffect } from 'react'
import './OrganizationsOptions.sass'
import { connect } from 'react-redux'
import DropdownBox from '../../../../../components/UI/DropdownBox/DropdownBox'
import useFilterObjValues from '../../../../../hooks/useFilterObjValues'
import getCollection from '../../../../../utilities/db/getCollection'
import Skeleton from '../../../../../components/UI/Skeleton/Skeleton'

function OrganizationsOptions({ accountId, onElementClicked, activeDocId }) {
  const [options, setOptions] = useState(null)
  const [filteredData, setFilterString] = useFilterObjValues(options)

  useEffect(() => {
    getCollection({ accountId, path: 'organizations', docIdName: 'organizationId' }).then((organizations) =>
      setOptions(organizations),
    )
  }, [])

  return (
    <DropdownBox class="OrganizationsOptions" isSearchable pushSearchVal={setFilterString} emptyText="Нет организаций">
      {!options || !filteredData
        ? renderSkeletonChilds(6)
        : filteredData.map((o) => (
            <div
              className={[
                'DropdownBox-Element',
                ...(activeDocId === o.organizationId ? ['DropdownBox-Element_theme_active'] : []),
              ].join(' ')}
              onClick={() => onElementClicked(o.organizationId)}
            >
              <p className="Label">{`${o.title}`}</p>
              <p className="SubLabel">{`${o.phone[0].value.value}`}</p>
              <p className="SubLabel">{`${o.address[0].value.formattedAddress}`}</p>
            </div>
          ))}
    </DropdownBox>
  )
}

const renderSkeletonChilds = (count) => {
  const children = []
  for (let index = 0; index < count; index++) {
    children.push(
      <div className="OptionsSkeleton">
        <Skeleton width={80} height={4} />
        <Skeleton width={110} height={4} />
      </div>,
    )
  }
  return children
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(OrganizationsOptions)
