import React, { useState, useEffect } from 'react'
import CodeInput from 'react-verification-code-input'
import Countdown from 'react-countdown'
import { differenceInMilliseconds } from 'date-fns'
import Button from '../../../../ui/Button/Button'
import { updateDoc } from '../../../../utilities/db/updateDoc'

function CodeForm({
  icon,
  title,
  description,
  type,
  sendCodeFunc = () => null,
  onSuccessFunc = () => null,
  changeData,
  user,
  redirectTo = null,
  sendCodeOnInit = false,
}) {
  const [newCodeAvaliableAt, setNewCodeAvailableAt] = useState(null)
  const [codeFieldValid, setCodeFieldValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const nextCodeTime = +localStorage.getItem(`${type}CodeTime`)
    const newCodeDateDif = differenceInMilliseconds(nextCodeTime, Date.now())

    if (nextCodeTime && newCodeDateDif > 0) {
      setNewCodeAvailableAt(nextCodeTime)
    } else {
      setNewCodeAvailableAt(Date.now() - 10000)
      if (sendCodeOnInit) sendCode()
    }
    if (redirectTo) localStorage.setItem('redirectTo', redirectTo)
  }, [])

  const sendCode = () => {
    const newCodeDate = Date.now() + 60000
    localStorage.setItem(`${type}CodeTime`, newCodeDate)
    setNewCodeAvailableAt(newCodeDate)
    sendCodeFunc()
  }

  const checkCodeInput = (inputCode) => {
    if (user.confirmationCode === inputCode) {
      setIsLoading(true)
      setCodeFieldValid(true)
      updateDoc({
        path: 'users',
        docId: user.uid,
        data: { [`${type}Verified`]: true, confirmationCode: null },
      }).then(() => onSuccessFunc())
    } else {
      setCodeFieldValid(false)
    }
  }

  return (
    <div className="ConfirmationForm">
      <img src={icon} alt={type} className="ConfirmationForm-Icon" />
      <div className="AuthForm-Header">
        <h5 className="AuthForm-Title">{title}</h5>
        <p className="AuthForm-Description">
          {description} {changeData}
        </p>
      </div>
      <CodeInput
        type="number"
        fields={4}
        fieldWidth={64}
        fieldHeight={64}
        autoFocus
        className={[
          'ConfirmationForm-CodeInput',
          ...(!codeFieldValid ? ['ConfirmationForm-CodeInput_status_fail'] : ['']),
        ].join(' ')}
        onChange={() => setCodeFieldValid(true)}
        onComplete={checkCodeInput}
      />
      <div className="ConfirmationForm-Buttons">
        <Button
          className="AuthForm-Button"
          icon="check"
          title="Подтвердить"
          fill="black"
          theme="solid"
          isLoading={isLoading}
          onClick={checkCodeInput}
          size={48}
        />
      </div>
      <p className="ConfirmationForm-LinkedText">
        Не получили проверочный код?
        <br />
        <Countdown
          key={newCodeAvaliableAt}
          date={newCodeAvaliableAt}
          renderer={(props) => <SendCodeButton {...props} sendNewCode={sendCode} />}
        />
      </p>
    </div>
  )
}

function SendCodeButton({ minutes, seconds, completed, sendNewCode }) {
  if (completed) {
    return <span onClick={() => sendNewCode()}>Отправить повторно</span>
  }
  return (
    <span className="NotActiveLink">
      Отправить повторно через 0{minutes}:{seconds < 10 && '0'}
      {seconds} сек.
    </span>
  )
}

export default CodeForm
