import getValuesFromPattern from '../../utilities/newforms/getValuesFromPattern'
import contactObject from '../../modules/Contacts/scenes/Contactlist/ContactsManager/contactDbForm'
import { setDoc } from '../../utilities/db/setDoc'
import { getCurDateWithUser } from '../../utilities/date/getCurDateWithUser'

const addContactsData = (accountId) => {
  const contacts = [
    {
      docId: 'default',
      data: getValuesFromPattern({
        formData: {
          lastName: 'Верный',
          name: 'Алексей',
          middleName: 'Янович',
          phone: [
            {
              type: '',
              value: '+79210303332',
            },
          ],
          deals: ['default'],
          responsible: 'F6ywHLXaOQOQLMqWKanREa9j0Ug1',
          organization: 'default',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: contactObject,
      }),
    },
    {
      data: getValuesFromPattern({
        formData: {
          lastName: 'Гончаров',
          name: 'Михаил',
          middleName: 'Павлович',
          phone: [
            {
              type: '',
              value: '+79000000032',
            },
          ],
          responsible: 'A3kHwtaUlgdyTKEMkJ0vlsSdz0F3',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: contactObject,
      }),
    },
    {
      data: getValuesFromPattern({
        formData: {
          lastName: 'Столярова',
          name: 'Нина',
          middleName: 'Сергеевна',
          phone: [
            {
              type: '',
              value: '+79113234456',
            },
          ],
          responsible: 'O7XN6hRIRjZLqREQO5ljwlQiiqm1',
          created: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
          updated: getCurDateWithUser('F6ywHLXaOQOQLMqWKanREa9j0Ug1'),
        },
        formPattern: contactObject,
      }),
    },
  ]

  return contacts.map((d) => setDoc({ accountId, path: 'contacts', ...d }))
}

export default addContactsData
