import React from 'react'
import './Logo.sass'
import { NavLink } from 'react-router-dom'
import PrimaryHor from './assets/primaryHor.svg'
import PrimaryWhiteHor from './assets/primaryWhiteHor.svg'

function Logo({ theme, direction, navLink = false, path }) {
  let src
  switch (theme) {
    case 'primary':
      src = direction.toLowerCase() === 'horizontal' ? PrimaryHor : 'PrimaryVertical'
      break
    case 'primaryWhite':
      src = direction.toLowerCase() === 'horizontal' ? PrimaryWhiteHor : 'PrimaryWhiteVertical'
      break

    default:
      break
  }

  return navLink ? (
    <NavLink to={path} className="Logo">
      <img src={src} alt="CardBox" className={`Logo_direction_${direction.toLowerCase()}`} />
    </NavLink>
  ) : (
    <a href={path} className="Logo">
      <img src={src} alt="CardBox" className={`Logo_direction_${direction.toLowerCase()}`} />
    </a>
  )
}

export default Logo
