import React, { useState, useEffect } from 'react'
import './FiltersNav.sass'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import Button from '../../../ui/Button/Button'
import FilterPopUpAddEdit from './FilterPopUpAddEdit'
import Skeleton from '../../../components/UI/Skeleton/Skeleton'
import isFormWithValues from '../../../utilities/newforms/isFormWithValues'
import getFormValues from '../../../utilities/newforms/getFormValues'
import { addDoc } from '../../../utilities/db/addDoc'
import { updateDoc } from '../../../utilities/db/updateDoc'
import { getCurDateWithUser } from '../../../utilities/date/getCurDateWithUser'
import { deleteDocs } from '../../../utilities/db/deleteDocs'
import OptionsButton from '../../../ui/OptionsButton/OptionsButton'
import FilterPopUpDelete from './FilterPopUpDelete'
import rootDbPath from '../../../utilities/db/rootDbPath'

function FiltersNav({ savedFilters, form, uid, accountId, filtersDbPath, applyFilter }) {
  const [showSaveFilter, setShowSaveFilter] = useState(false)
  useEffect(() => {
    setShowSaveFilter(isFormWithValues({ form }))
  }, [form])
  const [showPopUpFor, setShowPopUpFor] = useState(null)

  const onFilterSave = (filterForm) => {
    const values = getFormValues({ form, omitSystemFields: true })
    const filter = { ...filterForm, fields: values, created: getCurDateWithUser(uid), updated: getCurDateWithUser(uid) }

    if (showPopUpFor.action === 'add') {
      return addDoc({ accountId, path: filtersDbPath, data: filter })
    }
    return updateDoc({
      accountId,
      path: filtersDbPath,
      docId: showPopUpFor.filter.filterId,
      data: filter,
    })
  }

  const onFilterDelete = () =>
    deleteDocs({ path: `${rootDbPath(accountId)}/${filtersDbPath}`, docIds: [showPopUpFor.filter.filterId] })

  return (
    <>
      {showPopUpFor && (showPopUpFor.action === 'add' || showPopUpFor.action === 'edit') && (
        <FilterPopUpAddEdit
          filter={showPopUpFor.filter}
          onFilterSave={onFilterSave}
          close={() => setShowPopUpFor(null)}
        />
      )}
      {showPopUpFor && showPopUpFor.action === 'delete' && (
        <FilterPopUpDelete
          filter={showPopUpFor.filter}
          onFilterDelete={onFilterDelete}
          close={() => setShowPopUpFor(null)}
        />
      )}

      <div className="Filters-NavBlock">
        <div className="Nav-Category">
          <h4>Сохраненные фильтры</h4>
        </div>
        <div className="NavBlock-Items">
          {savedFilters ? (
            isEmpty(savedFilters) ? (
              <p className="NoInfoRow">Нет фильтров</p>
            ) : (
              savedFilters.map((filter) => (
                <div
                  className={['NavBlock-Item', ...(filter.isActive ? ['NavBlock-Item_theme_active'] : [])].join(' ')}
                  onClick={() => applyFilter(filter)}
                >
                  {filter.label}
                  {!filter.isSystemFilter && (
                    <OptionsButton
                      icon="ellipsis-v"
                      options={[
                        {
                          icon: 'pencil-alt',
                          label: 'Редакт.',
                          clicked: () => setShowPopUpFor({ action: 'edit', filter }),
                        },
                        {
                          icon: 'trash-alt',
                          label: 'Удалить',
                          clicked: () => setShowPopUpFor({ action: 'delete', filter }),
                        },
                      ]}
                    />
                  )}
                </div>
              ))
            )
          ) : (
            <FiltersNavSkeleton />
          )}
          {showSaveFilter && (
            <div className="Buttons-Container">
              <Button
                title="Сохранить фильтр"
                theme="solid"
                fill="accent"
                size={32}
                onClick={() => setShowPopUpFor({ action: 'add', filter: null })}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const FiltersNavSkeleton = () => {
  const content = []

  for (let index = 0; index < 5; index++) {
    content.push(
      <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Skeleton width={130} height={18} />
        <Skeleton circle width={24} height={24} />
      </p>,
    )
  }

  content.push(
    <p style={{ textAlign: 'center', marginTop: '8px' }}>
      <Skeleton width={110} height={24} />
    </p>,
  )

  return content
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(FiltersNav)
