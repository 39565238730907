import React from 'react'
import './Dashboard.sass'
import DashboardNav from './components/DashboardNav/DashboardNav'
import SalesFunnel from '../Analytics/components/SalesFunnel/SalesFunnel'
import AgencyPlanGauge from '../Analytics/components/AgencyPlanGauge/AgencyPlanGauge'
import UsersTopRating from '../Analytics/components/UsersTopRating/UsersTopRating'
import GeneralConversions from '../Analytics/components/GeneralConversions/GeneralConversions'
import MotivationPlan from '../Analytics/components/MotivationPlan/MotivationPlan'
import DealsWithoutTasksCounter from '../Analytics/components/DealsCounter/DealsWithoutTasksCounter'
import DealsCounter from '../Analytics/components/DealsCounter/DealsCounter'
import ExpiringContracts from '../Analytics/components/ExpiringContracts/ExpiringContracts'
import TasksCounter from '../Analytics/components/TasksCounter/TasksCounter'
import DealTypes from '../Analytics/components/DealTypes/DealTypes'
import AverageCheck from '../Analytics/components/AverageCheck/AverageCheck'
import MaxCheck from '../Analytics/components/MaxCheck/MaxCheck'
import CallsCounter from '../Analytics/components/CallsCounter/CallsCounter'
import TopDeals from '../Analytics/components/TopDeals/TopDeals'
import ErrorsCounter from '../Analytics/components/ErrorsCounter/ErrorsCounter'
import PersonalPlanGauge from '../Analytics/components/PersonalPlanGauge/PersonalPlanGauge'

function Dashboard({ permissions }) {
  console.log('🚀 ~ file: Dashboard.jsx ~ line 24 ~ Dashboard ~ permissions', permissions)
  return (
    <div className="Module-Container Dashboard-Module">
      <div className="Dashboard">
        <DashboardNav permissions={permissions} />
        <SalesFunnel />
        <div className="Dashboard-Widgets">
          <div className="Widgets-LeftContainer">
            <div className="Widgets-4x4">
              <AgencyPlanGauge />
              <PersonalPlanGauge />
              <GeneralConversions />
            </div>
            <div className="Widgets-Merged Widgets-auto-180">
              <DealsCounter />
              <ExpiringContracts />
              <TasksCounter />
              <DealsWithoutTasksCounter />
            </div>
            <DealTypes />
            <div className="Widgets-3x1">
              <AverageCheck />
              <MaxCheck />
              <CallsCounter />
            </div>
          </div>
          <div className="Widgets-RightContainer">
            <UsersTopRating />
            <TopDeals />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
