import startOfToday from 'date-fns/startOfToday'
import endOfYear from 'date-fns/endOfYear'
import startOfYear from 'date-fns/startOfYear'
import getCalendarYearsWithMonths from './getCalendarYearsWithMonths'

const getYearRange = () => {
  const startingDate = startOfYear(startOfToday())
  const endingDate = endOfYear(startOfToday())
  const label = 'Год'

  return {
    label,
    startingDate,
    endingDate,
    years: getCalendarYearsWithMonths(startingDate, endingDate),
  }
}

export default getYearRange
