import { isAfter, isBefore } from 'date-fns'
import getCollection from '../../../../../../utilities/db/getCollection'

const getDoneDeals = ({ accountId, dateRange = null, responsible = null } = {}) =>
  getCollection({
    accountId,
    path: 'deals',
    docIdName: 'dealId',
    whereQueries: [{ fieldPath: 'isDeleted', op: '==', value: false }],
  }).then((deals) =>
    deals.filter((d) => {
      if (d.finishedWith.status !== 'success') return false
      if (
        dateRange &&
        (!d.finishedWith.date ||
          !isAfter(d.finishedWith.date.seconds * 1000, dateRange.startingDate) ||
          !isBefore(d.finishedWith.date.seconds * 1000, dateRange.endingDate))
      )
        return false
      if (responsible && !responsible.includes(d.responsible)) return false
      return true
    }),
  )
export default getDoneDeals
