import { toast } from 'react-toastify'

const renderEvent = (containerId, toastId, component, options = {}) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: component, containerId, ...options })
  } else {
    toast(component, { toastId, containerId, ...options })
  }
}

export const dismissEvent = (toastId) => toast.dismiss(toastId)

export default renderEvent
