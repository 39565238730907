import React, { useEffect, useState } from 'react'

import Button from '../../../../../../ui/Button/Button'
import CategoryManager from './CategoryManager/CategoryManager'
import './CategoryPrice.sass'
import { db } from '../../../../../../config/firebase'
import rootDbPath from '../../../../../../utilities/db/rootDbPath'
import { filter, isEmpty } from 'lodash'
import DeleteCategoryManager from './CategoryManager/DeleteCategoryManager'
import CategoryPriceTile from './CategoryPriceTile/CategoryPriceTile'
import ServiceManager from './ServiceManager/ServiceManager'
import DeleteServiceManager from './ServiceManager/DeleteServiceManager'

function CategoryPrice({ accountId, extensionId, officeId }) {
  const [popUp, setPopUp] = useState({ type: null, instanceId: null })
  const [categories, setCategories] = useState(null)
  const [services, setServices] = useState(null)

  const togglePopUp = ({ type, show, editMode = false, instanceId = null, serviceId = null }) =>
    setPopUp({ type, show, editMode, instanceId, serviceId })

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/extensions/${extensionId}/categories`).onSnapshot((snap) => {
      const data = []
      snap.forEach((s) =>
        data.push({
          ...s.data(),
          categoryId: s.id,
        }),
      )
      setCategories(data)
    })
  }, [])

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/extensions/${extensionId}/services`).onSnapshot((snap) => {
      const data = []
      snap.forEach((s) =>
        data.push({
          ...s.data(),
          serviceId: s.id,
        }),
      )
      setServices(data)
    })
  }, [])

  const categoriesByOfficeId = !isEmpty(categories) ? filter(categories, ['officeId', officeId]) : null

  return (
    <div className="CategoryPrice">
      <Button
        onClick={() => setPopUp({ type: 'cat' })}
        // icon={icon}
        theme="bounded"
        size={28}
        title="Создать категорию"
        // color={color}
        // isLoading={isLoading}
        spinnerColor="gray"
        // className={className}
      />
      {popUp.type === 'delCat' && (
        <DeleteCategoryManager
          show
          categoryId={popUp.instanceId}
          extensionId={extensionId}
          close={() => togglePopUp({ type: null })}
        />
      )}
      {popUp.type === 'cat' && (
        <CategoryManager
          officeId={officeId}
          accountId={accountId}
          extensionId={extensionId}
          categoryId={popUp.instanceId}
          onClose={() => setPopUp({ type: null })}
        />
      )}
      {popUp.type === 'service' && (
        <ServiceManager
          accountId={accountId}
          extensionId={extensionId}
          categoryId={popUp.instanceId}
          serviceId={popUp.serviceId}
          onClose={() => setPopUp({ type: null })}
        />
      )}
      {popUp.type === 'delServ' && (
        <DeleteServiceManager
          show
          serviceId={popUp.serviceId}
          extensionId={extensionId}
          close={() => togglePopUp({ type: null })}
        />
      )}
      {!isEmpty(categoriesByOfficeId) &&
        categoriesByOfficeId.map((c) => <CategoryPriceTile services={services} togglePopUp={togglePopUp} {...c} />)}
    </div>
  )
}

export default CategoryPrice
