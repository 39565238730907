import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import getDoc from '../../../../utilities/db/getDoc'

const DeleteDealPopUp = ({ accountId, dealId, closePopUp, uid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dealName, setDealName] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    updateDoc({ accountId, path: 'deals', docId: dealId, data: { isDeleted: true }, uid }).then(() => closePopUp())
  }

  useEffect(() => {
    getDoc({ accountId, path: 'deals', docId: dealId }).then((deal) => setDealName(`${deal.title}`))
  }, [])

  return (
    <DeletePopUp
      title="Удаление сделки"
      warningMessage={
        <>
          Вы уверены, что хотите удалить <span className="Bolder">"{dealName}"</span>
          ?
          <br />
          Все данные, как-либо связанные с "{dealName}
          ", будут сохранены.
        </>
      }
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(DeleteDealPopUp)
