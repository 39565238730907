import isEmpty from 'lodash/isEmpty'
import getCollection from '../../../../../../utilities/db/getCollection'

const getSalesPlanFor = ({ accountId, years, offices, squads, responsible }) =>
  getCollection({ accountId, path: 'config/deals/plans', docIdName: 'planId' }).then((plans) => {
    let totalPlan = 0

    years.forEach((y) => {
      plans
        .filter((p) => p.year === y.year)
        .forEach((p) => {
          p.offices.forEach((o) => {
            // CountPlanForOffice
            if (!isEmpty(offices)) {
              if (offices.includes('all')) {
                y.months.forEach((m) => (totalPlan += o[m]))
              } else if (offices.includes(o.officeId)) {
                y.months.forEach((m) => (totalPlan += o[m]))
              }
            }

            // CountPlanForSquad
            if (!isEmpty(squads)) {
              o.squads.forEach((s) => {
                if (squads.includes(s.squadId)) {
                  y.months.forEach((m) => (totalPlan += s[m].totalPlan))
                }
              })
            }

            // CountPlanForResponsible
            if (!isEmpty(responsible)) {
              responsible.forEach((r) => {
                o.squads.forEach((s) => {
                  if (s[1].members.hasOwnProperty(r)) {
                    y.months.forEach((m) => (totalPlan += s[m].members[r].totalPlan))
                  }
                })
              })
            }
          })
        })
    })

    return totalPlan
  })

export default getSalesPlanFor
