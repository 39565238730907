import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfToday from 'date-fns/startOfToday'
import getCalendarYearsWithMonths from './getCalendarYearsWithMonths'

const getMonthRange = () => {
  const startingDate = startOfMonth(startOfToday())
  const endingDate = endOfMonth(startOfToday())
  const label = 'Месяц'

  return {
    label,
    startingDate,
    endingDate,
    years: getCalendarYearsWithMonths(startingDate, endingDate),
  }
}

export default getMonthRange
