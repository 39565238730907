import React from 'react'
import './NotificationsMarker.sass'

export default function NotificationsMarker({ counter }) {
  return counter > 0 ? (
    <span className={`NotificationsMarker ${counter > 99 && 'NotificationsMarker_theme_adaptive'}`}>{counter}</span>
  ) : (
    ''
  )
}
