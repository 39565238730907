import React, { useState, useRef } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { connect } from 'react-redux'
import { createRequiredField } from '../../../../../../../utilities/forms/createFormElement'
import taskInputChanged from '../../../functions/taskInputChanged'
import updateTask from '../../../functions/updateTask'

function TaskTitle({ accountId, taskId, title, uid, canBeEdit }) {
  const [taskTitle, setTaskTitle] = useState({
    ...createRequiredField('textarea', 'text'),
    value: title,
    valid: true,
  })
  const titleFieldRef = useRef()

  const onTextareaSubmit = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault()
      onTitleSubmit(event)
      titleFieldRef.current.blur()
    }
  }

  const onTitleSubmit = (event) => {
    const { value } = event.target
    if (!value.trim() || value === title) return
    updateTask({ accountId, taskId, taskData: { title: value }, uid })
  }

  return (
    <TextareaAutosize
      ref={titleFieldRef}
      className="Task-Title-Field"
      value={taskTitle.value}
      onChange={(event) => taskInputChanged(taskTitle, setTaskTitle, event)}
      onKeyDown={onTextareaSubmit}
      onBlur={onTitleSubmit}
      disabled={!canBeEdit}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(TaskTitle)
