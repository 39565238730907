import React, { useState } from 'react'
import './Tabs.sass'
import Icon from '../../components/UI/Icon/Icon'

function Tabs({ tabs, activeTabIndex, setActiveTabIndex }) {
  return (
    <div className="Tabs">
      <div className="Tabs-Header">
        {tabs.map((t, i) => (
          <div
            onClick={() => setActiveTabIndex(i)}
            className={['Tabs-Tab', ...(i === activeTabIndex ? ['Tabs-Tab_theme_active'] : [])].join(' ')}
          >
            {t.icon && <Icon name={t.icon} />}
            {t.title}
          </div>
        ))}
      </div>
      <div className="Tabs-Body">{tabs[activeTabIndex].content}</div>
    </div>
  )
}

export default Tabs
