const rootDbPath = require('./rootDbPath')

const getDoc = ({ db, accountId, path, docId, docIdName = null }) => {
  let dbPath = ''
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  return db
    .collection(dbPath)
    .doc(docId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return {
          ...(docIdName ? { [docIdName]: doc.id } : {}),
          ...doc.data(),
        }
      }
      return null
    })
}

module.exports = getDoc
