import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import * as ACTION_TYPES from '../actions/actionTypes'
import reorderTasksInColumn from '../sagas/functions/reorderTasksInColumn'

const initialState = {
  columns: [],
  rawColumns: [],
  listeners: {},
  uploadAttachment: null,
  showResultsForm: false,
  submitCommentAsTaskResult: null,
  isMounted: false,
}

const tasksReducer = (state = initialState, action) => {
  const stateClone = cloneDeep(state)

  switch (action.type) {
    case ACTION_TYPES.TASKS_SEND_DATA_TO_REDUCER:
      return initTasksBoardWithData(stateClone, action)
    case ACTION_TYPES.TASKS_ADD_TASK:
      return addTask(stateClone, action)
    case ACTION_TYPES.TASKS_UPDATE_TASK:
      return updateTask(stateClone, action)
    case ACTION_TYPES.TASKS_REMOVE_TASK:
      return removeTask(stateClone, action)
    case ACTION_TYPES.TASKS_UPDATE_COLUMNS:
      return updateColumns(stateClone, action)
    case ACTION_TYPES.TASKS_UPDATE_COLUMN_INFO:
      return updateColumnInfo(stateClone, action)
    case ACTION_TYPES.TASKS_ATTACHMENT_UPLOAD:
      return handleTasksAttachmentsUpload(stateClone, action)
    case ACTION_TYPES.TASKS_SHOW_RESULTS_FORM:
      return tasksShowResultsForm(stateClone, action)
    case ACTION_TYPES.TASKS_SAVE_LISTENERS:
      return saveListeners(stateClone, action)

    default:
      return state
  }
}

export default tasksReducer

const tasksShowResultsForm = (stateClone, action) => {
  const { status, comment } = action.payload
  stateClone.showResultsForm = status
  stateClone.submitCommentAsTaskResult = comment
  return stateClone
}

const handleTasksAttachmentsUpload = (stateClone, action) => {
  stateClone.uploadAttachment = action.payload ? action.payload : null
  return stateClone
}

const initTasksBoardWithData = (stateClone, action) => {
  const { columns, rawColumns } = action.payload

  return {
    ...stateClone,
    columns,
    rawColumns,
    isMounted: true,
  }
}

const updateColumns = (stateClone, action) => {
  return {
    ...stateClone,
    columns: action.payload,
  }
}

const updateColumnInfo = (stateClone, { payload: columnConf }) => {
  let column = find(stateClone.columns, ['columnId', columnConf.columnId])
  merge(column, columnConf)
  return stateClone
}

const updateTask = (stateClone, { payload: task }) => {
  const taskColumn = find(stateClone.columns, ['columnId', task.columnId])
  const oldTaskIndex = findIndex(taskColumn.tasks, ['taskId', task.taskId])
  taskColumn.tasks.splice(oldTaskIndex, 1, task)
  taskColumn.tasks = reorderTasksInColumn(taskColumn)

  return stateClone
}

const addTask = (stateClone, { payload: task }) => {
  const taskColumn = find(stateClone.columns, ['columnId', task.columnId])
  taskColumn.tasks.push(task)
  taskColumn.totalTasks += 1

  taskColumn.tasks = reorderTasksInColumn(taskColumn)

  return stateClone
}

const removeTask = (stateClone, { payload: task }) => {
  const taskColumn = find(stateClone.columns, ['columnId', task.columnId])
  const oldTaskIndex = findIndex(taskColumn.tasks, ['taskId', task.taskId])
  taskColumn.tasks.splice(oldTaskIndex, 1)
  taskColumn.totalTasks -= 1

  return stateClone
}

const saveListeners = (stateClone, { payload: listeners }) => {
  return { ...stateClone, listeners }
}
