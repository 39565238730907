import React, { useState, useEffect } from 'react'
import './NewDeals.sass'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isNull } from 'lodash'
import NumberFormat from 'react-number-format'
import FunnelBlock from '../FunnelBlock/FunnelBlock'
import usePeriodFromUrl from '../../../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../../../hooks/useResponsibleFromUrl'
import getNewDealsFor from '../../../../../Deals/scenes/Dealslist/functions/analytics/getNewDeals'
import declOfNum from '../../../../../../utilities/declOfNum'
import { getUrlQueryParams } from '../../../../../../utilities/queries'
import Skeleton from '../../../../../../components/UI/Skeleton/Skeleton'

function NewLeads({ accountId, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const { service } = getUrlQueryParams({ history: router.history, params: ['service'] })

  const [newDealsCounter, setNewDealsCounter] = useState(null)

  useEffect(() => {
    if (dateRange && resp) {
      const { offices, squads, responsible } = resp
      getNewDealsFor({ accountId, service, dateRange, offices, squads, responsible }).then((deals) =>
        setNewDealsCounter(deals.length),
      )
    }
  }, [dateRange, resp])

  return (
    <FunnelBlock
      className="FunnelBlock-NewDeals"
      blockContent={
        isNull(newDealsCounter) ? (
          <div>
            <p className="DealsCounter">
              <Skeleton circle width={32} height={32} />
            </p>
            <p className="Label">
              <Skeleton width={80} />
            </p>
          </div>
        ) : (
          <div>
            <p className="DealsCounter">
              {newDealsCounter > 0 ? (
                <NumberFormat prefix="+" value={newDealsCounter} displayType="text" thousandSeparator={' '} />
              ) : (
                newDealsCounter
              )}
            </p>
            <p className="Label">
              {`${declOfNum(newDealsCounter, ['Новая', 'Новые', 'Новых'])} ${declOfNum(newDealsCounter, [
                'сделка',
                'сделки',
                'сделок',
              ])}`}
            </p>
          </div>
        )
      }
      subBlockContent={
        <div className="NewDeals-Lost">{isNull(newDealsCounter) ? <Skeleton width={80} /> : 'Потеряные:'}</div>
      }
    />
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(NewLeads)
