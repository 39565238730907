import { db } from '../../config/firebase'
import rootDbPath from '../db/rootDbPath'

export const getDocCreationTypesOptions = async (accountId, orderBy) => {
  if (!orderBy) orderBy = 'label'
  const options = [{ label: 'Выбрать', value: '' }]
  await db
    .collection(`${rootDbPath(accountId)}/config/global/docCreationTypes`)
    .orderBy(orderBy)
    .get()
    .then((docRef) => {
      docRef.forEach((doc) => {
        options.push({
          label: doc.data().label,
          value: doc.id,
        })
      })
    })
  return options
}
