import { db } from '../../config/firebase'

export const updateDocs = ({ path, docsData }) => {
  const batch = db.batch()
  docsData.forEach((doc) => batch.update(db.collection(path).doc(doc.docId), doc.data))
  return batch
    .commit()
    .then(() => 'success')
    .catch((e) => {
      console.log('🚀 ~ file: updateDocs.js ~ line 10 ~ updateDocs ~ e', e)
      return 'fail'
    })
}
