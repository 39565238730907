export const questions = [
  {
    q: 'Есть ли период бесплатного пользования?',
    a: `В системе предусмотрен пробный период, доступен при первичной регистрации. Срок пробного периода составляет 7 дней. Во время пробного периода вам доступны все функции CardBox без ограничений.`,
  },
  {
    q: 'Как продлить подписку?',
    a: `Подписка на пользование CardBox приобретается на определенный период, необходимо вовремя её продлевать, чтобы работа не остановилась. Продлить подписку без смены тарифного плана вы можете в любое время в вашем аккаунте CardBox.`,
  },
  {
    q: 'Учитываются ли в подписке деактивированные пользователи?',
    a: `Не учитываются. Пример: если у вас в CardBox 10 автивных пользователей, а один из этих пользователей деактивирован (удален из вашей системы), тогда вы сможете создать еще одного активного пользователя вместо деактивированного. Вся история и данные деактивированному будет сохраняться.`,
  },
  {
    q: 'Можно ли оплачивать пользование помесячно?',
    a: 'Стоимость тарифных планов установлена для подписки от трех месяцев, помесячной оплаты в настоящее время нет.',
  },
  {
    q: 'Какие методы оплаты возможны?',
    a: `Доступна онлайн-оплата в разделе "Биллинг" и оплата по счету. Для генерации счета необходимо обратится в службу поддержки CardBox. Онлайн-оплата возможна в любой валюте.`,
  },
  {
    q: 'Как я могу связаться со службой поддержки?',
    a: `Наша служба поддержки будет рада помочь вам. <a href="/contact-us">Здесь</a> указано, как с ней можно связаться.`,
  },
]
