import React from 'react'
import isEmpty from 'lodash/isEmpty'
import './TaskCard.sass'

import formatExpirationDate from '../functions/formatExpirationDate'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import Icon from '../../../../../components/UI/Icon/Icon'
import UserAvatar from '../../../../../components/UserAvatar/UserAvatar'
import TaskTags from '../Task/components/TaskTags/TaskTags'

function TaskCard({
  isLoading,
  columnStyles,
  crossOutStyle,
  taskId,
  title,
  tags,
  attachments,
  comments,
  startDate,
  endDate,
  description,
  responsible,
  router,
  onClickFn = null,
}) {
  const openTask = () => {
    if (onClickFn && !isLoading) return onClickFn()
    if (!isLoading) router.history.push(`${router.match.path}/${taskId}`)
  }

  return (
    <div
      className={['Task-Card', ...(crossOutStyle ? ['Task-Card_theme_crossout'] : [''])].join(' ')}
      onClick={() => openTask()}
    >
      {!isEmpty(tags) && (
        <div className="Card-Header">
          <TaskTags tags={tags} />
          {/* <div className="Header-Icon">
          <Icon name="ellipsis-h" size="12" />
        </div> */}
        </div>
      )}
      <div className="Card-Body">
        {title}
        {isLoading && (
          <div className="Card-Loader">
            <Spinner type="popup" />
          </div>
        )}
      </div>
      <div className="Card-Markers">
        <div className="Markers-Container">
          <div className="Marker Date" style={{ color: columnStyles.dateTagText }}>
            <span className="Marker-Icon">
              <Icon name="clock" size="10" />
            </span>
            <span className="Marker-Text">{startDate ? formatExpirationDate({ startDate, endDate }) : ''}</span>
          </div>
          {description && (
            <div className="Marker Description">
              <span className="Marker-Icon">
                <Icon name="align-left" size="10" />
              </span>
            </div>
          )}
          {comments > 0 && (
            <div className="Marker Description">
              <span className="Marker-Icon">
                <Icon name="comment" size="10" />
              </span>
              <span className="Marker-Text">{comments}</span>
            </div>
          )}
          {attachments.length > 0 && (
            <div className="Marker Attachment">
              <span className="Marker-Icon">
                <Icon name="paperclip" size="10" />
              </span>
              <span className="Marker-Text">{attachments.length}</span>
            </div>
          )}
          {/* <div className='Marker CheckList'>
            <span className='Marker-Icon'>
              <Icon name='check-square' size='10' />
            </span>
            <span className='Marker-Text'>1/3</span>
          </div> */}
        </div>
        <div className="Card-Avatars">
          {responsible.map((resp) =>
            resp.responsibleId === 'all' ? (
              <span key={resp.responsibleId} className="AllMembersTag">
                Все сотрудники
              </span>
            ) : (
              <UserAvatar
                tooltip={`${resp.lastName} ${resp.name} ${resp.middleName}`}
                key={resp.responsibleId}
                size="24"
                url={resp.avatar}
              />
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskCard
