import { find } from 'lodash'
import { getAddressTypesOptions } from '../../../../../utilities/getOptions'

const fetchAddressFieldset = ({ accountId, addresses }) =>
  getAddressTypesOptions(accountId).then((addressTypes) =>
    addresses.map((a) => {
      const addressType = find(addressTypes, ['value', a.type])
      return {
        type: addressType ? addressType.label : '',
        value: a.value.formattedAddress,
      }
    }),
  )

export default fetchAddressFieldset
