import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '../../../ui/Button/Button'
import StatusMessage, { useStatusMessage } from '../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../utilities/newforms'
import FormRender from '../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'
import isFormValid from '../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../utilities/newforms/getFormValues'
import SignPage from './SignPage/SignPage'
import SocialSignIn from './SocialSignIn/SocialSignIn'
import signInUser from './functions/signInUser'
import handleSignInErrors from './functions/handleSignInErrors'
import FieldRender from '../../../utilities/newforms/render/FieldRender'

function SignIn({ ...router }) {
  const [form, setForm] = useState(createForm({ formPattern: new SignInForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const { email, password } = getFormValues({ form })

    signInUser({ email, password }).catch((e) => {
      const errorMessage = handleSignInErrors({ code: e.code })
      if (errorMessage)
        setStatusMessage({
          show: true,
          type: 'fail',
          message: errorMessage,
          closeAfter: 5000,
        })
      setIsLoading(false)
    })
  }

  return (
    <SignPage nav="signup">
      <div className="AuthForm">
        <div className="AuthForm-Header">
          <h5 className="AuthForm-Title">Вход в аккаунт</h5>
        </div>
        <form className="AuthForm-Container Site-Fields" onSubmit={onSubmit}>
          {statusMessage.show && (
            <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
          )}
          <SocialSignIn
            buttonTitle="Войти с помощью Google"
            deviderText="или войти с помощью E-mail"
            setStatusMessage={setStatusMessage}
          />
          <FieldRender
            field={{ ...form.email, fieldId: 'email' }}
            form={form}
            setForm={setForm}
            showErrors={showErrors}
          />
          <div className="AuthForm-SignIn-PasswordField">
            <Link to="/auth/forgot-password" className="AuthForm-ForgotPassword">
              Забыли пароль?
            </Link>
            <FieldRender
              field={{ ...form.password, fieldId: 'password' }}
              form={form}
              setForm={setForm}
              showErrors={showErrors}
            />
          </div>
          <Button
            type="submit"
            className="AuthForm-Button"
            icon="long-arrow-right"
            title="Войти в аккаунт"
            fill="black"
            theme="solid"
            isLoading={isLoading}
            onClick={onSubmit}
            size={48}
          />
          <p className="AuthForm-MobileSignStep">
            Нет аккаунта? <Link to="/auth/sign-up">Зарегистрировать</Link>
          </p>
        </form>
      </div>
    </SignPage>
  )
}

class SignInForm {
  constructor() {
    this.email = {
      field: {
        fieldId: 'email',
        icon: 'envelope',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        placeholder: 'Введите ваш E-mail',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.password = {
      field: {
        fieldId: 'password',
        fieldType: 'input',
        icon: 'fingerprint',
        inputType: 'password',
        placeholder: 'Введите ваш пароль',
        label: 'Пароль',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(SignIn)
