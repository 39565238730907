import { find } from 'lodash'
import { getPhoneTypesOptions } from '../../../../../utilities/getOptions'

const fetchPhoneFieldset = ({ accountId, phones }) =>
  getPhoneTypesOptions(accountId).then((phoneTypes) =>
    phones.map((p) => {
      const phoneType = find(phoneTypes, ['value', p.type])
      return {
        type: phoneType ? phoneType.label : '',
        value: p.value.value,
      }
    }),
  )

export default fetchPhoneFieldset
