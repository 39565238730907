import {
  getAddOptions,
  getAddressTypesOptions,
  getDocCreationTypesOptions,
  getEmailTypesOptions,
  getMembersOptions,
  getPhoneTypesOptions,
} from '../../../../../../utilities/getOptions'
import { createForm } from '../../../../../../utilities/newforms'

const createContactForm = ({ accountId, formData, formPattern }) =>
  createForm({
    formData,
    formPattern,
    methods: {
      getDocCreationTypesOptions: () => getDocCreationTypesOptions(accountId),
      getAddOptions: () => getAddOptions(accountId),
      getPhoneTypesOptions: () => getPhoneTypesOptions(accountId),
      getEmailTypesOptions: () => getEmailTypesOptions(accountId),
      getMembersOptions: () => getMembersOptions({ accountId }),
      getAddressTypesOptions: () => getAddressTypesOptions(accountId),
    },
  })

export default createContactForm
