const fetchParamType = (paramType) => {
  switch (paramType) {
    case 'inputText':
      return 'Текстовое поле'
    case 'inputNumber':
      return 'Числовое поле'
    case 'checkbox':
      return 'Чекбокс'
    case 'checkboxGroup':
      return 'Чекбокс-группа'
    case 'option':
      return 'Выбор опций'
    default:
      break
  }
}

export default fetchParamType
