import React, { useState, useEffect } from 'react'
import './OrganizationPopUpManager.sass'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isEmpty } from 'lodash'
import PopUp from '../../../../../components/UI/PopUp/PopUp'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import getDoc from '../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import { createForm } from '../../../../../utilities/newforms'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import createOrganizationForm from '../../../scenes/OrganizationsList/OrganizationsManager/functions/createOrganizationForm'
import Tabs from '../../../../../ui/Tabs/Tabs'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import SceneContentHeader from '../../../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import submitOrganizationForm from '../../../scenes/OrganizationsList/OrganizationsManager/functions/submitOrganizationForm'
import OrganizationsOptions from '../OrganizationsOptions/OrganizationsOptions'
import OrganizationCard from '../../../OrganizationCard/OrganizationCard'
import Button from '../../../../../ui/Button/Button'

function OrganizationPopUpManager({
  superstructure,
  superstructureData,
  accountId,
  organizationId,
  uid,
  pushOrganization,
  close,
}) {
  const [superstructureForm, setSuperstructureForm] = useState(null)
  const [superstructureSections, setSuperstructureSections] = useState([])
  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [organizationFromDb, setOrganizationFromDb] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useEffect(() => {
    promiseAllValues({
      ...(superstructure
        ? {
            superstructureObject: getDoc({
              accountId,
              path: superstructure.object.path,
              docId: superstructure.object.docId,
            }),
            superstructureForm: getDoc({
              accountId,
              path: superstructure.form.path,
              docId: superstructure.form.docId,
            }).then((data) => data.sections),
          }
        : {}),
      organizationObject: getDoc({ accountId, path: 'config/contacts/objects', docId: 'organizationObject' }),
      organizationForm: getDoc({ accountId, path: 'config/contacts/forms', docId: 'organizationManager' }).then(
        (data) => data.sections,
      ),
      organizationData: organizationId
        ? getDoc({ accountId, path: 'organizations', docId: organizationId })
        : { responsible: uid },
    }).then((results) => {
      if (superstructure) {
        setSuperstructureForm(createForm({ formData: superstructureData, formPattern: results.superstructureObject }))
        setSuperstructureSections(results.superstructureForm)
      }
      setForm(
        createOrganizationForm({
          accountId,
          formData: results.organizationData,
          formPattern: results.organizationObject,
        }),
      )
      setSections(results.organizationForm)
    })
  }, [])

  const onSumbit = () => {
    if (activeTabIndex === 0) {
      if (!isFormValid({ form }) || (superstructure && !isFormValid({ form: superstructureForm }))) {
        return setShowErrors(true)
      }

      submitOrganizationForm({
        accountId,
        organizationId,
        form,
        setStatusMessage: () => null,
        setShowErrors,
        setIsLoading,
        uid,
        onSuccessFunc: (docId) => {
          pushOrganization({
            organizationId: docId,
            ...(superstructure ? getFormValues({ form: superstructureForm }) : {}),
          })
          close()
        },
      })
    } else {
      if ((superstructure && !isFormValid({ form: superstructureForm })) || !organizationFromDb) {
        return setShowErrors(true)
      }

      pushOrganization({
        organizationId: organizationFromDb,
        ...(superstructure ? getFormValues({ form: superstructureForm }) : {}),
      })
      close()
    }
  }

  return (
    <PopUp
      show
      title={`${organizationId ? 'Редактировать' : 'Добавить'} организацию`}
      submitButtons
      isLoading={isLoading}
      onSubmit={onSumbit}
      close={close}
    >
      <Tabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabs={[
          {
            ...(organizationId
              ? {
                  title: 'Организация',
                  icon: 'pencil-alt',
                }
              : {
                  title: 'Новая организация',
                  icon: 'plus',
                }),
            content: (
              <div className="OrganizationPopUpManager">
                {(!superstructure || (superstructure && superstructureForm && !isEmpty(superstructureSections))) &&
                form &&
                !isEmpty(sections) ? (
                  <>
                    {superstructure && (
                      <FormRender
                        sections={superstructureSections}
                        form={superstructureForm}
                        setForm={setSuperstructureForm}
                        errors={showErrors}
                      />
                    )}
                    <FormRender sections={sections} form={form} setForm={setForm} errors={showErrors} />
                  </>
                ) : (
                  <Spinner type="popup" />
                )}
              </div>
            ),
          },
          ...(!organizationId
            ? [
                {
                  title: 'Существующая организация',
                  icon: 'list-ul',
                  content: (
                    <div className="OrganizationPopUpManager">
                      {superstructure && (
                        <FormRender
                          sections={superstructureSections}
                          form={superstructureForm}
                          setForm={setSuperstructureForm}
                          errors={showErrors}
                        />
                      )}
                      <SceneContentHeader title="Выберите организацию" />
                      {organizationFromDb ? (
                        <OrganizationCard
                          organizationId={organizationFromDb}
                          expanded
                          buttons={[{ type: 'detach', onDetach: () => setOrganizationFromDb(null) }]}
                        />
                      ) : (
                        <OrganizationsOptions
                          activeDocId={organizationFromDb}
                          onElementClicked={(docId) => setOrganizationFromDb(docId)}
                        />
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </PopUp>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(OrganizationPopUpManager)
