export const ESTATE_ON_MOUNT = 'ESTATE_ON_MOUNT'
export const ESTATE_INIT_COMPONENT_WITH_DATA = 'ESTATE_INIT_COMPONENT_WITH_DATA'
export const ESTATE_UPDATE_COMPONENT_DATA = 'ESTATE_UPDATE_COMPONENT_DATA'
export const ESTATE_FETCH_PAGE = 'ESTATE_FETCH_PAGE'
export const ESTATE_PUSH_NEW_DATA = 'ESTATE_PUSH_NEW_DATA'
export const ESTATE_ESTATE_WAS_ADDED = 'ESTATE_ESTATE_WAS_ADDED'
export const ESTATE_ESTATE_WAS_MODIFIED = 'ESTATE_ESTATE_WAS_MODIFIED'
export const ESTATE_ESTATE_WAS_REMOVED = 'ESTATE_ESTATE_WAS_REMOVED'
export const ESTATE_ADD_ESTATE = 'ESTATE_ADD_ESTATE'
export const ESTATE_MODIFY_ESTATE = 'ESTATE_MODIFY_ESTATE'
export const ESTATE_REMOVE_ESTATE = 'ESTATE_REMOVE_ESTATE'

export const ESTATE_CONTACTS_MANAGER_SAVE_FORM = 'ESTATE_CONTACTS_MANAGER_SAVE_FORM'
export const ESTATE_SAVE_FORM = 'ESTATE_SAVE_FORM'
