import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '../../../../../../ui/Button/Button'
import './CreateAccount.sass'
import scrollTop from '../../../../../../utilities/scrollTop'
import Backdrop from '../../../../../../components/UI/Backdrop/Backdrop'
import AccountForm from './AccountForm'

function CreateAccount({ user }) {
  const [showAccountForm, setShowAccountForm] = useState(false)

  useEffect(() => {
    if (showAccountForm) scrollTop()
  }, [showAccountForm])
  return (
    <div className="Site-CreateAccount">
      <Backdrop show={showAccountForm} close={() => setShowAccountForm(false)} className="Site-Backdrop">
        <AccountForm close={() => setShowAccountForm(false)} />
      </Backdrop>
      {user.canCreateAccount ? (
        <Button
          onClick={() => setShowAccountForm(true)}
          title="Создать аккаунт"
          fill="black"
          icon="plus"
          theme="solid"
          size={48}
          className="AuthForm-Button"
        />
      ) : (
        <div className="Site-CreateAccount-Denied">
          <p>
            Для создания аккаунта в системе, пожалуйста, обратитесь в <Link to="/contact-us">поддержку</Link>
          </p>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(CreateAccount)
