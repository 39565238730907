import React, { useState, useEffect } from 'react'
import { isEmpty, find } from 'lodash'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../../../components/DeletePopUp/DeletePopUp'
import getDoc from '../../../../../../utilities/db/getDoc'
import deleteMember from './functions/deleteMember'

const DeleteMemberPopUp = ({ accountId, userId, uid, close }) => {
  const [memberData, setMemberData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteMember({ accountId, userId, uid }).then((rest) => {
      console.log(rest)
      close()
    })
  }

  useEffect(() => {
    getDoc({ path: 'accounts', docId: accountId }).then(({ members }) => {
      const member = find(members, ['userId', userId])
      setMemberData(member)
    })
  }, [])

  return (
    <DeletePopUp
      title="Удаление сотрудника"
      warningMessage={
        <>
          Вы уверены, что хотите удалить{' '}
          <span className="Bolder">
            "{!isEmpty(memberData) && `${memberData.lastName} ${memberData.name} ${memberData.middleName}`}"
          </span>
          ?
          <br />
          Все данные, как-либо связанные с этим этим сотрудником, будут сохранены.
          <br />
          Доступ к системе для этого пользователя будет прекращен моментально
        </>
      }
      fieldLabel="Чтобы удалить этого пользователя, введите его идентификатор:"
      identifier={userId}
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DeleteMemberPopUp)
