import isObject from 'lodash/isObject'

export const countPlanTotals = (offices, month, start, end) => {
  let sum = 0
  if (month) {
    offices.forEach((o) => {
      sum += o[month]
    })
  } else {
    for (let index = start; index <= end; index++) {
      offices.forEach((o) => {
        sum += o[index]
      })
    }
  }

  return {
    className: 'SalesPlans-AccentCell',
    content: sum,
  }
}

export const countAvailablePlan = (office, month, useAllSquads = true, planOnEdit) => {
  let totalPlan = office[month]
  let { squads } = office
  if (!useAllSquads) squads = office.squads.filter((s) => s.squadId !== planOnEdit.squadId)

  squads.forEach((s) => {
    totalPlan -= s[month].totalPlan
  })
  return totalPlan > 0 ? totalPlan : 0
}

export const countEmployeeAvailablePlan = (squadData, month, useAllEmployees = true, planOnEdit) => {
  let { totalPlan } = squadData[month]

  Object.entries(squadData[month].members).forEach(([k, v]) => {
    if (useAllEmployees) {
      totalPlan -= v.totalPlan
    } else if (k !== planOnEdit.employeeId) {
      totalPlan -= v.totalPlan
    }
  })

  return totalPlan > 0 ? totalPlan : 0
}

export const countQuaterSum = (squad, quaterNum) => {
  const quater = getQuater(quaterNum)
  return countTotalPlanSum(squad, quater.start, quater.end)
}

export const countTotalPlanSum = (squad, start, end) => {
  let totalSum = 0
  for (let index = start; index <= end; index++) {
    if (isObject(squad[index])) {
      totalSum += squad[index].totalPlan
    } else {
      totalSum += squad[index]
    }
  }
  return totalSum
}

export const countEmployeePlanSum = (squadData, employeeId, quaterNum, start, end) => {
  let quater = {}
  if (quaterNum) {
    quater = getQuater(quaterNum)
  } else {
    quater = { start, end }
  }

  let totalSum = 0
  for (let index = quater.start; index <= quater.end; index++) {
    totalSum += squadData[index].members[employeeId].totalPlan
  }
  return totalSum
}

const getQuater = (quaterNum) => {
  const quater = {
    start: null,
    end: null,
  }

  switch (quaterNum) {
    case 1:
      quater.start = 1
      quater.end = 3
      break
    case 2:
      quater.start = 4
      quater.end = 6
      break
    case 3:
      quater.start = 7
      quater.end = 9
      break
    case 4:
      quater.start = 10
      quater.end = 12
      break

    default:
      break
  }
  return quater
}
