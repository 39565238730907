import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import getDoc from '../../../../utilities/db/getDoc'

const DeleteOrganizationPopUp = ({ accountId, organizationId, closePopUp, uid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [organizationTitle, setOrganizationTitle] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    updateDoc({ accountId, path: 'organizations', docId: organizationId, data: { isDeleted: true }, uid }).then(() =>
      closePopUp(),
    )
  }

  useEffect(() => {
    getDoc({ accountId, path: 'organizations', docId: organizationId }).then((organization) =>
      setOrganizationTitle(organization.title),
    )
  }, [])

  return (
    <DeletePopUp
      title="Удаление организации"
      warningMessage={
        <>
          Вы уверены, что хотите удалить <span className="Bolder">"{organizationTitle}"</span>
          ?
          <br />
          Все данные, как-либо связанные с "{organizationTitle}
          ", будут сохранены.
        </>
      }
      fieldLabel="Чтобы удалить эту организацию, введите ее идентификатор:"
      identifier={organizationId}
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(DeleteOrganizationPopUp)
