import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import './PhotoManager.sass'
import { concat, isEmpty, cloneDeep } from 'lodash'
import arrayMove from 'array-move'
import PhotoTile from './PhotoTile'
import { photoObject, deletePhoto, setAvatar } from './functions'
import { useCallbacks } from '../../utilities/shared'
import SortableList from '../UI/SortableList/SortableList'
import useModuleInit from '../../utilities/newforms/changed/useModuleInit'
import promiseAllValues from '../../utilities/promiseAllValues'

function PhotoManager({ accountId, projectId, projectPath, formData, syncState }) {
  const [photos, setPhotos] = useModuleInit({ formData, initialState: [] })

  useEffect(() => {
    syncState(photos)
  }, [photos])

  const handlers = useCallbacks((callbacks) => {
    callbacks.onDrop = (acceptedFiles) => {
      Promise.all(
        acceptedFiles.map((f, i) =>
          promiseAllValues(
            new photoObject({
              file: f,
              isAvatar: !!(i === 0 && isEmpty(photos)),
            }),
          ),
        ),
      ).then((photoObjects) => setPhotos(concat(photos, photoObjects)))
    }
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handlers.onDrop })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newPhotos = arrayMove(photos, oldIndex, newIndex)
    setPhotos(newPhotos)
  }

  return (
    <div
      className="PhotoManager"
      // style={!isEmpty(photos) ? { gridTemplateRows: 'max-content 1fr' } : {}}
    >
      {!isEmpty(photos) && (
        <SortableList
          className="PhotoManager-PhotoGrid"
          axis="xy"
          onSortEnd={onSortEnd}
          pressDelay={200}
          items={photos.map((p, i) => (
            <PhotoTile
              key={`photoTile-${i}`}
              {...p}
              setAvatar={() => setAvatar({ photoIndex: i, photos, setPhotos })}
              onDelete={() =>
                deletePhoto({
                  accountId,
                  photoIndex: i,
                  photos,
                  setPhotos,
                  projectId,
                  docPath: projectPath,
                })
              }
            />
          ))}
        />
      )}
      <div className={['DragAndDrop-Zone'].join(' ')} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Перетятине фото в это поле...</p>
        ) : (
          <p>
            Перетяните несколько фото с компьютера, либо <span>нажмите здесь</span> для выбора фото вручную
          </p>
        )}
      </div>
    </div>
  )
}

export default PhotoManager
