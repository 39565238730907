import { functions } from '../../config/firebase'

export const deleteDoc = (docPath) => {
  var deleteFn = functions.httpsCallable('dbDeleteDoc')

  return deleteFn({ path: docPath })
    .then(() => 'ok')
    .catch(function (err) {
      console.log('Delete failed, see console,')
      console.warn(err)
    })
}
