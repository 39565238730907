import startOfToday from 'date-fns/startOfToday'
import endOfToday from 'date-fns/endOfToday'

const getTodayRange = () => {
  const startingDate = startOfToday()
  const endingDate = endOfToday()
  const label = 'Сегодня'

  return {
    label,
    startingDate,
    endingDate,
  }
}

export default getTodayRange
