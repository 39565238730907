import { isEmpty } from 'lodash'
import React from 'react'
import { TagComponent } from './TagCloud'
import './TableTagCloud.sass'

function TableTagCloud({ tags = [], style }) {
  return !isEmpty(tags) ? (
    <div className="TableTagCloud" style={style}>
      {tags.map((t) => (
        <TagComponent tag={t} />
      ))}
    </div>
  ) : null
}

export default TableTagCloud
