import React, { useState, useEffect } from 'react'
import './DealTypes.sass'
import { connect } from 'react-redux'
import smartTruncate from 'smart-truncate'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PieChart from '../../widgets/PieChart/PieChart'
import DataCounter from '../../widgets/DataCounter/DataCounter'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import { getServiceTypesOptions } from '../../../../utilities/getOptions/getServiceTypesOptions'
import getDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDeals'
import getDoneDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import countDealsAverageDuration from '../../../Deals/scenes/Dealslist/functions/countDealsAverageDuration'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'

function DealTypes({ accountId, uid, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const [dealTypes, setDealTypes] = useState(null)
  const [averageDuration, setAverageDuration] = useState(null)

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads, responsible } = resp

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      })
        .then((data) =>
          promiseAllValues({
            services: getServiceTypesOptions(accountId),
            deals: getDeals({ accountId, dateRange, responsible: data.responsible }),
            doneDeals: getDoneDeals({ accountId, dateRange, responsible: data.responsible }),
          }),
        )
        .then((data) => {
          setDealTypes(
            data.services.map((s) => ({
              values: [data.deals.filter((deal) => deal.need === s.value).length],
              text: `${smartTruncate(s.label, 15)} %v`,
            })),
          )
          setAverageDuration(countDealsAverageDuration({ deals: data.doneDeals }))
        })
    }
  }, [dateRange, resp])

  return (
    <div className="DealTypes Widgets-Merged">
      <div>
        <PieChart title="Типы сделок" series={dealTypes} isLoading={!dealTypes} counterPeriod={dateRange} />
      </div>
      <DataCounter
        title="Сред. длительность сделки"
        counters={[
          {
            value: `${averageDuration && averageDuration}`,
            theme: 'default',
            style: { color: '#39393D' },
          },
        ]}
        isLoading={isNull(averageDuration)}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(DealTypes)
