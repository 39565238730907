import find from 'lodash/find'

import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import countComission from './countComission'

const countEnds = ({ step, deals, dateRange, responsible }) => {
  const filteredData = deals.filter((d) => {
    const funnelStep = find(d.funnel, ['stepId', step.stepId])
    if (!funnelStep) return false
    if (!responsible.includes(d.responsible)) return false
    if (
      funnelStep.ended &&
      isAfter(funnelStep.ended.at.seconds * 1000, dateRange.startingDate) &&
      isBefore(funnelStep.ended.at.seconds * 1000, dateRange.endingDate)
    ) {
      return true
    }
    return false
  })

  return countComission(filteredData)
}

export default countEnds
