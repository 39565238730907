import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../../../components/DeletePopUp/DeletePopUp'
import { db } from '../../../../../../config/firebase'
import getDoc from '../../../../../../utilities/db/getDoc'
import rootDbPath from '../../../../../../utilities/db/rootDbPath'

const DeleteRolePopUp = ({ accountId, roleId, close }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    db.collection(`${rootDbPath(accountId)}/roles/`)
      .doc(roleId)
      .delete()
      .then(() => close())
  }

  useEffect(() => {
    getDoc({ accountId, path: 'roles', docId: roleId }).then((role) => setTitle(role.title))
  }, [])

  return (
    <DeletePopUp
      title="Удаление системной роли"
      warningMessage={
        <>
          Вы уверены, что хотите удалить системную роль <span className="Bolder">"{title}"</span>?
        </>
      }
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(DeleteRolePopUp)
