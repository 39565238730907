import React from 'react'
import Icon from '../../../components/UI/Icon/Icon'

export default function EventWithIconTemplate({ icon, customIcon, iconTheme, content, buttons }) {
  return (
    <>
      <div className={['EventIcon', ...(iconTheme ? [`EventIcon_theme_${iconTheme}`] : [])].join(' ')}>
        {customIcon ? <img src={customIcon} alt="" /> : <Icon name={icon} size="12" weight="regular" />}
      </div>
      <div className="Container">
        <div className="Content">{content}</div>
        {buttons && (
          <div className="Buttons">
            {buttons.map((button) => {
              return <button className={button.class}>{button.label}</button>
            })}
          </div>
        )}
      </div>
    </>
  )
}
