import { db } from '../../config/firebase'

export const deleteDocs = ({ path, docIds }) => {
  const batch = db.batch()
  docIds.forEach((docId) => batch.delete(db.collection(path).doc(docId)))
  return batch
    .commit()
    .then(() => 'success')
    .catch((e) => {
      console.log('🚀 ~ file: deleteDocs.js ~ line 7 ~ deleteDocs ~ e', e)
      return 'fail'
    })
}
