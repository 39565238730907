import React from 'react'
import './FunnelBlock.sass'

function FunnelBlock({ blockContent, subBlockContent, className = '' }) {
  return (
    <div className={['FunnelBlock', ...(className ? [className] : [])].join(' ')}>
      <div className="FunnelBlock-BlockContent">{blockContent}</div>
      <div className="FunnelBlock-SubBlockContent">{subBlockContent}</div>
    </div>
  )
}

export default FunnelBlock
