import React from 'react'
import { withRouter } from 'react-router-dom'
import './TaskColumn.sass'

import TaskCard from '../TaskCard/TaskCard'
import AddTaskCard from './AddTaskCard'
import IconicButton from '../../../../../components/UI/IconicButton/IconicButton'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import declOfNum from '../../../../../utilities/declOfNum'

function TaskColumn({
  columnId,
  columnStyles,
  addTaskButton,
  newTaskConfig,
  label,
  tasks,
  totalTasks,
  hasMore,
  ...router
}) {
  return (
    <>
      <div className="Task-Column">
        <div className="Column-Title">
          <div className="Title-Marker" style={{ backgroundColor: columnStyles.markerColor }} />
          <div className="Column-TitleInfo">
            <div className="Title-Text">{label}</div>
            <div className="Column-TasksCounter">
              <span>{totalTasks}</span> {declOfNum(totalTasks, ['задача', 'задачи', 'задач'])}
            </div>
          </div>
          {/* <div className="Title-Button">
            <IconicButton type="option" icon="ellipsis-h" />
          </div> */}
        </div>
        <div className="Column-Body">
          {addTaskButton && <AddTaskCard columnId={columnId} newTaskConfig={newTaskConfig} />}
          {tasks.map((task) => (
            <TaskCard columnStyles={columnStyles} {...task} router={router} />
          ))}
          {hasMore && (
            <div className="Task-Column-Loading">
              <Spinner type="popup" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default withRouter(TaskColumn)
