import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import rootPath from '../../appTree/rootPath'
import Market from './Market'

function MarketRoot({ accountId, permissions }) {
  console.log('🚀 ~ file: MarketRoot.jsx ~ line 8 ~ MarketRoot ~ permissions', permissions)
  return (
    <Switch>
      <Route
        path={[
          `${rootPath(accountId)}/settings/market/apps/:categoryId`,
          `${rootPath(accountId)}/settings/market/apps`,
        ]}
        render={() => <Market permissions={permissions} />}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(MarketRoot)
