import React from 'react'
import { connect } from 'react-redux'
import './Settings.sass'
import { flatten } from 'lodash'
import { Route } from 'react-router'
import NavPanel from '../../ui/NavPanel/NavPanel'
import marketNavBlock from '../Market/marketNavBlock'

function Settings({ accountId, subsections }) {
  const companyBlock = {
    title: 'Компания',
    items: subsections
      .filter((s) => ['offices', 'members', 'salesPlans', 'roles'].includes(s.id) && true)
      .map((i) => {
        i.type = 'navlink'
        return i
      }),
  }

  const siteBlock = {
    title: 'Сайт',
    items: subsections
      .filter((s) => ['siteNews', 'siteFeeds'].includes(s.id) && true)
      .map((i) => {
        i.type = 'navlink'
        return i
      }),
  }
  const blocks = [companyBlock, siteBlock, ...(marketNavBlock(subsections) ? [marketNavBlock(subsections)] : [])]
  const availableRoutes = []
  blocks.forEach((b) => {
    availableRoutes.push(b.items)
  })
  const routes = flatten(availableRoutes)

  return (
    <div className="Module-Container Settings-Module">
      <div className="Settings">
        <NavPanel blocks={blocks} />
        {/* <CompanySettings /> */}
        {routes && (
          <div className="Settings-Container">
            {routes.map((r) => (
              <Route path={r.path} render={() => <r.component permissions={r.permissions} />} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, user: state.auth.user })

export default connect(mapStateToProps)(Settings)
