import React, { useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import './TaskExpireDate.sass'

import { connect } from 'react-redux'
import TaskNavElement from '../TaskNavElement'
import updateTask from '../../../../functions/updateTask'
import TaskDatePicker from '../../TaskDatePicker/TaskDatePicker'

function TaskExpirationDate({ accountId, icon, label, data: { taskId, startDate, endDate }, uid, canBeEdit }) {
  const updateTaskExpirationDate = (date) => {
    const dateToUpdate = {
      startDate: date,
      endDate: date,
    }
    updateTask({ accountId, taskId, taskData: dateToUpdate, uid })
  }

  return (
    <TaskNavElement icon={icon} label={label} canBeEdit={canBeEdit}>
      <TaskDatePicker startDate={startDate} endDate={endDate} onSaveButtonClicked={updateTaskExpirationDate} />
    </TaskNavElement>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

export default connect(mapStateToProps)(TaskExpirationDate)
