import React, { useState, useEffect } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { db } from '../../../../../../../config/firebase'
import { createRequiredField, createCheckbox } from '../../../../../../../utilities/forms/createFormElement'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import * as actions from '../../../../../store/actions/tasksActions'
import './TaskResults.sass'
import updateTask from '../../../functions/updateTask'
import getTaskComment from '../../../functions/getTaskComment'
import NewTaskConstructor from '../NewTaskConstructor/NewTaskConstructor'
import pushEventToHistory from '../../../../../../History/functions/pushEventToHistory'
import getTaskHistoryEventObject from '../../../../../../History/functions/getTaskHistoryEventObject'
import rootDbPath from '../../../../../../../utilities/db/rootDbPath'
import { addDoc } from '../../../../../../../utilities/db/addDoc'

function TaskResults({ accountId, taskId, taskData, uid, showTaskResultsForm, submitCommentAsTaskResult }) {
  const [lastAuthorComment, setLastAuthorComment] = useState(null)
  const [resultsForm, setResultsForm] = useState(initFields(lastAuthorComment, submitCommentAsTaskResult))
  const [showFieldErrors, setShowFieldErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [taskConstructor, updateTaskConstructor] = useState({})
  const [attachableData, setAttachableData] = useState({})

  useEffect(() => {
    db.collection(`${rootDbPath(accountId)}/tasks/${taskId}/comments/`)
      .where('created.by', '==', uid)
      .orderBy('created.at', 'desc')
      .limit(1)
      .get()
      .then((docs) =>
        docs.forEach((doc) => {
          setLastAuthorComment({ commentId: doc.id, ...doc.data() })
        }),
      )
  }, [])

  useEffect(() => {
    setResultsForm(initFields(lastAuthorComment, submitCommentAsTaskResult))
  }, [lastAuthorComment])

  useEffect(() => {
    if (!isEmpty(taskData.contacts))
      setAttachableData({ section: 'contacts', objectId: taskData.contacts[0].contactId })
    if (!isEmpty(taskData.leads)) setAttachableData({ section: 'leads', objectId: taskData.leads[0].leadId })
    if (!isEmpty(taskData.sales)) setAttachableData({ section: 'sales', objectId: taskData.sales[0].saleId })
  }, [taskData])

  const controllFieldsRelations = (field) => {
    if (field.fieldId === 'resultComment' && !submitCommentAsTaskResult) {
      const lastCommentField = find(resultsForm, ['fieldId', 'useLastComment'])
      if (lastCommentField.value === true) field.shouldRender = false
      else field.shouldRender = true
    }
    return field
  }

  const addNewTask = () => {
    if (taskConstructor.createNewTask)
      return addDoc({ accountId, path: 'tasks', data: taskConstructor.taskConfig }).then((tId) =>
        pushEventToHistory({
          section: attachableData.section,
          objectId: attachableData.objectId,
          eventObject: getTaskHistoryEventObject({ taskId: tId, uid }),
        }),
      )
    return Promise.resolve('')
  }

  const submitResults = () => {
    const commentField = find(resultsForm, ['fieldId', 'resultComment'])
    const useLastAuthorCommentField = find(resultsForm, ['fieldId', 'useLastComment'])
    setShowFieldErrors(false)

    if (commentField.shouldRender && commentField.value.trim()) {
      setIsLoading(true)
      return addNewTask().then(() =>
        updateTask({ accountId, taskId, taskData: { isFinished: true, result: commentField.value }, uid }).then(() => {
          showTaskResultsForm(false)
        }),
      )
    }

    if (Boolean(useLastAuthorCommentField.value) === true && !isEmpty(lastAuthorComment)) {
      setIsLoading(true)
      return addNewTask().then(() =>
        updateTask({ accountId, taskId, taskData: { isFinished: true, result: lastAuthorComment.text }, uid }).then(
          () => {
            showTaskResultsForm(false)
          },
        ),
      )
    }

    if (submitCommentAsTaskResult) {
      setIsLoading(true)
      return getTaskComment({ accountId, taskId, commentId: submitCommentAsTaskResult })
        .then((taskComment) =>
          addNewTask().then(() =>
            updateTask({ accountId, taskId, taskData: { isFinished: true, result: taskComment.text }, uid }),
          ),
        )
        .then(() => {
          showTaskResultsForm(false)
        })
    }

    return setShowFieldErrors(true)
  }

  return (
    <PopUp
      show
      title="Завершение задачи"
      submitButtons
      onSubmit={submitResults}
      onCancel={() => showTaskResultsForm(false)}
      isLoading={isLoading}
      className="TaskResults-PopUp"
      close={() => showTaskResultsForm(false)}
    >
      <div className="TaskResults-Container">
        {renderFields(resultsForm, setResultsForm, showFieldErrors, controllFieldsRelations)}
        {submitCommentAsTaskResult && <p>Задача будет завершена с результатом отмеченным в выбранном комментарие </p>}
        <NewTaskConstructor
          pushComponentControlls={updateTaskConstructor}
          attachableData={attachableData}
          uid={uid}
          showCreateNewTask
          newTaskConfig={{
            title: taskData.title,
            description: taskData.description,
            contacts: !isEmpty(taskData.contacts) ? [taskData.contacts[0].contactId] : [],
            leads: !isEmpty(taskData.leads) ? [taskData.leads[0].leadId] : [],
            sales: !isEmpty(taskData.sales) ? [taskData.sales[0].saleId] : [],
            estate: !isEmpty(taskData.estate) ? [taskData.estate[0].estateId] : [],
            responsible: taskData.responsible.map((r) => r.responsibleId),
            tags: taskData.tags.map((t) => t.tagId),
          }}
        />
      </div>
    </PopUp>
  )
}

const initFields = (lastAuthorComment, submitCommentAsTaskResult) => {
  const fields = [
    {
      fieldId: 'resultComment',
      ...createRequiredField('textarea-autosize', 'text', 'Добавить результат выполнения задачи'),
      shouldRender: !submitCommentAsTaskResult,
    },
    {
      fieldId: 'useLastComment',
      ...createCheckbox('Использовать свой последний комментарий в качестве результата задачи'),
      shouldRender: !isEmpty(lastAuthorComment) && !submitCommentAsTaskResult,
    },
  ]

  return fields
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  submitCommentAsTaskResult: state.tasks.submitCommentAsTaskResult,
})

const mapDispatchToProps = (dispatch) => ({
  showTaskResultsForm: (payload) => dispatch(actions.tasksShowResultsForm(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskResults)
