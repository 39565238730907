import { find } from 'lodash'
import getCollection from '../../../../../../utilities/db/getCollection'
import getDoc from '../../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import mergeFunnelWithPattern from '../../../../../Funnel/functions/mergeFunnelWithPattern'

const fetchFunnel = ({ accountId, needId, funnel }) =>
  promiseAllValues({
    funnelId: getDoc({ accountId, path: 'config/global/services', docId: needId }).then(({ funnelId }) => funnelId),
    funnels: getCollection({ accountId, path: 'config/deals/funnels', docIdName: 'funnelId' }),
  }).then((results) => {
    const pattern = find(results.funnels, ['funnelId', results.funnelId])
    return mergeFunnelWithPattern({ funnel, pattern: pattern.steps })
  })

export default fetchFunnel
