const { merge } = require('lodash')
const getCurDateWithUser = require('../getCurDateWithUser')
const addDoc = require('./addDoc')
const rootDbPath = require('./rootDbPath')

const setDoc = ({ db, accountId, path, docId, data, uid = null }) => {
  let dbPath
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  let dataToSet = data
  if (uid) {
    dataToSet = merge(data, {
      created: getCurDateWithUser(uid),
      updated: getCurDateWithUser(uid),
    })
  }

  if (docId) {
    return db
      .collection(dbPath)
      .doc(docId)
      .set(dataToSet)
      .then(() => docId)
      .catch((e) => {
        console.log('🚀 ~ file: setDoc.js ~ line 25 ~ setDoc ~ e', e)
        return 'fail'
      })
  }
  return addDoc({ db, accountId, path, data })
}

module.exports = setDoc
