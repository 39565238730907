import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isObject } from 'lodash'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import { addDoc } from '../../../../../../../utilities/db/addDoc'
import { logError } from '../../../../../../../utilities/db/logError'
import { createForm } from '../../../../../../../utilities/newforms'
import getFormValues from '../../../../../../../utilities/newforms/getFormValues'
import FormRender from '../../../../../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../../../../../utilities/newforms/validation/isFormValid'
import roleObject from './roleObject'
import getDoc from '../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import { getCurDateWithUser } from '../../../../../../../utilities/date/getCurDateWithUser'
import './ManageRolePopUp.sass'
import fillRoleObjectWithData from '../../functions/fillRoleObjectWithData'

function ManageRolePopUp({ accountId, solution, roleId, close, uid }) {
  const [form, setForm] = useState(
    !roleId
      ? createForm({
          formData: { created: getCurDateWithUser(uid), updated: getCurDateWithUser(uid) },
          formPattern: roleObject(solution),
        })
      : null,
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (roleId) {
      getDoc({ accountId, path: 'roles', docId: roleId }).then((formData) =>
        setForm(createForm({ formPattern: fillRoleObjectWithData({ formData, solution }) })),
      )
    }
  }, [roleId])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })
    const dataToSave = {
      ...values,
      modules: parseModulesValues(values.modules),
    }

    let dbOperation
    if (roleId) {
      dbOperation = updateDoc({ accountId, path: 'roles', docId: roleId, data: dataToSave, uid })
    } else {
      dbOperation = addDoc({ accountId, path: 'roles', data: dataToSave })
    }
    dbOperation
      .then(() => close())
      .catch((error) => {
        setIsLoading(false)
        logError({ error })
      })
  }

  return (
    <PopUp
      show
      title={`${roleId ? 'Редактировать' : 'Добавить'} роль`}
      submitButtons
      onSubmit={onSubmit}
      close={close}
      isLoading={isLoading}
    >
      <div className="RolePopUp">
        <FormRender
          sections={[{ fields: ['title', 'occupation'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
          sectionsSkeleton={[2]}
        />
      </div>
    </PopUp>
  )
}

export const parseModulesValues = (modules) => {
  const data = {}
  modules.forEach((m) => {
    const subsections = {}
    m.subsections.forEach((s) => {
      const permissions = {}
      const options = {}
      Object.entries(s.permissions).forEach(([pKey, pVal]) => (permissions[pKey] = pVal.value))

      if (s.options) {
        Object.entries(s.options).forEach(([pKey, pVal]) => (options[pKey] = pVal.value))
      }
      subsections[s.subsectionId] = { permissions, options }
    })
    data[m.moduleId] = subsections
  })

  return data
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  solution: state.auth.account.data.solution,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(ManageRolePopUp)
