import { chunk } from 'lodash'

const listFetchPage = async ({ collection, fetchFunction, page }) => {
  const docsByChunks = chunk(collection, 20)
  const totalPages = docsByChunks.length

  const chunkToFetch = docsByChunks[page - 1]
  const fetchedChunk = await Promise.all(chunkToFetch.map((d) => fetchFunction(d)))

  return { displayedData: fetchedChunk, totalPages }
}

export default listFetchPage
