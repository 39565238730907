import { useEffect, useState } from 'react'
import { createForm } from './createForm'
import useSystemFieldsMethods from './useSystemFieldsMethods'

const useFormLabels = ({ accountId, formPattern, formData }) => {
  const [form, setForm] = useState(null)
  const methods = useSystemFieldsMethods({ accountId, form })
  const [fetchedLabels, setFetchedLabels] = useState(null)

  useEffect(() => {
    if (formPattern && formData) {
      setForm(createForm({ formData, formPattern, methods }))
    }
  }, [formPattern, formData])

  useEffect(() => {
    if (form) {
      const labels = []
      Object.entries(formData).forEach(([k, v]) => {
        if (k === 'isDeleted' && v === false) return
        labels.push({
          fieldId: k,
          label: form[k].label || form[k].config.label,
        })
      })
      setFetchedLabels(labels)
    } else {
      setFetchedLabels(null)
    }
  }, [form])

  return fetchedLabels
}

export default useFormLabels
