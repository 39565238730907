import { cloneDeep } from 'lodash'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import fetchMemberByUserId from '../../../../../Settings/Company/components/Members/Memberslist/functions/fetchMemberByUserId'
import fetchAddressFieldset from '../../../Contactlist/functions/fetchAddressFieldset'
import fetchAdSource from '../../../Contactlist/functions/fetchAdSource'
import fetchCreationType from '../../../Contactlist/functions/fetchCreationType'
import fetchPhoneFieldset from '../../../Contactlist/functions/fetchPhoneFieldset'

const fetchOrganizationObject = ({ accountId, doc }) => {
  const contactClone = cloneDeep(doc)
  return promiseAllValues({
    adSource: fetchAdSource({ accountId, docId: doc.adSource }),
    creationType: fetchCreationType({ accountId, docId: doc.creationType }),
    address: fetchAddressFieldset({ accountId, addresses: doc.address }),
    phone: fetchPhoneFieldset({ accountId, phones: doc.phone }),
    responsible: fetchMemberByUserId({ accountId, userId: doc.responsible }),
  }).then((fetchedContact) => ({ ...contactClone, ...fetchedContact }))
}

export default fetchOrganizationObject
