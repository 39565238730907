import React from 'react'
import './Auth.sass'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import SignIn from './SignIn'
import SignUp from './SignUp'
import Confirmation from './Confirmation/Confirmation'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import redirectAfterSignOutLink from './functions/redirectAfterSignOutLink'
import redirectToPrevPath from './functions/redirectToPrevPath'

function Auth({ user }) {
  return !user ? (
    <Switch>
      <Route path="/auth/sign-in" exact component={SignIn} />
      <Route path="/auth/sign-up" exact component={SignUp} />
      <Route path="/auth/forgot-password" exact component={ForgotPassword} />
      <Route path="/auth">
        <Redirect to={redirectAfterSignOutLink() || '/auth/sign-in'} />
      </Route>
    </Switch>
  ) : (
    <Switch>
      {!user.emailVerified || !user.phoneVerified ? (
        <Switch>
          <Route path="/auth/confirmation" component={Confirmation} />
          <Route path="/auth">
            <Redirect to="/auth/confirmation" />
          </Route>
        </Switch>
      ) : (
        <Redirect to={redirectAfterSignOutLink() || redirectToPrevPath() || '/'} />
      )}
    </Switch>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(Auth)
