import { find } from 'lodash'

function hasAccountAccess({ user, account }) {
  if (user) {
    return user.systemRole === 'god' ? true : find(account.members, ['userId', user.uid])
  }
  return false
}

export default hasAccountAccess
