import sendSms from '../../../../../modules/Sms/functions/sendSms'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import genNewCode from './genNewCode'

const sendPhoneConfirmationCode = ({ uid, phone }) => {
  const code = genNewCode()
  return Promise.all([
    updateDoc({ path: 'users', docId: uid, data: { confirmationCode: code } }),
    sendSms({ phone, message: `Ваш проверочный код: ${code}` }),
  ])
}
export default sendPhoneConfirmationCode
