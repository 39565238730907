import { omit } from 'lodash'
import membersDbForm from '../modules/Settings/Company/components/Members/Memberslist/MembersManager/membersDbForm'
import { getCurDateWithUser } from '../utilities/date/getCurDateWithUser'
import { setDoc } from '../utilities/db/setDoc'
import { updateDoc } from '../utilities/db/updateDoc'
import getValuesFromPattern from '../utilities/newforms/getValuesFromPattern'
import accountObject from '../website/pages/Profile/Accounts/AccountDbForm'
import installContacts from './config/contacts'
import installDeals from './config/deals'
import installGlobal from './config/global'
import installOffices from './config/offices'
import installTasks from './config/tasks'
import addContactsData from './data/contacts'
import addOrganizationsData from './data/organizations'
import addDealsData from './data/deals'
import addTasksData from './data/tasks'
import addOfficeData from './data/offices'
import installEstate from './config/estate'

// TEST USER 1 F6ywHLXaOQOQLMqWKanREa9j0Ug1
// TEST USER 2 A3kHwtaUlgdyTKEMkJ0vlsSdz0F3
// TEST USER 3 O7XN6hRIRjZLqREQO5ljwlQiiqm1

const install = ({ title = '', identifier, user, solution, withData }) => {
  const account = getValuesFromPattern({
    formData: {
      title,
      owner: user.uid,
      isActive: false,
      solution,
      members: [
        omit(
          getValuesFromPattern({
            formData: {
              lastName: user.lastName,
              name: user.name,
              middleName: user.middleName,
              phone: user.phone,
              bdate: user.bdate,
              roleId: 'root',
              officeId: 'root',
              statusId: 'active',
              userId: user.uid,
              created: getCurDateWithUser(user.uid),
              updated: getCurDateWithUser(user.uid),
            },
            formPattern: membersDbForm,
          }),
          ['userSearch'],
        ),
      ],
    },
    formPattern: accountObject,
  })

  return setDoc({ path: 'accounts', data: account, docId: identifier }).then((accountId) =>
    Promise.all([
      installContacts({ accountId, uid: user.uid }),
      installDeals({ accountId, uid: user.uid }),
      installGlobal({ accountId, uid: user.uid }),
      installOffices({ accountId, uid: user.uid }),
      installTasks({ accountId, uid: user.uid }),
      ...(solution === 'estate' ? [installEstate({ accountId, uid: user.uid })] : []),
    ]).then(() =>
      Promise.all([
        addOfficeData({ accountId, uid: user.uid }),
        ...(withData
          ? [
              addContactsData(accountId),
              addOrganizationsData(accountId),
              addDealsData(accountId),
              addTasksData({ accountId, uid: user.uid }),
            ]
          : []),
      ]).then(() => updateDoc({ path: 'accounts', docId: accountId, data: { isActive: true } }).then(() => accountId)),
    ),
  )
}

export default install
