import { find, isArray } from 'lodash'

const fetchDefaultFields = ({ doc, formPattern }) => {
  const fetchedObject = {}

  Object.entries(doc).forEach(([k, v]) => {
    if (
      formPattern.hasOwnProperty(k) &&
      formPattern[k].field.fieldType === 'select' &&
      isArray(formPattern[k].field.getOptions)
    ) {
      const activeOption = find(formPattern[k].field.getOptions, ['value', doc[k]])
      if (activeOption && doc[k] !== '') {
        fetchedObject[k] = activeOption.label
      } else {
        fetchedObject[k] = ''
      }
    }
  })

  return fetchedObject
}

export default fetchDefaultFields
