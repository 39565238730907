import React, { useState, useEffect } from 'react'
import './UsersTopRating.sass'
import { orderBy, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import WidgetTile from '../../widgets/WidgetTile/WidgetTile'
import UserAvatar from '../../../../components/UserAvatar/UserAvatar'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import getDoneDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import countEarnings from '../../functions/countEarnings'
import getSalesTotalPlanFor from '../../../Settings/Company/components/SalesPlans/functions/getSalesTotalPlanFor'
import countDonePercent from '../../functions/countDonePercent'
import countRatePercent from '../../functions/countRatePercent'
import countConversionToDeal from '../../functions/countConversionToDeal'
import countConversionToFail from '../../functions/countConversionToFail'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getFailDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getFailDeals'

function UsersTopRating({ accountId, ...router }) {
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })
  const [users, setUsers] = useState(null)

  useEffect(() => {
    if (!isEmpty(dateRange)) {
      const { offices, squads } = resp
      let doneDeals = []
      let failDeals = []
      let employees = []

      promiseAllValues({
        doneDeals: getDoneDeals({ accountId, dateRange }),
        failDeals: getFailDeals({ accountId, dateRange }),
      })
        .then((obj) => {
          doneDeals = obj.doneDeals
          failDeals = obj.failDeals
          return getEmployeesFrom({ accountId, offices, squads, returnEmployeesAs: 'fetchedArray' })
        })
        .then((emp) => {
          employees = emp
          return Promise.all(
            employees.map((e) =>
              getSalesTotalPlanFor({ accountId, years: dateRange.years, responsible: [e.employeeId] }),
            ),
          )
        })
        .then((totalPlans) => {
          const employeesMotivationPlans = {}

          employees.forEach((e, ind) => (employeesMotivationPlans[e.employeeId] = totalPlans[ind]))

          return Promise.all(
            employees.map((e) => {
              const earnings = countEarnings({ doneDeals, failDeals, responsible: [e.employeeId] })

              return promiseAllValues({
                employeeId: e.employeeId,
                name: `${e.lastName} ${e.name} ${e.middleName}`,
                avatar: e.avatar,
                earnings: earnings.earnings,
                averageCheck: earnings.averageCheck,
                contracts: '-',
                deals: doneDeals.filter((ds) => e.employeeId === ds.responsible).length,
                donePlan: countDonePercent(earnings.earnings, employeesMotivationPlans[e.employeeId]),
                doneRate: countRatePercent(
                  dateRange.startingDate,
                  dateRange.endingDate,
                  earnings.earnings,
                  employeesMotivationPlans[e.employeeId],
                ),
                dealConversion: countConversionToDeal({ accountId, dateRange, responsible: [e.employeeId] }),
                failureConversion: countConversionToFail({ accountId, dateRange, responsible: [e.employeeId] }),
              })
            }),
          )
        })
        .then((fetchedEmployees) =>
          setUsers(orderBy(fetchedEmployees, ['earnings', 'donePlan', 'averageCheck'], ['desc', 'desc', 'desc'])),
        )
    }
  }, [dateRange])

  return (
    <WidgetTile title="Рейтинг лучших" isLoading={!users} counterPeriod={dateRange} className="UsersTopRating-Widget">
      <div className="UsersTopRating">
        {users && users.map((u, i) => <RatingLine key={u.employeeId} top={i + 1} {...u} />)}
      </div>
    </WidgetTile>
  )
}

function RatingLine({
  top,
  employeeId,
  name,
  avatar,
  earnings,
  averageCheck,
  contracts,
  deals,
  donePlan,
  doneRate,
  dealConversion,
  failureConversion,
}) {
  return (
    <div
      className={['RatingLine', ...(top === 1 ? ['RatingLine-Winner'] : top <= 3 ? ['RatingLine-Top'] : [])].join(' ')}
    >
      <div className="UserBlock">
        <UserAvatar size={top === 1 ? 64 : top === 2 ? 48 : top === 3 ? 36 : 24} url={avatar.publicUrl} />
        <p
          className={[
            'TopPlaceMarker',
            ...(top === 1
              ? ['TopPlaceMarker_theme_gold']
              : top === 2
              ? ['TopPlaceMarker_theme_silver']
              : top === 3
              ? ['TopPlaceMarker_theme_bronze']
              : []),
          ].join(' ')}
        >
          {top} место
        </p>
      </div>
      <div className="DataBlock">
        <div className="UserName">{name}</div>
        <div className="ProgressBar">
          <span className="ProgressMarker" style={{ width: `${donePlan > 100 ? 100 : donePlan}%` }} />
        </div>
        <div className="Counters">
          <CounterBlock label="Выручка" value={earnings} calcType="руб" borderRight />
          <CounterBlock label="Средний чек" value={averageCheck} calcType="руб" borderRight />
          <CounterBlock label="Договора" value={contracts} borderRight />
          <CounterBlock label="Сделки" value={deals} borderRight />
          <CounterBlock label="Факт" value={donePlan} calcType="%" />
          <CounterBlock label="Темп" value={doneRate} calcType="%" borderRight />
          <CounterBlock label="Конв. в победу" value={dealConversion} calcType="%" />
          <CounterBlock label="Конв. в проигрыш" value={failureConversion} calcType="%" />
        </div>
      </div>
    </div>
  )
}

function CounterBlock({ label, value, calcType = null, borderRight }) {
  return (
    <div className="CounterBlock" style={!borderRight ? { border: 0 } : {}}>
      <p className="CounterBlock-Counter">
        <NumberFormat value={value} displayType="text" thousandSeparator={' '} />
        {calcType && <span className="Counter-CalcType"> {calcType}</span>}
      </p>
      <p className="CounterBlock-Label">{label}</p>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(UsersTopRating)
