import { updateDoc } from '../../../../../../utilities/db/updateDoc'
import { firebase } from '../../../../../../config/firebase'
import getDoc from '../../../../../../utilities/db/getDoc'

const detachContactFromDeal = ({ accountId, dealId, ctForm, setCtForm, doc, uid }) => {
  const newForm = ctForm.filter((c) => doc.data.id !== c.id)
  const { type } = doc

  if (dealId) {
    updateDoc({
      accountId,
      path: doc.data.type === 'contact' ? 'contacts' : 'organizations',
      docId: doc.data.id,
      data: { deals: firebase.firestore.FieldValue.arrayRemove(dealId) },
      uid,
    })
    getDoc({ accountId, path: 'deals', docId: dealId }).then((deal) =>
      updateDoc({
        accountId,
        path: 'deals',
        docId: dealId,
        data: { [type]: deal[type].filter((c) => c.id !== doc.data.id) },
      }),
    )
  }

  setCtForm(newForm)
}

export default detachContactFromDeal
