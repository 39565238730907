import { firebase } from '../../../config/firebase'

export const getFileWithInternalLink = (link, setFile) => {
  switch (link) {
    case 'error':
      break
    default:
      firebase
        .storage()
        .ref()
        .child(link)
        .getDownloadURL()
        .then((url) => {
          setFile(url)
        })
      break
  }
}
