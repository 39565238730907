import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'

const getCommentObject = (message, uid) => ({
  type: 'comment',
  message,
  created: getCurDateWithUser(uid),
  updated: getCurDateWithUser(uid),
})

export default getCommentObject
