import { cloneDeep, findIndex } from 'lodash'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import getDoc from '../../../../utilities/db/getDoc'
import { db } from '../../../../config/firebase'
import rootDbPath from '../../../../utilities/db/rootDbPath'

const onParamStatusChanged = ({ paramId, status, estateCategoryId, estateTypeId, uid, accountId }) => {
  getDoc({ path: `${rootDbPath(accountId)}/config/estate/params`, docId: paramId, docIdName: 'paramId' }).then(
    (paramData) => {
      const index = findIndex(paramData.estateTypes, { estateTypeId, estateCategoryId })
      const newParamStatus = { estateCategoryId, estateTypeId, status }
      const estateTypes = cloneDeep(paramData.estateTypes)
      if (index !== -1) {
        estateTypes.splice(index, 1, newParamStatus)
      } else {
        estateTypes.push(newParamStatus)
      }
      db.collection(`${rootDbPath(accountId)}/config/estate/params`)
        .doc(paramId)
        .update({ estateTypes, updated: getCurDateWithUser(uid) })
    },
  )
}

export default onParamStatusChanged
