import { getHours, getMinutes } from 'date-fns'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import isYesterday from 'date-fns/isYesterday'
import { formatDateAsDayMonthYear, formatDateAsHHMM } from '../../../../../utilities/date/dateFormat'

const formatExpirationDate = ({ startDate, endDate }) => {
  const dateToFormat = startDate.seconds * 1000

  let dateName = ''

  if (isYesterday(dateToFormat)) {
    dateName = 'Вчера'
  } else if (isToday(dateToFormat)) {
    dateName = 'Сегодня'
  } else if (isTomorrow(dateToFormat)) {
    dateName = 'Завтра'
  } else {
    dateName = formatDateAsDayMonthYear(startDate)
  }

  let formatedDate = dateName

  if (
    getHours(dateToFormat) === 23 &&
    getMinutes(dateToFormat) === 59 &&
    getHours(endDate.seconds * 1000) === 23 &&
    getMinutes(endDate.seconds * 1000) === 59
  ) {
    formatedDate = `${formatedDate}, Весь день`
  } else if (
    getHours(dateToFormat) !== getHours(endDate.seconds * 1000) &&
    getMinutes(dateToFormat) !== getMinutes(endDate.seconds * 1000)
  ) {
    formatedDate = `${formatedDate}, ${formatDateAsHHMM(startDate)} - ${formatDateAsHHMM(endDate)}`
  } else {
    formatedDate = `${formatedDate}, ${formatDateAsHHMM(startDate)}`
  }

  return formatedDate
}

export default formatExpirationDate
