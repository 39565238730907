const getAddressObject = ({ inputAddress = '' } = {}) => ({
  coordinates: {
    lat: 0.0,
    lng: 0.0,
  },
  country: {
    name: '',
    short: '',
  },
  region: {
    name: '',
    short: '',
  },
  localityName: '',
  district: {
    name: '',
    short: '',
  },
  subLocalityName: {
    name: '',
    short: '',
  },
  route: {
    name: '',
    short: '',
  },
  streetNumber: '',
  postalCode: '',
  formattedAddress: '',
  inputAddress,
})

export default getAddressObject
