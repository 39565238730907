import React from 'react'
import './ScrollbarContainer.sass'
import { Scrollbar } from 'react-scrollbars-custom'

function ScrollbarContainer({ children, ...otherProps }) {
  const renderScrollHolder = props => {
    const { elementRef, ...restProps } = props
    return <div ref={elementRef} {...restProps} className="ScrollBar-Holder" />
  }

  return (
    <Scrollbar
      renderer={props => renderScrollHolder(props)}
      trackYProps={{ className: 'ScrollBar-TrackY' }}
      thumbYProps={{ className: 'ScrollBar-ThumbY' }}
      maximalThumbYSize={80}
      translateContentSizesToHolder
      {...otherProps}
    >
      {children}
    </Scrollbar>
  )
}

export default ScrollbarContainer
