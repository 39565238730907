import React from 'react'
import './Footer.sass'
import { Link } from 'react-router-dom'
import { links as productLinks } from '../Nav/ProductMenu'
import { links as solutionsLinks } from '../Nav/SolutionsMenu'
import { links as resourcesLinks } from '../Nav/ResourcesMenu'
import Icon from '../../../components/UI/Icon/Icon'
import TryFreeButton from '../TryFreeButton/TryFreeButton'
import FooterBlock from './FooterBlock'

function Footer() {
  return (
    <footer className="Site-Footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Site-Footer-Container">
              <FooterBlock title="Продукт" links={productLinks} />
              <FooterBlock title="Решения" links={solutionsLinks} />
              <FooterBlock title="Ресурсы" links={resourcesLinks} />
              <div className="Site-Footer-Block Footer-ContactsBlock">
                <h5 className="Site-TitleText">Офис</h5>
                <div className="Footer-Block-Links">
                  <a className="Contact-Data Site-PhoneNumber" href="tel: +7 (921) 605-90-80">
                    +7 (495) 133-16-38
                  </a>
                  <p className="Contact-Data">office@cdbx.io</p>
                  <div className="Contact-Button">
                    <TryFreeButton title="Пробная версия" fill="accent" className="TryFreeButton" />
                  </div>
                </div>
              </div>
            </div>
            <div className="Site-Footer-LegacyBlock">
              <div className="LegacyBlock-Links">
                <Link to="/user-agreement">Пользовательское соглашение</Link>
                <Link to="/privacy-policy">Политика обработки персональных данных</Link>
              </div>
              <div className="LegacyBlock-Social">
                <p>Мы в соц. сетях:</p>
                <a href="">
                  <Icon name="youtube" weight="brand" />
                </a>
                <a href="">
                  <Icon name="facebook-f" weight="brand" />
                </a>
                <a href="">
                  <Icon name="medium" weight="brand" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
