import React, { useState } from 'react'
import { cloneDeep, find, isEmpty } from 'lodash'
import { renderEmail } from 'react-html-email'
import Button from '../../../../ui/Button/Button'
import StatusMessage, { useStatusMessage } from '../../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../../utilities/newforms'
import FieldRender from '../../../../utilities/newforms/render/FieldRender'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import DropTile from './DropTile/DropTile'
import getCollection from '../../../../utilities/db/getCollection'
import genNewCode from '../Confirmation/functions/genNewCode'
import sendEmailRecoveryCode from './functions/sendEmailRecoveryCode'
import PasswordRecoverEmail from '../../../../modules/Emails/templates/PasswordRecoverEmail'
import Backdrop from '../../../../components/UI/Backdrop/Backdrop'
import CodeForm from '../Confirmation/CodeForm'
import smsIcon from '../Confirmation/assets/smsIcon.svg'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import sendPhoneRecoveryCode from './functions/sendPhoneRecoveryCode'

function PhoneRecover({ isActive, onClick, setShowNewPasswordFormFor }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        phone: {
          field: {
            fieldId: 'phone',
            fieldType: 'phone',
            inputType: 'text',
            icon: 'phone',
            placeholder: 'Введите ваш контактный телефон',
            label: 'Контактный телефон',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    }),
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [userConfirmationData, setUserConfirmationData] = useState(null)

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const { phone } = getFormValues({ form })
    getCollection({
      path: 'users',
      docIdName: 'userId',
    }).then((users) => {
      const user = find(users, (u) => u.phone.metadata.number === phone.metadata.number)
      if (!user) {
        setIsLoading(false)
        return setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Пользователь с таким номером телефона не зарегистрирован',
          closeAfter: 5000,
        })
      }

      setUserConfirmationData({ uid: user.userId, phone: user.phone.metadata.number })
      setIsLoading(false)
    })
  }

  const onSuccessFunc = () => {
    updateDoc({ path: 'users', docId: userConfirmationData.uid, data: { confirmationCode: null } }).then((uid) =>
      setShowNewPasswordFormFor(uid),
    )
  }

  const getNewConfirmationCode = () => {
    const code = genNewCode()
    sendPhoneRecoveryCode({ uid: userConfirmationData.uid, phone: userConfirmationData.phone, code }).then(() => {
      const ucDataClone = cloneDeep(userConfirmationData)
      ucDataClone.confirmationCode = code
      setUserConfirmationData(ucDataClone)
    })
  }

  return (
    <>
      <DropTile
        isActive={isActive}
        onClick={() => onClick('phone')}
        icon="phone"
        title="Восстановить по SMS"
        description="Проверочный код для восстановления доступа будет отправлен на номер телефона, указанный при регистрации"
      />
      {userConfirmationData && (
        <Backdrop show close={() => setUserConfirmationData(null)} className="Site-Backdrop">
          <CodeForm
            icon={smsIcon}
            type="phone"
            title="Сброс пароля"
            description="Подтвердите ваши действия. Для этого мы выслали проверочный код на указанный вами номер телефона. Пожалуйста, введите полученый код в форму ниже."
            user={userConfirmationData}
            sendCodeFunc={getNewConfirmationCode}
            onSuccessFunc={onSuccessFunc}
            sendCodeOnInit
          />
        </Backdrop>
      )}
      {isActive && (
        <>
          {statusMessage.show && (
            <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
          )}
          <form onSubmit={onSubmit} className="Site-Fields">
            <FieldRender
              field={{ ...form.phone, fieldId: 'phone' }}
              form={form}
              setForm={setForm}
              showErrors={showErrors}
            />
            <div className="ConfirmationForm-Buttons" style={{ marginTop: '24px' }}>
              <Button
                type="submit"
                className="AuthForm-Button"
                icon="check"
                title="Сбросить пароль"
                fill="black"
                theme="solid"
                isLoading={isLoading}
                onClick={onSubmit}
                size={48}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default PhoneRecover
