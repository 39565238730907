import React from 'react'
import './SalesFunnel.sass'
import NewDeals from './components/NewDeals/NewDeals'
import DealFunnelSteps from './components/DealFunnelSteps/DealFunnelSteps'
import SalesFunnelControlls from './components/SalesFunnelControlls/SalesFunnelControlls'

function SalesFunnel() {
  return (
    <div className="SalesFunnel">
      <SalesFunnelControlls />
      <div className="SalesFunnel-Container">
        <NewDeals />
        <DealFunnelSteps />
      </div>
    </div>
  )
}

export default SalesFunnel
