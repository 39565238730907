import React, { useRef, useEffect } from 'react'
import './UserProfileBar.sass'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import UserPopUp from './UserPopUp/UserPopUp'
import useWindowSize from '../../../hooks/useWindowSize'
import UserAvatar from '../../../components/UserAvatar/UserAvatar'
import Icon from '../../../components/UI/Icon/Icon'
import { usePopUp } from '../../../hooks/usePopUp'

function UserProfileBar({ user, ...router }) {
  const butRef = useRef()
  const ddbRef = useRef()

  const [showPopUp, setShowPopUp] = usePopUp(butRef, ddbRef)
  const { width } = useWindowSize()

  useEffect(() => {
    setShowPopUp(false)
  }, [router.history.location.pathname])

  return (
    <div className="Site-UserProfileBar">
      <div ref={butRef} style={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar size="32" url={user.avatar.publicUrl} />
        <div
          className={[
            'Site-UserProfileBar-UserData',
            ...(showPopUp ? ['Site-UserProfileBar-UserData_opened'] : []),
          ].join(' ')}
        >
          {width >= 576 && <p className="UserProfileBar-UserName">{user.name || 'Профиль'}</p>}
          <Icon name="angle-down" weight="regular" />
        </div>
      </div>
      {showPopUp && <UserPopUp ref={ddbRef} />}
    </div>
  )
}

/* <div ref={butRef}>
          <Avatar
            size="42"
            userName={`${user.name.trim()} ${user.lastName.trim()}`}
            src={user.avatar && user.avatar.url}
          />
        </div> */

const mapStateToProps = (state) => ({ user: state.auth.user })

export default compose(withRouter, connect(mapStateToProps))(UserProfileBar)
