import cloneDeep from 'lodash/cloneDeep'
import {
  getSalesPlanObject,
  getOfficeSalesPlanObject,
  getSquadSalesPlanObject,
  getMembersSalesPlanObject,
} from './getSalesPlanObject'
import { getOfficesOptions } from '../../../../../../utilities/getOptions/getOfficesOptions'

const addNewSalesPlan = ({ accountId, year }) => {
  const planObject = getSalesPlanObject({ year, offices: [] })

  return getOfficesOptions(accountId).then((offices) => {
    offices.forEach((o) => {
      const officePlan = getOfficeSalesPlanObject({ officeId: o.value, squads: [] })

      o.rawData.squads.forEach((s) => {
        const squadPlan = addSquadPlan(s)
        officePlan.squads.push(squadPlan)
      })
      planObject.offices.push(officePlan)
    })
    return planObject
  })
}

export const addSquadPlan = (s) => {
  const squadPlan = getSquadSalesPlanObject({ squadId: s.squadId })

  const monthPattern = cloneDeep(squadPlan.monthPattern)
  delete squadPlan.monthPattern

  for (let index = 1; index <= 12; index++) {
    squadPlan[index] = cloneDeep(monthPattern)
    squadPlan[index].members = {}
    s.members.forEach((m) => {
      squadPlan[index].members[m] = getMembersSalesPlanObject({ totalPlan: 0 })
    })
  }

  return squadPlan
}

export default addNewSalesPlan
