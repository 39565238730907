import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { renderEmail } from 'react-html-email'
import Button from '../../../ui/Button/Button'
import StatusMessage, { useStatusMessage } from '../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../utilities/newforms'
import FormRender from '../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../utilities/newforms/validation/isFormValid'
import { logError } from '../../../utilities/db/logError'
import SignPage from './SignPage/SignPage'
import SocialSignIn from './SocialSignIn/SocialSignIn'
import SignUpEmail from '../../../modules/Emails/templates/SignUpEmail'
import userDbForm from './functions/UserDbForm'
import getFormValues from '../../../utilities/newforms/getFormValues'
import signUpUser from './functions/signUpUser'
import handleSignUpErrors from './functions/handleSignUpErrors'
import sendEmailConfirmationCode from './Confirmation/functions/sendEmailConfirmationCode'
import genNewCode from './Confirmation/functions/genNewCode'
import { getUrlQueryParams } from '../../../utilities/queries/getUrlQueryParams'
import PolicyAgreement from '../PrivacyPolicy/PolicyAgreement'

function SignUp({ ...router }) {
  const { email } = getUrlQueryParams({ history: router.history, params: ['email'] })

  const [form, setForm] = useState(createForm({ formPattern: userDbForm, formData: email ? { email } : {} }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form, checkFields: ['email', 'phone', 'password'] })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    signUpUser({ userObject: values })
      .then((uid) => {
        const code = genNewCode()
        sendEmailConfirmationCode({
          code,
          uid,
          email: values.email,
          htmlEmail: renderEmail(<SignUpEmail code={code} email={values.email} password={values.password} />),
        })
      })
      .catch((e) => {
        const errorMessage = handleSignUpErrors({ code: e.code })
        if (errorMessage) {
          setStatusMessage({
            show: true,
            type: 'fail',
            message: errorMessage,
            closeAfter: 5000,
          })
        } else {
          logError({ error: e })
        }
        setIsLoading(false)
        console.log('🚀 ~ file: SignUp.jsx ~ line 47 ~ onSubmit ~ e', e)
      })
  }

  return (
    <SignPage nav="signin">
      <div className="AuthForm">
        <div className="AuthForm-Header">
          <h5 className="AuthForm-Title">Регистрация</h5>
          <p className="AuthForm-Description">
            Активируйте 7 дневную пробную версию. Платежные данные указывать не потребуется.
          </p>
        </div>
        <form onSubmit={onSubmit} className="AuthForm-Container Site-Fields">
          {statusMessage.show && (
            <StatusMessage className="Site-StatusMessage" type={statusMessage.type} message={statusMessage.message} />
          )}
          <SocialSignIn
            buttonTitle="Регистрация с помощью Google"
            deviderText="или зарегистрироваться с помощью E-mail"
            setStatusMessage={setStatusMessage}
          />
          <FormRender
            sections={[{ fields: ['email', 'phone', 'password'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <PolicyAgreement />
          <Button
            type="submit"
            isLoading={isLoading}
            className="AuthForm-Button"
            icon="long-arrow-right"
            title="Зарегистрироваться"
            fill="black"
            theme="solid"
            size={48}
            onClick={onSubmit}
          />
          <p className="AuthForm-MobileSignStep">
            Есть аккаунт? <Link to="/auth/sign-in">Войти</Link>
          </p>
        </form>
      </div>
    </SignPage>
  )
}

export default withRouter(SignUp)
