import getMonthRange from './getMonthRange'
import getQuarterRange from './getQuarterRange'
import getYearRange from './getYearRange'
import getCustomRange from './getCustomRange'

const getRangePickerObject = ({ type, startingDate, endingDate }) => {
  switch (type) {
    case 'month':
      return getMonthRange()
    case 'quarter':
      return getQuarterRange()
    case 'year':
      return getYearRange()
    case 'custom':
      return getCustomRange({ startingDate, endingDate })
    default:
      return getMonthRange()
  }
}

export default getRangePickerObject
