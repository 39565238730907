import uniqid from 'uniqid'

function funnelStepObject({
  stepId = uniqid(),
  label,
  startDate = null,
  endDate = null,
  stepStatus = null,
  expectation = 100,
  checkActivity = false,
  unactivityDays = 0,
  onPassedCallbackUrl = null,
  onSkippedCallbackUrl = null,
}) {
  return {
    stepId,
    label,
    startDate,
    endDate,
    stepStatus,
    expectation,
    checkActivity,
    unactivityDays,
    onPassedCallbackUrl,
    onSkippedCallbackUrl,
    version: '1.0',
  }
}

export default funnelStepObject
