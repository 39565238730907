import React from 'react'
import './TechPage.sass'

function TechPage({ nav, children }) {
  return (
    <div className="TechPage">
      {nav}
      <div className="TechPage-Body">{children}</div>
    </div>
  )
}

export default TechPage
