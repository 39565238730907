import merge from 'lodash/merge'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import countTaskComments from './countTaskComments'
import fetchTaskAttachments from './fetchTaskAttachments'
import fetchTaskContacts from './fetchTaskContacts'
import fetchTaskResponsible from './fetchTaskResponsible'
import fetchTaskTags from './fetchTaskTags'
import fetchTaskDeals from './fetchTaskDeals'

const fetchTask = ({ accountId, task }) =>
  promiseAllValues({
    responsible: fetchTaskResponsible({ accountId, responsible: task.responsible }),
    tags: fetchTaskTags({ accountId, tags: task.tags }),
    comments: countTaskComments({ accountId, taskId: task.taskId }),
    attachments: fetchTaskAttachments({ accountId, taskId: task.taskId }),
    contacts: fetchTaskContacts({ accountId, contacts: task.contacts }),
    leads: [],
    sales: fetchTaskDeals({ accountId, sales: task.sales }),
  }).then((fetchedTask) => merge(task, fetchedTask))

export default fetchTask
