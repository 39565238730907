import { getCurDateWithUser } from '../../../../../../utilities/date/getCurDateWithUser'
import { updateDoc } from '../../../../../../utilities/db/updateDoc'

const attachOrganization = ({ accountId, organizationId, contactId, uid }) =>
  updateDoc({
    accountId,
    path: 'contacts',
    docId: contactId,
    data: { organization: organizationId, updated: getCurDateWithUser(uid) },
  })

export default attachOrganization
