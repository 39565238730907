import { firebase } from '../../config/firebase'

export const deleteStorageFile = (docPath) => {
  console.log('🚀 ~ file: deleteStorageFile.js ~ line 4 ~ deleteStorageFile ~ docPath', docPath)
  const deleteFn = firebase.functions().httpsCallable('storageOnCall')

  return deleteFn({ func: 'deleteFile', path: docPath })
    .then(() => 'ok')
    .catch((err) => {
      console.log('Delete failed, see console,')
      console.warn(err)
    })
}
