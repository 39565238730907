import React, { useState, useRef } from 'react'
import './SmartInput.sass'
import find from 'lodash/find'
import { connect } from 'react-redux'
import UserAvatar from '../../../components/UserAvatar/UserAvatar'
import Button from '../../../ui/Button/Button'
import DropdownBox from '../../../components/UI/DropdownBox/DropdownBox'
import { usePopUp } from '../../../hooks/usePopUp'
import SetComment from './SetComment/SetComment'
import SetTask from './SetTask/SetTask'
import Icon from '../../../components/UI/Icon/Icon'

function SmartInput({ useLocalState, saveEventLocaly, user, attachableData }) {
  const typeOptions = [
    { label: 'Написать комментарий', value: 'comment' },
    { label: 'Поставить задачу', value: 'task' },
  ]
  const [type, setType] = useState(find(typeOptions, ['value', 'comment']))

  const typeRef = useRef()
  const ddBoxRef = useRef()
  const [showSelectType, setShowSelectType] = usePopUp(typeRef, ddBoxRef, true)

  const changeInputType = (typeValue) => {
    const newType = find(typeOptions, ['value', typeValue])
    setType(newType)
    setShowSelectType(false)
  }

  return (
    <div className="SmartInput">
      <UserAvatar size="24" url={user.avatar.publicUrl} />
      <div className="SmartInput-FieldContainer">
        <div className="TypeSelect">
          <Button theme="transparent-hug" color="accent">
            <span ref={typeRef}>
              {type.label} <Icon name="angle-down" />
            </span>
            {!useLocalState && showSelectType && (
              <DropdownBox ref={ddBoxRef} styles={{ width: '170px', left: '0', textAlign: 'left' }}>
                {typeOptions.map((option) => (
                  <li key={option.value} className="DropdownBox-Element" onClick={() => changeInputType(option.value)}>
                    {option.label}
                  </li>
                ))}
              </DropdownBox>
            )}
          </Button>
        </div>
        {type.value === 'comment' && (
          <SetComment
            useLocalState={useLocalState}
            attachableData={attachableData}
            saveEventLocaly={saveEventLocaly}
            uid={user.uid}
          />
        )}
        {type.value === 'task' && (
          <SetTask onTaskAdded={changeInputType} attachableData={attachableData} uid={user.uid} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(SmartInput)
