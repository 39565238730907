import countEnds from './countEnds'
import countNewStarts from './countNewStarts'

const countDiff = ({ step, deals, dateRange, responsible }) => {
  const starts = countNewStarts({ step, deals, dateRange, responsible })
  const ends = countEnds({ step, deals, dateRange, responsible })
  return {
    moneyCounter: starts.moneyCounter - ends.moneyCounter,
    totalCounter: starts.totalCounter - ends.totalCounter,
  }
}
export default countDiff
