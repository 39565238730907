import React from 'react'
import Button from '../../../../ui/Button/Button'
import Logo from '../../../../ui/Logo/Logo'
import LangPicker from '../../../components/Nav/LangPicker/LangPicker'
import './SignPage.sass'
import toTheSpace from './tothespace.svg'
import UserProfileBar from '../../../components/UserProfileBar/UserProfileBar'

function SignPage({ nav, children }) {
  return (
    <div className="Site-SignPage">
      <SignPageNav nav={nav} theme="sign" />
      <div className="Site-SignPage-Content">{children}</div>
      <div className="Site-SignPage-Illustration" style={{ backgroundImage: `url(${toTheSpace})` }} />
    </div>
  )
}

export function SignPageNav({ user, nav, theme = 'default' }) {
  let navText = 'Уже есть аккаунт?'
  let button = {
    type: 'navlink',
    title: 'Войти',
    path: '/auth/sign-in',
  }

  if (nav === 'signup') {
    navText = 'Еще нет аккаунта?'
    button = {
      type: 'navlink',
      title: 'Зарегистрировать',
      path: '/auth/sign-up',
    }
  }
  return (
    <div className={['Site-SignNav', ...(theme === 'sign' ? ['Site-SignNav_theme_sign'] : [])].join(' ')}>
      <Logo theme="primary" direction="horizontal" navLink path="/" />
      <div className="Site-Nav-Controlls Site-SignPage-Controlls">
        {!user ? (
          <>
            <p>{navText}</p>
            <Button {...button} fill={theme === 'sign' ? 'accentLight' : 'accent'} theme="solid" icon="sign-in" />
          </>
        ) : (
          <UserProfileBar />
        )}
        <LangPicker />
      </div>
    </div>
  )
}

export default SignPage
