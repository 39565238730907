import React from 'react'
import IconicButton from '../../../components/UI/IconicButton/IconicButton'
import './DialerSideEvent.sass'

function DialerSideEvent({ img: { src, alt } = {}, title, closeEvent, children, className }) {
  return (
    <div className={['DialerSideEvent', ...(className ? [className] : [])].join(' ')}>
      <img className="DialerSideEvent-Icon" src={src} alt={alt} />
      <div className="DialerSideEvent-Content">
        <div className="DialerSideEvent-Header">
          <p className="DialerSideEvent-Title">{title}</p>
          <IconicButton
            icon="times"
            iconWeight="solid"
            customClass="DialerSideEvent-CloseButton"
            clicked={closeEvent}
          />
        </div>
        {children}
      </div>
    </div>
  )
}

export default DialerSideEvent
