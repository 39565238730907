import rootPath from '../../../../../../appTree/rootPath'
import subsectionConfig from '../../../../../../appTree/subsectionConfig'

function getContactsManagerSubmenu({ accountId, contactId, modulePath, counters, history }) {
  const submenuLinks = [
    subsectionConfig({
      id: '',
      title: 'Инфо о контакте',
      path: contactId ? `${modulePath}/${contactId}/edit` : `${modulePath}/add`,
      access: true,
    }),
    subsectionConfig({
      title: 'Сделки',
      path: `${modulePath}/${contactId}/deals`,
      icon: {
        name: 'plus',
        onClick: () => history.push(`${rootPath(accountId)}/deals/list/add?contactId=${contactId}`),
      },
      counter: counters.deals,
      // access: contactId,
      access: false,
    }),
    subsectionConfig({
      title: 'Объекты',
      path: `${modulePath}/${contactId}/estate`,
      icon: {
        name: 'plus',
        onClick: () => history.push(`${rootPath(accountId)}/estate/list/add?contactId=${contactId}`),
      },
      counter: counters.estate,
      access: false,
      // access: contactId,
    }),
  ]

  return submenuLinks
}

export default getContactsManagerSubmenu
