import React, { useState, useEffect } from 'react'
import { clickToCall } from '../../Dialer/functions/clickToCall'
import './ActiveContactBlock.sass'
import Icon from '../../../components/UI/Icon/Icon'
import Button from '../../../ui/Button/Button'
import getDoc from '../../../utilities/db/getDoc'
import Skeleton from '../../../components/UI/Skeleton/Skeleton'
import { isEmpty, isArray } from 'lodash'
import { connect } from 'react-redux'

const ActiveContactBlock = ({
  accountId,
  loadContactOnMount = false,
  contactId,
  name,
  phone,
  email,
  buttons,
  uid: responsible,
}) => {
  const [contactData, setContactData] = useState({})

  useEffect(() => {
    if (loadContactOnMount) {
      getDoc({ accountId, path: 'contacts', docId: contactId }).then((data) => {
        setContactData({
          name: `${data.lastName} ${data.name} ${data.middleName}`,
          phone: `${data.phone[0].value}`,
          buttons,
        })
      })
    } else {
      setContactData({
        name,
        phone,
        email,
        buttons,
      })
    }
  }, [])

  return (
    <div className="ActiveContactBlock">
      {renderInfoLine({ icon: 'user', title: contactData.name, skeletonWidth: 140 })}
      {renderInfoLine({
        icon: 'mobile',
        title: contactData.phone,
        useActiveLink: true,
        activeLinkFunc: () => clickToCall({ name, phone, contactId, responsible }),
        skeletonWidth: 100,
      })}
      {contactData.email &&
        renderInfoLine({
          icon: 'envelope',
          title: contactData.email,
        })}
      {!isEmpty(contactData.buttons) ? (
        <div className="Buttons">
          {buttons.map((but) => (
            <Button {...but} />
          ))}
        </div>
      ) : isArray(contactData.buttons) ? (
        <div className="SkeletonButtons">
          <Skeleton width={100} />
          <Skeleton width={60} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

const renderInfoLine = ({ icon, title, skeletonWidth, useActiveLink = false, activeLinkFunc }) => (
  <div className="ActiveContactBlock-DataLine">
    <div className="DataLine-Icon">{title ? <Icon name={icon} /> : <Skeleton circle width={24} height={24} />}</div>
    <span className={useActiveLink ? 'ActiveLink' : ''} onClick={activeLinkFunc}>
      {title || <Skeleton width={skeletonWidth} />}
    </span>
  </div>
)

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(ActiveContactBlock)
