import * as ACTION_TYPES from './actionTypes'

export const tasksInitiateBoardMount = (payload) => ({ type: ACTION_TYPES.TASKS_INITIATE_BOARD_MOUNT, payload })

export const tasksFetchChunk = (payload) => ({ type: ACTION_TYPES.TASKS_FETCH_CHUNK, payload })

export const tasksUpdateColumns = (payload) => ({
  type: ACTION_TYPES.TASKS_UPDATE_COLUMNS,
  payload,
})
export const tasksUpdateColumnInfo = (payload) => ({
  type: ACTION_TYPES.TASKS_UPDATE_COLUMN_INFO,
  payload,
})

export const tasksUpdateTask = (payload) => ({
  type: ACTION_TYPES.TASKS_UPDATE_TASK,
  payload,
})

export const tasksRemoveTask = (payload) => ({
  type: ACTION_TYPES.TASKS_REMOVE_TASK,
  payload,
})

export const tasksSendDataToReducer = (payload) => ({
  type: ACTION_TYPES.TASKS_SEND_DATA_TO_REDUCER,
  payload,
})

export const tasksAttachmentUpload = (payload) => ({ type: ACTION_TYPES.TASKS_ATTACHMENT_UPLOAD, payload })

export const tasksTaskWasAdded = (payload) => ({ type: ACTION_TYPES.TASKS_TASK_WAS_ADDED, payload })

export const tasksTaskWasModified = (payload) => ({ type: ACTION_TYPES.TASKS_TASK_WAS_MODIFIED, payload })

export const tasksTaskWasRemoved = (payload) => ({ type: ACTION_TYPES.TASKS_TASK_WAS_REMOVED, payload })

export const tasksAddTask = (payload) => ({ type: ACTION_TYPES.TASKS_ADD_TASK, payload })

export const tasksShowResultsForm = (payload) => ({ type: ACTION_TYPES.TASKS_SHOW_RESULTS_FORM, payload })
