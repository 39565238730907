import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

const getCalendarYearsWithMonths = (startingDate, endingDate) => {
  const startingYear = getYear(startingDate)

  const calendarYearsDistance = differenceInCalendarYears(endingDate, startingDate)
  const years = []
  for (let i = startingYear; i <= startingYear + calendarYearsDistance; i++) {
    let months

    if (!calendarYearsDistance) {
      months = []
      const month = getMonth(startingDate) + 1
      const calendarMonthsDistance = differenceInCalendarMonths(endingDate, startingDate)

      for (let i = month; i <= month + calendarMonthsDistance; i++) {
        months.push(i)
      }
    }

    years.push({
      year: i,
      months,
    })
  }

  return years
}

export default getCalendarYearsWithMonths
