export const getSalesPlanObject = ({ year, offices = [] }) => ({
  year,
  offices,
})

export const getOfficeSalesPlanObject = ({ officeId, squads = [] }) => {
  const officePlan = {
    officeId,
    squads,
  }

  for (let index = 1; index <= 12; index++) {
    officePlan[index] = 0
  }

  return officePlan
}
export const getSquadSalesPlanObject = ({ squadId, members = [] }) => ({
  squadId,
  monthPattern: {
    totalPlan: 0,
    members,
  },
})
export const getMembersSalesPlanObject = ({ totalPlan = 0 }) => ({ totalPlan })
