import isArray from 'lodash/isArray'
import difference from 'lodash/difference'
import { pushCallEvent } from '../CallEvent/functions/pushCallEvent'
import { toast } from 'react-toastify'

export const handleInboundCallsNotifications = (prevCallNotifications, callNotifications) => {
  const prevNotifications = isArray(prevCallNotifications) ? prevCallNotifications : []

  const prevEvents = prevNotifications.map((event) => event.fbCallId)
  const curEvents = callNotifications.map((event) => event.fbCallId)
  const notificationsToDismiss = difference(prevEvents, curEvents)
  notificationsToDismiss.forEach((fbCallId) => toast.dismiss(fbCallId))

  callNotifications.forEach((event) => {
    if (event.fbCallId) {
      pushCallEvent({
        eventId: event.fbCallId,
        direction: 'inbound',
        responsible: event.responsible,
        phone: event.phone,
        contactId: event.contactId,
      })
    }
  })
}
