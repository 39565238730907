import React, { useEffect } from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import './Tasks.sass'

import isEmpty from 'lodash/isEmpty'
import InfiniteScroll from 'react-infinite-scroll-component'
import merge from 'lodash/merge'
import TaskColumn from './TaskColumn/TaskColumn'
import Task from './Task/Task'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import * as actions from '../../store/actions/tasksActions'
import ModuleWithSubmenu from '../../../Module/ModuleWithSubmenu'
import { db } from '../../../../config/firebase'
import buildTasksDbQuery from './functions/buildTasksDbQuery'
import getCollection from '../../../../utilities/db/getCollection'
import rootDbPath from '../../../../utilities/db/rootDbPath'
import rootPath from '../../../../appTree/rootPath'

function Tasks({
  accountId,
  columns,
  fetchChunk,
  uid,
  subsections,
  loadTasks,
  taskWasAdded,
  taskWasModified,
  taskWasRemoved,
  ...router
}) {
  useEffect(() => {
    loadTasks({ accountId, uid })

    const listeners = {}
    getCollection({ accountId, path: 'config/tasks/columns', docIdName: 'columnId' }).then((cols) => {
      cols
        .filter((c) => c.onMountFetch)
        .forEach((c) => {
          const dbRef = db.collection(`${rootDbPath(accountId)}/tasks`)
          const query = buildTasksDbQuery({ dbRef, rules: c.filterRules, orderBy: c.orderBy, uid })

          let initialCall = true

          const listener = query.onSnapshot((snap) => {
            if (!initialCall) {
              snap.docChanges().forEach((change) => {
                const payload = {
                  accountId,
                  rawTask: merge({ taskId: change.doc.id, columnId: c.columnId }, change.doc.data()),
                }

                if (change.type === 'added') taskWasAdded(payload)
                if (change.type === 'modified') taskWasModified(payload)
                if (change.type === 'removed') taskWasRemoved(payload)
              })
            }
            initialCall = false
          })
          listeners[c.columnId] = listener
        })
    })

    return () => {
      Object.values(listeners).map((unsubscribe) => unsubscribe())
    }
  }, [])

  const closeTask = () => {
    router.history.push(router.match.path)
  }

  const dataLength = columns.map((c) => c.tasks.length).reduce((a, b) => a + b, 0)
  const hasMore = columns.map((c) => c.hasMore).some((e) => e === true)

  return (
    <ModuleWithSubmenu subsections={subsections}>
      {isEmpty(columns) ? (
        <Spinner type="module" styles={{ marginTop: '32px' }} />
      ) : (
        <div className="Tasks-Container" id="TasksContainer">
          <InfiniteScroll
            dataLength={dataLength}
            next={() => fetchChunk({ accountId, uid })}
            hasMore={hasMore}
            scrollableTarget="TasksContainer"
            scrollThreshold="0px"
          >
            {columns.map((c) => (
              <TaskColumn
                key={c.columnId}
                columnId={c.columnId}
                columnStyles={c.styles}
                addTaskButton={c.addTaskButton}
                label={c.label}
                filterRule={c.filterRule}
                tasks={c.tasks}
                totalTasks={c.totalTasks}
                hasMore={c.lastDocumentSnapshot}
                newTaskConfig={c.newTaskConfig}
              />
            ))}
          </InfiniteScroll>
          {/* <div className='Task-Column Task-Column_theme_add'>+ Добавить колонку</div> */}
          <Route path={`${rootPath(accountId)}/tasks/list/:id`} render={() => <Task onClose={closeTask} />} />
        </div>
      )}
    </ModuleWithSubmenu>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
  columns: state.tasks.columns,
  isMounted: state.tasks.isMounted,
  columnsListeners: state.tasks.listeners,
})

const mapDispatchToProps = (dispatch) => ({
  fetchChunk: (payload) => dispatch(actions.tasksFetchChunk(payload)),
  loadTasks: (payload) => dispatch(actions.tasksInitiateBoardMount(payload)),
  taskWasAdded: (payload) => dispatch(actions.tasksTaskWasAdded(payload)),
  taskWasModified: (payload) => dispatch(actions.tasksTaskWasModified(payload)),
  taskWasRemoved: (payload) => dispatch(actions.tasksTaskWasRemoved(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks))
