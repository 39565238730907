import React, { useState } from 'react'
import './ChangeTaskStatus.sass'
import { connect } from 'react-redux'
import TaskNavElement from '../TaskNavElement'
import * as actions from '../../../../../../store/actions/tasksActions'
import updateTask from '../../../../functions/updateTask'

function ChangeTaskStatus({ accountId, taskId, isFinished, showTaskResultsForm, uid }) {
  const [isLoading, setIsLoading] = useState(false)

  const toggleTaskStatus = () => {
    if (isFinished) return returnTaskToIncoming()
    showTaskResultsForm({ status: true, comment: null })
  }

  const returnTaskToIncoming = () => {
    setIsLoading(true)
    updateTask({ accountId, taskId, taskData: { isFinished: false, result: '' }, uid }).then(() => setIsLoading(false))
  }

  return (
    <TaskNavElement
      icon={isFinished ? 'times' : 'check'}
      label={isFinished ? 'Отменить завершение' : 'Завершить задачу'}
      extraClass={isFinished && 'ChangeTaskStatus_theme_cancel'}
      cbOnClick={toggleTaskStatus}
      isLoading={isLoading}
      canBeEdit
    />
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

const mapDispatchToProps = (dispatch) => ({
  showTaskResultsForm: (payload) => dispatch(actions.tasksShowResultsForm(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTaskStatus)
