import uniqid from 'uniqid'

const getOfficeSquadObject = ({ squadId = uniqid(), title, markerColor, squadHead, members = [] }) => ({
  squadId,
  title,
  markerColor,
  squadHead,
  members,
})

export default getOfficeSquadObject
