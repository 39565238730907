import getDoc from '../../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../../utilities/promiseAllValues'
import fetchSolutions from './fetchSolutions'

const fetchAccountObject = (accountData) =>
  promiseAllValues({
    owner: getDoc({ path: 'users', docId: accountData.owner, docIdName: 'userId' }),
    solution: fetchSolutions(accountData.solution),
  }).then((fetchedAccount) => ({ ...accountData, ...fetchedAccount }))

export default fetchAccountObject
