import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'

import NavPanel from '../../../../../ui/NavPanel/NavPanel'
import ApiKeyForm from './ApiKeyForm'
import SubscriptionsSettings from './SubscriptionsSettings'
import { getOfficesOptions } from '../../../../../utilities/getOptions'
import CategoryPrice from './CategoryPrice/CategoryPrice'

function ExtensionUi({ accountId, extensionId }) {
  const [nav, setNav] = useState([])
  const [activeTab, setActiveTab] = useState('root')

  useEffect(() => {
    getOfficesOptions(accountId).then((data) =>
      setNav(
        data.map((d) => ({
          id: d.value,
          title: d.label,
          onClick: () => setActiveTab(d.value),
          // component: <ApiKeyForm accountId={accountId} extensionId={extensionId} />,
        })),
      ),
    )
  }, [])

  // const nav = [
  //   {
  //     id: 'apiKey',
  //     title: 'API Ключ',
  //     component: <ApiKeyForm accountId={accountId} extensionId={extensionId} />,
  //     onClick: () => setActiveTab('apiKey'),
  //   },
  //   {
  //     id: 'settings',
  //     title: 'Подключение номеров',
  //     component: <SubscriptionsSettings accountId={accountId} extensionId={extensionId} />,
  //     onClick: () => setActiveTab('settings'),
  //   },
  // ]

  return (
    <div className="IL2hGczCtAL9B1Tua2Th">
      <NavPanel
        blocks={[
          {
            items: nav.map((i) => {
              i.isActive = i.id === activeTab
              return i
            }),
          },
        ]}
      />

      <CategoryPrice officeId={activeTab} accountId={accountId} extensionId={extensionId} />
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(ExtensionUi)
