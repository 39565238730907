import React from 'react'
import './SceneContentPopUp.sass'

function SceneContentPopUp(props) {
  return (
    <div className='SceneContentPopUp' style={props.transparent && { background: 'none' }}>
      {props.children}
    </div>
  )
}

export default SceneContentPopUp
