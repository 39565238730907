import { find } from 'lodash'

const definePriceForSpaceQuantity = (estate) => {
  const spaceParam = find(estate.params, ['paramId', 'DvN83asYpGTY06ZMfIHN'])
  let priceForSpace = 0
  if (spaceParam) {
    priceForSpace = Math.round(estate.price / spaceParam.value, 2)
  }

  return priceForSpace
}

export default definePriceForSpaceQuantity
