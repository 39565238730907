import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import DataCounter from '../../widgets/DataCounter/DataCounter'
import getDoneDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import countEarnings from '../../functions/countEarnings'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import getFailDeals from '../../../Deals/scenes/Dealslist/functions/analytics/getFailDeals'

function AverageCheck({ accountId, uid, ...router }) {
  const [averageCheck, setAverageCheck] = useState(null)
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads, responsible } = resp

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      })
        .then((data) =>
          promiseAllValues({
            doneDeals: getDoneDeals({ accountId, dateRange, responsible: data.responsible }),
            failDeals: getFailDeals({ accountId, dateRange, responsible: data.responsible }),
          }),
        )
        .then(({ doneDeals, failDeals }) => {
          const earnings = countEarnings({ doneDeals, failDeals })
          setAverageCheck([
            {
              value: earnings.averageCheck,
              style: { color: '#39393D' },
            },
          ])
        })
    }
  }, [dateRange, resp])

  return <DataCounter title="Средний чек" counters={averageCheck} isLoading={!averageCheck} counterPeriod={dateRange} />
}
const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(AverageCheck)
