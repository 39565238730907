export const parseCallDirection = direction => {
  switch (direction) {
    case 'outbound':
      return 'Исходящий вызов'
    case 'inbound':
      return 'Входящий вызов'
    default:
      return 'Неизвестно'
  }
}
