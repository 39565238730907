import { useState, useEffect } from 'react'
import { getUrlQueryParams } from '../utilities/queries/'

const useResponsibleFromUrl = ({ listenUrl, history }) => {
  const [responsiblePicker, setResponsiblePicker] = useState(null)

  useEffect(() => {
    const { responsibleType, responsible } = getUrlQueryParams({ history, params: ['responsibleType', 'responsible'] })

    const rPicker = {
      offices: [],
      squads: [],
      responsible: [],
    }

    if (responsibleType === 'all') {
      rPicker.offices = ['all']
    }

    if (responsibleType === 'office') {
      rPicker.offices = [responsible]
    }

    if (responsibleType === 'squad') {
      rPicker.squads = [responsible]
    }

    if (responsibleType === 'employee') {
      rPicker.responsible = [responsible]
    }

    setResponsiblePicker(rPicker)
  }, [history.location.search])

  return [responsiblePicker, setResponsiblePicker]
}

export default useResponsibleFromUrl
