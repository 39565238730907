import React, { useState, useEffect } from 'react'
import './SearchUsersResults.sass'
import { isEmpty, isArray, pick } from 'lodash'
import searchDoc from '../../../../../../../../utilities/db/searchDoc'
import Skeleton from '../../../../../../../../components/UI/Skeleton/Skeleton'
import getDoc from '../../../../../../../../utilities/db/getDoc'
import UserAvatar from '../../../../../../../../components/UserAvatar/UserAvatar'
import Button from '../../../../../../../../ui/Button/Button'

function SearchUsersResults({ email, onUserClicked }) {
  const [results, setResults] = useState(null)

  useEffect(() => {
    if (email.length > 3) {
      searchDoc({ path: 'users', field: 'email', op: '==', value: email, docIdName: 'userId' }).then((result) =>
        setResults(result),
      )
    }
  }, [email])

  return isArray(results) ? (
    <div className="SearchUsersResults">
      {!isEmpty(results) ? (
        results.map((r) => <UserResultRow userId={r.userId} onClick={() => onUserClicked(r.userId)} />)
      ) : (
        <p className="EmptyMessage">Пользователь с указанным вами E-mail не найден</p>
      )}
    </div>
  ) : (
    ''
  )
}

function UserResultRow({ userId, onClick }) {
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (userId)
      getDoc({ path: 'users', docId: userId, docIdName: 'userId' }).then((user) =>
        setUserData(pick(user, ['avatar', 'lastName', 'name', 'middleName', 'email', 'bdate'])),
      )
  }, [userId])

  return (
    <div className="UserResultRow">
      {!isEmpty(userData) ? (
        <>
          <UserAvatar url={userData.avatar.publicUrl} size="32" />
          <div className="UserData">
            <p className="UserData-Name">{`${userData.lastName} ${userData.name} ${userData.middleName}`}</p>
            <p className="UserData-Email">{`${userData.email}`}</p>
          </div>
          <Button title="Прикрепить" theme="bounded" color="accent" border="accent" size={32} onClick={onClick} />
        </>
      ) : (
        <>
          <Skeleton className="Avatar" circle width={32} height={32} />
          <div className="UserSkeletonData">
            <Skeleton width={250} height={8} />
            <Skeleton width={90} height={8} />
          </div>
          <Skeleton className="UserInviteButton" />
        </>
      )}
    </div>
  )
}

export default SearchUsersResults
