export const TASKS_INITIATE_BOARD_MOUNT = 'TASKS_INITIATE_BOARD_MOUNT'
export const TASKS_SEND_DATA_TO_REDUCER = 'TASKS_SEND_DATA_TO_REDUCER'
export const TASKS_SET_LISTENER = 'TASKS_SET_LISTENER'
export const TASKS_TASK_WAS_ADDED = 'TASKS_TASK_WAS_ADDED'
export const TASKS_TASK_WAS_MODIFIED = 'TASKS_TASK_WAS_MODIFIED'
export const TASKS_TASK_WAS_REMOVED = 'TASKS_TASK_WAS_REMOVED'
export const TASKS_ADD_TASK = 'TASKS_ADD_TASK'
export const TASKS_UPDATE_TASK = 'TASKS_UPDATE_TASK'
export const TASKS_REMOVE_TASK = 'TASKS_REMOVE_TASK'
export const TASKS_ATTACHMENT_UPLOAD = 'TASKS_ATTACHMENT_UPLOAD'
export const TASKS_SHOW_RESULTS_FORM = 'TASKS_SHOW_RESULTS_FORM'
export const TASKS_FETCH_CHUNK = 'TASKS_FETCH_CHUNK'
export const TASKS_UPDATE_COLUMNS = 'TASKS_UPDATE_COLUMNS'
export const TASKS_UPDATE_COLUMN_INFO = 'TASKS_UPDATE_COLUMN_INFO'
export const TASKS_SAVE_LISTENERS = 'TASKS_SAVE_LISTENERS'
