export const createObjectFromForm = ({ formPattern, form }) => {
  const arr = Object.entries(formPattern).map(([k, v]) => {
    const fieldInForm = form[v.field.fieldId]

    if (v.field.fieldType !== 'fieldset') {
      v.field.value = fieldInForm.value
      return [k, v]
    } else {
      // v.field.fields = fieldInForm.values.map(fval => )
    }
  })

  return Object.fromEntries(arr)
}
