import React from 'react'
import { fieldChangedHandler } from './fieldChangedHandler'
import Field from '../../components/UI/Forms/Field/Field'
import find from 'lodash/find'

export const renderFields = (form, setForm, showErrors = false, cb = null) => {
  console.log('renderFields -> form', form)
  return form
    .filter((f) => {
      if (f.shouldRenderIf) {
        f.shouldRender = handleRenderBehavior(form, f)
      }
      const field = cb ? cb(f) : f
      return field.hasOwnProperty('shouldRender') ? field.shouldRender : true
    })
    .map((f) => {
      const field = cb ? cb(f) : f
      if (f.fieldType === 'html') {
        return <div className="Field-HtmlContent">{f.content}</div>
      } else {
        return (
          <Field
            key={field.fieldId}
            {...field}
            changed={fieldChangedHandler(field.fieldId, form, setForm)}
            showErrors={showErrors}
          />
        )
      }
    })
}

const handleRenderBehavior = (form, field) => {
  const dependencyField = find(form, ['fieldId', field.shouldRenderIf.fieldId])
  if (field.shouldRenderIf.value.includes(dependencyField.value)) return true
  return false
}
