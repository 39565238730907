import React, { useState, useEffect } from 'react'
import './TasksCounter.sass'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getTasks from '../../../Tasks/scenes/Tasks/functions/getTasks'
import WidgetTile from '../../widgets/WidgetTile/WidgetTile'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import isResponsibleFilterAvailable from '../../functions/isResponsibleFilterAvailable'
import getCollection from '../../../../utilities/db/getCollection'

function TasksCounter({ accountId, uid, ...router }) {
  const [counters, setCounters] = useState(null)
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(resp)) {
      const { offices, squads } = resp
      let { responsible } = resp

      promiseAllValues({
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
        tasksColumns: getCollection({ accountId, path: 'config/tasks/columns', docIdName: 'columnId' }),
      })
        .then((data) => {
          responsible = isResponsibleFilterAvailable(uid) ? data.responsible : [uid]
          const ctrs = {
            expiredTasks: getTasks({
              accountId,
              responsible,
              columns: data.tasksColumns.filter((c) => c.columnId === 'expired'),
            }),
            inProgressTasks: getTasks({
              accountId,
              responsible,
              columns: data.tasksColumns.filter((c) => !['expired', 'archive'].includes(c.columnId)),
            }),
            doneTasks: getTasks({
              accountId,
              responsible,
              columns: data.tasksColumns.filter((c) => c.columnId === 'archive'),
            }),
          }
          return promiseAllValues(ctrs)
        })
        .then((tasks) => {
          setCounters(
            mapValues(tasks, (oProps) =>
              Object.values(oProps)
                .map((v) => v.length)
                .reduce((a, b) => a + b, 0),
            ),
          )
        })
    }
  }, [resp])

  return (
    <WidgetTile title="Задачи" isLoading={!counters}>
      <div className="TasksCounter">
        <TaskCounterColumn label="Просроченные" theme="negative" counter={counters && counters.expiredTasks} />
        <TaskCounterColumn label="Активные" theme="positive" counter={counters && counters.inProgressTasks} />
        <TaskCounterColumn label="Выполненные" theme="default" counter={counters && counters.doneTasks} />
      </div>
    </WidgetTile>
  )
}

function TaskCounterColumn({ label, counter, theme }) {
  return (
    <div className={`TaskCounterColumn TaskCounterColumn_theme_${theme}`}>
      <div className="TaskCounterColumn-Label">{label}</div>
      <div className="TaskCounterColumn-Counter">{counter}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId, uid: state.auth.user.uid })

export default compose(withRouter, connect(mapStateToProps))(TasksCounter)
