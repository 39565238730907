import { all, put } from 'redux-saga/effects'
import { db } from '../../../../config/firebase'
import { store } from '../../../../index'
import * as actions from '../actions/dialerActions'

export function* handleActiveCallSaga({ payload: activeCall }) {
  const state = store.getState()
  // const responsible = state.firebase.auth.uid

  // const [[activeCall], callNotifications] = yield all([
  //   db
  //     .collection('api/calls/events')
  //     .where('responsible', '==', responsible)
  //     .where('isServing', '==', true)
  //     .get()
  //     .then((docRef) => {
  //       const call = []
  //       docRef.forEach((doc) => call.push({ fbCallId: doc.id, ...doc.data() }))
  //       return call
  //     }),
  //   db
  //     .collection('api/calls/events')
  //     .where('responsible', '==', responsible)
  //     .where('direction', '==', 'inbound')
  //     .where('currentState', '==', 'alerting')
  //     .get()
  //     .then((docRef) => {
  //       const call = []
  //       docRef.forEach((doc) => call.push({ fbCallId: doc.id, ...doc.data() }))
  //       return call
  //     }),
  // ])

  if (activeCall) {
    yield mergeCallDataWithClickToDealRegistry(activeCall)
  }

  if (activeCall.currentState === 'released') {
    activeCall.isServing = false
  }

  yield put(actions.dialerSendActiveCallToReducer(activeCall))
}

function* mergeCallDataWithClickToDealRegistry(activeCall) {
  if (activeCall.personality === 'click-to-dial' && !activeCall.phone) {
    const dataFromRegistry = yield db
      .collection('api/calls/clickToDialRegistry')
      .where('extTrackingId', '==', activeCall.extTrackingId)
      .get()
      .then((docRef) => {
        const event = []
        docRef.forEach((doc) => {
          event.push({ ...doc.data() })
        })
        return event[0]
      })

    if (dataFromRegistry.contactId) {
      activeCall.contactId = dataFromRegistry.contactId
    }

    activeCall.phone = dataFromRegistry.phone
  }

  yield put(actions.dialerShowCallBar())
}

// function* fetchNestedData(lead) {
//   const { need, contact, responsible } = yield all({
//     need: getNeed(lead),
//     contact: getContact(lead),
//     responsible: getResponsible(lead),
//   })

//   if (need) lead['need'] = need
//   if (contact) lead['contact'] = contact

//   if (responsible) lead['responsible'] = responsible

//   return lead
// }

// function* getNeed(lead) {
//   if (lead.need) {
//     let need = yield db
//       .collection('config/global/services')
//       .doc(lead.need)
//       .get()
//     const needData = need.data()

//     let needLabel = needData.label
//     if (lead.need === 'rent') {
//       if (lead.rentType === 'search') needLabel += ' / Поиск объекта'
//       if (lead.rentType === 'rental') needLabel += ' / Сдача объекта'
//     }

//     return needLabel
//   } else return null
// }

// function* getContact(lead) {
//   if (lead.contact) {
//     let contact = yield db
//       .collection('contacts')
//       .doc(lead.contact.id)
//       .get()

//     const contactData = contact.data()
//     return {
//       contactId: lead.contact.id,
//       contactType: 'contact',
//       lastName: contactData.lastName,
//       name: contactData.name,
//       middleName: contactData.middleName,
//       phone: contactData.phone[0].value,
//     }
//   } else return null
// }

// function* getResponsible(lead) {
//   let responsible = yield db
//     .collection('users')
//     .doc(lead.responsible)
//     .get()

//   const responsibleData = responsible.data()
//   return {
//     responsibleId: lead.responsible,
//     lastName: responsibleData.lastName,
//     name: responsibleData.name,
//     middleName: responsibleData.middleName,
//     avatar: responsibleData.avatar.url,
//   }
// }
