import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Button from '../../../ui/Button/Button'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'
import './CookieNotification.sass'
import CookiesImg from './cookies.svg'

function CookieNotification() {
  const [acceptedCookie, setAcceptedCookie] = useState(localStorage.getItem('acceptedCookies'))

  const onCookiesAccept = () => {
    localStorage.setItem('acceptedCookies', true)
    setAcceptedCookie(true)
  }

  return !acceptedCookie ? (
    <div className="Site-CookieNotification">
      <div className="CookieNotification-Container">
        <img src={CookiesImg} className="CookieNotification-Icon" alt="" />
        <div className="CookieNotification-Text">
          <p className="CookieNotification-Title">Как и все, мы используем Cookie!</p>
          Мы храним cookie-файлы на вашем устройстве для корректной работы сайта. Подробную информацию о том, как мы их
          используем вы найдете в разделе{' '}
          <HashLink to="/privacy-policy#cookies" smooth scroll={(el) => scrollWithOffset(el, -128)}>
            "Политика обработки персональных данных"
          </HashLink>{' '}
          в отношении cookie-файлов. Продолжая просматривать этот сайт, вы соглашаетесь с условиями использования
          cookie–файлов.
        </div>
        <div className="CookieNotification-Button">
          <Button title="Спасибо, понятно!" theme="solig" fill="accent" onClick={onCookiesAccept} />
        </div>
      </div>
    </div>
  ) : null
}

export default CookieNotification
