import { concat, pick } from 'lodash'
import getCollection from '../db/getCollection'

export const getDealFailReasons = (accountId) =>
  getCollection({
    accountId,
    path: 'config/deals/failReasons',
    docIdName: 'value',
    orderBy: { fieldPath: 'label', orderDir: 'desc' },
  }).then((reasons) =>
    concat(
      reasons.map((r) => pick(r, ['label', 'value'])),
      [{ label: 'Другая', value: 'other' }],
    ),
  )
