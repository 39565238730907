import getCollection from '../db/getCollection'

export const getContactsOptions = ({ accountId }) =>
  getCollection({
    accountId,
    path: 'contacts',
    whereQueries: { fieldPath: 'isDeleted', op: '==', value: false },
    orderBy: { fieldPath: 'updated.at', orderDir: 'desc' },
    docIdName: 'value',
  }).then((contacts) =>
    contacts.map((c) => ({
      contactId: c.value,
      label: `${c.lastName} ${c.name} ${c.middleName}`,
      value: c.value,
      phone: c.phone[0].value,
    })),
  )
