import { find } from 'lodash'
import getCollection from '../../../functions/utilities/db/getCollection'
import { db } from '../../config/firebase'

const fetchTags = ({ accountId, tagsDataPath, tags = [] }) =>
  getCollection({ db, accountId, path: tagsDataPath, docIdName: 'tagId' }).then((data) =>
    tags.filter((t) => find(data, ['tagId', t]) && true).map((t) => find(data, ['tagId', t])),
  )

export default fetchTags
