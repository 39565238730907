import React, { useEffect, useState } from 'react'
import Button from '../../../../../ui/Button/Button'
import FormRender from '../../../../../utilities/newforms/render/FormRender'
import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'
import { createForm } from '../../../../../utilities/newforms/createForm'
import './ExtensionUi.sass'
import isFormValid from '../../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../../utilities/newforms/getFormValues'
import updateDoc from '../../../../../../functions/utilities/db/updateDoc'
import { db } from '../../../../../config/firebase'
import getDoc from '../../../../../../functions/utilities/db/getDoc'

function ApiKeyForm({ accountId, extensionId }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ db, accountId, path: 'extensions', docId: extensionId }).then((data) => {
      setForm(
        createForm({
          formPattern: {
            apiKey: {
              field: {
                fieldId: 'apiKey',
                fieldType: 'input',
                inputType: 'text',
                required: true,
                label: 'API Ключ',
              },
              render: getFieldRenderObject(),
            },
          },
          formData: data,
        }),
      )
    })
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }

    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })

    updateDoc({ db, accountId, path: 'extensions', docId: extensionId, data: values }).then(() => setIsLoading(false))
  }

  return (
    <>
      <h4 className="Title">
        Сгенерируйте и вставьте ниже ключ API с личного профиля <span>Beeline Бизнес</span> на сайте{' '}
        <span>cloudpbx.beeline.ru</span>
      </h4>
      <form onSubmit={onSubmit}>
        <FormRender
          sections={[{ fields: ['apiKey'] }]}
          sectionsSkeleton={[1]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            type="button"
            theme="solid"
            fill="accent"
            title="Сохранить"
          />
        </div>
      </form>
    </>
  )
}

export default ApiKeyForm
