import { db } from '../../config/firebase'
import rootDbPath from '../db/rootDbPath'

export const getPhoneTypesOptions = async (accountId, orderBy) => {
  if (!orderBy) orderBy = 'label'
  const options = []

  await db
    .collection(`${rootDbPath(accountId)}/config/contacts/phoneTypes`)
    .orderBy(orderBy)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        options.push({
          value: doc.id,
          label: doc.data().label,
        })
      })
    })
  return options
}
