import React, { useEffect, useState } from 'react'
import './AttachedContactsAndOrganizations.sass'
import { cloneDeep, find, isEmpty, omit } from 'lodash'
import ContactPopUpManager from '../AttachedContacts/components/ContactPopUpManager/ContactPopUpManager'
import OrganizationPopUpManager from '../AttachedOrganizations/components/OrganizationPopUpManager/OrganizationPopUpManager'
import ContactCard from '../ContactCard/ContactCard'
import OrganizationCard from '../OrganizationCard/OrganizationCard'
import useModuleInit from '../../../utilities/newforms/changed/useModuleInit'
import SceneContentHeader from '../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import AddContactButton from './AddContactButton'

function AttachedContactsAndOrganizations({ syncState, contacts, superstructure, onDetach, disableEdit }) {
  const [showPopUpForContact, setShowPopUpForContact] = useState(null)
  const [contactsArray, setContactsArray] = useModuleInit({ formData: contacts, initialState: [] })

  const syncContacts = (data) => {
    const contactsClone = cloneDeep(contactsArray)
    contactsClone.push(data)
    setContactsArray(contactsClone)
  }

  useEffect(() => {
    syncState(contactsArray)
  }, [contactsArray])

  const getSuperstructureData = () => {
    if (!isEmpty(showPopUpForContact)) {
      const data = find(contactsArray, ['id', showPopUpForContact.id])
      if (data) return data
      return {}
    }
    return {}
  }

  let popUp = null
  if (showPopUpForContact) {
    switch (showPopUpForContact.type) {
      case 'contact':
        popUp = (
          <ContactPopUpManager
            superstructure={superstructure}
            superstructureData={getSuperstructureData()}
            contactId={showPopUpForContact.id}
            pushContact={(data) => syncContacts({ type: 'contact', id: data.contactId, ...omit(data, ['contactId']) })}
            close={() => setShowPopUpForContact(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contactsArray, ['id', c.contactId]))}
          />
        )
        break

      default:
        popUp = (
          <OrganizationPopUpManager
            superstructure={superstructure}
            superstructureData={getSuperstructureData()}
            organizationId={showPopUpForContact.id}
            pushOrganization={(data) =>
              syncContacts({ type: 'organization', id: data.organizationId, ...omit(data, ['organizationId']) })
            }
            close={() => setShowPopUpForContact(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contactsArray, ['id', c.organizationId]))}
          />
        )
    }
  }

  return (
    <div className="SceneContent">
      {!disableEdit ? popUp : null}
      <SceneContentHeader title="Контакты" />
      <div className="AttachedContactsAndOrganizations-Container">
        {!isEmpty(contactsArray) &&
          contactsArray.map((c, i) =>
            c.type === 'contact' ? (
              <ContactCard
                superstructure={superstructure}
                superstructureData={c}
                contactId={c.id}
                buttons={[
                  { type: 'openCard' },
                  { type: 'edit', onEdit: () => setShowPopUpForContact({ type: 'contact', id: c.id }) },
                  {
                    type: 'detach',
                    onDetach: () => onDetach(c, contactsArray, setContactsArray),
                  },
                ]}
                disableEdit={disableEdit}
              />
            ) : (
              <OrganizationCard
                expanded
                organizationId={c.id}
                superstructure={superstructure}
                superstructureData={c}
                buttons={[
                  { type: 'openCard' },
                  { type: 'edit', onEdit: () => setShowPopUpForContact({ type: 'organization', id: c.id }) },
                  {
                    type: 'detach',
                    onDetach: () => onDetach(c, contactsArray, setContactsArray),
                  },
                ]}
                disableEdit={disableEdit}
              />
            ),
          )}
        {!disableEdit && (
          <div
            className="DealContact-AddBlock"
            style={{ gridTemplateColumns: isEmpty(contactsArray) ? 'repeat(2, 1fr)' : '1fr' }}
          >
            {!find(contactsArray, ['type', 'organization']) && (
              <AddContactButton
                title="Добавить контакт"
                icon="user"
                onClick={() => setShowPopUpForContact({ type: 'contact' })}
              />
            )}
            {isEmpty(contactsArray) && (
              <AddContactButton
                title="Добавить организацию"
                icon="building"
                onClick={() => setShowPopUpForContact({ type: 'organization' })}
              />
            )}
          </div>
        )}
        {isEmpty(contactsArray) && disableEdit && <div className="NoInfoRow">Нет контактов</div>}
      </div>
    </div>
  )
}

export default AttachedContactsAndOrganizations
