import React, { useState, useEffect } from 'react'
import './Roles.sass'
import { connect } from 'react-redux'
import { cloneDeep, find, isEmpty } from 'lodash'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { db } from '../../../../../config/firebase'
import SceneContent from '../../../../../components/UI/SceneContent/SceneContent'
import InfoCard, { InfoCardSkeleton } from './components/InfoCard/InfoCard'
import ManageRolePopUp, { parseModulesValues } from './components/ManageRolePopUp/ManageRolePopUp'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import Skeleton from '../../../../../components/UI/Skeleton/Skeleton'
import fillRoleObjectWithData from './functions/fillRoleObjectWithData'
import RadioGroup, { RadioGroupSkeleton } from '../../../../../ui/RadioGroup/RadioGroup'
import { updateDoc } from '../../../../../utilities/db/updateDoc'
import DeleteRolePopUp from './components/DeleteRolePopUp'
import getDoc from '../../../../../utilities/db/getDoc'
import { addDoc } from '../../../../../utilities/db/addDoc'
import { getCurDateWithUser } from '../../../../../utilities/date/getCurDateWithUser'
import NavPanel from '../../../../../ui/NavPanel/NavPanel'
import createNavBlock from '../../../../../ui/NavPanel/functions/createNavBlock'
import createNavBlockItem from '../../../../../ui/NavPanel/functions/createNavBlockItem'
import SceneContentHeader from '../../../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import rootPath from '../../../../../appTree/rootPath'
import roleObject from './components/ManageRolePopUp/roleObject'

function Roles({ accountId, solution, uid, ...router }) {
  const roleId = router.match.params.id
  const blockId = router.match.params.block
  const subsectionId = router.match.params.subsection

  const [roles, setRoles] = useState(null)
  const [popUp, setPopUp] = useState({ action: null, show: false, instanceId: null })
  const [activeRole, setActiveRole] = useState(null)
  console.log('🚀 ~ file: Roles.jsx ~ line 35 ~ Roles ~ activeRole', activeRole)

  useEffect(() => {
    const listener = db
      .collection(`${rootDbPath(accountId)}/roles`)
      .orderBy('created.at', 'asc')
      .onSnapshot((snap) => {
        const docs = []
        snap.forEach((s) => docs.push({ roleId: s.id, ...fillRoleObjectWithData({ formData: s.data(), solution }) }))
        setRoles(docs)
      })
    return () => listener()
  }, [])

  const onRolePropStatusChanged = ({ roleId, module, subsection, settingsType, prop, status }) => {
    const role = cloneDeep(find(roles, ['roleId', roleId]))
    const prevRoleData = find(role.modules.field.value, ['moduleId', module])
    const subsectionData = find(prevRoleData.subsections, ['subsectionId', subsection])
    subsectionData[settingsType][prop].value = status
    const newModulesData = parseModulesValues(role.modules.field.value)
    updateDoc({ accountId, path: 'roles', docId: roleId, data: { modules: newModulesData }, uid })
  }

  useEffect(() => {
    if (!isEmpty(roles)) {
      if (!roleId) {
        return router.history.push(`${rootPath(accountId)}/settings/roles/${roles[0].roleId}`)
      }
      const role = find(roles, ['roleId', roleId])
      if (role) {
        return setActiveRole(role)
      }
      return router.history.push(`${rootPath(accountId)}/settings/roles/${roles[0].roleId}`)
    }

    setActiveRole(null)
  }, [roles, roleId])

  useEffect(() => {
    if ((!blockId || !subsectionId) && activeRole) {
      return router.history.push(
        `${rootPath(accountId)}/settings/roles/${roleId}/${roleObject(solution).modules.field.value[0].moduleId}/${
          roleObject(solution).modules.field.value[0].subsections[0].subsectionId
        }`,
      )
    }
  }, [blockId, subsectionId, activeRole])

  const duplicateRole = (roleId) => {
    getDoc({ accountId, path: 'roles', docId: roleId }).then((role) => {
      role.title = `${role.title} (Дубликат)`
      role.members = []
      role.created = getCurDateWithUser(uid)
      role.updated = getCurDateWithUser(uid)
      return addDoc({ accountId, path: 'roles', data: role })
    })
  }

  const createRoleNavBlock = () =>
    roleObject(solution).modules.field.value.map((m) =>
      createNavBlock({
        title: m.label,
        items: m.subsections.map((subsection) =>
          createNavBlockItem({
            title: subsection.label,
            type: 'button',
            onClick: () =>
              router.history.push(
                `${rootPath(accountId)}/settings/roles/${roleId}/${m.moduleId}/${subsection.subsectionId}`,
              ),
            isActive: blockId === m.moduleId && subsectionId === subsection.subsectionId,
          }),
        ),
      }),
    )

  const renderBlockSection = ({ title, settingsType }) => {
    const module = find(roleObject(solution).modules.field.value, ['moduleId', blockId])
    if (module) {
      const section = find(module.subsections, ['subsectionId', subsectionId])
      if (section && section.hasOwnProperty(settingsType)) {
        return (
          <div className="Content-Settings">
            <SceneContentHeader title={title} />
            {Object.entries(section[settingsType]).map(([pKey, pVal]) => {
              const moduleWithValue = find(activeRole.modules.field.value, ['moduleId', blockId])
              const sectionWithValue = find(moduleWithValue.subsections, ['subsectionId', subsectionId])

              return (
                <div className="Roles-SettingsRow">
                  <div className="Title">{pVal.label}</div>
                  <div className="Content">
                    <RadioGroup
                      options={pVal.options}
                      onChange={(newStatus) =>
                        onRolePropStatusChanged({
                          roleId: activeRole.roleId,
                          module: blockId,
                          subsection: subsectionId,
                          settingsType,
                          prop: pKey,
                          status: newStatus,
                        })
                      }
                      value={sectionWithValue[settingsType][pKey].value || section[settingsType][pKey].options[0].value}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )
      }
    }
  }

  return (
    <SceneContent
      title="Системные роли"
      buttons={[
        {
          type: 'add',
          icon: 'plus',
          clicked: () => setPopUp({ action: 'add', show: true, instanceId: null }),
        },
      ]}
      class="Roles-Module"
    >
      {(popUp.action === 'add' || popUp.action === 'edit') && popUp.show && (
        <ManageRolePopUp
          roleId={popUp.instanceId}
          close={() => setPopUp({ action: null, show: false, instanceId: null })}
        />
      )}
      {popUp.action === 'delete' && popUp.show && (
        <DeleteRolePopUp
          roleId={popUp.instanceId}
          close={() => setPopUp({ action: null, show: false, instanceId: null })}
        />
      )}
      <div className="Roles-ProfilesGrid">
        {roles
          ? roles.map((r) => (
              <InfoCard
                onClick={() => router.history.push(`${rootPath(accountId)}/settings/roles/${r.roleId}`)}
                isActive={activeRole && r.roleId === activeRole.roleId}
                title={r.title.field.value}
                description={r.occupation.field.value}
                mCounter={r.members.field.value.length}
                options={[
                  {
                    icon: 'pencil-alt',
                    label: 'Редакт.',
                    clicked: () => setPopUp({ action: 'edit', show: true, instanceId: r.roleId }),
                  },
                  {
                    return: 'docId',
                    type: 'edit',
                    icon: 'clone',
                    label: 'Дублировать',
                    clicked: () => duplicateRole(r.roleId),
                  },
                  ...(isEmpty(r.members.field.value)
                    ? [
                        {
                          return: 'docId',
                          type: 'delete',
                          icon: 'trash-alt',
                          label: 'Удалить',
                          clicked: () => setPopUp({ action: 'delete', show: true, instanceId: r.roleId }),
                        },
                      ]
                    : []),
                ]}
              />
            ))
          : [<InfoCardSkeleton />, <InfoCardSkeleton />, <InfoCardSkeleton />]}
      </div>

      {activeRole && blockId && subsectionId ? (
        <div key={`${activeRole.roleId}-${blockId}-${subsectionId}`} className="Roles-Container">
          <NavPanel blocks={createRoleNavBlock()} />
          <div className="Roles-Content">
            {renderBlockSection({ title: 'Права доступа', settingsType: 'permissions' })}
            {renderBlockSection({ title: 'Доп. опции', settingsType: 'options' })}
          </div>
        </div>
      ) : roles ? (
        <p className="NoInfoRow">Нет информации</p>
      ) : (
        getLoadingSkeleton()
      )}
    </SceneContent>
  )
}

function getLoadingSkeleton() {
  const blockSectionSkeleton = (
    <div className="Roles-SettingsRow">
      <div className="Title">
        <Skeleton width={80} />
      </div>
      <div className="Content">
        <RadioGroupSkeleton />
      </div>
    </div>
  )

  return (
    <div key="Roles-Skeleton" className="Roles-Container">
      <NavPanel blocks={null} />
      <div className="Roles-Content">
        <div>
          <SceneContentHeader title={<Skeleton width={80} height={12} />} />
          {[blockSectionSkeleton, blockSectionSkeleton]}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  solution: state.auth.account.data.solution,
  uid: state.auth.user.uid,
})

export default compose(withRouter, connect(mapStateToProps))(Roles)
