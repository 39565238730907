import subsectionConfig from '../../../../../../appTree/subsectionConfig'

function getDealsManagerSubmenu({ dealId, modulePath }) {
  const submenuLinks = [
    subsectionConfig({
      id: '',
      title: 'Инфо о сделке',
      path: dealId ? `${modulePath}/${dealId}/edit` : `${modulePath}/add`,
      access: true,
    }),
  ]

  return submenuLinks
}

export default getDealsManagerSubmenu
