import isEmpty from 'lodash/isEmpty'
import fetchTaskContacts from './fetchTaskContacts'
import { db } from '../../../../../config/firebase'

const fetchTaskDeals = ({ accountId, sales }) => {
  if (!isEmpty(sales)) {
    let parsedSales = []
    return Promise.all(sales.map((l) => db.collection('sales').doc(l).get()))
      .then((fetchedSales) => {
        parsedSales = fetchedSales.map((l) => ({ saleId: l.id, ...l.data() }))
        return Promise.all(parsedSales.map((pl) => fetchTaskContacts([pl.contact.id])))
      })
      .then((parsedSalesWithContacts) =>
        parsedSales.map((pl, index) => ({ saleId: pl.saleId, ...parsedSalesWithContacts[index][0] })),
      )
  }
  return []
}

export default fetchTaskDeals
