import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import * as VALIDATION_PATTERNS from '../../constants/forms/validationPatterns'
import * as FIELD_ERRORS from '../../constants/forms/fieldErrors'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

export const checkValidity = ({ fieldType, value, validation, ...rest }) => {
  let isValid = {
    status: true,
    error: '',
  }

  if (fieldType === 'phone') {
    const number = parsePhoneNumberFromString(value, rest.country)
    if (number && number.isValid()) {
      return {
        status: true,
        error: '',
      }
    }

    return {
      status: false,
      error: FIELD_ERRORS.PHONE_NOT_VALID,
    }
  }

  if (!validation) {
    return true
  }

  if (validation.required && fieldType !== 'checkbox') {
    switch (validation.pattern) {
      case 'email':
        if (VALIDATION_PATTERNS.EMAIL.pattern.test(value) && isValid.status) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: VALIDATION_PATTERNS.EMAIL.error,
          }
        }
        break
      case 'date':
        if (VALIDATION_PATTERNS.DATE.pattern.test(value) && isValid.status) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: VALIDATION_PATTERNS.DATE.error,
          }
        }
        break

      default:
        if (
          (isString(value) && value.trim() !== '' && isValid.status) ||
          (isArray(value) && !isEmpty(value) && isValid.status)
        ) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: FIELD_ERRORS.EMPTY_FIELD,
          }
        }
        break
    }
  }

  // if (rules.minLength) {
  //   isValid = value.length >= rules.minLength && isValid;
  // }

  // if (rules.maxLength) {
  //   isValid = value.length <= rules.maxLength && isValid;
  // }

  // if (rules.isEmail) {
  //   const pattern = ;
  //   isValid = pattern.test(value) && isValid;
  // }

  // if (rules.isNumeric) {
  //   const pattern = /^\d+$/;
  //   isValid = pattern.test(value) && isValid;
  // }

  return isValid
}

export const isFormValid = (form) => {
  const requiredFields = form.filter(
    (field) =>
      field.fieldType !== 'html' &&
      field.validation.required &&
      ((field.hasOwnProperty('shouldRender') && field.shouldRender) || !field.hasOwnProperty('shouldRender')),
  )
  let formIsValid = true

  requiredFields.forEach((field) => {
    if (!field.valid) formIsValid = false
  })

  return formIsValid
}
