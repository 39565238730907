import React, { useEffect, useState } from 'react'
import './DealContacts.sass'
import { cloneDeep, find, isEmpty } from 'lodash'
import SceneContentHeader from '../../../../../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import ContactPopUpManager from '../../../../../../Contacts/AttachedContacts/components/ContactPopUpManager/ContactPopUpManager'
import OrganizationPopUpManager from '../../../../../../Contacts/AttachedOrganizations/components/OrganizationPopUpManager/OrganizationPopUpManager'
import ContactCard from '../../../../../../Contacts/ContactCard/ContactCard'
import OrganizationCard from '../../../../../../Contacts/OrganizationCard/OrganizationCard'
import useModuleInit from '../../../../../../../utilities/newforms/changed/useModuleInit'
import AddContactButton from '../../../../../../Contacts/AttachedContactsAndOrganizations/AddContactButton'

function DealContacts({ syncState, useCustomer, contact, customer, isDetachableContact, onDetach, disableEdit }) {
  const [showPopUpForContact, setShowPopUpForContact] = useState(null)
  const [showPopUpForCustomer, setShowPopUpForCustomer] = useState(null)

  const [contacts, setContacts] = useModuleInit({ formData: contact, initialState: [] })
  const [customers, setCustomers] = useModuleInit({ formData: customer, initialState: [] })

  const syncContacts = ({ type, id }) => {
    const contactsClone = cloneDeep(contacts)
    contactsClone.push({ type, id })
    setContacts(contactsClone)
  }

  const syncCustomers = ({ type, id }) => {
    const customersClone = cloneDeep(customers)
    customersClone.push({ type, id })
    setCustomers(customersClone)
  }

  useEffect(() => {
    syncState(contacts, customers)
  }, [contacts, customers])

  let popUp = null
  if (showPopUpForContact) {
    switch (showPopUpForContact.type) {
      case 'contact':
        popUp = (
          <ContactPopUpManager
            contactId={showPopUpForContact.id}
            pushContact={({ contactId }) => syncContacts({ type: 'contact', id: contactId })}
            close={() => setShowPopUpForContact(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contacts, ['id', c.contactId]))}
          />
        )
        break

      default:
        popUp = (
          <OrganizationPopUpManager
            organizationId={showPopUpForContact.id}
            pushOrganization={({ organizationId }) => syncContacts({ type: 'organization', id: organizationId })}
            close={() => setShowPopUpForContact(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contacts, ['id', c.organizationId]))}
          />
        )
    }
  }

  if (showPopUpForCustomer) {
    switch (showPopUpForCustomer.type) {
      case 'contact':
        popUp = (
          <ContactPopUpManager
            contactId={showPopUpForCustomer.id}
            pushContact={({ contactId }) => syncCustomers({ type: 'contact', id: contactId })}
            close={() => setShowPopUpForCustomer(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contacts, ['id', c.contactId]))}
          />
        )
        break

      default:
        popUp = (
          <OrganizationPopUpManager
            organizationId={showPopUpForCustomer.id}
            pushOrganization={({ organizationId }) => syncCustomers({ type: 'organization', id: organizationId })}
            close={() => setShowPopUpForCustomer(null)}
            omitOptions={(ctcts) => ctcts.filter((c) => !find(contacts, ['id', c.organizationId]))}
          />
        )
    }
  }

  return (
    <div className="SceneContent">
      {popUp}
      <DealContact isActive={!isEmpty(contacts)} title="Контакт">
        {!isEmpty(contacts) &&
          contacts.map((c, i) =>
            c.type === 'contact' ? (
              <ContactCard
                contactId={c.id}
                buttons={[
                  { type: 'openCard' },
                  { type: 'edit', onEdit: () => setShowPopUpForContact({ type: 'contact', id: c.id }) },
                  ...(isDetachableContact && !disableEdit
                    ? [
                        {
                          type: 'detach',
                          onDetach: () =>
                            onDetach(
                              {
                                type: 'contact',
                                data: { type: 'contact', id: c.id },
                              },
                              contacts,
                              setContacts,
                            ),
                        },
                      ]
                    : []),
                ]}
              />
            ) : (
              <OrganizationCard
                expanded
                organizationId={c.id}
                buttons={[
                  { type: 'openCard' },
                  { type: 'edit', onEdit: () => setShowPopUpForContact({ type: 'organization', id: c.id }) },
                  ...(isDetachableContact && !disableEdit
                    ? [
                        {
                          type: 'detach',
                          onDetach: () =>
                            onDetach(
                              {
                                type: 'contact',
                                data: { type: 'organization', id: c.id },
                              },
                              contacts,
                              setContacts,
                            ),
                        },
                      ]
                    : []),
                ]}
              />
            ),
          )}
        {!disableEdit && (
          <div
            className="DealContact-AddBlock"
            style={{ gridTemplateColumns: isEmpty(contacts) ? 'repeat(2, 1fr)' : '1fr' }}
          >
            {!find(contacts, ['type', 'organization']) && (
              <AddContactButton
                title="Добавить контакт"
                icon="user"
                onClick={() => setShowPopUpForContact({ type: 'contact' })}
              />
            )}
            {isEmpty(contacts) && (
              <AddContactButton
                title="Добавить организацию"
                icon="building"
                onClick={() => setShowPopUpForContact({ type: 'organization' })}
              />
            )}
          </div>
        )}
        {isEmpty(contacts) && disableEdit && <div className="NoInfoRow">Нет контактов</div>}
      </DealContact>
      {useCustomer && (
        <DealContact isActive={!isEmpty(customers)} title="Клиент">
          {!isEmpty(customers) &&
            customers.map((c, i) =>
              c.type === 'contact' ? (
                <ContactCard
                  expanded={i === 0}
                  contactId={c.id}
                  buttons={[
                    { type: 'openCard' },
                    { type: 'edit', onEdit: () => setShowPopUpForCustomer({ type: 'contact', id: c.id }) },
                    ...(!disableEdit
                      ? [
                          {
                            type: 'detach',
                            onDetach: () =>
                              onDetach(
                                {
                                  type: 'customer',
                                  data: { type: 'contact', id: c.id },
                                },
                                customers,
                                setCustomers,
                              ),
                          },
                        ]
                      : []),
                  ]}
                />
              ) : (
                <OrganizationCard
                  expanded
                  organizationId={c.id}
                  buttons={[
                    { type: 'openCard' },
                    { type: 'edit', onEdit: () => setShowPopUpForCustomer({ type: 'organization', id: c.id }) },
                    ...(!disableEdit
                      ? [
                          {
                            type: 'detach',
                            onDetach: () =>
                              onDetach(
                                {
                                  type: 'customer',
                                  data: { type: 'contact', id: c.id },
                                },
                                customers,
                                setCustomers,
                              ),
                          },
                        ]
                      : []),
                  ]}
                />
              ),
            )}

          {isEmpty(customers) && !disableEdit && (
            <div className="DealContact-AddBlock" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <AddContactButton
                title="Добавить контакт"
                icon="user"
                onClick={() => setShowPopUpForCustomer({ type: 'contact' })}
              />
              <AddContactButton
                title="Добавить организацию"
                icon="building"
                onClick={() => setShowPopUpForCustomer({ type: 'organization' })}
              />
              {/* <AddDealContact title="Прикрепить сделку" icon="dollar-sign" /> */}
            </div>
          )}
          {isEmpty(customers) && disableEdit && <div className="NoInfoRow">Нет клиента</div>}
        </DealContact>
      )}
    </div>
  )
}

export function DealContact({ isActive, title, children }) {
  return (
    <div className="DealContact">
      <div className="DealContact-Header">
        <div className={['DealContact-Marker', ...(isActive ? ['DealContact-Marker_theme_active'] : [])].join(' ')}>
          <span className="Marker-Dot" />
        </div>
        <SceneContentHeader title={title} />
      </div>
      <div className="DealContact-Body">{children}</div>
    </div>
  )
}

export default DealContacts
