import React from 'react'
import Icon from '../../../components/UI/Icon/Icon'

function ActionButton({ icon, weight, theme, clicked }) {
  return (
    <button
      className={['AudioPlayer-ActionButton', ...(theme ? [`AudioPlayer-ActionButton_theme_${theme}`] : [])].join(' ')}
      onClick={() => clicked()}
    >
      <Icon name={icon} weight={weight} />
    </button>
  )
}

export default ActionButton
