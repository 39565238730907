import React, { useState, useEffect } from 'react'
import './ManageInfoBlockPopUp.sass'
import uniqid from 'uniqid'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'react-redux'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import { createSelectbox, createField } from '../../../../../../../utilities/forms/createFormElement'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import { getOfficeInfoBlockTypes } from '../../../../../../../utilities/getOptions/getOfficeInfoBlockTypes'
import Field from '../../../../../../../components/UI/Forms/Field/Field'
import { isFormValid } from '../../../../../../../utilities/forms/validation'
import { firebase } from '../../../../../../../config/firebase'
import IconicButton from '../../../../../../../components/UI/IconicButton/IconicButton'
import { fieldChanged } from '../../../../../../../utilities/forms/fieldChanged'
import getDoc from '../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'

function ManageInfoBlockPopUp({ accountId, officeId, infoBlockId, editMode, show, close, uid }) {
  const popUpConfig = editMode ? { title: 'Редактировать инфоблок' } : { title: 'Добавить инфоблок' }

  const [infoBlockForm, setInfoBlockForm] = useState([
    {
      fieldId: 'blockType',
      ...createSelectbox('Выберите блок', true, () => getOfficeInfoBlockTypes(accountId)),
    },
  ])

  const [infoBlockContent, setInfoBlockContent] = useState(createDescriptionFields())
  const [isLoading, setIsLoading] = useState(false)
  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const submitInfoBlock = () => {
    if (isFormValid(infoBlockForm)) {
      setShowFieldErrors(false)
      setIsLoading(true)

      const infoBlockObject = {
        infoBlockId: infoBlockForm[0].value,
        subId: uniqid(),
        content: infoBlockContent.map((content) => ({
          lineTitle: content.lineTitle.value,
          lineValue: content.lineValue.value,
        })),
      }
      if (infoBlockId && editMode) {
        getDoc({ accountId, path: 'offices', docId: officeId })
          .then(({ infoBlocks }) => {
            const indexOfModifiedBlock = findIndex(infoBlocks, ['subId', infoBlockId])
            infoBlocks.splice(indexOfModifiedBlock, 1, infoBlockObject)
            return updateDoc({ accountId, path: 'offices', docId: officeId, data: { infoBlocks }, uid })
          })
          .then(() => {
            close()
          })
      } else {
        updateDoc({
          accountId,
          path: 'offices',
          docId: officeId,
          data: { infoBlocks: firebase.firestore.FieldValue.arrayUnion(infoBlockObject) },
          uid,
        }).then(() => {
          close()
        })
      }
    } else {
      setShowFieldErrors(true)
    }
  }

  useEffect(() => {
    if (infoBlockId) {
      getDoc({ accountId, path: 'offices', docId: officeId }).then((office) => {
        const infoBlock = find(office.infoBlocks, ['subId', infoBlockId])
        const infoBlockFormClone = cloneDeep(infoBlockForm)
        infoBlockFormClone[0].value = infoBlock.infoBlockId
        infoBlockFormClone[0].touched = true
        infoBlockFormClone[0].valid = true

        setInfoBlockForm(infoBlockFormClone)
        const contentWithValues = createDescriptionFields(infoBlock.content)
        setInfoBlockContent(contentWithValues)
      })
    }
  }, [])

  const onDescriptionFieldChangedHandler = (fieldIdentifier, index, event) => {
    const contentClone = cloneDeep(infoBlockContent)
    const field = contentClone[index][fieldIdentifier]
    fieldChanged(field, event)
    setInfoBlockContent(contentClone)
  }

  const addDescriptionLine = () => {
    const infoBlockContentClone = cloneDeep(infoBlockContent)
    infoBlockContentClone.push(createDescriptionFields()[0])
    setInfoBlockContent(infoBlockContentClone)
  }

  const removeDescriptionLine = (index) => {
    const infoBlockContentClone = cloneDeep(infoBlockContent)
    infoBlockContentClone.splice(index, 1)
    setInfoBlockContent(infoBlockContentClone)
  }

  return (
    <PopUp
      show={show}
      close={close}
      title={popUpConfig.title}
      submitButtons
      onSubmit={submitInfoBlock}
      onCancel={close}
    >
      <div className="ManageInfoBlock-Container">
        {renderFields(infoBlockForm, setInfoBlockForm, showFieldErrors)}
        <div className="ManageInfoBlockPopUp-DescriptionLinesContainer">
          {infoBlockContent.map((c, index) => (
            <div className="ManageInfoBlockPopUp-DescriptionLine">
              <Field {...c.lineTitle} changed={(e) => onDescriptionFieldChangedHandler('lineTitle', index, e)} />
              <Field {...c.lineValue} changed={(e) => onDescriptionFieldChangedHandler('lineValue', index, e)} />
              {index === 0 ? (
                <IconicButton
                  type="add"
                  icon="plus"
                  customClass="ManageInfoBlockPopUp-DescriptionLine-FunctionButton"
                  clicked={addDescriptionLine}
                />
              ) : (
                <IconicButton
                  type="remove"
                  icon="minus"
                  customClass="ManageInfoBlockPopUp-DescriptionLine-FunctionButton"
                  clicked={() => removeDescriptionLine(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </PopUp>
  )
}

const descriptionFields = {
  lineTitle: createField('input', 'text', 'Строка'),
  lineValue: createField('input', 'text', 'Значение'),
}

const createDescriptionFields = (formData) => {
  const arr = []

  const f = cloneDeep(descriptionFields)
  if (formData) {
    formData.forEach((fieldSet) => {
      const obj = cloneDeep(descriptionFields)
      for (const key in fieldSet) {
        obj[key].value = fieldSet[key]
      }
      arr.push(obj)
    })
  } else {
    arr.push(f)
  }

  return arr
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(ManageInfoBlockPopUp)
