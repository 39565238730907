import getDoc from '../../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'

const deleteSquad = ({ accountId, officeId, squadId, uid }) =>
  getDoc({ accountId, path: 'offices', docId: officeId })
    .then((office) =>
      updateDoc({
        accountId,
        path: 'offices',
        docId: officeId,
        data: { squads: office.squads.filter((s) => s.squadId !== squadId) },
        uid,
      }),
    )
    .then(() => console.log('squad removed'))

export default deleteSquad
