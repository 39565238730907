import React, { useState, useEffect, useRef } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { connect } from 'react-redux'
import { usePopUp } from '../../../../../../../hooks/usePopUp'

import Button from '../../../../../../../components/UI/Button/Button'
import { TaskResponsible } from '../TaskNav/options/TaskResponsible'

function ResponsibleChooser({ accountId, responsible, uid, updateTaskConfig }) {
  const butRef = useRef()
  const ddBRef = useRef()
  const defaultLabel = ['Выберите ответственного']

  const [showSelectBox] = usePopUp(butRef, ddBRef, true)
  const [options, setOptions] = useState([])
  const [label, setLabel] = useState([])

  useEffect(() => {
    const fetchedLabels = []

    responsible.forEach((r) => {
      const option = find(options, ['value', r])
      if (option) fetchedLabels.push(option.label)
    })
    if (isEmpty(fetchedLabels)) setLabel(defaultLabel)
    else setLabel(fetchedLabels)
  }, [options, responsible])

  return (
    <>
      <div className="SelectableLabel" ref={butRef}>
        <span className="NoStyles">для</span>
        {label.map((l) => (
          <span key={l}>{l}</span>
        ))}
      </div>
      <div
        ref={ddBRef}
        className="SelectableLabel-PopUpContainer"
        style={{ position: 'relative', display: showSelectBox ? 'block' : 'none' }}
      >
        <TaskResponsible
          accountId={accountId}
          data={{ responsible: responsible.map((r) => ({ responsibleId: r })) }}
          uid={uid}
          returnValueCb={(resp) => updateTaskConfig({ responsible: resp })}
          returnOptionsCb={setOptions}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountId: state.auth.account.data.accountId,
})

export default connect(mapStateToProps)(ResponsibleChooser)
