import React from 'react'
import zb6aPxoNMXsPKsVZfT88 from './zb6aPxoNMXsPKsVZfT88/Logic'

function AppsLogic({ extensionId }) {
  const extensionsLogic = { zb6aPxoNMXsPKsVZfT88 }

  const Component = extensionsLogic[extensionId]
  return <Component extensionId={extensionId} />
}

export default AppsLogic
