import React, { useState } from 'react'
import parse from 'html-react-parser'
import CodeForm from './CodeForm'
import smsIcon from './assets/smsIcon.svg'
import Button from '../../../../ui/Button/Button'
import { createForm } from '../../../../utilities/newforms'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'
import FormRender from '../../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../../utilities/newforms/getFormValues'
import { updateDoc } from '../../../../utilities/db/updateDoc'
import sendPhoneConfirmationCode from './functions/sendPhoneConfirmationCode'

function ConfirmPhone({ user }) {
  const [showChangePhone, setShowChangePhone] = useState(!user.phone.value)
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        phone: {
          field: {
            fieldId: 'phone',
            fieldType: 'phone',
            inputType: 'text',
            icon: 'phone',
            placeholder: 'Введите ваш контактный телефон',
            label: 'Контактный телефон',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    }),
  )
  const [showErrors, setShowErrors] = useState(false)
  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setIsLoading(true)
    setShowErrors(false)
    const values = getFormValues({ form })
    updateDoc({ path: 'users', docId: user.uid, data: { ...values } }).then(() => setShowChangePhone(false))
  }

  return showChangePhone ? (
    <div className="ConfirmationForm">
      <img src={smsIcon} alt="phone" className="ConfirmationForm-Icon" />
      <div className="AuthForm-Header">
        <h5 className="AuthForm-Title">Укажите номер телефона</h5>
      </div>
      <form onSubmit={onSubmit} className="ConfirmationForm-Container">
        <div className="Site-Fields">
          <FormRender sections={[{ fields: ['phone'] }]} form={form} setForm={setForm} errors={showErrors} />
        </div>
        <div className="ConfirmationForm-Buttons">
          <Button
            type="submit"
            className="AuthForm-Button"
            icon="check"
            title="Сохранить"
            fill="black"
            theme="solid"
            isLoading={isLoading}
            onClick={onSubmit}
            size={48}
          />
        </div>
        <p className="ConfirmationForm-LinkedText">
          <span onClick={() => setShowChangePhone(false)}>Шаг назад</span>
        </p>
      </form>
    </div>
  ) : (
    <>
      <CodeForm
        icon={smsIcon}
        type="phone"
        title="Последний шаг"
        description={parse(
          `Осталось подтвердить ваш номер телефона. Для этого мы выслали SMS с проверочным кодом на номер <b>${user.phone.value}</b>. Пожалуйста, введите полученый код в форму ниже.`,
        )}
        changeData={
          <>
            Если номер телефона указан неверно, пожалуйста{' '}
            <span onClick={() => setShowChangePhone(true)}>измените номер</span> на актуальный.
          </>
        }
        sendCodeFunc={() => sendPhoneConfirmationCode({ uid: user.uid, phone: user.phone.metadata.number })}
        redirectTo="/profile/accounts"
        sendCodeOnInit
        user={user}
      />
    </>
  )
}

export default ConfirmPhone
