import { addDays } from 'date-fns'
import getFieldRenderObject from '../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  title: {
    field: {
      fieldId: 'title',
      fieldType: 'input',
      inputType: 'text',
      label: 'Название аккаунта',
      placeholder: 'Введите название аккаунта',
      required: false,
    },
    render: getFieldRenderObject(),
  },
  identifier: {
    field: {
      fieldId: 'identifier',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  solution: {
    field: {
      fieldId: 'solution',
      value: null,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  avatar: {
    field: {
      fieldId: 'avatar',
      fieldType: 'inputFile',
      inputType: 'file',
      label: 'Файл',
      icon: 'ellipsis-v',
    },
    render: getFieldRenderObject(),
  },
  members: {
    field: {
      fieldId: 'members',
      value: [],
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  totalMembers: {
    field: {
      fieldId: 'totalMembers',
      value: 3,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  owner: {
    field: {
      fieldId: 'owner',
      value: '',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  expired: {
    field: {
      fieldId: 'expired',
      value: addDays(new Date(), 7),
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  isActive: {
    field: {
      fieldId: 'isActive',
      value: false,
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  withData: {
    field: {
      fieldId: 'withData',
      fieldType: 'checkbox',
      label: 'Заполнить систему данными',
      value: true,
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
  created: {
    field: {
      fieldId: 'created',
      value: new Date(),
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  updated: {
    field: {
      fieldId: 'updated',
      value: new Date(),
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
  version: {
    field: {
      fieldId: 'version',
      value: '1.0',
      systemField: true,
    },
    render: getFieldRenderObject({ isSystem: true }),
  },
}

export default object
