import React from 'react'
import SiteSectionTitle from '../../components/SiteSectionTitle/SiteSectionTitle'

function UserAgreement() {
  return (
    <div className="Site-PrivacyPolicy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SiteSectionTitle title="Соглашение об использовании сайта" subTitle="Редакция от: 28 марта 2021" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="PrivacyPolicy-Container">
              <p>
                Внимательно прочитайте настоящее Соглашение, прежде чем начать пользоваться Сайтом. Вы обязаны соблюдать
                условия настоящего Соглашения, заходя на Сайт, используя сервисы, услуги и приложения, предлагаемые на
                Сайте. В случае если вы не согласны с условиями Соглашения, вы не можете пользоваться Сайтом или
                использовать любые сервисы, услуги и приложения, предлагаемые на Сайте, а также посещать страницы,
                размещенные в доменной зоне Сайта. Начало использования Сайта означает надлежащее заключение настоящего
                Соглашения и ваше полное согласие со всеми его условиями.
              </p>
              <h5>1. Термины и определения.</h5>
              <p>1.1. CardBox – Индивидуальный предприниматель Старкова Мария Вадимовна.</p>
              <p>
                1.2. Пользователь – лицо, получающее доступ к приложениям, сервисам, услугам и информации, размещенной
                на Сайте.
              </p>
              <p>
                1.3. Сайт - веб-сайты CardBox, размещенные в сети Интернет по адресам www.cdbx.ru, www.cdbx.io, в том
                числе поддомены ***.cdbx.ru, ***.cdbx.io, а также любые другие веб-сайты CardBox, содержащие ссылку на
                данное Соглашение.
              </p>
              <p>
                1.4. Соглашение – настоящее Соглашение между Пользователем и CardBox, устанавливающее правила
                использования Сайта, включая графические изображения и аудиовизуальную продукцию, элементы дизайна и
                средства индивидуализации, текстовую информацию и документацию, программы для ЭВМ и файлы для
                скачивания, любые иные произведения, объекты и материалы Сайта, а также условия и правила размещения
                Пользователем информации и материалов в соответствующих открытых разделах Сайта.
              </p>
              <h5>2. Общие положения и условия.</h5>
              <p>
                2.1. Доступ к информации, находящейся на защищенных разделах Сайта, разрешен только зарегистрированным
                Пользователям, получившим пароль для входа на защищенные разделы Сайта. Пароль не может передаваться
                другим лицам, и Пользователь полностью несет ответственность за весь ущерб, причиненный ему, CardBox или
                третьим лицам, возникший вследствие намеренной или ненамеренной передачи Пользователем пароля другому
                лицу. Пользователь несет ответственность за сохранение конфиденциальности пароля и любое использование
                Сайта посредством его пароля.
              </p>
              <p>
                2.2. Действующая редакция настоящего Соглашения размещена на Сайте. CardBox вправе в любое время в
                одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по
                истечении 2 (двух) дней с момента размещения новой версии Соглашения на Сайте. Пользователь обязан
                регулярно просматривать настоящее Соглашение и дополнительные условия или уведомления, размещенные на
                Сайте, так как постоянный доступ Пользователя данного Сайта считается убедительным принятием измененного
                соглашения.
              </p>
              <h5>3. Обязательства Пользователя.</h5>
              <p>
                3.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие
                российское законодательство или нормы международного права, в том числе в сфере интеллектуальной
                собственности, авторских и/или смежных правах, а также любых действий, которые приводят или могут
                привести к нарушению нормальной работы Сайта и сервисов Сайта.
              </p>
              <p>
                3.2. Любые средства индивидуализации, в том числе товарные знаки и знаки обслуживания, а равно логотипы
                и эмблемы, содержащиеся на страницах Сайта, являются интеллектуальной собственностью их
                правообладателей. Пользователю Сайта запрещено воспроизводить или иным способом использовать указанные
                средства индивидуализации и/или их элементы без предварительного письменного разрешения соответствующих
                правообладателей.
              </p>
              <p>
                3.3. В случае предъявления третьими лицами претензий CardBox, связанных с нарушением Пользователем
                условий настоящего Соглашения, а равно с размещенной Пользователем информацией на Сайте, указанный
                Пользователь обязуется самостоятельно урегулировать такие претензии, а также возместить CardBox все
                понесенные убытки и потери, включая возмещение штрафов, судебных расходов, издержек и компенсаций.
              </p>
              <p>
                3.4. CardBox не несет ответственности за посещение Пользователем, а также любое использование им внешних
                ресурсов (сайтов третьих лиц), ссылки на которые могут содержаться на Сайте. CardBox не несет
                ответственности за точность, надежность, достоверность и безопасность любой информации, материалов,
                рекомендаций и сервисов, размещенных на внешних ресурсах. Использование внешних ресурсов осуществляется
                Пользователем добровольно, исключительно по собственному усмотрению.
              </p>
              <p>
                3.5. CardBox стремится к обеспечению достоверности информации, размещенной на Сайте, однако не несет
                ответственности за любые неточности и/или недостоверность информации, а равно сбои в работе
                предоставляемых через Сайт сервисов. Пользователь согласен с тем, что CardBox не несет ответственность и
                не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или
                возникшими потерями или убытками, связанными с любым содержанием Сайта, интеллектуальной собственностью,
                товарами или услугами, доступными на нем или полученными через внешние сайты или ресурсы либо иные
                ожидания Пользователя, которые возникли в связи с использованием размещенной на Сайте информации или
                ссылки на внешние ресурсы.
                <br />
                Ни при каких условиях, включая, но не ограничиваясь невнимательностью или небрежностью Пользователя,
                CardBox не несет ответственности за любой ущерб (прямой или косвенный, случайный или закономерный),
                включая, но не ограничиваясь потерей данных или прибылей, связанной с использованием или невозможностью
                использования Сайта, информации, Программ, файлов или материалов на нем, даже если CardBox или его
                представители были предупреждены о возможности такой потери. В случае, если использование Сайта приведет
                к необходимости дополнительного обслуживания, исправления или ремонта любого оборудования, а равно
                восстановления данных, все связанные с этим затраты оплачиваются Пользователем.
              </p>
              <p>
                3.6. Вся представленная на Сайте информация предоставляется «как есть», без каких-либо гарантий, явных
                или подразумеваемых. CardBox полностью, в той мере, в какой это разрешено законом, отказывается от
                какой-либо ответственности, явной или подразумеваемой, включая, но не ограничиваясь неявными гарантиями
                пригодности к использованию, а также гарантиями законности любой информации, продукта или услуги,
                полученной или приобретенной с помощью этого Сайта.
              </p>
              <p>
                3.7. Пользователь согласен, что все материалы и сервисы Сайта или любая их часть могут сопровождаться
                рекламой, размещение которой не инициирует и не контролирует CardBox. Пользователь согласен с тем, что
                CardBox не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с такой рекламой.
              </p>
              <h5>4. Условия обработки и использования персональных данных.</h5>
              <p>
                4.1. Принимая условия настоящего Соглашения, Пользователь выражает свое согласие на обработку своих
                персональных данных.
              </p>
              <h5>5. Прочие положения</h5>
              <p>
                5.1. Использование материалов и сервисов Сайта, а равно размещение на нем материалов Пользователя,
                регулируется нормами действующего законодательства Российской Федерации. Все возможные споры, вытекающие
                из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим
                законодательством Российской Федерации по месту нахождения CardBox.
              </p>
              <p>
                5.2. Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безоговорочно
                принимает их.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAgreement
