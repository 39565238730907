import React from 'react'
import { filter, isEmpty } from 'lodash'
import SceneContent from '../../../../../../../components/UI/SceneContent/SceneContent'
import {
  addButton,
  deleteButton,
  editButton,
} from '../../../../../../../components/UI/SceneContent/SceneContentHeader/buttons'
import SceneTable from '../../../../../../../components/UI/SceneContent/SceneTable/SceneTable'

import {
  editButton as editOption,
  deleteButton as deleteOption,
} from '../../../../../../../components/UI/SceneContent/Buttons/buttons'

function CategoryPriceTile({ togglePopUp, services, title, categoryId }) {
  return (
    <SceneContent
      title={title}
      buttons={[
        deleteButton({ clicked: () => togglePopUp({ type: 'delCat', instanceId: categoryId }) }),
        editButton({ clicked: () => togglePopUp({ type: 'cat', instanceId: categoryId }) }),
        addButton({ clicked: () => togglePopUp({ type: 'service', instanceId: categoryId }) }),
      ]}
    >
      <SceneTable
        header={[['Услуга'], ['Стоимость'], ['', 32]]}
        body={
          !isEmpty(services) &&
          filter(services, ['categoryId', categoryId]).map((s) => [
            { id: s.serviceId },
            <span>{s.title}</span>,
            <span>{s.price}</span>,
            'options',
          ])
        }
        options={[
          editOption((serviceId) => togglePopUp({ type: 'service', instanceId: categoryId, serviceId })),
          deleteOption((serviceId) => togglePopUp({ type: 'delServ', serviceId })),
        ]}
      />
    </SceneContent>
  )
}

export default CategoryPriceTile
