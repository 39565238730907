import React from 'react'
import './Confirmation.sass'
import { connect } from 'react-redux'
import TechPage from '../../SitePage/TechPage'
import { SignPageNav } from '../SignPage/SignPage'
import ConfirmEmail from './ConfirmEmail'
import ConfirmPhone from './ConfirmPhone'

function Confirmation({ user }) {
  return (
    <TechPage nav={<SignPageNav user={user} nav="signin" />}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="ConfirmationForm-Container">
              {!user.emailVerified ? (
                <ConfirmEmail key={user.confirmationCode} user={user} />
              ) : !user.phoneVerified ? (
                <ConfirmPhone key={user.confirmationCode} user={user} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </TechPage>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(Confirmation)
