import React, { useRef } from 'react'
import './FileItem.sass'
import Icon from '../UI/Icon/Icon'
import { formatDateAsDayMontYearHHMM } from '../../utilities/date/dateFormat'
import Skeleton from '../UI/Skeleton/Skeleton'
import OptionsMenu from '../UI/OptionsMenu/OptionsMenu'
import IconicButton from '../UI/IconicButton/IconicButton'
import { usePopUp } from '../../hooks/usePopUp'
import { isEmpty } from 'lodash'

function FileItem({ onEdit, onDelete, title, needUpload, created } = {}) {
  const butRef = useRef()
  const optRef = useRef()
  const [showOptions] = usePopUp(butRef, optRef)

  const statusConfig = getFileItemStatus(needUpload)

  return (
    <div className="FileManager-FileItem">
      {created ? (
        <div className="FileItem-Icon">
          <Icon name="file-alt" />
        </div>
      ) : (
        <Skeleton circle height={20} width={20} />
      )}
      <div className="FileItem-Info">
        <div className="FileItem-InfoHeader">
          <span className="FileDate">
            {created ? (
              formatDateAsDayMontYearHHMM(created.hasOwnProperty('seconds') ? created : { seconds: created / 1000 })
            ) : (
              <Skeleton width={80} height={8} />
            )}
          </span>
          {needUpload && (
            <span className="FileStatus" style={{ color: statusConfig.color }}>
              {!isEmpty(statusConfig) ? statusConfig.title : <Skeleton width={60} height={8} />}
            </span>
          )}
        </div>
        <div className="FileItem-Description">
          <div className="FileItem-Text">{title ? title : <Skeleton width={120} height={8} />}</div>
          <div className="FileItem-Options">
            {created ? (
              <div className="OptionsButton-Container">
                <IconicButton ref={butRef} type="options" icon="list-ul" />{' '}
                {showOptions && (
                  <OptionsMenu
                    ref={optRef}
                    options={[
                      { label: 'Редакт.', icon: 'pencil-alt', clicked: onEdit },
                      { label: 'Удалить', icon: 'trash-alt', clicked: onDelete },
                    ]}
                  />
                )}
              </div>
            ) : (
              <Skeleton circle height={20} width={20} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const getFileItemStatus = (status) => {
  switch (status) {
    case true:
      return { title: 'Ожидает загрузки', color: '#F34375' }
    case false:
      return { title: 'Доступен', color: '#00C023' }
    default:
      return {}
  }
}

export default FileItem
