import React from 'react'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import isNull from 'lodash/isNull'
import sortBy from 'lodash/sortBy'
import { countQuaterSum, countTotalPlanSum, countPlanTotals, countEmployeePlanSum } from './countPlans'
import Marker from '../../../../../../components/UI/Marker/Marker'
import { getEditOptions, getDefaultOptions } from './getOptions'
import UserNameWithAvatar from '../../../../../../components/UserNameWithAvatar/UserNameWithAvatar'

export const renderEmployeesTable = (
  planId,
  officeId,
  squadData,
  planOnEdit,
  setPlanOnEdit,
  savePlan,
  editPlan,
  body,
  fieldChangedHandler,
  members,
) => {
  let employees = []
  for (const e in squadData['1'].members) {
    employees.push({ ...squadData['1'].members[e], employeeId: e, ...find(members, ['userId', e]) })
  }

  body.push(renderSquadTotalPlan(squadData))

  employees = sortBy(employees, ['lastName'])

  employees.forEach((e) => {
    const isActiveRow = planOnEdit.employeeId === e.employeeId

    const optionsContent = {
      optionsType: 'customOptions',
      options: [...(isActiveRow ? getEditOptions(savePlan, setPlanOnEdit) : getDefaultOptions(editPlan))],
    }

    body.push([
      { id: { planId, officeId, squadId: squadData.squadId, employeeId: e.employeeId } },
      ...renderPlanEmployeeData(isActiveRow, e, squadData, planOnEdit, fieldChangedHandler, optionsContent),
    ])
  })
}

export const renderSquadsTable = (
  p,
  planOnEdit,
  setPlanOnEdit,
  savePlan,
  editPlan,
  body,
  fieldChangedHandler,
  setShowForSquad,
) => {
  p.offices.forEach((o, index) => {
    o.squads.forEach((s, index) => {
      const isActiveRow =
        p.planId === planOnEdit.planId && planOnEdit.officeId === o.officeId && planOnEdit.squadId === s.squadId

      const optionsContent = {
        optionsType: 'customOptions',
        options: [...(isActiveRow ? getEditOptions(savePlan, setPlanOnEdit) : getDefaultOptions(editPlan))],
      }

      const optionsContentClone = cloneDeep(optionsContent)

      if (index === 0) {
        if (isNull(planOnEdit.squadId) && planOnEdit.officeId === o.officeId) {
          optionsContentClone.options = getEditOptions(savePlan, setPlanOnEdit)
        } else {
          optionsContentClone.options = getDefaultOptions(editPlan)
        }
        body.push(renderOfficeTotalPlan(o, p, planOnEdit, fieldChangedHandler, optionsContentClone))
      }
      body.push([
        { id: { planId: p.planId, officeId: o.officeId, squadId: s.squadId } },
        ...(index === 0 ? [{ props: { rowSpan: o.squads.length }, content: <span>{o.title}</span> }] : []),
        ...renderPlanSquadData(s, o, p, planOnEdit, fieldChangedHandler, setShowForSquad),
        optionsContent,
      ])
    })
    if (index + 1 === p.offices.length) {
      renderTotals(body, p)
    }
  })
}

const renderPlanEmployeeData = (isActiveRow, employee, squadData, planOnEdit, fieldChangedHandler, optionsContent) => {
  const employeeData = [
    <UserNameWithAvatar
      avatarUrl={employee.avatar.publicUrl}
      avatarSize="26"
      userName={`${employee.lastName} ${employee.name} ${employee.middleName}`}
    />,
  ]
  for (let index = 1; index <= 12; index++) {
    if (isActiveRow) {
      employeeData.push({
        className: 'SalesPlan-CellWithInput',
        content: (
          <>
            <input
              className="SalesPlan-Input"
              onChange={(e) => fieldChangedHandler(e, index)}
              type="text"
              value={planOnEdit[index].totalPlan}
            />
            <p className="SalesPlan-InputAlt">ост. {planOnEdit[index].availablePlan}</p>
          </>
        ),
      })
    } else {
      employeeData.push(<span>{squadData[index].members[employee.employeeId].totalPlan}</span>)
    }

    index === 3 &&
      employeeData.push(
        <span>
          {isActiveRow ? countQuaterSum(planOnEdit, 1) : countEmployeePlanSum(squadData, employee.employeeId, 1)}
        </span>,
      )
    index === 6 &&
      employeeData.push(
        <span>
          {isActiveRow ? countQuaterSum(planOnEdit, 2) : countEmployeePlanSum(squadData, employee.employeeId, 2)}
        </span>,
      )
    index === 9 &&
      employeeData.push(
        <span>
          {isActiveRow ? countQuaterSum(planOnEdit, 3) : countEmployeePlanSum(squadData, employee.employeeId, 3)}
        </span>,
      )
    if (index === 12) {
      employeeData.push(
        <span>
          {isActiveRow ? countQuaterSum(planOnEdit, 4) : countEmployeePlanSum(squadData, employee.employeeId, 4)}
        </span>,
      )
      employeeData.push(<span>{countEmployeePlanSum(squadData, employee.employeeId, false, 1, 12)}</span>)
    }
  }

  employeeData.push(optionsContent)

  return employeeData
}

export const renderPlanSquadData = (squad, office, plan, planOnEdit, fieldChangedHandler, setShowForSquad) => {
  const thisRowOnEdit =
    planOnEdit.planId === plan.planId && planOnEdit.officeId === office.officeId && planOnEdit.squadId === squad.squadId

  const squadData = [
    <div
      className="SalesPlans-SquadTitle"
      onClick={() => setShowForSquad({ squadId: squad.squadId, squadTitle: squad.title })}
    >
      <Marker color={squad.markerColor} size="6" />
      <span>{squad.title}</span>
    </div>,
  ]
  for (let index = 1; index <= 12; index++) {
    if (thisRowOnEdit) {
      squadData.push({
        className: 'SalesPlan-CellWithInput',
        content: (
          <>
            <input
              className="SalesPlan-Input"
              onChange={(e) => fieldChangedHandler(e, index)}
              type="text"
              value={planOnEdit[index].totalPlan}
            />
            <p className="SalesPlan-InputAlt">ост. {planOnEdit[index].availablePlan}</p>
          </>
        ),
      })
    } else {
      squadData.push(<span>{squad[index].totalPlan}</span>)
    }
    index === 3 &&
      squadData.push(<span>{thisRowOnEdit ? countQuaterSum(planOnEdit, 1) : countQuaterSum(squad, 1)}</span>)
    index === 6 &&
      squadData.push(<span>{thisRowOnEdit ? countQuaterSum(planOnEdit, 2) : countQuaterSum(squad, 2)}</span>)
    index === 9 &&
      squadData.push(<span>{thisRowOnEdit ? countQuaterSum(planOnEdit, 3) : countQuaterSum(squad, 3)}</span>)
    index === 12 &&
      squadData.push(<span>{thisRowOnEdit ? countQuaterSum(planOnEdit, 4) : countQuaterSum(squad, 4)}</span>)
    index === 12 &&
      squadData.push(
        <span>{thisRowOnEdit ? countTotalPlanSum(planOnEdit, 1, 12) : countTotalPlanSum(squad, 1, 12)}</span>,
      )
  }

  return squadData
}

export const renderSquadTotalPlan = (squadData) => {
  const squadTotalPlan = [
    {
      className: 'SalesPlans-TotalPlanCell',
      content: <div className="SalesPlans-AccentCell ">Общий план</div>,
    },
  ]

  for (let index = 1; index <= 12; index++) {
    squadTotalPlan.push(<span>{squadData[index].totalPlan}</span>)

    index === 3 &&
      squadTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: <div className="SalesPlans-AccentCell ">{countQuaterSum(squadData, 1)}</div>,
      })
    index === 6 &&
      squadTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: <div className="SalesPlans-AccentCell ">{countQuaterSum(squadData, 2)}</div>,
      })
    index === 9 &&
      squadTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: <div className="SalesPlans-AccentCell ">{countQuaterSum(squadData, 3)}</div>,
      })
    if (index === 12) {
      squadTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: <div className="SalesPlans-AccentCell ">{countQuaterSum(squadData, 4)}</div>,
      })
      squadTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: <div className="SalesPlans-AccentCell ">{countTotalPlanSum(squadData, 1, 12)}</div>,
      })
    }
  }

  return squadTotalPlan
}

export const renderOfficeTotalPlan = (office, plan, planOnEdit, fieldChangedHandler, optionsContent) => {
  const officeTotalPlan = [
    {
      id: {
        planId: plan.planId,
        officeId: office.officeId,
        squadId: null,
      },
    },
    <span />,
    {
      className: 'SalesPlans-TotalPlanCell',
      content: <div className="SalesPlans-AccentCell ">Общий план</div>,
    },
  ]

  const thisRowOnEdit =
    planOnEdit.planId === plan.planId && planOnEdit.officeId === office.officeId && !planOnEdit.squadId

  for (let index = 1; index <= 12; index++) {
    if (thisRowOnEdit) {
      officeTotalPlan.push({
        className: 'SalesPlan-CellWithInput',
        content: (
          <input
            className="SalesPlan-Input"
            onChange={(e) => fieldChangedHandler(e, index)}
            type="text"
            value={planOnEdit[index].totalPlan}
          />
        ),
      })
    } else {
      officeTotalPlan.push(<span>{office[index]}</span>)
    }

    index === 3 &&
      officeTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: (
          <div className="SalesPlans-AccentCell ">
            {thisRowOnEdit ? countQuaterSum(planOnEdit, 1) : countQuaterSum(office, 1)}
          </div>
        ),
      })
    index === 6 &&
      officeTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: (
          <div className="SalesPlans-AccentCell ">
            {thisRowOnEdit ? countQuaterSum(planOnEdit, 2) : countQuaterSum(office, 2)}
          </div>
        ),
      })
    index === 9 &&
      officeTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: (
          <div className="SalesPlans-AccentCell ">
            {thisRowOnEdit ? countQuaterSum(planOnEdit, 3) : countQuaterSum(office, 3)}
          </div>
        ),
      })
    index === 12 &&
      officeTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: (
          <div className="SalesPlans-AccentCell ">
            {thisRowOnEdit ? countQuaterSum(planOnEdit, 4) : countQuaterSum(office, 4)}
          </div>
        ),
      })
    index === 12 &&
      officeTotalPlan.push({
        className: 'SalesPlans-TotalPlanCell',
        content: (
          <div className="SalesPlans-AccentCell ">
            {thisRowOnEdit ? countTotalPlanSum(planOnEdit, 1, 12) : countTotalPlanSum(office, 1, 12)}
          </div>
        ),
      })
  }
  officeTotalPlan.push(optionsContent)

  return officeTotalPlan
}

export function renderTotals(body, p) {
  body.push([
    {
      className: 'SalesPlans-AccentCell SalesPlans-TotalCounts',
      props: { colSpan: 2 },
      content: <div className="">Итого:</div>,
    },
    countPlanTotals(p.offices, 1),
    countPlanTotals(p.offices, 2),
    countPlanTotals(p.offices, 3),
    countPlanTotals(p.offices, false, 1, 3),
    countPlanTotals(p.offices, 4),
    countPlanTotals(p.offices, 5),
    countPlanTotals(p.offices, 6),
    countPlanTotals(p.offices, false, 4, 6),
    countPlanTotals(p.offices, 7),
    countPlanTotals(p.offices, 8),
    countPlanTotals(p.offices, 9),
    countPlanTotals(p.offices, false, 7, 9),
    countPlanTotals(p.offices, 10),
    countPlanTotals(p.offices, 11),
    countPlanTotals(p.offices, 12),
    countPlanTotals(p.offices, false, 10, 12),
    countPlanTotals(p.offices, false, 1, 12),
    { className: 'SalesPlans-AccentCell', content: '' },
  ])
}
