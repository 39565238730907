import { difference } from 'lodash'
import getDoc from '../../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../../utilities/db/updateDoc'

const handleSquadMembersSync = ({ accountId, squadBefore, squadAfter, uid }) => {
  const afterMembers = squadAfter.members
  const beforeMembers = squadBefore ? squadBefore.members : afterMembers

  const membersWithoutSquads = difference(beforeMembers, afterMembers)

  return getDoc({ path: 'accounts', docId: accountId }).then((accountData) => {
    const newMembers = accountData.members.map((m) => {
      if (afterMembers.includes(m.userId)) m.squadId = squadAfter.squadId
      if (membersWithoutSquads.includes(m.userId)) m.squadId = ''

      return m
    })
    return updateDoc({ path: 'accounts', docId: accountId, data: { members: newMembers }, uid })
  })
}

export default handleSquadMembersSync
