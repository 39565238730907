import React, { useState, useEffect } from 'react'
import { pick, isEmpty } from 'lodash'
import UserAvatar from '../../../../../../../../components/UserAvatar/UserAvatar'
import './PinnedMember.sass'
import Skeleton from '../../../../../../../../components/UI/Skeleton/Skeleton'
import Button from '../../../../../../../../ui/Button/Button'
import Icon from '../../../../../../../../components/UI/Icon/Icon'
import { fileToBase64 } from '../../../../../../../../utilities/files/fileToBase64'
import { db } from '../../../../../../../../config/firebase'

function PinnedMember({ userId, avatar, avatarField, onDetach, onAvatarRemove, useDetachButton }) {
  const [userData, setUserData] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState(null)

  useEffect(() => {
    const listener = db
      .collection('users')
      .doc(userId)
      .onSnapshot((snap) => {
        const user = snap.data()
        setUserData(pick(user, ['avatar', 'lastName', 'name', 'middleName', 'email', 'bdate']))
      })

    return () => {
      listener()
    }
  }, [])

  useEffect(() => {
    if (avatar) {
      if (avatar.needUpload) {
        fileToBase64(avatar.file).then((result) => setAvatarUrl(result))
      } else {
        setAvatarUrl(avatar.publicUrl)
      }
    }
  }, [avatar])

  return (
    <div className="PinnedMember">
      {!isEmpty(userData) ? (
        <>
          <div className="PinnedMember-Container">
            <div className="Avatar">
              <UserAvatar url={avatarUrl || userData.avatar.publicUrl} size="56" />
              {avatarField}
              <div className="Avatar-ChangeScene">
                <Icon name="camera" />
              </div>
            </div>
            <div className="UserData">
              <p className="UserData-Name">
                {userData.name ? `${userData.lastName} ${userData.name} ${userData.middleName}` : 'Пользователь'}
              </p>
              <p className="UserData-Email">{`${userData.email}`}</p>
            </div>
            {useDetachButton && (
              <Button title="Открепить" theme="bounded" color="noty" border="noty" size={32} onClick={onDetach} />
            )}
          </div>
          {avatarUrl && (
            <Button
              className="RemoveAvatarButton"
              title="Удалить"
              theme="transparent-hug"
              color="noty"
              onClick={onAvatarRemove}
            />
          )}
        </>
      ) : (
        <PinnedMemberSkeleton useDetachButton={useDetachButton} />
      )}
    </div>
  )
}

export function PinnedMemberSkeleton({ useDetachButton }) {
  return (
    <div className="PinnedMember-Container">
      <Skeleton className="Avatar" circle width={56} height={56} />
      <div className="UserSkeletonData">
        <Skeleton width={250} height={8} />
        <Skeleton width={90} height={8} />
      </div>
      {useDetachButton && <Skeleton className="UserInviteButton" />}
    </div>
  )
}

export default PinnedMember
