import React, { useState, useEffect } from 'react'
import './CallTracker.sass'

import Timer from 'react-compound-timer'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/dialerActions'
import Icon from '../../../../components/UI/Icon/Icon'
import DialerButton from '../../DialerButton/DialerButton'
import { confirmButton, confirmDisabledButton, cancelButton } from '../../DialerButton/ButtonTypes'

function CallTracker({ call, sendCallRequest }) {
  let button

  if (call.currentState) {
    button = handleCallButtonWithCurrState(call, sendCallRequest)
  } else {
    button = call.phone ? confirmButton : confirmDisabledButton
  }

  const [activeCallStarted, setActiveCallStarted] = useState(null)

  useEffect(() => {
    setStartingTime()
  }, [])

  useEffect(() => {
    if (call.currentState !== 'active') {
      setActiveCallStarted(null)
    } else {
      setStartingTime()
    }
  }, [call])

  const setStartingTime = () => {
    if (call.hasOwnProperty('states') && call.states.active.started) {
      const now = new Date().getTime()
      const callStarted = call.states.active.started.seconds * 1000
      const diff = differenceInMilliseconds(now, callStarted)
      setActiveCallStarted(diff)
    }
  }

  return (
    <div className="CallTracker">
      <p className="CallTracker-PhoneNumber">{call.phone && call.phone}</p>
      <div className="CallTracker-Settings">
        <Icon
          name="record-vinyl"
          weight="regular"
          helperClass={`CallTracker-Recorder ${call.currentState === 'active' && `CallTracker-Recorder_theme_active`}`}
        />
        <p
          className={`CallTracker-CallTimer ${call.currentState.toLowerCase() === 'active' &&
            'CallTracker-CallTimer_theme_active'}`}
        >
          {activeCallStarted ? (
            <Timer
              initialTime={activeCallStarted}
              direction="forward"
              formatValue={(value) => `${value < 10 ? `0${value}` : value}:`}
            >
              <>
                <Timer.Hours />
                <Timer.Minutes />
                <Timer.Seconds formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
              </>
            </Timer>
          ) : (
            '00:00:00'
          )}
        </p>
        <DialerButton {...button} />
      </div>
    </div>
  )
}

const handleCallButtonWithCurrState = (call, sendCallRequest) => {
  const { currentState: state, phone, contactId, responsible, personality, sysmetCallControll } = call

  if (state === 'released' || state === 'callerror') {
    return {
      ...confirmButton,
      clicked: () =>
        sendCallRequest({
          direction: 'outbound',
          currentState: 'outboundInit',
          personality,
          contactId,
          phone,
          createContact: false,
          responsible,
        }),
    }
  } else {
    return sysmetCallControll ? cancelButton : confirmDisabledButton
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendCallRequest: (payload) => dispatch(actions.dialerSendCallRequest(payload)),
})

export default connect(null, mapDispatchToProps)(CallTracker)
