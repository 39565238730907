import {
  getAddOptions,
  getAddressTypesOptions,
  getDealFailReasons,
  getDocCreationTypesOptions,
  getEmailTypesOptions,
  getEstateCategoriesOptions,
  getEstateTypesOptions,
  getMembersOptions,
  getOfficesOptions,
  getOfficesSquadsOptions,
  getPhoneTypesOptions,
  getServiceTypesOptions,
  getSystemRolesOptions,
} from '../getOptions'
import { useCallbacks } from '../shared'

const useSystemFieldsMethods = ({ accountId, form = null }) => {
  const methods = {
    getServiceTypesOptions: () => getServiceTypesOptions(accountId),
    getMembersOptions: () => getMembersOptions({ accountId }),
    getDealFailReasons: () => getDealFailReasons(accountId),
    getEstateCategoriesOptions: () => getEstateCategoriesOptions(accountId),
    getEstateCategoriesTypesOptions: () => handlers.getEstateCategoriesTypesOptions(),
    getDocCreationTypesOptions: () => getDocCreationTypesOptions(accountId),
    getAddOptions: () => getAddOptions(accountId),
    getPhoneTypesOptions: () => getPhoneTypesOptions(accountId),
    getEmailTypesOptions: () => getEmailTypesOptions(accountId),
    getAddressTypesOptions: () => getAddressTypesOptions(accountId),
    getSystemRoles: () => getSystemRolesOptions(accountId),
    getOffices: () => getOfficesOptions(accountId),
    getOfficeSquads: () => handlers.getOfficeSquads(),
  }

  const handlers = useCallbacks((callbacks) => {
    callbacks.getEstateCategoriesTypesOptions = () => {
      if (form && form.estateCategory && form.estateCategory.value) {
        return getEstateTypesOptions({ accountId, estateCategoryId: form.estateCategory.value })
      }
      return Promise.resolve([])
    }

    callbacks.getOfficeSquads = () => {
      if (form && form.officeId.value) {
        return getOfficesSquadsOptions({ accountId, officeId: form.officeId.value }).then((options) => {
          options.unshift({ label: 'Выбрать', value: '' })
          return options
        })
      }
      return Promise.resolve([])
    }
  })

  return methods
}

export default useSystemFieldsMethods
