import { put, select, all } from 'redux-saga/effects'
import { db } from '../../../../../config/firebase'
import { getUid } from '../selectors'
import * as actions from '../actions/notificationsActions'

export function* getNotificationsSaga() {
  const uid = yield select(getUid)

  let notificationsQuery = yield db
    .collection('notifications')
    .where('subscribers', 'array-contains', uid)
    .get()

  const notifications = []
  notificationsQuery.forEach(notification => {
    notifications.push({
      notificationId: notification.id,
      ...notification.data(),
    })
  })

  yield put(actions.sendNotifications(notifications))
}
