import sendEmailApiRequest from './sendEmailApiRequest'

const subscribeToEmails = ({ email, listId }) =>
  sendEmailApiRequest({
    method: 'importContacts',
    request: {
      field_names: ['email', 'email_list_ids'],
      data: [[email, listId]],
    },
  })
export default subscribeToEmails
