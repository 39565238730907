import * as ACTION_TYPES from '../actions/actionTypes'
import { cloneDeep, unionBy, orderBy } from 'lodash'

const initialState = {
  totalPages: 0,
  displayedData: null,
  savedData: [],
  forms: {
    contactsManager: {},
    module: {},
  },
  isLoading: false,
}

export const estateReducer = (state = initialState, action) => {
  const stateClone = cloneDeep(state)
  switch (action.type) {
    case ACTION_TYPES.ESTATE_INIT_COMPONENT_WITH_DATA:
      return initComponentWithData(stateClone, action)
    case ACTION_TYPES.ESTATE_SAVE_FORM:
      return saveForm(stateClone, action)
    case ACTION_TYPES.ESTATE_UPDATE_COMPONENT_DATA:
      return updateComponentData(stateClone, action)
    case ACTION_TYPES.ESTATE_PUSH_NEW_DATA:
      return pushNewData(stateClone, action)
    case ACTION_TYPES.ESTATE_ADD_ESTATE:
      return addEstate(stateClone, action)
    case ACTION_TYPES.ESTATE_MODIFY_ESTATE:
      return modifyEstate(stateClone, action)
    case ACTION_TYPES.ESTATE_REMOVE_ESTATE:
      return removeEstate(stateClone, action)
    default:
      return state
  }
}

const initComponentWithData = (stateClone, action) => {
  return {
    ...stateClone,
    ...action.payload,
  }
}

const updateComponentData = (stateClone, action) => {
  return {
    ...stateClone,
    ...action.payload,
  }
}

const pushNewData = (stateClone, action) => {
  return {
    ...stateClone,
    ...action.payload,
    savedData: unionBy(action.payload.displayedData, stateClone.savedData, 'estateId'),
  }
}

const saveForm = (stateClone, action) => {
  const { formName, form } = action.payload
  stateClone.forms[formName] = form
  return stateClone
}

const addEstate = (stateClone, { payload: { doc, removeLast, totalPages } }) => {
  const displayedData = stateClone.displayedData
  if (removeLast) displayedData.splice(-1, 1, doc)
  else displayedData.push(doc)

  return {
    ...stateClone,
    displayedData: orderBy(displayedData, (d) => d.created.at.seconds * 1000, ['desc']),
    totalPages,
  }
}

const modifyEstate = (stateClone, { payload: { doc } }) => {
  const displayedData = stateClone.displayedData.map((d) => {
    if (d.estateId === doc.estateId) return doc
    return d
  })

  return {
    ...stateClone,
    displayedData,
  }
}

const removeEstate = (stateClone, { payload: { doc, docToShow, totalPages } }) => {
  const displayedData = stateClone.displayedData.filter((e) => e.estateId !== doc.estateId)
  const savedData = stateClone.savedData.filter((e) => e.estateId !== doc.estateId)
  if (docToShow) displayedData.push(docToShow)

  return {
    ...stateClone,
    displayedData,
    totalPages,
    savedData,
  }
}
