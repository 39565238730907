import React, { useState, useEffect } from 'react'
import './NewTaskConstructor.sass'
import TextareaAutosize from 'react-autosize-textarea'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import endOfTomorrow from 'date-fns/endOfTomorrow'
import { createRequiredField, createCheckbox } from '../../../../../../../utilities/forms/createFormElement'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import getTaskObject from '../../../functions/getTaskObject'
import taskInputChanged from '../../../functions/taskInputChanged'
import clearFieldValue from '../../../../../../../utilities/forms/clearFieldValue'

import ExpirationDateChooser from './ExpirationDateChooser'
import ResponsibleChooser from './ResponsibleChooser'
import TagChooser from './TagChooser'

function NewTaskConstructor({
  pushComponentControlls,
  attachableData,
  uid,
  showCreateNewTask = null,
  newTaskConfig = null,
}) {
  const [newTask, setNewTask] = useState([{ fieldId: 'createNewTask', ...createCheckbox('Поставить новую задачу') }])
  const [showErrors, setShowErrors] = useState(false)

  const { input, setInput, taskConfig, updateTaskConfig, dropTaskConfig } = useNewTaskConstructor({
    newTaskConfig,
    attachableData,
    uid,
  })

  useEffect(() => {
    pushComponentControlls({ input, createNewTask: newTask[0].value, taskConfig, updateTaskConfig, dropTaskConfig })
  }, [input, newTask, taskConfig])

  return (
    <div className="NewTaskConstructor">
      {showCreateNewTask ? renderFields(newTask, setNewTask, showErrors) : ''}
      {!showCreateNewTask || newTask[0].value ? (
        <>
          <div className="Tasks-InlineSelectors">
            <ExpirationDateChooser
              startDate={taskConfig.startDate}
              endDate={taskConfig.endDate}
              updateTaskConfig={updateTaskConfig}
            />
            <ResponsibleChooser responsible={taskConfig.responsible} updateTaskConfig={updateTaskConfig} />
            <TagChooser taskTags={taskConfig.tags} updateTaskConfig={updateTaskConfig} />
          </div>
          <TextareaAutosize
            className="TaskConstructor-InputField"
            placeholder={input.config.label}
            value={input.value}
            onChange={(e) => taskInputChanged(input, setInput, e)}
          />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export const useNewTaskConstructor = ({ newTaskConfig, attachableData, uid }) => {
  const defaultTaskConfig = {
    startDate: endOfTomorrow(),
    endDate: endOfTomorrow(),
    responsible: [uid],
    tags: [],
    uid,
    ...(newTaskConfig || {}),
    ...(!isEmpty(attachableData) ? { [attachableData.section]: [attachableData.objectId] } : {}),
  }

  const [input, setInput] = useState({
    ...createRequiredField('textarea', 'text', 'Введите название задачи'),
    value: (newTaskConfig && newTaskConfig.title) || '',
  })
  const [taskConfig, setTaskConfig] = useState(getTaskObject(defaultTaskConfig))

  useEffect(() => {
    updateTaskConfig({ title: input.value })
  }, [input])

  const updateTaskConfig = (taskConfigProperty) => {
    const taskConfigClone = cloneDeep(taskConfig)
    setTaskConfig({
      ...taskConfigClone,
      ...taskConfigProperty,
    })
  }

  const dropTaskConfig = () => {
    setTaskConfig(defaultTaskConfig)
    setInput(clearFieldValue(input))
  }
  return { input, setInput, taskConfig, updateTaskConfig, dropTaskConfig }
}

export default NewTaskConstructor
