import { db } from '../../../../../config/firebase'
import rootDbPath from '../../../../../utilities/db/rootDbPath'

const getTaskSnapshot = ({ accountId, taskId }) =>
  db
    .collection(`${rootDbPath(accountId)}/tasks`)
    .doc(taskId)
    .get()
    .then((d) => d)

export default getTaskSnapshot
