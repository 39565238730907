import React from 'react'
import SiteDropdownLink from './SiteDropdownMenu/SiteDropdownLink'
import SiteDropdownMenu from './SiteDropdownMenu/SiteDropdownMenu'

export const links = [
  {
    path: '',
    icon: 'dollar-sign',
    title: 'CRM для отделов продаж',
    description: `Узнайте подробнее о преимуществах отраслевого решения.`,
  },
  {
    path: '',
    icon: 'building',
    title: 'CRM для агентств недвижимости',
    description: `Узнайте подробнее о преимуществах отраслевого решения.`,
  },
  {
    path: '',
    icon: 'cars',
    title: 'CRM для автосалонов',
    description: `Узнайте подробнее о преимуществах отраслевого решения.`,
  },
  {
    path: '',
    icon: 'pencil-ruler',
    title: 'CRM для монтажных организаций',
    description: `Узнайте подробнее о преимуществах отраслевого решения.`,
  },
  {
    path: '',
    icon: 'mountains',
    title: 'CRM для туристических агентств',
    description: `Узнайте подробнее о преимуществах отраслевого решения.`,
  },
]

function SolutionsMenu() {
  return (
    <SiteDropdownMenu title="Решения">
      {links.map((l) => (
        <SiteDropdownLink path={l.path} icon={l.icon} title={l.title} description={l.description} />
      ))}
    </SiteDropdownMenu>
  )
}

export default SolutionsMenu
