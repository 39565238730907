const rootDbPath = require('./rootDbPath')

const addDoc = ({ db, accountId, path, data }) => {
  let dbPath
  if (accountId) {
    dbPath = `${rootDbPath(accountId)}/${path}`
  } else {
    dbPath = path
  }

  return db
    .collection(dbPath)
    .add(data)
    .then((doc) => doc.id)
}

module.exports = addDoc
