import React from 'react'
import './ProfileNav.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../../../components/UI/Icon/Icon'
import signOutUser from '../Auth/functions/signOutUser'

function ProfileNav() {
  return (
    <div className="Site-ProfileNav">
      <ProfileNavLink icon="browser" title="Аккаунты" path="/profile/accounts" />
      <ProfileNavLink icon="user" title="Мой профиль" path="/profile/edit" />
      <ProfileNavLink icon="sign-out" title="Выход" onClick={signOutUser} />
    </div>
  )
}

function ProfileNavLink({ path, icon, title, onClick }) {
  return onClick ? (
    <div className="Site-ProfileNavLink" onClick={onClick}>
      <Icon name={icon} weight="solid" />
      {title}
    </div>
  ) : (
    <NavLink
      to={path}
      className="Site-ProfileNavLink"
      activeClassName="Site-ProfileNavLink_status_active"
      // isActive={(match, location) => {
      //   const locations = [path, ...activeLocations]
      //   if (locations.includes(location.pathname)) return true
      // }}
      exact
    >
      <Icon name={icon} weight="solid" />
      {title}
    </NavLink>
  )
}

export default ProfileNav
