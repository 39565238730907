import React from 'react'
import './PlanGauge.sass'
import 'zingchart/es6'
import ZingChart from 'zingchart-react'
import NumberFormat from 'react-number-format'
import WidgetTile from '../WidgetTile/WidgetTile'

function PlanGauge({
  title,
  minValue = 0,
  maxValue = 100,
  currentValue = 1,
  rules = [],
  donePercent = 0,
  doneRate = 0,
  isLoading,
  counterPeriod,
}) {
  const chartConfig = {
    type: 'gauge',
    scale: { 'size-factor': 1 },
    plot: { size: '100%' },
    plotarea: {
      margin: 0,
      marginTop: '4px',
    },
    scaleR: {
      offsetY: 100,
      aperture: 180,
      center: { visible: false },
      item: { visible: false },
      maxValue: 150,
      minValue: 0,
      ring: {
        rules,
        size: '12px',
        backgroundColor: '#F2F4F6',
      },
      step: 1,
      tick: { visible: false },
    },
    tooltip: { borderRadius: '5px' },
    series: [
      {
        values: [donePercent],
        backgroundColor: '#39393D',
        indicator: [4, 4, 4, 4, 0.9],
        animation: {
          effect: 'ANIMATION_EXPAND_VERTICAL',
          method: 'ANIMATION_BACK_EASE_OUT',
          sequence: 'null',
          speed: 900,
        },
      },
    ],
  }
  return (
    <WidgetTile title={title} isLoading={isLoading} counterPeriod={counterPeriod}>
      <div className="PlanGauge">
        <ZingChart data={chartConfig} height={198} />
        <div className="PlanGauge-ValueBox">
          <p className="ValueBox-Current">
            <NumberFormat value={currentValue} displayType="text" thousandSeparator={' '} suffix={` руб`} />
          </p>
          <p className="ValueBox-Total">
            <NumberFormat value={maxValue} displayType="text" thousandSeparator={' '} suffix={` руб`} />
          </p>
        </div>
      </div>
      <div className="PlanGauge-Description">
        <p className="Description-Title">
          Выполнено: <span className="Description-Value">{donePercent}%</span>
        </p>
        <p className="Description-Title">
          Темп: <span className="Description-Value">{doneRate}%</span>
        </p>
      </div>
    </WidgetTile>
  )
}

export default PlanGauge
