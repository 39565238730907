import { cloneDeep, findIndex } from 'lodash'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import getDoc from '../../../../utilities/db/getDoc'
import { db } from '../../../../config/firebase'
import rootDbPath from '../../../../utilities/db/rootDbPath'

const onCategorytatusChanged = ({ paramCategoryId, estateCategoryId, status, estateTypeId, uid, accountId }) => {
  getDoc({
    path: `${rootDbPath(accountId)}/config/estate/paramsCategories`,
    docId: paramCategoryId,
    docIdName: 'estateCategoryId',
  }).then((categoryData) => {
    const index = findIndex(categoryData.estateTypes, { estateTypeId, estateCategoryId })
    const newCategoryStatus = { estateCategoryId, estateTypeId, status }
    const estateTypes = cloneDeep(categoryData.estateTypes)
    if (index !== -1) {
      estateTypes.splice(index, 1, newCategoryStatus)
    } else {
      estateTypes.push(newCategoryStatus)
    }
    db.collection(`${rootDbPath(accountId)}/config/estate/paramsCategories`)
      .doc(paramCategoryId)
      .update({ estateTypes, updated: getCurDateWithUser(uid) })
  })
}

export default onCategorytatusChanged
