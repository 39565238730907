const { default: getFieldRenderObject } = require('../../../../../../../utilities/newforms/render/getFieldRenderObject')

class ServiceForm {
  constructor() {
    this.categoryId = {
      field: { fieldId: 'categoryId' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название услуги',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'input',
        inputType: 'text',
        label: 'Стоимость',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.isActive = {
      field: {
        fieldId: 'isActive',
        fieldType: 'checkbox',
        label: 'Активная',
        value: true,
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: {
          at: '',
          by: '',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: {
          at: '',
          by: '',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default ServiceForm
