import differenceInDays from 'date-fns/differenceInDays'
import isNaN from 'lodash/isNaN'
import isFinite from 'lodash/isFinite'

const countRatePercent = (startingDate, endingDate, currentValue, maxValue) => {
  const totalDays = differenceInDays(endingDate, startingDate)
  let passedDays = differenceInDays(Date.now(), startingDate)
  if (passedDays === 0) passedDays = 1
  const ratePercent = Math.round(((currentValue / passedDays) * totalDays * 100) / maxValue)
  return isNaN(ratePercent) ? 0 : isFinite(ratePercent) ? ratePercent : 0
}

export default countRatePercent
