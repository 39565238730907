import React, { useState, useEffect, useRef } from 'react'
import './SiteDropdownMenu.sass'
import Media from 'react-media'
import Backdrop from '../../../../components/UI/Backdrop/Backdrop'
import SiteNavLink from '../SiteNavLink'
import { useCallbacks } from '../../../../utilities/shared'
import useWindowSize from '../../../../hooks/useWindowSize'
import useScrollPosition from '../../../../hooks/useScrollPosition'

function SiteDropdownMenu({ title, children }) {
  const { width: windowWidth } = useWindowSize()
  const [showMenu, setShowMenu] = useState(false)

  const linkRef = useRef()
  const backdropRef = useRef()

  useEffect(() => {
    if (windowWidth <= 1200) {
      document.addEventListener('mousedown', handlers.handleOverOutside)
      document.removeEventListener('mouseover', handlers.handleOverOutside)
    } else {
      document.addEventListener('mouseover', handlers.handleOverOutside)
      document.removeEventListener('mousedown', handlers.handleOverOutside)
    }

    return () => {
      document.removeEventListener('mouseover', handlers.handleOverOutside)
      document.removeEventListener('mousedown', handlers.handleOverOutside)
    }
  }, [windowWidth])

  const pos = useScrollPosition()

  useEffect(() => {
    setShowMenu(false)
  }, [pos])

  const handlers = useCallbacks((callbacks) => {
    callbacks.handleOverOutside = (event) => {
      if (linkRef.current && linkRef.current.contains(event.target)) {
        return togglePopUp()
      }
      if (backdropRef.current && !backdropRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }
  })

  const togglePopUp = () => setShowMenu(!showMenu)

  return (
    <>
      <SiteNavLink ref={linkRef} title={title} type="dropdown" isActive={showMenu} />
      <Media
        query="(max-width: 1200px)"
        render={() =>
          showMenu ? (
            <div ref={backdropRef} className="Site-DropdownMenu-Container">
              {children}
            </div>
          ) : (
            ''
          )
        }
      />
      <Media
        query="(min-width: 1201px)"
        render={() => (
          <Backdrop show={showMenu} className="Site-DropdownMenu" style={{ top: `${+pos + 64}px` }}>
            <div ref={backdropRef} className="Site-DropdownMenu-Container">
              {children}
            </div>
          </Backdrop>
        )}
      />
    </>
  )
}

export default SiteDropdownMenu
