import { getContactByPhone, getContactObject } from '../../../Contacts/scenes/Contactlist/ContactsManager/functions'
import dbCallContacts from '../../../Contacts/scenes/Contactlist/ContactsManager/functions/dbCallContacts'

export function* handleContactCreationSaga(phone, responsible) {
  const isContactExist = yield getContactByPhone(phone)
  if (!isContactExist) {
    return yield dbCallContacts(
      'addContact',
      getContactObject({
        name: 'Неизвестный',
        phone: [{ type: 'primary', value: phone }],
        uid: responsible,
        responsible,
      }),
    )
  }
  return null
}
