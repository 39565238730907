import React, { useRef } from 'react'
import './PhotoTile.sass'
import { usePopUp } from '../../hooks/usePopUp'
import Img from '../../ui/Img/Img'
import Icon from '../UI/Icon/Icon'

function PhotoTile({ isAvatar, isLayout, imageUrl, publicUrl, onDelete, setAvatar }) {
  const butRef = useRef()
  const optDd = useRef()
  const [showPopUp] = usePopUp(butRef, optDd)

  return (
    <div className="PhotoTile">
      <div className="PhotoTile-Markers">
        {isAvatar && (
          <div className="PhotoTile-Marker Avatar">
            <Icon name="star" />
          </div>
        )}
      </div>

      <Img src={imageUrl || publicUrl} alt="" className="PhotoTile-Skeleton" />
      <div ref={butRef} className="PhotoTile-Marker EditButton">
        <Icon name="ellipsis-v" />
      </div>
      <a href={imageUrl || publicUrl} data-fancybox="images">
        <div className="PhotoTile-Marker ZoomButton">
          <Icon name="search-plus" />
        </div>
      </a>
      <div ref={optDd} className="PhotoTile-Options" style={{ display: showPopUp ? 'flex' : 'none' }}>
        <div className="PhotoTile-OptionsContainer">
          <div className="Option" onClick={setAvatar}>
            <Icon name="star" helperClass={`Avatar ${isAvatar && 'Avatar_theme_active'}`} />
          </div>
          <div className="Option" onClick={onDelete}>
            <Icon name="trash-alt" helperClass="Delete" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotoTile
