import React, { useState, useRef } from 'react'
import './NumberPad.sass'

import toNumber from 'lodash/toNumber'
import isNaN from 'lodash/isNaN'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { connect } from 'react-redux'
import IconicButton from '../../../components/UI/IconicButton/IconicButton'
import DialerButton from '../DialerButton/DialerButton'
import { createFieldWithFunctionalIcon } from '../../../utilities/forms/createFormElement'
import { renderFields } from '../../../utilities/forms/renderFields'
import { numberPadButton } from '../DialerButton/ButtonTypes'
import { usePopUp } from '../../../hooks/usePopUp'
import { useCallbacks } from '../../../utilities/shared'
import * as actions from '../store/actions/dialerActions'

function NumberPad({ sendCallRequest, uid }) {
  const butRef = useRef()
  const containerRef = useRef()
  const [showPad, setShowPad] = usePopUp(butRef, containerRef, true)
  const [number, setNumber] = useState([
    {
      fieldId: 'number',
      ...createFieldWithFunctionalIcon(
        'input',
        'text',
        'Вызываемый номер',
        'backspace',
        null,
        () => handlers.removeLastField(),
        'phone',
      ),
    },
  ])

  const handlers = useCallbacks((callbacks) => {
    callbacks.removeLastField = () => {
      const stateClone = cloneDeep(number)
      const field = find(stateClone, ['fieldId', 'number'])
      field.value = field.value.substr(0, field.value.length - 1)
      setNumber(stateClone)
    }
  })

  return (
    <div className="Dialer-NumberPad-Container">
      <button
        ref={butRef}
        className={[
          'DialerButton',
          'DialerButton_theme_number_pad',
          ...(showPad ? ['DialerButton_theme_defaultActive'] : []),
        ].join(' ')}
      >
        {numberPadButton.content.content}
      </button>
      {showPad && (
        <div ref={containerRef} className="Dialer-NumberPad">
          <div className="NumberPad-Close">
            <IconicButton
              icon="times"
              iconWeight="solid"
              customClass="Dialer-CloseButton"
              clicked={() => setShowPad(false)}
            />
          </div>
          {renderFields(number, setNumber)}
          <PadButtons state={number} setState={setNumber} sendCallRequest={sendCallRequest} uid={uid} />
        </div>
      )}
    </div>
  )
}

function PadButtons({ state, setState, sendCallRequest, uid }) {
  const buttons = [
    { type: 'custom', content: '1' },
    { type: 'custom', content: '2' },
    { type: 'custom', content: '3' },
    { type: 'custom', content: '4' },
    { type: 'custom', content: '5' },
    { type: 'custom', content: '6' },
    { type: 'custom', content: '7' },
    { type: 'custom', content: '8' },
    { type: 'custom', content: '9' },
    { type: 'custom', content: '*/+' },
    { type: 'custom', content: '0' },
    { type: 'custom', content: '#' },
    { type: 'icon', name: 'phone', weight: 'solid' },
  ]

  const onButtonPressed = (content) => {
    const number = toNumber(content)
    const stateClone = cloneDeep(state)
    const field = find(stateClone, ['fieldId', 'number'])

    if (isNaN(number)) {
    } else {
      field.value = field.value.concat(number)
      setState(stateClone)
    }
  }

  const makeCall = () => {
    const phone = find(state, ['fieldId', 'number']).value
    sendCallRequest({ direction: 'outbound', phone, contactId: null, createContact: false, responsible: uid })
  }

  return (
    <div className="NumberPads-PadButtons">
      {buttons.map((button) =>
        button.type === 'icon' ? (
          <DialerButton content={button} theme="call" clicked={() => makeCall()} />
        ) : (
          <DialerButton content={button} theme="stroked" clicked={() => onButtonPressed(button.content)} />
        ),
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid })

const mapDispatchToProps = (dispatch) => ({
  sendCallRequest: (payload) => dispatch(actions.dialerSendCallRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NumberPad)
