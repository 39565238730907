import React, { useRef } from 'react'
import { usePopUp } from '../../../../../../../hooks/usePopUp'
import formatExpirationDate from '../../../functions/formatExpirationDate'
import TaskDatePicker from '../TaskDatePicker/TaskDatePicker'

function ExpirationDateChooser({ startDate, endDate, updateTaskConfig }) {
  const butRef = useRef()
  const ddBRef = useRef()

  const [showSelectBox, setShowSelectBox] = usePopUp(butRef, ddBRef, true)

  const setExpireDate = (date) => {
    const newTaskExpiration = {
      startDate: date,
      endDate: date,
    }
    updateTaskConfig(newTaskExpiration)
    setShowSelectBox(false)
  }

  return (
    <>
      <div className="SelectableLabel">
        <span className="NoStyles">На </span>
        <span ref={butRef}>
          {formatExpirationDate({ startDate: { seconds: startDate / 1000 }, endDate: { seconds: endDate / 1000 } })}
        </span>
      </div>
      {showSelectBox && (
        <div ref={ddBRef} className="SelectableLabel-PopUpContainer" style={{ position: 'relative' }}>
          <TaskDatePicker startDate={{ seconds: startDate / 1000 }} onSaveButtonClicked={setExpireDate} />
        </div>
      )}
    </>
  )
}

export default ExpirationDateChooser
