import React, { useState, useEffect, useImperativeHandle } from 'react'
import './FileManager.sass'
import { cloneDeep, isEmpty, findIndex } from 'lodash'
import SceneContent from '../UI/SceneContent/SceneContent'
import NewFilePopUp from './NewFilePopUp/NewFilePopUp'
import FileItem from './FileItem'
import useModuleInit from '../../utilities/newforms/changed/useModuleInit'

import DeleteFilePopUp from './DeleteFilePopUp'
import { deleteStorageFile } from '../../utilities/storage/deleteStorageFile'

const FileManager = React.forwardRef(({ formData, syncState = () => null, onDelete }, ref) => {
  const [files, setFiles] = useModuleInit({ formData, initialState: null })
  const [showNewFilePopUp, setShowNewFilePopUp] = useState(null)
  const [showDeleteFilePopUp, setShowDeleteFilePopUp] = useState(null)

  const pushFileToFileManager = (fileObject) => {
    const filesClone = cloneDeep(files)
    filesClone.push(fileObject)
    setFiles(filesClone)
  }

  const updateFileInFileManager = (fileObject) => {
    const filesClone = cloneDeep(files)
    const fileIndex = findIndex(filesClone, ['fileName', fileObject.fileName])
    filesClone.splice(fileIndex, 1, fileObject)
    setFiles(filesClone)
  }

  useEffect(() => {
    syncState(files)
  }, [files])

  useImperativeHandle(ref, () => ({
    getFiles() {
      return { files, setFiles }
    },
  }))

  const onDeleteSubmited = (file) =>
    onDelete(file.fileName)
      .then(() => {
        if (file.storagePath) {
          return deleteStorageFile(`${file.storagePath}/${file.fileName}.${file.fileExt}`)
        }
        return Promise.resolve('ok')
      })
      .then(() => {
        const filesClone = cloneDeep(files)
        const newFiles = filesClone.filter((f) => f.fileName !== file.fileName)
        setFiles(newFiles)
      })

  return (
    <SceneContent
      title="Файлы"
      buttons={[
        {
          type: 'add',
          icon: 'plus',
          clicked: () => setShowNewFilePopUp({}),
        },
      ]}
    >
      {showNewFilePopUp && (
        <NewFilePopUp
          formData={showNewFilePopUp}
          pushFileToFileManager={pushFileToFileManager}
          updateFileInFileManager={updateFileInFileManager}
          close={() => setShowNewFilePopUp(null)}
        />
      )}
      {showDeleteFilePopUp && (
        <DeleteFilePopUp
          file={showDeleteFilePopUp}
          deleteFunc={onDeleteSubmited}
          close={() => setShowDeleteFilePopUp(null)}
        />
      )}
      <div className="FileManager">
        {files ? (
          isEmpty(files) ? (
            <div className="EmptyMessage">Нет файлов</div>
          ) : (
            files.map((f) => (
              <FileItem {...f} onEdit={() => setShowNewFilePopUp(f)} onDelete={() => setShowDeleteFilePopUp(f)} />
            ))
          )
        ) : (
          <EmptyManagerSkeleton quantity={2} />
        )}
      </div>
    </SceneContent>
  )
})

const EmptyManagerSkeleton = ({ quantity }) => {
  const items = []

  for (let index = 0; index < quantity; index++) {
    items.push(<FileItem />)
  }
  return items
}

export default FileManager
