import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import { handleActiveCallSaga } from './handleActiveCallSaga'
import { dialerSendCallRequestSaga } from './dialerSendCallRequestSaga'
import subscribeToCallEventsSaga from './subscribeToCallEventsSaga'
import getSubscriptionInfo from './getSubscriptionInfo'

export function* watchDialer() {
  yield takeLatest(actionTypes.DIALER_SEND_CALL_REQUEST, dialerSendCallRequestSaga)
  yield takeEvery(actionTypes.DIALER_INITIATE_ACTIVE_CALL, handleActiveCallSaga)
  yield takeLatest(actionTypes.DIALER_SUBSCRIPTION_GET_SUBSCRIPTION, getSubscriptionInfo)
  yield takeLatest(actionTypes.DIALER_SUBSCRIPTION_SUBSCRIBE_TO_CALL_EVENTS, subscribeToCallEventsSaga)
}
