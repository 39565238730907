export const DIALER_SUBSCRIPTION_SET_LISTENER = 'DIALER_SUBSCRIPTION_SET_LISTENER'
export const DIALER_SUBSCRIPTION_SUBSCRIBE_TO_CALL_EVENTS = 'DIALER_SUBSCRIPTION_SUBSCRIBE_TO_CALL_EVENTS'
export const DIALER_SUBSCRIPTION_UPDATE_SUBSCRIPTION_INFO = 'DIALER_SUBSCRIPTION_UPDATE_SUBSCRIPTION_INFO'
export const DIALER_SUBSCRIPTION_GET_SUBSCRIPTION = 'DIALER_SUBSCRIPTION_GET_SUBSCRIPTION'

export const DIALER_INITIATE = 'DIALER_INITIATE'
export const DIALER_INITIATE_ACTIVE_CALL = 'DIALER_INITIATE_ACTIVE_CALL'
export const DIALER_INITIATE_INBOUND_CALLS = 'DIALER_INITIATE_INBOUND_CALLS'

export const DIALER_SEND_ACTIVE_CALL_TO_REDUCER = 'DIALER_SEND_ACTIVE_CALL_TO_REDUCER'
export const DIALER_SEND_DATA_TO_REDUCER = 'DIALER_SEND_DATA_TO_REDUCER'

export const DIALER_SET_LISTENER = 'DIALER_SET_LISTENER'

export const DIALER_SHOW_CALLBAR = 'DIALER_SHOW_CALLBAR'
export const DIALER_HIDE_CALLBAR = 'DIALER_HIDE_CALLBAR'
export const DIALER_TOGGLE_CALLBAR = 'DIALER_TOGGLE_CALLBAR'

export const DIALER_SEND_CALL_REQUEST = 'DIALER_SEND_CALL_REQUEST'

export const DIALER_MUTE_INBOUND_CALL_SOUND = 'DIALER_MUTE_INBOUND_CALL_SOUND'
export const DIALER_UNMUTE_INBOUND_CALL_SOUND = 'DIALER_UNMUTE_INBOUND_CALL_SOUND'
export const DIALER_TOGGLE_MUTE_INBOUND_CALL_SOUND = 'DIALER_TOGGLE_MUTE_INBOUND_CALL_SOUND'
