import React from 'react'

function CustomSceneTable({ header, body }) {
  return (
    <table className="SceneContent-Table">
      <thead>{header}</thead>
      <tbody>{body}</tbody>
    </table>
  )
}

export default CustomSceneTable
