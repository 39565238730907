import getCollection from '../../../../../utilities/db/getCollection'
import { db } from '../../../../../config/firebase'
import rootDbPath from '../../../../../utilities/db/rootDbPath'

const fetchTaskAttachments = ({ accountId, taskId }) =>
  getCollection({
    accountId,
    path: `tasks/${taskId}/attachments`,
    orderBy: { fieldPath: 'created.at', orderDir: 'desc' },
    docIdName: 'attachmentId',
  })
// db
//   .collection(`${rootDbPath(accountId)}/tasks/${taskId}/attachments`)
//   .orderBy('created.at', 'desc')
//   .get()
//   .then((docs) => {
//     const arr = []
//     docs.forEach((d) => {
//       arr.push({ attachmentId: d.id, ...d.data() })
//     })
//     return arr
//   })

export default fetchTaskAttachments
