import React from 'react'
import Submenu from '../../components/Submenu/Submenu'

function ModuleWithSubmenu({ subsections, className = '', children }) {
  return (
    <>
      <Submenu links={subsections} />
      <div className={`Module-Container ${className}`}>{children}</div>
    </>
  )
}

export default ModuleWithSubmenu
