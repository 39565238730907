import { put } from 'redux-saga/effects'
import apiCalls from '../../functions/apiCalls'
import dbCalls from '../../functions/dbCalls'
import { db } from '../../../../config/firebase'
import * as actions from '../actions/dialerActions'
import { cleanPhoneNumber } from '../../../../utilities/cleanPhoneNumber'

export default function* getSubscriptionInfo({ payload: { responsible } }) {
  const responsibleData = yield db
    .collection('users')
    .doc(responsible)
    .get()
    .then((doc) => doc.data())

  if (responsibleData && responsibleData.phone) {
    const phone = cleanPhoneNumber(responsibleData.phone)

    const subscriptionInfo = yield db
      .collection('extensions/08gWJH83oEYZsvfCZSaz/subscriptions')
      .doc(phone)
      .get()
      .then((doc) => doc.data())

    const { subscriptionId, status, expires } = subscriptionInfo

    const payload = {
      func: 'getSubscription',
      extensionId: '08gWJH83oEYZsvfCZSaz',
      subscriptionId,
    }

    const result = yield apiCalls(payload)

    if (result !== 'error') {
      yield dbCalls({
        func: 'updateSubscriptionFile',
        extensionId: '08gWJH83oEYZsvfCZSaz',
        phone,
        status: 'active',
        expires: result.expires,
      })
    } else if (status === 'uncontrolledError' || status === 'controlledError') {
      yield put(actions.dialerSendSubscriptionInfo(subscriptionInfo))
    } else {
      yield dbCalls({
        func: 'updateSubscriptionFile',
        extensionId: '08gWJH83oEYZsvfCZSaz',
        phone,
        status: 'uncontrolledError',
        expires: subscriptionInfo.expires,
      })
    }
    yield put(actions.dialerSetSubscriptionListener(phone))
  }
}
