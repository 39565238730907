import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PlanGauge from '../../widgets/PlanGauge/PlanGauge'
import getSalesTotalPlanFor from '../../../Settings/Company/components/SalesPlans/functions/getSalesTotalPlanFor'
import getSalesDonePlanFor from '../../../Settings/Company/components/SalesPlans/functions/getSalesDonePlanFor'
import countDonePercent from '../../functions/countDonePercent'
import countRatePercent from '../../functions/countRatePercent'
import getWidgetConfig from '../../functions/getWidgetConfig'
import usePeriodFromUrl from '../../../../hooks/usePeriodFromUrl'
import useResponsibleFromUrl from '../../../../hooks/useResponsibleFromUrl'
import getEmployeesFrom from '../../../Settings/Company/components/Members/functions/getEmployeesFrom'
import promiseAllValues from '../../../../utilities/promiseAllValues'

function AgencyPlan({ accountId, ...router }) {
  const [agencyPlan, setAgencyPlan] = useState({})
  const [dateRange] = usePeriodFromUrl({ listenUrl: true, history: router.history })
  const [resp] = useResponsibleFromUrl({ listenUrl: true, history: router.history })

  useEffect(() => {
    if (!isEmpty(dateRange) && !isEmpty(resp)) {
      const { offices, squads, responsible } = resp

      const widgetSettings = {
        minValue: 0,
        maxValue: getSalesTotalPlanFor({ accountId, years: dateRange.years, offices, squads, responsible }),
        currentValue: 0,
        donePercent: 0,
        doneRate: 0,
        rules: [],
        responsible:
          !isEmpty(offices) || !isEmpty(squads) ? getEmployeesFrom({ accountId, offices, squads }) : responsible,
      }

      promiseAllValues(widgetSettings)
        .then((ws) => {
          widgetSettings.maxValue = ws.maxValue

          return getSalesDonePlanFor({
            accountId,
            startingDate: dateRange.startingDate,
            endingDate: dateRange.endingDate,
            responsible: ws.responsible,
          })
        })
        .then((uDonePlan) => {
          widgetSettings.currentValue = uDonePlan
          widgetSettings.donePercent = countDonePercent(uDonePlan, widgetSettings.maxValue)
          widgetSettings.doneRate = countRatePercent(
            dateRange.startingDate,
            dateRange.endingDate,
            uDonePlan,
            widgetSettings.maxValue,
          )
          return getWidgetConfig({ accountId, widgetId: 'planGauge' })
        })
        .then((wConfig) => {
          // widgetSettings.rules = prepareWidgetRules({ wConfig, data: widgetSettings })
          setAgencyPlan(widgetSettings)
        })
    }
  }, [dateRange, resp])

  return <PlanGauge title="Общий план" isLoading={isEmpty(agencyPlan)} {...agencyPlan} counterPeriod={dateRange} />
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(AgencyPlan)
