import React from 'react'
import './index.sass'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import Auth from './pages/Auth/Auth'
import SupportEmail from '../modules/Emails/templates/SupportEmail'
import Pricing from './pages/Pricing/Pricing'
import ContactUs from './pages/ContactUs/ContactUs'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import SitePage from './pages/SitePage/SitePage'
import Profile from './pages/Profile/Profile'
import VerifiedUserRoute from '../routs/VerifiedUserRoute'
import usePrevPath from '../hooks/usePrevPath'
import CookieNotification from './components/CookieNotification/CookieNotification'
import UserAgreement from './pages/UserAgreement/UserAgreement'

function Website() {
  usePrevPath()
  return (
    <div className="Site">
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/email" component={SupportEmail} />
        <SitePage>
          <Route path="/" exact component={Home} />
          <Route path="/pricing" exact component={Pricing} />
          <VerifiedUserRoute path="/profile" component={Profile} redirectTo="signIn" />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/user-agreement" exact component={UserAgreement} />
        </SitePage>
      </Switch>
      <CookieNotification />
    </div>
  )
}

export default Website
