import arrayMove from 'array-move'
import { getCurDateWithUser } from '../../../../utilities/date/getCurDateWithUser'
import { updateDocs } from '../../../../utilities/db/updateDocs'
import rootDbPath from '../../../../utilities/db/rootDbPath'

const onParamPositionChanged = (params, from, to, uid, accountId) => {
  const newSortedArray = arrayMove(params, from, to).map((p, i) => {
    p.position = i
    return p
  })

  const docsData = []
  newSortedArray.forEach((p) =>
    docsData.push({ docId: p.paramId, data: { position: p.position, updated: getCurDateWithUser(uid) } }),
  )
  return updateDocs({ path: `${rootDbPath(accountId)}/config/estate/params`, docsData })
}

export default onParamPositionChanged
