import React, { useState, useEffect, useRef } from 'react'
import './TaskDescription.sass'

import cloneDeep from 'lodash/cloneDeep'
import TextareaAutosize from 'react-autosize-textarea'
import { useCallbacks } from '../../../../../../../utilities/shared'

import { createField } from '../../../../../../../utilities/forms/createFormElement'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import IconicButton from '../../../../../../../components/UI/IconicButton/IconicButton'
import taskInputChanged from '../../../functions/taskInputChanged'
import clearFieldValue from '../../../../../../../utilities/forms/clearFieldValue'
import updateTask from '../../../functions/updateTask'

function TaskDescription({ taskId, description, uid, canBeEdit }) {
  const [taskDescription, setTaskDescription] = useState({
    ...createField('textarea', 'text', 'Добавить подробное описание задачи'),
    value: description,
  })
  const [descriptionEditMode, setDescriptionEditMode] = useState(false)

  const taskDescriptionBlockRef = useRef()
  const taskDescriptionFieldRef = useRef()
  const taskDescriptionSaveButtonRef = useRef()
  const taskDescriptionDismissButtonRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handlers.handleClickOutside)
    return () => document.removeEventListener('mousedown', handlers.handleClickOutside)
  }, [])

  const handlers = useCallbacks((callbacks) => {
    callbacks.handleClickOutside = (event) => {
      if (
        descriptionEditMode &&
        taskDescriptionSaveButtonRef.current &&
        taskDescriptionSaveButtonRef.current.contains(event.target)
      ) {
        saveTaskDescriptionField()
      } else if (
        descriptionEditMode &&
        taskDescriptionDismissButtonRef.current &&
        taskDescriptionDismissButtonRef.current.contains(event.target)
      ) {
        dissmissTaskDescriptionFieldChanges()
      } else if (descriptionEditMode && !taskDescriptionFieldRef.current.contains(event.target)) {
        saveTaskDescriptionField()
      }
    }
  })

  const pushTaskDescription = (desc) => {
    if (desc.trim()) {
      updateTaskDescription(desc)
      const clone = cloneDeep(taskDescription)
      clone.value = desc
      setTaskDescription(clone)
    } else {
      setTaskDescription(clearFieldValue(taskDescription))
    }
  }

  const updateTaskDescription = (desc) => {
    if (desc === description) return
    updateTask(taskId, { description: desc }, uid)
  }

  const turnOnDescriptionEditMode = () => {
    if (canBeEdit) setDescriptionEditMode(true)
  }

  const saveTaskDescriptionField = () => {
    updateTaskDescription(taskDescription.value)
    setDescriptionEditMode(false)
  }

  const dissmissTaskDescriptionFieldChanges = () => {
    pushTaskDescription(description)
    setDescriptionEditMode(false)
  }

  return (
    <>
      <div className="Block-Title">
        <Icon name="align-left" size="16" />
        <span>Описание задачи</span>
      </div>
      <div ref={taskDescriptionBlockRef} className="Task-Description">
        {(taskDescription.value && !descriptionEditMode) || !canBeEdit ? (
          <p onClick={turnOnDescriptionEditMode}>{taskDescription.value}</p>
        ) : (
          <TextareaAutosize
            ref={taskDescriptionFieldRef}
            autoFocus={descriptionEditMode && true}
            className="Task-Description-Field"
            value={taskDescription.value}
            placeholder={taskDescription.config.label}
            onChange={(event) => taskInputChanged(taskDescription, setTaskDescription, event)}
            onFocus={turnOnDescriptionEditMode}
          />
        )}
        {descriptionEditMode && (
          <div className="TaskButtons-Container TaskDescription-Buttons-Container">
            <button ref={taskDescriptionSaveButtonRef} className="Save" onClick={saveTaskDescriptionField}>
              Сохранить
            </button>
            <div ref={taskDescriptionDismissButtonRef} className="Cancel" onClick={dissmissTaskDescriptionFieldChanges}>
              <IconicButton type="basic" icon="times" iconSize="12" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TaskDescription
