export const parseCallStatus = (status, personality) => {
  const callStatus = status.toLowerCase()
  const person = personality.toLowerCase()

  switch (callStatus) {
    case 'outboundinit':
      return parseOutboundInitStatus(person)
    case 'alerting':
      return parseAlertingStatus(person)
    case 'active':
      return parseActiveStatus()
    case 'released':
      return parseReleasedStatus(person)
    case 'callerror':
      return parseErrorStatus()
    default:
      return {
        title: '-',
      }
  }
}

const parseOutboundInitStatus = (person) => {
  switch (person) {
    case 'click-to-dial':
      return {
        title: 'Набор номера',
      }
    default:
      break
  }
}

const parseAlertingStatus = (person) => {
  switch (person) {
    case 'click-to-dial':
      return {
        title: 'Примите вызова на вашем устройстве',
        theme: 'waiting',
      }
    case 'originator':
      return {
        title: 'Соеденение с абонентом',
        theme: 'waiting',
      }

    default:
      break
  }
}

const parseActiveStatus = () => {
  return {
    title: 'Активный',
    theme: 'active',
  }
}

const parseReleasedStatus = (person) => {
  switch (person) {
    case 'click-to-dial':
      return {
        title: 'Вызов завершен. Вы не приняли вызов',
        theme: 'notify',
      }
    case 'originator':
    case 'terminator':
      return {
        title: 'Вызов завершен',
      }

    default:
      break
  }
}

const parseErrorStatus = () => {
  return {
    title: 'Вызов завершен. Ошибка номера',
    theme: 'notify',
  }
}
