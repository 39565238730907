import React, { useState, useEffect } from 'react'
import DatePicker from '../../../../../../../components/DatePicker/DatePicker'
import NextTimePicker from './NextTimePicker'
import {
  createRequiredFieldWithIcon,
  createFieldWithIcon,
} from '../../../../../../../utilities/forms/createFormElement'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import startOfTomorrow from 'date-fns/startOfTomorrow'
import { formatDateAsDayMonthYear, formatDateAsHHMM } from '../../../../../../../utilities/date/dateFormat'
import { convertDateFromString } from '../../../../../../../utilities/date/convertDateFromString'
import { getTimeFromString } from '../../../../../../../utilities/date/getTimeFromString'
import { isFormValid } from '../../../../../../../utilities/forms/validation'
import { startOfToday } from 'date-fns'

function TaskDatePicker({ startDate, onSaveButtonClicked }) {
  const [date, setDate] = useState([
    merge(createRequiredFieldWithIcon('input', 'text', 'Дата', 'calendar-alt', 'date', 'date'), {
      fieldId: 'date',
      value: formatDateAsDayMonthYear(startDate),
      valid: true,
      touched: true,
      error: '',
    }),
    merge(createFieldWithIcon('input', 'text', 'Время', 'clock', 'time'), {
      fieldId: 'time',
      value: formatDateAsHHMM(startDate),
    }),
  ])
  const [showErrors, setShowErrors] = useState(false)
  const [timestamp, setTimestamp] = useState(startOfTomorrow())

  useEffect(() => {
    const convertedDate = convertDateFromString(date[0].value)
    const time = getTimeFromString(date[1].value)
    if (time.length === 2) {
      convertedDate.setHours(time[0])
      convertedDate.setMinutes(time[1])
    }
    if (date[0].valid) setTimestamp(convertedDate)
  }, [date])

  const updateDate = (newDate) => {
    const formatedDate = formatDateAsDayMonthYear({ seconds: newDate / 1000 })
    const formatedTime = formatDateAsHHMM({ seconds: newDate / 1000 })
    const dateClone = cloneDeep(date)
    dateClone[0].value = formatedDate
    dateClone[1].value = formatedTime

    setDate(dateClone)
  }

  const saveNewDate = () => {
    if (isFormValid(date)) {
      setShowErrors(false)
      onSaveButtonClicked(timestamp)
    } else {
      setShowErrors(true)
    }
  }

  return (
    <div className="TaskDatePicker">
      <NextTimePicker startDate={startDate} pushDate={updateDate} />
      <div className="TaskDatePicker-Container">
        <div className="TaskDatePicker-Inputs">
          {renderFields(date, setDate, showErrors)}
          <button className="Save" onClick={saveNewDate}>
            Сохранить
          </button>
        </div>
        <DatePicker
          selected={timestamp}
          onChange={updateDate}
          showTimeSelect
          timeFormat="p"
          timeIntervals={15}
          shouldCloseOnSelect={false}
          inline
          minDate={startOfToday()}
        />
      </div>
    </div>
  )
}

export default TaskDatePicker
