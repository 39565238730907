import React from 'react'
import Icon from '../../../components/UI/Icon/Icon'

function AddContactButton({ title, icon, onClick }) {
  return (
    <div className="AddDealContact" onClick={onClick}>
      <div className="AddDealContact-Icon">
        <Icon name={icon} />
      </div>
      <span className="AddDealContact-Title">{title}</span>
    </div>
  )
}

export default AddContactButton
