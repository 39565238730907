import React, { useState, useEffect } from 'react'
import Office from './components/Office/Office'
import './Offices.sass'
import { connect } from 'react-redux'
import Spinner from '../../../../../components/UI/Spinner/Spinner'
import { db } from '../../../../../config/firebase'
import rootDbPath from '../../../../../utilities/db/rootDbPath'
import { orderBy, isNull, isEmpty } from 'lodash'
import rootPath from '../../../../../appTree/rootPath'
import ManageOfficePopUp from './components/ManageOfficePopUp/ManageOfficePopUp'
import { Route, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import fetchOffice from './components/Office/functions/fetchOffice'

function Offices({ accountId, ...router }) {
  const [offices, setOffices] = useState(null)

  useEffect(() => {
    const listener = db.collection(`${rootDbPath(accountId)}/offices`).onSnapshot((snap) => {
      let collection = []
      snap.forEach((d) => collection.push({ officeId: d.id, ...d.data() }))
      collection = orderBy(collection, (e) => e.created.at.seconds * 1000, ['desc'])
      Promise.all(collection.map((officeObject) => fetchOffice({ accountId, officeObject }))).then((fetchedOffices) =>
        setOffices(fetchedOffices),
      )
    })

    return () => {
      listener()
    }
  }, [])

  return (
    <>
      <Route
        path={[`${rootPath(accountId)}/settings/offices/add`, `${rootPath(accountId)}/settings/offices/:id/edit`]}
        exact
        render={() => (
          <ManageOfficePopUp close={() => router.history.push(`${rootPath(accountId)}/settings/offices`)} />
        )}
      />
      {isNull(offices) ? (
        <Spinner type="module" />
      ) : (
        <div className="Offices-Container">
          {!isEmpty(offices) ? (
            offices.map((o) => (
              <Office
                key={o.officeId}
                officeId={o.officeId}
                title={o.title}
                infoBlocks={o.infoBlocks}
                squads={o.squads}
              />
            ))
          ) : (
            <p className="NoInfoRow">Нет офисов</p>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default compose(withRouter, connect(mapStateToProps))(Offices)
