import getCollection from '../db/getCollection'

export const getEstateCategoriesOptions = (accountId) =>
  getCollection({
    accountId,
    path: 'config/estate/categories',
    orderBy: { fieldPath: 'label', orderDir: 'asc' },
    docIdName: 'value',
  })

export default getEstateCategoriesOptions
