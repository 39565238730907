import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './History.sass'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { db } from '../../config/firebase'

import Spinner from '../../components/UI/Spinner/Spinner'
import SceneContentHeader from '../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'

import Icon from '../../components/UI/Icon/Icon'
import SmartInput from './SmartInput/SmartInput'

import HistoryEvent from './HistoryEvent'
import isMonthAndYearEqual from '../../utilities/date/isMonthAndYearEqual'
import { formatDateAsMonthNameAndYear } from '../../utilities/date/dateFormat'
import rootDbPath from '../../utilities/db/rootDbPath'

function History({ accountData, useLocalState, pushState, eventsTypeSet, attachableData, uid }) {
  const [history, setHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [employees, setEmployees] = useState(accountData.members)

  useEffect(() => {
    if (!useLocalState) {
      return db
        .collection(`${rootDbPath(accountData.accountId)}/history`)
        .where('docId', '==', attachableData.objectId)
        .orderBy('created.at')
        .onSnapshot((events) => {
          const createdHistoryEvent = []
          const otherEvents = []
          events.forEach((event) => {
            const historyEvent = {
              eventId: event.id,
              ...event.data(),
            }
            // skipp createdHistory
            if (event.data().subtype !== 'createdHistory') {
              otherEvents.push(historyEvent)
            } else {
              createdHistoryEvent.push(historyEvent)
            }
          })
          otherEvents.unshift(...createdHistoryEvent)
          setHistory(otherEvents)
        })
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(employees)) setIsLoading(false)
  }, [employees])

  useEffect(() => {
    if (useLocalState) pushState(history)
  }, [history])

  const saveEventLocaly = (event) => {
    const historyClone = cloneDeep(history)
    historyClone.push(event)
    setHistory(historyClone)
  }

  let previousAuthor = null
  let previousEventDate = null

  const isSameAuthor = (author1, author2) => author1 === author2

  return isLoading ? (
    <Spinner type="module" styles={{ marginTop: '32px' }} />
  ) : (
    <>
      <SceneContentHeader title="История событий" />
      <div className="History-Content">
        {history.map((event) => {
          let content = null

          const showAuthorNameAndAvatar = !isSameAuthor(previousAuthor, event.created.by)
          previousAuthor = event.created.by

          if (!isMonthAndYearEqual(previousEventDate, event.created.at)) {
            previousEventDate = event.created.at

            content = (
              <div key={event.eventId}>
                <div className="History-DateLine">
                  <div className="Date">{formatDateAsMonthNameAndYear(previousEventDate)}</div>
                </div>
                <HistoryEvent
                  eventId={event.eventId}
                  eventsTypeSet={eventsTypeSet}
                  authors={employees}
                  showAuthorNameAndAvatar
                  {...event}
                  uid={uid}
                  attachableData={attachableData}
                />
              </div>
            )
          } else {
            content = (
              <HistoryEvent
                key={event.eventId}
                eventsTypeSet={eventsTypeSet}
                authors={employees}
                showAuthorNameAndAvatar={showAuthorNameAndAvatar}
                {...event}
                uid={uid}
                attachableData={attachableData}
              />
            )
          }

          return content
        })}
        <SmartInput useLocalState={useLocalState} saveEventLocaly={saveEventLocaly} attachableData={attachableData} />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  uid: state.auth.user.uid,
  accountData: state.auth.account.data,
})

export default connect(mapStateToProps)(History)
