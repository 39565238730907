import React from 'react'
import parse from 'html-react-parser'
import { renderEmail } from 'react-html-email'
import CodeForm from './CodeForm'
import emailIcon from './assets/emailIcon.svg'
import signOutUser from '../functions/signOutUser'
import SignUpEmail from '../../../../modules/Emails/templates/SignUpEmail'
import sendEmailConfirmationCode from './functions/sendEmailConfirmationCode'
import genNewCode from './functions/genNewCode'
import sendPhoneConfirmationCode from './functions/sendPhoneConfirmationCode'

function ConfirmEmail({ user }) {
  return (
    <CodeForm
      icon={emailIcon}
      type="email"
      title="Подтвердите ваш E-mail"
      description={parse(
        `Для полноценного использования сайта необходимо подтвердить ваш E-mail. Для этого мы выслали проверочный код по адресу <b>${user.email}</b>. Пожалуйста, введите полученый код в форму ниже.`,
      )}
      changeData={
        <>
          Если E-mail указан неверно, пожалуйста{' '}
          <span onClick={() => signOutUser({ redirectTo: '/auth/sign-up' })}>зарегистрируйтесь</span> еще раз.
        </>
      }
      user={user}
      sendCodeFunc={() => {
        const code = genNewCode()
        sendEmailConfirmationCode({
          code,
          uid: user.uid,
          email: user.email,
          htmlEmail: renderEmail(<SignUpEmail code={code} email={user.email} password={user.password} />),
        })
      }}
    />
  )
}

export default ConfirmEmail
