import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utilities/newforms'
import FieldRender from '../../../utilities/newforms/render/FieldRender'
import getFieldRenderObject from '../../../utilities/newforms/render/getFieldRenderObject'
import SiteSectionTitle from '../SiteSectionTitle/SiteSectionTitle'
import './ConversionBlock.sass'
import conversionPattern from './assets/conversionPattern.svg'
import cutterBackground from '../../pages/SitePage/assets/cutterBackground.svg'
import cutterWhite from '../../pages/SitePage/assets/cutterWhite.svg'
import PolicyAgreement from '../../pages/PrivacyPolicy/PolicyAgreement'
import getFormValues from '../../../utilities/newforms/getFormValues'

function ConversionBlock({ user, cutter = 'background', ...router }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        email: {
          field: {
            fieldId: 'email',
            icon: 'envelope',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            placeholder: 'Введите ваш E-mail',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    }),
  )

  const onSubmit = (e) => {
    e.preventDefault()
    if (!user) {
      const { email } = getFormValues({ form })
      if (email) return router.history.push(`/auth/sign-up?email=${email}`)
      return router.history.push(`/auth/sign-up`)
    }
    return router.history.push(`/profile/accounts`)
  }

  return (
    <div
      className="Site-ConversionBlock"
      style={{
        backgroundImage: `url(${cutter === 'background' ? cutterBackground : cutterWhite}), url(${conversionPattern})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SiteSectionTitle
              title="Начните прямо сейчас"
              subTitle={
                <>
                  <span style={{ fontWeight: '700' }}>CardBox позволит вам работать по настоящему эффективно</span>
                  <br />
                  <br />
                  Активируйте 7 дневную пробную версию. Платежные данные указывать не потребуется.
                </>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ConversionBlock-Container">
              <div className="Site-Fields">
                <form className="ConversionBlock-Form" onSubmit={onSubmit}>
                  <FieldRender field={{ ...form.email, fieldId: 'email' }} form={form} setForm={setForm} />
                  <Button
                    className="AuthForm-Button"
                    icon="long-arrow-right"
                    title="Попробовать бесплатно"
                    fill="black"
                    theme="solid"
                    size={48}
                    onClick={onSubmit}
                  />
                  <PolicyAgreement />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default compose(withRouter, connect(mapStateToProps))(ConversionBlock)
