import React, { useState, useEffect, useRef } from 'react'
import './ManageSquadPopUp.sass'
import uniqid from 'uniqid'
import { find, findIndex, cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import PopUp from '../../../../../../../components/UI/PopUp/PopUp'
import { createRequiredField, createSelectbox } from '../../../../../../../utilities/forms/createFormElement'
import { renderFields } from '../../../../../../../utilities/forms/renderFields'
import { getMembersOptions } from '../../../../../../../utilities/getOptions/getMembersOptions'
import Icon from '../../../../../../../components/UI/Icon/Icon'
import { TaskResponsible } from '../../../../../../Tasks/scenes/Tasks/Task/components/TaskNav/options/TaskResponsible'
import { usePopUp } from '../../../../../../../hooks/usePopUp'
import getOfficeSquadObject from '../../functions/getOfficeSquadObject'
import { isFormValid } from '../../../../../../../utilities/forms/validation'
import { firebase } from '../../../../../../../config/firebase'
import getDoc from '../../../../../../../utilities/db/getDoc'
import { updateDoc } from '../../../../../../../utilities/db/updateDoc'
import handleSquadMembersSync from './functions/handleSquadMembersSync'
import { createForm } from '../../../../../../../utilities/newforms/createForm'

function ManageSquadPopUp({ accountId, officeId, squadId, editMode, show, close, uid }) {
  const popUpConfig = editMode
    ? {
        title: 'Редактировать отдел',
        employeesIcon: 'pencil-alt',
        employeesTitle: 'Редактировать сотрудников',
      }
    : {
        title: 'Добавить отдел',
        employeesIcon: 'plus',
        employeesTitle: 'Добавить сотрудников',
      }

  const addEmployeesButRef = useRef()
  const addEmployeesPopUpRef = useRef()
  const [squadForm, setSquadForm] = useState([
    {
      fieldId: 'markerColor',
      ...createRequiredField('input', 'text', 'Цвет маркера'),
    },
    {
      fieldId: 'title',
      ...createRequiredField('input', 'text', 'Название отдела'),
    },
    {
      fieldId: 'squadHead',
      ...createSelectbox('Руководитель отдела', true, () => getMembersOptions({ accountId })),
    },
  ])
  const [squadEmployees, setSquadEmployees] = useState([])
  const [showSquadEmployeesSelect] = usePopUp(addEmployeesButRef, addEmployeesPopUpRef, true)
  const [isLoading, setIsLoading] = useState(false)
  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const submitSquad = () => {
    if (isFormValid(squadForm)) {
      setShowFieldErrors(false)
      setIsLoading(true)

      const squad = {}
      squadForm.forEach((f) => {
        squad[f.fieldId] = f.value
      })

      const { title, markerColor, squadHead } = squad

      const squadObject = getOfficeSquadObject({
        squadId: editMode ? squadId : uniqid(),
        title,
        markerColor,
        squadHead,
        members: squadEmployees,
      })

      if (squadId && editMode) {
        getDoc({ accountId, path: 'offices', docId: officeId })
          .then(({ squads }) => {
            const indexOfModifiedSquad = findIndex(squads, ['squadId', squadId])
            const squadBefore = cloneDeep(squads[indexOfModifiedSquad])

            squads.splice(indexOfModifiedSquad, 1, squadObject)
            return updateDoc({ accountId, path: 'offices', docId: officeId, data: { squads }, uid }).then(() =>
              handleSquadMembersSync({ accountId, squadBefore, squadAfter: squadObject, uid }),
            )
          })
          .then(() => close())
      } else {
        updateDoc({
          accountId,
          path: 'offices',
          docId: officeId,
          data: { squads: firebase.firestore.FieldValue.arrayUnion(squadObject) },
          uid,
        })
          .then(() => handleSquadMembersSync({ accountId, squadBefore: null, squadAfter: squadObject, uid }))
          .then(() => close())
      }
    } else {
      setShowFieldErrors(true)
    }
  }

  useEffect(() => {
    if (squadId) {
      getDoc({ accountId, path: 'offices', docId: officeId }).then((office) => {
        const squad = find(office.squads, ['squadId', squadId])
        const squadFormClone = cloneDeep(squadForm)
        squadFormClone.forEach((f) => {
          f.value = squad[f.fieldId]
          f.valid = true
          f.touched = true
        })
        setSquadForm(squadFormClone)
        setSquadEmployees(squad.members)
      })
    } else {
      const squadFormClone = cloneDeep(squadForm)
      squadFormClone.forEach((f) => {
        f.value = ''
      })
      setSquadForm(squadFormClone)
      setSquadEmployees([])
    }
  }, [squadId])

  return (
    <PopUp show={show} close={close} title={popUpConfig.title} submitButtons onSubmit={submitSquad} onCancel={close}>
      <div className="ManagerSquadPopUp-Container">
        {renderFields(squadForm, setSquadForm, showFieldErrors)}
        <div className="AddSquadPopUp-AddEmployees">
          <div ref={addEmployeesButRef} className="ParamsForm-AddButton">
            <div className="Icon-Container">
              <Icon name={popUpConfig.employeesIcon} weight="regular" />
            </div>
            <span>{popUpConfig.employeesTitle}</span>
          </div>
          {showSquadEmployeesSelect && (
            <div ref={addEmployeesPopUpRef}>
              <TaskResponsible
                accountId={accountId}
                data={{ responsible: squadEmployees.map((r) => ({ responsibleId: r })) }}
                returnValueCb={setSquadEmployees}
                chooseAllOption={false}
              />
            </div>
          )}
        </div>
      </div>
    </PopUp>
  )
}

const mapStateToProps = (state) => ({
  accountId: state.auth.account.data.accountId,
  uid: state.auth.user.uid,
})

export default connect(mapStateToProps)(ManageSquadPopUp)
