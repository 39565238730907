import React, { useState, useEffect, useRef } from 'react'
import './TrackBar.sass'
import Draggable from 'react-draggable'

function TrackBar({ position, duration, playFromPos }) {
  const [trackBarLength, setTrackBarLength] = useState(0)
  const [trackBarPinPosition, setTrackBarPinPosition] = useState(0)
  const [isDragMode, setIsDragMode] = useState(false)
  const [xGrid, setXGrid] = useState(0)
  const pinWidth = 9
  const trackBarRef = useRef()

  useEffect(() => {
    setTrackBarLength(trackBarRef.current.offsetWidth - pinWidth)
  }, [])

  useEffect(() => {
    if (duration && trackBarLength) {
      const durationInSec = Math.trunc(duration / 1000)
      setXGrid(trackBarLength / durationInSec)
    }
  }, [duration, trackBarLength])

  useEffect(() => {
    if (!isDragMode) {
      const positionInSec = Math.trunc(position / 1000)
      setTrackBarPinPosition(xGrid * positionInSec)
    }
  }, [position])

  const onDrag = (e, { x }) => {
    setTrackBarPinPosition(x)
    playFromPos((x * duration) / trackBarLength)
  }

  const onDragStop = (e, { x }) => {
    setIsDragMode(false)
  }

  return (
    <div ref={trackBarRef} className="AudioPlayer-TrackBar">
      <div className="TrackBar-ProgressBar" style={{ width: trackBarPinPosition }} />
      <Draggable
        axis="x"
        position={{ x: trackBarPinPosition, y: 0 }}
        bounds="parent"
        grid={[xGrid, 0]}
        onStart={() => setIsDragMode(true)}
        onDrag={onDrag}
        onStop={onDragStop}
      >
        <div className="TrackBar-Pin" />
      </Draggable>
    </div>
  )
}

export default TrackBar
