import checkColumnRulePass from './checkColumnRulePass'

const defineTaskColumn = ({ columns, task }) => {
  const filteredColumn = columns.filter((rC) =>
    rC.filterRules.map((r) => checkColumnRulePass({ rule: r, task })).every((res) => res === true),
  )

  return filteredColumn[0]
}

export default defineTaskColumn
