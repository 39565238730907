import React from 'react'
import './OptionsMenu.sass'

import DropdownBox from '../DropdownBox/DropdownBox'
import Icon from '../Icon/Icon'

const optionsMenu = React.forwardRef(({ options, styles }, ref) => {
  return (
    <DropdownBox ref={ref} styles={styles}>
      <ul className="OptionsList">
        {options.map((option) => (
          <li className="OptionsList-Element" onClick={option.clicked}>
            <div className="OptionsList-ElementIcon">
              <Icon name={option.icon} />
            </div>
            <span>{option.label}</span>
          </li>
        ))}
      </ul>
    </DropdownBox>
  )
})

export default optionsMenu
