import React from 'react'
import './SceneContentHeader.sass'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import IconicButton from '../../IconicButton/IconicButton'
import SceneFilters from '../../../../modules/TableFilters/SceneFilters/SceneFilters'

function SceneContentHeader({ title, filters, buttons }) {
  let buttonsContent = null
  if (buttons) {
    buttonsContent = buttons.map((button) => (
      <div key={button.icon} className="Header-ButtonContainer">
        <IconicButton {...button} />
      </div>
    ))
  } else {
    buttonsContent = null
  }

  return (
    <div className="SceneContent-Header" style={isEmpty(filters) ? { gridTemplateColumns: 'max-content 1fr' } : {}}>
      <span className="Header-Title">{title}</span>
      <SceneFilters filters={filters} />
      <div className="Header-ButtonsContainer">{buttonsContent}</div>
    </div>
  )
}

SceneContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.array,
  buttons: PropTypes.array,
}

SceneContentHeader.defaultProps = {
  filters: [],
  buttons: [],
}

export default SceneContentHeader
