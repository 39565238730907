import React, { useState } from 'react'
import './NewFilePopUp.sass'
import { isEmpty, pick } from 'lodash'
import PopUp from '../../UI/PopUp/PopUp'
import { createForm } from '../../../utilities/newforms'
import { NewFilePopUpForm } from './NewFilePopUpForm'
import FormRender from '../../../utilities/newforms/render/FormRender'
import isFormValid from '../../../utilities/newforms/validation/isFormValid'
import getFormValues from '../../../utilities/newforms/getFormValues'

function NewFilePopUp({ formData, close, pushFileToFileManager, updateFileInFileManager }) {
  const [form, setForm] = useState(
    createForm({
      formData: !isEmpty(formData) ? pick(formData, ['title']) : formData,
      formPattern: new NewFilePopUpForm({ withoutFile: !isEmpty(formData) }),
    }),
  )
  const [showErrors, setShowErrors] = useState(false)

  const submitFile = () => {
    if (!isFormValid({ form })) return setShowErrors(true)
    const values = getFormValues({ form })
    !isEmpty(formData)
      ? updateFileInFileManager({ ...formData, ...values })
      : pushFileToFileManager({ ...values, ...values.file })
    close()
  }

  return (
    <PopUp
      show
      title={`${!isEmpty(formData) ? 'Редактировать' : 'Загрузить'} файл`}
      submitButtons
      onSubmit={submitFile}
      close={close}
    >
      <div className="NewFilePopUp-Container">
        <FormRender
          sections={[{ fields: ['title', ...(!isEmpty(formData) ? [] : ['file'])] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

export default NewFilePopUp
