import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import toNumber from 'lodash/toNumber'
import getDoneDeals from '../../../../../Deals/scenes/Dealslist/functions/analytics/getDoneDeals'
import promiseAllValues from '../../../../../../utilities/promiseAllValues'
import getFailDeals from '../../../../../Deals/scenes/Dealslist/functions/analytics/getFailDeals'

const getSalesDonePlanFor = ({ accountId, startingDate, endingDate, responsible }) =>
  promiseAllValues({ doneDeals: getDoneDeals({ accountId }), failedDeals: getFailDeals({ accountId }) }).then(
    (results) => {
      let donePlan = 0

      results.doneDeals
        .filter((d) => {
          const doneDate = new Date(d.finishedWith.date.seconds * 1000)
          if (!(isAfter(doneDate, startingDate) && isBefore(doneDate, endingDate))) return false
          if (!isEmpty(responsible) && !responsible.includes(d.responsible)) return false
          return true
        })
        .forEach((d) => {
          if (isFinite(toNumber(d.comission))) {
            donePlan += toNumber(d.comission)
          }
        })

      results.failedDeals
        .filter((d) => {
          const doneDate = new Date(d.finishedWith.date.seconds * 1000)
          if (!(isAfter(doneDate, startingDate) && isBefore(doneDate, endingDate))) return false
          if (!isEmpty(responsible) && !responsible.includes(d.responsible)) return false
          return true
        })
        .forEach((d) => {
          if (isFinite(toNumber(d.factComission))) {
            donePlan += toNumber(d.factComission)
          }
        })

      return donePlan
    },
  )

export default getSalesDonePlanFor
