import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { concat, find } from 'lodash'
import getDoc from '../../../../utilities/db/getDoc'
import promiseAllValues from '../../../../utilities/promiseAllValues'
import TableFilters from '../../../TableFilters/TableFilters'
import defaultFilterFields from '../../../TableFilters/functions/defaultFilterFields'

function DealsFilters({ accountId, show, close, pushFilterFields, pushFilterButtons }) {
  const lsFilterProp = `${accountId}-dealFilter`
  const filtersDbPath = 'config/deals/filters'
  const systemFilters = [
    { filterId: 'deleted', label: 'Удаленные', fields: { isDeleted: true }, isSystemFilter: true, pos: 0 },
  ]

  const [formPattern, setFormPattern] = useState(null)
  const [formData, setFormData] = useState(null)
  const [blocks, setBlocks] = useState(null)

  useEffect(() => {
    promiseAllValues({
      object: getDoc({ accountId, path: 'config/deals/objects', docId: 'dealObject' }),
      form: getDoc({ accountId, path: 'config/deals/forms', docId: 'dealManager' }).then(({ sections }) => sections),
      filterData:
        find(systemFilters, ['filterId', localStorage.getItem(lsFilterProp)]) ||
        (localStorage.getItem(lsFilterProp) &&
          getDoc({
            accountId,
            path: filtersDbPath,
            docId: localStorage.getItem(lsFilterProp),
            docIdName: 'filterId',
          })),
    }).then((results) => {
      setFormPattern({
        ...results.object,
        ...defaultFilterFields(),
      })
      setBlocks([
        {
          label: 'Свойства сделок',
          fields: concat([{ fields: ['createdDate', 'createdBy', 'updatedDate', 'updatedBy'] }], results.form),
        },
      ])

      if (results.filterData) {
        setFormData(results.filterData.fields)
        pushFilterFields(results.filterData.fields)
        return
      }

      setFormData({})
      pushFilterFields({})
    })
  }, [])

  return (
    <TableFilters
      systemFilters={systemFilters}
      lsFilterProp={lsFilterProp}
      formPattern={formPattern}
      formData={formData}
      setFormData={setFormData}
      blocks={blocks}
      show={show}
      close={close}
      filtersDbPath={filtersDbPath}
      pushFilterButtons={pushFilterButtons}
      pushFilterFields={pushFilterFields}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DealsFilters)
