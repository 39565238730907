import getCollection from '../db/getCollection'

export const getSystemRolesOptions = (accountId) =>
  getCollection({ accountId, path: 'roles', docIdName: 'value' }).then((roles) => {
    const data = [
      {
        value: 'root',
        label: 'Главный администратор',
      },
    ]
    roles.forEach((r) =>
      data.push({
        value: r.value,
        label: r.title,
        rawData: r,
      }),
    )
    return data
  })
