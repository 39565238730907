import replace from 'lodash/replace'
import orderBy from 'lodash/orderBy'
import getDoc from '../../../utilities/db/getDoc'

const getWidgetConfig = ({ accountId, widgetId }) =>
  getDoc({ accountId, path: 'config/analytics/widgets', docId: widgetId })

export const prepareWidgetRules = ({ wConfig, data }) =>
  orderBy(wConfig.rules, ['order']).map((r) => {
    const param = r.rule.match(/\((.*)\)/)
    if (param) {
      r.rule = replace(r.rule, `${param[1]}`, data[param[1]])
    }
    return r
  })
export default getWidgetConfig
