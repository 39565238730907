import getFieldRenderObject from '../../../../../utilities/newforms/render/getFieldRenderObject'

const object = {
  role: {
    field: {
      fieldId: 'role',
      fieldType: 'select',
      label: 'Роль',
      required: true,
      getOptions: [
        { label: 'Собственний', value: 'owner' },
        { label: 'Контактное лицо', value: 'contact' },
      ],
      systemField: true,
    },
    render: getFieldRenderObject(),
  },
}

export const estateContactManagerForm = {
  sections: [
    {
      title: '',
      fields: ['role'],
    },
  ],
}

export default object
