import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import DatePicker from '../../../../components/DatePicker/DatePicker'
import formatDate, { convertToDateTime } from './functions/formatDate'
import { getUrlQueryParams, setUrlQueryParams } from '../../../../utilities/queries/'
import endOfMonth from 'date-fns/endOfMonth'

const CustomDatePicker = React.forwardRef(
  ({ listenUrl, dateRange, rangePicker, pickType, pushDateRange, setLabel, history }, ref) => {
    const [datePickerDate, setDatePickerDate] = useState({
      startDate: null,
      endDate: null,
      minDate: null,
    })

    let calendar = {}

    switch (pickType) {
      case 'month':
        calendar.type = 'month'
        calendar.dateFormat = 'yyyy-MM'
        break
      default:
        calendar.type = 'days'
        calendar.dateFormat = 'yyyy-MM-dd'
        break
    }

    useEffect(() => {
      if (listenUrl) {
        const dateObject = getDatePickerValuesFromUrlParams(history)
        if (dateObject.startDate && dateObject.endDate) {
          setDatePickerDate({
            startDate: dateObject.startDate,
            endDate: dateObject.endDate,
            minDate: dateObject.startDate,
          })
        }
      }
    }, [])

    const handleCustomRangePickerChange = (date) => {
      if (!datePickerDate.startDate) {
        setLabel(formatDate(date))
        return setDatePickerDate({ startDate: date, endDate: null, minDate: date })
      }
      if (!datePickerDate.endDate) {
        setLabel(`${formatDate(datePickerDate.startDate)} - ${formatDate(endOfMonth(date))}`)
        if (calendar.type === 'month') {
          return setDatePickerDate({ startDate: datePickerDate.startDate, endDate: endOfMonth(date), minDate: null })
        }
        return setDatePickerDate({ startDate: datePickerDate.startDate, endDate: date, minDate: null })
      }
      if (datePickerDate.startDate && datePickerDate.endDate) {
        setLabel(formatDate(date))
        return setDatePickerDate({ startDate: date, endDate: null, minDate: date })
      }
    }

    useEffect(() => {
      if (datePickerDate.startDate && datePickerDate.endDate) {
        pushDateRange({ ...rangePicker, startingDate: datePickerDate.startDate, endingDate: datePickerDate.endDate })
        history.push(
          setUrlQueryParams({
            history,
            params: [
              ['startDate', formatDate(datePickerDate.startDate)],
              ['endDate', formatDate(datePickerDate.endDate)],
            ],
          }),
        )
      }
    }, [datePickerDate.startDate, datePickerDate.endDate])

    return (
      <DatePicker
        ref={ref}
        selectsStart
        selectsEnd
        startDate={datePickerDate.startDate}
        endDate={datePickerDate.endDate}
        minDate={datePickerDate.minDate}
        dateFormat={calendar.dateFormat}
        shouldCloseOnSelect={false}
        onChange={handleCustomRangePickerChange}
        showMonthYearPicker={calendar.type === 'month'}
      />
    )
  },
)

export const getDatePickerValuesFromUrlParams = (history) => {
  const { startDate, endDate } = getUrlQueryParams({ history, params: ['startDate', 'endDate'] })
  if (startDate && endDate) {
    return {
      startDate: convertToDateTime({ date: startDate, setStartTime: true }),
      endDate: convertToDateTime({ date: endDate, setEndTime: true }),
    }
  }
  return {
    startDate: null,
    endDate: null,
  }
}

export default withRouter(CustomDatePicker)
