import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import DeletePopUp from '../../../../components/DeletePopUp/DeletePopUp'
import getDoc from '../../../../utilities/db/getDoc'
import fetchEstateObject from './functions/fetch/fetchEstateObject'
import { updateDoc } from '../../../../utilities/db/updateDoc'

const DeleteEstatePopUp = ({ accountId, estateId, uid, close }) => {
  const [estateData, setEstateData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    updateDoc({ accountId, path: 'estate', docId: estateId, data: { isDeleted: true }, uid }).then(() => close())
  }

  useEffect(() => {
    getDoc({ accountId, path: 'estate', docId: estateId, docIdName: 'estateId' })
      .then((doc) => fetchEstateObject({ accountId, doc }))
      .then((fetchedObject) => setEstateData(fetchedObject))
  }, [])

  return (
    <DeletePopUp
      title="Удаление объекта недвижимости"
      warningMessage={
        <>
          Вы уверены, что хотите удалить{' '}
          <span className="Bolder">
            "{!isEmpty(estateData) && `${estateData.estateType} по адресу: ${estateData.address.formattedAddress}`}"
          </span>
          ?
          <br />
          Все данные, как-либо связанные с этим объектом, будут сохранены.
        </>
      }
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const mapStateToProps = (state) => ({ uid: state.auth.user.uid, accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(DeleteEstatePopUp)
