import React, { useEffect, useRef } from 'react'
import './PopUp.sass'

import Backdrop from '../Backdrop/Backdrop'
import SceneContentHeader from '../SceneContent/SceneContentHeader/SceneContentHeader'
import ControllButtons from '../ControllButtons/ControllButtons'
import SubmitFormButtons from '../../../ui/SubmitFormButtons/SubmitFormButtons'
import Button from '../Button/Button'

function PopUp(props) {
  let buttons = null
  if (props.buttons) {
    buttons = props.buttons.map((button) => (
      <Button key={button.label} {...button}>
        {button.label}
      </Button>
    ))
  } else {
    buttons = null
  }

  return (
    <Backdrop transparentBackDrop={props.transparentBackDrop} show={props.show} close={props.close}>
      <div className={['PopUp', ...(props.className ? [props.className] : [])].join(' ')}>
        <SceneContentHeader
          title={props.title}
          buttons={[
            {
              type: 'basic',
              icon: 'times',
              clicked: () => props.close(),
            },
          ]}
        />
        <div className="PopUp-Content">{props.children}</div>
        {props.submitButtons && (
          <SubmitFormButtons isLoading={props.isLoading} onSubmit={props.onSubmit} onCancel={props.close} />
        )}
        {buttons && <ControllButtons>{buttons}</ControllButtons>}
        {props.customButtons && <div className="SubmitFormButtons">{props.customButtons}</div>}
      </div>
    </Backdrop>
  )
}

export default PopUp
