import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Estatelist from './scenes/Estatelist/Estatelist'
import EstateManager from './scenes/Estatelist/EstateManager/EstateManager'
import rootPath from '../../appTree/rootPath'
import ParamsManagerConfigurator from '../Settings/ParamsManagerConfigurator/ParamsManagerConfigurator'

function Estate({ permissions, options, subsections, accountId }) {
  console.log('🚀 ~ file: Estate.jsx ~ line 9 ~ Estate ~ options', options)
  return (
    <Switch>
      <Route path={`${rootPath(accountId)}/estate/list/params`} exact render={() => <ParamsManagerConfigurator />} />
      <Route
        path={[`${rootPath(accountId)}/estate/list`, `${rootPath(accountId)}/estate/list/page/:page`]}
        exact
        render={() => <Estatelist permissions={permissions} options={options} subsections={subsections} />}
      />
      <Route
        path={[
          ...(permissions.add ? [`${rootPath(accountId)}/estate/list/add`] : []),
          `${rootPath(accountId)}/estate/list/:id/edit`,
        ]}
        exact
        render={() => (
          <EstateManager permissions={permissions} options={options} closeLink={`${rootPath(accountId)}/estate/list`} />
        )}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ accountId: state.auth.account.data.accountId })

export default connect(mapStateToProps)(Estate)
