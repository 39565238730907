import React from 'react'
import './IconicButton.sass'

import Icon from '../Icon/Icon'

const iconicButton = React.forwardRef((props, ref) => {
  let extraClass
  let pushedClass

  switch (props.type) {
    case 'add':
      extraClass = 'IconicButton_theme_main'
      break
    case 'remove':
      extraClass = 'IconicButton_theme_remove'
      break
    case 'options':
      extraClass = 'IconicButton_theme_options'
      break
    default: {
      extraClass = 'IconicButton_theme_basic'
      pushedClass = props.pushed && 'IconicButton_theme_basic_pushed'
    }
  }

  const customClass = props.customClass || ''

  return (
    <button
      ref={ref}
      type="button"
      className={['IconicButton', extraClass, pushedClass, customClass].join(' ')}
      onClick={props.clicked}
      disabled={props.disabled}
    >
      <Icon name={props.icon} size={props.iconSize} weight={props.iconWeight} />
    </button>
  )
})

export default iconicButton
